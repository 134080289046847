import { DNABox, DNAText, Iffy } from '@alucio/lux-ui'
import React from 'react'
import { useDocumentTitle } from 'src/hooks/useDocumentTitle/useDocumentTitle'
import { DocumentORM, FolderItemORM } from 'src/types/orms'
import ActionBar, { ActionBarElement } from '../ActionBar/ActionBar'
import { BaseInfoPanelProps } from '../InfoPanel'
import { useInfoPanelState } from '../State/InfoPanelStateProvider'

export interface DocumentVariantProps extends BaseInfoPanelProps {
  variant: 'Document'
  documentORM?: DocumentORM
  folderItemORM?: FolderItemORM
}

interface DocumentTitleProps {
  documentORM: DocumentORM,
  folderItemORM?: FolderItemORM
}
const DocumentTitle: React.FC<DocumentTitleProps> = (props) => {
  const { documentORM, folderItemORM } = props
  const title = useDocumentTitle(documentORM.relations.version.latestUsableDocumentVersion, folderItemORM)

  return (
    <DNABox>
      <DNAText numberOfLines={2}>{title}</DNAText>
    </DNABox>)
}

/** This variant is currently unused but serves as a placeholder for when
 * document data model support is implemented in the infopanel component
  */
const DocumentVariant: React.FC<DocumentVariantProps> = props => {
  const { documentORM, folderItemORM } = props
  const { collapsed, activeActionBarOption, setCollapsed } = useInfoPanelState()
  if (!documentORM) return <></>

  const actionBarOptions: ActionBarElement[] = [
    {
      icon: collapsed ? 'arrow-collapse-left' : 'arrow-collapse-right',
      onPress: () => setCollapsed(!collapsed),
      popoverContent: collapsed
        ? <DNAText status="basic">Expand</DNAText>
        : <DNAText status="basic">Collapse</DNAText>,
      style: { borderBottomWidth: 1, borderBottomColor: '#EBEBEB' },
    },
  ]

  return (

    <DNABox appearance="row" fill>
      <Iffy is={!collapsed}>
        <Iffy is={activeActionBarOption === 'Sharing'}>
          {/* <Sharing itemORM={documentORM} /> */}
        </Iffy>
        <Iffy is={activeActionBarOption === 'Details'}>
          {/* <Details itemORM={documentORM} /> */}
          <DNABox>
            <DocumentTitle documentORM={documentORM} folderItemORM={folderItemORM} />
          </DNABox >
        </Iffy>
      </Iffy>
      {/* TODO: Switch to this component once we've implemented doc support */}
      {/* <ContentPanels itemORM={documentORM}> */}
      <ActionBar
        options={actionBarOptions}
        orientation="col"
        style={{ borderLeftWidth: 1, borderColor: '#EBEBEB', padding: 12 }}
      />
    </DNABox>
  )
}

export default DocumentVariant
