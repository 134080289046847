import React, { useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { DataStore } from '@aws-amplify/datastore';
import { DNABox, DNAButton, DNADivider, DNAText } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { Tenant } from '@alucio/aws-beacon-amplify/src/models';
import ActiveUser from 'src/state/global/ActiveUser';
import { TenantORM, useTenant } from 'src/state/redux/selector/tenant';
import { formatDataStorePayload } from 'src/state/redux/slice/common';
import omit from 'lodash/omit';
import UploadImageTenant from './UploadImageTenant';
import { ManagedFile } from 'src/components/DNA/FileUpload/FileUpload';

import API, { graphqlOperation } from '@aws-amplify/api';
import { updateTenantLogo } from '@alucio/aws-beacon-amplify/src/graphql/mutations';

const styles = StyleSheet.create({
  actionsContainer: {
    paddingVertical: 20,
    paddingHorizontal: 16,
  },
  mainWrapper: {
    backgroundColor: colors['color-text-basic'],
    overflowX: 'auto',
  },
});

interface ConfigurationProps {
  tenantId: string;
}

function removeInternalFields(tenantORM: TenantORM) {
  const tenant = { ...tenantORM.tenant };
  const internalFields = [
    'id', '_deleted', '_version', '_lastChangedAt', 'createdAt', 'createdBy',
    'updatedAt', 'updatedBy', 'statusChangedAt', 'statusChangedBy',
  ];
  return omit(tenant, internalFields);
}

const Configuration = (props: ConfigurationProps) => {
  const tenantORM = useTenant(props.tenantId);
  const jsonTenant = useMemo(() =>
    JSON.stringify(removeInternalFields(tenantORM!), undefined, 2),
  [tenantORM]);
  const [jsonConfig, setJsonConfig] = useState<string>(jsonTenant);
  const [error, setError] = useState<undefined | string>(undefined);
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const logo = useRef<null | ManagedFile>(null);

  async function save(): Promise<void> {
    try {
      setError(undefined);
      const jsonParsed = JSON.parse(jsonConfig);
      const now = new Date().toISOString();

      try {
        if (jsonTenant !== jsonConfig) {
          const newStatus = jsonParsed.status !== tenantORM?.tenant.status;
          const payload = {
            ...jsonParsed,
            id: tenantORM?.tenant.id,
            updatedAt: now,
            _version: tenantORM?.tenant['_version'],
            statusChangedAt: newStatus ? now : tenantORM?.tenant.statusChangedAt,
            statusChangedBy: newStatus ? ActiveUser.user!.id : tenantORM?.tenant.statusChangedBy,
          };
          await DataStore.save(formatDataStorePayload(Tenant, tenantORM?.tenant!, payload));
        }

        if (logo.current) {
          await API.graphql(
            graphqlOperation(updateTenantLogo, {
              input: {
                tenantId: tenantORM?.tenant.id!,
                key: logo.current.key,
              },
            }),
          ) as { data: { createTenantLambda: Tenant } };
          setIsDirty(false);
        }
      } catch (e) {
        setError('Invalid Tenant (definition error)');
      }
    } catch (e) {
      setError('Invalid JSON (syntax error)');
    }
  }

  function isFormDirty(initialInput: string, currentInput: string) {
    return initialInput !== currentInput
  }

  function onJSONChange(value): void {
    setIsDirty(isFormDirty(jsonTenant, value.target.value))
    setJsonConfig(value.target.value);
  }

  function onFinishHandler(file): void {
    logo.current = file;
    setIsDirty(true);
  }

  function removeImageHandler() : void {
    logo.current = null;
    if (jsonTenant === jsonConfig) {
      setIsDirty(false);
    }
  }

  const UploadImageTenantMemo = useMemo(() =>
    (<UploadImageTenant
      tenantId={tenantORM?.tenant.id!}
      onFinishHandler={onFinishHandler}
      removeImage={removeImageHandler}
    />)
  , [tenantORM?.tenant.id]);

  return (
    <DNABox style={styles.mainWrapper} fill appearance="col">
      <DNABox spacing="small" alignX="end" alignY="center" style={styles.actionsContainer}>
        <DNAButton
          size="small"
          status="primary"
          appearance="filled"
          onPress={save}
          disabled={!isDirty}
        >
          Save
        </DNAButton>
      </DNABox>
      <DNADivider />

      <DNABox style ={{ marginHorizontal: 16, marginVertical: 32 }}>
        {UploadImageTenantMemo}
      </DNABox>

      <DNABox appearance="col" style={styles.actionsContainer} fill>
        {
          error &&
            <DNAText style={{ marginBottom: 10 }} status="danger">{error}</DNAText>
        }
        <textarea
          onChange={onJSONChange}
          placeholder="Add JSON code..."
          value={jsonConfig}
          style={{
            minHeight: 200,
            height: '100%',
            borderRadius: 4,
            borderColor: colors[error ? 'color-danger-500' : 'color-flat-400'],
          }}
        />
      </DNABox>
    </DNABox>
  );
};

Configuration.displayName = 'Configuration';

export default Configuration;
