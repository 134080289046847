import React from 'react'
import { StyleSheet } from 'react-native'
import {
  GridList,
  DNABox,
  DNACard,
  DNADivider,
  Iffy,
  luxColors,
  util,
} from '@alucio/lux-ui'
import { Variant, DocumentORM } from 'src/types/types'
import { useSort } from 'src/components/DNA/hooks/useSort'
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import { usePagination } from 'src/components/DNA/Pagination/DNAPagination'

import { useAllDocumentsLength } from 'src/state/redux/selector/document'
import { useUserTenant } from 'src/state/redux/selector/user'
import { useDNADocumentActions } from '../Document/DNADocument.actions'
import { useAppSettings, useOnlineStatus } from 'src/state/context/AppSettings'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { DocumentListRow, FieldHeader, getFieldConfigsForTenant } from './common';

export interface DNADocumentListProps {
  documents: DocumentORM[],
  emptyVariant?: EmptyVariant,
}
export interface DNADocumentListComponent extends React.FC<DNADocumentListProps> {
  Paginated: typeof DNADocumentListPaginated
}

const S = StyleSheet.create({
  documentThumbnailBorder: {
    borderWidth: 1,
    borderColor: luxColors.border.primary,
  },
  hoveredRow: {
    backgroundColor: luxColors.mainContainer.primary,
  },
  HeaderCol: {
    paddingVertical: 12,
  },
  DesktopContainer: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    overflow: 'hidden',
  },
  MobileContainer: {
    backgroundColor: luxColors.info.primary,
  },
  MobileHeader: {
    backgroundColor: colors['color-flat-200'],
    paddingHorizontal: 16,
    minHeight: 49,
  },
  MobileRow: {
    paddingHorizontal: 16,
  },
  SelectedFilters: {
    backgroundColor: colors['color-brand-compliment-500'],
    borderRadius: 3,
    left: 20,
    paddingHorizontal: 4,
    paddingVertical: 2,
    position: 'absolute',
    top: 0,
  },
  DataCell: {
    paddingTop: 8,
    paddingBottom: 8,
  },
})

const DNADocumentListRow = React.memo(DocumentListRow);

// [TODO] - Consider cleaning/breaking this up
export const DNADocumentList: DNADocumentListComponent = (props) => {
  const { documents, emptyVariant } = props;
  const { sorts, toggleSort } = useSort()
  const allDocumentsLength = useAllDocumentsLength()
  const userTenant = useUserTenant()
  const { deviceMode } = useAppSettings()
  const isDesktop = deviceMode === 'desktop';
  const fieldConfigs = getFieldConfigsForTenant(userTenant, Variant.MSL, isDesktop)
  const documentActions = useDNADocumentActions()
  const onlineStatus = useOnlineStatus();
  const isNetworkConnected = onlineStatus

  const deviceModeProps = isDesktop
    ? { as: DNACard, style: S.DesktopContainer }
    : { fill: true, style: S.MobileContainer }

  const DeviceModeContainer = isDesktop
    ? DNACard
    : DNABox

  const hasFilterDocumentResults = allDocumentsLength && documents.length;

  const cols = fieldConfigs
    .reduce(
      (acc, field) => (field.fieldType === 'ACTION_MENU' || hasFilterDocumentResults ? `${acc} ${field.width}px` : acc),
      '',
    )

  return (
    <>
      {/* [TODO] - We should standardize around a prop called `emptyWithHeader`  */}
      <Iffy is={!allDocumentsLength}>
        <DNAEmpty emptyVariant={emptyVariant || EmptyVariant.DocumentListEmpty} />
      </Iffy>

      <Iffy is={allDocumentsLength}>
        <DeviceModeContainer {...deviceModeProps}>
          <GridList
            cols={cols}
            gap="12px"
            style={{ width: '100%' }}
          >
            {/* GridList header (Non-Empty) */}
            <Iffy is={hasFilterDocumentResults}>
              <GridList.Header style={util.mergeStyles(undefined, [S.MobileHeader, !isDesktop])}>
                {
                fieldConfigs.map(field => {
                  if (field.fieldType !== 'ACTION_MENU') {
                    return (
                      <FieldHeader
                        key={field.fieldName}
                        field={field}
                        style={S.HeaderCol}
                        toggleSort={toggleSort}
                        sortIcon={sorts?.[field.fieldName]?.icon}
                      />
                    )
                  } else {
                    return (
                      <GridList.Col key={field.fieldLabel} />
                    )
                  }
                })
              }
              </GridList.Header>
            </Iffy>

            {/* Empty Results */}
            <Iffy is={allDocumentsLength && !documents.length}>
              <DNAEmpty
                useContainer={false}
                emptyVariant={emptyVariant || EmptyVariant.DocumentListEmptySearch}
              />
            </Iffy>

            {/* Document Rows */}
            {
              documents.map((documentORM, idx) => (
                <React.Fragment key={`${documentORM.model.id}-${idx}`}>
                  <DNADivider />
                  <DNADocumentListRow
                    isNetworkConnected={isNetworkConnected}
                    isDesktop={isDesktop}
                    documentORM={documentORM}
                    variant={Variant.MSL}
                    onPress={documentActions.preview(documentORM)}
                    onThumbnailPress={documentActions.present(documentORM)}
                    fields={fieldConfigs}
                    rowStyle={isDesktop ? undefined : S.MobileRow}
                    cellStyle={S.DataCell}
                  />
                </React.Fragment>
              ))
            }
          </GridList>
        </DeviceModeContainer>
      </Iffy>
    </>
  )
}

const DNADocumentListPaginated: React.FC<Omit<DNADocumentListProps, 'documents'>> = (props) => {
  const { pagedRecords } = usePagination()

  return (
    <DNADocumentList
      {...props}
      documents={pagedRecords}
    />
  )
}

DNADocumentList.Paginated = DNADocumentListPaginated
export default DNADocumentList
