import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API, { graphqlOperation } from '@aws-amplify/api';
import { VirtualSessionWithAttendees } from 'src/types/types'
import { commonReducers, initialState, SliceStatus } from './common'
import subWeeks from 'date-fns/subWeeks'
import { SESSION_STATUS } from '@alucio/aws-beacon-amplify/src/API';

const sessionsByCreatedByCreatedAt = `
query SessionsByCreatedByCreatedAt(
  $createdBy: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelVirtualSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  sessionsByCreatedByCreatedAt(
    createdBy: $createdBy
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      meetingId
      roomName
      status
      hostName
      hostPhonePin
      createdAt
      createdBy
      terminatedAt
      recordingStatus
      documentHistory {
        contentType
        contentId
        totalDurationSec
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      attendees (filter: {status: {ne: DECLINED}}) {
        items {
          id
          attendeeKey
          tenantId
          hostId
          meetingId
          sessionId
          status
          name
          phonePin
          clientIP
          joinToken
          createdAt
          terminatedAt
          acceptedAt
          lastSeenAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
      }
    }
    nextToken
    startedAt
  }
}
`;

interface getRecentMeetingsForUserProps {
  userId: string,
}

const getRecentMeetingsForUser = createAsyncThunk(
  'virtualSesssion/getRecentMeetingsForUser',
  async (props: getRecentMeetingsForUserProps) => {
    // [NOTE] - We only display meetings within the last 2 weeks
    const displayDateRange = subWeeks(new Date(), 2).toISOString()

    try {
      const queryParams = {
        createdAt: { ge: displayDateRange },
        createdBy: props.userId,
        filter: {
          status: {
            eq: SESSION_STATUS.TERMINATED,
          },
        },
        sortDirection: 'DESC',
      }
      return await API.graphql(graphqlOperation(sessionsByCreatedByCreatedAt, queryParams))
    }
    catch (err) {
      // To log in the logger tool
      console.error(`Error retrieving meetings ${JSON.stringify(err)}`);
      throw new Error(`Error retrieving meetings ${JSON.stringify(err)}`);
    }
  },
)

const sliceName = 'virtualSession'
const {
  reducers,
  extraReducers,
} = commonReducers<VirtualSessionWithAttendees>(sliceName)

const virtualSessionSlice = createSlice({
  name: sliceName,
  initialState: initialState<VirtualSessionWithAttendees>(),
  reducers: {
    ...reducers,
    // for clearing redux store when unmounting from component
    clearState: state => {
      state.records = []
      state.status = SliceStatus.NEW
    },
  },
  extraReducers: {
    [getRecentMeetingsForUser.fulfilled.toString()]: (state, { payload }) => {
      const transformed = payload.data.sessionsByCreatedByCreatedAt.items.map((session) => {
        const { attendees, ...remainder } = session;
        return {
          attendees: attendees.items,
          ...remainder,
        }
      });

      state.status = SliceStatus.OK
      state.records = transformed;
    },
    ...extraReducers,
  },
})

export default virtualSessionSlice
export const virtualSessionSliceActions = {
  ...virtualSessionSlice.actions,
  getRecentMeetingsForUser,
}
