import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSettings } from 'src/state/context/AppSettings';
import { sharedFolderActions } from 'src/state/redux/slice/sharedFolder';
import MeetingsVariant from './Variants/Meetings/MyContentPanel.meetings';
import ContentPreviewModalVariant from './Variants/MyContentPanel.contentPreviewModal';
import VirtualVariant from './Variants/MyContentPanel.virtual';
import { PresentableModelORM } from 'src/state/context/ContentProvider/ContentProvider';

enum Variants {
  ContentPreviewModal,
  Virtual,
  Meetings,
}
type Variant = keyof typeof Variants
type ComponentVariants = Record<Variant, React.ReactElement>
interface MyContentPanelProps {
  variant: Variant
  onClosePanel?: () => void;
  /** TODO: Determine the purpose/necessity of this prop. */
  addGap?: boolean;
  displayContentPanel: boolean;
  sessionId?: string;
  onSelectPresentableORM?: (orm: PresentableModelORM, slide?: number) => void;
}

const MyContentPanel: React.FC<MyContentPanelProps> = props => {
  /** Quick trick to filter out the variant property from the parent wrapper props */
  const { variant, ...variantProps } = props

  const componentVariants:ComponentVariants = {
    ContentPreviewModal: <ContentPreviewModalVariant {...variantProps}/>,
    Virtual: <VirtualVariant {...variantProps}/>,
    Meetings: <MeetingsVariant {...variantProps}/>,
  }

  const dispatch = useDispatch();
  const { isOnline } = useAppSettings();
  // FETCHES THE SHARED FOLDERS FOR THE CONTENT PANEL
  useEffect(() => {
    dispatch(sharedFolderActions.getSharedFoldersAction())
  }, [isOnline]);

  const selectedVariant = componentVariants[variant]

  return selectedVariant
}

MyContentPanel.displayName = 'ContentPanel';

export default MyContentPanel;
