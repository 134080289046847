/* eslint-disable max-len */
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgBookmarks(props: SvgProps) {
  return (
    <Svg
      viewBox="0 0 32 32"
      width={32}
      height={32}
      fill="none"
      // @ts-ignore
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.997 0h14.006A4.009 4.009 0 0129 3.997V27a1 1 0 01-1.625.78l-5-4 1.25-1.56L27 24.92V4.001A2.009 2.009 0 0024.998 2H11.002A2.009 2.009 0 009 4.002V5H7V3.997A4.009 4.009 0 0110.997 0z"
        fill={props.color ?? '#fff'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.997 5H20v1l.003-1A4.009 4.009 0 0124 8.997V31a1 1 0 01-1.644.765L13.5 24.307l-8.856 7.458A1 1 0 013 31V8.997A4.009 4.009 0 016.997 5zm13.001 2H7.002A2.009 2.009 0 005 9.002V28.85l7.856-6.616a1 1 0 011.288 0L22 28.851V9A2.009 2.009 0 0019.998 7z"
        fill={props.color ?? '#fff'}
      />
    </Svg>
  );
}

export default SvgBookmarks;
