import { useEffect, useState } from 'react';

/**
 * Hook for full screen state and toggling full screen
 * Works only on Safari, Chrome, Edge
 * @returns isFullScreen, toggleFullScreen
 */
const useFullScreen = () => {
  const [isFullScreen, setFullScreen] = useState<boolean>(!!document.webkitFullscreenElement);

  const toggleFullScreen = () => {
    if (!document.webkitFullscreenElement) {
      document.documentElement.webkitRequestFullScreen();
    }
    else {
      if (document.webkitExitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  useEffect(() => {
    const onFullScreenChange = () => {
      setFullScreen((prev) => !prev);
    }

    document.addEventListener('webkitfullscreenchange', onFullScreenChange);
    return () => document.removeEventListener('webkitfullscreenchange', onFullScreenChange);
  }, [])

  return {
    isFullScreen,
    toggleFullScreen,
  }
}

export default useFullScreen;
