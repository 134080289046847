import { DNABox, DNAButton, DNAChip, DNADivider, DNAText, Iffy, Hoverable } from '@alucio/lux-ui'
import { ImageBackground, Pressable, StyleSheet, ViewStyle } from 'react-native';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import React, { useState } from 'react'
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip';
import { isDocumentVersionORM, isFolderItemORM } from 'src/types/typeguards';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors['color-dark-1000'],
    maxWidth: 320,
    borderLeftWidth: 1,
    borderColor: '#383838',
  },
  header: {
    paddingHorizontal: 24,
    paddingVertical: 12,
    height: 56,
    justifyContent: 'space-between',
    backgroundColor: colors['color-success-500'],
  },
  // TODO remove style in button
  expandButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  overlay: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.48)',
  },
});

const CurrentlyPresentingPanel = () => {
  const { setCurrentTab } = useMeetingsState()
  const { activePresentation } = useContent();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const presentable = activePresentation?.presentable;
  const currentPageNumber = activePresentation?.currentPresentablePage.presentationPageNumber;

  const s3URL = detectArchivedFileKeyPath(activePresentation?.currentPresentablePage.documentVersion?.model,
    activePresentation?.currentPresentablePage.page!) || ''

  const isDocumentVersion = isDocumentVersionORM(activePresentation?.presentable.orm) ||
    isDocumentVersionORM(activePresentation?.presentable.orm.relations.item);

  const isModifiedDocumentVersion = isFolderItemORM(activePresentation?.presentable.orm) && isDocumentVersion &&
    activePresentation?.presentable.orm.model.visiblePages?.length;

  function toggleHover(): void {
    setIsHovered(!isHovered);
  }

  function handleExpand(): void {
    setIsHovered(false);
    setCurrentTab('PRESENTING')
  }

  return (

    <DNABox style={styles.container} appearance="col">
      <DNABox appearance="row" alignY="center" style={styles.header}>
        <DNAText h5 bold status="basic">Currently Presenting</DNAText>
        <DNAButton
          appearance="filled"
          context="square"
          size="medium"
          iconLeft="arrow-expand"
          style={styles.expandButton}
          iconStyle={{ paddingHorizontal: 12 } as ViewStyle}
          onPress={handleExpand}
        >
          Expand
        </DNAButton>
      </DNABox>
      <Iffy is={!activePresentation}>
        <DNABox style={{ width: 320, height: 182 }}>
          <ImageBackground
            source={require('../../../../../../../applications/beacon/assets/images/beacon-presentation-bg.jpg')}
            style={{ flex: 1 }}
          />
        </DNABox>
      </Iffy>
      <Iffy is={activePresentation}>
        <DNABox
          spacing="small"
          appearance="row"
          alignY="center"
          style={{ paddingHorizontal: 16, paddingVertical: 12 }}
        >
          <DNABox spacing="small">
            <Iffy is={isDocumentVersion && activePresentation}>
              <DNABox spacing="small">
                <DNADocumentChip
                  item={activePresentation?.currentPresentablePage?.documentVersion!}
                  variant="purpose"
                />
              </DNABox>
            </Iffy>

            <Iffy is={isModifiedDocumentVersion || !isDocumentVersion}>
              <DNAChip
                appearance="tag"
                style={{ backgroundColor: colors['color-basic-transparent-200'] }}
                status="dark"
              >
                MODIFIED
              </DNAChip>
            </Iffy>
            <DNAText numberOfLines={2} style={{ maxWidth: 270 }} status="basic">
              {activePresentation?.presentable.title}
            </DNAText>
          </DNABox>
        </DNABox>

        <Hoverable onHoverIn={toggleHover} onHoverOut={toggleHover}>
          <DNABox>
            <Pressable onPress={handleExpand}>
              <DNAThumbnail
                s3URL={s3URL}
                useLoadingIndicator={true}
                width={320}
                height={182}
              />
              <Iffy is={isHovered}>
                <DNABox style={[styles.overlay]}>
                  <DNAButton
                    appearance="ghost"
                    context="square"
                    size="medium"
                    iconLeft="arrow-expand"
                    iconStyle={{ color: 'white' } as ViewStyle}
                    onPress={handleExpand}
                  >
                    <DNAText bold status="basic">Expand</DNAText>
                  </DNAButton>
                </DNABox>
              </Iffy>
            </Pressable>
          </DNABox>
        </Hoverable>
        <DNABox alignX="center" spacing="small" style={{ paddingVertical: 16 }} alignY="center">
          <DNAText style={{ fontSize: 14 }} bold status="basic">
            {`Slide ${currentPageNumber} of ${presentable?.numberOfPages}`}
          </DNAText>
        </DNABox>
        <DNADivider variant="virtual" />
        <DNABox style={{ padding: 16 }}>
          <DNAText style={{ fontSize: 15 }} status="basic">
            {activePresentation?.currentPresentablePage.page.speakerNotes || 'No slide notes'}
          </DNAText>
        </DNABox>
      </Iffy>
    </DNABox >
  )
}

export default CurrentlyPresentingPanel
