import React from 'react'
import { TouchableOpacity, ImageBackground, StyleSheet } from 'react-native'
import { DNABox, DNAButton, DNAIcon, DNAText, Iffy, luxColors, util } from '@alucio/lux-ui'
import { useLDClient } from 'launchdarkly-react-client-sdk'

import TenantLogo from 'src/components/DNA/Header/TenantLogo'
import MoleculesBGSrc from 'src/../assets/images/Molecules.svg'

import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import useScreenNav from 'src/components/DNA/hooks/useScreenNav'
import { useAppSettings } from 'src/state/context/AppSettings'
import { DNARoute, ROUTES } from 'src/router/routes'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import useIsPublisherView from 'src/hooks/useIsPublisherView/useIsPublisherView'
import { useSearchTextContextProvider } from 'src/components/Header/SearchInput/SearchInput'
import { FeatureFlags } from 'src/types/featureFlags'
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag'

const S = StyleSheet.create({
  navItem: {
    paddingVertical: 20,
    textAlign: 'center',
    width: 88,
  },
  moleculesBG: {
    flex: 1,
    bottom: 0,
    width: '100%',
    height: 408,
    opacity: 0.2,
    position: 'absolute',
    zIndex: -1,
  },
  publisherViewSideBar: {
    backgroundColor: colors['color-text-basic'],
    borderRightColor: colors['color-flat-300'],
    borderRightWidth: 1,
    paddingTop: 20,
  },
  sideBar: {
    backgroundColor: colors['color-brand-500'],
    paddingTop: 20,
  },
})

const useNavItem = (route: DNARoute) => {
  const currentPage = useCurrentPage()
  const { go } = useScreenNav()
  const { setCurrentSearchString } = useSearchTextContextProvider()

  return {
    isCurrentPage: currentPage === route || currentPage?.PARENT_PATH === route.PATH,
    goTo: () => {
      go(route)
      setCurrentSearchString('')
    },
  }
}

const NavItemDesktop: React.FC<{ route: DNARoute, isPublisherView?: boolean }> = (props) => {
  const { isPublisherView, route } = props
  const common = useNavItem(route)
  let textStatus
  let iconColor

  if (isPublisherView) {
    textStatus = common.isCurrentPage ? 'primary' : 'subtle'
    iconColor = common.isCurrentPage ? colors['color-primary-500'] : colors['color-subtle-500']
  }

  return (
    <DNABox
      testID={`${route.testID}-nav-button`}
      style={
        util.mergeStyles(
          undefined,
          S.navItem,
          [{ backgroundColor: 'rgba(255,255,255,.16)' }, common.isCurrentPage],
          [{ backgroundColor: colors['color-brand-compliment-100'] }, common.isCurrentPage && isPublisherView],
        )
      }
      appearance="col"
      alignX="center"
      spacing="small"
      as={TouchableOpacity}
      // @ts-ignore
      onPress={common.goTo}
    >
      <DNAIcon.Styled
        color={iconColor}
        width={40}
        height={40}
        name={route?.ICON}
        CustomIcon={route?.CUSTOM_ICON}
      />
      <DNAText c1 bold={props.isPublisherView} status={textStatus || 'basic'}>
        {route.LABEL}
      </DNAText>
    </DNABox>
  )
}

const NavItemTablet: React.FC<{ route: DNARoute }> = (props) => {
  const { route } = props
  const common = useNavItem(route)

  return (
    <DNAButton
      size="xlarge"
      appearance="ghost"
      status={common.isCurrentPage ? 'primary' : 'subtle'}
      onPress={common.goTo}
      customIconLeft={route?.CUSTOM_ICON}
      testID={`${route.testID}-nav-button`}
    >
      {route.LABEL}
    </DNAButton>
  )
}

const useNavigationMenu = () => {
  const currentPage = useCurrentPage()
  const isPublisherView = useIsPublisherView()

  return { currentPage, isPublisherView }
}

const DNANavigationMenuDesktop: React.FC = () => {
  const common = useNavigationMenu()

  /** SECTION: Feature flags */
  const ldClient = useLDClient()
  const disableVirtual = ldClient?.variation('disable-virtual', false)
  const disablePublisherFolder = ldClient?.variation('beac-2327-disable-publisher-folder', true)
  const beac1849EnablePublisherReports = ldClient?.variation('beac-1849-enable-publisher-reports', false)
  const enableEmailTemplates = useFeatureFlag(FeatureFlags.BEAC_2516_Enable_Document_Sharing_Templates)
  const beac1498ThirdParty  = useFeatureFlag(FeatureFlags.BEAC_1498_third_party_conferencing_integration);
  /** !SECTION: End Feature flags */

  const isDisabledOnCurrentRoute = common.currentPage?.configOptions?.desktop?.disableGlobalNavigationMenu

  if (isDisabledOnCurrentRoute) { return null }

  return (
    <DNABox
      appearance="col"
      style={common.isPublisherView ? S.publisherViewSideBar : S.sideBar}
    >
      {/* MSL View */}
      <Iffy is={!common.isPublisherView}>
        <NavItemDesktop route={ROUTES.HOME} />
        <NavItemDesktop route={ROUTES.LIBRARY} />
        <NavItemDesktop route={ROUTES.FOLDERS} />
        <NavItemDesktop route={ROUTES.BOOKMARKS} />
        <Iffy is={!disableVirtual}>
          <NavItemDesktop route={ROUTES.MEETING_HISTORY} />
        </Iffy>
        <Iffy is={beac1498ThirdParty}>
          <NavItemDesktop route={ROUTES.MEETING_HISTORY_V2} />
        </Iffy>
      </Iffy>

      {/* Publisher View */}
      <Iffy is={common.isPublisherView}>
        <NavItemDesktop route={ROUTES.PUBLISHER_DASHBOARD} isPublisherView />
        <Iffy is={enableEmailTemplates}>
          <NavItemDesktop route={ROUTES.EMAIL_TEMPLATES} isPublisherView />
        </Iffy>
        <Iffy is={!disablePublisherFolder}>
          <NavItemDesktop route={ROUTES.PUBLISHER_FOLDERS} isPublisherView />
        </Iffy>
        <Iffy is={beac1849EnablePublisherReports}>
          <NavItemDesktop route={ROUTES.REPORTS} isPublisherView />
        </Iffy>
      </Iffy>

      {/* Background */}
      <Iffy is={!common.isPublisherView}>
        <ImageBackground
          style={S.moleculesBG}
          source={{ uri: MoleculesBGSrc as string }}
        />
      </Iffy>
    </DNABox>
  )
}

const DNANavigationMenuTablet: React.FC = () => {
  const common = useNavigationMenu()

  const isDisabledOnCurrentRoute = common.currentPage?.configOptions?.tablet?.disableGlobalNavigationMenu

  if (isDisabledOnCurrentRoute) { return null }

  return (
    <DNABox
      style={{
        shadowColor: luxColors.basicBlack.primary,
        shadowOffset: {
          width: 0,
          height: 9,
        },
        shadowOpacity: 0.50,
        shadowRadius: 12.35,
        paddingVertical: 6,
        paddingHorizontal: 16,
        backgroundColor: 'white',
      }}
    >
      <DNABox fill alignY="center" spacing="around">
        <NavItemTablet route={ROUTES.FOLDERS} />
        <NavItemTablet route={ROUTES.BOOKMARKS} />
        <NavItemTablet route={ROUTES.LIBRARY} />
      </DNABox>
      <DNABox>
        <TenantLogo size="small" />
      </DNABox>
    </DNABox>
  )
}

const DNANavigationMenu = (props) => {
  const { deviceMode } = useAppSettings()

  return deviceMode === 'desktop'
    ? <DNANavigationMenuDesktop {...props} />
    : <DNANavigationMenuTablet {...props} />
}

export default DNANavigationMenu
