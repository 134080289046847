import React, { useState } from 'react'
import { FlatList, Text, ScrollView } from 'react-native'
import { batch, useSelector } from 'react-redux'
import { openDB } from 'idb'
import { DNABox, DNAButton, Modal } from '@alucio/lux-ui'

import store, { RootState } from 'src/state/redux/store'
import { cacheActions } from 'src/state/redux/slice/Cache/cache'

const broadcastChannelDBName = 'pubkey.broadcast-channel-0-WORKER_CHANNEL'

const DevLogger: React.FC<{ disable?: boolean}> = (props) => {
  const { disable } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const logs = useSelector((state: RootState) => state.cache.logs)

  if (disable) return null;

  const debugBroadcastChannel = async () => {
    store.dispatch(cacheActions.addLog('CLIENT - Attempting to debug Broadcast Channel'))

    const db = await openDB(broadcastChannelDBName)

    if (!db.objectStoreNames.contains('messages')) {
      store.dispatch(cacheActions.addLog('CLIENT - No Broadcast Channel DB Found'))
      return;
    }

    const tx = db.transaction('messages')
    const idbStore = tx.store

    const messages = await idbStore.getAll()
    await tx.done

    batch(() => {
      store.dispatch(cacheActions.addLog('CLIENT - ---IDB DEBUG---'))
      messages.forEach((msg) => {
        store.dispatch(cacheActions.addLog('\n' + JSON.stringify(msg, null, 2)))
      })
    })

    await db.close()
  }

  return (
    <DNABox
      style={{
        zIndex: 1000,
        position: 'absolute',
        bottom: 20,
        right: 20,
      }}
    >
      <DNAButton onPress={() => setIsOpen(p => !p)}>
        See Logs
      </DNAButton>
      <Modal
        visible={isOpen}
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
        }}
      >
        <DNABox
          appearance="col"
          fill
          style={{ backgroundColor: 'white' }}
          spacing="medium"
          childFill={1}
        >
          <DNABox
            alignX="end"
            spacing="medium"
            style={{ marginTop: 20, marginRight: 20 }}
          >
            <DNAButton onPress={debugBroadcastChannel}>
              Print WorkerChannel IndexedDB
            </DNAButton>
            <DNAButton onPress={() => store.dispatch(cacheActions.clearLog())}>
              Clear Logs
            </DNAButton>
            <DNAButton onPress={() => setIsOpen(false)}>
              Close
            </DNAButton>
          </DNABox>

          <DNABox fill>
            <ScrollView style={{ display: 'flex', flex: 1 }}>
              <FlatList
                keyExtractor={(_, idx) => '' + idx}
                data={logs}
                renderItem={({ item, index }) => (<Text>{index} : {item}</Text>)}
              />
            </ScrollView>
          </DNABox>
        </DNABox>
      </Modal>
    </DNABox>
  )
}

export default DevLogger
