import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNACard,
  DNACheckbox,
  DNADivider,
  DNAIcon,
  DNAText,
  Iffy,
  luxColors,
  Tabs,
  util,
} from '@alucio/lux-ui'
import { useAppSettings } from 'src/state/context/AppSettings'
import { useAllDocumentsInstance, useAllPersonalDocuments } from 'src/state/redux/selector/document'
import documentQuery from 'src/state/redux/document/query';
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'

import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList'
import InputComponent from 'src/components/Publishers/InputComponent';

import debounce from 'lodash/debounce';
import { DocumentORM, DocumentVersionORM, FolderORM } from 'src/types/types'
import { Input } from '@ui-kitten/components';
import { GroupStatus, PayloadGroup, usePresentationBuilderState } from '../state/PresentationBuilderStateProvider';
import { DocumentAccessLevel, PurposeType } from '@alucio/aws-beacon-amplify/src/models';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { v4 as uuid } from 'uuid';
import PageGroupList from 'src/components/SlideSelector/PageGroupList';
import useThumbnailSelector from 'src/components/SlideSelector/useThumbnailSelector';
import { SlideMode } from 'src/components/SlideSelector/SlideSelector';
import { useCanPerformSearch, useMSLDocumentSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import { useLDClient } from 'launchdarkly-react-client-sdk';

const Options = {
  BOOKMARKS: 'Bookmarks',
  LIBRARY: 'Library',
  MY_UPLOADS: 'My Uploads',
  SEARCH_RESULTS: 'Search results',
};

interface HeaderProps {
  localSearchText: string,
  setSelectedTab: (tab: string) => void,
  selectedTab: string,
  setLocalSearchText: (text: string) => void,
  setSearchText: (text: string) => void,
}

interface SubHeaderProps {
  selectedDocumentVersion,
  searchResultsText,
  setSelectedDocumentVersion,
  selectorThumbnailMode,
  toggleSelectorThumbnailMode,
}

interface ContentProps {
  selectedTab: string,
  setSelectedDocumentVersion: (doc) => void,
  searchText?: string,
  selectedDocumentVersion?: DocumentVersionORM,
  // [TODO-1837] - Give these types
  setPageGroups,
  handleGroupSelect,
  selectorThumbnailSize,
  currentTab,
  groupDrafts,
}

interface LowerActionBarProps {
  allPagesSelected,
  handleSelectAllToggle,
  selectionCount,
  pages,
  handleGroupAdd,
  selectedGroups
}

interface TabProps {
  isSelected: boolean;
  option: {
    title: string,
  }
  onTabSelected: (tab: string) => void,
}

const BOOKMARKED_DOCS_QUERY = documentQuery.merge(
  documentQuery.filters.published,
  documentQuery.filters.bookmarked,
  documentQuery.filters.nonInternal,
  documentQuery.filters.hidingEnabled,
  documentQuery.sorts.bookmarkedAsc,
)

const ALL_DOCS_QUERY = documentQuery.merge(
  documentQuery.sorts.updatedAtDesc,
  documentQuery.sorts.titleAsc,
  documentQuery.filters.published,
  documentQuery.filters.nonInternal,
  documentQuery.filters.hidingEnabled,
  documentQuery.sorts.bookmarkedAsc,
)

const styles = StyleSheet.create({
  searchBarStyle: {
    alignSelf: 'center',
    backgroundColor: luxColors.info.primary,
    borderColor: luxColors.disabled.secondary,
    width: 400,
    color: colors['flat-600'],
    borderRadius: 4,
    flexDirection: 'row',
  },
  searchBarStyleTablet: {
    marginHorizontal: 12,
    width: 320,
  },
  headerTabsWrapper: {
    marginTop: '10px',
  },
  headerWrapper: {
    height: 56,
    paddingHorizontal: 64,
    borderBottomColor: colors['color-flat-300'],
    borderBottomWidth: 1,
  },
  headerWrapperTablet: {
    paddingHorizontal: 0,
  },
  contentWrapper: {
    paddingHorizontal: 64,
  },
  documentList: {
    marginBottom: 32,
  },
  searchResultsText: {
    marginVertical: 16,
  },
  lowerActionWrapper: {
    maxHeight: 56,
    paddingHorizontal: 19,
    borderColor: luxColors.disabled.secondary,
    borderTopWidth: 1,
  },
});

const tabs: TabProps['option'][] = [
  { title: 'Bookmarks' },
  { title: 'Library' },
  { title: 'My Uploads' },
];

const usePresentationSelector = () => {
  const [selectedTab, setSelectedTab] = useState<string>(Options.BOOKMARKS);
  const [localSearchText, setLocalSearchText] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [selectedDocumentVersion, setSelectedDocumentVersion] = useState<DocumentVersionORM | undefined>();
  const {
    builderMode,
    selectorThumbnailMode,
    selectorThumbnailSize,
    customDeck,
    setSelectedGroups,
    toggleSelectorThumbnailMode,
  } = usePresentationBuilderState();
  const {
    allPagesSelected,
    pages,
    pageGroups,
    selectionCount,
    groupsWithSomeSlidesSelected,

    handleSelectAllToggle,
    handleThumbSelect,
    setPageGroups,
    handleSelectGroup,
    getGroupDrafts,
    initializeThumbnails,
  } = useThumbnailSelector({ mode: SlideMode.DocumentVersion })

  const isSelectionMode = builderMode === 'selection';

  const bookmarkedDocs = useAllDocumentsInstance(BOOKMARKED_DOCS_QUERY)
  const documents = useAllDocumentsInstance(ALL_DOCS_QUERY)
  const myPersonalDocuments = useAllPersonalDocuments();

  const resultsFromSearch: DocumentORM[] = useMSLDocumentSearch({ text: searchText })

  const currentTab = {
    [Options.BOOKMARKS]: {
      items: bookmarkedDocs,
      label: 'Bookmarks',
    },
    [Options.LIBRARY]: {
      items: documents,
      label: 'Library',
    },
    [Options.MY_UPLOADS]: {
      items: myPersonalDocuments,
      label: 'My Uploads',
    },
    // "Ghost tab". It works as a tab but is not shown as an option to be selected.
    // Will be applied upon a valid search text input value.
    [Options.SEARCH_RESULTS]: {
      items: resultsFromSearch.filter((document) =>
        document.relations.version.latestDocumentVersionPublished?.model.canHideSlides &&
          document.relations.version.latestDocumentVersionPublished?.model.purpose !== PurposeType.INTERNAL_USE_ONLY,
      ),
      label: 'Search results',
    },
  }

  const searchResultsText: string = localSearchText === ''
    ? `${currentTab[selectedTab].items.length} item(s)` : `Search results for "${localSearchText}" ` +
      `| ${currentTab[selectedTab].items.length} result(s)`;

  const handleGroupAdd = () => {
    const newPayloadGroups = getGroupDrafts()
      // filter out groups without selected pages
      .filter(groupDraft => groupDraft.pages.some(page => page.checked))
      // map the remaining to payload group objs
      .map<PayloadGroup>(
        groupDraft => {
          const groupId = uuid();
          groupDraft.pages.forEach((page) => {
            analytics?.track('CUSTOM_SLIDE_ADDED', {
              action: 'SLIDE_ADDED',
              category: 'CUSTOM',
              customDeckId: customDeck?.model.id,
              groupId: groupId,
              pageId: page.pageId,
            });
          });

          return {
            documentVersion: selectedDocumentVersion!,
            id: groupId,
            groupSrcId: groupDraft.id,
            groupStatus: GroupStatus.ACTIVE,
            pages: groupDraft.pages,
            visible: true,
          }
        },
      )

    setSelectedGroups(p => {
      return [...p, ...newPayloadGroups]
    })
    // unselect / uncheck all after adding to presentation
    handleSelectAllToggle(false);
  }

  useEffect(() => {
    selectedDocumentVersion && initializeThumbnails(selectedDocumentVersion.model)
  }, [selectedDocumentVersion])

  return {
    currentTab,
    localSearchText,
    selectedTab,
    searchText,
    searchResultsText,
    selectedDocumentVersion,
    isSelectionMode,
    allPagesSelected,
    pages,
    pageGroups,
    selectionCount,
    selectorThumbnailMode,
    selectorThumbnailSize,
    groupsWithSomeSlidesSelected,
    setLocalSearchText,
    setSelectedTab,
    setSearchText,
    setSelectedDocumentVersion,
    handleSelectAllToggle,
    handleThumbSelect,
    setPageGroups,
    handleGroupAdd,
    handleSelectGroup,
    toggleSelectorThumbnailMode,
    getGroupDrafts,
  }
}

const Header: React.FC<HeaderProps> = (props) => {
  const {
    localSearchText,
    selectedTab,
    setLocalSearchText,
    setSelectedTab,
    setSearchText,
  } = props;

  const LDClient = useLDClient();
  const enableMyUploads = LDClient?.variation('enable-viewer-file-upload', false);
  const filteredTabs = enableMyUploads ? tabs : tabs.filter((item) => item.title !== 'My Uploads');

  const canPerformSearch = useCanPerformSearch();
  const { deviceMode } = useAppSettings()
  // Used to return to that tab if user clears the search input
  const tabSelectedBeforeSearch = useRef(tabs[0].title);

  // Memoized Components
  const MemoizedClearTextButton = useMemo(() => getClearButtonIcon(), [selectedTab]);
  const MemoizedMagnifying = useMemo(() => renderMagnifyingIcon(), []);
  const MemoizedMagnifyingFunction = useCallback(() => MemoizedMagnifying, []);
  const MemoizedTabs = useMemo(() => getTabs(), [selectedTab]);
  const inputRef = useRef<Input>(null)

  function renderCloseIcon(): ReactElement {
    if (!localSearchText) {
      return <DNABox />;
    }

    return MemoizedClearTextButton;
  }

  function getTabs(): ReactElement {
    return (<Tabs
      selectedTab={selectedTab}
      onSelectTab={handleTabSelect}
      tabs={filteredTabs}
      selectedTabVariant="primary"
    />)
  }

  function getClearButtonIcon(): ReactElement {
    const handleClear = () => {
      onSearchTextChange('')
      inputRef.current?.focus()
    }
    return (
      <TouchableOpacity onPress={handleClear}>
        <DNAIcon.Styled name="close" appearance="ghost" status="subtle" />
      </TouchableOpacity>
    );
  }

  function renderMagnifyingIcon(): ReactElement {
    return (
      <TouchableOpacity disabled>
        <DNAIcon.Styled name="magnify" appearance="ghost" status="subtle" />
      </TouchableOpacity>
    );
  }
  function handleTabSelect(tab: string) {
    setLocalSearchText('');
    setSearchText('');
    setSelectedTab(tab);
  }

  function onSearchTextChange(text: string): void {
    const canTextBeSearchable = canPerformSearch(text);
    setLocalSearchText(text);

    if (text) {
      debounce(() => {
        setSearchText(text);
      }, 1000)();
    }

    if (canTextBeSearchable && selectedTab && selectedTab !== 'Search results') {
      tabSelectedBeforeSearch.current = selectedTab;
      setSelectedTab('Search results');
    } else if (!canTextBeSearchable && selectedTab === 'Search results') {
      setSelectedTab(tabSelectedBeforeSearch.current);
    }
  }

  const isTablet = deviceMode === 'tablet'

  return (
    <DNABox
      alignY="center"
      style={util.mergeStyles(
        undefined,
        styles.headerWrapper,
        [styles.headerWrapperTablet, isTablet],
      )}
      spacing="between"
    >
      <DNABox style={styles.headerTabsWrapper}>
        {MemoizedTabs}
      </DNABox>
      <DNABox fill>
        <InputComponent
          ref={inputRef}
          getRightIconFunction={renderCloseIcon}
          getLeftIconFunction={MemoizedMagnifyingFunction}
          hideLabel={true}
          inputStyle={util.mergeStyles(
            undefined,
            styles.searchBarStyle,
            [styles.searchBarStyleTablet, isTablet],
          )}
          onChangeText={onSearchTextChange}
          placeholderTextColor={luxColors.contentPanelBackground.quinary}
          removeMarginPadding={true}
          title="Search documents..."
          value={localSearchText}
        />
      </DNABox>
    </DNABox>
  );
}

const SubHeader: React.FC<SubHeaderProps> = (props) => {
  const {
    selectedDocumentVersion,
    searchResultsText,
    setSelectedDocumentVersion,
    selectorThumbnailMode,
    toggleSelectorThumbnailMode,
  } = props

  const { deviceMode } = useAppSettings()
  const isTablet = deviceMode === 'tablet'
  const isPersonalFile =
    selectedDocumentVersion?.relations.document.model.accessLevel === DocumentAccessLevel.USER;

  return (
    <DNABox alignY="center">
      <Iffy is={!selectedDocumentVersion}>
        <DNABox
          style={{
            marginHorizontal: isTablet ? 24 : 64,
            marginTop: 16,
          }}
        >
          <DNAText
            h5
            status="secondary"
            style={styles.searchResultsText}
          >
            {searchResultsText}
          </DNAText>
        </DNABox>
      </Iffy>
      <Iffy is={selectedDocumentVersion}>
        <DNABox
          fill
          alignY="center"
          style={{ height: 80 }}
        >
          <DNABox>
            <DNAButton
              status="dark"
              appearance="ghost"
              onPress={() => setSelectedDocumentVersion(undefined)}
              iconLeft="chevron-left"
            >
              Back
            </DNAButton>
          </DNABox>
          <DNABox appearance="col" fill spacing="small">
            <DNAText numberOfLines={1} b1>{selectedDocumentVersion?.model.title}</DNAText>
            <DNABox appearance="row" spacing="medium">
              <Iffy is={!isPersonalFile}>
                <DNADocumentChip
                  item={selectedDocumentVersion?.relations.document!}
                  variant="purpose"
                />
              </Iffy>
              <DNADocumentChip
                item={selectedDocumentVersion?.relations.document!}
                variant="docType"
              />
            </DNABox>
          </DNABox>
          <DNABox fill alignX="end">
            <DNAButton
              appearance="ghost"
              status="dark"
              iconLeft={selectorThumbnailMode === 'small' ? 'view-grid' : 'view-comfy'}
              onPress={toggleSelectorThumbnailMode}
            />
          </DNABox>
        </DNABox>
      </Iffy>
    </DNABox>

  )
}

const Content: React.FC<ContentProps> = (props) => {
  const {
    selectedTab,
    selectedDocumentVersion,
    handleGroupSelect,
    selectorThumbnailSize,
    currentTab,
    groupDrafts,
    setSelectedDocumentVersion,
  } = props

  const { deviceMode } = useAppSettings()

  // TODO: Properly type this
  const onItemPress = (item) => {
    setSelectedDocumentVersion(item.relations.version.latestDocumentVersionPublished)
  }

  let emptyVariant: EmptyVariant;
  switch (selectedTab) {
    case Options.BOOKMARKS:
      emptyVariant = EmptyVariant.FolderListBookmark
      break
    case Options.SEARCH_RESULTS:
      emptyVariant = EmptyVariant.DocumentResultsListEmpty
      break
    case Options.LIBRARY:
      emptyVariant = EmptyVariant.DocumentListEmpty
      break
    case Options.MY_UPLOADS:
      emptyVariant = EmptyVariant.DocumentListEmpty
      break
    default:
      emptyVariant = EmptyVariant.FolderListEmpty
  }

  function clearNavStack() {
    setSelectedDocumentVersion(undefined);
  }

  // On tab switch, clear the Nav Stack
  useEffect(() => { clearNavStack() }, [selectedTab]);

  const isTablet = deviceMode === 'tablet'

  return (
    <ScrollView style={{ flex: 1 }}>
      <Iffy is={!selectedDocumentVersion}>
        <DNACard
          appearance="outline"
          style={util.mergeStyles(
            undefined,
            styles.documentList,
            [{ paddingHorizontal: 64 }, !isTablet],
          )}
        >
          <DNAFlatList<(DocumentORM | DocumentVersionORM | FolderORM) >
            items={currentTab[selectedTab].items}
            variant="customDeck"
            emptyVariant={emptyVariant}
            onPress={onItemPress}
            useEmptyContainer={false}
          />
        </DNACard>
      </Iffy>
      <Iffy is={selectedDocumentVersion}>
        <DNABox fill style={{ marginHorizontal: 64 }}>
          <PageGroupList
            handleGroupSelect={handleGroupSelect}
            thumbnailSize={selectorThumbnailSize}
            groupDrafts={groupDrafts}
            documentVersionORM={selectedDocumentVersion}
          />
        </DNABox>
      </Iffy>
    </ScrollView>
  )
}

const LowerActionBar: React.FC<LowerActionBarProps> = (props) => {
  const {
    allPagesSelected,
    handleSelectAllToggle,
    selectionCount,
    pages,
    handleGroupAdd,
  } = props
  return (
    <DNABox fill style={styles.lowerActionWrapper} alignY="center">
      {/* 'Select all' check box */}
      <DNACheckbox
        checked={allPagesSelected}
        onChange={handleSelectAllToggle}
        style={{ marginRight: 7 }}
      />
      <DNAText>Select all</DNAText>
      <DNADivider
        style={{ width: 1, height: 20, marginHorizontal: 12, backgroundColor: luxColors.headerTopColor.primary }}
      />
      <DNAText>{`${selectionCount} of ${pages.length} Selected`}</DNAText>
      <DNABox fill alignX="end">
        <DNAButton
          appearance="filled"
          onPress={handleGroupAdd}
        >
          Add to presentation
        </DNAButton>
      </DNABox>
    </DNABox>
  )
}
const PresentationSelector: React.FC = () => {
  const {
    isSelectionMode,
    localSearchText,
    selectedTab,
    selectedDocumentVersion,
    searchResultsText,
    selectorThumbnailMode,
    searchText,
    pages,
    selectorThumbnailSize,
    currentTab,
    allPagesSelected,
    selectionCount,
    groupsWithSomeSlidesSelected,
    setLocalSearchText,
    setSelectedTab,
    setSearchText,
    setSelectedDocumentVersion,
    toggleSelectorThumbnailMode,
    setPageGroups,
    handleSelectGroup,
    handleSelectAllToggle,
    handleGroupAdd,
    getGroupDrafts,
  } = usePresentationSelector()

  return (
    <DNABox fill appearance="col" style={{ display: isSelectionMode ? undefined : 'none' }}>
      <Header
        localSearchText={localSearchText}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
        setSearchText={setSearchText}
        setLocalSearchText={setLocalSearchText}
      />
      <SubHeader
        selectedDocumentVersion={selectedDocumentVersion}
        searchResultsText={searchResultsText}
        setSelectedDocumentVersion={setSelectedDocumentVersion}
        selectorThumbnailMode={selectorThumbnailMode}
        toggleSelectorThumbnailMode={toggleSelectorThumbnailMode}
      />
      <Content
        selectedTab={selectedTab}
        setSelectedDocumentVersion={setSelectedDocumentVersion}
        searchText={searchText}
        selectedDocumentVersion={selectedDocumentVersion}
        setPageGroups={setPageGroups}
        handleGroupSelect={handleSelectGroup}
        selectorThumbnailSize={selectorThumbnailSize}
        currentTab={currentTab}
        groupDrafts={getGroupDrafts()}
      />
      <Iffy is={selectedDocumentVersion}>
        <LowerActionBar
          allPagesSelected={allPagesSelected}
          handleSelectAllToggle={handleSelectAllToggle}
          selectionCount={selectionCount}
          pages={pages}
          handleGroupAdd={handleGroupAdd}
          selectedGroups={groupsWithSomeSlidesSelected}
        />
      </Iffy>

    </DNABox>
  )
}

export default PresentationSelector
