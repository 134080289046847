import React, { ReactElement, useMemo, useState } from 'react'
import { Pressable, StyleProp, StyleSheet, TouchableOpacityProps, ViewStyle } from 'react-native'
import {
  DNABox,
  DNAButton, DNAChip,
  DNAText,
  GridList,
  Iffy,
  luxColors,
  Popover,
  util,
} from '@alucio/lux-ui'
import { DocumentStatus, FieldConfig, Tenant } from '@alucio/aws-beacon-amplify/src/models';
import { Variant, DocumentORM, DocumentVersionORM } from 'src/types/types';
import { SortDir, SortEntry, SortFieldType, SortMap } from '../hooks/useSort';
import DNADocumentStatusDot from 'src/components/DNA/Document/DNADocumentStatusDot'
import { ActionCallbacks, useDNADocumentActions } from '../Document/DNADocument.actions';
import DNADocumentContextMenu from '../Document/DNADocumentContextMenu/DNADocumentContextMenu';
import { DNACard } from '@alucio/lux-ui/src/components/layout/DNACard/DNACard';
import DNADocumentThumbnail from '../Document/DNADocumentThumbnail';
import DNADocumentChip from '../Document/DNADocumentChip';
import format from 'date-fns/format';
import kebabCase from 'lodash/kebabCase'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import useCurrentPage from '../hooks/useCurrentPage';

export interface ListFieldConfig {
  fieldName: string,
  altFieldName?: string,
  fieldLabel: string,
  fieldType: ('LABEL' | 'MODEL' | 'ACTION_MENU' | 'DOC_MODEL'),
  dataType?: ('string' | 'date' | 'boolean')
  width: number,
  defaultSort?: SortDir,
  fieldResolver?: (docVer: DocumentVersionORM) => any
}

export interface DNADocumentListRowProps {
  documentORM: DocumentORM,
  variant: Variant,
  isDesktop: boolean,
  isNetworkConnected: boolean,
  onPress?: (doc: DocumentORM) => void,
  onThumbnailPress?: (doc: DocumentORM) => void,
  actionCallbacks?: ActionCallbacks,
  fields: ListFieldConfig[],
  cellStyle?: StyleProp<ViewStyle>
  rowStyle?: StyleProp<ViewStyle>
}

const VALUE_MAX_TEXT_LINES = 5

export const SYSTEM_FIELD_CONFIGS: Record<string, ListFieldConfig> = {
  'ACTION_MENU': {
    fieldName: 'action',
    fieldLabel: '',
    fieldType: 'ACTION_MENU',
    width: 32,
  },
  'ALLOW_HIDE_SLIDES': {
    dataType: 'boolean',
    fieldName: 'canHideSlides',
    fieldLabel: 'Hide Slides',
    fieldType: 'MODEL',
    width: 65,
  },
  'DOC_CREATED_AT': {
    dataType: 'date',
    fieldName: 'createdAt',
    fieldLabel: 'Created At',
    fieldType: 'DOC_MODEL',
    width: 100,
  },
  'DOC_CREATED_BY': {
    dataType: 'string',
    fieldName: 'createdBy',
    fieldLabel: 'Created By',
    fieldType: 'DOC_MODEL',
    width: 240,
  },
  'DISTRIBUTABLE': {
    dataType: 'boolean',
    fieldName: 'distributable',
    fieldLabel: 'Distributable',
    fieldType: 'MODEL',
    width: 85,
  },
  'PUBLISHER_DOC_UPDATED_AT': {
    dataType: 'date',
    fieldName: 'publishedAt',
    altFieldName: 'createdAt',
    fieldResolver: (docVersion: DocumentVersionORM) => {
      const latestPublished = docVersion.relations.document.relations.version.latestDocumentVersionPublished
      // For Publisher list date column we have to pick the date based on certain conditions:
      // - If the doc is sealed use the publishedAt value
      // - If the latest doc version is a draft in progress use the createdAt value
      // - If the latest doc version is published use the publishedAt value
      const value = docVersion.meta.sealedStatus
        ? latestPublished?.model.publishedAt
        : docVersion.model.status !== 'PUBLISHED'
          ? docVersion.model.createdAt
          : docVersion.model.publishedAt

      return value
    },
    fieldLabel: 'Date',
    fieldType: 'MODEL',
    width: 100,
  },
  'DOC_UPDATED_AT': {
    dataType: 'date',
    fieldName: 'publishedAt',
    fieldLabel: 'Date',
    fieldType: 'MODEL',
    width: 100,
  },
  'DOC_UPDATED_BY': {
    dataType: 'string',
    fieldName: 'updatedBy',
    fieldLabel: 'Last Updated By',
    fieldType: 'MODEL',
    width: 240,
  },
  'EXPIRES_AT': {
    dataType: 'date',
    fieldName: 'expiresAt',
    fieldLabel: 'Expiration',
    fieldType: 'MODEL',
    width: 100,
  },
  'FILE_TYPE': {
    dataType: 'string',
    fieldName: 'type',
    fieldLabel: 'Type',
    fieldType: 'MODEL',
    width: 50,
  },
  'ID': {
    dataType: 'string',
    fieldName: 'id',
    fieldLabel: 'ID',
    fieldType: 'DOC_MODEL',
    width: 80,
  },
  'LABEL_STATUS_DOT': {
    dataType: 'string',
    fieldName: 'labelStatusDot',
    fieldLabel: '',
    fieldType: 'MODEL',
    width: 8,
  },
  'OWNER': {
    dataType: 'string',
    fieldName: 'owner',
    fieldLabel: 'Business Owner',
    fieldType: 'MODEL',
    width: 240,
  },
  'PURPOSE': {
    dataType: 'string',
    fieldName: 'purpose',
    fieldLabel: 'Purpose',
    fieldType: 'MODEL',
    width: 80,
  },
  'STATUS': {
    dataType: 'string',
    fieldName: 'status',
    fieldLabel: 'Status',
    fieldType: 'DOC_MODEL',
    width: 125,
  },
  'THUMBNAIL': {
    fieldName: 'thumbnail',
    fieldLabel: '',
    fieldType: 'MODEL',
    width: 89,
  },
  'TITLE': {
    dataType: 'string',
    fieldName: 'title',
    fieldLabel: 'Name',
    fieldType: 'MODEL',
    width: 384,
  },
  'CONTENT_SOURCE': {
    dataType: 'string',
    fieldName: 'source',
    fieldLabel: 'Content Source',
    fieldType: 'MODEL',
    width: 120,
    fieldResolver: (docVersion: DocumentVersionORM) => docVersion.meta.integration.source,
  },

}

const S = StyleSheet.create({
  documentThumbnailBorder: {
    borderWidth: 1,
    borderColor: luxColors.border.primary,
  },
  hoveredRow: {
    backgroundColor: luxColors.mainContainer.primary,
  },
  unPublishedVersionStatus: {
    borderWidth: 2,
    borderColor: luxColors.warning.quaternary,
  },

});

export const getSortConfigForFields = (fieldConfigs: ListFieldConfig[]): SortMap => {
  const initSort: SortMap = {}
  fieldConfigs.filter(field => field).forEach((field) => {
    if (field.fieldType === 'LABEL' || field.fieldType === 'MODEL' || field.fieldType === 'DOC_MODEL') {
      const sortConfig: SortEntry = {
        key: field.fieldName,
        fieldResolver: field.fieldResolver,
        fieldType: field.fieldType === 'LABEL' ? SortFieldType.label
          : field.fieldType === 'MODEL' ? SortFieldType.ver_model : SortFieldType.doc_model,
        label: field.fieldLabel,
        dir: field.defaultSort ? field.defaultSort : SortDir.none,
      }
      if (sortConfig.dir !== SortDir.none) { sortConfig.icon = SortDir.desc ? 'menu-down' : 'menu-up' }

      initSort[field.fieldName] = sortConfig
    }
  })
  return initSort;
}

const getFieldConfigForCustomField = (field: FieldConfig): ListFieldConfig => {
  return {
    fieldName: field.fieldName,
    fieldType: 'LABEL',
    fieldLabel: field.fieldName,
    width: 240,
  }
}

export const getFieldConfigsForTenant = (
  tenant: Tenant | undefined,
  variant: Variant,
  isDesktop: boolean,
): ListFieldConfig[] => {
  let fieldConfig: ListFieldConfig[] = [];

  // We lock the first couple of columns to ensure consistent UI
  const addContentSource = tenant && tenant.integrations && tenant.integrations.length > 0
  const omitFields = addContentSource ? [] : ['CONTENT_SOURCE']
  const systemFields = cloneDeep(omit(SYSTEM_FIELD_CONFIGS, omitFields)) as Record<string, ListFieldConfig>
  if (!isDesktop) {
    systemFields.ACTION_MENU.width = 60;
    systemFields.THUMBNAIL.width = 90;
  }
  if (variant === Variant.MSL) {
    const actionMenu = {
      ...systemFields.ACTION_MENU,
      width: !isDesktop ? 140 : 100,
    };
    fieldConfig.push(actionMenu);
    fieldConfig.push(systemFields.THUMBNAIL);
    fieldConfig.push(systemFields.TITLE);
    fieldConfig.push(systemFields.PURPOSE);
    addContentSource && fieldConfig.push(systemFields.CONTENT_SOURCE);
  } else {
    fieldConfig.push(systemFields.THUMBNAIL);
    fieldConfig.push(systemFields.ACTION_MENU);
    fieldConfig.push(systemFields.LABEL_STATUS_DOT);
    fieldConfig.push(systemFields.TITLE);
    fieldConfig.push(systemFields.PURPOSE);
    fieldConfig.push(systemFields.STATUS);
    addContentSource && fieldConfig.push(systemFields.CONTENT_SOURCE);
  }
  const customConfig =
    variant === Variant.MSL
      ? tenant?.mslListConfig
      : tenant?.publisherListConfig;
  if (customConfig) {
    const customFields = customConfig.fields.map(
      (field): ListFieldConfig => {
        let config: ListFieldConfig;
        if (systemFields[field.fieldName]) {
          if (variant === Variant.publisher && field.fieldName === 'DOC_UPDATED_AT') {
            config = systemFields.PUBLISHER_DOC_UPDATED_AT;
          } else {
            config = systemFields[field.fieldName];
          }
        } else {
          const tenantFieldConfig = tenant?.fields?.find(
            (label) => label.fieldName === field.fieldName
          );
          if (!tenantFieldConfig) {
            throw new Error(`Invalid tenant list configuration field \
          ${field.fieldName} not found in tenant's field configuration`);
          }
          config = getFieldConfigForCustomField(tenantFieldConfig);
        }
        config.width = field.width ? field.width : config.width;
        if (config.width < 150) {
          config.width = 150;
        }
        config.fieldLabel = field.header ? field.header : config.fieldLabel;
        return config;
      },
    );
    fieldConfig = [...fieldConfig, ...customFields];
    if (customConfig.sort) {
      const sortFieldName = systemFields[customConfig.sort.field]
        ? systemFields[customConfig.sort.field].fieldName
        : customConfig.sort.field;
      const sortField = fieldConfig.find(
        (field) => field.fieldName === sortFieldName,
      );
      if (!sortField) {
        throw new Error(`Invalid tenant list configuration sort field \
        ${customConfig.sort.field} not found in available list fields`);
      }
      sortField.defaultSort = customConfig.sort.isAsc
        ? SortDir.asc
        : SortDir.desc;
    }
  } else {
    const defaultTenantFields = (
      tenant?.fields?.filter((field) => !!field.defaultSearchFilter) ?? []
    ).map(getFieldConfigForCustomField);

    if (variant === Variant.MSL) {
      fieldConfig = [...fieldConfig, ...defaultTenantFields];
      fieldConfig.push({
        ...systemFields.DOC_UPDATED_AT,
        defaultSort: SortDir.desc,
      });
    } else {
      fieldConfig = [...fieldConfig, ...defaultTenantFields];
      fieldConfig.push(systemFields.EXPIRES_AT);
      fieldConfig.push({
        ...systemFields.PUBLISHER_DOC_UPDATED_AT,
        defaultSort: SortDir.desc,
      });
    }
  }
  return fieldConfig;
};

interface FieldHeaderProps {
  field: ListFieldConfig,
  toggleSort: (string) => void
  sortIcon: ('menu-down' | 'menu-up' | undefined),
}

export const FieldHeader: React.FC<FieldHeaderProps & TouchableOpacityProps> = (props) => {
  const { field, sortIcon, toggleSort, ...rest } = props
  return (
    <GridList.Header {...rest}>
      <Iffy is={field.fieldLabel.length > 0}>
        <DNAButton
          appearance="ghost"
          context="minimum"
          status="neutral"
          onPress={() => toggleSort(field.fieldName)}
          iconRight={sortIcon}
          testID={'header-sort-' + kebabCase(field.fieldName)}
        >
          {field.fieldLabel}
        </DNAButton>
      </Iffy>
    </GridList.Header>
  )
}

export const DocumentListRow: React.FC<DNADocumentListRowProps> = (props) => {
  const {
    documentORM,
    isDesktop,
    isNetworkConnected,
    fields,
    variant,
    cellStyle,
    rowStyle,
    onPress,
    onThumbnailPress,
    actionCallbacks,
  } = props
  const route = useCurrentPage()
  const documentActions = useDNADocumentActions()
  const [isHovered, setIsHovered] = useState<boolean>(false);
  // [TODO-2126] - Rather than checking network status and the isContentCached key here
  //               we should just expose a property on the Version ORM that combines both
  //               Something like "isUnavailable"
  const unavailableContent =
    !isNetworkConnected &&
    !documentORM.relations.version.cachedDocumentVersion?.meta.assets.isContentCached

  const MemoizedActionButtons = useMemo(() => (
    <DNABox alignX="center">
      <Iffy is={variant === Variant.MSL}>
        <DNABox style={{ width: 32, height: 32 }}>
          <Iffy is={!unavailableContent}>
            <DNAButton
              appearance="ghostAlt"
              status={!isDesktop && documentORM.meta?.bookmark?.isBookmarked ? 'primary' : 'subtle'}
              context={isDesktop ? 'moderate' : 'roundTablet'}
              onPress={documentActions.bookmark(documentORM)}
              iconLeft={documentORM.meta?.bookmark?.isBookmarked
                ? 'bookmark'
                : 'bookmark-outline'
              }
              size={isDesktop ? undefined : 'xlarge'}
            />
          </Iffy>
        </DNABox>
      </Iffy>
      <DNADocumentContextMenu
        documentORM={documentORM}
        variant={variant}
        actionCallbacks={actionCallbacks}
      >
        <DNAButton
          appearance="ghostAlt"
          status="subtle"
          context={isDesktop ? 'moderate' : 'roundTablet'}
          iconLeft="dots-vertical"
          style={{ width: 32, height: 32 }}
          size={isDesktop ? undefined : 'xlarge'}
        />
      </DNADocumentContextMenu>
    </DNABox>
  ), [documentORM, isDesktop, variant, unavailableContent]);

  function onMouseEnter(): void {
    setIsHovered(true);
  }

  function onMouseLeave(): void {
    setIsHovered(false);
  }
  const safeOnPress = () => {
    if (onPress) {
      onPress(documentORM)
    }
  }
  const safeOnThumbnailPress = () => {
    if (onThumbnailPress) {
      onThumbnailPress(documentORM)
    }
  }

  const getFormattedModelValue = (field: ListFieldConfig) => {
    const value = field.fieldType === 'MODEL'
      ? (field.fieldResolver
        ? field.fieldResolver(documentORM.relations.version.latestDocumentVersion)
        : documentORM.relations.version.latestUsableDocumentVersion?.model[field.fieldName])
      : documentORM.model[field.fieldName]

    switch (field.dataType) {
      case 'date':
        return value ? format(new Date(value), 'MM/dd/yyyy') : ''
      case 'boolean':
        return value ? 'Yes' : 'No'
      default:
        return value;
    }
  }

  const modelFieldRenderer = (field: ListFieldConfig) => {
    function getRegularFieldComponent(
      field: ListFieldConfig): ReactElement {
      return (
        <DNAText
          numberOfLines={VALUE_MAX_TEXT_LINES}
          testID={`document-gridlist-${field.fieldLabel.toLowerCase()}`}
        >
          {getFormattedModelValue(field)}
        </DNAText>
      );
    }

    if (field.fieldName === 'status') {
      return documentORM.meta.hasUnpublishedVersion
        ? <Popover key={documentORM.model.id}>
          <Popover.Anchor>
            <DNABox
              style={util.mergeStyles(
                undefined,
                [S.unPublishedVersionStatus,
                  (documentORM.meta.hasUnpublishedVersion && documentORM.model.status === DocumentStatus.PUBLISHED)],
              )}
            >
              <DNADocumentChip
                item={documentORM}
                variant="status"
              />
            </DNABox>
          </Popover.Anchor>
          <Popover.Content offset={-60}>
            <DNAText numberOfLines={1} style={{ color: 'white' }}>New version in progress</DNAText>
          </Popover.Content>
        </Popover>
        : <DNADocumentChip
            item={documentORM}
            variant="status"
        />
    } else if (field.fieldName === 'purpose') {
      return (
        <Iffy is={documentORM.relations.version.latestUsableDocumentVersion?.model.purpose}>
          <DNADocumentChip
            item={documentORM}
            variant="purpose"
          />
        </Iffy>
      )
    } else if (field.fieldName === 'thumbnail') {
      const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')

      const documentVersion = documentORM.relations.version.latestUsableDocumentVersion

      const canPresent = documentORM.meta.permissions.MSLPresent
      const defaultOnPress = canPresent ? documentActions.present(documentORM) : documentActions.preview(documentORM)

      const handleThumbnailPress = () => {
        return isPublisherRoute ? safeOnThumbnailPress() : defaultOnPress()
      }

      return (
        <Pressable onPress={safeOnThumbnailPress} >
          <DNABox style={S.documentThumbnailBorder}>
            <DNADocumentThumbnail
              width={85}
              height={48}
              documentVersionORM={documentVersion}
              showProcessing={variant === Variant.publisher}
              unavailableContent={unavailableContent}
              /** Custom behavior here. Thumbs in Publisher routes just open the preview modal,
               * whereas MSL views will either present or preview based on whether a doc is internal */
              onPress={handleThumbnailPress}
            />
          </DNABox>
        </Pressable>
      )
    } else if (field.fieldName === 'labelStatusDot') {
      return (
        <DNADocumentStatusDot documentORM={documentORM} />
      )
    } else if (field.fieldName === 'title') {
      return (
        <DNABox appearance="col" fill spacing="tiny" childStyle={[1, { flexDirection: 'row' }]}>
          {getRegularFieldComponent(field)}
          <Iffy is={unavailableContent}>
            <DNAChip appearance="tag" style={{ backgroundColor: luxColors.basicBlack.primary }}>
              CONTENT NOT AVAILABLE
            </DNAChip>
          </Iffy>
        </DNABox>
      )
    } else {
      return getRegularFieldComponent(field);
    }
  }

  return (
    // @ts-ignore [todo] Extend As Props to Container Props
    <GridList.Row
      as={DNACard}
      appearance="flat"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onFocus={onMouseLeave}
      interactive="hoverable"
      onPress={safeOnPress}
      style={
        util.mergeStyles(
          undefined,
          [S.hoveredRow, isHovered],
          rowStyle,
          { opacity: unavailableContent ? 0.4 : 1, cursor: 'pointer' })
      }
    >
      {
        fields?.map(field => (
          <GridList.Col key={field.fieldName} style={cellStyle}>
            <Iffy is={field.fieldType === 'ACTION_MENU'}>
              {MemoizedActionButtons}
            </Iffy>
            <Iffy is={field.fieldType === 'MODEL' || field.fieldType === 'DOC_MODEL'}>
              {modelFieldRenderer(field)}
            </Iffy>
            <Iffy is={field.fieldType === 'LABEL'}>
              <DNAText
                numberOfLines={VALUE_MAX_TEXT_LINES}
                testID={`document-gridlist-${field.fieldLabel.toLowerCase()}`}
              >
                {documentORM.meta.tenantFields.valuesMap[field.fieldName]?.join(', ')}
              </DNAText>
            </Iffy>
          </GridList.Col>
        ))
      }
    </GridList.Row>
  )
}
