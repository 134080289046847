import React, { forwardRef } from 'react';
import { StyleSheet } from 'react-native'
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const S = StyleSheet.create({
  sortableWrapper: {
    display: 'flex',
    boxSizing: 'border-box',
    transformOrigin: '0 0',
    // See https://docs.dndkit.com/api-documentation/sensors/touch#recommendations
    touchAction: 'manipulation',
  },
})

type ItemProps = {
  style?: React.CSSProperties,
  children: React.ReactNode,
}

export const Item = forwardRef<HTMLImageElement, ItemProps>((props, ref) => {
  const { style, children, ...restProps } = props
  // [TODO-928] - Try to support <DNABox> instead of <div>
  return (
    <div ref={ref} style={style} {...restProps}>
      { children }
    </div>
  );
});

export const SortableItem: React.FC<{
  id: string,
  style?: React.CSSProperties,
  visible?: boolean,
}> = (props) => {
  const { id, visible = true, style, children } = props

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  if (!visible) return null

  const sortableStyle = {
    ...S.sortableWrapper,
    opacity: isDragging ? 0.3 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  } as React.CSSProperties;

  const finalStyle = StyleSheet.flatten([
    sortableStyle,
    style,
  ]) as React.CSSProperties

  return (
    <Item
      ref={setNodeRef}
      style={finalStyle}
      {...attributes}
      {...listeners}
    >
      { children }
    </Item>
  );
}
