import React, { useMemo, useContext, createContext, useRef } from 'react';
import { State, Interpreter } from 'xstate'
import { useMachine } from '@xstate/react'

import { DNABox, DNAText, DNAButton } from '@alucio/lux-ui'
import updateVersionMachine, {
  StateMeta,
  StateTags,
  MetaValues,
  UpdateVersionContext,
  UpdateVersionState,
  UpdateVersionEvents,
} from 'src/state/machines/versioning/updateVersionRedesign'
import { DocumentORM, DocumentVersionORM } from 'src/types/types'
import { ConversionStatus } from '@alucio/aws-beacon-amplify/src/models';
import Error from 'src/components/DNA/Error'

// import { inspect } from '@xstate/inspect';
// inspect({ iframe: false });

enum ConditionalEnum {
  hasOptimizedFinishedThisSession,
  hasInfoMessage,
  isCriticalError,
  isCancellingNewVersionUpload,
  isUploadingNewVersion,
  isPublishedViewMode,
  isProcessingNewVersion,
  isInDraftingState,
  isDraftDirty,
  isCreatingFromExisting,
  canCreateFromExisting,
  canCreateFromUpload,
  canCreateNewVersion,
  canPublish,

  isNavBlocked,
  hasProcessingError,
  isModifying,
  isDocumentPublished,
  isExitBlocked,
  isPublishing,
}

type StateConditions = { [K in keyof typeof ConditionalEnum]: boolean }

/**
 * Context
 */
type VersioningPanelContextValue = {
  // Is there a better way to infer this? ReturnType<typeof useMachine<YourGenericsHere?>>
  state: State<UpdateVersionContext, UpdateVersionEvents, any, UpdateVersionState>,
  meta: Partial<MetaValues>,
  send: Interpreter<UpdateVersionContext, any, UpdateVersionEvents, UpdateVersionState>['send'],
  currentDocumentVersionORM: DocumentVersionORM,
  latestPublishedDocumentVersionORM?: DocumentVersionORM,
  documentORM: DocumentORM,
  documentVersionORM: DocumentVersionORM,
  toggleSlider: (onInvisCb?: () => void) => void,
  cond: StateConditions
}
const VersioningPanelContext = createContext<VersioningPanelContextValue>(null!)

const VersioningPanelProvider: React.FC<{
  documentORM: DocumentORM,
  toggleSlider: () => void,
  initialDocumentId?: string,
}> = (props) => {
  const { toggleSlider, documentORM, children } = props
  const latestDocumentVersion: DocumentVersionORM = documentORM.relations.version.latestDocumentVersion;
  const machine = useRef(updateVersionMachine(latestDocumentVersion.model)).current

  const [state, send] = useMachine(machine, { devTools: true })

  const meta = Object
    .values(state.meta as Record<string, StateMeta>)
    .reduce<Partial<MetaValues>>((acc, meta) => ({ ...acc, ...meta }), {} as Partial<MetaValues>)

  const currentDocumentVersionORM = useMemo(
    () => {
      const targetVersionORM = documentORM
        .relations
        .documentVersions
        .find(docVerORM => docVerORM.model.id === state.context.documentVersionId)

      // [TODO-2126] - This is a workaround for concurrent draft deletion
      //               There will be a few frames where the targetDocVer will be undefined due to
      //               the machine not being able to properly update its state in time
      //             - So we fall back to the latest published for those few frames
      //               and then once the machine state changes, it should re-correct itself to using
      //               and be able to find the targetVersionORM
      //             - See VERSION_UPDATE isDraftDeleted in the machine for more details

      if (!targetVersionORM) {
        send({ type: 'DELETE_DRAFT' })
      }

      return targetVersionORM ?? documentORM.relations.version.latestDocumentVersionPublished
    },
    [documentORM, state.context.documentVersionId],
  )

  const latestPublishedVersion = documentORM.relations.version.latestDocumentVersionPublished

  const hasOptimizedFinishedThisSession = state.context.hasOptimizedFinishedThisSession
  const hasInfoMessage = !!meta.infoMessage
  const isUploadingNewVersion = state.matches('draft.documentInfo.processing.upload.uploading')
  const isPublishedViewMode = state.matches('published')
  const isInDraftingState = !isPublishedViewMode

  // [TODO-2126] - We should centralize the interface for critical errors
  //               Whether it's tags/state nodes/etc
  const isCriticalError = currentDocumentVersionORM?.model.conversionStatus === ConversionStatus.ERROR
  const isProcessingNewVersion = state.matches('draft.documentInfo.processing')
  const isCreatingFromExisting = state.matches('draft.documentInfo.processing.existing.optimizing')
  const canCreateFromExisting = state.can({ type: 'CREATE_FROM_EXISTING' })
  // @ts-ignore - We don't need to have a valid File Payload to do this check
  const canCreateFromUpload = state.can({ type: 'CREATE_FROM_UPLOAD', payload: { file: undefined } })
  const canCreateNewVersion = canCreateFromExisting || canCreateFromUpload
  const isCancellingNewVersionUpload = state.context.cancelUpload
  const canPublish = state.can({ type: 'PUBLISH_VERSION', payload: { } })

  // [TODO-2126] - Conslidate our forms at the component/tab level and thus our dirty flags
  const isDraftDirty = (
    state.context.documentInfoIsDirty ||
    state.context.documentSettingsIsDirty ||
    state.context.documentPublishIsDirty
  )

  const isExitBlocked = state.hasTag(StateTags.DISABLE_EXIT)
  const isNavBlocked = state.hasTag(StateTags.DISABLE_NAV)
  const hasProcessingError = state.hasTag(StateTags.PROCESSING_ERROR)
  const isModifying = state.hasTag(StateTags.DISABLE_MODIFY)
  const isDocumentPublished = documentORM.model.status === 'PUBLISHED'
  const isPublishing = state.matches('draft.documentPublish.publishing')

  const cond: StateConditions = {
    hasOptimizedFinishedThisSession,
    hasInfoMessage,
    isCriticalError,
    isCancellingNewVersionUpload,
    isUploadingNewVersion,
    isPublishedViewMode,
    isProcessingNewVersion,
    isInDraftingState,
    isDraftDirty,
    isCreatingFromExisting,
    canCreateFromExisting,
    canCreateFromUpload,
    canCreateNewVersion,
    canPublish,

    isNavBlocked,
    hasProcessingError,
    isModifying,
    isDocumentPublished,
    isExitBlocked,
    isPublishing,
  }

  // [NOTE] - This would happen if we have a Document with no child versions
  if (!currentDocumentVersionORM)
  { return (
    <DNABox
      fill
      appearance="col"
      alignX="center"
      alignY="center"
      style={{ backgroundColor: 'white' }}
    >
      <Error
        promptLogout={false}
        message="Could not open Document for versioning!"
      >
        <DNAText>{ documentORM.model.id }</DNAText>
        <DNAButton
          appearance="ghost"
          onPress={toggleSlider}
        >
          Go back
        </DNAButton>
      </Error>
    </DNABox>
  ) }

  return (
    <VersioningPanelContext.Provider
      value={{
        documentORM,
        latestPublishedDocumentVersionORM: latestPublishedVersion,
        currentDocumentVersionORM: currentDocumentVersionORM,
        documentVersionORM: latestDocumentVersion,
        send,
        state,
        meta,
        toggleSlider,
        cond,
      }}
    >
      { children }
    </VersioningPanelContext.Provider>
  )
}

export const useVersioningPanel = () => useContext(VersioningPanelContext)
export default VersioningPanelProvider
