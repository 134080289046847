import im from 'immer';

/**
 * Returns an object with the keys as the given idx
 * @param array: Array
 * @param idx: String
 */
export function indexArray<T>(array: T[], idx: string) {
  return array.reduce((acc, item) => {
    acc[item[idx]] = item;
    return acc;
  }, {});
}

/**
 * [TODO] - Consider just using `sortCollection` instead
 *
 * Returns an array ordered by the given fields
 * @param array: Array
 * @param keys: String[]
 * @param ascendingOrder: Boolean[]
 */
export function orderArray<T>(array: T[], keys: string[] = [], ascendingOrder: boolean[] = []): T[] {
  function sort(a, b): number {
    let result = 0;

    function sortByKey(key: string, ascendingOrder: boolean = true): number {
      const ascendingValue = ascendingOrder ? 1 : -1;
      const numeric = typeof a[key] === 'number';
      const isBoolean = typeof a[key] === 'boolean';

      if (isBoolean) {
        if (a[key] === b[key]) {
          return 0;
        }
        return (a[key] > b[key] ? 1 : -1) * ascendingValue;
      }

      return a[key].toString().localeCompare(b[key], 'en', { numeric }) * ascendingValue;
    }

    keys.forEach((key, index) => {
      if (result === 0) {
        result = sortByKey(key, ascendingOrder[index])
      }
    });

    return result;
  }

  return im(array, recipe => recipe.sort(sort)) as T[];
}
