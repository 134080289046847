import { DNAGridCards } from 'src/components/DNA/Grid/Cards/types'

import DNADocumentDefaultCard from './DNADocumentDefaultCard'
import DNADocumentFolderItemCard from './DNADocumentFolderItemCard'
import DNAFolderDefaultCard from './DNAFolderDefaultCard'
import DNACustomDeckCard from './DNACustomDeckCard'

export const DNADefaultRows: DNAGridCards = {
  DNADocumentCard: DNADocumentDefaultCard,
  DNACustomDeckCard: DNACustomDeckCard,
  DNADocumentFolderItemCard: DNADocumentFolderItemCard,
  DNAFolderCard: DNAFolderDefaultCard,
}

export default DNADefaultRows
