import React from 'react'
import { StyleSheet, ViewStyle } from 'react-native'
import im from 'immer'

import {
  DNABox,
  DNAButton,
  DNAText,
  DNAContextMenu,
  Stack,
  DNAIcon, util,
} from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'

import {
  PayloadGroup,
  usePresentationBuilderState,
  GroupStatus,
} from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider'
import { CustomDeckORM } from 'src/types/orms'

const S = StyleSheet.create({
  opaqueBackground: {
    backgroundColor: colors['color-dark-transparent-700'],
  },
  containerMargin: {
    margin: 8,
  },
  flexButton: {
    flex: 1,
    margin: 'auto',
    maxWidth: 384,
  },
})

export const notPublishedStatus = {
  [GroupStatus.REVOKED]: true,
  [GroupStatus.DELETED]: true,
  [GroupStatus.ARCHIVED]: true,
}

const WarningButton: React.FC<{
  onPress: () => void,
  label: string,
  icon: string,
}> = (props) => {
  const { onPress, icon, label } = props
  const { displayPageSourceFile } = usePresentationBuilderState();

  return (
    <DNAButton
      status="warning"
      context="moderate"
      iconLeft={icon}
      iconStyle={{ marginRight: 4 }}
      onPress={onPress}
      style={util.mergeStyles(
        undefined,
        S.flexButton,
        [{ marginBottom: 40 }, displayPageSourceFile],
      )}
    >
      { label }
    </DNAButton>
  )
}

// [TODO-928]
//  - This would be useful in the context level
//    -or-
// - Put it in the useSlide hook, but then it requires prop drilling which I think we should avoid for now
// - Current performance impact is pretty negligble, so not a big deal right now
const canRemoveGroup = (group: PayloadGroup, selectedGroups: PayloadGroup[]): boolean => {
  const pageIdRequiredCountMap = group.documentVersion?.model?.pages.reduce<Record<string, number>>(
    (acc, page) => {
      if (page.isRequired) {
        const selectedRequiredCount = selectedGroups.reduce<number>(
          (acc, grp) => {
            if (grp.documentVersion === group.documentVersion) {
              grp.pages.forEach(pg => {
                if (pg.isRequired && pg.pageId === page.pageId)
                { acc++ }
              })
            }

            return acc
          },
          0,
        )

        acc[page.pageId] = selectedRequiredCount
      }
      return acc
    },
    { }
  ) ?? { }

  const canRemove = group.pages.every(page => {
    const requiredCount = pageIdRequiredCountMap[page.pageId]

    return requiredCount !== undefined
      ? requiredCount > 1
      : true
  })

  return canRemove
}

const onRemove = (
  group: PayloadGroup,
  setSelectedGroups: React.Dispatch<React.SetStateAction<PayloadGroup[]>>,
  customDeck?: CustomDeckORM,
) => () => {
  group.pages.forEach((page) => {
    analytics?.track('CUSTOM_SLIDE_REMOVED', {
      action: 'SLIDE_REMOVED',
      category: 'CUSTOM',
      customDeckId: customDeck?.model.id,
      groupId: group.id,
      pageId: page.pageId,
    });
  })
  setSelectedGroups((groups) => groups.filter((grp) => grp.id !== group.id))
}

export const GroupItemContextMenu: React.FC<{ group: PayloadGroup }> = (props) => {
  const { group } = props
  const { selectedGroups, setSelectedGroups, customDeck } = usePresentationBuilderState()

  const onHide = () => {
    setSelectedGroups(
      groups => im(groups, draft => {
        draft.forEach(grp => {
          if (grp.id === group.id) {
            const action = grp.visible ? 'HIDE' : 'UNHIDE';
            grp.pages.forEach((page) => {
              analytics?.track(`CUSTOM_SLIDE_${action}`, {
                action: `SLIDE_${action}`,
                category: 'CUSTOM',
                customDeckId: customDeck?.model.id,
                groupId: group.id,
                pageId: page.pageId,
              });
            });
            grp.visible = !grp.visible;
          }
        })
      }),
    )
  }

  const canRemove = canRemoveGroup(group, selectedGroups)

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        <DNAButton
          context="square"
          iconLeft="dots-vertical"
          size="small"
          status="shade"
        />
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        <DNAContextMenu.Item
          title={ group.visible ? 'Hide' : 'Unhide'}
          icon={ group.visible ? 'eye-off-outline' : 'eye-outline'}
          onPress={onHide}
        />
        {
          canRemove &&
            <DNAContextMenu.Item
              title="Remove"
              icon="trash-can-outline"
              onPress={onRemove(group, setSelectedGroups, customDeck)}
            />
        }
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export const FindAndReplaceOverlay: React.FC<{ group: PayloadGroup }> = (props) => {
  const { group } = props
  const {
    setSelectedGroups,
    setActiveReplacementGroup,
    handleModeChange,
  } = usePresentationBuilderState()

  const onFindAndReplace = () => {
    setActiveReplacementGroup(group)
    handleModeChange('replace')
  }

  return (
    <DNABox
      fill
      appearance="col"
      spacing="between"
      style={S.containerMargin}
    >
      <DNABox alignX="end">
        <DNAButton
          iconLeft="trash-can-outline"
          status="shade"
          context="square"
          size="xlarge"
          onPress={onRemove(group, setSelectedGroups)}
        />
      </DNABox>
      <WarningButton
        icon="alert"
        label="Find & replace"
        onPress={onFindAndReplace}
      />
    </DNABox>
  )
}

export const StatusChangedOverlay: React.FC<{ group: PayloadGroup }> = (props) => {
  const { group } = props
  const { setSelectedGroups } = usePresentationBuilderState()

  return (
    <DNABox
      fill
      appearance="col"
      alignY="end"
      spacing="medium"
      style={S.containerMargin}
    >
      <DNABox fill alignX="center" alignY="end">
        <DNAText bold b1 status="basic">
          Source file was {group.groupStatus.toLowerCase()}
        </DNAText>
      </DNABox>

      <WarningButton
        label="Remove"
        icon="trash-can-outline"
        onPress={onRemove(group, setSelectedGroups)}
      />
    </DNABox>
  )
}

export const GroupItemOverlay: React.FC<{
  style?: ViewStyle,
  group: PayloadGroup,
  enabled?: boolean,
}> = (props) => {
  const { group, style, enabled, children } = props

  const isNotPublished = !!notPublishedStatus[group.groupStatus]
  const isFindAndReplace = group.groupStatus === GroupStatus.MAJOR_UPDATE
  const showItemOverlay = isFindAndReplace || isNotPublished

  const finalStyle = StyleSheet.flatten([
    { padding: 4 },
    style,
  ])

  if (!enabled)
  { return children as React.ReactElement }

  const isOpaqueScenario = (!group.visible || isNotPublished || isFindAndReplace)

  return (
    <DNABox style={finalStyle}>
      <Stack>
        {/* Children - Base Layer */}
        <Stack.Layer>
          { children }
        </Stack.Layer>

        {/* Opaque background for multiple scenarios */}
        {
          isOpaqueScenario &&
            <Stack.Layer fill>
              <DNABox
                fill
                alignX="center"
                alignY="center"
                style={S.opaqueBackground}
              />
            </Stack.Layer>
        }

        {/* Hidden Group Overlay */}
        {
          !group.visible &&
            <Stack.Layer fill>
              <DNABox
                fill
                alignX="center"
                alignY="center"
              >
                <DNAIcon.Styled
                  name="eye-off-outline"
                  size="xlarge"
                />
              </DNABox>
            </Stack.Layer>
        }

        {/* Status Changed Overlay */}
        {
          isNotPublished &&
            <Stack.Layer fill>
              <StatusChangedOverlay group={group} />
            </Stack.Layer>
        }

        {/* Find & Replace Overlay */}
        {
          isFindAndReplace &&
            <Stack.Layer fill>
              <FindAndReplaceOverlay group={group}/>
            </Stack.Layer>
        }

        {/* Context Menu */}
        {
          !showItemOverlay &&
            <Stack.Layer anchor="topRight">
              <DNABox style={S.containerMargin}>
                <GroupItemContextMenu group={group}/>
              </DNABox>
            </Stack.Layer>
        }
      </Stack>
    </DNABox>
  )
}
