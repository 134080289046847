import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNADivider, DNASpinner, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { PERFORMING_ACTION, SUBMITTING_STATUS } from './EmailTemplateDrawer';
import { EmailTemplate, EmailTemplateStatus } from '@alucio/aws-beacon-amplify/src/models';

const styles = StyleSheet.create({
  content: {
    backgroundColor: colors['color-text-basic'],
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
});

interface Props {
  canActivate?: boolean,
  closeDrawer: () => void,
  emailTemplate?: EmailTemplate,
  isFormDirty?: boolean,
  onActivate: (action: PERFORMING_ACTION) => void,
  submittingStatus: SUBMITTING_STATUS,
  onPreview: () => void,
  onDelete: () => void,
}

const EmailDrawerFooter = (props: Props) => {
  const isSuccess = props.submittingStatus === SUBMITTING_STATUS.SUCCESS;
  const isSubmitting = props.submittingStatus === SUBMITTING_STATUS.SUBMITTING;

  function onActivate(): void {
    props.onActivate(PERFORMING_ACTION.ACTIVATING);
  }

  function onSaveDraft(): void {
    props.onActivate(props.emailTemplate ? PERFORMING_ACTION.SAVE_EXISTING : PERFORMING_ACTION.FIRST_DRAFT);
  }

  function updateTemplate(): void {
    props.onActivate(PERFORMING_ACTION.SAVE_EXISTING);
  }

  function onDelete(): void {
    props.onDelete()
  }

  return (
    <DNABox appearance="col" alignY="end">
      <DNADivider />
      <DNABox spacing="between" alignY="center" style={styles.content}>
        <DNABox spacing="tiny" fill>
          <Iffy is={props.emailTemplate?.id}>
            <DNAButton
              appearance="filled"
              size="xlarge"
              status="flat"
              context="moderate"
              style={{ paddingVertical:5 }}
              disabled={!props.emailTemplate}
              iconRight="trash-can-outline"
              onPress={onDelete}
            />
          </Iffy>
          <DNAButton appearance="filled" status="flat" onPress={props.onPreview}>Preview</DNAButton>
        </DNABox>
        <DNABox spacing="small">
          <DNAButton appearance="filled" status="flat" onPress={props.closeDrawer}>Cancel</DNAButton>
          <Iffy is={!props.emailTemplate?.status || props.emailTemplate?.status === EmailTemplateStatus.DRAFT}>
            <DNAButton
              style={{ maxHeight: 43 }}
              appearance={isSuccess ? 'filled' : 'outline'}
              disabled={!props.isFormDirty && !isSuccess}
              status={isSuccess ? 'success' : undefined}
              iconLeft={isSuccess ? 'check' : undefined}
              onPress={onSaveDraft}
            >

              {
                isSuccess ? 'Saved' : isSubmitting ? <DNASpinner size="tiny" status="info" /> : 'Save draft'
              }
            </DNAButton>
          </Iffy>
          <Iffy is={props.emailTemplate?.status !== EmailTemplateStatus.ACTIVE}>
            <DNAButton disabled={!props.canActivate} onPress={onActivate}>Activate</DNAButton>
          </Iffy>
          <Iffy is={props.emailTemplate?.status === EmailTemplateStatus.ACTIVE}>
            <DNAButton
              style={{ maxHeight: 43 }}
              disabled={!props.canActivate || !props.isFormDirty}
              status={isSuccess ? 'success' : undefined}
              iconLeft={isSuccess ? 'check' : undefined}
              onPress={updateTemplate}
            >
              {
                isSuccess ? 'Updated' : isSubmitting ? <DNASpinner size="tiny" status="info" /> : 'Update template'
              }
            </DNAButton>
          </Iffy>
        </DNABox>
      </DNABox>
    </DNABox>
  );
};

EmailDrawerFooter.displayName = 'EmailDrawerFooter';

export default EmailDrawerFooter;
