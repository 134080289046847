
import { DNABox } from '@alucio/lux-ui'
import React from 'react'
import { DocumentORM, FolderORM } from 'src/types/orms'
import { isDocumentORM, isFolderORM } from 'src/types/typeguards'
import InfoPanelStateProvider, { useInfoPanelState } from './State/InfoPanelStateProvider'
import FolderVariant, { FolderVariantProps } from './Variants/Folder'
import DocumentVariant, { DocumentVariantProps } from './Variants/Document'
import { StyleSheet } from 'react-native'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import useCurrentPage from '../DNA/hooks/useCurrentPage'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'
import mergeStyles from '@alucio/lux-ui/src/components/util/mergeStyles'

enum InfoPanelVariants {
  Folder,
  Document,
}
type InfoPanelVariant = keyof typeof InfoPanelVariants

const styles = StyleSheet.create(
  {
    hostContainer: {
      maxWidth: 360,
      backgroundColor: colors['color-text-basic'],
      shadowColor: '#EBEBEB',
      shadowRadius: 8,
    },
    tabletStyles: {
      position: 'absolute',
      right: 0,
      backgroundColor: colors['color-text-basic'],
      borderColor: '#EBEBEB',
      height: 'calc(100vh - 206px)',
      top: 56,
    },
  },
)
export interface BaseInfoPanelProps {
  variant?: InfoPanelVariant
  onClosePanel?: () => void
  displayInfoPanel?: boolean
  /** If this component is intended to stay as a global component, we can simply
   * move this from a prop to a wrapper element which watches the InfoPanel slice */
  itemORM?: FolderORM | DocumentORM
}

type VariantInfoPanelProps = FolderVariantProps | DocumentVariantProps

const InfoPanel: React.FC<VariantInfoPanelProps> = props => {
  const { deviceMode } = useAppSettings()

  /**
   * TODO: Outside the scope of this PR but the useCurrentPage hook does not appear
   * to be behaving as expected when viewing a folder. Returns an undefined rather
   * than the folder's route. Will need to switch to the "isActive..." check below once
   * this has been fixed. For now, the undefined produces the intended effect of hiding
   * the panel when not in the folders view.
   */
  const route = useCurrentPage({ exact: true })
  // const isActiveOnCurrentRoute =
  //   route?.configOptions?.desktop?.activateInfoPanel ||
  //   route?.configOptions?.tablet?.activateInfoPanel
  if (route) return <></>

  const { itemORM } = props
  if (!itemORM) return <></>

  const isTablet = deviceMode === DeviceMode.tablet

  /** Dynamic variant element selection based on the variant prop */
  const InfoPanelVariant = () => {
    const { collapsed } = useInfoPanelState()
    let detectedVariant
    switch (props.variant) {
      case 'Folder':
        detectedVariant = (isFolderORM(itemORM) &&
          (<FolderVariant
            {...props}
            folderORM={itemORM}
          />)
        )
        break
      /** The document case is currently unused but will likely be implemented in the near future */
      case 'Document':
        detectedVariant = (isDocumentORM(itemORM) &&
          (<DocumentVariant
            {...props}
            documentORM={itemORM}
          />)
        )
        break
    }

    return (
      <DNABox
        testID="info-panel-container"
        fill={!collapsed}
        style={mergeStyles(undefined, styles.hostContainer, [styles.tabletStyles, isTablet])}
      >
        {detectedVariant || (<></>)}
      </DNABox>
    )
  }

  return (
    <InfoPanelStateProvider>
      <InfoPanelVariant />
    </InfoPanelStateProvider>
  )
}

export default InfoPanel
