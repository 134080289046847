import { ContentPresented, Meeting, MeetingStatus, MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { commonReducers, initialState, SliceState } from './common';
import { DataStore } from '@aws-amplify/datastore';
import ActiveUser from '../../global/ActiveUser'

const sliceName = 'meeting'
const { reducers, extraReducers } = commonReducers<Meeting>(sliceName)

interface CreateMeetingPayload {
  title?: string;
  startTime: string;
  endTime?: string;
  contentPresented?: ContentPresented[];
  type: MeetingType;
  notes?: string;
  status: MeetingStatus;
  callback: (meeting: Meeting) => void
}

interface StartedMeetingPayload {
  type: MeetingType;
  title: string;
  callback?: (meetingId: string) => void;
}

const meetingSlice = createSlice({
  name: sliceName,
  initialState: initialState<Meeting>(),
  reducers: {
    ...reducers,
    createMeeting: ( // this method should require the startTime/endTime because it is created from the meetings  history page
      _state: SliceState<Meeting>,
      action: PayloadAction<CreateMeetingPayload>,
    ): void => {
      const { payload } = action;
      const user = ActiveUser.user;
      const meeting = new Meeting({
        tenantId: user!.tenantId,
        title: payload.title,
        startTime: payload.startTime,
        contentPresented: payload.contentPresented || [],
        type: payload.type,
        notes: payload.notes,
        endTime: payload.endTime,
        status: payload.status,
        createdBy: user!.id,
        updatedBy: user!.id,
      });

      analytics?.track('MEETING_MANUAL_ADD', {
        action: 'MANUAL_ADD',
        category: 'MEETING',
        meetingId: meeting.id,
      });

      DataStore.save(meeting).then(payload.callback) // need to verify next step!
    },
    updateMeeting: {
      prepare: (meeting: Meeting, updatedContent: Meeting) => { // TODO: need to verify this
        return {
          payload: {
            model: Meeting,
            entity: meeting,
            updates: { ...updatedContent },
          },
        }
      },
      reducer: reducers.batchSave,
    },
    startMeeting: (
      _state: SliceState<Meeting>,
      action: PayloadAction<StartedMeetingPayload>,
    ): void => {
      const { payload } = action;
      const user = ActiveUser.user;
      const now = new Date().toISOString();

      DataStore.save(
        new Meeting({
          tenantId: user!.tenantId,
          startTime: now,
          type: payload.type,
          title: payload.title,
          status: MeetingStatus.IN_PROGRESS,
          contentPresented: [],
          createdBy: user!.id,
          updatedBy: user!.id,
        }),
      ).then((meeting) =>
        payload.callback?.(meeting.id),
      )
    },
    endMeeting: {
      prepare: (meeting: Meeting) => {
        const now = new Date().toISOString();
        return {
          payload: {
            model: Meeting,
            entity: meeting,
            updates: {
              endTime: now,
              status: MeetingStatus.ENDED_EXIT,
              contentPresented: meeting.contentPresented?.map(p => !p?.closedAt ? { ...p!, closedAt: now } : p!),
            },
          },
        }
      },
      reducer: reducers.save,
    },
    deleteMeeting: {
      prepare: (meeting: Meeting) => {
        analytics?.track('MEETING_DELETED', {
          action: 'DELETED',
          category: 'MEETING',
          meetingId: meeting.id,
        });
        return {
          payload: {
            model: Meeting,
            entity: meeting,
            updates: {
              status: MeetingStatus.DELETED,
            },
          },
        }
      },
      reducer: reducers.save,
    },
  },
  extraReducers,
});

export default meetingSlice;
export const meetingActions = {
  ...meetingSlice.actions,
};
