import { ContentPresented, ContentPresentedStatus } from '@alucio/aws-beacon-amplify/src/models';
import { DNABox, DNAButton, DNAChip, DNAText, Iffy, Popover } from '@alucio/lux-ui';
import React, { useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { useAllDocumentVersionMap } from 'src/state/redux/selector/document';
import { useCustomDeckORMMap } from 'src/state/redux/selector/folder';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import DNADocumentChip from '../DNA/Document/DNADocumentChip';
import { useDNADocumentVersionActions } from '../DNA/Document/DNADocumentVersion.actions';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import mergeStyles from '@alucio/lux-ui/src/components/util/mergeStyles';
import { differenceInMinutes, parseISO } from 'date-fns';

const styles = StyleSheet.create({
  contentContainer: {
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderColor: colors['color-flat-400'],
    borderRadius: 4,
  },
  rowStyleEdit: {
    padding: 8,
    borderTopWidth: 1,
    borderColor: colors['color-flat-400'],
  },
  rowStyle: {
    paddingTop: 8,
  },

})

interface ContentPresentedListItemProps {
  contentId: string,
  contentType: ContentPresented['contentType'],
  isEditForm: boolean,
  folderItemId?: string,
  durationInMinutes?: number
  onDelete?: (contentPresentedId: string, folderItemId?: string) => void,
}

type ListItems = Record<ContentPresentedListItemProps['contentType'], React.FC<ContentPresentedListItemProps>>

const listItems: ListItems = {
  CUSTOM_DECK: (props) => {
    const { contentId, isEditForm, onDelete, durationInMinutes, folderItemId } = props
    const customDecksMap = useCustomDeckORMMap();
    const customDeck = customDecksMap[contentId]
    const title = customDeck.meta.title
    return (
      <DNABox
        alignY="center"
        spacing="between"
        fill
        style={mergeStyles(undefined, styles.rowStyle, [styles.rowStyleEdit, isEditForm])}
      >
        <DNABox
          fill
          spacing="small"
        >
          <DNAChip appearance="tag" status="subtle">MODIFIED</DNAChip>
          <DNABox spacing="small">
            <DNAText
              numberOfLines={2}
              status={isEditForm ? 'primary' : undefined}
              appearance={isEditForm ? 'ghost' : undefined}
              style={{ maxWidth: 750 }}
            >
              {title}
            </DNAText>
            <DNAText style={{ color: colors['color-flat-700'] }}>
              {`(${durationInMinutes || 0} min)`}
            </DNAText>
          </DNABox>
        </DNABox>
        <Iffy is={isEditForm && onDelete}>
          <DNAButton
            appearance="filled"
            size="medium"
            status="flat"
            context="moderate"
            style={{ paddingVertical: 5 }}
            iconRight="trash-can-outline"
            onPress={() => onDelete?.(contentId, folderItemId)}
          />
        </Iffy>
      </DNABox>
    )
  },
  DOCUMENT_VERSION: (props) => {
    const { contentId, isEditForm, onDelete, durationInMinutes } = props
    const dispatch = useDispatch()
    const documentVersionMap = useAllDocumentVersionMap()
    const { shareEMail } = useDNADocumentVersionActions();
    const documentVersion = documentVersionMap[contentId]
    const documentORM = documentVersion.relations.document
    const { latestDocumentVersionPublished } = documentORM.relations.version;

    if (!latestDocumentVersionPublished) return null

    const title = `v ${documentVersion?.model.versionNumber || ''} ${documentVersion?.model.title}`

    const handlePress = () => {
      dispatch(
        contentPreviewModalActions.setModalVisibility({
          documentVersionId: latestDocumentVersionPublished.model.id,
          isOpen: true,
        },
        ));
    }

    return (
      <DNABox
        alignY="center"
        spacing="between"
        fill
        style={mergeStyles(undefined, styles.rowStyle, [styles.rowStyleEdit, isEditForm])}
      >
        <DNABox fill spacing="small">
          <Iffy is={documentVersion?.meta.permissions.MSLShare}>
            <Popover placement="top">
              <Popover.Anchor>
                <DNAButton
                  status="subtle"
                  appearance="ghost"
                  context="minimum"
                  iconLeft="share"
                  onPress={shareEMail(latestDocumentVersionPublished)}
                />
              </Popover.Anchor>
              <Popover.Content offset={2}>
                <DNAText status="basic">
                  Copy shareable link
                </DNAText>
              </Popover.Content>
            </Popover>
          </Iffy>
          <DNADocumentChip item={documentORM} variant="purpose" />
          <Pressable onPress={handlePress}>
            <DNABox appearance="row" fill spacing="small" >
              <DNAText
                numberOfLines={2}
                status={isEditForm ? 'primary' : undefined}
                appearance={isEditForm ? 'ghost' : undefined}
                style={{ maxWidth: 750 }}
              >
                {title}
              </DNAText>
              <DNAText style={{ color: colors['color-flat-700'] }}>
                {`(${durationInMinutes || 0} min)`}
              </DNAText>
            </DNABox>
          </Pressable>
        </DNABox >
        <Iffy is={isEditForm}>
          <DNAButton
            appearance="filled"
            size="medium"
            status="flat"
            context="moderate"
            style={{ paddingVertical: 5 }}
            iconRight="trash-can-outline"
            onPress={() => onDelete?.(contentId)}
          />
        </Iffy>
      </DNABox >
    )
  },
}
interface ContentPresentedListProps {
  contentPresented: (ContentPresented | null)[],
  isEditForm: boolean,
  onDelete?: (contentPresentedId: string) => void,
}
const ContentPresentedList: React.FC<ContentPresentedListProps> = (props) => {
  const { contentPresented, isEditForm, onDelete } = props;

  const JSXContent = contentPresented.map((content, index) => {
    if (!content || content.status === ContentPresentedStatus.DELETED) {
      return null;
    }
    const durationInMinutes = content.closedAt
      ? differenceInMinutes(parseISO(content.closedAt), parseISO(content.openedAt)) : undefined;

    const ListItem = listItems[content.contentType]

    return (
      <ListItem
        isEditForm={isEditForm}
        key={`content-item-${content.contentId}-${index}`}
        contentId={content.contentId}
        folderItemId={content.folderItemId}
        onDelete={onDelete}
        durationInMinutes={durationInMinutes}
        contentType={content.contentType}
      />
    )
  });
  const [showAll, setShowAll] = useState(contentPresented.length <= 5);

  const toggleShowAll = () => {
    setShowAll((prev) => !prev);
  }

  const contentRowsToRender = showAll ? JSXContent : JSXContent.filter(p => p).slice(0, 5);

  return (
    <>
      <Iffy is={!contentPresented.length}>
        <DNAText>No content presented</DNAText>
      </Iffy>
      <Iffy is={contentPresented.length}>
        <DNABox
          appearance="col"
          shrink
          style={mergeStyles(undefined, {}, [styles.contentContainer, isEditForm])}
        >
          {contentRowsToRender}
          {contentPresented.filter(p => p?.status === ContentPresentedStatus.ACTIVE).length > 5 &&
            <DNABox style={isEditForm ? { padding: 8 } : undefined}>
              <DNAButton
                status="primary"
                appearance="ghost"
                context="minimum"
                onPress={toggleShowAll}
              >
                {showAll ? 'Show less' : 'Show all'}
              </DNAButton>
            </DNABox>}
        </DNABox>
      </Iffy>
    </>
  )
}

export default ContentPresentedList;
