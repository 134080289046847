import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, Image } from 'react-native';
import { DNABox, DNAButton, DNADivider, DNAText, Iffy, luxColors } from '@alucio/lux-ui';
import { TenantORM } from 'src/state/redux/selector/tenant';
import {
  useIntegrationSettingsList,
  useIsIntegrationSettingsHydrated,
} from 'src/state/redux/selector/integrationSettings';
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { useDispatch } from 'react-redux';
import { integrationSettingsActions } from 'src/state/redux/slice/integrationSettings';
import Integration from '../Integrations';
import { IntegrationSettings } from '@alucio/aws-beacon-amplify/src/models';

type IntegrationListProps = {
  selectedTenant: TenantORM
}

const styles = StyleSheet.create({
  crumbsColor: {
    color: luxColors.flat.primary,
  },
  header: {
    marginBottom: 16,
  },
  itemTextColor: {
    color: luxColors.contentText.tertiary,
  },
  mainWrapper: {
    backgroundColor: colors['color-text-basic'],
    overflowX: 'auto',
  },
  tab: {
    paddingHorizontal: 12,
    paddingVertical: 20,
  },
  tenantRowWrapper: {
    borderBottomColor: luxColors.divider.primary,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 20,
  },
  tenantsWrapper: {
    borderRadius: 8,
    overflow: 'hidden',
  },
  integrationRow: {
    borderRadius: 4,
    height: 64,
    justifyContent: 'space-between',
    borderColor: '#EBEBEB',
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    marginTop: 16,
    border: '1px solid #EBEBEB',
    alignItems: 'center',
  },
  integrationTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 24,
    display: 'flex',
    alignItems: 'center',
    color: colors['color-dark-500'],
  },
  integrationStatus: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 13,
    lineHeight: 20,
    display: 'flex',
    alignItems: 'center',
  },
  actionsContainer: {
    padding: 16,
  },
  listContainer: {
    padding: 24,
  },
});

const IntegrationsList = (props: IntegrationListProps) => {
  const { selectedTenant } = props
  const dispatch = useDispatch()
  const integrationSettings = useIntegrationSettingsList()
  const isIntegrationSettingsHydrated = useIsIntegrationSettingsHydrated();
  const [showForm, setShowForm] = useState(false)
  const [integrationSetting, setIntegrationSetting] = useState<IntegrationSettings | undefined>(undefined)

  useEffect(() => {
    dispatch(integrationSettingsActions.getIntegrationsAction(selectedTenant.tenant.id))
  }, [])

  const handleCreate = useCallback(
    () => {
      setShowForm(true)
      setIntegrationSetting(undefined)
    },
    [],
  )

  const handleEdit = useCallback(
    (integration) => {
      setShowForm(true)
      setIntegrationSetting(integration)
    },
    [],
  )

  const handleCloseForm = useCallback(() => {
    setShowForm(false)
    setIntegrationSetting(undefined)
  }, [])

  return (
    <DNABox style={styles.mainWrapper} fill appearance="col">
      <Iffy is={!showForm}>
        <DNABox spacing="small" alignX="end" style={styles.actionsContainer}>
          <DNAButton
            size="small"
            status="neutral"
            appearance="outline"
            onPress={handleCreate}
          >
            Add integration
          </DNAButton>
        </DNABox>
        <DNADivider />
        <DNABox appearance="col" style={styles.listContainer} >
          <Iffy is={isIntegrationSettingsHydrated && !integrationSettings.length}>
            <DNABox appearance="col" alignX="center">
              <DNAEmpty emptyVariant={EmptyVariant.IntegrationSettingsEmpty} />
              <DNAButton
                size="medium"
                status="neutral"
                appearance="outline"
                onPress={handleCreate}
              >
                Add integration
              </DNAButton>
            </DNABox>
          </Iffy>
          <Iffy is={!isIntegrationSettingsHydrated}>
            <DNABox fill alignX="center" alignY="center">
              <Image
                testID="dna-loader-report"
                style={{ width: 200, height: 200 }}
                source={require('../../../assets/images/LoaderDNA.gif')}
              />
            </DNABox>
          </Iffy>
          <Iffy is={integrationSettings.length > 0}>
            <>
              <DNAText bold>Configured Integrations</DNAText>
              { integrationSettings.map(i =>
                (<DNABox appearance="row" style={styles.integrationRow}>
                  <DNABox appearance="col">
                    <DNABox>
                      <DNAText>
                        {i.name}
                      </DNAText>
                    </DNABox>
                    <DNABox style={styles.integrationStatus} spacing="tiny">
                      <DNAText status={i.enabled ? 'success' : 'danger'} c1>
                        { i.enabled ? 'Enabled' : 'Disabled' }
                      </DNAText>
                    </DNABox>
                  </DNABox>
                  <DNAButton
                    size="tiny"
                    status="neutral"
                    appearance="outline"
                    onPress={() => handleEdit(i)}
                  >
                    Edit
                  </DNAButton>
                </DNABox>),
              )}
            </>
          </Iffy>
        </DNABox>
      </Iffy>
      <Iffy is={showForm}>
        <Integration
          onClose={handleCloseForm}
          selectedTenant={selectedTenant}
          selectedIntegration={integrationSetting}
        />
      </Iffy>
    </DNABox>
  );
};

IntegrationsList.displayName = 'IntegrationsList';

export default IntegrationsList;
