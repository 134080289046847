import format from 'date-fns/format'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'

import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { VirtualSessionORM, ORMTypes, VirtualSessionWithAttendees } from 'src/types/types'
import { RootState } from '../store'

export const selVirtualSession = (state: RootState) => state.virtualSession.records

function getSessionDate(createdAt: string) {
  const date = format(new Date(createdAt), 'MMM do, yyyy');
  return date;
}

function getSessionDuration(createdAt: string, terminatedAt?: string) {
  if (!terminatedAt) {
    return 'Unknown';
  }
  const minutes = formatDistanceStrict(new Date(createdAt), new Date(terminatedAt), { unit: 'minute' });
  return minutes;
}

function getSessionStartTime(createdAt: string) {
  const startedTime = format(new Date(createdAt), 'h:mm a');
  return startedTime
}

function getSessionTerminatedTime(terminatedAt?: string) {
  if (!terminatedAt) {
    return '';
  }
  const terminatedTime = format(new Date(terminatedAt), 'h:mm a');
  return terminatedTime
}

function getSessionTimeFormatted(createdAt: string, terminatedAt?: string) {
  return `${getSessionStartTime(createdAt)} - ${getSessionTerminatedTime(terminatedAt)}`
}

export const virtualSessionList = createSelector(
  selVirtualSession,
  (sessions: VirtualSessionWithAttendees[]): VirtualSessionORM[] => {
    const mapped = sessions.map(session => {
      return {
        model: session,
        type: ORMTypes.VIRTUAL_SESSION,
        meta: {
          sessionDate: getSessionDate(session.createdAt),
          sessionDuration: getSessionDuration(session.createdAt, session.terminatedAt),
          sessionTime: getSessionTimeFormatted(session.createdAt, session.terminatedAt),
        },
      }
    })
    return mapped
  },
)

export const useVirtualSessionList = (): ReturnType<typeof virtualSessionList> =>
  useSelector((state: RootState) => virtualSessionList(state))
