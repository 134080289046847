import React, { useEffect } from 'react'
import { StyleSheet } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNAText,
} from '@alucio/lux-ui'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import {
  GroupStatus,
  PayloadGroup, usePresentationBuilderState,
} from '../../state/PresentationBuilderStateProvider';
import { useDocumentVersionORM } from 'src/state/redux/selector/document';
import { v4 as uuid } from 'uuid';
import useThumbnailSelector from 'src/components/SlideSelector/useThumbnailSelector';
import PageGroupList, { GroupDraft } from 'src/components/SlideSelector/PageGroupList';
import { SlideMode } from 'src/components/SlideSelector/SlideSelector';

const styles = StyleSheet.create({
  contentWrapper: {
    overflowY: 'auto',
    paddingHorizontal: 64,
    minWidth: 500,
  },
});

interface Props {
  documentVersionId: string;
  onReplacementSelected: (payloadGroup: PayloadGroup) => void;
}

const SlideSelector = (props: Props) => {
  const {
    onReplacementSelected,
    documentVersionId,
  } = props

  const {
    selectorThumbnailMode,
    selectorThumbnailSize,
    toggleSelectorThumbnailMode,
  } = usePresentationBuilderState()
  const {
    initializeThumbnails,
    getGroupDrafts,
  } = useThumbnailSelector({ mode: SlideMode.DocumentVersion })
  const documentVersionORM = useDocumentVersionORM(documentVersionId);

  useEffect(() => {
    documentVersionORM && initializeThumbnails(documentVersionORM?.model, false)
  }, [documentVersionORM])

  const groupDrafts = getGroupDrafts()

  const handleGroupSelect = (selectedGroup:GroupDraft) => {
    const newPayloadGroup:PayloadGroup = {
      id: uuid(),
      documentVersion:documentVersionORM,
      pages: selectedGroup.pages,
      groupSrcId: selectedGroup.id,
      groupStatus: GroupStatus.ACTIVE,
      visible: true,
    }
    onReplacementSelected(newPayloadGroup)
  }

  if (!documentVersionORM) {
    return null;
  }

  return (
    <DNABox fill alignY="start" style={styles.contentWrapper}>
      <DNABox appearance="col" fill>
        {/* breadcrumb */}
        <DNABox
          alignY="center"
          style={{
            margin: 10,
            height: 80,
          }}
        >
          <DNABox appearance="col" spacing="small">
            <DNAText b1>
              {documentVersionORM?.model.title}
            </DNAText>
            <DNABox appearance="row" spacing="medium">
              <DNADocumentChip
                item={documentVersionORM?.relations.document!}
                variant="purpose"
              />
              <DNADocumentChip
                item={documentVersionORM?.relations.document!}
                variant="docType"
              />
            </DNABox>
          </DNABox>
          <DNABox fill alignX="end">
            <DNAButton
              appearance="ghost"
              status="dark"
              iconLeft={selectorThumbnailMode === 'small' ? 'view-grid' : 'view-comfy'}
              onPress={toggleSelectorThumbnailMode}
            />
          </DNABox>
        </DNABox>
        <DNABox fill>
          <PageGroupList
            handleGroupSelect={handleGroupSelect}
            thumbnailSize={selectorThumbnailSize}
            groupDrafts={groupDrafts}
            disableCheckboxes={true}
          />
        </DNABox>
      </DNABox>
    </DNABox>
  )
};

SlideSelector.displayName = 'SlideSelector';

export default SlideSelector;
