import React from 'react'
import {
  DNABox,
  DNAText,
  luxColors,
} from '@alucio/lux-ui'

import { Controller, useFormContext } from 'react-hook-form'

import InputComponent from 'src/components/Publishers/InputComponent'

// convert this into a variant
const BeaconIntegration = () => {
  const { control, formState: { errors } } = useFormContext()

  return (
    <DNABox fill>
      <DNABox appearance="col" spacing="medium" fill>
        <DNABox appearance="col" spacing="small">
          <Controller
            as={InputComponent}
            name="name"
            title="Integration name"
            characterLimit={250}
            showCharacterCounter={true}
            required={true}
            rules={{ required: 'This field is required', maxLength: 250 }}
            control={control}
            removeMarginPadding
            inputStyle={errors.name && { borderColor: luxColors.error.primary }}
          />
          {errors.name && errors.name.type === 'required' &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.name.message}
            </DNAText>
          }
          {errors.name && errors.name.type === 'maxLength' &&
            <DNAText style={{ color: luxColors.error.primary }}>
              Maximum character limit is 250
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="small">
          <Controller
            as={InputComponent}
            name="clientConfigurationFields.appsync_url"
            title="appSync URL"
            required={true}
            rules={{ required: 'This field is required' }}
            control={control}
            removeMarginPadding
            inputStyle={
              (errors.clientConfigurationFields && errors.clientConfigurationFields.appsync_url) &&
              { borderColor: luxColors.error.primary }}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.appsync_url) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.appsync_url.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="small">
          <Controller
            as={InputComponent}
            name="clientConfigurationFields.username"
            title="Username"
            required={true}
            rules={{ required: 'This field is required' }}
            control={control}
            removeMarginPadding
            inputStyle={
              (errors.clientConfigurationFields && errors.clientConfigurationFields.username) &&
              { borderColor: luxColors.error.primary }}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.username) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.username.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="small">
          <Controller
            as={InputComponent}
            name="clientConfigurationFields.aws_cognito_identity_pool_id"
            title="aws_cognito_identity_pool_id"
            required={true}
            rules={{ required: 'This field is required' }}
            control={control}
            removeMarginPadding
            inputStyle={
              (errors.clientConfigurationFields && errors.clientConfigurationFields.aws_cognito_identity_pool_id) &&
              { borderColor: luxColors.error.primary }}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.aws_cognito_identity_pool_id) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.aws_cognito_identity_pool_id.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="small">
          <Controller
            as={InputComponent}
            name="clientConfigurationFields.aws_user_pools_id"
            title="aws_user_pools_id"
            required={true}
            rules={{ required: 'This field is required' }}
            control={control}
            removeMarginPadding
            inputStyle={
              (errors.clientConfigurationFields && errors.clientConfigurationFields.aws_user_pools_id) &&
              { borderColor: luxColors.error.primary }}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.aws_user_pools_id) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.aws_user_pools_id.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="small">
          <Controller
            as={InputComponent}
            name="clientConfigurationFields.aws_appsync_region"
            title="aws_appsync_region"
            required={true}
            rules={{ required: 'This field is required' }}
            control={control}
            removeMarginPadding
            inputStyle={
              (errors.clientConfigurationFields && errors.clientConfigurationFields.aws_appsync_region) &&
              { borderColor: luxColors.error.primary }}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.aws_appsync_region) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.aws_appsync_region.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="small">
          <Controller
            as={InputComponent}
            name="clientConfigurationFields.aws_user_pools_web_client_id"
            title="aws_user_pools_web_client_id"
            required={true}
            rules={{ required: 'This field is required' }}
            control={control}
            removeMarginPadding
            inputStyle={
              (errors.clientConfigurationFields && errors.clientConfigurationFields.aws_user_pools_web_client_id) &&
              { borderColor: luxColors.error.primary }}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.aws_user_pools_web_client_id) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.aws_user_pools_web_client_id.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="small">
          <Controller
            as={InputComponent}
            name="clientConfigurationFields.tenantIdSource"
            title="Tenant ID source"
            required={true}
            rules={{ required: 'This field is required' }}
            control={control}
            removeMarginPadding
            inputStyle={
              (errors.clientConfigurationFields && errors.clientConfigurationFields.tenantIdSource) &&
              { borderColor: luxColors.error.primary }}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.tenantIdSource) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.tenantIdSource.message}
            </DNAText>
          }
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export default BeaconIntegration
