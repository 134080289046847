import React, { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { useForm, Controller } from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import {
  Box,
  DNABox,
  DNACheckbox,
  DNAText,
  Icon,
  InformationMessage,
  Iffy,
  luxColors,
  Popover,
} from '@alucio/lux-ui'
import { DocumentVersionChangeType, NotificationScope } from '@alucio/aws-beacon-amplify/src/models';
import { DocumentVersionORM } from 'src/types/types';

import VersionChangesPrompt from 'src/screens/Publishers/Versioning/VersionChangesPrompt';
import InputComponent from 'src/components/Publishers/InputComponent';
import { useVersioningPanel } from './VersioningPanelContext';

import { S } from 'src/screens/Publishers/Versioning/VersioningPanel'
import { styles } from 'src/components/Publishers/Styles';

const componentStyles = StyleSheet.create({
  releaseNotes: {
    minHeight: 261,
  },
});

const formSchema = z.object({
  notificationScope: z.nativeEnum(NotificationScope),
  changeType: z.nativeEnum(DocumentVersionChangeType),
  releaseNotes: z.string().nonempty({ message: 'This field is required' }),
})

type PublishFormSchema = z.infer<typeof formSchema>

export type DocumentPublishFormRef = {
  handleSaveDraftForDocumentPublish: () => PublishFormSchema
  handlePublishDocumentInfo: () => Promise<PublishFormSchema | null>
}

// [TODO-2126] - Extract this into a generic type since React doesn't
const useDocumentPublishForm = (ref:
  | ((instance: DocumentPublishFormRef | null) => void)
  | React.MutableRefObject<DocumentPublishFormRef | null>
  | null,
) => {
  const { currentDocumentVersionORM, state, send } = useVersioningPanel()

  const computeInitialValues = (docVerORM: DocumentVersionORM): Partial<PublishFormSchema> => {
    const { releaseNotes, changeType, notificationScope } = docVerORM.model
    return {
      releaseNotes,
      changeType: changeType as DocumentVersionChangeType ?? DocumentVersionChangeType.MINOR,
      notificationScope: notificationScope as NotificationScope ?? NotificationScope.NONE,
    }
  }

  const rhForm = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: computeInitialValues(currentDocumentVersionORM),
  })

  const { formState: { isDirty } } = rhForm

  useEffect(
    () => {
      if (!isDirty && state.context.documentPublishIsDirty) {
        send({ type: 'SET_IS_DIRTY', payload: { type: 'publish', isDirty } })
      }

      if (
        isDirty &&
        state.event.type !== 'SAVE_DRAFT' &&
        !state.context.documentPublishIsDirty
      ) { send({ type: 'SET_IS_DIRTY', payload: { type: 'publish', isDirty } }) }

      if (isDirty && state.event.type === 'SAVE_DRAFT' &&
        !state.context.documentPublishIsDirty) {
        rhForm.reset(rhForm.getValues(), { isDirty: false })
      }
    },
    [state, isDirty],
  )

  // [NOTE] - Sync changes form state machine to form
  useEffect(
    () => {
      if (state.context.changesCheckResult?.detectedChangeType) {
        rhForm.setValue('changeType', state.context.changesCheckResult.detectedChangeType)
      }
    },
    [state.context.changesCheckResult?.detectedChangeType],
  )

  // [NOTE] - Update form values on version history selection
  //          This is for the temporary read only mode
  useEffect(
    () => {
      const newForm = computeInitialValues(currentDocumentVersionORM)
      rhForm.reset(newForm)
    },
    [currentDocumentVersionORM?.model.id],
  )

  const handleSaveDraftForDocumentPublish = (): PublishFormSchema => {
    const formValues = rhForm.getValues()
    return formValues
  }

  const handlePublishDocumentInfo = async (): Promise<PublishFormSchema | null> => {
    const isValid = await rhForm.trigger()
    return isValid
      ? rhForm.getValues()
      : null
  }

  useImperativeHandle(
    ref,
    () => ({
      handleSaveDraftForDocumentPublish,
      handlePublishDocumentInfo,
    }),
  )

  return rhForm
}

const DocumentPublish = forwardRef<DocumentPublishFormRef>((props, ref) => {
  const { documentORM, state } = useVersioningPanel()

  const latestDocumentVersion: DocumentVersionORM = documentORM.relations.version.latestDocumentVersion;
  const detectedChangeType = state.context.changesCheckResult?.detectedChangeType ?? DocumentVersionChangeType.MINOR
  const disableMinorChangeType = state.context.changesCheckResult?.disableMinor ?? false
  const client = useLDClient()
  const isEmailNotificationEnabled = client?.variation('beac-1535-msl-email-alerts', false)

  const { control, formState: { errors } } = useDocumentPublishForm(ref)

  const errorMessages = Object
    .values(errors)
    .map(error => error?.type === 'too_small' ? 'Missing required fields' : error?.message)
    .join('\n')

  const [showNote, setShowNote] = useState(false);

  useEffect(() => {
    setShowNote(control.getValues().changeType === DocumentVersionChangeType.MAJOR &&
    control.getValues().notificationScope !== NotificationScope.EMAIL);
  }, [state.context.changesCheckResult])

  return (
    <ScrollView style={S.tabContentContainer}>
      <DNABox
        fill
        appearance="col"
        spacing="large"
      >

        {/* FORM ERRORS */}
        <Iffy is={errorMessages}>
          <InformationMessage
            text={errorMessages}
            variance="danger"
          />
        </Iffy>
        <Iffy is={isEmailNotificationEnabled && showNote}>
          <InformationMessage
            text={'Note: users with personalized files containing ' +
            'this document will still receive an email notification.'}
            variance="primary"
          />
        </Iffy>
        <Iffy is={isEmailNotificationEnabled}>
          <DNABox
            testID="notify-users-email-checkbox"
            childFill={1}
            spacing="small"
          >
            {/* NOTIFICATION SCOPE */}
            <Controller
              control={control}
              name="notificationScope"
              defaultValue={NotificationScope.NONE}
              required
              render={({ onChange, value }) => {
                return (
                  <DNACheckbox
                    testID={value ? 'checkbox-checked' : 'checkbox-unchecked'}
                    checked={value === NotificationScope.EMAIL}
                    onChange={e => {
                      onChange(e ? NotificationScope.EMAIL : NotificationScope.NONE)
                      setShowNote(!e && control.getValues().changeType === DocumentVersionChangeType.MAJOR);
                    }}
                  />
                )
              }}
            />
            <Box style={styles.TitleContainer}>
              <DNAText
                bold
                numberOfLines={3}
                ellipsizeMode="tail"
              >
                Notify users of new versions via email
              </DNAText>
              <Popover>
                <Popover.Anchor>
                  <Icon style={styles.HelpToolTipIconStyle} name="help-circle-outline" />
                </Popover.Anchor>
                <Popover.Content>
                  <DNAText style={{ color: 'white' }}>
                    Your audience will receive an email notification if this file exists in one of their folders.
                  </DNAText>
                </Popover.Content>
              </Popover>
            </Box>
          </DNABox>
        </Iffy>
        {/* SEMVER CHANGE TYPE */}
        <Iffy is={documentORM.relations.version.latestDocumentVersionPublished != null}>
          <Controller
            name="changeType"
            control={control}
            required={true}
            defaultValue={detectedChangeType}
            render={({ value, onChange }) => (
              <VersionChangesPrompt
                changeType={value}
                disableMinor={disableMinorChangeType}
                detectedChangeType={detectedChangeType}
                setChangeType={(e) => {
                  onChange(e);
                  setShowNote(e === DocumentVersionChangeType.MAJOR &&
                  control.getValues().notificationScope !== NotificationScope.EMAIL);
                }}
              />
            )}
          />
        </Iffy>

        {/* RELEASE NOTES */}
        <DNABox appearance="col" spacing="small">
          <Controller
            name="releaseNotes"
            control={control}
            as={InputComponent}
            required={true}
            characterLimit={1000}
            inputStyle={componentStyles.releaseNotes}
            multiline={true}
            placeHolder="Enter release notes…"
            removeMarginPadding={true}
            showCharacterCounter={true}
            testID="release-notes-container"
            title="RELEASE NOTES"
            titleColor={luxColors.contentPanelBackground.secondary}
            defaultValue={latestDocumentVersion.model.releaseNotes || ''}
          />
          <Iffy is={errors.releaseNotes}>
            <DNAText status="danger">
              This field is required
            </DNAText>
          </Iffy>
        </DNABox>
      </DNABox>
    </ScrollView>
  )
})

export default DocumentPublish
