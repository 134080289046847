import { DNAGridCards } from 'src/components/DNA/Grid/Cards/types'

import DNADocumentVirtualCard from './DNADocumentVirtualCard'
import DNADocumentFolderItemCard from './DNADocumentFolderItemCard'
import DNAFolderVirtualCard from './DNAFolderVirtualCard'
import DNACustomDeckVirtualCard from './DNACustomDeckVirtualCard'

export const DNADefaultRows: DNAGridCards = {
  DNADocumentCard: DNADocumentVirtualCard,
  DNACustomDeckCard: DNACustomDeckVirtualCard,
  DNADocumentFolderItemCard: DNADocumentFolderItemCard,
  DNAFolderCard: DNAFolderVirtualCard,
}

export default DNADefaultRows
