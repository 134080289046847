import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { DNABox, DNAButton, DNACard, DNAIcon, DNAText, Iffy, luxColors } from '@alucio/lux-ui';
import { TenantORM, useTenantList } from 'src/state/redux/selector/tenant';
import DNARouteTabs from 'src/components/DNA/Navigation/DNARouteTabs/DNARouteTabs'
import UsersList from './UsersList';
import IntegrationsList from './IntegrationsList';
import { Switch, Route } from 'src/router';
import Configuration from './Configuration';
import { useDispatch } from 'react-redux';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import NewTenantModal from './NewTenantModal';

const styles = StyleSheet.create({
  crumbsColor: {
    color: luxColors.flat.primary,
  },
  header: {
    marginBottom: 16,
  },
  itemTextColor: {
    color: luxColors.contentText.tertiary,
  },
  mainWrapper: {
    paddingHorizontal: 33,
    paddingVertical: 24,
    backgroundColor: luxColors.backgroundColor.secondary,
    flex: 1,
    flexDirection: 'column',
    minWidth: 'calc(100vw - 10px)',
    overflowX: 'auto',
  },
  tabContainer: {
    backgroundColor: luxColors.emptyStateButtonText.primary,
  },
  tab: {
    paddingHorizontal: 12,
    paddingVertical: 20,
    backgroundColor: luxColors.emptyStateButtonText.primary,
  },
  tenantRowWrapper: {
    borderBottomColor: luxColors.divider.primary,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 20,
  },
  tenantsWrapper: {
    borderRadius: 8,
    overflow: 'hidden',
  },
});

interface TenantRowProps {
  tenant: TenantORM;
  setSelectedTenant: (tenant: TenantORM) => void;
}

const TenantRow = (props: TenantRowProps) => {
  const { tenant, setSelectedTenant } = props;

  function handleSelectedTenant(): void {
    setSelectedTenant(tenant);
  }

  return (
    <DNACard
      appearance="flat"
      style={styles.tenantRowWrapper}
      interactive="pressableAlt"
      onPress={handleSelectedTenant}
    >
      <DNAText style={styles.itemTextColor}>{tenant.tenant.name}</DNAText>
      {/* [TODO]: TO BE APPLIED IN FOLLOWING TASKS */}
      {/* <DNAButton */}
      {/*  status="subtle" */}
      {/*  appearance="ghostAlt" */}
      {/*  context="minimum" */}
      {/*  iconLeft="dots-vertical" */}
      {/* /> */}
    </DNACard>
  );
};

const Tenants = () => {
  const tenants = useTenantList();
  const [selectedTenant, setSelectedTenant] = useState<TenantORM | undefined>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTenant) {
      const tenant = tenants.find((tenant) => tenant.tenant.id === selectedTenant.tenant.id);
      setSelectedTenant(tenant);
    }
  }, [tenants]);

  function unSelectTenant(): void {
    setSelectedTenant(undefined);
  }

  function addOrganizationHandler(): void {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <NewTenantModal
          {...props}
        />
      ),
    }),
    )
  }

  return (
    <View style={styles.mainWrapper}>
      { /* HEADER */}
      <DNABox spacing="between" alignY="center" style={styles.header}>
        <DNABox alignY="center">
          <Pressable onPress={unSelectTenant}>
            <DNAText style={styles.crumbsColor}>
              Organizations
            </DNAText>
          </Pressable>
          <Iffy is={selectedTenant}>
            <DNAIcon.Styled
              name="chevron-right"
              appearance="ghost"
              status="subtle"
              context="minimum"
            />
            <DNAText style={styles.crumbsColor}>
              {selectedTenant?.tenant.name}
            </DNAText>
          </Iffy>
        </DNABox>
      </DNABox>
      { /* TENANTS LIST */}
      <Iffy is={!selectedTenant}>
        <DNABox appearance="col">
          <DNABox
            appearance="row"
            fill
            spacing="between"
            alignY="center"
            style={{ paddingVertical: 14, paddingHorizontal: 16, backgroundColor: colors['color-text-basic'] }}
          >
            <DNAText bold style={styles.itemTextColor}>Name</DNAText>
            <DNAButton
              status="dark"
              appearance="outline"
              size="small"
              onPress={addOrganizationHandler}
            >
              Create Organization
            </DNAButton>
          </DNABox>
          <DNABox>
            <DNACard style={styles.tenantsWrapper}>
              { tenants.map((tenant) =>
                <TenantRow setSelectedTenant={setSelectedTenant} key={tenant.tenant.id} tenant={tenant}/>)
            }
            </DNACard>
          </DNABox>
        </DNABox>
      </Iffy>
      { /* USERS LIST */}
      <Iffy is={selectedTenant}>
        <NestedRoutes selectedTenant={selectedTenant} />
      </Iffy>
    </View>
  );
};

const DNATenantsSwitcher: React.FC<{
  selectedTenant: TenantORM | undefined
}> = (props) => {
  const { selectedTenant } = props;

  return (
    <DNABox alignX="center" fill>
      <DNABox fill>
        <DNACard style={styles.tabContainer} appearance="outline">
          <DNARouteTabs.Router>
            <DNARouteTabs.Tabs withDivider>
              <DNARouteTabs.Tab title="Users" path="/tenants">
                <UsersList selectedTenant={selectedTenant} />
              </DNARouteTabs.Tab>
              <DNARouteTabs.Tab title="Integrations" path="/tenants/integrations">
                <IntegrationsList selectedTenant={selectedTenant!} />
              </DNARouteTabs.Tab>
              <DNARouteTabs.Tab title="Settings" path="/tenants/configuration">
                <Configuration tenantId={selectedTenant!.tenant.id} />
              </DNARouteTabs.Tab>
              <DNARouteTabs.Unmatched rewrite="/tenants" />
            </DNARouteTabs.Tabs>
          </DNARouteTabs.Router>
        </DNACard>
      </DNABox>
    </DNABox>
  )
}

const NestedRoutes : React.FC<{
  selectedTenant: TenantORM | undefined
}> = (props) => {
  return (
    <Switch>
      <Route exact path="/tenants" component={() => <DNATenantsSwitcher {...props} /> } />
      <Route exact path="/tenants/integrations" component={() => <DNATenantsSwitcher {...props} /> } />
      <Route exact path="/tenants/configuration" component={() => <DNATenantsSwitcher {...props} /> } />
    </Switch>
  )
}

Tenants.displayName = 'Tenants';

export default Tenants;
