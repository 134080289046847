import React, { useState, useEffect } from 'react'
import { ScrollView } from 'react-native'
import im from 'immer'

import {
  FolderORM,
  DocumentORM,
  FolderItemORM,
  ORMTypes,
  isDocumentORM,
  isDocumentVersionORM,
  isFolderItemORM,
  isCustomDeckORM,
} from 'src/types/types'

import { useAllFolders } from 'src/state/redux/selector/folder'
import { filters, sorts, merge } from 'src/state/redux/folder/query'

import { DNABox, Collapsible } from '@alucio/lux-ui'
import DNANestedFolderRow from './DNAFolderNestedRow'
import useCurrentPage from '../../hooks/useCurrentPage'

type DNAFolderNestedListProps = {
  itemORM?: DocumentORM | FolderItemORM,
  onFolderSelect?: (f: FolderSelectedMap) => void,
}

export type FolderSelectedMap = {
  [id: string]: { folder: FolderORM, locked: boolean } | undefined
}

export type FolderExpandedMap = {
  [id: string]: boolean | undefined
}

const getLatestDocumentId = (
  itemORM: DocumentORM | FolderItemORM | undefined,
  isPublisherView : boolean) : string | undefined => (
  isDocumentORM(itemORM)
    ? isPublisherView
      ? itemORM.relations.version.latestDocumentVersionPublished?.model.id ||
        itemORM.relations.version.latestUsableDocumentVersion?.model.id
      : itemORM.relations.version.latestDocumentVersionPublished?.model.id
    : isDocumentVersionORM(itemORM?.relations.item) && !itemORM?.meta.isModified
      ? isPublisherView
        ? itemORM?.relations.item.relations.document.relations.version.latestDocumentVersionPublished?.model.id ||
          itemORM?.relations.item.relations.document.relations.version.latestUsableDocumentVersion?.model.id
        : itemORM?.relations.item.relations.document.relations.version.latestDocumentVersionPublished?.model.id
      : itemORM?.model.id
)

const initMap = (
  folders: FolderORM[],
  itemORM?: DocumentORM | FolderItemORM,
  isPublisherView: boolean = false,
) => {
  return im({}, draft => {
    for (const folder of folders) {
      const folderId = folder.model.id

      if (itemORM) {
        for (const nestedItem of folder.relations.items) {
          const nestedItemORM = nestedItem.relations.item;
          const folderItemID = getLatestDocumentId(itemORM, isPublisherView);
          const nestedItemID = nestedItemORM.model.id;

          // Root level folder
          if (nestedItemORM.type === ORMTypes.DOCUMENT_VERSION) {
            if (
              isFolderItemORM(itemORM) &&
              itemORM.meta.isModified &&
              nestedItem.model?.id === folderItemID
            )
            { draft[folderId] = { folder, locked: true } }
            else if (
              nestedItemID === folderItemID &&
              !nestedItem.meta.isModified
            )
            { draft[folderId] = { folder, locked: true } }
          }

          else if (nestedItemORM.type === ORMTypes.CUSTOM_DECK) {
            if (
              nestedItem.model?.id === folderItemID
            )
            {
              draft[folderId] = { folder, locked: true }
            }
          }

          // Nested level folder
          if (nestedItemORM.type === ORMTypes.FOLDER) {
            for (const subNestedItem of nestedItemORM.relations.items) {
              if (subNestedItem.relations.item.type === ORMTypes.DOCUMENT_VERSION) {
                if (
                  isFolderItemORM(itemORM) &&
                  itemORM.meta.isModified &&
                  subNestedItem.model.id === folderItemID
                )
                { draft[nestedItemID] = { folder, locked: true } }
                else if (
                  subNestedItem.relations.item.model.id === folderItemID)
                { draft[nestedItemID] = { folder, locked: true } }
              }
              else if (subNestedItem.relations.item.type === ORMTypes.CUSTOM_DECK) {
                if (
                  subNestedItem.model.id === folderItemID)
                { draft[nestedItemID] = { folder, locked: true } }
              }
            }
          }
        }
      }
    }
  })
}

const DEFAULT_FOLDER_FILTER = merge(
  filters.active,
  filters.rootFolder,
  sorts.pinnedAsc,
)

const DISABLED_CHECK_MESSAGE = 'Modified files cannot be added to a shared folder';

const DNANestedFolderList: React.FC<DNAFolderNestedListProps> = (props) => {
  const { itemORM, onFolderSelect } = props

  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')

  const folders = useAllFolders(DEFAULT_FOLDER_FILTER)
  const isCustomDeck = isFolderItemORM(itemORM) && isCustomDeckORM(itemORM?.relations?.item);
  const [selected, setSelected] = useState<FolderSelectedMap>(initMap(folders, itemORM, isPublisherRoute))
  const [expanded, setExpanded] = useState<FolderExpandedMap>({})

  useEffect(
    () => { onFolderSelect?.(selected) },
    [onFolderSelect, selected],
  )

  const toggleSelected = (folderORM: FolderORM) => () =>
    setSelected(p => {
      // If this document is already in a folder, it's locked
      if (p[folderORM.model.id]?.locked)
      { return p }

      return {
        ...p,
        [folderORM.model.id]: p[folderORM.model.id]
          ? undefined
          : { folder: folderORM, locked: false },
      }
    })

  const toggleExpanded = (folderORM: FolderORM) => () =>
    setExpanded(p => ({
      ...p,
      [folderORM.model.id]: p[folderORM.model.id]
        ? undefined
        : true,
    }))

  return (
    <DNABox
      as={ScrollView}
      appearance="col"
      spacing="medium"
      style={{ width: 560, height: 320 }}
    >
      {
        folders.map((folderORM) => (
          <>
            {/* Root Folder */}
            <DNANestedFolderRow
              folderORM={folderORM}
              onPress={toggleSelected(folderORM)}
              disabledMessage={isCustomDeck && folderORM.meta.isSharedFolder ? DISABLED_CHECK_MESSAGE : ''}
              onExpand={toggleExpanded(folderORM)}
              isChecked={!!selected[folderORM.model.id]}
              isCheckedDisabled={!!selected[folderORM.model.id]?.locked ||
                (isCustomDeck && folderORM.meta.isSharedFolder)}
              isExpanded={!!expanded[folderORM.model.id]}
            />
            {/* Nested Folder */}
            <Collapsible collapsed={!expanded[folderORM.model.id]}>
              <DNABox
                spacing="medium"
                appearance="col"
                style={{ marginTop: 16, marginLeft: 48 }}
              >
                {
                  folderORM
                    .relations
                    .items
                    .filter(item => item.relations.item.type === 'FOLDER')
                    .map(folderItem => (
                      <DNANestedFolderRow
                        folderORM={folderItem.relations.item as FolderORM}
                        onPress={toggleSelected(folderItem.relations.item as FolderORM)}
                        isChecked={!!selected[folderItem.relations.item.model.id]}
                        disabledMessage={isCustomDeck && folderORM.meta.isSharedFolder ? DISABLED_CHECK_MESSAGE : ''}
                        isCheckedDisabled={!!selected[folderItem.relations.item.model.id]?.locked ||
                          (isCustomDeck && folderORM.meta.isSharedFolder)}
                      />
                    ))
                }
              </DNABox>
            </Collapsible>
          </>
        ))
      }
    </DNABox>
  )
}

export default DNANestedFolderList
