import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native';
import { DNABox, createPortal } from '@alucio/lux-ui'
import { DNABoxProps } from '@alucio/lux-ui/lib/components/layout/DNABox/DNABox'

import throttle from 'lodash/throttle'
import {
  DndContext,
  DragOverlay,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
  rectIntersection,
} from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { snapCenterToCursor } from '@dnd-kit/modifiers'

import {
  usePresentationBuilderState,
  PayloadGroup,
  GroupStatus,
} from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider'
import { SortableItem, Item } from './DnDWrapper'
import { GroupedSlides, Slide } from './Slide'
import { useCustomCenteredRectIntersection } from './customCollision'

import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'

const S = StyleSheet.create({
  parentContainer: {
    marginTop: 32,
    marginHorizontal: '4%',
  },
  groupedSlidesFill: {
    flexBasis: '100%',
  },
  wrappedRowHorizontalSpacing: {
    marginRight: 32,
  },
  wrappedRowVerticalSpacing: {
    marginBottom: 24,
  },
  isDraggingBorder: {
    borderColor: colors['color-primary-400'],
  },
})

const CARD_WIDTH = 204
const CARD_HEIGHT = 350

export const useSlideThumbnails = () => {
  const { selectedGroups, hiddenSlidesVisible } = usePresentationBuilderState();
  const [localGroupOrder, setLocalGroupOrder] = useState<string[]>(selectedGroups.map(group => group.id))

  // GroupId -> PayloadGroup
  const groupMap = useMemo(
    () => selectedGroups.reduce<Record<string, PayloadGroup>>(
      (acc, group) => {
        acc[group.id] = group
        return acc
      },
      { },
    ),
    [selectedGroups],
  )

  // GroupId -> isVisible
  const visibleGroupsMap = useMemo(
    () => selectedGroups.reduce<Record<string, boolean>>(
      (acc, group) => {
        const shouldSlideBeVisible = group.groupStatus !== GroupStatus.ACTIVE ||
          group.visible || (!group.visible && hiddenSlidesVisible)
        const allSlidesVisible = hiddenSlidesVisible
        const isSlideVisible = shouldSlideBeVisible || allSlidesVisible

        acc[group.id] = isSlideVisible
        return acc
      },
      { },
    ),
    [selectedGroups, hiddenSlidesVisible],
  )

  // GroupId -> Starting Idx
  const { groupStartingIdxMap } = useMemo(
    () => localGroupOrder.reduce<{
      idxAcc: number
      groupStartingIdxMap: Record<string, number>
    }>(
      (acc, groupId) => {
        const group = groupMap[groupId]

        if (!group || !group.visible) return acc

        acc.groupStartingIdxMap[groupId] = acc.idxAcc
        acc.idxAcc += group.pages.length

        return acc
      },
      {
        idxAcc: 1,
        groupStartingIdxMap: { },
      },
    ),
    [localGroupOrder],
  )

  // Refresh local group on selected groups update
  useEffect(
    () => {
      setLocalGroupOrder(selectedGroups.map(group => group.id))
    },
    [selectedGroups],
  )

  return {
    groupMap,
    visibleGroupsMap,
    groupStartingIdxMap,
    localGroupOrder,
    setLocalGroupOrder,
  }
}

export const SlideThumbnails: React.FC = () => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const { builderMode, setSelectedGroups } = usePresentationBuilderState()
  const {
    localGroupOrder,
    setLocalGroupOrder,
    visibleGroupsMap,
    groupMap,
    groupStartingIdxMap,
  } = useSlideThumbnails()

  const isCustomizationMode = builderMode === 'customization';

  const customRectIntersection = useCustomCenteredRectIntersection(CARD_WIDTH, CARD_HEIGHT)
  const pointerOptions = { activationConstraint: { delay: 150, tolerance: 60 } }
  const sensors = useSensors(
    useSensor(MouseSensor, pointerOptions),
    useSensor(TouchSensor, pointerOptions),
  );

  const handleDragStart = (event) => setActiveId(event.active.id)
  const handleDragEnd = () => {
    setActiveId(null)
    // Commit rearranged changes back to parent context state
    setSelectedGroups(localGroupOrder.map(localGroupId => groupMap[localGroupId]))
  }
  const handleDragOver = useCallback(
    // [TODO-1837] - Need to keep a backup copy in-case the user wants to cancel (esc button)
    throttle(
      (event) => {
        const { active, over } = event;

        if (over && active.id !== over.id) {
          setLocalGroupOrder((localGroupOrder) => {
            const oldIndex = localGroupOrder.indexOf(active.id);
            const newIndex = localGroupOrder.indexOf(over.id);

            return arrayMove(localGroupOrder, oldIndex, newIndex);
          });
        }
      },
      25,
    ),
    [],
  )

  return (
    <ScrollView>
      <DNABox
        wrap="start"
        alignX={isCustomizationMode ? undefined : 'center'}
        style={S.parentContainer}
      >
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          collisionDetection={isCustomizationMode ? customRectIntersection : rectIntersection }
          modifiers={[snapCenterToCursor]}
        >
          <SortableContext
            items={localGroupOrder}
            strategy={() => null}
          >
            {/* Map draggable Slides and Grouped Slides */}
            {
              localGroupOrder.map((groupId) => {
                const group = groupMap[groupId]
                if (!group) return null

                const isGroup = group.pages.length > 1

                const ComponentType = isGroup
                  ? GroupedSlides
                  : Slide

                const orientation: DNABoxProps['appearance'] = isCustomizationMode ? 'row' : 'col'

                const groupedProps = {
                  group,
                  startingIdx: groupStartingIdxMap[group.id],
                  style: S.groupedSlidesFill,
                  orientation,
                }

                const slideProps = {
                  group,
                  page: group.pages[0],
                  displayIdx: groupStartingIdxMap[group.id],
                  enableOverlay: true,
                }

                const groupStyle = (ComponentType === GroupedSlides) && isCustomizationMode
                  ? StyleSheet.flatten([S.groupedSlidesFill, S.wrappedRowVerticalSpacing])
                  : StyleSheet.flatten([
                    isCustomizationMode && S.wrappedRowHorizontalSpacing,
                    S.wrappedRowVerticalSpacing,
                  ])

                return (
                  <View key={group.id} style={groupStyle}>
                    <SortableItem
                      key={group.id}
                      id={group.id}
                      visible={visibleGroupsMap[group.id]}
                    >
                      {
                        isGroup
                          ? <GroupedSlides {...groupedProps} />
                          : <Slide {...slideProps} />
                      }
                    </SortableItem>
                  </View>
                )
              })
            }
          </SortableContext>
          {/* Drag Overlay */}
          {
            createPortal(
              <DragOverlay>
                {
                  activeId
                    ? <Item>
                      {
                        groupMap[activeId]?.pages.length > 1
                          ? <GroupedSlides
                              group={groupMap[activeId]}
                              startingIdx={groupStartingIdxMap[activeId]}
                              orientation={isCustomizationMode ? 'row' : 'col'}
                              style={S.isDraggingBorder}
                          />
                          : <Slide
                              group={groupMap[activeId]}
                              page={groupMap[activeId].pages[0]}
                              displayIdx={groupStartingIdxMap[activeId]}
                              style={S.isDraggingBorder}
                          />
                      }
                    </Item>
                    : null
                }
              </DragOverlay>,
              document.body,
            )
          }
        </DndContext>
      </DNABox>
    </ScrollView>
  );
}
