/* eslint-disable max-len */
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgBars(props: SvgProps) {
  return (
    <Svg
      viewBox="0 0 32 32"
      width={32}
      height={32}
      fill="none"
            // @ts-ignore
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path fillRule="evenodd" clipRule="evenodd" d="M2 1C2.55228 1 3 1.44772 3 2V29H30C30.5523 29 31 29.4477 31 30C31 30.5523 30.5523 31 30 31H3C2.46957 31 1.96086 30.7893 1.58579 30.4142C1.21071 30.0391 1 29.5304 1 29V2C1 1.44772 1.44772 1 2 1Z" fill={props.color ?? '#fff'} />
      <Path fillRule="evenodd" clipRule="evenodd" d="M7.25 15C7.11193 15 7 15.1119 7 15.25V24.75C7 24.8881 7.11193 25 7.25 25H9.75C9.88807 25 10 24.8881 10 24.75V15.25C10 15.1119 9.88807 15 9.75 15H7.25ZM5 15.25C5 14.0074 6.00736 13 7.25 13H9.75C10.9926 13 12 14.0074 12 15.25V24.75C12 25.9926 10.9926 27 9.75 27H7.25C6.00736 27 5 25.9926 5 24.75V15.25Z" fill={props.color ?? '#fff'} />
      <Path fillRule="evenodd" clipRule="evenodd" d="M16.25 12C16.1119 12 16 12.1119 16 12.25V24.75C16 24.8881 16.1119 25 16.25 25H18.75C18.8881 25 19 24.8881 19 24.75V12.25C19 12.1119 18.8881 12 18.75 12H16.25ZM14 12.25C14 11.0074 15.0074 10 16.25 10H18.75C19.9926 10 21 11.0074 21 12.25V24.75C21 25.9926 19.9926 27 18.75 27H16.25C15.0074 27 14 25.9926 14 24.75V12.25Z" fill={props.color ?? '#fff'} />
      <Path fillRule="evenodd" clipRule="evenodd" d="M25.2275 8C25.0894 8 24.9775 8.11193 24.9775 8.25V24.75C24.9775 24.8881 25.0894 25 25.2275 25H27.7275C27.8655 25 27.9775 24.8881 27.9775 24.75V8.25C27.9775 8.11193 27.8655 8 27.7275 8H25.2275ZM22.9775 8.25C22.9775 7.00736 23.9848 6 25.2275 6H27.7275C28.9701 6 29.9775 7.00736 29.9775 8.25V24.75C29.9775 25.9926 28.9701 27 27.7275 27H25.2275C23.9848 27 22.9775 25.9926 22.9775 24.75V8.25Z" fill={props.color ?? '#fff'} />
    </Svg>

  );
}

export default SvgBars;
