import { useRouteMatch } from 'src/router'
import { ROUTES, DNARoute } from 'src/router/routes'

type useCurrentPage = (opts?: { exact?: boolean}) => DNARoute | undefined

const useCurrentPage: useCurrentPage = (opts = { exact: true }) => {
  const routeMatch = useRouteMatch

  const matchedPath = Object
    .values(ROUTES)
    .find(route => {
      const match = routeMatch({ path: route.PATH, ...opts })

      // Skip the home path (/) if exact is not enabled
      if (match?.path === ROUTES.HOME.PATH && !opts.exact) {
        return;
      }

      return match
    })

  return matchedPath
}

export default useCurrentPage
