/* eslint-disable max-len */
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgLetter(props: SvgProps) {
  return (
    <Svg
      viewBox="0 0 32 32"
      width={32}
      height={32}
      fill="none"
      // @ts-ignore
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 7C4.67157 7 4 7.67157 4 8.5V23.5C4 24.3284 4.67157 25 5.5 25H26.5C27.3284 25 28 24.3284 28 23.5V8.5C28 7.67157 27.3284 7 26.5 7H5.5ZM2 8.5C2 6.567 3.567 5 5.5 5H26.5C28.433 5 30 6.567 30 8.5V23.5C30 25.433 28.433 27 26.5 27H5.5C3.567 27 2 25.433 2 23.5V8.5Z"
        fill={props.color ?? '#fff'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.21069 9.38611C6.54976 8.95016 7.17804 8.87162 7.61399 9.21069L16 15.7332L24.3861 9.21069C24.8221 8.87162 25.4503 8.95016 25.7894 9.38611C26.1285 9.82205 26.0499 10.4503 25.614 10.7894L16.614 17.7894C16.2529 18.0703 15.7472 18.0703 15.3861 17.7894L6.38611 10.7894C5.95016 10.4503 5.87162 9.82205 6.21069 9.38611Z"
        fill={props.color ?? '#fff'}
      />
    </Svg>
  );
}

export default SvgLetter;
