import { Platform } from 'react-native';
import { SessionStatus } from 'src/components/IdleComponent/IdleComponent';

export const defaultDisconnectMsg = {
  type: 'logout',
  msg: 'You’ve been logged out',
  visible: false,
}

export function setDisconnectedDue(reason: SessionStatus) {
  const disconnectedObjReason = reason === SessionStatus.end
    ? { msg: defaultDisconnectMsg.msg }
    : { msg: 'You’ve been logged out due to inactivity.', type: 'timeExpired' };
  sessionStorage.setItem('setDisconnectedDue',
    JSON.stringify({ ...defaultDisconnectMsg, visible: true, ...disconnectedObjReason }),
  );
}

export function setDisconnectedDueDefault() {
  sessionStorage.setItem('setDisconnectedDue', JSON.stringify(defaultDisconnectMsg));
}

export function getDisconnectedDue() {
  return JSON.parse(sessionStorage.getItem('setDisconnectedDue') || '{}');
}

export function cleanDisconnectedDue() {
  // Clean the persisted session ifo after a refresh from the user
  Platform.OS === 'web' && window.addEventListener('beforeunload', () => sessionStorage.clear());
}
