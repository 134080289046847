import React, { ReactElement, useContext, useMemo } from 'react'
import { StyleSheet } from 'react-native';
import { compose } from 'redux'
import { useParams } from 'src/router';
import {
  Iffy,
  DNABox,
  DNAButton,
  DNAText,
  DNADivider,
  luxColors,
} from '@alucio/lux-ui'

import DNADocumentGridList from 'src/components/DNA/GridList/DNADocumentGridList'
import DNAGrid from 'src/components/DNA/Grid/DNAGrid'
import DNAPagination from 'src/components/DNA/Pagination/DNAPagination'
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import DNADocumentFilters, {
  useDNADocumentFilters,
  withDNADocumentFilters,
} from 'src/components/DNA/Document/DNADocumentFilters/DNADocumentFilters'

import docQuery from 'src/state/redux/document/query'
import useDisplayMode, { DisplayModes } from 'src/components/DNA/hooks/useDisplayMode'
import { useAppSettings } from 'src/state/context/AppSettings'
import { useSort, withSort } from 'src/components/DNA/hooks/useSort'
import { useAllDocumentsInstance } from 'src/state/redux/selector/document'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import useFixedHeader from '@alucio/lux-ui/src/hooks/useFixedLayout'
import { useMSLDocumentSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import { DocumentORM } from 'src/types/types';
import { SearchInputContext } from 'src/components/Header/SearchInput/SearchInput';
import GridListTabletHeader from 'src/components/DNA/GridList/GridListTabletHeader'

const styles = StyleSheet.create({
  mobileGridViewContainer: {
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  mobileGridViewHeader: {
    backgroundColor: colors['color-flat-200'],
    paddingHorizontal: 16,
    paddingVertical: 5,
  },
  selectedFilters: {
    backgroundColor: colors['color-brand-compliment-500'],
    borderRadius: 3,
    left: 20,
    paddingHorizontal: 4,
    paddingVertical: 2,
    position: 'absolute',
    top: 0,
  },
});

const useDNALibrary = (searchText?: string) => {
  const { displayMode, displayModeIconName, toggleDisplayMode } = useDisplayMode()
  const { sortSelectorOpts } = useSort()
  const { toggleModal, filterSelectorOpts } = useDNADocumentFilters()

  const filteredDocsSelectorOpts = useMemo(
    () => docQuery.merge(
      docQuery.filters.published, filterSelectorOpts, searchText
        ? { ...sortSelectorOpts, sort: undefined } : sortSelectorOpts),
    [sortSelectorOpts, filterSelectorOpts],
  )

  const allDocsSelectorOpts = useMemo(
    () => docQuery.merge(
      docQuery.filters.published, searchText
        ? { ...sortSelectorOpts, sort: undefined } : sortSelectorOpts),
    [sortSelectorOpts, filterSelectorOpts],
  )

  const isSearch = searchText && searchText !== ''
  const docSearchOpts = useMemo(() => { return { text: searchText || '' }; }, [searchText]);
  const allDocs: DocumentORM[] = useAllDocumentsInstance(allDocsSelectorOpts)
  const allSearchedDocuments: DocumentORM[] = useMSLDocumentSearch(docSearchOpts, allDocsSelectorOpts)
  const allDocuments: DocumentORM[] = isSearch ? allSearchedDocuments : allDocs;

  const filteredSearchDocuments: DocumentORM[] = useMSLDocumentSearch(docSearchOpts, filteredDocsSelectorOpts);
  const filteredDocuments: DocumentORM[] = useAllDocumentsInstance(filteredDocsSelectorOpts);
  const documents = isSearch ? filteredSearchDocuments : filteredDocuments;

  return {
    displayMode,
    displayModeIconName,
    toggleDisplayMode,
    toggleModal,
    selectorOpts: filteredDocsSelectorOpts,
    documents,
    allDocuments,
  }
}

type DNALibraryProps = {
  toggleSearchMessage?: boolean
}

export const DNALibraryDesktop: React.FC<DNALibraryProps> = () => {
  const { searchText } = useParams<{ searchText?: string }>();
  const {
    displayMode,
    displayModeIconName,
    toggleDisplayMode,
    toggleModal,
    selectorOpts,
    documents,
    allDocuments,
  } = useDNALibrary(searchText)
  const docSearchOpts = useMemo(
    () => searchText != null ? { text: searchText } : undefined,
    [searchText],
  )
  const fixedHeaderWidth = useFixedHeader({ offSet: 200 });
  const paginatedContent: ReactElement = (
    <>
      <DNABox appearance="col" spacing="medium">
        <Iffy is={displayMode === DisplayModes.list}>
          <DNADocumentGridList.Paginated
            emptyVariant={EmptyVariant.DocumentListEmptySearch}
          />
        </Iffy>
        <Iffy is={displayMode === DisplayModes.grid}>
          <DNABox>
            <DNAGrid.Paginated
              emptyVariant={EmptyVariant.DocumentGridEmptySearch}
            />
          </DNABox>
        </Iffy>
      </DNABox>
      <DNABox style={{ alignSelf: 'flex-start' }}>
        <DNAPagination.Nav />
      </DNABox>
    </>
  );

  return (
    <DNABox alignX="center">
      <DNADocumentFilters.Modal documents={allDocuments} />
      <DNABox
        fill
        style={{
          // This is a jank workaround to fill the space so the pinkish bg doesn't show
          // flex doesn't work well here, so we calculate the screen height - (header + footer)
          minHeight: 'calc(100vh - 160px)',
          minWidth: 'calc(100vw - 158px)',
          paddingHorizontal: 48,
          paddingVertical: 20,
          maxWidth: displayMode === DisplayModes.grid ? fixedHeaderWidth : undefined,
        }}
      >
        <DNABox fill spacing="medium" appearance="col">
          {/* Header */}
          <DNABox alignY="center" spacing="between" style={{ maxWidth: fixedHeaderWidth }}>
            {
              searchText != null
                ? (
                  <DNAText testID="page-title" h5 status="secondary">
                    Search results for "{searchText}" | {documents.length} result{documents.length === 1 ? '' : 's'}
                  </DNAText>
                )
                : <DNAText testID="page-title" h5 status="secondary">Library</DNAText>
            }
            <DNABox spacing="medium">
              <DNAButton
                testID="header-filters-button"
                size="large"
                status="brand"
                appearance="outline"
                iconLeft="filter-variant"
                onPress={toggleModal}
              >
                Filters
              </DNAButton>
              <DNAButton
                size="large"
                status="brand"
                appearance="outline"
                context="round"
                iconLeft={displayModeIconName}
                onPress={toggleDisplayMode}
              />
            </DNABox>
          </DNABox>
          <DNADivider />
          <DNABox spacing="between" alignY="start" childFill={0} style={{ maxWidth: fixedHeaderWidth }}>
            <DNADocumentFilters.Chips documents={documents} />
          </DNABox>
          {/* PAGINATED CONTENT */}
          {
            <DNAPagination docSearchOptions={docSearchOpts} query={selectorOpts}>
              {paginatedContent}
            </DNAPagination>
          }
        </DNABox>
      </DNABox>
    </DNABox>
  );
}

export const DNALibraryTablet: React.FC<DNALibraryProps> = (props) => {
  const { toggleSearchMessage } = props
  const { searchText } = useContext(SearchInputContext)
  const { displayMode, documents } = useDNALibrary(searchText)

  if (toggleSearchMessage) {
    return (
      <DNABox fill style={{ minWidth: 'fit-content' }}>
        <DNAEmpty emptyVariant={EmptyVariant.SearchInstructions} />
      </DNABox>
    )
  }

  const paginatedContent: ReactElement = (
    <DNABox
      appearance="col"
      fill
      style={{ minWidth: 'fit-content' }}
    >
      <GridListTabletHeader
        searchText={searchText}
        totalRecordCount={documents.length}
      />
      <DNADivider />

      <Iffy is={displayMode === DisplayModes.list}>
        <DNADocumentGridList
          emptyVariant={EmptyVariant.DocumentListEmptySearch}
          documents={documents}
        />
      </Iffy>
      <Iffy is={displayMode === DisplayModes.grid}>
        <DNABox fill appearance="col" spacing="medium">
          {/* CONTENT */}
          <DNABox style={styles.mobileGridViewContainer}>
            <DNAGrid
              emptyVariant={EmptyVariant.DocumentGridEmptySearch}
              items={documents}
            />
          </DNABox>
        </DNABox>
      </Iffy>
    </DNABox>
  );

  return (
    <DNABox fill style={{ backgroundColor: luxColors.info.primary, overflow: 'scroll' }}>
      <DNADocumentFilters.Modal documents={documents} />
      {paginatedContent}
    </DNABox>
  )
}

const DNALibrarySwitcher: React.FC<{ toggleSearchMessage?: boolean }> = (props) => {
  const { deviceMode } = useAppSettings()

  return deviceMode === 'desktop'
    ? <DNALibraryDesktop {...props} />
    : <DNALibraryTablet {...props} />
}

export default compose(
  withDNADocumentFilters,
  withSort,
)(DNALibrarySwitcher)
