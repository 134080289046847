/* eslint-disable max-len */
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgFolder(props: SvgProps) {
  return (
    <Svg
      viewBox="0 0 32 32"
      width={32}
      height={32}
      fill="none"
      // @ts-ignore
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 6A1.5 1.5 0 003 7.5v17A1.5 1.5 0 004.5 26h23a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0027.5 8H13.757a3.5 3.5 0 01-1.942-.588l-1.74-1.16A1.5 1.5 0 009.243 6H4.5zm-2.475-.975A3.5 3.5 0 014.5 4h4.743a3.5 3.5 0 011.942.588l1.74 1.16a1.5 1.5 0 00.832.252H27.5A3.5 3.5 0 0131 9.5v15a3.5 3.5 0 01-3.5 3.5h-23A3.5 3.5 0 011 24.5v-17a3.5 3.5 0 011.025-2.475z"
        fill={props.color ?? '#fff'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12a1 1 0 011-1h28a1 1 0 110 2H2a1 1 0 01-1-1z"
        fill={props.color ?? '#fff'}
      />
    </Svg>
  );
}

export const PublisherSvgFolder = (props: SvgProps) => {
  return (
    <Svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      // @ts-ignore
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path fillRule="evenodd" clipRule="evenodd" d="M4.5 6C4.10218 6 3.72064 6.15804 3.43934 6.43934C3.15804 6.72064 3 7.10218 3 7.5V24.5C3 24.8978 3.15804 25.2794 3.43934 25.5607C3.72064 25.842 4.10218 26 4.5 26H27.5C27.8978 26 28.2794 25.842 28.5607 25.5607C28.842 25.2794 29 24.8978 29 24.5V9.5C29 9.10218 28.842 8.72064 28.5607 8.43934C28.2794 8.15804 27.8978 8 27.5 8H13.7569C13.0658 7.99998 12.3902 7.7954 11.8153 7.41205C11.8153 7.41203 11.8152 7.41202 11.8152 7.412L10.0752 6.252C9.82882 6.0877 9.53929 6.00001 9.24312 6C9.24312 6 9.24313 6 9.24312 6H4.5ZM2.02513 5.02513C2.6815 4.36875 3.57174 4 4.5 4H9.24312C9.93416 4.00002 10.6098 4.2046 11.1847 4.58795C11.1847 4.58797 11.1848 4.58798 11.1848 4.588L12.9248 5.748C13.1712 5.9123 13.4607 5.99999 13.7569 6C13.7569 6 13.7569 6 13.7569 6H27.5C28.4283 6 29.3185 6.36875 29.9749 7.02513C30.6313 7.6815 31 8.57174 31 9.5V24.5C31 25.4283 30.6313 26.3185 29.9749 26.9749C29.3185 27.6313 28.4283 28 27.5 28H4.5C3.57174 28 2.6815 27.6313 2.02513 26.9749C1.36875 26.3185 1 25.4283 1 24.5V7.5C1 6.57174 1.36875 5.6815 2.02513 5.02513Z" fill={props.color ?? '#fff'} />
      <Path fillRule="evenodd" clipRule="evenodd" d="M1 12C1 11.4477 1.44772 11 2 11H30C30.5523 11 31 11.4477 31 12C31 12.5523 30.5523 13 30 13H2C1.44772 13 1 12.5523 1 12Z" fill={props.color ?? '#fff'}/>
    </Svg>
  );
}

export default SvgFolder;
