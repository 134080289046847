import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import im from 'immer';
import { Dispatch } from 'redux';
import { ShareType } from '@alucio/aws-beacon-amplify/src/models';
import { emailTemplatePreview } from 'src/utils/shareLink/shareLink.web';
import { drawerActions, DRAWER_ENTITIES } from 'src/state/redux/slice/drawer';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import DNACommonConfirmation from '../Modal/DNACommonConfirmation';
import { emailTemplateActions } from 'src/state/redux/slice/emailTemplate';
import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui';
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast';
import DNAEmailTemplateDeleteModal from '../Modal/DNAEmailTemplateDeleteModal';
import { EmailTemplateORM, getAssociatedFileTitle } from 'src/types/types';

export type EmailTemplateContextOptions = {
  [key in keyof typeof EmailTemplateContextActions]: {
    title: string,
    icon: string,
    onPress: (d: Dispatch, f: EmailTemplateORM, toast?: ToastActions) => () => void
  }
}

export type BindEmailTemplateContextActions = {
  [key in keyof typeof EmailTemplateContextActions]: (f: EmailTemplateORM) => () => void
}

export enum EmailTemplateContextActions {
  edit = 'Edit',
  preview = 'Preview',
  deactivate = 'Deactivate',
  delete = 'Delete',
}

export type EmailTemplateActions = (keyof typeof EmailTemplateContextActions)

export const useDNAEmailTemplateActions = (): BindEmailTemplateContextActions => {
  const dispatch = useDispatch();
  const toast = useToast();

  const bound = useMemo(() => im(emailTemplateContextActions, draft => {
    for (const action in emailTemplateContextActions) {
      const actionTyped = action as keyof typeof EmailTemplateContextActions
      draft[action] = (emailTemplateORM: EmailTemplateORM) =>
        emailTemplateContextActions[actionTyped].onPress(dispatch, emailTemplateORM, toast)
    }
  }) as unknown as BindEmailTemplateContextActions,
  [dispatch],
  );

  return bound
};

/** This is specific to the Context Menus */
export const emailTemplateContextActions: EmailTemplateContextOptions = {
  edit: {
    icon: 'pencil',
    title: EmailTemplateContextActions.edit,
    onPress: (dispatch, emailTemplateORM: EmailTemplateORM) => () => {
      dispatch(drawerActions.toggle({
        entityId: emailTemplateORM.model.id,
        entity: DRAWER_ENTITIES.EMAIL_TEMPLATE,
      }));
    },
  },
  preview: {
    icon: 'eye',
    title: EmailTemplateContextActions.preview,
    onPress: (_, emailTemplateORM: EmailTemplateORM) => () => {
      const activeAssociatedFiles = emailTemplateORM.relations.associatedFiles?.
        filter(af => af.meta.canBeSharedByMSL)
        .map(af => ({
          title: getAssociatedFileTitle(af),
          contentId: af.file?.id!,
          sharedContentId: af.model.id,
          type: af.model.type === 'ATTACHED_FILE' ? ShareType.ATTACHED_FILE : ShareType.DOCUMENT_VERSION,
        }))
      emailTemplatePreview(
        emailTemplateORM.model.subject,
        emailTemplateORM.model.body,
        emailTemplateORM.model.cc,
        emailTemplateORM.model.bcc,
        activeAssociatedFiles);
    },
  },
  deactivate: {
    icon: 'close-octagon-outline',
    title: EmailTemplateContextActions.deactivate,
    onPress: (dispatch, emailTemplateORM, toast) => () => {
      const payload = {
        isVisible: true,
        allowBackdropCancel: false,
        component: () =>
          (<DNACommonConfirmation
            status="danger"
            cancelText="Cancel"
            descriptionText="Once deactivated, this will no longer be available to the end users."
            confirmActionText="Deactivate"
            onConfirmAction={() => {
              dispatch(emailTemplateActions.deactivate(emailTemplateORM.model))
            toast?.add(
              <GenericToast
                title="Email template deactivated"
                status="error"
              />,
              ToastOrientations.TOP_RIGHT,
            );
            }}
            title="Confirm deactivation?"
          />),
      };

      dispatch(DNAModalActions.setModal(payload));
    },
  },
  delete: {
    icon: 'trash-can-outline',
    title: EmailTemplateContextActions.delete,
    onPress: (dispatch, emailTemplateORM, toast) => () => {
      const payload = {
        isVisible: true,
        allowBackdropCancel: false,
        component: () =>
          (<DNAEmailTemplateDeleteModal toast={toast} emailTemplate={emailTemplateORM.model} />),
      };

      dispatch(DNAModalActions.setModal(payload));
    },
  },
};
