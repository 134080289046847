import React from 'react';
import { useDispatch } from 'react-redux';

import { DNABox, DNAButton, DNAModal, DNAText, Iffy, luxColors, LuxStatus } from '@alucio/lux-ui';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';

interface Props {
  descriptionText?: string;
  onConfirmAction?: () => void;
  cancelText?: string;
  confirmActionText?: string;
  status?: LuxStatus;
  title?: string;
}

const DNACommonConfirmation = (props: Props) => {
  const { onConfirmAction, title, status = 'primary' } = props;
  const dispatch = useDispatch();

  function closeModal(): void {
    dispatch(DNAModalActions.closeModal());
  }

  function onAction(): void {
    onConfirmAction && onConfirmAction();
    closeModal();
  }

  return (
    <DNAModal>
      <DNAModal.Header onClose={closeModal}>
        <DNAText h5>{title}</DNAText>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox style={{ padding: 8, minWidth: 384 }}>
          <DNAText style={{ color: luxColors.flat.primary }}>{props.descriptionText}</DNAText>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <DNAButton status="flat" onPress={closeModal} size="small">
          <DNAText status="flat">{props.cancelText}</DNAText>
        </DNAButton>
        <Iffy is={props.onConfirmAction}>
          <DNAButton onPress={onAction} status={status} size="small">{props.confirmActionText}</DNAButton>
        </Iffy>
      </DNAModal.Confirmation>
    </DNAModal>
  )
};

export default DNACommonConfirmation;
