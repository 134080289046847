import React from 'react';
import {
  Iffy,
  Icon,
  Popover,
  IndexPath,
  util,
  DNAText,
  DNABox,
  DNASelect,
} from '@alucio/lux-ui';

import { styles } from './Styles';
import { StyleProp, ViewStyle } from 'react-native';
import { FieldConfig } from '@alucio/aws-beacon-amplify/src/models';

interface GroupSelectInputProps {
  availableValues: { [key: string]: string[]};
  descriptionText?: string;
  disabled?: boolean;
  onChangeSelection: (
    value: string[] | string,
    field?: FieldConfig
  ) => void;
  required?: boolean;
  status?: string;
  titleColor?: string;
  style?: StyleProp<ViewStyle>;
  removeMarginPadding?: boolean;
  title: string;
  value: string;
  field?: FieldConfig
}

/**
 * [NOTE] - While this is named GroupSelectInput, it really only handles one value per row
 *          More specifically, this is just a select component that
 *            1. Handles title/value in each row
 *            2. Interfaces to be form friendly (Zod currently)
 */
export default function GroupSelectInputComponent(props: GroupSelectInputProps) {
  const {
    availableValues,
    descriptionText,
    onChangeSelection,
    required = true,
    status,
    title,
    titleColor,
    removeMarginPadding,
    disabled = false,
    value,
    field,
    ...rest
  } = props;

  const keyValues = Object.keys(availableValues)
  const selectedIndex = keyValues.findIndex(keyValue => keyValue === value)
  const selectedValue = availableValues[keyValues[selectedIndex]]?.[0]

  const handleOnSelect = (e: IndexPath | IndexPath[]) => {
    if (!Array.isArray(e)) {
      onChangeSelection(keyValues[e.row])
    }
  }

  return (
    <DNABox
      fill
      style={util.mergeStyles(undefined, styles.Container, [styles.NoMarginPadding, removeMarginPadding])}
    >
      <DNABox style={styles.TitleContainer}>
        <DNABox>
          <DNAText style={styles.required}>{required ? '*' : ''}</DNAText>
        </DNABox>
        <DNABox>
          <DNAText
            style={
              util.mergeStyles(
                undefined,
                styles.Title,
                [{ color: titleColor }, titleColor],
              )
            }
          >{title}</DNAText>
        </DNABox>
        <Iffy is={descriptionText}>
          <DNABox>
            <Popover>
              <Popover.Anchor>
                <Icon style={styles.HelpToolTipIconStyle} name="help-circle" />
              </Popover.Anchor>
              <Popover.Content>
                <DNAText style={{ color: 'white' }}>{descriptionText}</DNAText>
              </Popover.Content>
            </Popover>
          </DNABox>
        </Iffy>
      </DNABox>

      <DNABox fill>
        <DNASelect
          size="large"
          disabled={disabled}
          status="flat"
          selectedIndex={selectedIndex !== -1 ? new IndexPath(selectedIndex) : undefined }
          onSelect={handleOnSelect}
          style={{ flex: 1 }}
          value={selectedValue}
          {...rest}
        >
          {
            Object
              .entries(availableValues)
              .map(([key, value]) => (
                <DNASelect.Item
                  title={() => (
                    <DNABox appearance="col" style={{ marginHorizontal: 14 }}>
                      <DNAText>{value[0]}</DNAText>
                      <DNAText status="subtle" b2>{key}</DNAText>
                    </DNABox>
                  )}
                />
              ))
          }
        </DNASelect>
      </DNABox>
    </DNABox>
  );
}
