import { DNABox, DNACard, DNADivider, DNAText, GridList } from '@alucio/lux-ui';
import format from 'date-fns/format';
import React from 'react';
import { StyleSheet } from 'react-native';
import { MeetingORM } from 'src/types/types';
import ContentPresentedList from './ContentPresentedList';
import MeetingContextMenu from './MeetingContextMenu';

const S = StyleSheet.create({
  HeaderCol: {
    paddingVertical: 12,
    alignItems: 'flex-start',
  },
  PaddedContentRow: {
    paddingLeft: 30,
  },
  Row: {
    flexBasis: 'auto',
  },
});

interface MeetingHistoryListRowProps {
  data: MeetingORM,
}
const MeetingHistoryListRow : React.FC<MeetingHistoryListRowProps> = (props) => {
  const { startTime, endTime, title, contentPresented } = props.data.model;
  return (
    <GridList.Row>
      <GridList.Col style={S.HeaderCol} span={1}>
        <DNABox appearance="col">
          <DNAText>{format(new Date(startTime), 'LLL d, y')}</DNAText>
          <DNAText>{format(new Date(startTime), 'p')}</DNAText>
        </DNABox>
      </GridList.Col>
      <GridList.Col style={S.HeaderCol} span={1}>
        <DNABox appearance="col">
          <DNAText>{endTime ? format(new Date(endTime), 'LLL d, y') : ''}</DNAText>
          <DNAText>{endTime ? format(new Date(endTime), 'p') : ''}</DNAText>
        </DNABox>
      </GridList.Col>
      <GridList.Col style={S.HeaderCol} span={1}>
        <DNAText numberOfLines={2}>{title}</DNAText>
      </GridList.Col>
      <GridList.Col style={S.HeaderCol} span={1}>
        <DNABox appearance="col">
          <ContentPresentedList isEditForm={false} contentPresented={contentPresented || []} />
        </DNABox>
      </GridList.Col>
      <GridList.Col style={S.HeaderCol} span={1}>
        <MeetingContextMenu meetingORM={props.data} />
      </GridList.Col>
    </GridList.Row>
  )
}

const headers = ['Start Time', 'End Time', 'Title', 'Content Presented'];

interface MeetingTableProps {
  data: MeetingORM[],
}
const MeetingTable: React.FC<MeetingTableProps> = (props) => {
  const { data } = props;

  return (
    <DNACard
      appearance="float"
      style={{ paddingHorizontal: 16, paddingVertical: 8, overflow: 'hidden', borderRadius: 8 }}
    >
      <GridList
        cols={'150px 150px minmax(200px,500px) minmax(200px,700px) 50px'}
        gap="12px"
        style={{ width: '100%' }}
      >
        <GridList.Header>
          {
              headers.map((header) => {
                return (
                  <GridList.Col key={header} style={S.HeaderCol} span={1}>
                    <DNAText b2 bold status="flat">{header}</DNAText>
                  </GridList.Col>
                )
              })
            }
        </GridList.Header>
        {
            data.map((meeting) => {
              return (
                <React.Fragment key={meeting.model.id}>
                  <DNADivider />
                  <MeetingHistoryListRow data={meeting}/>
                </React.Fragment>
              )
            })
          }
      </GridList>
    </DNACard>
  )
}

export default MeetingTable;
