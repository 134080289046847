import React, { useState } from 'react';
import { DNABox, DNAButton, DNACheckbox, DNAModal, DNAText } from '@alucio/lux-ui';
import { DNAModalVariant } from '../DNAConnectedModal';
import { useTenant } from 'src/state/redux/selector/tenant';
import ActiveUser from 'src/state/global/ActiveUser';
interface Props {
  onSave?: () => void;
}

const defaultMessage = 'I confirm the required pages/slides that I have hidden\
 are duplicates within this presentation.';
const RequiredSlidesHiddenConfirmation: DNAModalVariant<Props> = (props) => {
  const { toggleModal, onSave } = props
  const tenant = useTenant(ActiveUser.user!.tenantId);
  const message = tenant?.tenant.config?.requiredSlidesHiddenMessage;

  const handleCancel = () => {
    toggleModal()
  }

  const handleSave = () => {
    toggleModal()
    onSave && onSave()
  }

  const [checked, setChecked] = useState(false);

  const toggleCheck = () => {
    setChecked((prev) => !prev);
  }

  return (
    <DNAModal>
      <DNAModal.Header>
        <DNAText h5>Required slides were hidden</DNAText>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox appearance="row" style={{ width: 512 }} alignY="start">
          <DNACheckbox checked={checked} onChange={toggleCheck} style={{ marginRight: 11 }}/>
          <DNAText>{message ?? defaultMessage}</DNAText>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <DNAButton size="small" status="flat" onPress={handleCancel}>
          Cancel
        </DNAButton>
        <DNAButton size="small" onPress={handleSave} status="primary" disabled={!checked}>
          Save
        </DNAButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
};

export default RequiredSlidesHiddenConfirmation;
