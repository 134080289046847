// [TODO] - Not sure if anything is using this Row Variant at the moment
import React from 'react'
import { DocumentVersionORM, FolderItemORM } from 'src/types/types'
import { useDocumentTitle } from 'src/hooks/useDocumentTitle/useDocumentTitle'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import { DocumentStatus } from '@alucio/aws-beacon-amplify/src/models';
import { StyleSheet } from 'react-native';
import { useDNADocumentVersionActions } from 'src/components/DNA/Document/DNADocumentVersion.actions'

import { DNABox, DNACard, DNAText, DNAButton, Iffy, useTheme, DNAChip, luxColors } from '@alucio/lux-ui'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import DNADocumentVersionContextMenu from 'src/components/DNA/Document/DNADocumentVersionContextMenu'
import DNADocumentVersionUpdateButton from 'src/components/DNA/Document/DNADocumentVersionUpdateButton'
import { CustomLabelDisplay } from './CustomLabelDisplay'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';

export type DNADocumentVersionDefaultRowProps = {
  isNetworkConnected?: boolean,
}

const styles = StyleSheet.create({
  bookmarkButtonPWAWrapper: {
    marginRight: 10,
    paddingRight: 30,
    width: 22,
  },
});

const DNADocumentFolderItemDefaultRow: DNARowsComponent<
  FolderItemORM,
  DNADocumentVersionDefaultRowProps
> = (props) => {
  const {
    ORM: folderItem,
    onPress,
    style,
    isDesktop = true,
    isNetworkConnected,
  } = props
  const theme = useTheme()
  // [TODO] - I think we need to revisit this? A Folder Item should not be limited to a DocVerORM
  const documentVersionORM = (folderItem.relations.item as DocumentVersionORM)
  const isBookmarked = documentVersionORM.relations.document.meta.bookmark.isBookmarked
  const documentVerActions = useDNADocumentVersionActions()
  const route = useCurrentPage({ exact: false })
  const { status: docStatus } = documentVersionORM.relations.document.model
  const { visiblePages } = folderItem.model
  const isModified =
    visiblePages?.length &&
    documentVersionORM.model.canHideSlides &&
    documentVersionORM.model.status === 'PUBLISHED'
  const title = useDocumentTitle(documentVersionORM, folderItem)
  const isLatestPublished = documentVersionORM.meta.version.isLatestPublished
  // [TODO-ORM] - We should have a property that combines isOnline and isContentCached
  //               on the DocumentVersionORM rather than checking both at the component level
  const unavailableContent = !isNetworkConnected && !documentVersionORM.meta.assets.isContentCached;
  const canPresent = documentVersionORM.relations.document.meta.permissions.MSLPresent
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const handleOnPress = () => {
    documentVerActions.present(documentVersionORM, folderItem)()
  }

  // If the publisher's selected cover page was hidden by the user
  // we use the first visible page
  // [TODO-2126] - We should be able calculate this at the ORM level instead
  let customThumbnailKey: string | undefined
  const selectedThumbnail = documentVersionORM.model.selectedThumbnail ?? 1
  if (folderItem.model.visiblePages && !folderItem.model.visiblePages.find(vp => vp === selectedThumbnail)) {
    const customPage = documentVersionORM.model.pages[folderItem.model.visiblePages[0] - 1]
    customThumbnailKey = detectArchivedFileKeyPath(documentVersionORM.model, customPage) ?? undefined
  }

  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={() => {
        onPress?.(folderItem);
      }}
      style={[
        {
          paddingHorizontal: theme['row-padding-horizontal'],
          paddingVertical: theme['row-padding-vertical'],
        },
        style,
      ]}
    >
      <DNABox
        testID="folder-item-row"
        alignY="center"
        spacing={isDesktop ? 'between' : 'medium'}
        childFill={1}
      >
        <Iffy is={!isDesktop}>
          <DNABox spacing="medium">
            {/* Bookmark Button */}
            <DNABox
              style={styles.bookmarkButtonPWAWrapper}
            >
              <Iffy is={isLatestPublished && !unavailableContent}>
                <DNAButton
                  status="subtle"
                  appearance="ghostAlt"
                  context="roundTablet"
                  onPress={documentVerActions.bookmark(documentVersionORM)}
                  iconLeft={isBookmarked ? 'bookmark' : 'bookmark-outline'}
                  size="xlarge"
                />
              </Iffy>
            </DNABox>
            <DNADocumentVersionContextMenu
              folderItemORM={folderItem}
              unavailableContent={unavailableContent}
            >
              <DNAButton
                status="subtle"
                appearance="ghostAlt"
                context={isDesktop ? 'minimum' : 'roundTablet'}
                iconLeft="dots-vertical"
                testID="folder-item-ellipsis"
                size={isDesktop ? undefined : 'xlarge'}
              />
            </DNADocumentVersionContextMenu>
          </DNABox>
        </Iffy>
        <DNABox
          spacing="large"
          alignY="center"
          childFill={1}
          style={{ opacity: unavailableContent ? 0.4 : 1 }}
        >
          <DNADocumentThumbnail
            documentVersionORM={documentVersionORM}
            width={76}
            height={44}
            unavailableContent={unavailableContent}
            customThumbnailKey={customThumbnailKey}
            onPress={canPresent ? handleOnPress : undefined}
          />
          {/* Title */}
          <DNABox
            spacing="small"
            appearance="col"
            childFill
            style={{ paddingRight: 16 }}
          >
            <DNAText testID="folder-item-title" b1 numberOfLines={1}>
              {title}
            </DNAText>
            <DNABox
              alignY="center"
              spacing="small"
              fill
              childStyle={[4, { flex: 1 }]}
            >
              <DNABox appearance="row">
                <DNADocumentChip
                  item={documentVersionORM.relations.document}
                  variant="purpose"
                />
                <Iffy is={isPublisherRoute}>
                  <DNABox style={{ marginHorizontal: 8 }}>
                    <DNADocumentChip
                      item={documentVersionORM.relations.document}
                      variant="status"
                    />
                  </DNABox>
                </Iffy>
              </DNABox>
              <Iffy is={docStatus === DocumentStatus.ARCHIVED}>
                <DNAChip appearance="tag" status="subtle">
                  {docStatus}
                </DNAChip>
              </Iffy>
              <Iffy is={isModified}>
                <DNAChip appearance="tag" status="subtle">
                  MODIFIED
                </DNAChip>
              </Iffy>
              <Iffy is={unavailableContent}>
                <DNAChip
                  appearance="tag"
                  style={{ backgroundColor: luxColors.basicBlack.primary }}
                >
                  CONTENT NOT AVAILABLE
                </DNAChip>
              </Iffy>
              <CustomLabelDisplay
                documentORM={documentVersionORM.relations.document}
              />
            </DNABox>
          </DNABox>
        </DNABox>
        {/* Actions */}
        <Iffy is={isDesktop}>
          <DNABox>
            <DNADocumentVersionUpdateButton orm={folderItem} />
            {/* Bookmark Button */}
            <Iffy is={isLatestPublished && !unavailableContent && !isPublisherRoute}>
              <DNAButton
                status="subtle"
                appearance="ghostAlt"
                context="minimum"
                style={{ width: 32, height: 32 }}
                onPress={documentVerActions.bookmark(documentVersionORM)}
                iconLeft={isBookmarked ? 'bookmark' : 'bookmark-outline'}
              />
            </Iffy>
            <DNADocumentVersionContextMenu
              folderItemORM={folderItem}
              unavailableContent={unavailableContent}
            >
              <DNAButton
                status="subtle"
                appearance="ghostAlt"
                context="minimum"
                style={{ width: 32, height: 32 }}
                iconLeft="dots-vertical"
              />
            </DNADocumentVersionContextMenu>
          </DNABox>
        </Iffy>
        <Iffy is={!isDesktop}>
          {/* Update Version Notification */}
          <DNADocumentVersionUpdateButton orm={folderItem} />
        </Iffy>
      </DNABox>
    </DNACard>
  );
}

export default DNADocumentFolderItemDefaultRow
