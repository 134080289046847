import React, { useState } from 'react'
import { ScrollView } from 'react-native'
import { DNABox, Iffy } from '@alucio/lux-ui'

import DNANavigationMenu from 'src/components/DNA/NavigationMenu/DNANavigationMenu'
import DNAAppHeader from 'src/components/DNA/Header/DNAAppHeader'
import DNAFooter from 'src/components/Footer/Footer'
import NetworkConnectionBanner from 'src/components/NetworkConnection/NetworkConnectionBanner'

import { useAppSettings, useOnlineStatus } from 'src/state/context/AppSettings'
import { useLocation } from 'react-router'
import InfoPanel from 'src/components/InfoPanel/InfoPanel'
import { RootState } from 'src/state/redux/store'
import { useSelector } from 'react-redux'

const AppContainerShared = () => {
  const onlineStatus = useOnlineStatus()
  const isNetworkConnected = onlineStatus
  return {
    isNetworkConnected,
  }
}

const AppContainerDesktop: React.FC = (props) => {
  const { isNetworkConnected } = AppContainerShared()
  const { pathname } = useLocation()
  const [width, setWidth] = useState(0)
  const lowerCasedPathName = pathname.toLowerCase()
  const enableHorizontalScroll = ['/publisher', '/library', '/bookmarks'].includes(lowerCasedPathName) ||
    lowerCasedPathName.includes('/search') || lowerCasedPathName.includes('/publisher-search')
  const { activeItemORM } = useSelector((state: RootState) => state.infoPanel)

  return (
    <DNABox fill appearance="col">
      <DNAAppHeader />
      <DNABox fill style={{ zIndex: -1 }}>
        <DNANavigationMenu />
        <DNABox appearance="col" fill>
          <DNABox>
            <Iffy is={!isNetworkConnected}>
              <NetworkConnectionBanner />
            </Iffy>
          </DNABox>
          <DNABox fill>
            <ScrollView
              contentContainerStyle={{
                flex: 1,
                justifyContent: 'space-between',
                // @ts-ignore
                overflowX: 'auto',
                zIndex: -1,
              }}
            >
              <Iffy is={enableHorizontalScroll}>
                <DNABox
                  appearance="row"
                  onLayout={({ nativeEvent }) => {
                    // @ts-ignore
                    const { scrollWidth = 0 } = nativeEvent.target as { scrollWidth: number }
                    setWidth(scrollWidth)
                  }}
                >
                  {props.children}
                </DNABox>
                <DNAFooter width={width} />
              </Iffy>
              <Iffy is={!enableHorizontalScroll}>
                {props.children}
                <DNAFooter />
              </Iffy>
            </ScrollView>
          </DNABox>
        </DNABox>
        <InfoPanel
          variant="Folder"
          itemORM={activeItemORM}
        />
      </DNABox>
    </DNABox>
  )
}

const AppContainerTablet: React.FC = (props) => {
  const { children } = props
  const { isNetworkConnected } = AppContainerShared()
  const { activeItemORM } = useSelector((state: RootState) => state.infoPanel)

  return (
    <DNABox fill appearance="col">
      {/* DEV Note - Items added under the app header require a zIndex of -1 for the popups to
          show properly */}
      <DNAAppHeader />
      <DNABox fill appearance="col" style={{ zIndex: -1 }}>
        {/* TODO: Investigate a better way of getting these items to have visibility underneath
            popups from the DNAAppHeader besides having to add these zIndex:-1 styles, should
            probably switch to adding a +1 on the app header zindex which would remove the necessity
            for devs to remember to add -1 props here but that could have unintended consequences
            with modals */}
        <DNABox style={{ zIndex: -1 }}>
          <Iffy is={!isNetworkConnected}>
            <NetworkConnectionBanner />
          </Iffy>
        </DNABox>
        <DNABox fill appearance="row">
          <ScrollView style={{ zIndex: -1 }} contentContainerStyle={{ flex: 1, zIndex: -1 }}>
            {children}
          </ScrollView>
          <InfoPanel
            variant="Folder"
            itemORM={activeItemORM}
          />
        </DNABox>
        <DNANavigationMenu />
      </DNABox>
    </DNABox>
  )
}

const AppContainer: React.FC = (props) => {
  const { deviceMode } = useAppSettings()

  return deviceMode === 'desktop'
    ? <AppContainerDesktop {...props} />
    : <AppContainerTablet {...props} />
}

export default AppContainer
