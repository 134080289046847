import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Pressable, Platform, StyleSheet, View, Image } from 'react-native'
import { Icon, Text, util, luxColors, Iffy, DNABox } from '@alucio/lux-ui';
import { presentationControlActions } from 'src/state/redux/slice/PresentationControl/presentationControl'
import { PlayerActions } from '@alucio/video'
import { FileType } from '@alucio/aws-beacon-amplify/src/models';
import { useContentViewerModalState } from '../state/ContentViewerModalStateProvider';
import { SelectionOption } from '../ContentPreviewModal';

interface ActionBarProps {
  onOptionSelected: (option: SelectionOption) => void;
  isPublisherMode: boolean;
  fileExtension: FileType;
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    width: 390,
    alignSelf: 'center',
  },
  icons: {
    flex: 1,
    alignItems: 'center',
    position: 'absolute',
    width: '390',
    height: '64',
    top: -85,
    flexDirection: 'row-reverse',
    backgroundColor: 'rgba(0,0,0,.6)',
    borderRadius: 4,
    marginBottom: 5,
    paddingRight: 8,
  },
  icon: {
    color: luxColors.border.primary,
    opacity: 1.0,
    textAlign: 'center',
    margin: 0,
    width: 30,
    height: 30,
  },
  iconSm: {
    width: 23,
    height: 23,
  },
  iconRotate: {
    transform: [
      { rotate: '-90deg' },
    ],
  },
  button: {
    paddingLeft: 16,
    paddingRight: 8,
    paddingTop: 10,
    paddingBottom: 8,
  },
  slideBtnText: {
    color: luxColors.border.primary,
    opacity: 1.0,
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
  slideCounter: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
  },
  vR: {
    height: 36,
    marginLeft: 8,
    paddingRight: 0,
    borderStyle: 'solid',
    borderWidth: 0,
    borderLeftWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.2)',
  },
})

const iconImage = require('../../../../assets/images/Icons/Molecule.svg')

const SlidesText = {
  [FileType.PDF]: 'Pages',
  [FileType.PPTX]: 'Slides',
};

const ActionBar: React.FC<ActionBarProps> = (props: ActionBarProps) => {
  const { onOptionSelected, isPublisherMode } = props;
  const { totalSlides, adjustedSlideNumber } = useContentViewerModalState()
  const dispatch = useDispatch();

  const isCurrentlyFullScreen = Platform.OS === 'web' &&
    !!((document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement && document.webkitFullscreenElement !== null))
  const [isFullScreen, setIsFullScreen] = useState<boolean>(isCurrentlyFullScreen)

  useEffect(() => {
    function fullScreenListenerHandler(): void {
      if (!document?.fullscreenElement) {
        setIsFullScreen?.(false);
      } else if (document?.fullscreenElement) {
        setIsFullScreen?.(true);
      }
    }

    document?.addEventListener('fullscreenchange', fullScreenListenerHandler);

    return () => document?.removeEventListener('fullscreenchange', fullScreenListenerHandler);
  }, []);

  const fullScreenSupported = Platform.OS === 'web' && (document.documentElement.requestFullscreen ||

    document.documentElement.webkitRequestFullScreen);

  return (
    <View style={styles.container}>
      <View style={styles.icons}>
        <Pressable
          onPress={() => onOptionSelected(isPublisherMode ? 'exit' : 'exit-fullwindow')}
        >
          <DNABox
            testID="content-present-mode-exit-button"
            style={styles.button}
            appearance="col"
            alignX="center"
            alignY="center"
          >
            <Text style={styles.slideBtnText}>EXIT</Text>
          </DNABox>
        </Pressable>
        <View style={styles.vR} />
        <Iffy is={fullScreenSupported}>
          <Pressable
            onPress={() => {
              setIsFullScreen(currVal => {
                onOptionSelected(
                  currVal
                    ? 'exit-fullscreen'
                    : 'enter-fullscreen',
                );
                return !currVal
              })
            }}
          >
            <DNABox
              testID="content-present-mode-fullscreen-button"
              style={styles.button}
              appearance="col"
              alignX="center"
              alignY="center"
            >
              <Icon
                name={isFullScreen ? 'fullscreen-exit' : 'fullscreen'}
                style={util.mergeStyles(undefined, styles.icon, styles.iconSm, styles.iconRotate)}
              />
              <Text style={styles.slideBtnText}>{isFullScreen ? 'Exit full screen' : 'Full screen'}</Text>
            </DNABox>
          </Pressable>
        </Iffy>
        <Pressable
          onPress={useCallback(() => onOptionSelected('slides'), [])}
        >
          <DNABox style={styles.button} appearance="col" alignX="center" alignY="center">
            <Icon
              name={'content-copy'}
              style={util.mergeStyles(
                undefined,
                styles.icon,
                styles.iconSm,
                styles.iconRotate,
              )}
            />
            <Text style={styles.slideBtnText}>{SlidesText[props.fileExtension]}</Text>
          </DNABox>
        </Pressable>
        {/* My Content Panel */}
        <Pressable
          onPress={useCallback(() => onOptionSelected('content-panel'), [])}
        >
          <DNABox style={styles.button} appearance="col" alignX="center" alignY="center">
            <Image
              source={iconImage}
              style={ {
                height: 23,
                width: 23,
              }}
            />
            <Text style={styles.slideBtnText}>My Content</Text>
          </DNABox>
        </Pressable>
        <View style={styles.vR} />
        <Pressable
          onPress={useCallback(
            () => dispatch(presentationControlActions.triggerPresentationAction({ action: PlayerActions.next })), [],
          )}
        >
          <View>
            <Icon name={'chevron-right'} style={styles.icon} />
          </View>
        </Pressable>
        <Text style={styles.slideCounter}>{adjustedSlideNumber} of {totalSlides}</Text>
        <Pressable
          onPress={useCallback(
            () => dispatch(presentationControlActions.triggerPresentationAction({ action: PlayerActions.prev })), [],
          )}
        >
          <View>
            <Icon name={'chevron-left'} style={[styles.icon, { marginLeft: 20 }]} />
          </View>
        </Pressable>
      </View>
    </View>
  )
}

export default ActionBar
