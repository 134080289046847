import React, { useState } from 'react'
import { Text, DNAButton, DNABox, DNASlider, DNAHeader } from '@alucio/lux-ui'
import { useAppSettings } from 'src/state/context/AppSettings'
import FAQScreen from 'src/screens/FAQ/FAQ';

type FAQButtonProps = { }

export const FAQButtonDesktop: React.FC<FAQButtonProps> = () => {
  return (
    <Text
      href="/faq"
      hrefAttrs={{ target: '_blank' }}
    >
      <DNAButton
        context="minimum"
        appearance="ghost"
        status="dark"
        size="xlarge"
        iconLeft="help-circle-outline"
        testID="user-guide-button"
      />
    </Text>
  )
}

export const FAQButtonTablet: React.FC<FAQButtonProps> = () => {
  const [visible, setVisible] = useState<boolean>(false)

  return (
    <>
      <DNAButton
        context="minimum"
        appearance="ghost"
        status="flat"
        size="xlarge"
        iconLeft="help-circle-outline"
        onPress={() => setVisible(p => !p)}
        testID="user-guide-button"
      />
      <DNASlider
        visible={visible}
        setVisible={setVisible}
      >
        <DNABox
          fill
          appearance="col"
          style={{ backgroundColor: 'white' }}
        >
          <DNAHeader status="brand" title="User Guide / FAQ">
            <DNAHeader.Left>
              <DNAButton
                status="brandAlt"
                onPress={() => setVisible(false)}
                testID="tablet-header-close"
              >
                Close
              </DNAButton>
            </DNAHeader.Left>
          </DNAHeader>
          <FAQScreen />
        </DNABox>
      </DNASlider>
    </>
  )
}

export const FAQButton: React.FC<FAQButtonProps> = (props) => {
  const { deviceMode } = useAppSettings()

  return deviceMode === 'desktop'
    ? <FAQButtonDesktop {...props} />
    : <FAQButtonTablet {...props} />
}

export default FAQButton
