import { DocumentVersion, Page, PageGroup } from '@alucio/aws-beacon-amplify/src/models'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import useThumbnailSize from 'src/hooks/useThumbnailSize/useThumbnailSize'
import { slideSelectorModalActions } from 'src/state/redux/slice/SlideSelectorModal/SlideSelectorModal'
import { getImageObjectURLFromCloudfront } from 'src/utils/loadCloudfrontAsset/common'
import { SlideMode, ThumbnailPage } from './SlideSelector'
import { v4 as uuid } from 'uuid'
import { Logger } from '@aws-amplify/core'
import { GroupDraft } from './PageGroupList';
import { isDocumentVersionORM } from 'src/types/typeguards';

const logger = new Logger('ThumbnailSelector', 'INFO');

type ThumbnailSelectorType = {
  mode?: SlideMode
  preSelectedThumbnails?: number[]
  disableRequiredToggle?:boolean
}

// TODO: REMOVE THIS ON THE CLEAN UP
export const isV2FileArchive = (document : DocumentVersion | undefined) =>
  document?.convertedArchiveKey?.includes('/v2/')
  // TODO UNNCOMMENT THIS: FOR SOME REASON THERE ARE SOME PATHS WITH CONVERSION STATUS ON 2 BUT THE KEY IS STILL VERSION 1
  // || document?.converterVersion === 2

// [TODO-2126] - This should be calculated at the ORM level
//             - The name doesn't match what it's doing? Should be a thumbnail not archived file key?
export const detectArchivedFileKeyPath = (
  document : DocumentVersion | undefined,
  page: Pick<Page, 'number'|'pageId'>,
  size : string = 'medium',
) : string | null =>
  document?.convertedFolderKey
    ? isV2FileArchive(document)
      ? `${document?.convertedFolderKey}thumbnails/${page.number}_${size}_thumb.png`
      : `${document?.convertedFolderKey}${page.pageId}_thumb.png`
    : null

const getPlaceholderPages = (numberOfPages: number): ThumbnailPage[] => {
  return Array.from({ length: numberOfPages })
    .map((_, idx) => ({
      pageId: `placeholder_${idx + 1}`,
      number: idx + 1,
      srcHash: '',
      thumbnailObjectURL: '',
      checked: false,
      disabled: true,
      disableCheckbox: true,
      isPlaceholder: true,
      isCover: false,
    }))
}

const useThumbnailSelector = (
  props: ThumbnailSelectorType,
  onAction?: (pages?: ThumbnailPage[], pageGroups?: PageGroup[]) => void,
) => {
  const {
    mode,
    preSelectedThumbnails,
    disableRequiredToggle,
  } = props;
  const dispatch = useDispatch();
  const [pages, setPages] = useState<ThumbnailPage[]>([]);
  const [pageGroups, setPageGroups] = useState<PageGroup[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { thumbnailMode, toggleThumbnailMode, thumbnailSize } = useThumbnailSize()

  /** Logic is: not an array of pages where an unchecked item is found */
  const allPagesSelected = !pages.find(p => !p.checked) && pages.length > 0;

  // Get a list of all currently selected page objects
  const selectedPageObjects = pages.filter(p => p.checked)

  /** BEGIN GROUPING VARS */

  // Get a list of IDs from all currently slected pages
  const selectedPagesIds = selectedPageObjects.map(page => page.pageId)

  const selectionCount = pages.filter(p => p.checked).length;
  const selectedPages = pages
    .filter(p => p.checked)
    .map(p => p.number);

  // Get a list of all current pagegroups which contain any of the selected slides
  const groupsWithSomeSlidesSelected = pageGroups.filter(pageGroup => {
    return pageGroup?.pageIds?.some(pID => selectedPagesIds.includes(pID))
  })

  // Get a list of grouped slide IDs
  const groupedSlideIDs = pageGroups.map(pageGroup => pageGroup.pageIds).flat()

  const allSelectedSlidesAreGrouped =
    selectionCount > 0 &&
    selectedPagesIds.every(pageId => groupedSlideIDs.includes(pageId))

  // filter out existing pagegroups with selected slides
  const groupsWithNoSelectedSlides = pageGroups.filter(pageGroup => {
    return pageGroup?.pageIds?.every(pID => !selectedPagesIds.includes(pID))
  })

  // Get a list of ungrouped and selected slides
  const selectedSlidesNotInGroup = selectedPageObjects.filter(page => !groupedSlideIDs.includes(page.pageId))

  /** END GROUPING VARS */

  const isFolder = (mode === undefined || mode === SlideMode.FolderItem);

  // CAN UNGROUP WHEN ONLY ONE GROUP IS SELECTED
  /**
   * Ungrouping should be allowed only when the following conditions are met:
   * - all selected slides are in a group and only one group is selected
   * */
  const canUngroup = allSelectedSlidesAreGrouped && groupsWithSomeSlidesSelected.length === 1

  const checkedPages = pages.filter(page => page.checked)
  const isOptional = checkedPages.every(page => page.isRequired)
  const isSinglePageSelected = selectedSlidesNotInGroup.length === 1 && groupsWithSomeSlidesSelected.length === 0
  const isSingleGroupSelected = selectedSlidesNotInGroup.length === 0 && groupsWithSomeSlidesSelected.length === 1
  const isCover = checkedPages.length
    ? isSinglePageSelected
      ? checkedPages[0].isCover
      : isSingleGroupSelected ? checkedPages.some(p => p.isCover) : false
    : false

  const canSelectCover = !isCover && (isSinglePageSelected || isSingleGroupSelected)

  const handleSelectGroup = (groupDraft: GroupDraft) => {
    const groupDraftPageIDs = groupDraft.pages.map(page => page.pageId)
    const pagesInGroup = pages.filter(page => groupDraftPageIDs.includes(page.pageId))
    const pagesInGroupIDs = pagesInGroup.map(page => page.pageId)

    // if the slide is required the user cannot hide the slide
    const skipRequired = disableRequiredToggle && pagesInGroup.some(page => page.isRequired)
    if (skipRequired) return
    setPages(p => {
      const selectedPages = p.map(page => pagesInGroupIDs.includes(page.pageId)
        ? { ...page, checked: !page.checked }
        : page,
      )

      return selectedPages
    })
  }

  const handleThumbSelect = (thumbPage: ThumbnailPage) => {
    // if the slide is required the user cannot hide the slide
    const skipRequired = disableRequiredToggle && thumbPage.isRequired
    if (skipRequired) return undefined;
    else {
      setPages(p => {
        // deep copy triggers rerender, is there a better way to do this or is this ok?
        const updatedPages = [...p]
        const page = updatedPages[thumbPage.number - 1]
        page.checked = !thumbPage.checked
        return updatedPages
      })
    }
  };

  const handleSelectAllToggle = (checked?: boolean) => {
    setPages(p => {
      const updatedPages = p.map(page => {
        /** Multi-use toggle allows for checked value to be passed in, if not passed in fall back to existing logic  */
        const updatedCheckedState = typeof checked === 'boolean'
          ? checked
          : (isFolder && page.isRequired
            ? true
            : !allPagesSelected)

        const skipRequired = disableRequiredToggle && page.isRequired
        const currentPage = {
          ...page,
          checked: skipRequired || updatedCheckedState,
        }

        return currentPage;
      })
      return updatedPages
    })
  };

  const handleSetRequiredSlides = () => {
    setPages(
      p => {
        /** If our checked items are all already required, we should set all checked pages to optional
         * (isRequired = false), otherwise (mix of optional + required or all optional) set them all to required */
        const shouldSetOptional = p.filter(page => page.checked).every(page => page.isRequired)
        const updatedPages = p.map(page => {
          const currentPage = { ...page };
          if (mode === SlideMode.DocumentVersion && currentPage.checked) currentPage.isRequired = !shouldSetOptional
          return currentPage;
        })

        onAction?.(updatedPages)
        return updatedPages
      },
    )
  }

  const handleSetCoverThumbnail = () => {
    setPages(
      p => {
        const updatedPages = p.map(page => {
          const currentPage = { ...page };
          if (mode === SlideMode.DocumentVersion) {
            currentPage.isCover = false
            if (currentPage.checked && checkedPages[0].pageId === currentPage.pageId) {
              currentPage.isCover = true
            }
          }
          return currentPage;
        })

        onAction?.(updatedPages)
        return updatedPages
      },
    )
  }

  /**
   * THIS IS FOR NON-CONSECUTIVE
   * - all selected pages are ungrouped and no group is selected ( show grouping )
   * - all selected pages are ungrouped and at least one group is selected ( show grouping )
   * - all selected pages are grouped and only one group is selected ( show ungrouping )
   * - all selected pages are grouped and multiple groups are selected ( show grouping )
   * */

  // the only time you ungroup is when only one group is selected
  const handleSetGroupedSlides = (): void => {
    if (canUngroup) {
      setPageGroups(groupsWithNoSelectedSlides)
      onAction?.(undefined, groupsWithNoSelectedSlides)
      return;
    }

    if (selectionCount > 0) {
      if (selectedSlidesNotInGroup && groupsWithSomeSlidesSelected.length === 0) {
        // all selected pages are ungrouped and no group is selected
        const newPageGroup: PageGroup = {
          id: uuid(),
          pageIds: [...selectedPagesIds],
        }

        setPageGroups(p => {
          const updatedPageGroups = [...p, newPageGroup]
          onAction?.(undefined, updatedPageGroups)
          return updatedPageGroups
        });
      } else if (selectedSlidesNotInGroup && groupsWithSomeSlidesSelected.length > 0) {
        // all selected pages are ungrouped and at least one group is selected
        const newPageGroup: PageGroup =
        {
          id: uuid(),
          pageIds: [...selectedPagesIds],
        }

        const updatedPageGroups = [...groupsWithNoSelectedSlides, newPageGroup]
        setPageGroups(updatedPageGroups)
        onAction?.(undefined, updatedPageGroups)
      } else if (allSelectedSlidesAreGrouped && groupsWithSomeSlidesSelected.length === 1) {
        // all selected pages are grouped and only one group is selected
        const updatedPageGroups = [...groupsWithNoSelectedSlides]
        setPageGroups(updatedPageGroups)
        onAction?.(undefined, updatedPageGroups)
      } else if (allSelectedSlidesAreGrouped && groupsWithSomeSlidesSelected.length > 1) {
        // all selected pages are grouped and multiple groups are selected
        const newPageGroup: PageGroup =
        {
          id: uuid(),
          pageIds: [...selectedPagesIds],
        }

        const updatedPageGroups = [...groupsWithNoSelectedSlides, newPageGroup]

        setPageGroups(updatedPageGroups)
        onAction?.(undefined, updatedPageGroups)
      }

      if (selectedPageObjects.some(p => p.isCover)) {
        handleSetCoverThumbnail()
      }

      const shouldSetRequired =
        selectedPageObjects.some(p => p.isRequired) &&
        !selectedPageObjects.every(p => p.isRequired)

      shouldSetRequired && handleSetRequiredSlides()
    }
  }

  // Get images from cloudfont, using the auth token
  const fetchImages = (
    activeItem: DocumentVersion,
    pageSettings: (
      url: string,
      page: Page
    ) => ThumbnailPage,
  ) => {
    return async function initFetch() {
      const images = await Promise.all(
        activeItem.pages.map(async (page: Page) => {
          const thumbnailObjectURL = await getImageObjectURLFromCloudfront(
            detectArchivedFileKeyPath(activeItem, page)!,
          )
          const fetchedPage = pageSettings(thumbnailObjectURL, page)
          // mark the page as checked if it's visible in the modified file OR if everything is visible (Should only happen in 'Select Slides')
          if (!mode) {
            fetchedPage.checked = preSelectedThumbnails?.includes(page.number) || preSelectedThumbnails === null;
          }
          return (fetchedPage)
        }) ?? [],
      )
      setPages(images)
    }
  }

  /**
     * Utility function to handle fetching thumbnail images for each docVer page which augments the
     * data model and adds a thumbnail object URL, a checked property, & a disabled property
     */
  const populateThumbnails = (activeItem, selectRequiredSlides: boolean) => {
    if (activeItem instanceof DocumentVersion) {
      logger.debug('populating thumbs', activeItem)
      // set the ThumbnailPage properties when the image is get from cloud front

      const thumbnailPage = (thumbnailObjectURL: string, page: Page) => {
        return {
          ...page,
          thumbnailObjectURL: thumbnailObjectURL,
          checked: selectRequiredSlides && !!page.isRequired,
          isRequired: page.isRequired,
          isCover: (activeItem.selectedThumbnail ?? 1) === page.number,
          disabled: !!disableRequiredToggle && !!page.isRequired,
        };
      }

      (fetchImages(activeItem, thumbnailPage))()
    } else if (isDocumentVersionORM(activeItem)) {
      // set the ThumbnailPage properties when the image is get from cloud front
      const thumbnailPage = (thumbnailObjectURL: string, page: Page) => {
        return {
          ...page,
          thumbnailObjectURL: thumbnailObjectURL,
          checked: page.isRequired || false,
          isRequired: page.isRequired,
          isCover: (activeItem.model.selectedThumbnail ?? 1) === page.number,
          disabled: !!disableRequiredToggle && !!page.isRequired,
        };
      }

      (fetchImages(activeItem.model, thumbnailPage))()
    }
  }

  /**
   * Combine ungrouped pages and existing pagegroups into array of page groups
   * (convert ungrouped to pg with single element)
   * */
  const getGroupDrafts = (): GroupDraft[] => {
    const groupedPageIDs = pageGroups.map(pageGroup => pageGroup.pageIds).flat()
    const allPagesGrouped = pages.length === groupedPageIDs.length
    const groupDrafts: GroupDraft[] = pageGroups.map((group) => {
      return {
        id: group.id,
        pages: group.pageIds?.reduce((acc, id) => {
          const page = pages.find(page => page.pageId === id);
          if (page) {
            acc.push(page);
          }
          return acc;
        }, [] as ThumbnailPage[]) || [],
      }
    });
    const sortGroupsByPage = (groups: GroupDraft[]) => groups.sort((a, b) => {
      if (a.pages[0]?.number < b.pages[0]?.number) {
        return -1;
      }
      return 1;
    });

    /** case where all pages are grouped. just return the existing page groups */
    if (allPagesGrouped) return sortGroupsByPage(groupDrafts);

    /** Convert ungrouped pages to array of pagegroups */
    // [TEMP] - Might have had a pages array duplicated due to conflict resolution here
    const flatPages = [...new Set(pages.map(page => page.pageId))]
      .map(page => pages.find(pg => pg.pageId === page)!)

    const convertedPages: GroupDraft[] = flatPages
      .filter(page => !groupedPageIDs.includes(page.pageId))
      .map(page => ({
        id: page.pageId,
        pages: [page],
      }));

    // RETURN THEM ORDERED BY PAGE NUMBER
    return sortGroupsByPage([...convertedPages, ...groupDrafts]);
  }

  /** Event Handlers */
  const closeModal = () => {
    // THE IDEA OF THIS IS TO DO THE DISPATCH AFTER THE ANIMATION TO HIDE THE MODAL IS DONE
    /**
     * TODO: Hmmm, there's gotta be a more graceful way of dealing with this. Will allow for now but I've always had
     * issues with some edge case of people being on hardware that's slow enough to make things like this an issue
     * */
    setIsOpen(false);
    setTimeout(() => {
      dispatch(slideSelectorModalActions.setActiveDocumentVersion({}))
    }, 1000);
  };

  const initializeThumbnails = (activeDocumentVersion: DocumentVersion, selectRequiredSlides: boolean = true) => {
    // Show placeholder slides while the thumbnail images are being fetched
    setPages(getPlaceholderPages(activeDocumentVersion.pages.length))
    populateThumbnails(activeDocumentVersion, selectRequiredSlides)
    activeDocumentVersion.pageGroups && setPageGroups(activeDocumentVersion.pageGroups)
  }

  return {
    allPagesSelected,
    canUngroup,
    isOpen,
    isOptional,
    isCover,
    pages,
    pageGroups,
    selectionCount,
    selectedPages,
    groupsWithSomeSlidesSelected,
    thumbnailMode,
    thumbnailSize,
    canSelectCover,

    closeModal,
    getGroupDrafts,
    handleThumbSelect,
    handleSelectAllToggle,
    handleSetRequiredSlides,
    handleSetCoverThumbnail,
    handleSetGroupedSlides,
    handleSelectGroup,
    initializeThumbnails,
    setPageGroups,
    toggleThumbnailMode,
  }
}

export default useThumbnailSelector
