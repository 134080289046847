import React from 'react';

import { DNABox, DNAButton, DNAModal, DNAText } from '@alucio/lux-ui';
import { DNAModalVariant } from './DNAConnectedModal';

export enum UserReactivateMode {
  reactivate = 'reactivate',
  deactivate = 'deactivate'
}

interface UserReactivateConfirmationProps {
  onClose: () => void
  mode: UserReactivateMode,
  username: string
}

const reactivateText = {
  title: {
    reactivate: 'Reactivate User',
    deactivate: 'Deactivate User',
  },
  body: {
    reactivate: 'Are you sure you want to reactivate',
    deactivate: 'Are you sure you want to deactivate',
  },
}

const UserReactivateConfirmation:DNAModalVariant<UserReactivateConfirmationProps> = (props) => {
  const { toggleModal, onClose, mode, username } = props

  const handleContinue = () => {
    toggleModal()
    onClose()
  }

  const handleCancel = () => {
    toggleModal()
  }

  return (
    <DNAModal>
      <DNAModal.Header>
        <DNAText h5>{reactivateText.title[mode]}</DNAText>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox style={{ padding: 8 }}>
          <DNAText status="flat">
            {`${reactivateText.body[mode]} ${username}?`}
          </DNAText>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <DNAButton size="small" status="flat" onPress={handleCancel}>
          Cancel
        </DNAButton>
        <DNAButton size="small" onPress={handleContinue} status="primary">
          {mode === UserReactivateMode.deactivate ? 'Deactivate' : 'Reactivate'}
        </DNAButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
};

export default UserReactivateConfirmation;
