import React from 'react'
import { FolderItemORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'

import {
  DNABox,
  DNACard,
  DNAText,
  Iffy,
  useTheme,
  DNAChip,
  DNAButton,
} from '@alucio/lux-ui'

import DNACustomDeckContextMenu from 'src/components/DNA/Document/DNACustomDeckContextMenu'
import DNACustomDeckThumbnail from 'src/components/DNA/Document/DNACustomDeckThumbnail'
import { isCustomDeckORM } from 'src/types/typeguards'
import DNADocumentVersionUpdateButton from 'src/components/DNA/Document/DNADocumentVersionUpdateButton'
import { useDNACustomDeckActions } from 'src/components/DNA/Document/DNACustomDeck.actions'
import { useAppSettings } from 'src/state/context/AppSettings'

export type DNADocumentVersionDefaultRowProps = {
  isNetworkConnected?: boolean,
}

const DNADocumentFolderItemCustomDeckRow: DNARowsComponent<
  FolderItemORM,
  DNADocumentVersionDefaultRowProps
> = (props) => {
  const {
    ORM: folderItem,
    onPress,
    style,
    isDesktop = true,
    isNetworkConnected,
  } = props
  const theme = useTheme()
  const DNACustomDeckActions = useDNACustomDeckActions()
  const { isOnline } = useAppSettings()

  /**
   * This is where typescript really helps us identify coding best practice. The way this and related components were structured/typed,
   * there is a possibility of getting into this component with the wrong ORM. This is a broader issue than just checking what type the related
   * item is. Basically, the folderItem comes in as any number of folderItemORM types and should be limited to just CustomDeckORM folderitem
   * types. Since this component is intended to only ever be loaded when we are using a customDeckORM, the data structure/typings should be identified
   * prior to loading the component. Then only the necessary props should be passed in.
   *
   * Note, that the folder item is being passed in and then passed right back out unnecessarily in the onPress call. Additionally, the DNACustomDeckContextMenu
   * component is being designed as a type specific component, but being passed a property that is multi-type. If the component is intended to be
   * specific to the datatype the props should reflect this approach as well. However, if this intended to be a reusable component, then passing the
   * more generic FolderItemORM is ok.
   * */
  if (!isCustomDeckORM(folderItem.relations.item)) { return null }

  const customDeckORM = folderItem.relations.item
  const unavailableContent = !isNetworkConnected && !customDeckORM.meta.assets.isContentCached
  const isReviewRequired = customDeckORM.meta.version.requiresReview
  const isReviewDisabled = !customDeckORM.meta.assets.isContentCached && !isOnline
  const openDeckEditor = DNACustomDeckActions.edit(customDeckORM, folderItem, 'REVIEW')

  const handleRowPress = () => {
    if (!isOnline && !customDeckORM.meta.assets.isContentCached) {
      return;
    }

    if (isReviewRequired) {
      openDeckEditor()
      return;
    }

    onPress?.(folderItem)
  }

  const handleThumbPress = () => {
    if (!isOnline && !customDeckORM.meta.assets.isContentCached) {
      return;
    }

    if (isReviewRequired) {
      openDeckEditor()
      return;
    }

    DNACustomDeckActions.present(customDeckORM, folderItem)()
  }

  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={handleRowPress}
      style={[
        {
          paddingHorizontal: theme['row-padding-horizontal'],
          paddingVertical: theme['row-padding-vertical'],
        },
        style,
      ]}
    >
      <DNABox
        alignY="center"
        spacing={isDesktop ? 'between' : 'medium'}
        childFill={1}
      >
        <Iffy is={!isDesktop}>
          <DNABox spacing="small" style={{ marginLeft: 56 }}>
            {/* Context menu */}
            <DNACustomDeckContextMenu
              folderItemORM={folderItem}
            >
              <DNAButton
                status="subtle"
                appearance="ghostAlt"
                context={isDesktop ? 'minimum' : 'roundTablet'}
                iconLeft="dots-vertical"
                size={isDesktop ? undefined : 'xlarge'}
              />
            </DNACustomDeckContextMenu>
          </DNABox>
        </Iffy>
        <DNABox
          spacing="large"
          alignY="center"
          childFill={1}
        >
          <DNACustomDeckThumbnail
            customDeckORM={customDeckORM}
            width={76}
            height={44}
            unavailableContent={unavailableContent}
            // Assessing implementation in #BEAC-2155
            onPress={handleThumbPress}
          />
          {/* Title */}
          <DNABox
            spacing="small"
            appearance="col"
            childFill
            style={{ paddingRight: 16 }}
          >
            <DNAText b1 numberOfLines={1}>
              {folderItem.model.customTitle ? folderItem.model.customTitle : 'Untitled Presentation'}
            </DNAText>
            <DNABox
              alignY="center"
              spacing="small"
              fill
              childStyle={[4, { flex: 1 }]}
            >
              <DNAChip appearance="tag" status="subtle">
                MODIFIED
              </DNAChip>
            </DNABox>
          </DNABox>
          {/* Update Version Notification */}
          <Iffy is={!isReviewRequired}>
            <DNADocumentVersionUpdateButton orm={folderItem} />
          </Iffy>
        </DNABox>
        {/* Actions */}
        <DNABox alignY="center" spacing="medium">
          <Iffy is={isReviewRequired}>
            <DNAButton
              appearance="outline"
              onPress={openDeckEditor}
              iconLeft="alert"
              size="small"
              status="warning"
              disabled={isReviewDisabled}
            >
              Review
            </DNAButton>
          </Iffy>
          <Iffy is={isDesktop}>
            <DNABox spacing="small">
              <DNACustomDeckContextMenu
                folderItemORM={folderItem}
              >
                <DNAButton
                  status="subtle"
                  appearance="ghostAlt"
                  context="minimum"
                  iconLeft="dots-vertical"
                />
              </DNACustomDeckContextMenu>
            </DNABox>
          </Iffy>
        </DNABox>
      </DNABox>
    </DNACard>
  );
}

export default DNADocumentFolderItemCustomDeckRow
