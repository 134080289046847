import { Logger } from '@aws-amplify/core'
import workerChannel from 'src/worker/channels/workerChannel'
import { SET_WORKER_CLIENT_LOGGING } from '@alucio/core'

const consoleLogger = new Logger('PWA', 'WARN');
let enableClientLogging = false

let callback

const logger = {
  debug: (message: string, ...obj: any[]) => {
    consoleLogger.debug(message, obj)

    if (enableClientLogging)
    { workerChannel.postMessage({ type: 'LOG_MESSAGE', level: 'DEBUG', message: message }) }
  },
  info: (message: string, ...obj: any[]) => {
    consoleLogger.info(message, obj)

    if (enableClientLogging)
    { workerChannel.postMessage({ type: 'LOG_MESSAGE', level: 'INFO', message: message }) }
    callback?.(message)
  },
  warn: (message: string, ...obj: any[]) => {
    consoleLogger.warn(message, obj)

    if (enableClientLogging)
    { workerChannel.postMessage({ type: 'LOG_MESSAGE', level: 'WARN', message: message }) }
  },
  error: (message: string, ...obj: any[]) => {
    consoleLogger.error(message, obj)

    if (enableClientLogging)
    { workerChannel.postMessage({ type: 'LOG_MESSAGE', level: 'ERROR', message: message }) }
  },
  verbose: (message: string, ...obj: any[]) => {
    consoleLogger.verbose(message, obj)

    if (enableClientLogging)
    { workerChannel.postMessage({ type: 'LOG_MESSAGE', level: 'VERBOSE', message: message }) }
  },
  setCallback: (cb: (msg: string) => void) => {
    callback = cb
  },
  setClientLogging: (val: boolean) => {
    enableClientLogging = val
  },
}

workerChannel
  .observable
  .filter(v => v.type === 'SET_WORKER_CLIENT_LOGGING')
  .subscribe(m => {
    const msg = m as SET_WORKER_CLIENT_LOGGING
    logger.setClientLogging(msg.value)
  })

export default logger
