import { DNABox, DNAButton, DNAIcon, DNAModal, DNAText, Iffy, Popover } from '@alucio/lux-ui';
import { useFormik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { FlatList } from 'react-native';
import { CustomField } from 'src/screens/Publishers/Versioning/DocumentInfo';
import { useTenantFields } from 'src/state/redux/selector/tenant';
import { DNAModalVariant } from '../DNAConnectedModal';
import { AccessFilterType, ShareFolderMode } from './DNAShareFolderModal';

interface CreateAccessFilterProps {
  isDesktop: boolean;
  onCreateFilter: (value: any) => void;
  display: boolean;
  folderName: string;
  setMode: (mode: ShareFolderMode) => void;
}
const tooltipText = 'Creating an access filter will determine which users will have access ' +
'to this folder based on the filters that they have been assigned. ' +
'However, they may not have access to all the content within this folder ' +
'if their assigned filters restrict their access to specific content.'

type formikFilterType = Record<string, string[]>

const CreateAccessFilter: DNAModalVariant<CreateAccessFilterProps> = (props) => {
  const { toggleModal, onCreateFilter, display, setMode, isDesktop, folderName } = props;

  const opts = useMemo(() => ({ defaultSearchFilter: true }), []);
  const tenantFields = useTenantFields(opts);
  const mappedTenantFields = useMemo(() =>
    tenantFields.map(field => ({ ...field, required: false }))
      .filter(field => field.userFilter)
  , [tenantFields]);

  // todo: we are not using formik anymore, remove and replace with react hook form if needed
  const formik = useFormik({
    initialValues: mappedTenantFields.reduce((acc, { fieldName }) =>
      ({ ...acc, [`${fieldName}`]: [] }), { }) as formikFilterType,
    onSubmit: (values, { resetForm }) => {
      const filterValue : AccessFilterType  = {}
      Object.keys(values).forEach(key => {
        const sortedValues = values[key].sort((a, b) => a.localeCompare(b));
        if (sortedValues.length) {
          filterValue[key] = { key, values: sortedValues }
        }
      })
      resetForm();
      onCreateFilter(filterValue);
    },
  });

  const onChangeCustomField = useCallback((fieldName, value) => formik.setFieldValue(fieldName, value), [])

  return (
    <DNABox testID="create-access-filter-modal" style={{ display : display ? undefined : 'none' }}>
      <DNAModal>
        <DNAModal.Header>
          {/* DESKTOP HEADER */}
          <Iffy is={isDesktop}>
            <DNABox appearance="row" spacing="between" alignY="center" style={{ minWidth: 600 }}>
              <DNAButton
                appearance="ghost"
                status="neutral"
                iconLeft="chevron-left"
                onPress={() => setMode(ShareFolderMode.SHARE_FOLDER)}
              >
                Back </DNAButton>
              <DNAButton iconLeft="close" onPress={toggleModal} appearance="ghost" status="neutral" size="medium" />
            </DNABox>
          </Iffy>
          {/* TABLET HEADER */}
          <Iffy is={!isDesktop}>
            <DNABox appearance="row" spacing="between" alignY="center" style={{ minWidth: 600 }}>
              <DNABox alignY="center" spacing="tiny">
                <DNAButton
                  appearance="ghost"
                  status="dark"
                  size="large"
                  iconLeft="chevron-left"
                  onPress={() => setMode(ShareFolderMode.SHARE_FOLDER)}
                />
                <DNAText status="dark" h4>{folderName}</DNAText>
              </DNABox>
              <DNAButton
                testID="create-button"
                size="large"
                appearance="ghost"
                onPress={formik.submitForm}
                disabled={!formik.isValid || !formik.dirty}
                status="primary"
              >
                Create
              </DNAButton>
            </DNABox>
          </Iffy>
        </DNAModal.Header>
        <DNAModal.Body>
          <DNABox appearance="col" fill>
            <DNABox appearance="col" style={{ marginTop: 24, marginLeft: 24 }}>
              <DNABox testID="create-access-filter-container" appearance="row">
                <DNAText label>Create Access Filter</DNAText>
                <Popover>
                  <Popover.Anchor>
                    <DNABox>
                      <DNAIcon.Styled
                        testID="create-access-filter"
                        style={{ marginLeft: 5 }}
                        appearance="ghost"
                        status="subtle"
                        name="help-circle-outline"
                      />
                    </DNABox>
                  </Popover.Anchor>
                  <Popover.Content>
                    <DNAText
                      testID="create-access-filter-tooltip-text"
                      style={{ color: 'white', width: 400 }}
                    >
                      {tooltipText}
                    </DNAText>
                  </Popover.Content>
                </Popover>
              </DNABox>
            </DNABox>
            <DNABox style={{ height: 308, marginTop: 18 }}>
              <FlatList
                data={mappedTenantFields}
                renderItem={({ item, index }) => {
                  return (
                    <CustomField
                      key={`${item.fieldName}_${index}`}
                      onChangeSelection={onChangeCustomField}
                      fieldName={item.fieldName}
                      fieldConfig={item}
                      value={formik.values[item.fieldName]}
                    />
                  )
                }}
              />
            </DNABox>
          </DNABox>
        </DNAModal.Body>
        <Iffy is={isDesktop}>
          <DNAModal.Confirmation>
            <DNAButton
              testID="create-button"
              size="small"
              onPress={formik.submitForm}
              disabled={!formik.isValid || !formik.dirty}
              status="primary"
            >
              Create
            </DNAButton>
          </DNAModal.Confirmation>
        </Iffy>
      </DNAModal>
    </DNABox>
  )
}

export default CreateAccessFilter;
