import React, { forwardRef } from 'react';
import { GroupDraftStatus, ReplaceGroupDraft } from './FindAndReplace';
import { DNABox, DNAButton, DNAChip, DNAIcon, DNAText, Iffy, Stack, util } from '@alucio/lux-ui';
import { Pressable, StyleSheet } from 'react-native';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { DocumentAccessLevel, Page } from '@alucio/aws-beacon-amplify/src/models';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
import { DocumentVersionORM } from 'src/types/orms';

const styles = StyleSheet.create({
  groupChildWrapper: {
    alignSelf: 'flex-start',
    height: '100%',
  },
  groupWrapper: {
    paddingHorizontal: 17,
    paddingVertical: 17,
  },
  thumbnail: {
    backgroundColor: 'lightgrey',
    borderColor: colors['color-text-basic'],
    borderRadius: 3,
    borderWidth: 3,
    height: 124,
    width: 220,
  },
  thumbnailWrapper: {
    borderColor: colors['color-flat-400'],
    borderRadius: 6,
    borderWidth: 3,
  },
  thumbIndividualOverlay: {
    borderColor: colors['color-warning-500'],
    borderRadius: 6,
    borderWidth: 3,
    maxHeight: 130,
  },
  thumbOverlay: {
    borderRadius: 3,
    backgroundColor: colors['color-dark-transparent-500'],
    flex: 1,
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  trashCanStack: {
    alignItems: 'flex-end',
    padding: 9,
    width: '100%',
  },
  selectedReplaceThumbnail: {
    borderColor: colors['color-warning-500'],
    borderStyle: 'dashed',
  },
  replaceThumbnail: {
    backgroundColor: colors['color-text-basic'],
    height: 130,
    minWidth: 226,
  },
  removeIcon: {
    color: colors['color-text-basic'],
    height: 18,
    width: 16,
  },
  removeIconWrapper: {
    height: 32,
    width: 32,
    backgroundColor: colors['color-dark-transparent-400'],
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface GroupRowProps {
  isActiveGroup: boolean;
  currentIndexCount: number;
  group: ReplaceGroupDraft;
  onRemoveGroup: (groupId: string) => void;
  onAcceptDeletedGroup: (groupId: string) => void;
  onSelectGroup: (groupId: string) => void;
  slidesThumbnails: { [key: string]: string };
  onUndoRemoval: (groupId: string) => void;
}

interface ReplacementGroupProps {
  isActiveGroup: boolean;
  group: ReplaceGroupDraft;
  onActionPressed: () => void;
  slidesThumbnails: { [key: string]: string };
}

interface PageComponentProps {
  selectedWrapper?: boolean;
  page: Page;
  documentVersion?: DocumentVersionORM;
  isFromPersonalDocument?: boolean;
  hideThumbnail?: boolean;
  number?: number;
  thumbnail?: string;
  warningWrapper?: boolean;
  visible: boolean;
}

const DOC_STATUS = {
  'DELETED': 'deleted',
  'REVOKED': 'revoked',
  'ARCHIVED': 'archived',
};

const GroupRow = forwardRef((props: GroupRowProps, ref) => {
  const { group } = props;
  const isDocumentUnavailable =
    [GroupDraftStatus.DELETED, GroupDraftStatus.REVOKED, GroupDraftStatus.ARCHIVED].includes(group.status);
  const useThumbnailOverlay = isDocumentUnavailable || group.status === GroupDraftStatus.GROUP_REMOVED;
  const warningWrapper = useThumbnailOverlay || group.status === GroupDraftStatus.MAJOR_UPDATE;
  const displayTrashCan = !useThumbnailOverlay && group.status !== GroupDraftStatus.ACTIVE;
  const isFromPersonalDocument =
    group.documentVersion?.relations.document.model.accessLevel === DocumentAccessLevel.USER;

  const onAction = (): void => {
    if ([GroupDraftStatus.MAJOR_UPDATE, GroupDraftStatus.REPLACED].includes(group.status)) {
      props.onSelectGroup(group.groupId);
    } else if (group.status === GroupDraftStatus.GROUP_REMOVED) {
      props.onUndoRemoval(group.groupId);
    } else if (isDocumentUnavailable) {
      props.onAcceptDeletedGroup(group.groupId);
    }
  };

  const hideThumbnail =
    [GroupDraftStatus.DELETED, GroupDraftStatus.REVOKED, GroupDraftStatus.ARCHIVED].includes(group.status);

  return (
    <DNABox spacing="medium" alignY="center" alignX="center" childStyle={[1, styles.groupChildWrapper]} ref={ref}>
      <Pressable
        onPress={onAction}
        disabled={![GroupDraftStatus.MAJOR_UPDATE, GroupDraftStatus.REPLACED].includes(group.status)}
      >
        <DNABox
          spacing="medium"
          appearance="col"
          style={util.mergeStyles(undefined, [styles.thumbnailWrapper, group.isGroup],
            [{ borderColor: colors['color-warning-500'] }, warningWrapper && group.isGroup],
            [{ opacity: 0.5 }, group.status === GroupDraftStatus.ACTIVE])}
        >
          <DNABox>
            <Stack>
              {/* ARRAY OF PAGES WITHIN THE GROUP */}
              <Stack.Layer
                style={[group.isGroup && styles.groupWrapper, displayTrashCan && group.isGroup && { paddingTop: 50 }]}
              >
                <DNABox appearance="col" spacing="small">
                  {
                    group.pages.map((page, index) =>
                      (<PageComponent
                        page={page}
                        visible={group.visible}
                        documentVersion={group.documentVersion}
                        key={`${group.groupId}-${page?.pageId}`}
                        hideThumbnail={hideThumbnail}
                        warningWrapper={!group.isGroup && warningWrapper}
                        isFromPersonalDocument={isFromPersonalDocument}
                        number={props.currentIndexCount + index}
                        thumbnail={props.slidesThumbnails[page?.pageId] || ''}
                      />))
                  }
                </DNABox>
              </Stack.Layer>
              {
                // NOT VISIBLE ICON
                !group.visible &&
                  <Stack.Layer
                    style={util.mergeStyles(undefined, styles.thumbOverlay,
                      [styles.thumbIndividualOverlay, !group.isGroup])}
                    fill
                  >
                    <DNABox fill alignX="center" alignY="center">
                      <DNAIcon.Styled name="eye-off-outline" size="large"/>
                    </DNABox>
                  </Stack.Layer>
              }
              {
                // BLACK OVERLAY WITH MESSAGE/ACTION BUTTON
                useThumbnailOverlay &&
                  <Stack.Layer
                    style={util.mergeStyles(undefined, styles.thumbOverlay,
                      [styles.thumbIndividualOverlay, !group.isGroup],
                      [{ justifyContent: 'center', paddingBottom: 10 }, !group.isGroup && isDocumentUnavailable])}
                  >
                    <DNABox alignY={'center'} alignX="center" appearance="col" spacing="small">
                      <DNAText status="basic" bold>
                        {group.status === GroupDraftStatus.GROUP_REMOVED ? 'Deleted'
                          : `Source file was ${DOC_STATUS[group.status]}`}
                      </DNAText>
                      <Iffy is={isDocumentUnavailable}>
                        <DNAButton status="warning" onPress={onAction} size="small">
                          <DNABox spacing="medium">
                            <DNAIcon
                              appearance="ghostAlt"
                              status="basic"
                              style={{ height: 18, width: 16 }}
                              name="trash-can-outline"
                            />
                            <DNAText status="basic" bold>Remove</DNAText>
                          </DNABox>
                        </DNAButton>
                      </Iffy>
                    </DNABox>
                  </Stack.Layer>
              }
              {
                // TASH CAN ICON TO REMOVE THE THUMBNAIL
                displayTrashCan &&
                  <Stack.Layer style={styles.trashCanStack}>
                    <Pressable
                      onPress={() => props.onRemoveGroup(group.groupId)}
                      style={styles.removeIconWrapper}
                    >
                      <DNAIcon
                        status="neutral"
                        color="white"
                        appearance="ghostAlt"
                        style={styles.removeIcon}
                        name="trash-can-outline"
                      />
                    </Pressable>
                  </Stack.Layer>
              }
            </Stack>
          </DNABox>
        </DNABox>
      </Pressable>
      { /* CENTER ARROW */}
      <Iffy is={group.status !== GroupDraftStatus.ACTIVE && !isDocumentUnavailable}>
        <DNAIcon.Styled
          appearance="ghostAlt"
          status="flatAlt"
          size="large"
          context="minimum"
          name="arrow-right"
          style={{ marginBottom: 30 }}
        />
        { /* LEFT SIDE OF THE ROW (REPLACEMENT GROUP) */}
        <Pressable
          disabled={!props.isActiveGroup && group.status !== GroupDraftStatus.REPLACED}
          onPress={onAction}
          style={{ height: '100%' }}
        >
          <ReplacementGroup
            slidesThumbnails={props.slidesThumbnails}
            isActiveGroup={props.isActiveGroup}
            group={group}
            onActionPressed={onAction}
          />
        </Pressable>
      </Iffy>
    </DNABox>
  );
});

// RIGHT SIDE OF THE GROUP ROW (THE GROUP THAT WILL REPLACE THE ONE WITH ERRORS)
const ReplacementGroup = (props: ReplacementGroupProps) => {
  const { group } = props;
  const showButtonOption =
    !props.isActiveGroup &&
    [GroupDraftStatus.MAJOR_UPDATE, GroupDraftStatus.GROUP_REMOVED].includes(props.group.status);
  const showReplacementThumbnail = group.groupReplacement && group.status === GroupDraftStatus.REPLACED;
  const moreThanOneSlide = group.isGroup || ((group.groupReplacement?.pages.length || 0) > 1);

  return (
    <DNABox
      alignY="center"
      alignX="center"
      style={util.mergeStyles(undefined, styles.thumbnailWrapper, styles.replaceThumbnail,
        [styles.selectedReplaceThumbnail, props.isActiveGroup],
        [{ height: '100%' }, moreThanOneSlide],
        [{ backgroundColor: 'transparent' }, showReplacementThumbnail],
        [{ height: 'unset' }, showReplacementThumbnail && !group.isGroup],
        [{ borderWidth: 0 }, !moreThanOneSlide && showReplacementThumbnail],
      )}
    >
      <Iffy is={showReplacementThumbnail}>
        { /* NEW SLIDES */}
        <DNABox
          style={moreThanOneSlide && { paddingHorizontal: 10, paddingVertical: 10 }}
          appearance="col"
          spacing="small"
        >
          {
            group.groupReplacement?.pages.map((page) =>
              (<PageComponent
                page={page}
                visible={group.visible}
                documentVersion={group.documentVersion}
                key={`${group.groupId}-${page.pageId}`}
                selectedWrapper={!moreThanOneSlide && showReplacementThumbnail && props.isActiveGroup}
                thumbnail={props.slidesThumbnails[page.pageId]}
              />))
          }
        </DNABox>
      </Iffy>
      <Iffy is={showButtonOption}>
        <DNAButton onPress={props.onActionPressed} status="flat">
          <DNAText>
            {group.status === GroupDraftStatus.MAJOR_UPDATE ? 'Add replacement' : 'Restore'}
          </DNAText>
        </DNAButton>
      </Iffy>
      <Iffy is={props.isActiveGroup && group.status !== GroupDraftStatus.REPLACED}>
        <DNAText
          bold
          status="warning"
          style={{ textAlign: 'center' }}
        >
          {'Select a slide from the \nleft area'}
        </DNAText>
      </Iffy>
    </DNABox>
  );
};

// THUMBNAIL
const PageComponent = (props: PageComponentProps) => {
  const s3URL = detectArchivedFileKeyPath(props.documentVersion?.model, props.page)!;
  return (
    <DNABox appearance="col">
      <DNABox
        style={[styles.thumbnailWrapper,
          props.warningWrapper && { borderColor: colors['color-warning-500'] },
          props.selectedWrapper && styles.selectedReplaceThumbnail]}
      >
        {props.hideThumbnail ? <DNABox style={styles.thumbnail} />
          : <DNAThumbnail
              overlayText={props.documentVersion?.model.title}
              style={styles.thumbnail}
              height={124}
              width={220}
              s3URL={s3URL}
          />
        }
      </DNABox>
      <Iffy is={props.number || props.page.isRequired || props.isFromPersonalDocument}>
        <DNABox spacing="between" style={{ marginTop: 4 }}>
          <DNAText>{props.visible ? props.number : ''}</DNAText>
          <Iffy is={props.page.isRequired}>
            <DNAChip
              status="danger"
              appearance="tag"
            >REQUIRED</DNAChip>
          </Iffy>
          <Iffy is={props.isFromPersonalDocument}>
            <DNAChip status="dark" appearance="tag">
              MY UPLOADS
            </DNAChip>
          </Iffy>
        </DNABox>
      </Iffy>
    </DNABox>
  );
};

GroupRow.displayName = 'GroupRow';

export default GroupRow;
