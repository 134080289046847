import React, { createContext, useContext, useEffect, useReducer } from 'react';
import {
  DocumentVersionORM,
  FolderItemORM,
  isCustomDeckORM,
  isDocumentVersionORM,
  Presentable,
  PresentablePage,
} from 'src/types/types';
import { PPZTransform } from '@alucio/core';
import { getPresentable } from './helper';
import useContentInfoBroadcastChannel from './useContentInfoBroadcastChannel';
import { Logger } from '@aws-amplify/core';
const logger = new Logger('ContentProvider', 'INFO');
interface InitialValues {
  item?: PresentableModelORM
  meetingId?: string
}

export interface LoadedPresentation {
  presentable: Presentable,
  currentPresentablePage: PresentablePage
  currentPPZCoords?: PPZTransform,
  currentStep?: number,
}

export type PresentableModelORM = DocumentVersionORM | FolderItemORM;

interface ContentType {
  activePresentation?: LoadedPresentation,
  addPresentation: (item: PresentableModelORM, pageNumber?: number) => void,
  changeActivePresentation: (presentableId: string) => void,
  initPresentation: (item: PresentableModelORM, pageNumber?: number) => void,
  nextPage: () => void,
  presentations: LoadedPresentation[],
  prevPage: () => void,
  removePresentation: (presentableId: string) => void,
  setActiveSlideByPresentationPageNumber: (presentationPageNumber: number, step?: number) => void,
  setCurrentPPZCoords: React.Dispatch<React.SetStateAction<PPZTransform | undefined>>,

}

type ContentReducerAction = (
  { type: 'addPresentation', payload: { item: PresentableModelORM, pageNumber?: number } } |
  { type: 'removePresentation', payload: { presentationId: string } } |
  { type: 'changeActivePresentation', payload: { presentationId: string } } |
  { type: 'initPresentation', payload: { item: PresentableModelORM, pageNumber?: number } } |
  { type: 'setActiveSlideByPresentationPageNumber', payload: { presentationPageNumber: number, step?: number } } |
  { type: 'setCurrentPPZCoords', payload: { coords: PPZTransform | undefined } }
);

interface ContentProviderState {
  activePresentation?: LoadedPresentation,
  presentations: LoadedPresentation[],
}

function getNewLoadedPresentation(presentable: Presentable, initialPage?: number): LoadedPresentation {
  let activePage: PresentablePage | undefined;

  const allPages = presentable.presentableGroups.reduce<PresentablePage[]>((acc, { pages }) =>
    [...acc, ...pages], []);

  if (initialPage) {
    if (isDocumentVersionORM(presentable.orm) || isDocumentVersionORM(presentable.orm.relations.item)) {
      activePage = allPages.find((page) => page.page.number === initialPage);
    } else if (isCustomDeckORM(presentable.orm.relations.item)) {
      activePage = allPages.find((page) => page.presentationPageNumber === initialPage);
    }
  } else {
    activePage = presentable.presentableGroups[0]?.pages[0];
  }

  if (!activePage) {
    throw new Error('Presentable Page not found');
  }

  return {
    presentable,
    currentPresentablePage: activePage,
  }
}

function getPresentablePage(presentable: Presentable, presentationPageNumber: number): PresentablePage | undefined {
  for (const group of presentable.presentableGroups) {
    for (const page of group.pages) {
      if (page.presentationPageNumber === presentationPageNumber) {
        return page;
      }
    }
  }
}

const contentReducer = (state: ContentProviderState, action: ContentReducerAction): ContentProviderState => {
  switch (action.type) {
    case 'addPresentation': {
      // IF THE NEW PRESENTATION IS ALREADY IN OUR LOADED PRESENTATION'S ARRAY
      // THAT ONE WILL BE SELECTED INSTEAD OF ADDING A DUPLICATED PRESENTATION
      const loadedPresentation = state.presentations.find(({ presentable }) =>
        presentable.orm?.model.id === action.payload.item.model.id,
      );

      if (loadedPresentation) {
        if (action.payload.pageNumber) {
          const newPage = getPresentablePage(loadedPresentation.presentable, action.payload.pageNumber);
          loadedPresentation.currentPresentablePage = newPage || loadedPresentation.currentPresentablePage;
        }

        return {
          activePresentation: loadedPresentation,
          presentations: state.presentations,
        }
      }

      const presentable = getPresentable(action.payload.item);
      logger.debug('New Presentable', presentable)
      const activePresentation = getNewLoadedPresentation(presentable, action.payload.pageNumber);
      return {
        activePresentation,
        presentations: [...state.presentations, activePresentation],
      }
    }
    case 'removePresentation': {
      const presentations = state.presentations.filter(({ presentable }) =>
        presentable.id !== action.payload.presentationId);
      const activePresentation = state.activePresentation?.presentable.id === action.payload.presentationId
        ? presentations[0] : state.activePresentation;

      if (!activePresentation) {
        console.warn('The current presentation was closed.');
      } else if (presentations.length === state.presentations.length) {
        console.warn('The presentation to be removed was not found.');
      }

      return {
        presentations,
        activePresentation,
      }
    }
    case 'changeActivePresentation': {
      const activePresentation = state.presentations.find(({ presentable }) =>
        presentable.id === action.payload.presentationId);

      if (!activePresentation) {
        console.warn('The requested presentation was not found. \' Keeping the current one.');
      }

      return {
        ...state,
        activePresentation: activePresentation || state.activePresentation,
      }
    }
    case 'setActiveSlideByPresentationPageNumber': {
      if (!state.activePresentation) {
        return {
          presentations: [],
          activePresentation: undefined,
        }
      }

      const newPage = getPresentablePage(state.activePresentation.presentable, action.payload.presentationPageNumber);

      if (!newPage) {
        throw new Error('Page not found');
      }

      return {
        ...state,
        presentations: state.presentations
          .map(({ presentable, currentPresentablePage, currentPPZCoords, currentStep }) =>
            (
              {
                presentable,
                currentPresentablePage: presentable.id === state.activePresentation?.presentable.id
                  ? newPage : currentPresentablePage,
                currentPPZCoords,
                currentStep,
              })),
        activePresentation: {
          presentable: state.activePresentation.presentable,
          currentPPZCoords: undefined,
          currentPresentablePage: newPage,
          currentStep: action.payload.step,
        },
      }
    }
    case 'initPresentation': {
      const presentable = getPresentable(action.payload.item);
      const activePresentation = getNewLoadedPresentation(presentable, action.payload.pageNumber);
      return {
        activePresentation,
        presentations: [activePresentation],
      }
    }
    case 'setCurrentPPZCoords': {
      if (!state.activePresentation || !action.payload.coords) {
        return state;
      }
      state.activePresentation.currentPPZCoords = action.payload.coords;
      return {
        ...state,
        activePresentation: {
          ...state.activePresentation,
          currentPPZCoords: {
            ...action.payload.coords,
          },
        },
      };
    }
  }
};

const ContentProvider: React.FC<InitialValues> = (props) => {
  const [{ activePresentation, presentations }, dispatch] = useReducer(contentReducer, {
    presentations: [],
    activePresentation: undefined,
  });

  useContentInfoBroadcastChannel({
    activePresentation,
    setActiveSlideByPresentationPageNumber,
    setCurrentPPZCoords,
    meetingId: props.meetingId,
  });

  useEffect(() => {
    if (props.item) {
      dispatch({ type: 'initPresentation', payload: { item: props.item } });
    }
  }, []);

  function addPresentation(item: PresentableModelORM, pageNumber?: number): void {
    dispatch({ type: 'addPresentation', payload: { item, pageNumber } });
  }

  function removePresentation(presentationId: string): void {
    dispatch({ type: 'removePresentation', payload: { presentationId } });
  }

  function changeActivePresentation(presentationId: string): void {
    dispatch({ type: 'changeActivePresentation', payload: { presentationId } });
  }

  function setActiveSlideByPresentationPageNumber(presentationPageNumber: number, step?: number): void {
    dispatch({ type: 'setActiveSlideByPresentationPageNumber', payload: { presentationPageNumber, step } });
  }

  function initPresentation(item: PresentableModelORM, pageNumber?: number): void {
    dispatch({ type: 'initPresentation', payload: { item, pageNumber } });
  }

  function setCurrentPPZCoords(coords?: PPZTransform): void {
    dispatch({ type: 'setCurrentPPZCoords', payload: { coords } });
  }

  function nextPage(): void {
    if (activePresentation &&
      activePresentation.currentPresentablePage.presentationPageNumber <
      activePresentation?.presentable.numberOfPages) {
      dispatch({
        type: 'setActiveSlideByPresentationPageNumber',
        payload: {
          presentationPageNumber: activePresentation.currentPresentablePage.presentationPageNumber + 1,
        },
      });
    }
  }

  function prevPage(): void {
    if (activePresentation && activePresentation.currentPresentablePage.presentationPageNumber > 1) {
      dispatch({
        type: 'setActiveSlideByPresentationPageNumber',
        payload: {
          presentationPageNumber: activePresentation.currentPresentablePage.presentationPageNumber - 1,
        },
      });
    }
  }

  const contextValue = {
    activePresentation,
    changeActivePresentation,
    addPresentation,
    presentations,
    removePresentation,
    setActiveSlideByPresentationPageNumber,
    setCurrentPPZCoords,
    initPresentation,
    nextPage,
    prevPage,
  } as ContentType;

  return (
    <ContentContext.Provider value={contextValue}>
      {props.children}
    </ContentContext.Provider>
  )
}

export const ContentContext = createContext<ContentType>(null!);
ContentContext.displayName = 'ContentContext';
ContentProvider.displayName = 'ContentProvider';

export function useContent() {
  const context = useContext(ContentContext)
  if (!context) {
    throw new Error('useContent must be used within the ContentProvider')
  }
  return context;
}

export default ContentProvider;
