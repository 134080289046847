import React, { createContext, useContext, useState } from 'react'
import { Pressable, TouchableOpacity } from 'react-native'
import { OverflowMenu, MenuItem } from '@ui-kitten/components'
import { DNABox, DNAText, DNAIcon, DNADivider, DNAChip, DNAButton, DNASlider, Iffy } from '@alucio/lux-ui'
import ProfilePicture from 'src/components/DNA/Header/ProfilePicture'

import useScreenNav from 'src/components/DNA/hooks/useScreenNav'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import { useAppSettings, useOnlineStatus } from 'src/state/context/AppSettings'
import { useCurrentUser } from 'src/state/redux/selector/user'
import { useLogout } from 'src/components/Authenticator';

import { UserRole } from '@alucio/aws-beacon-amplify/src/models'
import { ROUTES } from 'src/router/routeDef'
import ProfileScreen from 'src/screens/Profile/Profile'
import { useSearchTextContextProvider } from 'src/components/Header/SearchInput/SearchInput'
import DNACommonConfirmation from '../Modal/DNACommonConfirmation'
import { isIOS } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'

const USER_ROLES = {
  [UserRole.ALUCIO_ADMIN]: 'Alucio Admin',
  [UserRole.TENANT_ADMIN]: 'Admin',
  [UserRole.TENANT_PUBLISHER]: 'Publisher',
  [UserRole.TENANT_VIEWER]: 'Viewer',
};

const ROLE_SWITCH = {
  [ROUTES.PUBLISHER_DASHBOARD.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.EMAIL_TEMPLATES.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.SEARCH_RESULTS_PUBLISHER.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.PUBLISHER_FOLDERS.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.PUBLISHER_SHARED_FOLDERS.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.PUBLISHER_FOLDERS_UPDATES.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.PUBLISHER_SHARED_FOLDERS.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.REPORTS.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  default: {
    role: USER_ROLES[UserRole.TENANT_PUBLISHER],
    screen: ROUTES.PUBLISHER_DASHBOARD,
  },
}

const analyticsEvents: { [K: string]: [string, Object] } = {
  roleSwitchToMSL: [
    'USER_CHANGE_TO_MSL',
    { action: 'CHANGE_TO_MSL', category: 'USER' },
  ],
  roleSwitchToPublisher: [
    'USER_CHANGE_TO_PUBLISHER',
    { action: 'CHANGE_TO_PUBLISHER', category: 'USER' },
  ],
  logout: [
    'LOGIN_LOGOUT',
    { action: 'LOGOUT', category: 'LOGIN' },
  ],
}

type ProfileMenuContextType = {
  appSettings?: ReturnType<typeof useAppSettings>,
  menu?: {
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
  },
  user?: {
    user: ReturnType<typeof useCurrentUser>['userProfile'],
    fullName: string,
    labelValues: string[],
    roleSwitch: typeof ROLE_SWITCH['default'],
  },
  nav?: {
    currentPage: ReturnType<typeof useCurrentPage>,
    go: () => void,
    goToProfilePassword: () => void,
    goToLogout: () => void,
  },
  enableDeviceSwitch?: boolean
}

// Being lazy by not filling out default values and making the types possibly undefined instead
const ProfileMenuContext = createContext<ProfileMenuContextType>({})
const useProfileMenu = () => useContext(ProfileMenuContext)
const ProfileMenuProvider: React.FC = (props) => {
  const { children } = props
  const [visible, setVisible] = useState<boolean>(false)
  const { userProfile: user } = useCurrentUser();
  const { go, goTo } = useScreenNav()
  const { signOut } = useLogout()
  const currentPage = useCurrentPage({ exact: false })
  const appSettings = useAppSettings()
  const dispatch = useDispatch();

  const fullName = user?.givenName + ' ' + user?.familyName

  /** Default labels from account */
  const rawLabelValues = user?.defaultFilterValues
    ?.reduce<string[]>(
      (acc, label) =>
        (label.values ? [...acc, ...label.values] : acc),
      []
    ) ?? []

  const labelValues = Array
    .from(new Set(rawLabelValues))
    .sort()

  const dismiss = (fn: Function) => (...args) => {
    setVisible(false)
    fn(...args)
  }

  const value = {
    appSettings,
    menu: {
      visible,
      setVisible,
    },
    user: {
      user,
      fullName,
      labelValues,
      roleSwitch: ROLE_SWITCH[currentPage?.UID ?? ''] ?? ROLE_SWITCH.default,
    },
    nav: {
      currentPage,
      go: dismiss(go),
      goToProfilePassword: dismiss(goTo.PROFILE_PASSWORD),
      goToLogout: dismiss(() => {
        const handleSignout = () => {
          goTo.HOME(undefined, ...analyticsEvents.logout)
          signOut()
        }
        // If isPWAInstalled is undefined we assume the PWA is installed if we're not on iOS & offline is enabled
        if (appSettings.isPWAInstalled !== false &&
          appSettings.isOfflineEnabled &&
          !appSettings.isPWAStandalone &&
          !isIOS) {
          const confirmModal = () => (
            <DNACommonConfirmation
              cancelText="Cancel"
              title="Confirm log out"
              confirmActionText="Log out"
              descriptionText="Log out from all tabs and the Beacon App ?"
              onConfirmAction={handleSignout}
            />
          )
          const payload = {
            isVisible: true,
            allowBackdropCancel: false,
            component: confirmModal,
          };
          dispatch(DNAModalActions.setModal(payload));
        } else {
          handleSignout()
        }
      }),
    },
    enableDeviceSwitch: appSettings.isDeviceSwitchEnabled,
  }

  return (
    <ProfileMenuContext.Provider value={value}>
      {children}
    </ProfileMenuContext.Provider>
  )
}

// Menu's Avatar component
const ProfilePictureMenu: React.FC = (props) => {
  const common = useProfileMenu()

  return (
    <OverflowMenu
      visible={common?.menu?.visible}
      onBackdropPress={() => common?.menu?.setVisible(false)}
      anchor={() => (
        <TouchableOpacity
          testID="profile-menu"
          activeOpacity={1}
          onPress={() => common?.menu?.setVisible(p => !p)}
        >
          <ProfilePicture />
        </TouchableOpacity>
      )}
    >
      <MenuItem
        disabled
        appearance="grouped"
        title={() => props.children as React.ReactElement}
      />
    </OverflowMenu>
  )
}

const ProfileMenuDesktop: React.FC = () => {
  const common = useProfileMenu()
  const onlineStatus = useOnlineStatus()
  const isNetworkConnected = onlineStatus
  const { setCurrentSearchString } = useSearchTextContextProvider();

  const roleSwitchHandler = () => {
    // @ts-ignore
    common.nav.go(common.user.roleSwitch.screen)
    setCurrentSearchString('');
  }

  if (!common?.user?.user) {
    return (
      <DNAText>
        User profile could not be loaded. Please contact an admin
      </DNAText>
    )
  }

  return (
    <ProfilePictureMenu>
      {/* MAIN INFO */}
      <DNABox fill appearance="col">
        <DNABox
          appearance="col"
          style={{
            width: 320,
            paddingVertical: 24,
            paddingHorizontal: 36,
            backgroundColor: 'white',
          }}
          alignX="center"
          spacing="medium"
          fill
        >
          <ProfilePicture size="medium" />
          <DNABox appearance="col" alignX="center" spacing="small">
            <DNAText h5 style={{ textAlign: 'center' }}>{common.user.fullName}</DNAText>
            <DNAText b1 style={{ textAlign: 'center' }}>{common.user.user.email}</DNAText>
            <DNAText status="subtle">{USER_ROLES[common.user.user.role]}</DNAText>
          </DNABox>
          <DNAButton
            testID="account-profile-button"
            appearance="ghost"
            context="minimum"
            onPress={() => common?.nav?.goToProfilePassword()}
            disabled={!isNetworkConnected}
          >
            Account Profile
          </DNAButton>
          <DNADivider height={1} width={80} />
          <DNABox appearance="col" alignX="center" fill spacing="small">
            <DNAText status="subtle">My profile</DNAText>
            <DNABox
              fill
              alignX="center"
              wrap="start"
              spacing="small"
              childStyle={{ paddingBottom: 4 }}
            >
              {
                common.user.labelValues.map((label, idx) => (
                  <DNAChip key={`${label}-${idx}`}>
                    {label}
                  </DNAChip>
                ))
              }
            </DNABox>
          </DNABox>
        </DNABox>

        <DNABox appearance="col">
          {/* ROLE SWITCH */}
          <Iffy is={common?.user.user.role === UserRole.TENANT_PUBLISHER}>
            <DNADivider />
            <DNABox appearance="col" style={{ padding: 12 }}>
              <DNABox style={{ paddingBottom: 8 }} fill appearance="col">
                <DNAText c2 status="secondary">USE BEACON AS ...</DNAText>
              </DNABox>
              <DNABox
                testID={`${common.user.roleSwitch.role.toLocaleLowerCase()}-menu-option`}
                alignY="center"
                spacing="small"
                as={TouchableOpacity}
                // @ts-ignore
                onPress={roleSwitchHandler}
                activeOpacity={1}
              >
                <DNAIcon.Styled
                  appearance="ghost"
                  status="dark"
                  name="account-edit"
                  size="medium"
                />
                <DNAText>{common.user.roleSwitch.role}</DNAText>
              </DNABox>
            </DNABox>
          </Iffy>

          {/* LOGOUT */}
          <DNADivider />
          <DNABox
            style={{ padding: 12 }}
            alignY="center"
            spacing="small"
            as={TouchableOpacity}
            // @ts-ignore
            onPress={() => common.nav.goToLogout()}
            activeOpacity={1}
            testID="account-logout-button"
          >
            <DNAIcon.Styled
              appearance="ghost"
              status="dark"
              name="logout"
              size="medium"
            />
            <DNAText>Log out</DNAText>
          </DNABox>
          {/* DEVICE SWITCH */}
          <Iffy is={common.enableDeviceSwitch}>
            <DNADivider />
            <DNABox
              as={TouchableOpacity}
              style={{ padding: 12 }}
              alignY="center"
              spacing="small"
              // @ts-ignore
              onPress={() => common.appSettings.dispatch({ type: 'toggleDeviceMode' })}
              activeOpacity={1}
            >
              <DNAIcon.Styled
                appearance="ghost"
                status="dark"
                name="monitor-cellphone"
                size="medium"
              />
              <DNAText>Switch device modes</DNAText>
            </DNABox>
          </Iffy>
        </DNABox>
      </DNABox>
    </ProfilePictureMenu>
  )
}

const ProfileMenuTablet: React.FC = () => {
  const [profileVisible, setProfileVisible] = useState<boolean>(false)

  return (
    <>
      <Pressable
        testID="profile-menu"
        onPress={() => {
          setProfileVisible(true)
        }}
      >
        <ProfilePicture />
      </Pressable>

      <DNASlider
        visible={profileVisible}
        setVisible={setProfileVisible}
      >
        <ProfileScreen />
      </DNASlider>
    </>
  )
}

const ProfileMenu: React.FC = (props) => {
  const { deviceMode } = useAppSettings()

  return (
    <ProfileMenuProvider>
      {
        deviceMode === 'desktop'
          ? <ProfileMenuDesktop {...props} />
          : <ProfileMenuTablet {...props} />
      }
    </ProfileMenuProvider>
  )
}

export default ProfileMenu
