import React, { ReactElement } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNACard, DNAChip, DNAText, Iffy } from '@alucio/lux-ui';
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { DRAWER_ENTITIES, drawerActions } from 'src/state/redux/slice/drawer';
import { useDispatch } from 'react-redux';
import { useEmailTemplateList } from 'src/state/redux/selector/emailTemplate';
import { EmailTemplate, EmailTemplateStatus } from '@alucio/aws-beacon-amplify/src/models';
import DNAEmailTemplatesContextMenu from 'src/components/DNA/EmailTemplates/DNAEmailTemplatesContextMenu';
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import { EmailTemplateORM } from 'src/types/types';
import { sortCollection } from 'src/state/redux/selector/common';
import emailTemplateQuery from 'src/state/redux/emailTemplate/query';

const styles = StyleSheet.create({
  rowMainWrapper: {
    minHeight: 'auto',
  },
  rowWrapper: {
    borderBottomColor: colors['color-flat-300'],
    borderBottomWidth: 1,
    minHeight: 64,
    paddingHorizontal: 21,
    paddingVertical: 12,
  },
  header: {
    marginBottom: 16,
  },
  mainWrapper: {
    paddingHorizontal: 33,
    paddingVertical: 24,
    backgroundColor: colors['color-text-basic'],
    minHeight: 'calc(100vh - 160px)',
    minWidth: 'calc(100vw - 98px)',
    overflowX: 'auto',
  },
});

const EmailTemplates = () => {
  const emailTemplates = useEmailTemplateList();
  const orderedTemplates = sortCollection(emailTemplates, emailTemplateQuery.sorts.titleAsc);

  const dispatch = useDispatch();
  const currentPage = useCurrentPage()

  const onCreateEmailTemplate = (): void => {
    dispatch(drawerActions.toggle({
      entity: DRAWER_ENTITIES.EMAIL_TEMPLATE,
    }));
  };

  return (
    <DNABox style={styles.mainWrapper} appearance="col">
      { /* HEADER */}
      <DNABox spacing="between" alignY="center" style={styles.header}>
        <DNAText h5 status="subtle" testID="page-title">
          {currentPage?.LABEL}
        </DNAText>
        <DNAButton onPress={onCreateEmailTemplate}>
          Create new
        </DNAButton>
      </DNABox>
      { /* TEMPLATES LIST */}
      <Iffy is={emailTemplates.length}>
        <DNABox>
          <EmailTemplateList emailTemplates={orderedTemplates} />
        </DNABox>
      </Iffy>
      { /* EMPTY STATE */}
      <Iffy is={!emailTemplates.length}>
        <DNACard appearance="flat">
          <DNAEmpty emptyVariant={EmptyVariant.EmailTemplatesListEmpty} />
        </DNACard>
      </Iffy>
    </DNABox>
  );
};

function EmailTemplateList(props: { emailTemplates: EmailTemplateORM[] }): ReactElement {
  const dispatch = useDispatch()

  const openEmailTemplateEditorPanel = (emailTemplate: EmailTemplate) => {
    dispatch(drawerActions.toggle({
      entityId: emailTemplate.id,
      entity: DRAWER_ENTITIES.EMAIL_TEMPLATE,
    }))
    analytics?.track('EMAIL_EDIT', {
      action: 'EMAIL_EDIT',
      category: 'EMAIL',
      emailTemplateId: emailTemplate.id,
    });
  }

  return (
    <DNACard appearance="outline" >
      {
        props.emailTemplates.filter(x => x.model.status !== EmailTemplateStatus.REMOVED)
          .map((emailTemplateORM, i, arr) => (
            <DNACard
              key={emailTemplateORM.model.id}
              appearance="flat"
              interactive="pressable"
              style={styles.rowMainWrapper}
              onPress={() => { openEmailTemplateEditorPanel(emailTemplateORM.model) }}
            >
              <DNABox
                testID="email-template-list"
                key={emailTemplateORM.model.id}
                alignY="center"
                style={(i === arr.length - 1) ? [styles.rowWrapper, { borderBottomWidth: 0 }]
                  : styles.rowWrapper}
              >
                <DNABox style={{ marginRight: 24 }}>
                  <DNAEmailTemplatesContextMenu emailTemplateORM={emailTemplateORM}>
                    <DNAButton
                      status="subtle"
                      appearance="ghostAlt"
                      context="minimum"
                      iconLeft="dots-vertical"
                    />
                  </DNAEmailTemplatesContextMenu>
                </DNABox>
                <DNABox appearance="col" fill>
                  <DNAText numberOfLines={1}>{emailTemplateORM.model.title}</DNAText>
                  <DNABox spacing="tiny" wrap="start">
                    {
                      emailTemplateORM.model.labelValues?.map(({ value }, idx) => (
                        <DNAChip key={value + idx} appearance="tag" status="dark" style={{ marginTop: 4 }}>
                          {value}
                        </DNAChip>
                      ))
                    }
                  </DNABox>
                </DNABox>
                <Iffy is={emailTemplateORM.model.status === EmailTemplateStatus.DRAFT}>
                  <DNAChip style={{ marginLeft: 24 }} appearance="tag" status="warningAlt">
                    DRAFT
                  </DNAChip>
                </Iffy>
                <Iffy is={emailTemplateORM.model.status === EmailTemplateStatus.DEACTIVATED}>
                  <DNAChip style={{ marginLeft: 24 }} appearance="tag" status="dangerAlt">
                    DEACTIVATED
                  </DNAChip>
                </Iffy>
              </DNABox>
            </DNACard>
          ))
      }
    </DNACard>
  );
}

EmailTemplates.displayName = 'EmailTemplates';

export default EmailTemplates;
