import React from 'react'
import {
  ScrollView,
  StyleSheet,
} from 'react-native';
import {
  DNAIcon,
  Iffy,
  DNABox,
  DNACheckbox,
  DNAText,
  Popover,
  DNADivider,
  InformationMessage,
  Spinner,
} from '@alucio/lux-ui'
import { DNATextProps } from '@alucio/lux-ui/src/components/type/DNAText/DNAText'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import { useVersioningPanel } from './VersioningPanelContext';
import { useUserByEmail } from 'src/state/redux/selector/user'
import { S } from 'src/screens/Publishers/Versioning/VersioningPanel'
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag'
import { FeatureFlags } from 'src/types/featureFlags'

const PURPOSE_HOVER = (
  `Expected use, and handling purpose designated for this file.
EP: Can be presented to external audience, in proactive setting
ER: Can be presented to external audience, in reactive setting
INT: Cannot be shared outside of the company`
)

const PERMISSIONS_MODIFIABLE_HOVER = (
  `Enabling this option allows your audience to hide slides or pages within this file
as well as use this file to build personalized presentations if your organization
offers this feature.'`
)

const PERMISSIONS_DISTRIBUTABLE = (
  'This determines whether a file can be distributed outside of the company.'
)

const styles = StyleSheet.create({
  LeftColumn: {
    maxWidth: 612,
  },
  ColumnDivider: {
    marginHorizontal: 24,
    maxWidth: 2,
  },
  ThumbnailBorder: {
    borderColor: colors['color-flat-400'],
    borderRadius: 2,
    borderWidth: 1,
  },
  mainWrapper: {
    flex: 1,
  },
})

const titleProps: DNATextProps = {
  bold: true,
  status: 'dark',
  b1: true,
}

const DocumentInfoReadOnly: React.FC = () => {
  const { currentDocumentVersionORM, cond, meta } = useVersioningPanel()

  const docVersion = currentDocumentVersionORM
  const currentDocumentVersionORMExpiresAt = docVersion.model.expiresAt;
  const selectedDate = currentDocumentVersionORMExpiresAt != null
    ? format(parseISO(currentDocumentVersionORMExpiresAt!), 'MM/dd/yyyy')
    : '';

  const ownerName = useUserByEmail(docVersion.model.owner)?.meta.formattedName
  const enableDistributable = useFeatureFlag(FeatureFlags.BEAC_2516_Enable_Document_Sharing_Templates)

  return (
    <ScrollView style={[styles.mainWrapper, S.tabContentContainer]}>
      <DNABox
        fill
        appearance="col"
        spacing="large"
        childFill={2}
      >
        {/* INFORMATIONAL BANNER */}
        <Iffy is={cond.hasInfoMessage}>
          <InformationMessage
            leftIcon={cond.isProcessingNewVersion
              ? <DNABox style={{ marginLeft:10 }}>
                <Spinner status="primary" size="small" />
              </DNABox> : undefined}
            text={meta.infoMessage?.message}
            variance={meta.infoMessage?.status}
          />
        </Iffy>
        {/* DOCUMENT HEADER */}
        <DNABox alignY="end" spacing="medium" childFill={1}>
          {/* DOCUMENT - THUMBNAIL */}
          <DNABox style={styles.ThumbnailBorder}>
            <DNADocumentThumbnail
              documentVersionORM={docVersion}
              width={137}
              height={75}
              showProcessing={true}
            />
          </DNABox>

          {/* DOCUMENT - TITLE */}
          <DNABox appearance="col" spacing="tiny">
            <DNAText {...titleProps}>Title</DNAText>
            <DNAText status="flat" numberOfLines={1}>{docVersion.model.title}</DNAText>
          </DNABox>

        </DNABox>

        <ScrollView>
          <DNABox>
            {/* LEFT COLUMN */}
            <DNABox fill appearance="col" spacing="large">
              {/* PURPOSE */}
              <DNABox appearance="col">
                {/* PURPOSE - TITLE */}
                <DNABox alignY="center" spacing="small">
                  <DNAText {...titleProps}>Purpose</DNAText>
                  <Popover>
                    <Popover.Anchor>
                      <DNAIcon.Styled size="small" status="flatAlt" name="help-circle-outline" />
                    </Popover.Anchor>
                    <Popover.Content>
                      <DNAText status="basic">{ PURPOSE_HOVER }</DNAText>
                    </Popover.Content>
                  </Popover>
                </DNABox>
                {/* PURPOSE - VALUE */}
                <DNAText status="flat">
                  {docVersion.model.purpose}
                </DNAText>
              </DNABox>

              {/* PERSMISSIONS */}
              <DNABox appearance="col" spacing="small">
                {/* PERMISSIONS - TITLE */}
                <DNAText {...titleProps}>File Permissions</DNAText>
                {/* PERMISSIONS - ROWS */}
                <DNABox appearance="col" spacing="small">
                  {/* PERMISSIONS - MODIFIABLE */}
                  <DNABox spacing="small">
                    <DNACheckbox
                      checked={docVersion.model.canHideSlides}
                      disabled={true}
                    />
                    <DNABox alignY="center" spacing="small">
                      <DNAText status="flat">File is modifiable</DNAText>
                      <Popover>
                        <Popover.Anchor>
                          <DNAIcon.Styled size="small" status="flatAlt" name="help-circle-outline" />
                        </Popover.Anchor>
                        <Popover.Content>
                          <DNAText status="basic">
                            { PERMISSIONS_MODIFIABLE_HOVER }
                          </DNAText>
                        </Popover.Content>
                      </Popover>
                    </DNABox>
                  </DNABox>

                  {/* PERMISSIONS - DISTRIBUTABLE */}
                  <Iffy is={enableDistributable}>
                    <DNABox spacing="small">
                      <DNACheckbox
                        checked={docVersion.model.distributable}
                        disabled={true}
                      />
                      <DNABox alignY="center" spacing="small">
                        <DNAText status="flat">File is distributable</DNAText>
                        <Popover>
                          <Popover.Anchor>
                            <DNAIcon.Styled size="small" status="flatAlt" name="help-circle-outline" />
                          </Popover.Anchor>
                          <Popover.Content>
                            <DNAText status="basic">
                              { PERMISSIONS_DISTRIBUTABLE }
                            </DNAText>
                          </Popover.Content>
                        </Popover>
                      </DNABox>
                    </DNABox>
                  </Iffy>

                  {/* PERMISSIONS - DOWNLOADABLE */}
                  <DNABox spacing="small">
                    <DNACheckbox
                      checked={docVersion.model.downloadable}
                      disabled={true}
                    />
                    <DNAText status="flat">File is downloadable by viewers</DNAText>
                  </DNABox>
                </DNABox>
              </DNABox>
              {/* SHORT DESCRIPTION */}
              <DNABox appearance="col" spacing="tiny">
                <DNAText {...titleProps}>Short Description</DNAText>
                <DNAText status="flat">{docVersion.model.shortDescription}</DNAText>
              </DNABox>
              {/* LONG DESCRIPTION */}
              <Iffy is={docVersion.model.longDescription}>
                <DNABox appearance="col" spacing="tiny">
                  <DNAText {...titleProps}>Long Description</DNAText>
                  <DNAText status="flat">{docVersion.model.longDescription}</DNAText>
                </DNABox>
              </Iffy>
              {/* CONTENT SOURCE */}
              <Iffy is={docVersion.meta.integration.source}>
                <DNABox appearance="col" spacing="tiny">
                  <DNAText {...titleProps}>Content Source</DNAText>
                  <DNAText status="flat">{docVersion.meta.integration.source}</DNAText>
                </DNABox>
              </Iffy>
              {/* EXPIRATION DATE */}
              <DNABox appearance="col" spacing="tiny">
                <DNAText {...titleProps}>Expiration Date</DNAText>
                <DNAText status="flat">{selectedDate}</DNAText>
              </DNABox>
              {/* BUSINESS OWNER */}
              <DNABox appearance="col" spacing="tiny">
                <DNAText {...titleProps}>Business Owner</DNAText>
                <DNAText status="flat">{ownerName}</DNAText>
                <DNAText status="flat">{docVersion.model.owner}</DNAText>
              </DNABox>
            </DNABox>

            {/* COLUMN DIVIDER */}
            <DNADivider vertical style={styles.ColumnDivider} />

            {/* RIGHT COLUMN */}
            <DNABox fill appearance="col" spacing="large">
              {/* TENANT FIELDS */}
              {
              Object
                .entries(docVersion.meta.tenantFields.valuesMap)
                .map(([fieldKey, fieldValue]) => {
                  return (
                    fieldValue.length > 0
                      ? (
                        <DNABox key={fieldKey} appearance="col" spacing="tiny">
                          <DNAText {...titleProps}>{fieldKey}</DNAText>
                          <DNAText status="flat">{fieldValue.join(', ')}</DNAText>
                        </DNABox>
                      )
                      : null
                  )
                })
            }
            </DNABox>
          </DNABox>
        </ScrollView>
      </DNABox>
    </ScrollView>
  )
}

export default DocumentInfoReadOnly;
