import { CustomDeckORM, DocumentVersionORM, PageExtended, FolderItemORM } from 'src/types/types';
import { FolderItemType } from '@alucio/aws-beacon-amplify/src/models';

/**
 * Returns a string that represents the size of the file
 * @param bytes: number
 * @param decimals: number
 */
export function bytesToSize(bytes: number, decimals: number = 2): string {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Returns a boolean that represents, given the params, whether it's customSlides (not custom deck)
 * @param activeDoc?: DocumentORM
 * @param folderItemORM?: FolderItemORM
 */
export function haveHiddenSlides(activeDocVersion?: DocumentVersionORM, folderItemORM?: FolderItemORM): boolean {
  return folderItemORM?.model.type !== FolderItemType.CUSTOM_DECK &&
    !!(activeDocVersion?.relations.document?.meta.permissions.MSLSelectSlides &&
    activeDocVersion?.relations.document?.relations.version.latestDocumentVersionPublished?.model.canHideSlides &&
    folderItemORM?.model?.visiblePages?.length) &&
    // @ts-expect-error, This validation it is to only affect the latest version of the custom document
    (activeDocVersion?.model.versionNumber === folderItemORM?.relations.item.model.versionNumber)
}

/**
 * Returns an array of the visible pages according to the document/folderItemORM
 * @param activeDoc?: DocumentORM
 * @param folderItemORM?: FolderItemORM
 * @param customDeck?: CustomDeckORM
 */
export function getVisiblePages(
  activeDocVersion?: DocumentVersionORM,
  folderItemORM?: FolderItemORM,
  customDeck?: CustomDeckORM): PageExtended[] {
  if (!haveHiddenSlides(activeDocVersion, folderItemORM) && !customDeck) {
    return activeDocVersion?.model.pages || [];
  }

  if (customDeck) {
    return customDeck.meta.groupsORM.reduce((acc, group) => {
      if (!group.model.visible) {
        return acc;
      }

      // IF THE ITEM IS A CUSTOM DECK, WE NEED A WAY TO IDENTIFY THE PAGE'S DOCUMENTVERSION
      // THEREFORE THE DOCUMENTVERSIONPAGE TYPE (WHICH IS THE SAME AS A PAGE BUT WITH AN OPTIONAL
      // DOCUMENTVERSION KEY)
      return [
        ...acc,
        ...group.pages.map(
          (page) => (
            {
              ...page.page,
              documentVersion: page.documentVersionORM,
              groupId: group.model.id,
            }
          ),
        ),
      ]
    }, [] as PageExtended[]);
  }

  return activeDocVersion?.model.pages
    .filter(({ number, isRequired }) => (folderItemORM?.model?.visiblePages?.includes(number) || isRequired)) || [];
}
