import { DNABox, Iffy } from '@alucio/lux-ui'
import React from 'react'
import { useAppSettings } from 'src/state/context/AppSettings'
import { FolderORM } from 'src/types/orms'
import { useInfoPanelState } from '../State/InfoPanelStateProvider'
import Details from './Details/Details'
import Sharing from './Sharing/Sharing'

interface ContentPanelsProps {
  itemORM: FolderORM
  /** TODO: add this type in the future once documents are supported */
  // | DocumentORM
}

const ContentPanels: React.FC<ContentPanelsProps> = (
  { itemORM },
) => {
  const { activeActionBarOption, collapsed } = useInfoPanelState()
  const { deviceMode } = useAppSettings()
  const isTablet = deviceMode === 'tablet'
  return (
    <Iffy is={!collapsed}>
      <DNABox style={isTablet ? { width: 285 } : { flex: 1 }}>
        <Iffy is={activeActionBarOption === 'Sharing'}>
          <Sharing itemORM={itemORM} />
        </Iffy>
        <Iffy is={activeActionBarOption === 'Details'}>
          <Details itemORM={itemORM} />
        </Iffy>
      </DNABox>
    </Iffy>
  )
}

export default ContentPanels
