import React from 'react'
import { FolderItemORM } from 'src/types/types'
import { DNACardComponent } from 'src/components/DNA/Grid/Cards/types'

import { useAppSettings } from 'src/state/context/AppSettings';
import {
  DNAButton,
  DNABox,
  DNAText,
  DNACard,
  DNADivider,
  Iffy,
  DNAChip,
  luxColors,
} from '@alucio/lux-ui'

import DNACustomDeckContextMenu from 'src/components/DNA/Document/DNACustomDeckContextMenu'
import DNACustomDeckThumbnail from 'src/components/DNA/Document/DNACustomDeckThumbnail';
import { isCustomDeckORM } from 'src/types/typeguards'
import DNADocumentVersionUpdateButton from 'src/components/DNA/Document/DNADocumentVersionUpdateButton';
import { useDNACustomDeckActions } from 'src/components/DNA/Document/DNACustomDeck.actions';
import { CUSTOM_DECK_CARD_MAX_HEIGHT } from './constants';

export const DNACustomDeckCard: DNACardComponent<FolderItemORM> = (props) => {
  const { ORM: folderItemORM, isNetworkConnected, onPress } = props
  const { deviceMode } = useAppSettings();
  const DNACustomDeckActions = useDNACustomDeckActions()
  const { isOnline } = useAppSettings()

  if (!isCustomDeckORM(folderItemORM.relations.item)) { return (<></>) }
  const customDeckORM = folderItemORM.relations.item
  const isReviewRequired = customDeckORM.meta.version.requiresReview
  const unavailableContent = !isNetworkConnected && !customDeckORM.meta.assets.isContentCached
  const openDeckEditor = DNACustomDeckActions.edit(customDeckORM, folderItemORM, 'REVIEW')

  const handleThumbPress = () => {
    // [TODO-2126] - We should have a property that combines isOnline and isContentCached
    //               on the CustomDeckORM rather than checking both at the component level
    if (!isOnline && !customDeckORM.meta.assets.isContentCached) {
      return;
    }

    if (isReviewRequired) {
      openDeckEditor()
      return;
    }

    DNACustomDeckActions.present(customDeckORM)()
  }

  return (
    <DNABox
      alignX="start"
      style={{ minWidth: 272, height: CUSTOM_DECK_CARD_MAX_HEIGHT }}
    >
      <DNACard
        interactive="pressable"
        onPress={() => { isReviewRequired ? openDeckEditor() : onPress?.(folderItemORM) }}
        style={{ padding: 12 }}
      >
        <DNABox appearance="col" spacing="between" style={{ minHeight:'100%', opacity: unavailableContent ? 0.4 : 1 }}>
          <DNACustomDeckThumbnail
            customDeckORM={customDeckORM}
            width={248}
            height={142}
            unavailableContent={unavailableContent}
            onPress={handleThumbPress}
          />
          <DNABox appearance="col" spacing="medium">
            <DNAText
              style={{ width: 208, overflow: 'hidden' }}
              numberOfLines={2}
            >
              {/* This is folderItemORM instead of customDeckORM because the title prop is in the folder item orm */}
              {folderItemORM.model.customTitle ? folderItemORM.model.customTitle : 'Untitled Presentation'}
            </DNAText>
            <DNABox appearance="col" spacing="small">
              <DNADivider />
              <DNABox alignY="center" spacing="between">
                <DNABox alignY="center" spacing="small">
                  <DNAChip appearance="tag" status="subtle">MODIFIED</DNAChip>
                  <Iffy is={unavailableContent}>
                    <DNAChip appearance="tag" style={{ backgroundColor: luxColors.basicBlack.primary }}>
                      NOT DOWNLOADED
                    </DNAChip>
                  </Iffy>
                </DNABox>
                <DNABox spacing="small">
                  <Iffy is={!isReviewRequired}>
                    <DNADocumentVersionUpdateButton orm={folderItemORM} />
                    <DNACustomDeckContextMenu folderItemORM={folderItemORM}>
                      <DNAButton
                        status="subtle"
                        appearance="ghostAlt"
                        context={deviceMode === 'tablet' ? 'roundTablet' : 'minimum'}
                        iconLeft="dots-vertical"
                        size={deviceMode === 'tablet' ? 'xlarge' : 'large'}
                      />
                    </DNACustomDeckContextMenu>
                  </Iffy>
                  <Iffy is={isReviewRequired}>
                    <DNAButton
                      appearance="outline"
                      onPress={openDeckEditor}
                      iconLeft="alert"
                      size="small"
                      status="warning"
                    >
                      Review
                    </DNAButton>
                  </Iffy>
                </DNABox>
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNACard>
    </DNABox>
  )
}

export default DNACustomDeckCard
