import {
  DNABox,
  // TODO: reenable this in https://alucioinc.atlassian.net/browse/BEAC-2829
  // DNAButton,
  DNADivider, DNAText, Iffy,
} from '@alucio/lux-ui';
import React from 'react';

import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import MeetingTable from 'src/components/Meeting/MeetingTable';
import { useMeetingList } from 'src/state/redux/selector/meeting';
// TODO: reenable this in https://alucioinc.atlassian.net/browse/BEAC-2829
// import { useDispatch } from 'react-redux';
// import { drawerActions, DRAWER_ENTITIES } from 'src/state/redux/slice/drawer';
import orderBy from 'lodash/orderBy'
import { MeetingORM } from 'src/types/orms';

export const isNotDeleted = (item: unknown) : item is MeetingORM => item
  ? (item as MeetingORM).model.status !== 'DELETED'
  : false

const DNAMeetingsHistoryV2 = () => {
  const meetingList = useMeetingList();
  // TODO: reenable this in https://alucioinc.atlassian.net/browse/BEAC-2829
  // const dispatch = useDispatch();

  const visibleMeetings = meetingList
    .filter(isNotDeleted)

  /** TODO: perhaps we should look into why these are being stored in a seemingly random
   * order on the backend should performance become an issue in the future. Additionally,
   * pagination might need to be implemented here if the list gets too large */
  const sortedMeetings = orderBy(visibleMeetings, ['model.startTime'], ['desc'])

  // TODO: reenable this in https://alucioinc.atlassian.net/browse/BEAC-2829
  // const onAddMeeting = (): void => {
  //   dispatch(drawerActions.toggle({
  //     entity: DRAWER_ENTITIES.MEETING,
  //   }));
  // };

  return (
    <DNABox alignX="center">
      <DNABox
        fill
        style={{
          maxWidth: 1440,
          paddingHorizontal: 48,
          paddingVertical: 20,
        }}
      >
        <DNABox fill spacing="medium" appearance="col">
          {/* Header */}
          <DNABox alignY="center" spacing="between">
            <DNAText h5 status="secondary">Meeting History V2</DNAText>
            {/* TODO: reenable this in https://alucioinc.atlassian.net/browse/BEAC-2829 */}
            {/* <DNAButton size="small" onPress={onAddMeeting}>Add meeting</DNAButton> */}
          </DNABox>
          <DNADivider />
          {/* Empty */}
          <Iffy is={!sortedMeetings.length}>
            <DNAEmpty
              useContainer={false}
              emptyVariant={EmptyVariant.MeetingsHistoryEmpty}
            />
          </Iffy>
          {/* Table */}
          <Iffy is={sortedMeetings.length}>
            <MeetingTable data={sortedMeetings}/>
          </Iffy>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export default DNAMeetingsHistoryV2;
