import React, { useEffect, useMemo, useRef, useState } from 'react'
import { compose } from 'redux'

import {
  DNABox,
  DNAButton,
  DNADivider,
  DNAText,
} from '@alucio/lux-ui'

import { useSort, withSort } from 'src/components/DNA/hooks/useSort'
import { merge, filters } from 'src/state/redux/document/query'

import DNAPublisherList from 'src/components/DNA/GridList/DNAPublisherGridList'
import DNADocumentFilters,
{
  useDNADocumentFilters,
  withDNADocumentFilters,
} from 'src/components/DNA/Document/DNADocumentFilters/DNADocumentFilters'
import useFixedHeader from '@alucio/lux-ui/src/hooks/useFixedLayout'
import { StyleSheet, Image } from 'react-native';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { useCanPerformSearch, useDocumentSearch } from 'src/state/redux/selector/documentSearch/documentSearch'
import { useHistory, useLocation } from 'src/router'
import qs from 'qs'
import debounce from 'lodash/debounce';
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'

const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: colors['color-text-basic'],
    flex: 1,
    flexDirection: 'column',
    minHeight: 'calc(100vh - 160px)',
    minWidth: 'auto',
    paddingHorizontal: 33,
    paddingVertical: 24,
  },
  resultsLoadingImage: {
    width: 200,
    height: 200,
  },
});

const PublisherSearchResults: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const qsParam = qs.parse(location.search, { ignoreQueryPrefix: true })
  const [searchText, setSearchText] = useState(qsParam.searchText as string ?? '');
  const searchTextHolder = useRef(qsParam.searchText as string ?? '');
  const [isLoading, setIsLoading] = useState(false);
  const { sortSelectorOpts } = useSort()
  const fixedHeaderWidth = useFixedHeader({ offSet: 163 });
  const { toggleModal, filterSelectorOpts } = useDNADocumentFilters()
  const canPerformSearch = useCanPerformSearch();

  useEffect(() => {
    const searchInputReference = document.getElementById('header-search-input') as HTMLInputElement;
    const debouncedKeyDownHandler = debounce(() => {
      setSearchText(searchTextHolder.current)
      setIsLoading(false)

      // Update the url with  the new search text
      history.replace({
        pathname: location.pathname,
        search: qs.stringify({ ...qsParam, searchText: searchTextHolder.current }),
      })
    }, 500);

    const keyDownHandler = (newSearchText: string) => {
      const canSearch = canPerformSearch(newSearchText.trim());
      if (canSearch && !isLoading) {
        setIsLoading(true)
      }
      // Store the updated text in a ref to update
      // the state searchText in the debounced handler
      searchTextHolder.current = newSearchText
      debouncedKeyDownHandler()
    }

    const keyDownHandlerWrapper = () => {
      if (searchInputReference.value !== searchTextHolder.current) {
        keyDownHandler(searchInputReference.value)
      }
    }

    const clearClickHandler = (e: any) => {
      if (e.target?.closest("[data-testid='header-search-input-clear']")) {
        keyDownHandler('')
      }
    }
    searchInputReference?.addEventListener('keyup', keyDownHandlerWrapper);

    // We bind directly to document for clear search icon click because the icon
    // is being re-rendered by Search Input and ref would be lost
    document.addEventListener('click', clearClickHandler)
    return () => {
      searchInputReference?.removeEventListener('keyup', keyDownHandlerWrapper);
      document.removeEventListener('click', clearClickHandler)
    }
  }, []);

  const selectorOpts = useMemo(
    () => merge(
      filters.nonDeleted,
      filterSelectorOpts,
      sortSelectorOpts,
    ),
    [sortSelectorOpts, filterSelectorOpts],
  )
  const docSearchOpts = useMemo(() => ({ text: searchText, isPublisher: true }), [searchText])
  const searchedDocs = useDocumentSearch(docSearchOpts, selectorOpts)
  const pluralResults = searchedDocs.length > 1 ? 's' : ''

  const title =
    `Search results for "${searchText}" | ${searchedDocs.length} result${pluralResults}`

  return (
    <DNABox style={styles.mainWrapper} spacing="medium">
      <DNADocumentFilters.Modal documents={searchedDocs} />
      <DNABox fill spacing="medium" appearance="col">
        {/* Header */}
        <DNABox alignY="end" spacing="between" style={{ maxWidth : fixedHeaderWidth }}>
          <DNABox appearance="col" spacing="medium">
            <DNAText testID="page-title" h5 status="subtle">{title}</DNAText>
          </DNABox>
          <DNABox>
            <DNAButton
              appearance="outline"
              iconLeft="filter-variant"
              onPress={toggleModal}
            >
              Filters
            </DNAButton>
          </DNABox>
        </DNABox>
        <DNADivider />
        {/* Content */}
        <DNABox fill>
          <DNABox appearance="col" spacing="medium">
            <DNABox spacing="between" alignY="start" childFill={0} style={{ maxWidth :  fixedHeaderWidth }}>
              <DNADocumentFilters.Chips documents={searchedDocs} chipsStatus="primary" />
            </DNABox>
            <DNABox appearance="col" spacing="medium">
              <DNAPublisherList
                documents={searchedDocs}
                isLoading={isLoading}
                loadingComponent={<LoadingAnimation />}
                emptyVariant={EmptyVariant.PublisherDocumentResultsListEmpty}
              />
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  );
}

const LoadingAnimation = () => (
  <DNABox fill alignY="center">
    <DNABox
      fill
      appearance="col"
      alignX="center"
      alignY="center"
      spacing="medium"
      style={[{ padding: 48 }]}
    >
      <Image
        style={styles.resultsLoadingImage}
        source={require('../../../assets/images/LoaderDNA.gif')}
      />
    </DNABox>
  </DNABox>
)

export default compose(
  withDNADocumentFilters,
  withSort,
)(PublisherSearchResults)
