import React, { useEffect } from 'react'
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui'
import { Pressable, StyleSheet } from 'react-native'
import { SelectionOption } from '../ContentPreviewModal'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import { LoadedDocument, useContentViewerModalState } from '../state/ContentViewerModalStateProvider'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { isDocumentVersionORM, isFolderItemORM } from 'src/types/typeguards';
import { isDocumentVersion } from 'src/components/DNA/AssociatedFiles/AssociatedFilesList';

interface ActiveDocumentTabsProps {
    onOptionSelected: (option: SelectionOption) => void;
}

const styles = StyleSheet.create({
  tabBase:{
    borderColor: colors['color-flat-800'],
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  inactiveTab: {
    backgroundColor: colors['color-flat-1200'],
  },
  activeTab: {
    backgroundColor: colors['color-flat-1000'],
  },
})

const ActiveDocumentTabs:React.FC<ActiveDocumentTabsProps> = (
  props,
) => {
  const { onOptionSelected } = props
  const {
    setActiveDocVersionAndSlide,
    setActiveFolderItemAndSlide,
    loadedDocuments,
    setLoadedDocuments,
    activeSlide,
  } = useContentViewerModalState()

  const handleClose = (selectedDoc: LoadedDocument) => {
    const { activeItem:selectedItem } = selectedDoc
    if (loadedDocuments.length === 1) {
      /** If this is the only loaded doc exit the content preview modal */
      onOptionSelected('exit-fullwindow')
    } else {
      /** Otherwise pop it out of the loaded docs array */
      setLoadedDocuments(p => {
        const selectedItemIdx = p.findIndex(loadedDoc => loadedDoc.activeItem.model.id === selectedItem.model.id)
        p.splice(selectedItemIdx, 1)

        if (selectedDoc.active) {
          const itemToSwitchTo = p[0]
          itemToSwitchTo.active = true
          if (isFolderItemORM(itemToSwitchTo.activeItem)) {
            setActiveFolderItemAndSlide(itemToSwitchTo.activeItem, itemToSwitchTo.activeSlide)
          } else if (isDocumentVersionORM(itemToSwitchTo.activeItem)) {
            setActiveDocVersionAndSlide(itemToSwitchTo.activeItem, itemToSwitchTo.activeSlide)
          }
        }

        return [...p]
      })
    }
  }

  const handleTabSelection = (selectedDoc:LoadedDocument) => {
    const { activeItem:selectedItemORM } = selectedDoc

    /** Reset all loaded docs to not active */
    loadedDocuments
      .forEach(loadedDoc => { loadedDoc.active = false })
    /** Update our current doc to be the active item */
    selectedDoc.active = true

    if (isFolderItemORM(selectedItemORM)) {
      setActiveFolderItemAndSlide(selectedItemORM, selectedDoc.activeSlide)
    } else if (isDocumentVersionORM(selectedItemORM)) {
      setActiveDocVersionAndSlide(selectedItemORM, selectedDoc.activeSlide);
    }
  }

  const tabEls = loadedDocuments.map(loadedDoc => {
    const { activeItem, active:isActiveDoc } = loadedDoc
    let title:string|undefined
    let chip
    if (isFolderItemORM(activeItem)) {
      if (activeItem.model.customTitle) {
        title = activeItem.model.customTitle
      } else if (isDocumentVersion(activeItem.relations.item)) {
        title = activeItem.relations.item.model.title
        chip = (<DNADocumentChip
          item={activeItem.relations.item.relations.document}
          variant="purpose"
        />)
      }
    } else if (isDocumentVersionORM(activeItem)) {
      title = activeItem.model.title
      chip = (<DNADocumentChip
        item={activeItem.relations.document}
        variant="purpose"
      />)
    }

    const tabStyle = isActiveDoc ? [styles.tabBase, styles.activeTab] : [styles.tabBase, styles.inactiveTab]

    return (
      <DNABox
        key={activeItem.model.id}
        as={Pressable}
        alignY="center"
        style={tabStyle}
        fill
        // @ts-ignore - using the as prop to switch to a pressable element does not add onPress as a recognized prop. Ping @alucio-kinthirath for details
        onPress={() => { handleTabSelection(loadedDoc) }}
        childFill={1}
      >
        <DNABox
          style={{
            marginLeft: 8,
            marginRight: 4,
            opacity: isActiveDoc ? undefined : 0.4,
          }}
        >

          {/* Iffy doesn't work here because the typescript compiler doesn'trecognize the guard for activeItem */}
          {chip}

        </DNABox>

        <DNABox
          fill
        >
          <DNAText
            b2
            status="basic"
            style={{
              opacity: isActiveDoc ? undefined : 0.4,
            }}
            numberOfLines={1}
          >{title}</DNAText>
        </DNABox>
        <DNABox>
          <DNAButton
            appearance="ghost"
            status="neutral"
            context="minimum"
            iconLeft={'close'}
            style={{ marginRight: 12 }}
            onPress={() => { handleClose(loadedDoc) }}
          />
        </DNABox>
      </DNABox>
    )
  })

  const handleActiveSlideUpdate = () => {
    const activeDocIdx = loadedDocuments.findIndex(loadedDoc => loadedDoc.active)
    if (activeDocIdx === -1) return
    loadedDocuments[activeDocIdx].activeSlide = activeSlide
  }
  useEffect(handleActiveSlideUpdate, [activeSlide])

  return (
    <DNABox
      appearance="row"
      fill
      style={{ maxHeight:32, maxWidth: '100%' }}
    >
      {tabEls}
    </DNABox>
  )
}

export default ActiveDocumentTabs
