import React from 'react';
import { EmailTemplateStatus } from '@alucio/aws-beacon-amplify/src/models';
import {
  useDNAEmailTemplateActions,
  EmailTemplateActions,
  emailTemplateContextActions,
} from './DNAEmailTemplates.actions';
import { DNAContextMenu } from '@alucio/lux-ui';
import { EmailTemplateORM } from 'src/types/types';

export interface DNAEmailTemplateContextMenuProps {
  emailTemplateORM: EmailTemplateORM,
  actions?: EmailTemplateActions[],
  children: React.ReactElement,
}

export const DNAEmailTemplatesContextMenu: React.FC<DNAEmailTemplateContextMenuProps> = (props) => {
  const {
    emailTemplateORM,
    actions,
    children,
  } = props;
  const DNAEmailTemplateActions = useDNAEmailTemplateActions();

  const actionsToUse: EmailTemplateActions[] = ['edit', 'preview'];

  if (emailTemplateORM.model.status === EmailTemplateStatus.ACTIVE) {
    actionsToUse.push('deactivate');
  }

  actionsToUse.push('delete')

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        { children }
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        {
          (actions ?? actionsToUse).map(action => {
            const { onPress, ...rest } = emailTemplateContextActions[action];
            return (
              <DNAContextMenu.Item
                key={action}
                onPress={DNAEmailTemplateActions[action](emailTemplateORM)}
                {...rest}
              />
            )
          })
        }
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default DNAEmailTemplatesContextMenu;
