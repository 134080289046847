import React from 'react';
import { DNACard, DNAText, Iffy } from '@alucio/lux-ui';
import { FlatList, Pressable, StyleSheet } from 'react-native';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import DocumentOpenedPresentationRow from './DocumentOpenedPresentationRow';
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';

const styles = StyleSheet.create({
  panel: {
    backgroundColor: colors['color-flat-1200'],
    padding: 24,
  },
})

export interface OpenedPresentationProps {
  inPersonControls?: boolean,
}

const OpenedPresentations: React.FC<OpenedPresentationProps> = (props) => {
  const { presentations, removePresentation, activePresentation, changeActivePresentation } = useContent();

  const renderItem = ({ item }) => {
    const Row = (<DocumentOpenedPresentationRow
      removePresentation={removePresentation}
      inPersonControls={props.inPersonControls}
      activePresentation={activePresentation}
      changeActivePresentation={changeActivePresentation}
      item={item}
    />)
    return !props.inPersonControls ? Row
      : <Pressable onPress={() => changeActivePresentation(item.presentable.id)} >
        {Row}
      </Pressable>
  };

  return (
    <DNACard
      style={!props.inPersonControls ? styles.panel : {
        backgroundColor: colors['color-flat-1200'],
      }}
    >
      <Iffy is={!props.inPersonControls}>
        <DNAText h5 style={{ color: colors['color-text-basic'], marginBottom: 20 }}>
          You can open multiple presentations and quickly switch between them.
        </DNAText>
        <Iffy is={!presentations.length}>
          <DNAEmpty
            emptyVariant={EmptyVariant.MeetingsOpenedPresentationsEmpty}
            variant="default"
          />
        </Iffy>
      </Iffy>
      <Iffy is={presentations.length}>
        <FlatList
          keyExtractor={(item) => item.presentable.id}
          data={presentations}
          initialNumToRender={10}
          renderItem={renderItem}
          contentContainerStyle={{ borderRadius: 8 }}
        />

      </Iffy>
    </DNACard >
  )
}

OpenedPresentations.displayName = 'OpenedPresentations';
export default OpenedPresentations;
