import React, { useEffect, useState } from 'react';
import { DNABox, DNAButton, DNASpinner, DNAText } from '@alucio/lux-ui';
import { Controller } from 'react-hook-form'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { ScrollView, StyleSheet } from 'react-native';
import InputComponent from 'src/components/Publishers/InputComponent';
import { Meeting } from '@alucio/aws-beacon-amplify/src/models';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';

const styles = StyleSheet.create({
  inputStyle: {
    backgroundColor: colors['color-flat-400'],
  },
  contentContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 26,
    paddingBottom: 12,
  },
})

const MeetingDetails = () => {
  const { meetingORM, editMeeting, meetingForm } = useMeetingsState()
  const onSubmit = (data: Meeting) => {
    if (meetingORM) {
      const payload = {
        ...meetingORM?.model,
        title: data.title,
        notes: data.notes,
      };
      editMeeting(payload)
      reset({ ...payload }, { isDirty: false })
    }
  }

  const {
    control, handleSubmit, reset,
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitting },
  } = meetingForm

  const [isSaved, setIsSaved] = useState(isSubmitSuccessful)

  useEffect(() => {
    isSubmitSuccessful && setIsSaved(true)
    setTimeout(() => {
      setIsSaved(false);
    }, 3000)
  }, [isSubmitSuccessful])

  const isButtonDisabled = !isDirty && !isSaved;

  return (
    <DNABox fill appearance="col" style={styles.contentContainer}>
      <ScrollView>
        <DNABox fill appearance="col" spacing="medium" >
          <DNABox spacing="medium" appearance="col">
            <DNABox appearance="col">
              <Controller
                name="title"
                title="Meeting Title"
                control={control}
                as={InputComponent}
                required
                hideLabel={false}
                multiline={true}
                numOfLines={1}
                characterLimit={250}
                disabled={false}
                showCharacterCounter
                inputStyle={[styles.inputStyle]}
                removeMarginPadding={true}
                titleColor={colors['color-text-basic']}
              />
              {
                errors.title &&
                  <DNAText status="danger">
                    {errors.title.message}
                  </DNAText>
              }
            </DNABox>
            <DNABox appearance="col" spacing="medium">
              <Controller
                name="notes"
                title="Meeting Notes"
                placeHolder="Notes..."
                control={control}
                as={InputComponent}
                hideLabel={false}
                required={false}
                multiline={true}
                characterLimit={1000}
                disabled={false}
                showCharacterCounter
                removeMarginPadding={true}
                numOfLines={20}
                inputStyle={[styles.inputStyle]}
                titleColor={colors['color-text-basic']}
              />
            </DNABox>
            <DNABox />
          </DNABox>
        </DNABox>
      </ScrollView>
      <DNABox fill appearance="col" alignY="end" alignX="end">
        <DNABox spacing="small">
          {/* TODO: WORKAROUND TO BE ABLE TO SHOW THE OPACITY IN A BLACK BACKGROUND */}
          <DNABox
            fill
            style={isButtonDisabled ? { backgroundColor: colors['color-text-basic'], borderRadius: 30 }
              : null}
          >
            <DNAButton
              style={{ maxHeight: 43 }}
              appearance="filled"
              status={isSaved ? 'success' : undefined}
              iconLeft={isSaved ? 'check' : undefined}
              disabled={isButtonDisabled}
              onPress={handleSubmit(onSubmit)}
            >
              {
                isSaved ? 'Saved' : isSubmitting ? <DNASpinner size="tiny" status="info" /> : 'Save'
              }
            </DNAButton>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>

  )
}
MeetingDetails.displayName = 'MeetingDetails';
export default MeetingDetails;
