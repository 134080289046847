import React, { useEffect, useState } from 'react';
import { Pressable, ScrollView, StyleSheet, ViewStyle } from 'react-native';
import { DNABox, DNAButton, DNAHeader, DNAText, Iffy, util } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import DocumentsUpdates from './DocumentsUpdates';
import { useDNAMyFoldersSharedResources } from './DNAMyFolders';
import { FolderItemORM, FolderORM } from 'src/types/orms';
import { useHistory, useLocation } from 'src/router';
import { filterCollection } from 'src/state/redux/selector/common';
import { filters, merge, sorts } from 'src/state/redux/folder/query';
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import { useDNAFolderNav } from 'src/components/DNA/Folder/Nav/DNAFolderNav';
import DNADefaultHeader from 'src/components/DNA/FlatList/Headers/DNADefaultHeaders';
import DNAGrid from 'src/components/DNA/Grid/DNAGrid';
import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList';
import useDisplayMode, { DisplayModes } from 'src/components/DNA/hooks/useDisplayMode';
import { ROUTES } from 'src/router/routes';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useAllPersonalDocuments } from 'src/state/redux/selector/document';

const S = StyleSheet.create({
  chevronIcon: {
    color: colors['color-flat-800'],
    marginTop: 2,
  },
  header: {
    borderBottomColor: colors['color-flat-300'],
    borderBottomWidth: 1,
    padding: 0,
    shadowOpacity: 0,
  },
  mainWrapper: {
    backgroundColor: colors['color-text-basic'],
    paddingBottom: 12,
  },
  myUpdatesContentTablet: {
    minHeight: 'auto',
  },
  selectedTab: {
    borderBottomWidth: 3,
    borderBottomColor: colors['color-primary-500'],
  },
  tabButton: {
    paddingHorizontal: 12,
  },
});

const ArchivedFoldersTablet: React.FC<{ onPress: (item: FolderItemORM | FolderORM) => void }> = props => {
  const { onPress } = props;
  const { displayMode } = useDisplayMode();

  const folderNav = useDNAFolderNav();

  /* using correct item collection depending on level */
  const archivedItems = folderNav.isCurrentlyNested
    ? folderNav.currentItems
    : filterCollection(folderNav.currentFolders, merge(filters.archived, filters.rootFolder, sorts.pinnedAsc))

  const emptyVariantType = folderNav.isCurrentlyNested ? EmptyVariant.FolderGridEmpty : EmptyVariant.FolderListCreate

  return (
    <DNABox appearance="col" spacing="medium">
      <Iffy is={displayMode === DisplayModes.grid && archivedItems.length !== 0}>
        <DNAGrid
          items={archivedItems}
          onPress={onPress}
          emptyVariant={emptyVariantType}
          style={{ marginHorizontal: 24 }}
        />
      </Iffy>
      <Iffy is={displayMode === DisplayModes.list && archivedItems.length !== 0}>
        <DNAFlatList<FolderORM>
          items={archivedItems}
          onPress={onPress}
          emptyVariant={emptyVariantType}
          disableHeader={true}
        >
          <DNADefaultHeader />
        </DNAFlatList>
      </Iffy>
    </DNABox>
  )
};

const TABS_OPTIONS = [{
  title: 'My Folders',
  path: '/folders',
}, {
  title: 'Shared With Me',
  path: '/shared_folders',
}, {
  title: 'My Uploads',
  path: '/folders/uploads',
}, {
  title: 'My Updates',
  path: '/folders/updates',
}];

const folderRegex = /^\/folders\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b.*/gm;

// TODO: refactor to show personal documents
const DNAMyFoldersTablet = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const lowerCasedPathName = pathname.toLowerCase();
  const sharedFoldersTab = lowerCasedPathName.includes('shared_folders')
  const isInsideFolder = !!lowerCasedPathName.match(folderRegex);
  const [archiveMode, setArchiveMode] = useState<boolean>();
  const isMyUpdatesScreen = lowerCasedPathName === '/folders/updates';
  const isMyUploadsScreen = lowerCasedPathName === '/folders/uploads';
  const isFoldersList = !archiveMode && !isMyUpdatesScreen && !isMyUploadsScreen;
  const LDClient = useLDClient();
  const enableSharedFolders = LDClient?.variation('enable-shared-folders', false);
  const enableMyUploads = LDClient?.variation('enable-viewer-file-upload', false);

  useEffect(() => {
    /*
     Redirect to 404 if my uploads is not enabled
     Ideally this would be placed in routes.tsx, however with the way the components were structured this is the best way
     to do it without major rewrites
    */
    if (isMyUploadsScreen && !enableMyUploads) {
      history.replace(ROUTES.NOT_FOUND.PATH);
    }
  }, []);

  const {
    folderNav,
    folderNavURL,
    createOnPress,
    displayModeIconName,
    toggleDisplayMode,
    displayMode,
    activeItems,
    onPress,
    returnToRoot,
    getBreadCrumb,
    emptyVariantType,
    launchSlideBuilder,
  } = useDNAMyFoldersSharedResources();

  const personalDocuments = useAllPersonalDocuments();

  const rootIsShared = folderNav.folderStack.length &&
    folderNav.folderStack[0].model?.sharePermissions?.some((item) => !item.isDeleted);

  const toggleArchiveMode = () => {
    setArchiveMode(p => !p);

    if (sharedFoldersTab) {
      history.push(ROUTES.SHARED_FOLDERS.PATH);
    } else {
      returnToRoot();
    }
  };

  useEffect(() => {
    folderNav.setDisplaySharedFolders(sharedFoldersTab);
  }, [sharedFoldersTab]);

  // We need to correctly target the child index to flex
  // since they're Iffy, their idx are grid = 1 and list = 2
  const gridOrListChildFill = !archiveMode && displayMode === DisplayModes.grid ? 1 : 2;

  return (
    <DNABox appearance="col" style={S.mainWrapper} fill>
      <DNAHeader status="flat" size="medium" style={S.header}>
        <DNAHeader.Left>
          <DNABox>
            <Iffy is={folderNav.folderStack.length === 0 && !archiveMode}>
              {
                TABS_OPTIONS.map((option) => {
                  if (option.title === 'Shared With Me' && !enableSharedFolders) {
                    return null;
                  }
                  if (option.title === 'My Uploads' && !enableMyUploads) {
                    return null;
                  }
                  return (
                    <DNABox
                      key={option.title}
                      alignY="center"
                      style={util.mergeStyles(undefined, { height: 56 },
                        [S.selectedTab, lowerCasedPathName === option.path])}
                    >
                      <Pressable
                        testID={'DNATab'}
                        style={S.tabButton}
                        onPress={() => history.push(option.path)}
                      >
                        <DNAText h4 status={lowerCasedPathName === option.path ? 'primary' : 'flat'}>
                          {option.title}
                        </DNAText>
                      </Pressable>
                    </DNABox>
                  )
                })
              }
            </Iffy>

            {/* Beyond one level down in folder tree */}
            <Iffy is={folderNav.folderStack.length > 0}>
              <DNABox fill alignY="center">
                <Iffy is={folderNav.folderStack.length === 1}>
                  {/* Root Link */}
                  <DNAButton
                    appearance="ghost"
                    status="dark"
                    context="tablet"
                    iconLeft="chevron-left"
                    iconStyle={S.chevronIcon}
                    onPress={() => folderNavURL.popToFolder()}
                  />
                </Iffy>

                <Iffy is={folderNav.folderStack.length > 1}>
                  {getBreadCrumb(folderNav.folderStack.length - 2, true, true)}
                </Iffy>
              </DNABox>
            </Iffy>
          </DNABox>
        </DNAHeader.Left>
        <DNAHeader.Center>
          {/* One level down from root in folder tree */}
          <Iffy is={folderNav.folderStack.length === 0 && archiveMode}>
            <DNABox fill alignX="center">
              <DNAText testID="page-title" status="secondary" h5>
                Archived Folders
              </DNAText>
            </DNABox>
          </Iffy>

          {/* Beyond one level down in folder tree */}
          <Iffy is={folderNav.folderStack.length > 0}>
            <DNABox fill alignY="center" spacing="between">
              <Iffy is={folderNav.folderStack.length === 1}>
                {/* Current Folder Link */}
                {getBreadCrumb(folderNav.folderStack.length - 1, true)}
              </Iffy>

              <Iffy is={folderNav.folderStack.length > 1}>{getBreadCrumb(folderNav.folderStack.length - 1, true)}</Iffy>
            </DNABox>
          </Iffy>
        </DNAHeader.Center>
        <DNAHeader.Right>
          {/* Right Controls */}
          <DNABox>
            <Iffy is={!sharedFoldersTab && !rootIsShared && isInsideFolder}>
              <DNAButton
                size="large"
                appearance="ghost"
                status="dark"
                context="tablet"
                iconLeft="card-plus-outline"
                disabled={!folderNav.isCurrentlyNested}
                onPress={launchSlideBuilder}
              />
            </Iffy>
            <Iffy is={!folderNav.isMaxDepth && !archiveMode && !sharedFoldersTab}>
              <DNAButton
                size="large"
                appearance="ghost"
                status="dark"
                context="tablet"
                iconLeft="folder-plus"
                onPress={createOnPress}
              />
            </Iffy>
            <DNAButton
              size="large"
              appearance="ghost"
              status={archiveMode ? 'primary' : 'dark'}
              context="tablet"
              iconLeft="inbox-arrow-down"
              onPress={toggleArchiveMode}
            />
            <DNAButton
              size="large"
              appearance="ghost"
              status="dark"
              context="tablet"
              iconLeft={displayModeIconName}
              onPress={toggleDisplayMode}
            />
          </DNABox>
        </DNAHeader.Right>
      </DNAHeader>
      <ScrollView>
        <DNABox fill alignX="center">
          <DNABox
            fill
            spacing="medium"
            appearance="col"
            childFill={gridOrListChildFill}
          >
            {/* Content */}
            <Iffy is={isFoldersList && displayMode === DisplayModes.grid}>
              <DNAGrid
                items={activeItems}
                onPress={onPress}
                emptyVariant={emptyVariantType}
                // [TODO]: Switching between list and grid does not center empty properly
                style={{ paddingHorizontal: 24, paddingVertical: 12, overflowY: 'auto' } as ViewStyle}
              />
            </Iffy>

            <Iffy is={isFoldersList && displayMode === DisplayModes.list}>
              <DNAFlatList items={activeItems} onPress={onPress} emptyVariant={emptyVariantType} disableHeader={true}>
                <DNADefaultHeader />
              </DNAFlatList>
            </Iffy>

            {/* Archived */}
            <Iffy is={archiveMode}>
              <ArchivedFoldersTablet onPress={onPress} />
            </Iffy>

            {/* Updates */}
            <Iffy is={isMyUpdatesScreen}>
              <DNABox fill alignX="center" style={S.myUpdatesContentTablet}>
                <DNABox fill spacing="medium" appearance="col" style={{ paddingHorizontal: 16 }}>
                  <DocumentsUpdates isTablet={true} />
                </DNABox>
              </DNABox>
            </Iffy>

            {/* Uploads */}
            <Iffy is={isMyUploadsScreen}>
              <DNAFlatList
                items={personalDocuments}
                onPress={onPress}
                emptyVariant={emptyVariantType}
                disableHeader={true}
              >
                <DNADefaultHeader />
              </DNAFlatList>
            </Iffy>
          </DNABox>
        </DNABox>
      </ScrollView>
    </DNABox>
  )
};

export default DNAMyFoldersTablet;
