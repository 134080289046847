/* eslint-disable max-len */
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgHome(props: SvgProps) {
  return (
    <Svg
      viewBox="0 0 32 32"
      fill="none"
      // @ts-ignore
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12.25a1 1 0 011 1V28h5v-7.5a2.5 2.5 0 012.5-2.5h5a2.5 2.5 0 012.5 2.5V28h5V13.25a1 1 0 112 0V28a2 2 0 01-2 2h-6a1 1 0 01-1-1v-8.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5V29a1 1 0 01-1 1H6a2 2 0 01-2-2V13.25a1 1 0 011-1z"
        fill={props.color ?? '#fff'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.386 2.54l13.306 12.738a1 1 0 11-1.384 1.444L16.02 4.002a.228.228 0 00-.04 0L2.691 16.722a1 1 0 11-1.382-1.445L14.614 2.54c.408-.414.964-.54 1.389-.539.423 0 .977.128 1.382.54z"
        fill={props.color ?? '#fff'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 4a1 1 0 011-1h3a1 1 0 011 1v7.188a1 1 0 11-2 0V5h-1v3.313a1 1 0 11-2 0V4z"
        fill={props.color ?? '#fff'}
      />
    </Svg>
  );
}

export default SvgHome;
