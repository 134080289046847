import {
  AssociatedFile,
  AttachedFile,
  Document,
  DocumentVersion,
  Tenant,
  Folder,
  FolderItem,
  LabelValue,
  FieldConfig,
  CustomDeck,
  CustomDeckGroup,
  CustomDeckPage,
  EmailTemplate,
  Page,
  PageGroup,
  DocumentStatus,
  IntegrationType,
  DocumentVersionIntegration,
  DocumentIntegration,
  Meeting,
} from '@alucio/aws-beacon-amplify/src/models'
import { AlucioAuthHeaders } from '@alucio/core'
import { UserORM } from 'src/state/redux/selector/user'
import { VERSION_UPDATE_STATUS, VirtualSessionWithAttendees } from './types'

// [TODO-297]: We should make extendable interfaces for easier type composition
export enum ORMTypes {
  ASSOCIATED_FILE = 'ASSOCIATED_FILE',
  DOCUMENT = 'DOCUMENT',
  DOCUMENT_VERSION = 'DOCUMENT_VERSION',
  FOLDER = 'FOLDER',
  FOLDER_ITEM = 'FOLDER_ITEM',
  VIRTUAL_SESSION = 'VIRTUAL_SESSION',
  CUSTOM_DECK = 'CUSTOM_DECK',
  EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
  PAGE = 'PAGE',
  PAGE_GROUP = 'PAGE_GROUP',
  MEETING = 'MEETING',
}

export const getAssociatedFileTitle = (af: AssociatedFileORM) => {
  return af.model.type === 'ATTACHED_FILE'
    ? (af.file as AttachedFile)?.title
    : af.relations.latestUsableDocumentVersion?.title
}

export interface AssociatedFileORM {
  model: AssociatedFile,
  type: ORMTypes.ASSOCIATED_FILE,
  meta: {
    canBeSharedByMSL: boolean
  },
  relations: {
    latestUsableDocumentVersion?: DocumentVersion
  },
  // It's possible that a DocVer may be updated BEFORE the AttachedFile record comes in
  // We cannot guarantee the order of subscribtions received so it's safer to assume
  // that the file itself may be undefined
  // [TODO] - Consider adding types to the file object for easier TS inference
  file?: Document | AttachedFile,
}

export interface CustomDeckORM {
  model: CustomDeck,
  type: ORMTypes.CUSTOM_DECK,
  meta: {
    assets: {
      thumbnailKey?: string,
      isContentCached: boolean,
    },
    title?: string,
    version: {
      updateStatus: VERSION_UPDATE_STATUS,
      requiresReview: boolean,
      autoUpdateUnacknowledged: boolean,
    }
    groupsORM: CustomDeckGroupORM[],
  },
}

export interface CustomDeckGroupORM {
  isGroup: boolean,
  model: CustomDeckGroup,
  pages: {
    model: CustomDeckPage,
    documentVersionORM: DocumentVersionORM,
    page: Page,
  }[],
  meta: {
    version: {
      updateStatus: VERSION_UPDATE_STATUS,
    }
  }
}

export interface DocumentORM {
  model: Document,
  type: ORMTypes.DOCUMENT,
  relations: {
    documentVersions: DocumentVersionORM[],
    tenant: Tenant, // NOTE: No ORM'd version yet
    version: {
      latestUsableDocumentVersion: DocumentVersionORM,
      latestDocumentVersion: DocumentVersionORM,
      latestDocumentVersionPublished: DocumentVersionORM | undefined,
      cachedDocumentVersion: DocumentVersionORM | undefined,
    }
  },
  meta: {
    assets: {
      thumbnailKey?: string,
      fileURL?: string,
      getAuthHeaders: () => Promise<AlucioAuthHeaders>
    },
    bookmark: {
      isBookmarked: boolean
      createdAt?: string,
    }
    tenantFields: {
      labelCompletion: boolean,
      valuesMap: {
        [key: string]: LabelValue['value'][]
      }
      configsMap: {
        [key: string]: FieldConfig
      }
    },
    integration: {
      integrationType?: IntegrationType | keyof typeof IntegrationType,
      integration?: DocumentIntegration,
      source?: string,
    },
    permissions: {
      bookmark: boolean,
      addToFolder: boolean,
      MSLPresent: boolean,
      MSLDownload: boolean,
      MSLSelectSlides: boolean,
    },
    hasUnpublishedVersion: boolean,
    sealedStatus?: (
      | DocumentStatus.ARCHIVED
      | DocumentStatus.REVOKED
      | DocumentStatus.DELETED
      | 'ARCHIVED'
      | 'REVOKED'
      | 'DELETED'
    )
  }
}

export interface DocumentVersionORM {
  model: DocumentVersion,
  type: ORMTypes.DOCUMENT_VERSION,
  relations: {
    document: DocumentORM,
    associatedFiles: AssociatedFileORM[],
    pages: PageORM[],
    pageGroups: PageGroupORM[],
  },
  meta: {
    assets: {
      thumbnailKey?: string
      fileURL?: string,
      getAuthHeaders: () => Promise<AlucioAuthHeaders>,
      contentKey?: string,
      isContentCached: boolean,
      isThumbnailCached: boolean,
    },
    version: {
      semVerLabel: string,
      isLatestPublished: boolean,
      updateStatus: VERSION_UPDATE_STATUS
    },
    integration: {
      integrationType?: IntegrationType | keyof typeof IntegrationType,
      integration?: DocumentVersionIntegration,
      source?: string,
    },
    permissions: {
      MSLShare: boolean,
    },
    tenantFields: {
      labelCompletion: boolean,
      valuesMap: {
        [key: string]: LabelValue['value'][]
      }
      configsMap: {
        [key: string]: FieldConfig
      }
    },
    sealedStatus?: DocumentORM['meta']['sealedStatus']
  }
}
export interface EmailTemplateORM {
  model: EmailTemplate,
  relations: {
    associatedFiles: AssociatedFileORM[],
  },
  type: ORMTypes.EMAIL_TEMPLATE,
}

export interface FolderORM {
  model: Folder,
  type: ORMTypes.FOLDER,
  relations: {
    parentFolder: FolderORM | null,
    items: FolderItemORM[]
    owner?: UserORM
  },
  meta: {
    isSharedWithTheUser?: boolean,
    isSharedFolder?: boolean,
    itemCount: number,
    folderCount: number,
    version: {
      containsAutoUpdatedItem: boolean
      containsOutdatedDocVer: boolean
      containsPendingReviewItem: boolean
    }
  }
}
export interface FolderItemORM {
  model: FolderItem
  type: ORMTypes.FOLDER_ITEM,
  meta: {
    assets: {
      // [TODO-2126] - This is currently not being used anywhere, but might be good in the future
      //               to have access to it at this level
      thumbnailKey?: string,
    },
    hasAutoUpdatedItem: boolean
    hasOutdatedItem: boolean
    isModified?: boolean
  }
  relations: {
    item: DocumentVersionORM | FolderORM | CustomDeckORM
    parent?: FolderORM
  }
}

export interface PageORM {
  model: Page,
  type: ORMTypes.PAGE,
  relations: {
    documentVersion: DocumentVersionORM,
    pageGroup?: PageGroupORM,
  }
}

export interface PageGroupORM {
  model: PageGroup,
  type: ORMTypes.PAGE_GROUP,
  meta: {
    isRequired: boolean,
  }
  relations: {
    pages: PageORM[],
    documentVersion: DocumentVersionORM,
  }
}

// TODO - 716 should the type be ORMTypes.VIRTUAL_SESSION
export interface VirtualSessionORM {
  model: VirtualSessionWithAttendees,
  type: ORMTypes,
  meta: {
    sessionDate: string,
    sessionDuration: string,
    sessionTime: string,
  }
}
export interface MeetingORM {
  model: Meeting,
  type: ORMTypes.MEETING,
}
