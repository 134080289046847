import { useCallback, useEffect, useRef, useState } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { API, graphqlOperation } from '@aws-amplify/api'
import {
  PPZTransform,
  PresentationContentState,
} from '@alucio/core';
import { Logger } from '@aws-amplify/core';
import { FileType } from '@alucio/aws-beacon-amplify/src/models';
import { generateTokenForContentAccess } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import { GenerateTokenForContentAccessQuery } from '@alucio/aws-beacon-amplify/src/API';
import AWSConfig from '@alucio/aws-beacon-amplify/src/aws-exports';
import { PresentationBroadcastContent } from '@alucio/core/lib/state/context/PresentationPlayer/types';
import { LoadedPresentation } from './ContentProvider';
import { DocumentVersionORM, PresentablePage } from 'src/types/types';
import { useOnlineStatus } from '../AppSettings';
import { getDocPath } from 'src/components/ContentPreviewModal/ContentPreview/IFrame/IFrame.web';
import { PW } from 'src/state/machines/presentation/playerWrapper';
import { AlucioChannel, debounce, useSafePromise } from '@alucio/lux-ui';
import {
  PresentationChannelMessage,
  PresentationStateIdleEvent,
  PresentationStateSyncEvent,
} from 'src/state/machines/presentation/playerWrapperTypes';
import equal from 'fast-deep-equal';
import useInterval from '@alucio/core/lib/hooks/useInterval/useInterval';
const logger = new Logger('useContentInfoBroadcastChannel', 'DEBUG');

interface Props {
  activePresentation?: LoadedPresentation,
  setActiveSlideByPresentationPageNumber: (presentationPageNumber: number, step?: number) => void,
  setCurrentPPZCoords: (coords?: PPZTransform) => void,
  meetingId?: string,
}

const useContentInfoBroadcastChannel = (props: Props) => {
  const { activePresentation, setActiveSlideByPresentationPageNumber, setCurrentPPZCoords } = props;
  const isOnline = useOnlineStatus();
  const safePromise = useSafePromise()
  // TODO when we integrate we'll want to pull the meeting ID from the hook
  const meetingId = props.meetingId ?? 'aaaaaa'
  const [contentInfo, setContentInfo] = useState<PresentationBroadcastContent>();
  const currentActivePresentationRef = useRef<LoadedPresentation | undefined>(activePresentation);
  const channel: BroadcastChannel<PW.PresentationChannelMessage> = useRef(
    AlucioChannel.get(AlucioChannel.commonChannels.PRESENTATION_CHANNEL)).current

  useEffect(handleNewActivePage, [activePresentation,
    activePresentation?.currentPresentablePage,
    activePresentation?.currentStep,
    activePresentation?.currentPPZCoords?.positionX,
    activePresentation?.currentPPZCoords?.positionY,
    activePresentation?.currentPPZCoords?.scale]);

  // ** CHANNEL LISTENER/SENDER ** //
  // SENDS A MESSAGE WITH THE NEW UPDATED CONTENT STATE
  const broadcastStateSync = useCallback(debounce((msg: PresentationChannelMessage) => {
    async function postMsg() {
      logger.debug('Sending to Presentation Channel', { channel, msg })
      channel.postMessage(msg)
    }
    safePromise.makeSafe(postMsg())
      .catch(err => !err.isCanceled
        ? console.error(err)
        : undefined,
      )
  }, 300), []);
  useEffect(() => {
    broadcastStateSync(getBroadcastMessage(contentInfo));
  }, [contentInfo]);

  function getBroadcastMessage(contentInfo?: PresentationBroadcastContent):
    PresentationStateSyncEvent | PresentationStateIdleEvent {
    const msg = contentInfo ? {
      type: 'PRESENTATION_STATE_SYNC',
      meetingId,
      payload: contentInfo,
    } as PresentationStateSyncEvent
      : {
        type: 'PRESENTATION_STATE_IDLE',
        meetingId,

      } as PresentationStateIdleEvent
    return msg;
  }

  // WAITS FOR MESSAGES TO UPDATE THE STATE ACCORDINGLY
  useEffect(() => {
    channel.onmessage = (msg: PW.PresentationChannelMessage) => {
      if (msg.meetingId === meetingId && msg.type !== 'PRESENTATION_STATE_SYNC') {
        switch (msg.type) {
          case 'NAVIGATE_PAST_FIRST':
            logger.debug('Got NAVIGATE_PAST_FIRST')
            navigatePastFirst()
            break
          case 'NAVIGATE_PAST_LAST':
            logger.debug('Got NAVIGATE_PAST_LAST')
            navigatePastLast();
            break
          case 'PRESENTATION_PROGRESS': {
            logger.debug('Got PRESENTATION_PROGRESS')
            const { page, groupId, step } = msg.payload as PresentationContentState;
            if (page && groupId) {
              pageChange(page, groupId, step);
            }
            break;
          }
          case 'PPZ_TRANSFORM':
            logger.debug('Got PPZ_TRANSFORM')
            setCurrentPPZCoords(msg.payload);
            break
          default:
            logger.warn(`Received unhandled message from wrapper: ${msg.type}`, msg)
        }
      }
    };
  }, []);

  // HEARTBEAT
  useInterval(() =>
    broadcastStateSync(getBroadcastMessage(contentInfo))
  , 2000);

  // ** HANDLERS ** //
  function navigatePastLast(): void {
    navigatePast(true);
  }

  function navigatePastFirst(): void {
    navigatePast();
  }

  // ACCORDING TO OUR LAST PAGE (currentPresentablePageRef) AND THE NEW ACTIVE ONE,
  // IT DETERMINES THE NEW STATE FOR THE PRESENTATION TO HAVE
  function handleNewActivePage(): void {
    logger.debug('entered handleNewActivePage')
    if (!activePresentation) {
      currentActivePresentationRef.current = undefined;
      setContentInfo(undefined)
      return;
    }

    const isNewDocument = activePresentation.currentPresentablePage.documentVersion.model.id !==
      currentActivePresentationRef.current?.currentPresentablePage.documentVersion.model.id;

    if (isNewDocument) {
      // UPDATES THE CONTENT INFO STATE TO REFLECT THE NEW DOCUMENT INFO
      handleChangeOfDocument(activePresentation);
      currentActivePresentationRef.current = { ...activePresentation };
      return;
    }

    if (!contentInfo) {
      return;
    }

    const isNewGroup = activePresentation.currentPresentablePage.presentableGroup.id !==
      currentActivePresentationRef.current?.currentPresentablePage.presentableGroup.id;

    if (isNewGroup) {
      const group = activePresentation.currentPresentablePage.presentableGroup;

      // REMAINS THE SAME DOCUMENT INFO BUT UPDATES THE GROUP/PAGE/STEP
      setContentInfo({
        ...contentInfo,
        groupId: group.id,
        state: {
          page: activePresentation.currentPresentablePage.page.number,
          step: activePresentation.currentStep || 0,
        },
        ppzCoords: undefined,
        visiblePages: group.pages.reduce<number[]>((acc, page) => {
          acc.push(page.page.number);
          return acc;
        }, []),
      });
      currentActivePresentationRef.current = { ...activePresentation };
      return;
    }

    const isNewPage = activePresentation.currentPresentablePage.id !==
      currentActivePresentationRef.current?.currentPresentablePage.id;

    if (isNewPage) {
      // REMAINS THE SAME DOCUMENT INFO BUT UPDATES THE PAGE/STEP
      setContentInfo({
        ...contentInfo,
        state: {
          page: activePresentation.currentPresentablePage.page.number,
          step: activePresentation.currentStep || 0,
        },
        ppzCoords: undefined,
      });
      currentActivePresentationRef.current = { ...activePresentation };
      return;
    }

    const isNewStep = activePresentation.currentStep !==
      currentActivePresentationRef.current?.currentStep;

    if (isNewStep) {
      // REMAINS THE SAME DOCUMENT INFO BUT UPDATES THE STEP
      setContentInfo({
        ...contentInfo,
        state: {
          page: contentInfo.state.page,
          step: activePresentation.currentStep || 0,
        },
        ppzCoords: undefined,
      });
      currentActivePresentationRef.current = { ...activePresentation };
      return;
    }

    const isNewPPZCords = !equal(
      activePresentation.currentPPZCoords, currentActivePresentationRef.current?.currentPPZCoords);

    if (isNewPPZCords) {
      // REMAINS THE SAME DOCUMENT INFO BUT UPDATES THE COORDS
      setContentInfo({
        ...contentInfo,
        ppzCoords: activePresentation.currentPPZCoords,
      });
      currentActivePresentationRef.current = { ...activePresentation };
    }
  }

  async function handleChangeOfDocument(activePresentation: LoadedPresentation): Promise<void> {
    const documentVersionORM = activePresentation.currentPresentablePage.documentVersion;
    const docPath = getDocPath(documentVersionORM, isOnline);
    const bucket = AWSConfig.aws_user_files_s3_bucket;
    const group = activePresentation.currentPresentablePage.presentableGroup;
    const updatedInfo = {
      // The JWT is loaded via a promise to allow us to go ahead and init
      // the player while we're fetching the JWT
      JWT: isOnline ? 'PENDING' : '',
      bucket,
      docPath: `/content/${docPath}`,
      documentVersionId: documentVersionORM.model.id,
      documentId: documentVersionORM.relations.document.model.id,
      groupId: group.id,
      contentType: documentVersionORM.model.type === 'PDF' ? FileType.PDF : FileType.PPTX,
      state: {
        page: activePresentation.currentPresentablePage.page.number,
        step: activePresentation.currentStep || 0,
      },
      visiblePages: group.pages.reduce<number[]>((acc, page) => {
        acc.push(page.page.number);
        return acc;
      }, []),
    }

    if (isOnline) {
      getJWT(documentVersionORM, docPath).then((jwt) => {
        setContentInfo({
          ...updatedInfo,
          JWT: jwt,
        })
      })
    }
    setContentInfo(updatedInfo);
  }

  // DETERMINES THE NEXT PAGE/GROUP TO GO, DEPENDING ON THE CURRENT ONE
  function navigatePast(next?: boolean): void {
    let newPage: PresentablePage | undefined;

    for (const group of currentActivePresentationRef.current?.presentable.presentableGroups || []) {
      for (const page of group.pages) {
        if (currentActivePresentationRef.current?.currentPresentablePage.presentationPageNumber ===
          (page.presentationPageNumber + (next ? -1 : 1))) {
          newPage = page;
          break;
        }
      }
    }

    if (newPage) {
      setActiveSlideByPresentationPageNumber(newPage.presentationPageNumber, next ? 0 : -1);
    } else {
      console.warn(`New Page to NAVIGATE_PAST_${next ? 'FIRST' : 'LAST'} not found`);
    }
  }

  async function getJWT(documentVersionORM: DocumentVersionORM, docPath: string): Promise<string> {
    const { data } = await API.graphql(
      graphqlOperation(generateTokenForContentAccess, {
        documentId: documentVersionORM.relations.document.model.id,
        documentVersionId: documentVersionORM.model.id,
        authorizedPath: docPath,
        durationSeconds: 60 * 60,
      }),
    ) as { data: GenerateTokenForContentAccessQuery };
    return data.generateTokenForContentAccess?.token || '';
  }

  // NOTE: PAGENUMBER IS THE ACTUAL NUMBER OF THE PAGE WITHIN A DOCUMENT
  function pageChange(pageNumber: number, groupId: string, step?: number): void {
    let newPage: PresentablePage | undefined;

    for (const group of currentActivePresentationRef.current?.presentable.presentableGroups || []) {
      for (const page of group.pages) {
        if (page.page.number === pageNumber && group.id === groupId) {
          newPage = page;
          break;
        }
      }
    }

    if (newPage) {
      setActiveSlideByPresentationPageNumber(newPage.presentationPageNumber, step);
    } else {
      console.warn('New Page to CHANGE not found');
    }
  }
};

export default useContentInfoBroadcastChannel;
