import React from 'react';
import { Box, Text, Icon, Iffy, DatePicker, Popover, util } from '@alucio/lux-ui';
import { GestureResponderEvent } from 'react-native';
import { styles } from './Styles';

interface DateProps {
  title: string;
  required?: boolean;
  descriptionText?: string;
  selectedDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  includeTimePicker? : boolean;
  dateFormat? : string;
  removeMarginPadding?: boolean;
  onShowCalendar?: ((e:GestureResponderEvent) => void);
  onDateSelected?: ((date:Date) => void)
}

function DateComponent(props: DateProps) {
  const {
    title, selectedDate, onShowCalendar, required = true, descriptionText,
    onDateSelected, disabled, includeTimePicker, dateFormat, removeMarginPadding,
  } = props;

  return (
    <Box
      style={util.mergeStyles(
        undefined,
        styles.Container,
        [styles.NoMarginPadding, removeMarginPadding])
    }
    >
      <Box style={styles.TitleContainer}>
        <Box>
          <Text style={styles.required}>{required ? '*' : ''}</Text>
        </Box>
        <Box>
          <Text style={styles.Title}>{title}</Text>
        </Box>
        <Box>
          <Iffy is={descriptionText}>
            <Popover>
              <Popover.Anchor>
                <Icon style={styles.HelpToolTipIconStyle} name="help-circle-outline" />
              </Popover.Anchor>
              <Popover.Content>
                <Text style={{ color: 'white' }}>{descriptionText}</Text>
              </Popover.Content>
            </Popover>
          </Iffy>
        </Box>
      </Box>
      <DatePicker
        onDateSelected={onDateSelected}
        selectedDate={selectedDate}
        disabled={disabled}
        onShowCalendar={onShowCalendar}
        showMonthYearDropDown={true}
        dateFormat={dateFormat}
        includeTimePicker={includeTimePicker}
      />
    </Box>
  );
}

export default React.memo(DateComponent);
