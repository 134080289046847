import { DNARoute, ROUTES, PAGES } from 'src/router/routes'
import { useHistory } from 'src/router'
import { History } from 'history'

type RouteFn = (
  callback?: () => void,
  event?: string,
  properties?: Object,
  options?: SegmentAnalytics.SegmentOpts,
  analyticsCallback?: () => void
) => void

const goTo = (history: History) => {
  return Object
    .entries(ROUTES)
    .reduce<Record<PAGES, RouteFn>>(
      (acc, [key, route]) => {
        acc[key] = (callback, event, properties, options, analyticsCallback) => {
          history.push({
            pathname: route.PATH,
            search: route.SEARCH,
          })

          callback?.()
          if (event)
          { analytics?.track(event, properties, options, analyticsCallback) }
        }
        return acc
      },
      { } as Record<PAGES, RouteFn>,
    )
}

const go = (history: History) => (route: DNARoute, callback?: () => void) => {
  history.push({
    pathname: route.PATH,
    search: route.SEARCH,
  })
  callback?.()
}

const useScreenNav = () => {
  const history = useHistory()
  return {
    goTo: goTo(history),
    go: go(history),
    history: history,
  }
}

export default useScreenNav
