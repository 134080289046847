import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PlayerEvents, usePresentationPlayerContext } from '@alucio/video';
import {
  DocumentORM,
  DocumentVersionORM,
  PageExtended,
  FolderItemORM,
  CustomDeckORM,
} from 'src/types/types';
import { PPZTransform } from '@alucio/core'

import { getVisiblePages, haveHiddenSlides } from 'src/utils/documentHelpers';
import { useSelector } from 'react-redux';
import { RootState } from 'src/state/redux/store';
import { isCustomDeckORM, isDocumentVersionORM } from 'src/types/typeguards';

export enum SidebarOptions {
  metadataSidebar = 'metadataSidebar',
  releaseNotesSidebar = 'releaseNotesSidebar',
  notesSidebar = 'notesSidebar',
  associatedFilesSidebar = 'associatedFilesSidebar'
}

export enum SWIPE_TYPE {
  VERTICAL = 'VERTICAL',
}

export interface LoadedDocument {
  activeItem: FolderItemORM|DocumentVersionORM,
  activeSlide: number
  active?: boolean
}

 interface ContentViewerModalStateType {
  registerLoadedDocumentState: (
    selectedItem: FolderItemORM | DocumentVersionORM,
    activeSlide?: number | undefined
    ) => void
  loadedDocuments: LoadedDocument[],
  setLoadedDocuments: React.Dispatch<React.SetStateAction<LoadedDocument[]>>,
  getCurrentConsecutiveGroup: (slide: number) => CustomDeckConsecutiveGroup | undefined,
  startingSlide: number,
  customDeck?: CustomDeckORM,
  isModifiedDoc: boolean,
  isViewingAssociatedFile: boolean,
  folderItemORM: FolderItemORM,
  getSidebarOptions: () => SidebarOptions[]
  isFullWindow: boolean,
  setIsFullWindow: React.Dispatch<React.SetStateAction<boolean>>,
  visibleSidebar: SidebarOptions | undefined,
  setVisibleSidebar: React.Dispatch<React.SetStateAction<SidebarOptions | undefined>>,
  slideRollVisible: boolean,
  setSlideRollVisible: React.Dispatch<React.SetStateAction<boolean>>,
  controlsVisible: boolean,
  setControlsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  contentPanelVisible: boolean,
  setContentPanelVisible: React.Dispatch<React.SetStateAction<boolean>>,
  activeSlide: number,
  setActiveSlide: React.Dispatch<React.SetStateAction<number | undefined>>,
  totalSlides: number,
  activeDoc: DocumentORM,
  setActiveDoc: React.Dispatch<React.SetStateAction<DocumentORM | undefined>>,
  setFolderItemORM: React.Dispatch<React.SetStateAction<FolderItemORM | undefined>>,
  setActiveDocVersionAndSlide: (doc: DocumentVersionORM, slide: number) => void,
  setActiveFolderItemAndSlide: (folderItem: FolderItemORM, slide: number) => void,
  activeDocVersion: DocumentVersionORM,
  setActiveDocVersion: React.Dispatch<React.SetStateAction<DocumentVersionORM | undefined>>,
  setControlsTimeout(n?: number): void,
  adjustedSlideNumber: number,
  visiblePages: PageExtended[],
  currentPPZCoords?: PPZTransform
  setCurrentPPZCoords: React.Dispatch<React.SetStateAction<PPZTransform | undefined>>
  onSwipeAction?: (swipeType: SWIPE_TYPE) => void,
  activePageObject?: PageExtended,
  currentDocumentVersion?: DocumentVersionORM,
  documentTabLimit: number
  visibleSearch: boolean,
  setVisibleSearch: React.Dispatch<React.SetStateAction<boolean>>
  searchText: string,
  setSearchText: React.Dispatch<React.SetStateAction<string>>
}

const ContentViewerModalStateContext = createContext<ContentViewerModalStateType>(null!)
ContentViewerModalStateContext.displayName = 'ContentViewerModalContext'

interface InitialValues {
  documentVersionORM?: DocumentVersionORM;
  folderItemORM?: FolderItemORM;
  totalSlides?: number;
  isFullWindow?: boolean;
  context?: 'APP' | 'PUBLISHER' | 'VIRTUAL';
}

interface CustomDeckConsecutiveGroup {
  consecutiveGroupNumber: number;
  documentVersionId: string;
  groupIds: string[];
  visiblePageNumbers: number[];
}

const DEFAULT_TIMEOUT = 1000 * 5
const documentTabLimit = 10

const ContentViewerModalStateProvider: React.FC<InitialValues> = (props) => {
  const { context = 'APP' } = props
  const [loadedDocuments, setLoadedDocuments] = useState<LoadedDocument[]>([])
  const [activeDoc, setActiveDoc] = useState<DocumentORM | undefined>(props.documentVersionORM?.relations.document)
  const [folderItemORM, setFolderItemORM] = useState<FolderItemORM | undefined>(props.folderItemORM)
  const [isFullWindow, setIsFullWindow] = useState<boolean>(props.isFullWindow || false)
  const [visibleSidebar, setVisibleSidebar] = useState<SidebarOptions | undefined>(undefined)
  const [slideRollVisible, setSlideRollVisible] = useState<boolean>(false)
  const [controlsVisible, setControlsVisible] = useState<boolean>(true)
  const [contentPanelVisible, setContentPanelVisible] = useState<boolean>(false)
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  // [TODO-1354] - Instead of storing the ORM object in state, store only the id instead
  //  Storing an ORM in object in state will most likely become stale
  //  Storing the id and using a selector at the context/provider level is the way to go
  const [activeDocVersion, setActiveDocVersion] = useState<DocumentVersionORM | undefined>(props.documentVersionORM);
  const [currentPPZCoords, setCurrentPPZCoords] = useState<PPZTransform>()
  const { playerEventData } = usePresentationPlayerContext()
  const timeoutRef = useRef<number>()
  const modalState = useSelector((state: RootState) => state.contentPreviewModal)
  const customDeck = isCustomDeckORM(folderItemORM?.relations.item)
    ? folderItemORM?.relations.item
    : undefined

  /* DECK RELATED */
  const areCustomSlides = haveHiddenSlides(activeDocVersion, folderItemORM);
  const isModifiedDoc = activeDoc?.model.status === 'PUBLISHED' && areCustomSlides
  const visiblePages: PageExtended[] =
  useMemo(
    () =>
      getVisiblePages(
        activeDocVersion,
        folderItemORM,
        customDeck,
      ),
    [activeDocVersion, folderItemORM, areCustomSlides],
  )
  const startingSlide = customDeck ? 1 : visiblePages[0]?.number || 1;
  // NOTE: FOR CUSTOM DECKS, SINCE SAME PAGE CAN BE USED MULTIPLE TIMES, THE ONLY WAY FOR US TO KNOW
  // IN WHICH PAGE ARE WE (ACTIVE SLIDE), IS BY THE INDEX OF THE PAGE IN THE VISIBLEPAGES ARRAY
  const [activeSlide, setActiveSlide] = useState<number>(startingSlide);
  const activePageObject = customDeck
    ? visiblePages[activeSlide - 1]
    : visiblePages.find(({ number }) => number === activeSlide);
  const currentDocumentVersion = activePageObject?.documentVersion || activeDocVersion;
  const adjustedSlideNumber = (
    !areCustomSlides
      ? activeSlide
      : visiblePages.findIndex(({ number }) => number === activeSlide) + 1
  ) || 1;

  const isViewingAssociatedFile =
    modalState.documentHistoryVersionNavigation &&
    modalState.documentHistoryVersionNavigation.length > 0

  /** SECTION - UTILITY FUNCTIONS */
  // TO AVOID HAVING THE IFRAME CHANGING FROM ONE GROUP TO ANOTHER (FROM THE SAME DOCVER),
  // WE CREATE A "SUPER GROUP" SO THE PLAYER ALREADY KNOWS THE CONSECUTIVE SLIDES OF CONSECUTIVE GROUPS
  const customDeckConsecutiveGroups: CustomDeckConsecutiveGroup[] = useMemo(() => {
    if (!customDeck) {
      return [];
    }

    let currentGroupId = 0;
    return customDeck.meta.groupsORM.reduce((acc, groupORM) => {
      if (!groupORM.model.visible) {
        return acc;
      }

      const groupPagesNumbers = groupORM.pages.map(({ page: { number } }) => number);
      const areGroupPagesInCurrentGroup = groupPagesNumbers.some(
        (pageNumber) => acc[currentGroupId - 1]?.visiblePageNumbers.includes(pageNumber));
      const canBeAddedToCurrentGroup =
        acc[currentGroupId - 1]?.documentVersionId === groupORM.pages[0]?.model.documentVersionId &&
        !areGroupPagesInCurrentGroup;

      if (canBeAddedToCurrentGroup) {
        acc[currentGroupId - 1].groupIds.push(groupORM.model.id);
        acc[currentGroupId - 1].visiblePageNumbers.push(...groupPagesNumbers);
      } else {
        currentGroupId++;
        acc.push({
          consecutiveGroupNumber: currentGroupId,
          groupIds: [groupORM.model.id],
          documentVersionId: groupORM.pages[0]?.model.documentVersionId!,
          visiblePageNumbers: groupPagesNumbers,
        });
      }

      return acc;
    }, [] as CustomDeckConsecutiveGroup[]);
  }, [customDeck]);

  // FOR CUSTOM DECKS, RETRIEVES THE CURRENTCONSECUTIVEGROUP BASED ON THE SLIDE INDEX
  const getCurrentConsecutiveGroup = (slideIndex): CustomDeckConsecutiveGroup | undefined => {
    if (!customDeck) {
      return;
    }

    const currentGroup = customDeck.meta.groupsORM
      .find(({ model: { id } }) => id === visiblePages[slideIndex - 1]?.groupId);

    return customDeckConsecutiveGroups.find(({ groupIds }) => groupIds.includes(currentGroup?.model.id || ''));
  };

  const setActiveFolderItemAndSlide = (folderItem: FolderItemORM, slide: number) => {
    setActiveSlide(slide);
    setFolderItemORM(folderItem);

    // IF IT'S A CUSTOM DECK, WE USE THE DOCVER OF THE SELECTED PAGE
    if (isCustomDeckORM(folderItem.relations.item)) {
      let visibleIndex = 0;
      folderItem.relations.item.meta.groupsORM.forEach((group) => {
        if (group.model.visible) {
          group.pages.forEach((page) => {
            visibleIndex++;
            if (visibleIndex === slide) {
              setActiveDocVersion(page.documentVersionORM);
            }
          });
        }
      });
    } else if (isDocumentVersionORM(folderItem.relations.item)) {
      setActiveDocVersion(folderItem.relations.item);
    }
    setTimeout(() => {
      setActiveSlide(slide);
    }, 1000);
  };

  const setActiveDocVersionAndSlide = (docVersion: undefined | DocumentVersionORM, slide: number) => {
    setFolderItemORM(undefined)
    setActiveSlide(slide)
    setActiveDocVersion(docVersion)
    setTimeout(() => { setActiveSlide(slide); }, 1000)
  }
  /**
   * This function initializes or resets a timer for handling
   * the controls overlay visibility. Defaults to 5 seconds.
   * @param timeout
   */
  const setControlsTimeout = (timeout?: number) => {
    if (isFullWindow) {
      // Clear previous timeout ref
      window.clearTimeout(timeoutRef.current)

      // Set new timeout if slideroll is not already visible
      if (!slideRollVisible) {
        timeoutRef.current = window.setTimeout(() => {
          setControlsVisible(false)
          setSlideRollVisible(false)
        }, timeout || DEFAULT_TIMEOUT)
      }

      // Enable control visibility if already not visible
      setControlsVisible(true)
    }
  }

  /* Returns currently visible sidebar options given modal state */
  const getSidebarOptions = (): SidebarOptions[] => {
    if (customDeck) {
      return [SidebarOptions.notesSidebar];
    }

    const options = [
      SidebarOptions.notesSidebar,
      SidebarOptions.releaseNotesSidebar]
    if (activeDocVersion?.model.associatedFiles &&
      activeDocVersion.model.associatedFiles.length > 0 &&
      !isViewingAssociatedFile) {
      options.push(SidebarOptions.associatedFilesSidebar)
    }
    options.push(SidebarOptions.metadataSidebar)
    return options
  }

  const registerLoadedDocumentState = (
    selectedItem: FolderItemORM|DocumentVersionORM,
    activeSlide?:number,
  ) => {
    const { id:selectedItemId } = selectedItem.model
    setLoadedDocuments(p => {
      const loadedDocumentIds = p.map(loadedDoc => loadedDoc.activeItem.model.id)
      const selectedItemIdx = p.findIndex(loadedDoc => loadedDoc.activeItem.model.id === selectedItemId)

      if (loadedDocumentIds.includes(selectedItemId)) {
        // Search for item in p and update
        activeSlide && (p[selectedItemIdx].activeSlide = activeSlide)
        p.forEach(doc => { doc.active = false })
        p[selectedItemIdx].active = true
        return p
      } else {
        // otherwise add new item in p
        p.forEach(loadedDoc => {
          loadedDoc.active = false
        })
        const newLoadedDoc:LoadedDocument = {
          activeItem:selectedItem,
          activeSlide:activeSlide ?? 1,
          active: true,
        }
        return [...p, newLoadedDoc]
      }
    })
  }
  /** !SECTION - UTILITY FUNCTIONS */

  /** SECTION - HANDLERS */
  const handleTrackingUpdate = () => {
    if (activeSlide && activeDocVersion) {
      const trackingObject = {
        action: 'PRESENTED',
        category: 'SLIDE',
        context,
        pageNumber: activeSlide,
        documentId: activeDocVersion?.model.documentId,
        documentVersionId: activeDocVersion?.model.id,
        customDeckId: customDeck?.model.id,
      };

      // IF IT'S VIRTUAL, ALWAYS TRACK THE SLIDE CHANGING, OTHERWISE, ONLY WHEN IN FULLSCREEN.
      if (context === 'VIRTUAL') {
        analytics?.track('SLIDE_PRESENTED', trackingObject);
      } else if (isFullWindow) {
        analytics?.track('SLIDE_PRESENTED', trackingObject);
      }
    }
  }
  /**
   * It is not clear how the following two handlers below differ and what scenarios they cover.
   * Can they be consolidated into a single function/useEffect?
   */
  const handleActiveDocUpdate = () => {
    if (activeDoc && (!activeDocVersion || activeDoc.model.id !== activeDocVersion.relations.document.model.id)) {
      setActiveDocVersion(
        activeDoc.relations.version.latestDocumentVersionPublished || activeDoc.relations.version.latestDocumentVersion,
      )
    } else if (!activeDoc) {
      setActiveDocVersion(undefined);
    }
  }
  const handleactiveDocVerUpdate = () => {
    if (activeDocVersion && (!activeDoc || activeDoc.model.id !== activeDocVersion.relations.document.model.id)) {
      setActiveDoc(activeDocVersion.relations.document);
      setVisibleSidebar(undefined)
    }
    setVisibleSearch(false);
  }

  const onSwipeAction = (swipeType: SWIPE_TYPE): void => {
    if (swipeType === SWIPE_TYPE.VERTICAL) {
      setSlideRollVisible((state) => !state);
    }
  };

  const handlePlayerEventData = () => {
    if (playerEventData) {
      // Would you just look at this silly switch? So basically, we look for an event from the iframe...
      switch (playerEventData.event) {
        // If it's a slidechange event we check to see if we're out of sync with the player and sync up
        // if necessary. Hopefully the player is not emitting dupes or unsolicited events 😳
        case PlayerEvents.statusEvent:
          if (activeSlide !== playerEventData.param) {
            setActiveSlide(playerEventData.param.slide)
          }
          break
        // If we see any mouse events from the iframe, make sure we are timing out the controls overlay visibility
        case PlayerEvents.mouseEvent:
          setControlsTimeout()
          break
        case PlayerEvents.swipeVertical:
          if (playerEventData.param) {
            setSlideRollVisible(!slideRollVisible)
          }
          break;
      }
    }
  }

  const handleContextInit = () => {
    const initialDoc = folderItemORM ?? activeDocVersion
    initialDoc && setLoadedDocuments(
      [{
        activeItem:initialDoc,
        activeSlide:activeSlide,
        active:true,
      }],
    )
  }
  const handleSearchClose = () => {
    if (!visibleSearch) {
      setSearchText('');
    }
  }

  useEffect(handleTrackingUpdate, [activeSlide, isFullWindow, activeDocVersion])
  useEffect(handleActiveDocUpdate, [activeDoc])
  useEffect(handleactiveDocVerUpdate, [activeDocVersion, folderItemORM])
  useEffect(handlePlayerEventData, [playerEventData])
  useEffect(handleSearchClose, [visibleSearch])
  useEffect(handleContextInit, [])

  const contextValue = {
    registerLoadedDocumentState,
    loadedDocuments,
    setLoadedDocuments,
    activePageObject,
    getCurrentConsecutiveGroup,
    customDeck,
    startingSlide,
    isModifiedDoc,
    isViewingAssociatedFile,
    folderItemORM,
    isFullWindow,
    setIsFullWindow,
    getSidebarOptions,
    visibleSidebar,
    setVisibleSidebar,
    slideRollVisible,
    setSlideRollVisible,
    controlsVisible,
    setControlsVisible,
    activeSlide,
    setActiveSlide,
    totalSlides: visiblePages.length,
    activeDoc,
    setActiveDoc,
    activeDocVersion,
    setFolderItemORM,
    setActiveDocVersion,
    setControlsTimeout,
    adjustedSlideNumber,
    currentPPZCoords,
    setCurrentPPZCoords,
    visiblePages,
    setActiveDocVersionAndSlide,
    setActiveFolderItemAndSlide,
    onSwipeAction,
    currentDocumentVersion,
    contentPanelVisible,
    setContentPanelVisible,
    documentTabLimit,
    visibleSearch,
    setVisibleSearch,
    searchText,
    setSearchText,
  } as ContentViewerModalStateType

  return (
    <ContentViewerModalStateContext.Provider value={contextValue}>
      {props.children}
    </ContentViewerModalStateContext.Provider>
  )
}
ContentViewerModalStateProvider.displayName = 'ContentViewerModalStateProvider'
export const useContentViewerModalState = () => {
  const context = useContext(ContentViewerModalStateContext)
  if (!context) {
    throw new Error('useContentViewerModalState must be used within the ContentViewerModalStateProvider')
  }
  return context;
}

export default ContentViewerModalStateProvider
