import { DocumentVersion, PageGroup } from '@alucio/aws-beacon-amplify/src/models'
import { DNABox, DNAButton, DNACheckbox, DNADivider, DNAText, Iffy, luxColors } from '@alucio/lux-ui'
import React, { useEffect } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/state/redux/store'
import { documentVersionActions } from 'src/state/redux/slice/documentVersion'
import useThumbnailSelector from './useThumbnailSelector'
import {
  CancelSave,
  SlideMode,
  ThumbnailPage,
} from './SlideSelector'
import omit from 'lodash/omit';
import PageGroupList from './PageGroupList'
import { useDocumentVersionORM } from 'src/state/redux/selector/document'

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 48,
    paddingVertical: 48,
  },
  rowWrapper: {
    backgroundColor: colors['color-text-basic'],
    paddingHorizontal: 16,
  },
  verticalDivider: {
    backgroundColor: luxColors.headerTopColor.primary,
    height: 20,
    marginHorizontal: 12,
    width: 1,
  },
});

/**
 * Main slide selector component. For Document version
 * @param props
 */
const SlideDocumentVersionSelector: React.FC<{
  onAction?: (pages?: ThumbnailPage[], pageGroups?: PageGroup[]) => void,
  disableHeader?: boolean,
  isReadOnly?: boolean,
}> = (props) => {
  const { onAction, disableHeader } = props
  const dispatch = useDispatch()
  const modalState = useSelector((state: RootState) => state.SlideSelectorModal);
  const selectedDocumentVersion = useDocumentVersionORM(modalState.documentVersionId!);
  const {
    allPagesSelected,
    canUngroup,
    isCover,
    isOptional,
    pages,
    pageGroups,
    selectionCount,
    thumbnailMode,
    thumbnailSize,
    canSelectCover,

    setPageGroups,
    toggleThumbnailMode,
    closeModal,
    handleSelectAllToggle,
    handleSetRequiredSlides,
    handleSetCoverThumbnail,
    handleSetGroupedSlides,
    handleSelectGroup,
    getGroupDrafts,
    initializeThumbnails,
  } = useThumbnailSelector({ mode: SlideMode.DocumentVersion }, onAction)

  const activeItem = selectedDocumentVersion?.model;
  const groupDrafts = getGroupDrafts()
  const groupingOptionActive = ( selectionCount > 1) || canUngroup
  const isReadOnly = props.isReadOnly ?? modalState.isReadOnly
  const [firstPageItem] = pages

  /** Interaction handlers */
  const handleSave = () => {
    activeItem &&
      dispatch(documentVersionActions.batchSave({
        model: DocumentVersion,
        entity: activeItem,
        updates: {
          pages: pages.map(page => omit(page, ['thumbnailObjectURL', 'checked', 'disabled'])),
          pageGroups: pageGroups,
        },
      }))
  };

  /** useEffect Hooks */
  const handleStateUpdate = () => {
    if (!activeItem || !modalState.documentVersionId) return
    if (!firstPageItem?.pageId.includes(modalState.documentVersionId)) {
      initializeThumbnails(activeItem, false)
      activeItem.pageGroups && setPageGroups(activeItem.pageGroups)
    }
  }
  // the second argument is a temp workaround, the component is still rendering a few extra times
  // need to refactor this for better performance
  // currently the thumbnails are initialized more than once, it should only initialize once
  useEffect(handleStateUpdate, [modalState, firstPageItem?.pageId])

  /** Main Component Structure */
  return (
    <DNABox fill appearance="col">
      {/* Header */}
      <Iffy is={!disableHeader}>
        <CancelSave
          closeModal={closeModal}
          handleSave={handleSave}
          saveDisabled={false}
          title={selectedDocumentVersion?.model.title || ''}
          isReadOnly={modalState.isReadOnly}
        />
        <DNADivider />
      </Iffy>

      {/* Body */}
      <DNABox fill appearance="col">
        { /* SELECTED SLIDES INFO */}
        <DNABox style={styles.rowWrapper} alignY="center">
          <Iffy is={!isReadOnly}>
            {/* 'Select all' check box */}
            <DNACheckbox
              checked={allPagesSelected}
              onChange={handleSelectAllToggle}
              style={{ marginRight: 7 }}
            />
            <DNAText>Select all</DNAText>
            <DNADivider style={styles.verticalDivider} />
            <DNAText>{`${selectionCount} of ${pages.length} Selected`}</DNAText>
            <DNADivider style={styles.verticalDivider} />
            <Iffy is={selectionCount >= 1}>
              <DNAButton
                appearance="ghost"
                context="minimum"
                status="dark"
                onPress={handleSetRequiredSlides}
              >
                {isOptional ? 'Mark not required' : 'Mark required'}
              </DNAButton>
            </Iffy>
            <Iffy is={canSelectCover && !isCover}>
              <DNADivider style={styles.verticalDivider} />
              <DNAButton
                appearance="ghost"
                context="minimum"
                status="dark"
                onPress={handleSetCoverThumbnail}
              >
                Set as cover thumbnail
              </DNAButton>
            </Iffy>
            <Iffy is={groupingOptionActive}>
              <DNADivider style={styles.verticalDivider} />
              <DNAButton
                appearance="ghost"
                context="minimum"
                status="dark"
                onPress={handleSetGroupedSlides}
              >
                {canUngroup ? 'Ungroup' : 'Group'}
              </DNAButton>
            </Iffy>
            <DNABox fill alignX="end">
              <DNAButton
                appearance="ghost"
                status="dark"
                iconLeft={thumbnailMode === 'large' ? 'view-comfy' : 'view-grid'}
                onPress={toggleThumbnailMode}
              />
            </DNABox>
          </Iffy>
        </DNABox>
        <DNADivider />
        { /* CONTENT (SLIDES) */}
        {/* @luisenaguero why was this additional scrollview added here? */}
        <ScrollView style={styles.content}>
          <PageGroupList
            handleGroupSelect={isReadOnly ? () => undefined : handleSelectGroup}
            thumbnailSize={thumbnailSize}
            groupDrafts={groupDrafts}
            disableCheckboxes={isReadOnly}
          />
        </ScrollView>
      </DNABox>
    </DNABox>
  )
}

export default SlideDocumentVersionSelector;
