import { DNABox, DNAButton, Popover } from '@alucio/lux-ui'
import React from 'react'
import { Pressable, ViewStyle } from 'react-native'
import { useInfoPanelState } from '../State/InfoPanelStateProvider'

enum ActionBarOrientations {
  'row',
  'col'
}
type ActionBarOrientation = keyof typeof ActionBarOrientations

enum ActionBarOrders {
  'normal',
  'reverse'
}
type ActionBarOrder = keyof typeof ActionBarOrders

export interface ActionBarElement {
  icon?: string
  label?: string
  popoverContent?: React.ReactNode
  onPress?: () => void
  style?: ViewStyle
  active?: boolean
}

interface ActionBarProps {
  options: ActionBarElement[]
  order?: ActionBarOrder
  orientation?: ActionBarOrientation
  style?: ViewStyle
}

const ActionBar: React.FC<ActionBarProps> = (props) => {
  const { collapsed } = useInfoPanelState()
  const { orientation, options, order, style } = props

  const actionBarOptionElements = options.map(option => {
    const { icon, label, popoverContent, active, ...optionProps } = option

    const anchor = (
      <DNAButton
        onPress={optionProps.onPress}
        iconLeft={icon}
        context="round"
        appearance={!collapsed && active ? 'filled' : 'ghost'}
        status={!collapsed && active ? 'primary' : 'neutral'}
      />
    )

    const popover = (
      <Popover placement="left">
        <Popover.Anchor>
          {anchor}
        </Popover.Anchor>

        <Popover.Content >
          {popoverContent}
        </Popover.Content>
      </Popover>
    )

    const derivedElement = popoverContent
      ? popover
      : anchor

    return (
      <Pressable {...optionProps}>
        {derivedElement}
      </Pressable>
    )
  })

  const orderedActionBarOptionElements = order === 'reverse'
    ? actionBarOptionElements.reverse()
    : actionBarOptionElements

  return (
    <DNABox appearance={orientation} style={style} spacing="small">
      {orderedActionBarOptionElements}
    </DNABox>
  )
}

export default ActionBar
