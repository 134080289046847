import React from 'react'
import { useDispatch } from 'react-redux'
import { Meeting } from '@alucio/aws-beacon-amplify/src/models';
import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui';
import { meetingActions } from 'src/state/redux/slice/meeting';
import DNACommonConfirmation from './DNACommonConfirmation';
import { CONTENT_PRESENTED_STATUS } from '@alucio/aws-beacon-amplify/src/API';

interface DeleteConfirmationProps {
  meeting: Meeting,
  contentPresentedId: string,
  folderItemId?: string,
  onDelete?: () => void,
}

const DNAMeetingContentDeleteModal = (props: DeleteConfirmationProps) => {
  const { meeting, onDelete, contentPresentedId, folderItemId } = props
  const dispatch = useDispatch()
  const toast = useToast();

  const onConfirm = () => {
    const payload = {
      ...meeting,
      contentPresented: meeting.contentPresented?.map(p => (p.contentId === contentPresentedId &&
        !folderItemId && !p.folderItemId) ||
        (p.folderItemId === folderItemId && p.folderItemId === folderItemId)
        ? { ...p, status: CONTENT_PRESENTED_STATUS.DELETED } : p),
    }
    dispatch(meetingActions.updateMeeting(
      meeting, payload,
    ))
    toast?.add(
      <GenericToast title="Content presented deleted" status="error" />,
      ToastOrientations.TOP_RIGHT,
    )
    onDelete?.()
  }

  return (<DNACommonConfirmation
    status="danger"
    cancelText="Cancel"
    descriptionText="This cannot be undone."
    confirmActionText="Delete"
    onConfirmAction={onConfirm}
    title="Delete content presented?"
  />)
}

export default DNAMeetingContentDeleteModal
