import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface SlideSelectorModalSliceType {
  isOpen?: boolean,
  activeFolderItemId?: string,
  folderId?: string,
  documentVersionId?: string,
  isReadOnly?: boolean,
}

const initialState: SlideSelectorModalSliceType = {
  isOpen: false,
};

const slideSelectorModalSlice = createSlice({
  name: 'SlideSelectorModalState',
  initialState: initialState,
  reducers: {
    /** This reducer displays the slide selector modal if an active folderitem is passed. If not, the modal is hidden */
    setActiveFolderItem: (state, action: PayloadAction<SlideSelectorModalSliceType>) => {
      const { payload } = action
      if (payload.activeFolderItemId) {
        state.isOpen = payload.isOpen
        state.activeFolderItemId = payload.activeFolderItemId
        state.folderId = payload.folderId
      } else {
        state.isOpen = false
        state.activeFolderItemId = undefined
        state.folderId = undefined
      }
      return state
    },
    setActiveDocumentVersion: (state, action: PayloadAction<SlideSelectorModalSliceType>) => {
      const { payload } = action
      if (payload.documentVersionId) {
        state.isOpen = payload.isOpen
        state.documentVersionId = payload.documentVersionId;
        state.isReadOnly = payload.isReadOnly;
      } else {
        state.isOpen = false
        state.documentVersionId = undefined
      }
      return state
    },
  },
});

export default slideSelectorModalSlice;
export const slideSelectorModalActions = {
  ...slideSelectorModalSlice.actions,
}
