
import { useEffect, useState } from 'react';

/** This is run prior to the  react router being instantiated so to support SSO
 * we must use the location property of the window interface on the WebAPI */
const navigation = window.location;

const useRetryOnErrosSSO = () => {
  const [error, setError] = useState<undefined | string>();
  useEffect(() => {
    const url = localStorage.getItem('loginUrl')
    const errorOnFirstSign = navigation
      .search
      .startsWith('?error_description=Error+in+SAML+response+processing%3A+Already+found+an+entry+for+username');
    const ssoError = navigation.search.startsWith('?error_description');
    const persistedSSO = localStorage.getItem('ssoError');

    if (errorOnFirstSign && url) navigation.href = url;
    else if (ssoError) {
      localStorage.setItem('ssoError', JSON.stringify({
        ssoError: navigation.search,
        // eslint-disable-next-line max-len
        error: 'We were unable to log you in using Single Sign-On. Please try again or contact your organization’s system administrator to resolve this issue.',
      }));
      navigation.href = navigation.origin
    }
    else if (persistedSSO) {
      const ssoError = { ...(JSON.parse(persistedSSO) as unknown as { ssoError: string, error: string}) };
      console.info(ssoError.ssoError); // eslint-disable-line no-console
      setError(ssoError.error);
      setTimeout(() => localStorage.removeItem('ssoError'), 1000); // if we dont do this for an unknown reason the messages never is shown
    }
    else setError(undefined)
  }, [navigation.search])

  const saveUrl = (url) => {
    localStorage.setItem('loginUrl', url);
  }

  const cleanSSoError = () => setError(undefined)

  return {
    saveUrl,
    cleanSSoError,
    error,
  }
}

export default useRetryOnErrosSSO
