// import { useEffect } from 'react'
import { useState } from 'react'

enum ThumbnailModes {
    large = 'large',
    small = 'small'
  }
export type ThumbnailMode = keyof typeof ThumbnailModes

export interface ThumbnailSize {
  width: number
  height: number
}
interface ThumbnailSizes {
  [key: string]: ThumbnailSize
}

export const thumbnailSizes: ThumbnailSizes = {
  [ThumbnailModes.small]: {
    width: 220,
    height: 104,
  },
  [ThumbnailModes.large]: {
    width: 336,
    height: 190,
  },
}

const useThumbnailSize = () => {
  const [thumbnailMode, setThumbnailMode] = useState<ThumbnailMode>('large')

  const thumbnailSize = {
    width: thumbnailSizes[thumbnailMode].width,
    height: thumbnailSizes[thumbnailMode].height,
  }

  const toggleThumbnailMode = () => {
    setThumbnailMode(p => (p === 'small' ? 'large' : 'small'))
  }

  return {
    thumbnailMode,
    setThumbnailMode,
    toggleThumbnailMode,
    thumbnailSize,
  }
}

export default useThumbnailSize
