import React from 'react'
import { useDispatch } from 'react-redux'

import { EmailTemplate } from '@alucio/aws-beacon-amplify/src/models';
import { GenericToast, ToastOrientations } from '@alucio/lux-ui';

import { emailTemplateActions } from 'src/state/redux/slice/emailTemplate';
import DNACommonConfirmation from './DNACommonConfirmation';
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast';

interface DeleteConfirmationProps {
    toast: ToastActions | undefined,
    emailTemplate: EmailTemplate,
    onDelete?: () => void,
}

const DNAEmailTemplateDeleteModal = (props: DeleteConfirmationProps) => {
  const { toast, emailTemplate, onDelete } = props
  const dispatch = useDispatch()

  const onConfirm = () => {
    dispatch(emailTemplateActions.delete(emailTemplate))
    toast?.add(
      <GenericToast title="Email template deleted" status="error" />,
      ToastOrientations.TOP_RIGHT,
    )
    onDelete?.()
  }

  return (<DNACommonConfirmation
    status="danger"
    cancelText="Cancel"
    descriptionText="This action cannot be undone"
    confirmActionText="Delete"
    onConfirmAction={onConfirm}
    title="Delete email template?"
  />)
}

export default DNAEmailTemplateDeleteModal
