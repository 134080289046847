import { combineReducers } from '@reduxjs/toolkit'

import attachedFileSlice from 'src/state/redux/slice/attachedFile';
import contentPreviewModalSlice from 'src/state/redux/slice/contentPreviewModal';
import customDeckSlice from 'src/state/redux/slice/customDeck'
import documentSlice from 'src/state/redux/slice/document'
import documentVersionSlice from 'src/state/redux/slice/documentVersion'
import emailTemplateSlice from 'src/state/redux/slice/emailTemplate';
import folderSlice from 'src/state/redux/slice/folder'
import tenantSlice from 'src/state/redux/slice/tenant'
import userSlice from 'src/state/redux/slice/user'
import drawerSlice from 'src/state/redux/slice/drawer'
import virtualSlice from 'src/state/redux/slice/virtual'
import virtualSessionSlice from 'src/state/redux/slice/virtualSession'
import DNAModalSlice from 'src/state/redux/slice/DNAModal/DNAModal'
import presentationControlSlice from 'src/state/redux/slice/PresentationControl/presentationControl';
import slideSelectorModalSlice from 'src/state/redux/slice/SlideSelectorModal/SlideSelectorModal';
import cacheSlice from 'src/state/redux/slice/Cache/cache'
import presentationBuilderSlice from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder';
import integrationSettingsSlice from 'src/state/redux/slice/integrationSettings'
import infoPanelSlice from './slice/infoPanel'
import sharedFolderSlice from 'src/state/redux/slice/sharedFolder';
import meetingSlice from 'src/state/redux/slice/meeting';

export const rootReducer = combineReducers({
  attachedFile: attachedFileSlice.reducer,
  contentPreviewModal: contentPreviewModalSlice.reducer,
  customDeck: customDeckSlice.reducer,
  document: documentSlice.reducer,
  documentVersion: documentVersionSlice.reducer,
  emailTemplate: emailTemplateSlice.reducer,
  folder: folderSlice.reducer,
  tenant: tenantSlice.reducer,
  user: userSlice.reducer,
  drawer: drawerSlice.reducer,
  virtual: virtualSlice.reducer,
  virtualSession: virtualSessionSlice.reducer,
  sharedFolder: sharedFolderSlice.reducer,
  DNAModal: DNAModalSlice.reducer,
  PresentationControl: presentationControlSlice.reducer,
  SlideSelectorModal: slideSelectorModalSlice.reducer,
  cache: cacheSlice.reducer,
  PresentationBuilder: presentationBuilderSlice.reducer,
  integrationSettings: integrationSettingsSlice.reducer,
  infoPanel: infoPanelSlice.reducer,
  meeting: meetingSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>
