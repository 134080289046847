import { User } from '@alucio/aws-beacon-amplify/src/models';

import debounce from 'lodash/debounce'
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStore } from '@aws-amplify/datastore'

import {
  commonReducers,
  initialState,
  SliceState,
  ModelPayload,
  formatDataStorePayload,
} from './common';

const sliceName = 'user';
const { reducers, extraReducers, asyncActions } = commonReducers<User>(sliceName)

const debouncedDataStoreSave = debounce(DataStore.save, 2500)

const bookmark = (
  state: SliceState<User>,
  action: PayloadAction<ModelPayload<User>>,
): SliceState<User> => {
  const { model, entity, updates } = action.payload
  const targetUserIdx = state.records.findIndex(user => entity.id === user.id)

  if (targetUserIdx === -1 || updates.bookmarkedDocs === undefined)
  { return state; }

  const updated = formatDataStorePayload(model, entity, updates)

  state.records[targetUserIdx] = updated
  debouncedDataStoreSave(updated)

  return state
}

const userSlice = createSlice({
  name: sliceName,
  initialState: initialState<User>(),
  reducers: {
    ...reducers,
    bookmark,
    setCognitoUser: (state, action) => {
      return {
        ...state,
        cognitoUser: action.payload,
      };
    },
  },
  extraReducers,
});

export default userSlice;
export const userActions = {
  ...userSlice.actions,
  ...asyncActions,
}
