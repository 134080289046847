import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { commonReducers, initialState, SliceStatus } from './common';
import { getSharedFolders } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { GetSharedFoldersQuery } from '@alucio/aws-beacon-amplify/src/API';
import { Folder } from '@alucio/aws-beacon-amplify/src/models';

const sliceName = 'sharedFolder'
const { reducers, extraReducers } = commonReducers<Folder>(sliceName)

const getSharedFoldersAction = createAsyncThunk(
  'sharedFolder/getSharedFolders',
  async () => {
    const { data } = await API.graphql(graphqlOperation(getSharedFolders)) as GraphQLResult<GetSharedFoldersQuery>;
    return data?.getSharedFolders || [];
  },
)

const sharedFolderSlice = createSlice({
  name: sliceName,
  initialState: initialState<Folder>(),
  reducers,
  extraReducers: {
    [getSharedFoldersAction.fulfilled.toString()]: (state, { payload }) => {
      state.status = SliceStatus.OK;
      state.hydrated = true;
      state.records = payload;
    },
    [getSharedFoldersAction.rejected.toString()]: (state) => {
      state.status = SliceStatus.ERROR;
    },
    ...extraReducers,
  },
})

export default sharedFolderSlice
export const sharedFolderActions = {
  ...sharedFolderSlice.actions,
  getSharedFoldersAction,
}
