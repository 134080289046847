import React, { useState, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import {
  Iffy,
  DNABox,
  DNAButton,
  DNAText,
  DNADivider,
  DNAIcon,
  Collapsible,
  useOrientationChange,
  Popover,
} from '@alucio/lux-ui'
import { Switch, Route, useLocation } from 'src/router'

import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList'

import { filterCollection } from 'src/state/redux/selector/common'
import { FolderORM, FolderItemORM, isDocumentVersionORM, DocumentORM } from 'src/types/types'
import { filters, sorts, merge } from 'src/state/redux/folder/query'

import useDisplayMode, { DisplayModes } from 'src/components/DNA/hooks/useDisplayMode'
import useFolderNavURL, { useFolderNavURLInit } from 'src/components/DNA/Folder/Nav/useFolderNavURL'
import { useDNAFolderActions } from 'src/components/DNA/Folder/DNAFolder.actions'
import { useDNADocumentVersionActions } from 'src/components/DNA/Document/DNADocumentVersion.actions'
import { withDNAFolderNav, useDNAFolderNav } from 'src/components/DNA/Folder/Nav/DNAFolderNav'
import DNADefaultHeader from 'src/components/DNA/FlatList/Headers/DNADefaultHeaders'
import DNAMyFoldersTablet from './DNAMyFoldersTablet';
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import DNAGrid from 'src/components/DNA/Grid/DNAGrid'
import { useAppSettings } from 'src/state/context/AppSettings'
import { ROUTES } from 'src/router/routes'
import useScreenNav from 'src/components/DNA/hooks/useScreenNav'
import truncate from 'lodash/truncate'
import DNARouteTabs from 'src/components/DNA/Navigation/DNARouteTabs/DNARouteTabs'
import DocumentsUpdates from './DocumentsUpdates'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import { useDispatch } from 'react-redux'
import { infoPanelActions } from 'src/state/redux/slice/infoPanel'
import { sharedFolderActions } from 'src/state/redux/slice/sharedFolder'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import MyUploads from './MyUploads'
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions'

const S = StyleSheet.create({
  chevronIcon: { marginTop: 2 },
  tab: {
    paddingHorizontal: 12,
    paddingVertical: 20,
  },
  breadcrumb: {
    fontSize: 14,
    fontWeight: '700',
  },
  gridViewContainer: {
    paddingTop: 16,
    paddingHorizontal: 8,
    borderWidth: 1,
    // TODO: This color is neither in the new theme system and in the legacy one, but it isused in the mockups
    borderColor: '#EBEBEB',
    paddingBottom: 16,
    marginBottom: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    backgroundColor: 'white',
  },
  tabContainer: {
    backgroundColor: 'white',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: colors['color-flat-400'],
  },
  header: {
    backgroundColor: 'white',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 0,
    borderColor: colors['color-flat-400'],
    padding: 16,
  },
})

const MAX_FOLDER_NAME_LENGTH = 69

export const useDNAMyFoldersSharedResources = () => {
  const dispatch = useDispatch()
  const { go } = useScreenNav()
  const { displayMode, displayModeIconName, toggleDisplayMode } = useDisplayMode()
  const folderActions = useDNAFolderActions()
  const documentVersionActions = useDNADocumentVersionActions()
  const orientation = useOrientationChange()
  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const folderNav = useDNAFolderNav()
  const documentActions = useDNADocumentActions();

  let folderNavURLPath = ROUTES.FOLDERS.PATH

  if (isPublisherRoute && folderNav.displaySharedFolders) {
    folderNavURLPath = ROUTES.PUBLISHER_SHARED_FOLDERS.PATH
  } else if (isPublisherRoute) {
    folderNavURLPath = ROUTES.PUBLISHER_FOLDERS.PATH
  } else if (folderNav.displaySharedFolders) {
    folderNavURLPath = ROUTES.SHARED_FOLDERS.PATH
  }

  const folderNavURL = useFolderNavURL(folderNavURLPath)

  const activeItems = isPublisherRoute
    ? folderNav.currentItems
    : folderNav.currentItems.filter(
      item => (isDocumentVersionORM(item.relations.item) ? item.relations.item.model.status !== 'NOT_PUBLISHED' : true),
    )

  const { deviceMode } = useAppSettings()
  const isTablet = deviceMode === 'tablet'

  /** TODO: Identify why we are using two different types here for folder items */
  const onPress = (item: FolderItemORM | FolderORM | DocumentORM) => {
    if (item.type === 'FOLDER') {
      folderNavURL.pushFolder(item)
    } else if (item.type === 'DOCUMENT') {
      const documentVersion = item.relations.version.latestUsableDocumentVersion;
      if (documentVersion.model.conversionStatus === 'PROCESSED') {
        documentActions.preview(item)();
      }
    } else if (item.relations.item.type === 'DOCUMENT_VERSION') {
      const documentVersionORM = item.relations.item
      documentVersionActions.preview(documentVersionORM, item)()
    } else if (item.relations.item.type === 'CUSTOM_DECK') {
      const customDeck = item.relations.item
      for (const group of customDeck.meta.groupsORM) {
        if (group.model.visible && group.pages[0]) {
          documentVersionActions.preview(group.pages[0].documentVersionORM, item)()
          break
        }
      }
    }
  }
  const createOnPress = () => {
    folderNav.isCurrentlyNested
      ? folderActions.createSubFolder(folderNav.folderStack.slice(-1)[0])()
      : folderActions.createFolder((undefined as any) as FolderORM)()
  }

  const returnToRoot = () => {
    go(ROUTES.FOLDERS)
  }

  const getBreadCrumb = (idx, truncateText = false, hideText = false) => {
    const notCurrentFolder = folderNav.folderStack.length - 1 !== idx
    const folderORM = folderNav.folderStack[idx]
    const breadCrumb = folderORM ? (
      <DNABox appearance="row">
        <DNAButton
          appearance="ghost"
          status="dark"
          context="tablet"
          iconLeft={notCurrentFolder ? 'chevron-left' : undefined}
          iconStyle={S.chevronIcon}
          onPress={() => folderNavURL.popToFolder(folderORM)}
        >
          <Iffy is={!hideText}>
            <DNABox>
              <Iffy is={truncateText && isTablet && orientation === 'LANDSCAPE'}>
                {truncate(folderORM.model.name, { length: 50 })}
              </Iffy>
              <Iffy is={truncateText && isTablet && orientation === 'PORTRAIT'}>
                {truncate(folderORM.model.name, { length: 30 })}
              </Iffy>
              <Iffy is={!truncateText}>{folderORM.model.name}</Iffy>
            </DNABox>
          </Iffy>
        </DNAButton>
      </DNABox>
    ) : null

    return breadCrumb
  }

  const launchSlideBuilder = () => {
    /** This is taken from the createOnPress pattern above but is a
     * bit of a hack. Need to revisit this and properly implement */
    const currentFolder = folderNav.folderStack.slice(-1)[0]
    folderActions.createCustomPresentation(currentFolder)()
  }
  const { isOnline } = useAppSettings()
  const emptyVariantType = folderNav.isFolderListLoaded
    ? (folderNav.isCurrentlyNested ? EmptyVariant.FolderGridEmpty : EmptyVariant.FolderListCreate)
    : (isOnline ? EmptyVariant.FolderListLoading : EmptyVariant.FolderListSharedOffline)

  /** Inform the global info panel that we've navigated into a folder */
  useEffect(() => {
    const currentItem = folderNav.folderStack.slice(-1)[0];
    dispatch(infoPanelActions.setActiveItem(currentItem))
  }, [folderNav])

  return {
    folderActions,
    displayMode,
    displayModeIconName,
    toggleDisplayMode,
    folderNav,
    folderNavURL,
    activeItems,
    onPress,
    createOnPress,
    returnToRoot,
    getBreadCrumb,
    emptyVariantType,
    launchSlideBuilder,
  }
}

type SharedResourcesType = ReturnType<typeof useDNAMyFoldersSharedResources>

const ArchivedFolders: React.FC<{ noActiveContent?: boolean }> = props => {
  const { noActiveContent } = props
  const { displayMode } = useDisplayMode()

  const folderNav = useDNAFolderNav()
  const folderNavURL = useFolderNavURL('/folders')

  const archivedFolders = filterCollection(
    folderNav.currentFolders,
    merge(filters.archived, filters.rootFolder, sorts.pinnedAsc),
  )

  const [isArchiveVisible, setIsArchivedVisible] = useState<boolean>(!!noActiveContent && archivedFolders.length > 0)
  const emptyVariantType = folderNav.isCurrentlyNested ? EmptyVariant.FolderGridEmpty : EmptyVariant.FolderListCreate

  return (
    <DNABox appearance="col" spacing="medium">
      <DNABox style={{ marginTop: 48 }} alignY="center">
        <DNAButton
          appearance="ghost"
          status="subtle"
          context="minimum"
          iconLeft={isArchiveVisible ? 'chevron-down' : 'chevron-right'}
          onPress={() => setIsArchivedVisible(p => !p)}
          iconStyle={{ marginTop: 2, marginRight: 2 }}
          style={{ marginRight: 12 }}
          testID="archived-folders-toggle"
        >
          <DNAText h5 status="subtle">
            Archived Folders ({archivedFolders.length ?? 0})
          </DNAText>
        </DNAButton>
        <DNADivider style={{ flex: 1 }} />
      </DNABox>
      <Collapsible collapsed={!isArchiveVisible} style={{ padding: 10 }}>
        <Iffy is={displayMode === DisplayModes.grid && archivedFolders.length !== 0}>
          <DNAGrid items={archivedFolders} onPress={folderNavURL.pushFolder} emptyVariant={emptyVariantType} />
        </Iffy>
        <Iffy is={displayMode === DisplayModes.list && archivedFolders.length !== 0}>
          <DNAFlatList<FolderORM>
            items={archivedFolders}
            onPress={folderNavURL.pushFolder}
            emptyVariant={emptyVariantType}
          >
            <DNADefaultHeader />
          </DNAFlatList>
        </Iffy>
      </Collapsible>
    </DNABox>
  )
}

const folderRegex = /^\/folders\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b.*/gm;

export const DNAMyFoldersTabsDesktop: React.FC = () => {
  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const LDClient = useLDClient();
  const enableSharedFolders = LDClient?.variation('enable-shared-folders', true);
  const enableMyUploads = LDClient?.variation('enable-viewer-file-upload', false);

  return (
    <DNABox alignX="center">
      <DNABox fill alignX={isPublisherRoute ? 'stretch' : 'center'}>
        <DNABox
          fill
          style={{
            maxWidth: isPublisherRoute ? undefined : 1440,
            paddingHorizontal: isPublisherRoute ? 32 : 48,
            paddingVertical: 20,
            backgroundColor: isPublisherRoute ? colors['color-text-basic'] : undefined,
          }}
        >
          <DNABox appearance="col" fill spacing="medium">
            <DNABox alignY="center" style={{ margin: 0, marginHorizontal: 12 }}>
              <DNAText
                testID="page-title"
                status={isPublisherRoute ? 'subtle' : 'secondary'}
                h5
              >
                My Folders
              </DNAText>
            </DNABox>
            <DNARouteTabs.Router>
              <Iffy is={isPublisherRoute}>
                <DNARouteTabs.Tabs withDivider style={S.tabContainer}>
                  <DNARouteTabs.Tab title="My Folders" path="/publisher_folders" style={S.tab} hasNavigationPaths>
                    <DNAMyFoldersDesktop />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Tab
                    title="Shared With Me"
                    path="/publisher_shared_folders"
                    style={[S.tab, !enableSharedFolders && { display: 'none' }]}
                    hasNavigationPaths
                  >
                    <DNAMyFoldersDesktop />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Tab title="My Updates" path="/publisher_folders/updates" style={S.tab}>
                    <DocumentsUpdates />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Unmatched rewrite="/publisher_folders" />
                </DNARouteTabs.Tabs>
              </Iffy>
              <Iffy is={!isPublisherRoute}>
                <DNARouteTabs.Tabs withDivider style={S.tabContainer}>
                  <DNARouteTabs.Tab title="My Folders" path="/folders" style={S.tab} hasNavigationPaths>
                    <DNAMyFoldersDesktop />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Tab
                    title="Shared With Me"
                    path="/shared_folders"
                    style={[S.tab, !enableSharedFolders && { display: 'none' }]}
                    hasNavigationPaths
                  >
                    <DNAMyFoldersDesktop />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Tab
                    title="My Uploads"
                    path="/folders/uploads"
                    style={[S.tab, !enableMyUploads && { display: 'none' }]}
                  >
                    <MyUploads />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Tab title="My Updates" path="/folders/updates" style={S.tab}>
                    <DocumentsUpdates />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Unmatched rewrite="/folders" />
                </DNARouteTabs.Tabs>
              </Iffy>
            </DNARouteTabs.Router>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

// [TODO] This should probably contain a switch and DNAFolderContent should declared at the root route level
// (So we can reuse the header bar)

export const DNAMyFoldersDesktop: React.FC = () => {
  const {
    folderNav,
    folderNavURL,
    createOnPress,
    displayModeIconName,
    toggleDisplayMode,
    displayMode,
    activeItems,
    onPress,
    emptyVariantType,
    launchSlideBuilder,
  } = useDNAMyFoldersSharedResources()
  const { setDisplaySharedFolders } = folderNav
  const { pathname } = useLocation();
  const lowerCasedPathName = pathname.toLowerCase();
  const isInsideFolder = !!lowerCasedPathName.match(folderRegex);
  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const sharedFoldersTab = !!route?.PATH.includes('shared_folders');

  const rootIsShared = folderNav.folderStack.length &&
    folderNav.folderStack[0].model?.sharePermissions?.some((item) => !item.isDeleted)

  useEffect(() => {
    setDisplaySharedFolders(sharedFoldersTab);
  }, [sharedFoldersTab]);

  return (
    <DNABox
      fill
      appearance="col"
    >
      {/* Breadcrumb Nav */}
      <DNABox
        alignY="center"
        spacing="between"
        style={S.header}
      >
        <DNABox alignY="center" spacing="small">
          <DNAButton appearance="ghost" status="subtle" context="minimum" onPress={() => folderNavURL.popToFolder()}>
            <DNAText testID="page-title" status="secondary" style={S.breadcrumb}>
              {sharedFoldersTab ? 'Shared With Me' : 'My Folders'}
            </DNAText>
          </DNAButton>
          {folderNav.folderStack.map(folderORM => [
            <DNAIcon.Styled
              name="chevron-right"
              appearance="ghost"
              status="subtle"
              context="minimum"
              style={S.chevronIcon}
            />,
            <DNAButton
              appearance="ghost"
              status="subtle"
              context="minimum"
              onPress={() => folderNavURL.popToFolder(folderORM)}
            >
              <DNAText status="secondary" style={S.breadcrumb}>
                {truncate(folderORM.model.name, { length: MAX_FOLDER_NAME_LENGTH })}
              </DNAText>
            </DNAButton>,
          ])}
        </DNABox>

        {/* Right Controls */}

        <DNABox spacing="medium">
          <Iffy is={!isPublisherRoute && !rootIsShared && !sharedFoldersTab && isInsideFolder}>
            <Popover placement="top">
              <Popover.Anchor>
                <DNAButton
                  iconLeft="card-plus-outline"
                  size="large"
                  appearance="ghost"
                  status="brand"
                  context="minimum"
                  disabled={!folderNav.isCurrentlyNested}
                  onPress={launchSlideBuilder}
                />
              </Popover.Anchor>
              <Popover.Content>
                <DNAText style={{ color: colors['color-text-basic'] }}>
                  {folderNav.isCurrentlyNested
                    ? 'Build Presentation'
                    : 'Open a folder to access this Presentation Builder feature'}
                </DNAText>
              </Popover.Content>
            </Popover>
          </Iffy>
          <Iffy is={!folderNav.isMaxDepth && !sharedFoldersTab}>
            <Popover placement="top">
              <Popover.Anchor>
                <DNAButton
                  size="large"
                  appearance="ghost"
                  status="brand"
                  context="minimum"
                  iconLeft="folder-plus"
                  onPress={createOnPress}
                />
              </Popover.Anchor>
              <Popover.Content>
                <DNAText status="basic">Create new folder</DNAText>
              </Popover.Content>
            </Popover>
          </Iffy>
          <DNAButton
            size="large"
            appearance="ghost"
            status="brand"
            context="minimum"
            iconLeft={displayModeIconName}
            onPress={toggleDisplayMode}
          />
        </DNABox>
      </DNABox>

      {/* Content */}
      {/* <Iffy is={
          // [TODO-297] - Make sure empty states still work
          archivedFolders.length === 0 ||
          activeFolders.length > 0}
        > */}
      <Iffy is={displayMode === DisplayModes.grid}>
        <DNABox style={S.gridViewContainer}>
          <DNAGrid
            items={activeItems}
            onPress={onPress}
            emptyVariant={emptyVariantType}
          />
        </DNABox>
      </Iffy>
      <Iffy is={displayMode === DisplayModes.list}>
        <DNAFlatList
          items={activeItems}
          variant="default"
          onPress={onPress}
          emptyVariant={emptyVariantType}
        >
          <DNADefaultHeader variant={isPublisherRoute ? 'publisher' : 'default'} />
        </DNAFlatList>
      </Iffy>
      {/* </Iffy> */}

      {/* Archived */}
      <Iffy is={!folderNav.isCurrentlyNested} >
        <ArchivedFolders />
      </Iffy>
    </DNABox>
  )
}

const DNAMyFoldersSwitcher: React.FC = () => {
  const { deviceMode } = useAppSettings()

  return deviceMode === 'desktop' ? <DNAMyFoldersTabsDesktop /> : <DNAMyFoldersTablet />
}

// [TODO]: We can potentially just render <Routes> within the above component
// - Try using useRouteMatch and then do a conditional route render if we don't
//   exceed the max depth
const NestedRoutes = () => {
  useFolderNavURLInit()
  const dispatch = useDispatch()

  const ldlClient = useLDClient();
  const { isOnline } = useAppSettings()
  const enableSharedFolders = ldlClient?.variation('enable-shared-folders', false);

  useEffect(() => {
    if (enableSharedFolders && isOnline) {
      dispatch(sharedFolderActions.getSharedFoldersAction())
    }
  }, [isOnline])

  return (
    <Switch>
      {/* Viewer */}
      <Route exact path="/folders" component={DNAMyFoldersSwitcher} />
      <Route exact path="/folders/:id" component={DNAMyFoldersSwitcher} />
      <Route exact path="/folders/:id/:idd" component={DNAMyFoldersSwitcher} />
      <Route exact path="/shared_folders" component={DNAMyFoldersSwitcher} />
      <Route exact path="/shared_folders/:id" component={DNAMyFoldersSwitcher} />
      <Route exact path="/shared_folders/:id/:idd" component={DNAMyFoldersSwitcher} />
      {/* publisher */}
      <Route exact path="/publisher_folders" component={DNAMyFoldersSwitcher} />
      <Route exact path="/publisher_folders/:id" component={DNAMyFoldersSwitcher} />
      <Route exact path="/publisher_folders/:id/:idd" component={DNAMyFoldersSwitcher} />
      <Route exact path="/publisher_shared_folders" component={DNAMyFoldersSwitcher} />
      <Route exact path="/publisher_shared_folders/:id" component={DNAMyFoldersSwitcher} />
      <Route exact path="/publisher_shared_folders/:id/:idd" component={DNAMyFoldersSwitcher} />
    </Switch>
  )
}

export default withDNAFolderNav(
  NestedRoutes,
  // TODO: Suggest updating this to filter shared dynamically based on selected tab
)
