import {
  VirtualSession,
  VirtualAttendee,
  Page,
} from '@alucio/aws-beacon-amplify/src/models'
import { AssociatedFileORM, DocumentVersionORM, ORMTypes } from './orms'
import { PresentableModelORM } from 'src/state/context/ContentProvider/ContentProvider';

// [TODO-297]: We should make extendable interfaces for easier type composition

export enum VERSION_UPDATE_STATUS {
  NOT_PUBLISHED = 'NOT_PUBLISHED',
  CURRENT = 'CURRENT',
  PENDING_MAJOR = 'MAJOR',
  PENDING_MINOR = 'MINOR',
}
export interface PageExtended extends Page {
  documentVersion?: DocumentVersionORM;
  groupId?: string;
}

export interface VirtualSessionWithAttendees extends VirtualSession {
  attendees: VirtualAttendee[]
}

export interface EntityWithFiles {
  model: {
    id: string,
    documentId?: string
  },
  relations: {
    associatedFiles: AssociatedFileORM[],
  },
}

export interface PresentablePage {
  id: string,
  page: Page,
  presentationPageNumber: number,
  documentVersion: DocumentVersionORM,
  presentableGroup: PresentableGroup
}

export interface PresentableGroup {
  id: string,
  documentVersion: DocumentVersionORM,
  pages: PresentablePage[]
}

export interface Presentable {
  id: string,
  orm: PresentableModelORM,
  type: ORMTypes.DOCUMENT_VERSION | ORMTypes.FOLDER_ITEM,
  title: string,
  numberOfPages: number,
  presentablePages: PresentablePage[],
  presentableGroups: PresentableGroup[],
}

export * from './enums'
export * from './featureFlags'
export * from './orms'
export * from './typeguards'
