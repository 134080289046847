import React, { useState, useEffect } from 'react';
import { Box, Popover, Icon, Iffy, RadioGroup, Text, RadioItem } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'

import { styles } from './Styles';

interface SelectInputProps {
  title: string;
  required?: boolean;
  values: string[];
  selectedValue?: string;
  onChangeSelection?: any;
  disabled?: boolean;
  disabledIndexes?: number[];
  descriptionText?: string;
}

const RadioInputComponent = React.forwardRef((props: SelectInputProps, ref) => {
  const {
    title,
    required = true,
    values,
    selectedValue,
    onChangeSelection,
    descriptionText,
    disabledIndexes = [],
    ...rest
  } = props;

  const propSelectedIndex = selectedValue ? values.indexOf(selectedValue) : undefined;
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(propSelectedIndex);
  useEffect(() => {
    if (selectedValue && selectedValue !== '') {
      const itemIndex = values.indexOf(selectedValue);
      setSelectedIndex(itemIndex);
    } else {
      setSelectedIndex(undefined);
    }
  }, [selectedValue, values]);

  const onChange = (idx: number) => {
    setSelectedIndex(idx);
    const value = values[idx];
    if (onChangeSelection) {
      onChangeSelection(value);
    }
  };
  return (
    <Box style={styles.RadioContainer} ref={ref}>
      <Box style={styles.TitleContainer}>
        <Box>
          <Text style={styles.required}>{required ? '*' : ''}</Text>
        </Box>
        <Box>
          <Text style={styles.Title}>{title}</Text>
        </Box>
        <Box>
          <Iffy is={descriptionText}>
            <Box>
              <Popover>
                <Popover.Anchor>
                  <Icon style={styles.HelpToolTipIconStyle} name="help-circle-outline" />
                </Popover.Anchor>
                <Popover.Content>
                  <Text style={{ color: 'white' }}>{descriptionText}</Text>
                </Popover.Content>
              </Popover>
            </Box>
          </Iffy>
        </Box>
      </Box>
      <RadioGroup.Kitten
        selectedIndex={selectedIndex}
        onChange={onChange}
        style={{ backgroundColor: colors['color-flat-200'] }}
      >
        {values.map((value: string, index: number) => (
          <RadioItem.Kitten
            key={value}
            {...rest}
            disabled={disabledIndexes.includes(index)}
            style={{ backgroundColor: 'rgba(0,0,0,0.0)' }}
          >
            {value}
          </RadioItem.Kitten>
        ))}
      </RadioGroup.Kitten>
    </Box>
  );
})

export default RadioInputComponent
