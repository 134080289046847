import React from 'react';
import {
  DNABox,
  InformationMessage,
} from '@alucio/lux-ui'

// Components
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import { useVersioningPanel } from './VersioningPanelContext';

const VersioningCriticalError = () => {
  const { documentORM } = useVersioningPanel()

  const docVersion = documentORM.relations.version.latestDocumentVersion

  return (
    <DNABox
      fill
      appearance="col"
      style={{ marginTop: 40, marginHorizontal: 40 }}
      spacing="medium"
    >
      <InformationMessage
        text="Invalid file. Please delete draft and upload new file."
        variance="danger"
        actionButtonText=""
      />
      <DNABox style={{ maxWidth: 159, maxHeight: 91 }}>
        <DNADocumentThumbnail
          documentVersionORM={docVersion}
          width={159}
          height={91}
          showProcessing={true}
        />
      </DNABox>
    </DNABox>
  )
}

export default VersioningCriticalError
