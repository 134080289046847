import React from 'react';

import {
  Box,
  DNABox,
  DNACheckbox,
  DNAText,
  Icon,
  Iffy,
  Popover,
} from '@alucio/lux-ui'
import { styles } from 'src/components/Publishers/Styles';
import { useVersioningPanel } from './VersioningPanelContext';
import { NotificationScope } from '@alucio/aws-beacon-amplify/src/models';
import { useLDClient } from 'launchdarkly-react-client-sdk'

const DocumentReleaseNotes = () => {
  const { currentDocumentVersionORM } = useVersioningPanel()
  const client = useLDClient()
  const isEmailNotificationEnabled = client?.variation('beac-1535-msl-email-alerts', false)

  return (
    <DNABox
      fill
      appearance="col"
      spacing="large"
      style={styles.tabContentWrapper}
    >
      <Iffy is={isEmailNotificationEnabled}>
        <DNABox
          testID="notify-users-email-checkbox"
          childFill={1}
          spacing="small"
        >
          <DNACheckbox
            testID={true ? 'checkbox-checked' : 'checkbox-unchecked'}
            checked={currentDocumentVersionORM.model.notificationScope === NotificationScope.EMAIL}
            onChange={() => { }}
            disabled
          />
          <Box style={styles.TitleContainer}>
            <DNAText
              numberOfLines={3}
              ellipsizeMode="tail"
              bold
            >
              Notify users of new versions via email
            </DNAText>
            <Popover>
              <Popover.Anchor>
                <Icon style={styles.HelpToolTipIconStyle} name="help-circle-outline" />
              </Popover.Anchor>
              <Popover.Content>
                <DNAText style={{ color: 'white' }}>
                  Your audience will receive an email notification if this file exists in one of their folders.
                </DNAText>
              </Popover.Content>
            </Popover>
          </Box>
        </DNABox>
      </Iffy>
      <DNABox style={{ maxWidth: 560 }} appearance="col" spacing="small">
        <DNAText bold>Revision Level</DNAText>
        <DNAText>
          {currentDocumentVersionORM.model.changeType === 'MAJOR' ? 'Major Change' : 'Minor Change'}
        </DNAText>
      </DNABox>
      <DNABox style={{ maxWidth: 560 }} appearance="col" spacing="small">
        <DNAText bold>Release Notes</DNAText>
        <DNAText>
          {currentDocumentVersionORM.model.releaseNotes}
        </DNAText>
      </DNABox>
    </DNABox>
  )
}

export default DocumentReleaseNotes
