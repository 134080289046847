import React, { useMemo } from 'react';
import MyContentPanel from 'src/components/MyContentPanel/MyContentPanel';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';

const BrowseContent = () => {
  const { addPresentation } = useContent();
  const MemoizedPanel = useMemo(() => (
    <MyContentPanel
      variant="Meetings"
      displayContentPanel={true}
      onSelectPresentableORM={addPresentation}
    />
  ), []);

  return MemoizedPanel;
};

BrowseContent.displayName = 'BrowseContent';

export default BrowseContent;
