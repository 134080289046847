import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Icon, luxColors } from '@alucio/lux-ui';
import { ValidationType } from './PasswordChecker';

const styles = StyleSheet.create({
  checkIcon: {
    color: luxColors.toastIcon.success,
    height: 17,
    marginRight: 7,
    width: 17,
  },
  disabledColor: {
    color: luxColors.basicBlack.secondary,
  },
  headerText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 16,
    marginTop: 20,
  },
  row: {
    flexDirection: 'row',
  },
  securityItem: {
    alignItems: 'center',
    marginBottom: 5,
  },
  securityItemText: {
    color: luxColors.text.secondary,
    fontSize: 13,
  },
  securityWrapper: {
    flex: 1,
    flexDirection: 'column',
  },
});

interface Props {
  passwordValidations: ValidationType;
  headerTextColor?: string;
  headerText?: string;
}

const PasswordCriterias = (props: Props) => {
  const { hasEightCharacters, hasNumber, hasUppercase, hasLowercase, matchingPasswords } = props.passwordValidations;

  return (
    <>
      <Text style={[styles.headerText, { color: props.headerTextColor || luxColors.text.primary }]}>
        {props.headerText || 'Password criteria:'}
      </Text>
      <View style={styles.row}>
        <View style={styles.securityWrapper}>
          <View style={[styles.row, styles.securityItem]}>
            <Icon style={[styles.checkIcon, !hasUppercase && styles.disabledColor]} name="check-circle" />
            <Text testID="password-criteria" style={styles.securityItemText}>An uppercase letter</Text>
          </View>
          <View style={[styles.row, styles.securityItem]}>
            <Icon style={[styles.checkIcon, !hasLowercase && styles.disabledColor]} name="check-circle" />
            <Text testID="password-criteria" style={styles.securityItemText}>A lowercase letter</Text>
          </View>
          <View style={[styles.row, styles.securityItem]}>
            <Icon style={[styles.checkIcon, !matchingPasswords && styles.disabledColor]} name="check-circle" />
            <Text testID="password-criteria" style={styles.securityItemText}>Passwords need to match</Text>
          </View>
        </View>
        <View style={styles.securityWrapper}>
          <View style={[styles.row, styles.securityItem]}>
            <Icon style={[styles.checkIcon, !hasNumber && styles.disabledColor]} name="check-circle" />
            <Text testID="password-criteria" style={styles.securityItemText}>A number</Text>
          </View>
          <View style={[styles.row, styles.securityItem]}>
            <Icon style={[styles.checkIcon, !hasEightCharacters && styles.disabledColor]} name="check-circle" />
            <Text testID="password-criteria" style={styles.securityItemText}>At least 8 characters</Text>
          </View>
        </View>
      </View>
    </>
  );
};

export default PasswordCriterias;
