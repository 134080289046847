import React from 'react';
import { View } from 'react-native'
import { Route, Switch, Redirect } from './index';

import DNAMSLHome from 'src/screens/Home/DNAMSL/DNAMSLHome'
import DNAMyFolders from 'src/screens/MyFolders/DNAMyFolders'
import DNALibrary from 'src/screens/MyLibrary/DNALibrary'
import DNABookmarks from 'src/screens/Bookmarks/DNABookmarks'
import DNANotifications from 'src/screens/Notifications/Notifications'
import DNAMeetingsHistory from 'src/screens/MeetingsHistory/DNAMeetingsHistory'
import Profile from 'src/screens/Profile/Profile';

import Playground from '../screens/Playground';
import Reports from '../screens/Reports/Reports';
import Documents from '../screens/Documents';
import Tenants from '../screens/Tenants/Tenants';
import EmailTemplates from '../screens/EmailTemplates/EmailTemplates';
import Virtual from '../screens/Virtual/Virtual';
import Meetings from '../screens/Meetings/Meetings';
import FAQ from 'src/screens/FAQ/FAQ'
import SyncStatusDebug from 'src/screens/Debug/SyncStatus'

import { useCurrentUser } from 'src/state/redux/selector/user';
import { UserRole } from '@alucio/aws-beacon-amplify/src/models';
import NotFound from 'src/components/DNA/Error'

import ROUTES from './routeDef'
import { useHistory } from 'react-router';
import PublisherSearchResults from 'src/screens/PublisherSearchResults/PublisherSearchResults';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import DNAMeetingsHistoryV2 from 'src/screens/MeetingsHistory/DNAMeetingsHistoryV2';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag';
import { FeatureFlags } from 'src/types/featureFlags';
import PopoutContent from 'src/screens/Meetings/PopoutContent/PopoutContent';

export { ROUTES } from './routeDef'
export type { DNARoute, PAGES } from './routeDef'

const ProtectedRoute = ({ allowedRoles, component: Component, onInvalidRedirectTo, ...rest }) => {
  // [TODO] Make this more configurable later, currently a workaround for performance
  const { userProfile: user } = useCurrentUser();
  const canUserAccess = allowedRoles.includes(user?.role);

  return (
    <Route
      {...rest}
      render={(props) => (
        canUserAccess
          ? <Component {...props} />
          : <Redirect to={onInvalidRedirectTo} />
      )}
    />
  )
}
// [TODO]: This is a workaround to improve perceived smoothness
//  This is due to slow first render times on new pages
//  Most likely due to UI Kitten :(
const PerformanceWorkaroundRoute = () => {
  return (
    <View style={{ flex: 1 }} />
  )
}

const Routes = () => {
  const LDClient = useLDClient()
  const beac1849EnablePublisherReports = LDClient?.variation('beac-1849-enable-publisher-reports', false)
  const disableVirtual = LDClient?.variation('disable-virtual', false)
  const disablePublisherFolder = LDClient?.variation('beac-2327-disable-publisher-folder', true)
  const enableSharedFolders = LDClient?.variation('enable-shared-folders', false)
  const beac1498ThirdParty  = useFeatureFlag(FeatureFlags.BEAC_1498_third_party_conferencing_integration);

  return (
    <Switch>
      {/* Currently Home only pertains to the MSL, but could be different in the future */}
      <Route exact path={ROUTES.HOME.PATH} component={DNAMSLHome} />
      <ProtectedRoute
        path={ROUTES.PUBLISHER_DASHBOARD.PATH}
        component={Documents}
        onInvalidRedirectTo={'/'}
        allowedRoles={ROUTES.PUBLISHER_DASHBOARD.ALLOWED_ROLES}
      />
      <ProtectedRoute
        path={ROUTES.TENANTS.PATH}
        component={Tenants}
        onInvalidRedirectTo={'/'}
        allowedRoles={ROUTES.TENANTS.ALLOWED_ROLES}
      />
      <ProtectedRoute
        path={ROUTES.INTEGRATIONS.PATH}
        component={Tenants}
        onInvalidRedirectTo={'/'}
        allowedRoles={ROUTES.INTEGRATIONS.ALLOWED_ROLES}
      />
      <ProtectedRoute
        path={ROUTES.EMAIL_TEMPLATES.PATH}
        component={EmailTemplates}
        onInvalidRedirectTo={'/'}
        allowedRoles={ROUTES.EMAIL_TEMPLATES.ALLOWED_ROLES}
      />
      <ProtectedRoute
        path={ROUTES.REPORTS.PATH}
        component={beac1849EnablePublisherReports ? Reports : NotFound}
        onInvalidRedirectTo={'/'}
        allowedRoles={ROUTES.REPORTS.ALLOWED_ROLES}
      />
      <ProtectedRoute
        path={ROUTES.SEARCH_RESULTS_PUBLISHER.PATH}
        component={PublisherSearchResults}
        onInvalidRedirectTo={'/'}
        allowedRoles={ROUTES.SEARCH_RESULTS_PUBLISHER.ALLOWED_ROLES}
      />
      <Route path={ROUTES.PLAYGROUND.PATH} component={Playground} />
      <Route path={ROUTES.LIBRARY.PATH} component={DNALibrary} />
      <Route path={ROUTES.BOOKMARKS.PATH} component={DNABookmarks} />
      <Route path={ROUTES.MEETING_HISTORY.PATH} component={!disableVirtual ? DNAMeetingsHistory : NotFound} />
      <Route path={ROUTES.MEETING_HISTORY_V2.PATH} component={beac1498ThirdParty ? DNAMeetingsHistoryV2 : NotFound}/>
      <Route path={ROUTES.FOLDERS.PATH} component={DNAMyFolders} />
      <Route path={ROUTES.SHARED_FOLDERS.PATH} component={enableSharedFolders ? DNAMyFolders : NotFound} />
      <Route path={ROUTES.PUBLISHER_FOLDERS.PATH} component={!disablePublisherFolder ? DNAMyFolders : NotFound} />
      <Route
        path={ROUTES.PUBLISHER_SHARED_FOLDERS.PATH}
        component={enableSharedFolders ? DNAMyFolders : NotFound}
      />
      {/* We're using DNALibrary to handle both search and non-search views. We will probably refactor this later in the future. */}
      <Route
        path={ROUTES.SEARCH_RESULTS.PATH}
        component={() => <DNALibrary initUserDefaultFilters={false} />}
      />
      <Route path={ROUTES.VIRTUAL.PATH} component={!disableVirtual ? Virtual : NotFound} />
      <Route path={ROUTES.MEETING_PRESENTATION.PATH} component={beac1498ThirdParty ? Meetings : NotFound} />
      <Route
        path={ROUTES.MEETING_POPOUT_CONTENT.PATH}
        component={beac1498ThirdParty
          ? PopoutContent
          : NotFound}
      />
      <Route path={ROUTES.FAQ.PATH} component={FAQ} />
      <Route path={ROUTES.NOTIFICATIONS.PATH} component={DNANotifications} />
      <Route path={ROUTES.PROFILE.PATH} component={Profile} />
      <Route path={ROUTES.SYNC_STATUS_DEBUG.PATH} component={SyncStatusDebug} />
      <Route path="/." component={PerformanceWorkaroundRoute} />
      <Route path={ROUTES.NOT_FOUND.PATH} component={NotFound} />
      <Route component={NotFound} />
    </Switch>
  );
};

export const RoutesListener = () => {
  const history = useHistory();
  const currentUser = useCurrentUser();

  if (history.location.pathname === '/' && currentUser?.userProfile?.role === UserRole.ALUCIO_ADMIN) {
    history.push('/tenants');
  }

  analytics.page(history.location.pathname);

  return null;
};

export default Routes;
