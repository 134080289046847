import { createReducer, createAction } from '@reduxjs/toolkit'
import { RootState } from 'src/state/redux/rootReducer'
import { datastoreSave } from 'src/state/redux/slice/common'

import { Document, DocumentVersion } from '@alucio/aws-beacon-amplify/src/models'
import { DocumentVersionORM } from 'src/types/orms'

const initialState = { } as RootState

export const multiSliceActions = {
  deleteDocumentDraft: createAction<DocumentVersionORM>('multiSlice/deleteDocumentDraft'),
}

export const multiSliceReducer = createReducer(
  initialState,
  builder => {
    builder.addCase(
      multiSliceActions.deleteDocumentDraft,
      (state, action) => {
        const documentVersionORM  = action.payload
        const parentDocumentORM = documentVersionORM.relations.document
        const isVersionPublished = documentVersionORM.model.status === 'PUBLISHED'
        const isDocumentPublished = parentDocumentORM.model.status === 'PUBLISHED'

        if (isVersionPublished)
        { throw new Error('Cannot delete Published document version') }

        if (!isDocumentPublished) {
          datastoreSave(
            Document,
            parentDocumentORM.model,
            { status: 'DELETED' },
          )

          return state
        }

        if (!isVersionPublished && isDocumentPublished) {
          datastoreSave(
            DocumentVersion,
            documentVersionORM.model,
            { status: 'DELETED' },
          )

          return state
        }

        throw new Error(`Could not determine deletion status for DocumentVersion ${documentVersionORM.model.id}`)
      },
    )
  },
)
