import { DocumentVersion } from '@alucio/aws-beacon-amplify/src/models'
import { DocumentVersionORM } from 'src/types/types'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'

import store from 'src/state/redux/store'
import { allDocumentsSortedAndFilteredFactory } from 'src/state/redux/selector/document'

interface VersionChanges {
  isMajorVersionRequired: boolean,
  isMajorVersionProposed: boolean,
}

/**
 * We cannot pass DocumentORMs around via the context or through events
 * due to it not being serializable (circular structure)
 *
 * Instead, we use Redux is a plain JS way with selectors to grab the latest ORM
 * at any given time
 */

export const getDocumentORMFactory = (id: string) => {
  const documentSelector = allDocumentsSortedAndFilteredFactory()
  const filter = { filter: { model: { id } } }

  return () => {
    const [targetDoc] = documentSelector(
      store.getState(),
      undefined,
      filter,
    )

    if (!targetDoc) throw new Error('Target Document not found')
    return targetDoc
  }
}

export const omitInternalFields = (docVer: Partial<DocumentVersion>) => {
  return omit(docVer, 'versionNumber', '_version', '_lastChangedAt', '_deleted', 'contentSource')
}

/**
 * Compares a working version draft against a previously published version
 * to determine what type of SemVer change should be applied
 */
export const determineSemVerChange = (
  latestDocumentVersion: Partial<DocumentVersion>,
  latestPublishedVersion?: DocumentVersionORM,
): VersionChanges => {
  const minorChange = {
    isMajorVersionRequired: false,
    isMajorVersionProposed: false,
  };

  const majorSuggestedChange = {
    isMajorVersionRequired: false,
    isMajorVersionProposed: true,
  }

  const majorRequiredChange = {
    isMajorVersionRequired: true,
    isMajorVersionProposed: true,
  }

  const draftPages = latestDocumentVersion?.pages
  const draftPageGroups = latestDocumentVersion?.pageGroups;
  const latestPublishedPages = latestPublishedVersion?.model.pages
  const latestPublishedPageGroups = latestPublishedVersion?.model.pageGroups

  // [NOTE] - This really shouldn't happen as pages should always be populated after file processing
  if (!latestPublishedPages || !draftPages) {
    return minorChange
  }

  // 1. A new document (first version to be published)
  //    We don't show this in the UI anyways, but might be nice to have later
  const isFirstDraft = latestDocumentVersion && !latestPublishedVersion
  if (isFirstDraft) {
    return majorRequiredChange
  }

  // 2. Draft version has fewer slides
  const draftVersionHasFewerSlides = draftPages.length < latestPublishedPages.length
  if (draftVersionHasFewerSlides) {
    return majorRequiredChange
  }

  // 3. Draft version grouping has changed due to one of
  //    a. amount of groups not matching (including not having any groups)
  //    b. slide indexes not matching
  const pageGroupLengthChanged = Number(draftPageGroups?.length) !== Number(latestPublishedPageGroups?.length)
  const draftPageGroupSlideIndexes = draftPageGroups
    ?.map(pageGroup => pageGroup.pageIds?.map(pageId => pageId.split('_')[2]))
  const publishedPageGroupSlideIndexes = latestPublishedPageGroups
    ?.map(pageGroup => pageGroup.pageIds?.map(pageId => pageId.split('_')[2]))
  const pageGroupIndexesChanged = !isEqual(draftPageGroupSlideIndexes, publishedPageGroupSlideIndexes)

  if (pageGroupLengthChanged || pageGroupIndexesChanged) {
    return majorRequiredChange
  }

  // 4. Required slides changed
  const draftRequiredSlides = draftPages?.map((page, idx) => ({ [idx]: !!page.isRequired }))
  const publishedRequiredSlides = latestPublishedPages?.map((page, idx) => ({ [idx]: !!page.isRequired }))
  // [NOTE] - We first compare the original range of slides for required changes
  const originalSlidesRequiredCheck = !isEqual(
    draftRequiredSlides.slice(0, publishedRequiredSlides.length),
    publishedRequiredSlides,
  )
  // [NOTE] - If any new additional slides have required, then flag for major change
  const additionalSlidesRequiredCheck = draftRequiredSlides
    .slice(publishedRequiredSlides.length, draftRequiredSlides.length)
    .some(slide => Object.values(slide).some(required => required))

  if (originalSlidesRequiredCheck || additionalSlidesRequiredCheck) {
    return majorRequiredChange
  }

  // 5. Any changes to the "Purpose" field
  const didPurposeChange = latestDocumentVersion?.purpose !== latestPublishedVersion?.model.purpose
  if (didPurposeChange) {
    return majorRequiredChange
  }

  // 6. File is no longer modifiable
  const fileNoLongerModifiable = (
    (latestPublishedVersion?.model.canHideSlides === true) &&
    (!latestDocumentVersion.canHideSlides)
  )
  if (fileNoLongerModifiable) {
    return majorRequiredChange
  }

  // 7. Additional pages without other required/grouping changes
  if (draftPages.length > latestPublishedPages.length) {
    return majorSuggestedChange
  }

  // 8. Otherwise anything else is a minor change
  return minorChange;

  // 9. [TODO] - Preserve user's last selection if minor change?
}

export default {
  getDocumentORMFactory,
  omitInternalFields,
  determineSemVerChange,
}
