import { LDFlagValue } from 'launchdarkly-js-sdk-common'

/** NOTE: Naming convention using snake_case allows for cleaner
 * implementation here vs string enums using kebab-case */
enum FeatureFlagKeys {
  BEAC_2516_Enable_Document_Sharing_Templates,
  BEAC_1498_third_party_conferencing_integration,

  /** TODO: the items below were implemented prior to the typing and are still using kebab-case on launch darkly.
   * These items would need to be renamed/rebuilt on that service to take advantage of this typing implementation */
  beac_1849_enable_publisher_reports,
  disable_virtual,
  beac_2327_disable_publisher_folder,
  enable_shared_folders,
  beac_1535_msl_email_alerts,
  allow_ms_edge,
}
type FeatureFlagKey = keyof typeof FeatureFlagKeys

export interface FeatureFlag {
  key: FeatureFlagKey,
  defaultValue?: LDFlagValue
}

export const FeatureFlags: Record<FeatureFlagKey, FeatureFlag> = {
  BEAC_2516_Enable_Document_Sharing_Templates: {
    key: 'BEAC_2516_Enable_Document_Sharing_Templates',
    defaultValue: true,
  },

  BEAC_1498_third_party_conferencing_integration: {
    key: 'BEAC_1498_third_party_conferencing_integration',
    defaultValue: false,
  },

  /** TODO: the items below were implemented prior to the typing and are still using kebab-case on launch darkly.
     * These items would need to be renamed/rebuilt on that service to take advantage of this typing implementation */
  beac_1849_enable_publisher_reports: {
    key: 'beac_1849_enable_publisher_reports',
    defaultValue: false,
  },
  disable_virtual: {
    key: 'disable_virtual',
    defaultValue: false,
  },
  beac_2327_disable_publisher_folder: {
    key: 'beac_2327_disable_publisher_folder',
    defaultValue: true,
  },
  enable_shared_folders: {
    key: 'enable_shared_folders',
    defaultValue: false,
  },
  beac_1535_msl_email_alerts: {
    key: 'beac_1535_msl_email_alerts',
    defaultValue: false,
  },
  allow_ms_edge: {
    key: 'allow_ms_edge',
    defaultValue: true,
  },
}
