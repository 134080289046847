import {
  CustomDeck,
  CustomDeckGroup,
  CustomDeckPage,
  DocumentStatus,
  FolderItemType,
  Page,
} from '@alucio/aws-beacon-amplify/src/models';
import isEqual from 'lodash/isEqual'
import React, { createContext, useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import ClearSlides from 'src/components/DNA/Modal/DNAPresentationBuilder/ClearSlides'
import useEditorState from './useEditorState';
import RequiredSlidesHiddenConfirmation
  from 'src/components/DNA/Modal/DNAPresentationBuilder/RequiredSlidesHiddenConfirmation';
import CloseConfirmation from 'src/components/DNA/Modal/DNAPresentationBuilder/CloseConfirmation';
import { presentationBuilderActions } from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder';
import { folderActions } from 'src/state/redux/slice/folder';
import useSelectorState from './useSelectorState';
import { ThumbnailMode, ThumbnailSize } from 'src/hooks/useThumbnailSize/useThumbnailSize';
import { RootState } from 'src/state/redux/store';
import {
  CustomDeckGroupORM,
  CustomDeckORM,
  DocumentVersionORM,
  FolderItemORM,
  ORMTypes,
  VERSION_UPDATE_STATUS,
} from 'src/types/types';
import { useCustomDeckORMById } from 'src/state/redux/selector/folder';
import { v4 as uuid } from 'uuid';
import defer from 'lodash/defer';
import { contentPreviewModalActions } from '../../../state/redux/slice/contentPreviewModal';

/** These options represent the different 'modes' this component can be in. The default is 'selection' mode */
enum BuilderModes {
  selection,
  customization,
  replace,
}
export type BuilderMode = keyof typeof BuilderModes

/** State properties which can be used by various sub-components to manipulate the current in-progress presentation */
export interface PresentationBuilderStateType {
  clearSlides: () => void
  closePresentationBuilder: (save?: boolean) => void
  savePresentation: () => void
  builderMode: BuilderMode
  setBuilderMode: React.Dispatch<React.SetStateAction<BuilderMode>>
  title: string | undefined
  setTitle: React.Dispatch<React.SetStateAction<string | undefined>>
  wasSavedWithoutTitle: boolean
  setWasSavedWithoutTitle: React.Dispatch<React.SetStateAction<boolean>>
  numOfRequiredSlides: number,
  selectedGroups: PayloadGroup[]
  setSelectedGroups: React.Dispatch<React.SetStateAction<PayloadGroup[]>>
  hiddenSlidesVisible: boolean
  setHiddenSlidesVisible: React.Dispatch<React.SetStateAction<boolean>>
  displayPageSourceFile: boolean
  setDisplayPageSourceFile: React.Dispatch<React.SetStateAction<boolean>>
  handleModeChange: (nextMode?: BuilderMode) => void
  editorThumbnailMode: ThumbnailMode
  setEditorThumbnailMode: React.Dispatch<React.SetStateAction<ThumbnailMode>>
  toggleEditorThumbnailMode: () => void
  editorThumbnailSize: ThumbnailSize
  selectorThumbnailMode: ThumbnailMode
  setSelectorThumbnailMode: React.Dispatch<React.SetStateAction<ThumbnailMode>>
  toggleSelectorThumbnailMode: () => void,
  selectorThumbnailSize: ThumbnailSize,
  onPreview: () => void,
  cancelPresentation: () => void,
  activeReplacementGroup?: PayloadGroup,
  setActiveReplacementGroup: React.Dispatch<React.SetStateAction<PayloadGroup | undefined>>,
  onApplyFindAndReplace: (payloadGroups: PayloadGroup[], newTitle: string) => void,
  customDeck?: CustomDeckORM,
}

export interface PayloadGroup {
  id: string;
  groupSrcId?: string;
  documentVersion?: DocumentVersionORM;
  pages: Page[];
  visible: boolean;
  groupStatus: GroupStatus,
}

export enum GroupStatus {
  MAJOR_UPDATE = 'MAJOR_UPDATE',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
  REVOKED = 'REVOKED',
  ACTIVE = 'ACTIVE',
}

/** Type defining config props for the <PresentationBuilderStateProvider> element */
interface PresentationBuilderStateProviderProps {
}

/** Context instantiation/config */
export const PresentationBuilderStateContext = createContext<PresentationBuilderStateType>(null!)
PresentationBuilderStateContext.displayName = 'PresentationBuilderContext'

/**
 * Identify the current status of the document, as an example if the documente should be removed or be replaced by another one
 * @param documentVersion
 * @returns MAJOR_UPDATE, DELETED, ARCHIVED, ACTIVE
 */
export const getPayloadGroupStatus = (documentVersion?: DocumentVersionORM): GroupStatus => {
  if (!documentVersion) return GroupStatus.DELETED;

  const isMajor = documentVersion?.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MAJOR
  const isNotPublished = documentVersion?.meta.version.updateStatus === VERSION_UPDATE_STATUS.NOT_PUBLISHED

  if (isMajor) {
    return GroupStatus.MAJOR_UPDATE
  }
  else if (isNotPublished) {
    const status = documentVersion?.relations.document.model.status as DocumentStatus
    if ([DocumentStatus.ARCHIVED].includes(status!)) {
      return GroupStatus.ARCHIVED
    }
    else if ([DocumentStatus.REVOKED].includes(status!)) {
      return GroupStatus.REVOKED
    }
    else if ([DocumentStatus.DELETED].includes(status!)) {
      return GroupStatus.DELETED
    }
    // Not sure if is a real posibility
    else {
      return GroupStatus.ACTIVE;
    }
  }
  else {
    return GroupStatus.ACTIVE
  }
}

/* When saving, convert our draft object into a CustomDeckGroup[] */
function payloadGroupsToCustomDeckGroup(
  draftGroups: PayloadGroup[],
  existingCustomDeck?: CustomDeckORM,
): CustomDeckGroup[] {
  return draftGroups.map((draftGroup) => {
    let pages: CustomDeckPage[] = [];

    if (draftGroup.groupStatus === GroupStatus.DELETED) {
      // IF THE DOCUMENT WAS DELETED (MEANING THAT THE PAGES ARE EMPTY)
      // WE WANT TO KEEP THE REFERENCES AS THEY'RE IN THE CUSTOMDECK SO THEY'RE STILL
      // SHOWN AS REQUIRED TO BE MANUALLY REMOVED
      const savedGroup = existingCustomDeck?.model.groups.find(({ id }) => id === draftGroup.id);
      pages = savedGroup?.pages || [];
    } else {
      pages = draftGroup.pages.map((page) => ({
        pageId: page.pageId,
        pageNumber: page.number,
        documentVersionId: draftGroup.documentVersion?.model.id || '',
      }))
    }

    return {
      id: draftGroup.id,
      srcId: draftGroup.groupSrcId ?? draftGroup.documentVersion?.model.id,
      pages,
      visible: draftGroup.visible,
    };
  });
}

/* FUNCTIONS TO CREATE A DRAFT FOLDERITEMORM TO USE THE CUSTOM DECK IN THE PLAYER */
function toCustomDeckORMToPreview(groups: PayloadGroup[], customDeck?: CustomDeckORM): CustomDeckORM {
  const groupORMs: CustomDeckGroupORM[] = [];
  const validGroups = groups.filter(({ groupStatus }) => groupStatus === GroupStatus.ACTIVE);
  const customDeckGroups: CustomDeckGroup[] = payloadGroupsToCustomDeckGroup(validGroups, customDeck);
  const now = new Date().toISOString();

  validGroups.forEach((group) => {
    const customDeckGroup = customDeckGroups.find(({ id }) => id === group.id) as CustomDeckGroup;

    groupORMs.push({
      isGroup: group.pages.length > 1,
      model: customDeckGroup,
      pages: group.pages.map((page) => ({
        documentVersionORM: group.documentVersion!,
        page,
        model: {
          documentVersionId: group.documentVersion?.model.id!,
          pageNumber: page.number,
          pageId: page.pageId,
        },
      })),
      meta: {
        version: {
          updateStatus: VERSION_UPDATE_STATUS.CURRENT,
        },
      },
    });
  });

  return {
    model: {
      id: uuid(),
      createdAt: now,
      updatedAt: now,
      createdBy: '',
      autoUpdateAcknowledgedAt: now,
      updatedBy: '',
      tenantId: '',
      groups: customDeckGroups,
    },
    type: ORMTypes.CUSTOM_DECK,
    meta: {
      groupsORM: groupORMs,
      assets: {
        isContentCached: false,
      },
      version: {
        updateStatus: VERSION_UPDATE_STATUS.CURRENT,
        requiresReview: false,
        autoUpdateUnacknowledged: false,
      },
    },
  };
}

/* CREATES A DRAFT FOLDER ITEM SO WE CAN SEND IT TO THE CONTENT PREVIEW MODAL AS A PREVIEW OF THE NEW CUSTOM DECK */
function toFolderItemORM(
  selectedGroups: PayloadGroup[],
  customTitle?: string, customDeckORM?: CustomDeckORM,
  folderItemORM?: FolderItemORM): FolderItemORM {
  const draftCustomDeckORM = toCustomDeckORMToPreview(selectedGroups, customDeckORM);

  if (folderItemORM) {
    return {
      ...folderItemORM,
      meta: {
        assets: { },
        hasAutoUpdatedItem: false,
        hasOutdatedItem: false,
      },
      relations: {
        item: draftCustomDeckORM,
      },
    }
  }

  const now = new Date().toISOString();

  return {
    model: {
      id: uuid(),
      customTitle: customTitle || 'Presentation Preview',
      type: FolderItemType.CUSTOM_DECK,
      itemId: uuid(),
      itemLastUpdatedAt: now,
      addedAt: now,
    },
    meta: {
      assets: { },
      hasAutoUpdatedItem: false,
      hasOutdatedItem: false,
    },
    type: ORMTypes.FOLDER_ITEM,
    relations: {
      item: draftCustomDeckORM,
    },
  };
}

/** Provider instantiation/config */
const PresentationBuilderStateProvider: React.FC<PresentationBuilderStateProviderProps> = (props) => {
  const globalBuilderState = useSelector((state: RootState) => state.PresentationBuilder);
  const { targetFolder, folderItemORM, customDeckId } = globalBuilderState;
  const existingCustomDeck = useCustomDeckORMById(customDeckId || '');
  const [builderMode, setBuilderMode] = useState<BuilderMode>(existingCustomDeck ? 'customization' : 'selection');
  const [title, setTitle] = useState<string | undefined>(folderItemORM?.model.customTitle ?? '')
  const [wasSavedWithoutTitle, setWasSavedWithoutTitle] = useState<boolean>(false)
  const [numOfRequiredSlides, setNumOfRequiredSlides] = useState<number>(0)
  const initialTitleRef = useRef(title)
  const [selectedGroups, setSelectedGroups] = useState<PayloadGroup[]>(
    existingCustomDeck
      ? toCustomDeckPayloadGroups(existingCustomDeck)
      : [],
  );
  const initialSelectedGroupRef = useRef(selectedGroups)
  const [hiddenSlidesVisible, setHiddenSlidesVisible] = useState<boolean>(true)
  const [displayPageSourceFile, setDisplayPageSourceFile] = useState<boolean>(true);
  const [activeReplacementGroup, setActiveReplacementGroup] = useState<PayloadGroup | undefined>();
  const dispatch = useDispatch()

  const {
    editorThumbnailMode,
    setEditorThumbnailMode,
    toggleEditorThumbnailMode,
    editorThumbnailSize,
  } = useEditorState()

  const {
    selectorThumbnailMode,
    setSelectorThumbnailMode,
    toggleSelectorThumbnailMode,
    selectorThumbnailSize,
  } = useSelectorState()

  /** CONTEXT UTILITIES */
  const handleModeChange = (nextMode?: BuilderMode) => {
    // from selection mode or replace mode user can only go to customization mode
    // from customization mode user can either go to selection or replace depending on the button clicked
    setBuilderMode((p) => {
      if (nextMode) {
        return nextMode
      } else if (p === 'selection' || p === 'replace') {
        return 'customization'
      } else {
        // this is a catch all where the user is sent to the default mode
        return 'selection'
      }
    })
  }

  const clearSlides = () => {
    selectedGroups.length &&
      dispatch(
        DNAModalActions.setModal(
          {
            isVisible: true,
            allowBackdropCancel: true,
            component: (props) => (
              <ClearSlides
                {...props}
                onClear={() => setNumOfRequiredSlides(0)}
                setSelectedGroups={setSelectedGroups}
              />
            ),
          },
        ),
      )
  }

  const onPreview = (): void => {
    const draftFolderItemORM = toFolderItemORM(selectedGroups, title, existingCustomDeck, folderItemORM);

    // TODO: add type guard and remove as assertion here once typings cleanup from #1708 has been merged
    dispatch(
      contentPreviewModalActions.setModalVisibility({
        documentVersionId:  (draftFolderItemORM.relations.item.model as CustomDeck)
          .groups[0]?.pages[0].documentVersionId,
        folderItemORM: draftFolderItemORM,
        isOpen: true,
      },
      ))
  };

  const savePresentation = () => {
    if (selectedGroups.length) {
      if (!title) {
        setWasSavedWithoutTitle(true);
        return;
      }
      const requiredHiddenSlides = selectedGroups.some((slide) => {
        return !slide.visible && slide.pages.some((page) => page.isRequired)
      })

      if (requiredHiddenSlides) {
        openRequiredHiddenSlidesConfirmation()
        return
      }

      createOrUpdatePresentation()
      setWasSavedWithoutTitle(false);
    }
  }

  const createOrUpdatePresentation = () => {
    if (existingCustomDeck) {
      folderItemORM &&
      targetFolder &&
      title &&
      dispatch(folderActions.updateCustomDeck(
        targetFolder,
        folderItemORM,
        existingCustomDeck.model,
        payloadGroupsToCustomDeckGroup(selectedGroups, existingCustomDeck),
        title,
      ))
    } else {
      targetFolder &&
      title &&
      dispatch(
        folderActions.createCustomDeck(
          targetFolder,
          payloadGroupsToCustomDeckGroup(selectedGroups, existingCustomDeck),
          title,
        ),
      )
    }

    closePresentationBuilder()
  }

  const cancelPresentation = () => {
    const hasChanges = !isEqual(initialSelectedGroupRef.current, selectedGroups) || initialTitleRef.current !== title
    if (hasChanges) {
      openCloseConfirmation()
      return
    }

    closePresentationBuilder()
  }

  const closePresentationBuilder = () => {
    dispatch(presentationBuilderActions.closePresentationBuilder())
  }

  const openRequiredHiddenSlidesConfirmation = () => {
    dispatch(
      DNAModalActions.setModal(
        {
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => (
            <RequiredSlidesHiddenConfirmation
              onSave={createOrUpdatePresentation}
              {...props}
            />
          ),
        },
      ),
    )
  }

  const onApplyFindAndReplace = (payloadGroups: PayloadGroup[], newTitle: string) => {
    setTitle(newTitle);
    setBuilderMode('customization')
    const { groups, numRequiredSlidesAdded } = validatePayloadGroups(payloadGroups)
    setSelectedGroups(groups)
    setNumOfRequiredSlides(numRequiredSlidesAdded)
  };

  const validatePayloadGroups = (
    payloadGroups: PayloadGroup[],
  ): {
    groups: PayloadGroup[],
    numRequiredSlidesAdded: number
  } => {
    const docVersionMap = new Map<string, DocumentVersionORM>();
    const addedPages = new Set<string>();
    const addedPageGroups = new Set<string>();
    let numAddedSlides = 0;

    // We only add required slides if the deck is in a valid state otherwise we let the user keep on editing
    if (!payloadGroups.every((group) => group.groupStatus === GroupStatus.ACTIVE)) {
      return {
        groups: payloadGroups,
        numRequiredSlidesAdded: 0,
      }
    }

    payloadGroups.forEach(group => {
      if (group.documentVersion) {
        docVersionMap.set(group.documentVersion!.model.id, group.documentVersion!)
        group.pages.forEach(page => addedPages.add(page.pageId))
        group.groupSrcId && addedPageGroups.add(group.groupSrcId)
      }
    })

    const newGroups: PayloadGroup[] = []

    // We iterate through all documents making sure required group/slides are already added
    docVersionMap.forEach((docVer) => {
      docVer.relations.pages.forEach(page => {
        if (page.model.isRequired) {
          if (page.relations.pageGroup && !addedPageGroups.has(page.relations.pageGroup.model.id)) {
            const srcDocGroup = page.relations.pageGroup
            // We need to add this group
            const pages = srcDocGroup.relations.pages.map(pageORM => {
              addedPages.add(pageORM.model.pageId)
              return pageORM.model;
            })
            newGroups.push({
              id: uuid(),
              groupSrcId: srcDocGroup.model.id,
              documentVersion: docVer,
              pages,
              visible: true,
              groupStatus: GroupStatus.ACTIVE,
            })
            numAddedSlides = numAddedSlides + pages.length
            addedPageGroups.add(srcDocGroup.model.id)
          } else if (!addedPages.has(page.model.pageId)) {
            addedPages.add(page.model.pageId)
            newGroups.push({
              id: uuid(),
              groupSrcId: docVer.model.id,
              documentVersion: docVer,
              pages: [page.model],
              visible: true,
              groupStatus: GroupStatus.ACTIVE,
            })
            numAddedSlides++
          }
        }
      })
    })
    return {
      groups: [...payloadGroups, ...newGroups],
      numRequiredSlidesAdded: numAddedSlides,
    }
  }

  const setSelectedGroupsWithValidation = (value: (PayloadGroup[] | ((current: PayloadGroup[]) => PayloadGroup[]))) => {
    if (typeof value === 'function') {
      setSelectedGroups((currValue) => {
        const result = validatePayloadGroups(value(currValue))
        defer(() => setNumOfRequiredSlides(result.numRequiredSlidesAdded))
        return result.groups
      })
    } else {
      const result = validatePayloadGroups(value as PayloadGroup[])
      setSelectedGroups(result.groups)
      defer(() => setNumOfRequiredSlides(result.numRequiredSlidesAdded))
    }
  }

  const onCloseHandler = () => dispatch(presentationBuilderActions.closePresentationBuilder())

  const openCloseConfirmation = () => {
    dispatch(
      DNAModalActions.setModal(
        {
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => <CloseConfirmation {...props} onClose={onCloseHandler} />,
        },
      ),
    )
  }

  /** If we're editing, we'll convert the CUSTOMDECKORM into our draft object **/
  function toCustomDeckPayloadGroups(customDeckORM: CustomDeckORM): PayloadGroup[] {
    return customDeckORM.meta.groupsORM.map((groupORM) => {
      const documentVersion = groupORM.pages[0].documentVersionORM;
      const groupStatus = getPayloadGroupStatus(documentVersion);

      return {
        id: groupORM.model.id,
        groupSrcId: groupORM.model.srcId,
        documentVersion,
        pages: groupORM.pages.map(({ page }) => page),
        visible: groupORM.model.visible,
        groupStatus,
      };
    });
  }

  const contextValue: PresentationBuilderStateType = {
    onApplyFindAndReplace,
    clearSlides,
    closePresentationBuilder,
    savePresentation,
    builderMode,
    setBuilderMode,
    title,
    setTitle,
    wasSavedWithoutTitle,
    setWasSavedWithoutTitle,
    numOfRequiredSlides,
    selectedGroups,
    setSelectedGroups: setSelectedGroupsWithValidation,
    hiddenSlidesVisible,
    setHiddenSlidesVisible,
    displayPageSourceFile,
    setDisplayPageSourceFile,
    handleModeChange,
    editorThumbnailMode,
    onPreview,
    setEditorThumbnailMode,
    toggleEditorThumbnailMode,
    editorThumbnailSize,
    selectorThumbnailMode,
    setSelectorThumbnailMode,
    toggleSelectorThumbnailMode,
    selectorThumbnailSize,
    cancelPresentation,
    activeReplacementGroup,
    setActiveReplacementGroup,
    customDeck: existingCustomDeck,
  }

  return (
    <PresentationBuilderStateContext.Provider value={contextValue}>
      {props.children}
    </PresentationBuilderStateContext.Provider>
  )
}

PresentationBuilderStateProvider.displayName = 'PresentationBuilderStateProvider'

/** Hook for utilizing presentation builder state values/methods. Also
 * handles guarding against using context outside of the provider */
export function usePresentationBuilderState() {
  const context = useContext(PresentationBuilderStateContext)
  if (!context) {
    throw new Error('usePresentationBuilderState must be used within the PresentationBuilderStateProvider')
  }
  return context;
}

export default PresentationBuilderStateProvider
