import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import { DNABox, DNACheckbox, DNAText, Popover } from '@alucio/lux-ui'
import { usePresentationBuilderState } from '../../state/PresentationBuilderStateProvider'
import { Action, usePresentationBuilderSharedResources } from '../PresentationEditor'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';

const style = StyleSheet.create({
  settings: {
    backgroundColor: colors['color-text-basic'],
    borderColor: colors['color-flat-400'],
    borderRadius: 4,
    borderWidth: 1,
    minWidth: 210,
    paddingVertical: 16,
    paddingHorizontal: 20,
    shadowColor: colors['color-flat-1200'],
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.16,
    shadowRadius: 11.22,
  },
});

const SecondaryActionButtons: React.FC<{disablePreview?: boolean}> = ({ disablePreview }) => {
  const { getActionEls } = usePresentationBuilderSharedResources()
  const {
    clearSlides,
    editorThumbnailMode,
    toggleEditorThumbnailMode,
    hiddenSlidesVisible,
    setHiddenSlidesVisible,
    displayPageSourceFile,
    setDisplayPageSourceFile,
    onPreview,
  } = usePresentationBuilderState()

  const [displaySettings, setDisplaySettings] = useState<boolean>(false);

  function toggleVisibilitySettings(): void {
    setDisplaySettings((val) => !val);
  }

  const actions:Action[] = [
    {
      disabled: disablePreview,
      iconLeft:'play',
      size:'xlarge',
      context:'minimum',
      status: disablePreview ? 'subtle' : undefined,
      tooltip:'Preview',
      onPress: onPreview,
    },
    {
      iconLeft:'trash-can-outline',
      size:'xlarge',
      context:'minimum',
      tooltip:'Clear all',
      onPress: clearSlides,
    },
    {
      iconLeft: editorThumbnailMode === 'large' ? 'view-comfy' : 'view-grid',
      size:'xlarge',
      context:'minimum',
      tooltip:'Toggle thumbnail size',
      onPress: toggleEditorThumbnailMode,
    },
    {
      iconLeft: 'cog',
      size:'xlarge',
      context:'minimum',
      tooltip:'Settings',
      onPress : toggleVisibilitySettings,
    },
  ];
  const actionEls = getActionEls(actions);
  const settingsIcon = actionEls.pop();

  return (
    <DNABox spacing="small">
      {actionEls}
      <Popover
        lazyMount
        placement="bottom"
        interactive={true}
        visible={displaySettings}
        onBackdropPress={toggleVisibilitySettings}
        type="menu"
      >
        <Popover.Anchor>
          {settingsIcon}
        </Popover.Anchor>
        <Popover.Content>
          <DNABox spacing="small" appearance="col" style={style.settings}>
            <DNABox spacing="small">
              <DNACheckbox checked={displayPageSourceFile} onChange={setDisplayPageSourceFile}/>
              <DNAText>Show source file name</DNAText>
            </DNABox>
            <DNABox spacing="small">
              <DNACheckbox checked={hiddenSlidesVisible} onChange={setHiddenSlidesVisible} />
              <DNAText>Show hidden slides</DNAText>
            </DNABox>
          </DNABox>
        </Popover.Content>
      </Popover>
    </DNABox>
  )
};

export default React.memo(SecondaryActionButtons);
