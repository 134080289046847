import {
  Document,
  DocumentStatus,
  DocumentVersion,
  EmailTemplate,
  LabelValues,
} from '@alucio/aws-beacon-amplify/src/models'

// this functions returns true if doc/email template satisfies the lockedFilters
export function matchesLockedFilters(lockedFilters: LabelValues[]): (obj: DocumentVersion | EmailTemplate) => boolean {
  return function (obj: DocumentVersion | EmailTemplate): boolean {
    if (lockedFilters.length < 1) { return true }
    for (const lockedFilter of lockedFilters) {
      let compliant = false
      let hasKeyDefined = false;
      for (const label of obj.labelValues || []) {
        if (lockedFilter.key === label.key) {
          hasKeyDefined = true
          compliant = compliant || lockedFilter.values.includes(label.value)
        }
      }
      if (!compliant && hasKeyDefined) { return false }
    }
    return true
  }
}

interface DocVersionRecord {
  versions: DocumentVersion[],
  document: Document
}

// [TODO-REDUX] - We should revisit this whenever we move redux to a key based object instead of array of records
export function filterDocumentsForDeletedAndLockedFilters(
  lockedFilters: LabelValues[],
  documents: Document[],
  versions: DocumentVersion[]): { documents: Document[], versions: DocumentVersion[] } {
  const initVersionLookup = documents.reduce<Record<string, DocVersionRecord>>((acc, doc) => {
    if (doc.status !== DocumentStatus.DELETED) {
      acc[doc.id] = {
        versions: [],
        document: doc,
      }
    }
    return acc
  }, {})
  const versionLookup = versions.reduce<Record<string, DocVersionRecord>>((acc, currVersion) => {
    if (acc[currVersion.documentId] && currVersion.status !== DocumentStatus.DELETED) {
      acc[currVersion.documentId].versions = [...acc[currVersion.documentId].versions, currVersion]
    }
    return acc
  }, initVersionLookup)
  const matchFunction = matchesLockedFilters(lockedFilters)
  return Object.keys(versionLookup).reduce<{
    documents: Document[],
    versions: DocumentVersion[]
  }>((acc, documentId) => {
    const versions = versionLookup[documentId].versions.sort((a, b) => b.versionNumber - a.versionNumber)
    // Standard logic of use latest published version or fallback to latest version
    const lastUsableVersion = versions.find((ver) => ver.status === DocumentStatus.PUBLISHED) ?? versions[0]
    if (matchFunction(lastUsableVersion)) {
      acc.versions = [...acc.versions, ...versions]
      acc.documents = [...acc.documents, versionLookup[documentId].document]
    }
    return acc
  }, { documents: [], versions: [] })
}
