import { FolderORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import { useEffect } from 'react'

export const DNADocumentVirtualCustomDeckRow: DNARowsComponent<FolderORM> = () => {
  useEffect(() => {
    console.error('DNADocumentVirtualCustomDeckRow not implemented yet')
  }, [])

  return (null)
}

export default DNADocumentVirtualCustomDeckRow
