import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  DNABox,
  DNAButton,
  DNACard,
  DNACheckbox,
  DNAChip,
  DNAIcon,
  DNAModal,
  DNAText,
  Iffy,
  Popover,
  util,
} from '@alucio/lux-ui'
import { StyleSheet } from 'react-native'
import {
  AssociatedFileORM,
  DocumentORM,
  DocumentVersionORM,
  EmailTemplateORM,
  getAssociatedFileTitle,
} from 'src/types/types'
import {
  AssociatedFileType,
  Document,
  DocumentStatus,
  EmailTemplate,
  ShareType,
} from '@alucio/aws-beacon-amplify/src/models'
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast'
import { CurrentUser } from 'src/state/redux/selector/user'
import { useAllDocumentsInstance } from 'src/state/redux/selector/document';
import {
  ContentShareProps,
  copyEmailTemplateToClipboard,
  copyShareableLinkToClipboard,
  shareEmail,
  shareEmailTemplate,
} from 'src/utils/shareLink/shareLink.web'
import { ShareableLinkResult } from 'src/utils/shareLink/common'

// if it is attachments then the type to shareable should be ShareType.ATTACHMENT
const styles = StyleSheet.create({
  documentList: {
    borderColor: colors['color-flat-300'],
    borderRadius: 4,
    borderWidth: 2,
    padding: 20,
    marginTop: 4,
    marginBottom: 24,
  },
  sectionTitle: {
    textTransform: 'uppercase',
  },
  tooltipIcon: {
    color: '#B6B6B6',
  },
  documentChip: {
    minWidth: 45,
  },
  emailTemplatesCard: {
    borderColor: colors['color-flat-400'],
  },
  emailTemplatesRow: {
    padding: 12,
    paddingRight: 20,
  },
  emailTemplatesRowBorder: {
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: colors['color-flat-400'],
  },
  emailTemplateAction: {
    marginLeft: 38,
  },
});

type ShareAssociatedFileRowProps = {
  associatedFile: AssociatedFileORM
  reducedDocs: ShareFileOption,
  onToggleCheckbox: (docId: string) => void
}

const ShareAssociatedFileRow: React.FC<ShareAssociatedFileRowProps> = props => {
  const {
    associatedFile,
    reducedDocs,
    onToggleCheckbox,
  } = props;
  return (
    <DNABox key={associatedFile.model.id}>
      <DNABox appearance="row" spacing="medium" alignY="center" fill childStyle={[2, { flex: 1 }]}>
        <DNACheckbox
          checked={reducedDocs[associatedFile.file?.id!].beingShared}
          disabled={associatedFile.model.isDefault}
          status="primary"
          appearance="default"
          onChange={() => onToggleCheckbox(associatedFile.file?.id!)}
        />
        <DNABox style={styles.documentChip}>
          <DNAChip appearance="tag" status="dark">
            {associatedFile.file?.type}
          </DNAChip>
        </DNABox>
        <DNAText numberOfLines={1}>{getAssociatedFileTitle(associatedFile)}</DNAText>
      </DNABox>
    </DNABox>
  )
}

// [BEAC-1583] For email templates we will allow to link unpublished doc files but
// we will only share the published ones, the same that happens in Doc Version
const getAttachmentsToShare = (associatedFiles: AssociatedFileORM[], allDocumentORM: DocumentORM[]) => {
  return associatedFiles
    .filter(af => af.meta.canBeSharedByMSL)
    .map(af => {
      let contentId = af.file?.id!
      if (af.model.type === 'DOCUMENT') {
        const [currentAssociatedDoc] = allDocumentORM
          .filter(currentDoc => af.file?.id! === currentDoc.model.id)
        contentId = currentAssociatedDoc != null
          ? currentAssociatedDoc.relations.version.latestDocumentVersionPublished?.model.id!
          : contentId
      }
      return {
        title: getAssociatedFileTitle(af),
        contentId,
        sharedContentId: af.model.id,
        type: af.model.type === 'ATTACHED_FILE' ? ShareType.ATTACHED_FILE : ShareType.DOCUMENT_VERSION,
      }
    })
}

type ShareEmailTemplateRowProps = {
  associatedFiles: AssociatedFileORM[],
  documentVersionORM: DocumentVersionORM,
  emailTemplateORM: EmailTemplateORM,
  copyEmailTemplate?: EmailTemplateCopy,
  docsToShare: ShareFileOption[],
  onEmailTemplateCopy: (emailTemplateORM: EmailTemplateORM) => void,
  isLastRow: boolean
}

const ShareEmailTemplateRow: React.FC<ShareEmailTemplateRowProps> = props => {
  const {
    associatedFiles,
    documentVersionORM,
    emailTemplateORM,
    copyEmailTemplate,
    docsToShare,
    onEmailTemplateCopy,
    isLastRow,
  } = props;
  const allDocumentORM = useAllDocumentsInstance()
  const isCopied = copyEmailTemplate?.emailTemplate.id === emailTemplateORM.model.id &&
    copyEmailTemplate.copyStatus === COPY_STATE.COPIED
  const isCopying = copyEmailTemplate?.emailTemplate.id === emailTemplateORM.model.id &&
    copyEmailTemplate.copyStatus === COPY_STATE.COPYING
  const isReadyForCopy = copyEmailTemplate?.emailTemplate.id === emailTemplateORM.model.id &&
    copyEmailTemplate.copyStatus === COPY_STATE.READY_FOR_COPY

  const copyButtonText = isReadyForCopy
    ? 'Tap to copy'
    : isCopied
      ? 'Copied'
      : isCopying
        ? 'Generating...'
        : 'Copy'

  const onEmailPressed = useCallback((): void => {
    shareEmailTemplate(
      docsToShare,
      getAttachmentsToShare(emailTemplateORM.relations.associatedFiles, allDocumentORM),
      emailTemplateORM.model,
    ).then(() => {
      const isMainDocBeingShared: boolean = Object.values(docsToShare).filter((docToShare: ShareFileOption) =>
        docToShare.isMainDoc && docToShare.beingShared).length === 1;
      if (isMainDocBeingShared) {
        analytics?.track('DOCUMENT_SHARE', {
          action: 'SHARE',
          channel: 'EMAIL',
          category: 'DOCUMENT',
          documentId: documentVersionORM.model.documentId,
          documentVersionId:
            documentVersionORM.model.id,
          emailTemplateId: emailTemplateORM.model.id,
        });
      }
      docsToShare.forEach((docToShare: ShareFileOption) => !docToShare.isMainDoc &&
        analytics?.track('DOCUMENT_AF_SHARE', {
          action: 'AF_SHARE',
          attachedContentId: docToShare.contentProps.contentId,
          category: 'DOCUMENT',
          channel: 'EMAIL',
          documentId: documentVersionORM.model.documentId,
          documentVersionId: documentVersionORM.model.id,
          sharedContentId: docToShare.contentProps.sharedContentId,
          type: docToShare.contentProps.type === 'ATTACHED_FILE' ? 'UPLOAD' : 'LINK',
        }),
      );
    })
  }, [associatedFiles, documentVersionORM, emailTemplateORM]);

  return (
    <DNABox
      testID="email-template-share-row"
      key={emailTemplateORM.model.id}
      style={util.mergeStyles(undefined, styles.emailTemplatesRow, [styles.emailTemplatesRowBorder, !isLastRow])}
    >
      <DNABox
        appearance="row"
        spacing="between"
        alignY="center"
        fill
        childStyle={[0, { flex: 1 }]}
      >
        <DNABox appearance="col" fill>
          <DNAText numberOfLines={2}>{emailTemplateORM.model.title}</DNAText>
          <DNABox spacing="tiny" wrap="start">
            {
              emailTemplateORM.model.labelValues?.map(({ value }, idx) => (
                <DNAChip key={value + idx} appearance="tag" status="dark" style={{ marginTop: 4 }}>
                  {value}
                </DNAChip>
              ))
            }
          </DNABox>
        </DNABox>
        <DNABox>
          <DNABox style={styles.emailTemplateAction}>
            <DNAButton
              testID="email-template-mailto-client-button"
              status="primary"
              appearance="ghost"
              context="minimum"
              iconLeft="email-send-outline"
              onPress={onEmailPressed}
            >
              Email
            </DNAButton>
          </DNABox>
          <DNABox style={styles.emailTemplateAction}>
            <DNAButton
              testID={
                isCopied
                  ? 'email-template-link-copied-button'
                  : 'email-template-copy-link-button'
              }
              status={isCopied ? 'success' : 'primary'}
              appearance="ghost"
              context="minimum"
              iconLeft={isCopied ? 'check-bold' : 'content-copy'}
              onPress={() => onEmailTemplateCopy(emailTemplateORM)}
            >
              {copyButtonText}
            </DNAButton>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export type DNAFileShareModalProps = {
  documentVersionORM: DocumentVersionORM
  currentUser?: CurrentUser
  toast: ToastActions,
  emailTemplates: EmailTemplateORM[],
}

export interface ShareFileOption {
  isMainDoc: boolean,
  isDefault: boolean,
  beingShared: boolean,
  isDistributable: boolean,
  contentProps: ContentShareProps
}

export enum MODAL_SCREEN {
  SELECT_FILES,
  SELECT_TEMPLATES
}

enum COPY_STATE {
  NONE,
  COPYING,
  READY_FOR_COPY,
  COPIED
}

interface EmailTemplateCopy {
  emailTemplate: EmailTemplate,
  copyStatus: COPY_STATE,
  copyToClipboard?: () => void
}

const DNAFileShareModal: DNAModalVariant<DNAFileShareModalProps> = props => {
  const {
    closeModal,
    documentVersionORM,
    toast,
    currentUser,
    emailTemplates,
  } = props
  const [copyEmailTemplate, setCopyEmailTemplate] = useState<EmailTemplateCopy | undefined>();
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [currentScreen, setCurrentScreen] = useState<MODAL_SCREEN>(MODAL_SCREEN.SELECT_FILES)
  const allDocumentORM = useAllDocumentsInstance()

  const sortTitle = (
    a: AssociatedFileORM, b: AssociatedFileORM,
  ) => getAssociatedFileTitle(a)?.localeCompare(getAssociatedFileTitle(b) ?? '') ?? 0;

  // WE REMOVE THE NON PUBLISHED FILES AND NON DISTRIBUTABLE ONES
  const associatedFiles = documentVersionORM.relations.associatedFiles.filter((associatedFile) => {
    if (associatedFile.model.type !== AssociatedFileType.DOCUMENT) {
      return true;
    }

    const isDistributable = associatedFile.relations?.latestUsableDocumentVersion?.distributable;
    return (associatedFile.file as Document).status === DocumentStatus.PUBLISHED && isDistributable
  });

  const optionalDoc = [] as AssociatedFileORM[];
  const requiredDoc = [] as AssociatedFileORM[];

  associatedFiles.sort(sortTitle).forEach((associatedFile) => {
    if (associatedFile.model.isDefault) {
      requiredDoc.push(associatedFile);
    }

    const isDistributable = associatedFile.model.type === AssociatedFileType.ATTACHED_FILE
      ? !!associatedFile.model.isDistributable
      : !!associatedFile.relations.latestUsableDocumentVersion?.distributable;

    if (!associatedFile.model.isDefault && isDistributable) {
      optionalDoc.push(associatedFile);
    }
  });

  // TODO simplify the if statements logic and fix the new reduce type
  const docsFormatted: ShareFileOption =
    associatedFiles?.reduce
      ((reducedDocObj, associatedFile) => {
        if (reducedDocObj[associatedFile.file?.id!]) {
          return reducedDocObj
        } else {
          const fileTitle = getAssociatedFileTitle(associatedFile)
          reducedDocObj[associatedFile.file?.id!] = {
            isMainDoc: false,
            isDefault: associatedFile.model.isDefault ?? false,
            beingShared: associatedFile.model.isDefault ?? false,
            isDistributable: associatedFile.model.type === 'ATTACHED_FILE'
              ? !!associatedFile.model.isDistributable
              : !!associatedFile.relations.latestUsableDocumentVersion?.distributable,
            contentProps: associatedFile.model.type === 'ATTACHED_FILE'
              ? {
                contentId: associatedFile.file?.id!,
                sharedContentId: associatedFile.model.id,
                title: fileTitle,
                type: ShareType.ATTACHED_FILE,
              }
              : {
                contentId: associatedFile.file?.id!,
                sharedContentId: associatedFile.model.id,
                title: fileTitle,
                type: ShareType.DOCUMENT_VERSION,
              },
          }
          return reducedDocObj
        }
      }, {} as ShareFileOption) ?? {}

  const mainFileFormatted: { [x: number]: ShareFileOption } = {
    [documentVersionORM.relations.document.model.id]: {
      isMainDoc: true,
      isDefault: false,
      beingShared: requiredDoc.length === 0 && optionalDoc.length === 0,
      isDistributable: documentVersionORM.model.distributable ?? false,
      contentProps: {
        contentId: documentVersionORM.relations.document.model.id,
        title: documentVersionORM?.model.title,
        type: ShareType.DOCUMENT_VERSION,
      },
    },
  }

  const allDocsFormatted = {
    ...mainFileFormatted,
    ...docsFormatted,
  }
  const [reducedDocs, setReducedDocs] = useState(allDocsFormatted)

  useEffect(() => {
    // eslint-disable-next-line max-len
    const docsBeingSharedCount = Object.values(reducedDocs).filter((doc: any) => !doc.isDefault && doc.beingShared).length;
    if (docsBeingSharedCount === 1) {
      setSelectAll(true);
    }
  }, [])

  function setEmailTemplateAsCopied(emailTemplate: EmailTemplate) {
    setCopyEmailTemplate({ emailTemplate, copyStatus: COPY_STATE.COPIED })
    setTimeout(() => {
      setCopyEmailTemplate({ emailTemplate, copyStatus: COPY_STATE.NONE })
    }, 3000);
  }

  function handleToggleCheckbox(docId: string): void {
    const selectableDocsCount = optionalDoc.length + (documentVersionORM.model.distributable ? 1 : 0)
    // eslint-disable-next-line max-len
    const docsBeingSharedCount = Object.values(reducedDocs).filter((doc: any) => !doc.isDefault && doc.beingShared).length;

    if (!reducedDocs[docId].beingShared && docsBeingSharedCount === selectableDocsCount - 1) {
      setSelectAll(true)
    }
    else if (reducedDocs[docId].beingShared && docsBeingSharedCount === selectableDocsCount) {
      setSelectAll(false)
    }

    setReducedDocs(p => ({ ...p, [docId]: { ...p[docId], ...{ beingShared: !p[docId].beingShared } } }))
  }

  function handleSelectAll() {
    setSelectAll(p => !p)
    for (const doc in reducedDocs) {
      // if it is default dont toggle
      if (!reducedDocs[doc].isDefault && reducedDocs[doc].isDistributable) {
        setReducedDocs(p => ({ ...p, [doc]: { ...p[doc], ...{ beingShared: !selectAll } } }))
      }
    }
  }

  async function handleEmailTemplateCopy(emailTemplateORM: EmailTemplateORM) {
    // if previously we got a copy function we just use it and return
    if (copyEmailTemplate?.copyToClipboard && copyEmailTemplate?.copyStatus === COPY_STATE.READY_FOR_COPY) {
      copyEmailTemplate?.copyToClipboard()
      setEmailTemplateAsCopied(emailTemplateORM.model)
      const isMainDocBeingShared: boolean = Object.values(docsToShare).filter((docToShare: ShareFileOption) =>
        docToShare.isMainDoc && docToShare.beingShared).length === 1;
      if (isMainDocBeingShared) {
        analytics?.track('DOCUMENT_SHARE', {
          action: 'SHARE',
          channel: 'EMAIL',
          category: 'DOCUMENT',
          documentId: documentVersionORM.model.documentId,
          documentVersionId: documentVersionORM.model.id,
          emailTemplateId: emailTemplateORM.model.id,
        });
      }
      docsToShare.forEach((docToShare: ShareFileOption) => !docToShare.isMainDoc &&
        analytics?.track('DOCUMENT_AF_SHARE', {
          action: 'AF_SHARE',
          attachedContentId: docToShare.contentProps.contentId,
          category: 'DOCUMENT',
          channel: 'EMAIL',
          documentId: documentVersionORM.model.documentId,
          documentVersionId: documentVersionORM.model.id,
          sharedContentId: docToShare.contentProps.sharedContentId,
          type: docToShare.contentProps.type === 'ATTACHED_FILE' ? 'UPLOAD' : 'LINK',
        }),
      );
      return;
    }

    setCopyEmailTemplate({
      emailTemplate: emailTemplateORM.model,
      copyStatus: COPY_STATE.COPYING,
    })

    const copyFn = await copyEmailTemplateToClipboard(
      docsToShare,
      getAttachmentsToShare(emailTemplateORM.relations.associatedFiles, allDocumentORM),
      toast,
      emailTemplateORM.model)

    if (copyFn) {
      setCopyEmailTemplate({
        emailTemplate: emailTemplateORM.model,
        copyStatus: COPY_STATE.READY_FOR_COPY,
        copyToClipboard: copyFn,
      })
    }
    else {
      setEmailTemplateAsCopied(emailTemplateORM.model)
      const isMainDocBeingShared: boolean = Object.values(docsToShare).filter((docToShare: ShareFileOption) =>
        docToShare.isMainDoc && docToShare.beingShared).length === 1;
      if (isMainDocBeingShared) {
        analytics?.track('DOCUMENT_SHARE', {
          action: 'SHARE',
          channel: 'EMAIL',
          category: 'DOCUMENT',
          documentId: documentVersionORM.model.documentId,
          documentVersionId: documentVersionORM.model.id,
          emailTemplateId: emailTemplateORM.model.id,
        });
      }
      docsToShare.forEach((docToShare: ShareFileOption) => !docToShare.isMainDoc &&
        analytics?.track('DOCUMENT_AF_SHARE', {
          action: 'AF_SHARE',
          attachedContentId: docToShare.contentProps.contentId,
          category: 'DOCUMENT',
          channel: 'EMAIL',
          documentId: documentVersionORM.model.documentId,
          documentVersionId: documentVersionORM.model.id,
          sharedContentId: docToShare.contentProps.sharedContentId ?? null,
          type: docToShare.contentProps.type === 'ATTACHED_FILE' ? 'UPLOAD' : 'LINK',
        }),
      );
    }
  }

  const docsToShare: ShareFileOption[] = useMemo((): ShareFileOption[] => {
    const sharedDocs: ShareFileOption[] = [];
    for (const doc in reducedDocs) {
      if (reducedDocs[doc].beingShared) {
        sharedDocs.push(reducedDocs[doc])
      }
    }
    sharedDocs.sort((a, b) => {
      if (a.isMainDoc) {
        return -1
      } else if (b.isMainDoc) {
        return 1
      } else {
        return (a.contentProps.title ?? '').localeCompare(b.contentProps.title ?? '')
      }
    })

    return sharedDocs.map((file: ShareFileOption): ShareFileOption => {
      if (file.contentProps.type === ShareType.DOCUMENT_VERSION) {
        const [currentAssociatedDoc] = allDocumentORM
          .filter(currentDoc => file.contentProps.contentId === currentDoc.model.id)
        file.contentProps.contentId = currentAssociatedDoc != null
          ? currentAssociatedDoc.relations.version.latestDocumentVersionPublished?.model.id!
          : file.contentProps.contentId
      }
      return file;
    })
  }, [allDocumentORM, reducedDocs]);

  const onCopyLinksPressed = useCallback((): void => {
    copyShareableLinkToClipboard(docsToShare, toast).then((shareableLinkResults: ShareableLinkResult[]) => {
      shareableLinkResults.forEach((shareableLinkResult: ShareableLinkResult) => {
        if (shareableLinkResult.contentId === documentVersionORM.model.id) {
          analytics?.track('DOCUMENT_SHARE', {
            action: 'SHARE',
            channel: 'LINK',
            category: 'DOCUMENT',
            documentId: documentVersionORM.model.documentId,
            documentVersionId:
              documentVersionORM.model.id,
            shareId: shareableLinkResult.id,
          });
        } else {
          analytics?.track('DOCUMENT_AF_SHARE', {
            action: 'AF_SHARE',
            attachedContentId: shareableLinkResult.contentId,
            category: 'DOCUMENT',
            channel: 'LINK',
            documentId: documentVersionORM.model.documentId,
            documentVersionId: documentVersionORM.model.id,
            shareId: shareableLinkResult.id,
            type: shareableLinkResult.type === 'ATTACHED_FILE' ? 'UPLOAD' : 'LINK',
          });
        }
      });
    });
  }, [associatedFiles, documentVersionORM]);

  return (
    <DNAModal>
      <DNAModal.Header onClose={closeModal}>
        <DNABox>
          <DNAText>{currentScreen === MODAL_SCREEN.SELECT_FILES
            ? 'Select files to share or copy link'
            : 'Select email template'}
          </DNAText>
        </DNABox>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox appearance="col" spacing="between" style={{ width: 800 }}>
          <Iffy is={currentScreen === MODAL_SCREEN.SELECT_FILES}>
            <DNABox appearance="col" spacing="tiny" style={{ maxHeight: 500, overflow: 'scroll' }}>
              {/* Select all */}
              <DNABox appearance="row" spacing="small" fill style={styles.documentList}>
                <DNACheckbox
                  checked={selectAll}
                  status="primary"
                  appearance="default"
                  onChange={() => handleSelectAll()}
                />
                <DNAText>Select all</DNAText>
              </DNABox>
              {/* Main file */}
              <DNABox appearance="col" spacing="tiny" alignY="center" fill>
                <DNABox alignY="center" spacing="small">
                  <DNAText bold c1 style={styles.sectionTitle}>Main file</DNAText>
                  <Popover placement="top">
                    <Popover.Anchor>
                      <DNAIcon name="help-circle-outline" status="flat" size="large" style={styles.tooltipIcon} />
                    </Popover.Anchor>
                    <Popover.Content offset={2}>
                      <DNAText status="basic">
                        This is the active file that you selected before opening this modal.
                      </DNAText>
                    </Popover.Content>
                  </Popover>
                </DNABox>
                <DNABox
                  appearance="row"
                  spacing="medium"
                  alignY="center"
                  fill
                  style={styles.documentList}
                  childStyle={[2, { flex: 1 }]}
                >
                  <Iffy is={documentVersionORM.model.distributable}>
                    <DNACheckbox
                      checked={reducedDocs[documentVersionORM.relations.document.model.id].beingShared}
                      status="primary"
                      appearance="default"
                      onChange={() => handleToggleCheckbox(documentVersionORM.relations.document.model.id)}
                    />
                  </Iffy>
                  <DNABox style={styles.documentChip}>
                    <DNADocumentChip
                      item={documentVersionORM.relations.document}
                      variant="docType"
                    />
                  </DNABox>
                  <DNAText style={{ flex: 1, fontSize: 14, lineHeight: 16 }} numberOfLines={5}>
                    {documentVersionORM.model.title}
                  </DNAText>
                </DNABox>
              </DNABox>
              {/* Required */}
              <Iffy is={requiredDoc?.length > 0}>
                <DNABox appearance="col" spacing="tiny" alignY="center" fill>
                  <DNABox alignY="center" spacing="small">
                    <DNAText bold c1 style={styles.sectionTitle}>Required</DNAText>
                    <Popover placement="top">
                      <Popover.Anchor>
                        <DNAIcon name="help-circle-outline" status="flat" size="large" style={styles.tooltipIcon} />
                      </Popover.Anchor>
                      <Popover.Content offset={2}>
                        <DNAText status="basic">
                          You are required to share these files when distributing externally.
                        </DNAText>
                      </Popover.Content>
                    </Popover>
                  </DNABox>
                  <DNABox appearance="col" spacing="medium" style={styles.documentList}>
                    {requiredDoc.map(associatedFile =>
                      (
                        <React.Fragment key={associatedFile.model.id}>
                          <ShareAssociatedFileRow
                            associatedFile={associatedFile}
                            reducedDocs={reducedDocs}
                            onToggleCheckbox={handleToggleCheckbox}
                          />
                        </React.Fragment>),
                    )}
                  </DNABox>
                </DNABox>
              </Iffy>
              {/* Optional */}
              <Iffy is={optionalDoc.length > 0}>
                <DNABox appearance="col" spacing="tiny" alignY="center" fill>
                  <DNABox alignY="center" spacing="small">
                    <DNAText bold c1 style={styles.sectionTitle}>Optional</DNAText>
                    <Popover placement="top">
                      <Popover.Anchor>
                        <DNAIcon name="help-circle-outline" status="flat" size="large" style={styles.tooltipIcon} />
                      </Popover.Anchor>
                      <Popover.Content offset={2}>
                        <DNAText status="basic">
                          These files are optional for you to share when distributing externally.
                        </DNAText>
                      </Popover.Content>
                    </Popover>
                  </DNABox>
                  <DNABox appearance="col" spacing="medium" style={styles.documentList}>
                    {optionalDoc.map(associatedFile =>
                      (
                        <React.Fragment key={associatedFile.model.id}>
                          <ShareAssociatedFileRow
                            associatedFile={associatedFile}
                            reducedDocs={reducedDocs}
                            onToggleCheckbox={handleToggleCheckbox}
                          />
                        </React.Fragment>),
                    )}
                  </DNABox>
                </DNABox>
              </Iffy>
            </DNABox>
            <DNABox alignX="end" spacing="tiny" style={{ paddingTop: 16 }}>
              <DNAButton
                status="flatAlt"
                appearance="filled"
                context="round"
                onPress={() => closeModal()}
              >
                Cancel
              </DNAButton>
              <DNAButton
                testID="copy-links-button"
                status="primary"
                appearance="outline"
                context="round"
                disabled={!Object.values(reducedDocs).some((doc: any) => doc.beingShared)}
                onPress={onCopyLinksPressed}
              >
                Copy links
              </DNAButton>
              <Iffy is={emailTemplates.length > 0}>
                <DNAButton
                  testID="continue-to-share-button"
                  status="primary"
                  appearance="filled"
                  context="round"
                  disabled={!Object.values(reducedDocs).some((doc: any) => doc.beingShared)}
                  onPress={() => setCurrentScreen(MODAL_SCREEN.SELECT_TEMPLATES)}
                >
                  Continue to share
                </DNAButton>
              </Iffy>
              <Iffy is={emailTemplates.length === 0}>
                <DNAButton
                  status="primary"
                  appearance="filled"
                  context="round"
                  onPress={() => shareEmail(
                    docsToShare,
                    currentUser?.userProfile!,
                  )}
                >
                  Share
                </DNAButton>
              </Iffy>
            </DNABox>
          </Iffy>
          <Iffy is={currentScreen === MODAL_SCREEN.SELECT_TEMPLATES}>
            <DNABox appearance="col" spacing="tiny" style={{ minHeight: 300, maxHeight: 500, overflow: 'scroll' }}>
              <DNACard appearance="outline" style={[styles.emailTemplatesCard]}>
                {emailTemplates.map((emailTemplateORM, idx) =>
                  (
                    <React.Fragment key={emailTemplateORM.model.id}>
                      <ShareEmailTemplateRow
                        associatedFiles={associatedFiles}
                        documentVersionORM={documentVersionORM}
                        emailTemplateORM={emailTemplateORM}
                        copyEmailTemplate={copyEmailTemplate}
                        docsToShare={docsToShare}
                        onEmailTemplateCopy={handleEmailTemplateCopy}
                        isLastRow={idx === emailTemplates.length - 1}
                      />
                    </React.Fragment>),
                )}
              </DNACard>
            </DNABox>
            <DNABox alignX="start" spacing="tiny" style={{ paddingTop: 16 }}>
              <DNAButton
                status="flatAlt"
                appearance="filled"
                context="round"
                iconLeft="chevron-left"
                onPress={() => setCurrentScreen(MODAL_SCREEN.SELECT_FILES)}
              >
                Back
              </DNAButton>
            </DNABox>
          </Iffy>
        </DNABox>
      </DNAModal.Body>
    </DNAModal>
  )
}

export default DNAFileShareModal
