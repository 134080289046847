import React, { useState } from 'react';
import { DNABox, DNAButton } from '@alucio/lux-ui';
import { StyleProp, ViewStyle } from 'react-native';
interface Props {
  testID?: string;
  disabled?: boolean;
  multiple?: boolean;
  style?: StyleProp<ViewStyle>;
  context?: 'round';
  size?: 'tiny' | 'small';
  iconLeft?: string;
  iconStyle?: StyleProp<ViewStyle>;
  onSelect: (files: File[]) => void;
  text: string;
  status?: 'primary' | 'success' | 'info';
  acceptedTypes?: string;
  appearance?: 'ghostAlt' | 'filled' | 'outline' | 'ghost' | 'outlineAlt' | undefined;
}

function SelectFileButton(props: Props) {
  const {
    acceptedTypes = '.pptx,.pdf',
    multiple,
    onSelect,
    text,
    disabled,
    ...restButtonProps
  } = props;
  function onFilesSelected(event): void {
    event.stopPropagation();
    event.preventDefault();
    onSelect([...event.target.files]);
    // Set value to empty string so onChange always fire
    setValue('');
  }

  const [value, setValue] = useState('');

  return (
    <DNABox>
      <input
        accept={acceptedTypes}
        id="uploadButton"
        type="file"
        style={{ display: 'none' }}
        multiple={!!multiple}
        value={value}
        onChange={onFilesSelected}
        disabled={disabled}
      />
      <label htmlFor="uploadButton">
        <DNAButton {...restButtonProps}>
          { text }
        </DNAButton>
      </label>
    </DNABox>
  );
}

export default SelectFileButton;
