import { DocumentStatus } from '@alucio/aws-beacon-amplify/src/models';
import format from 'date-fns/format'
import { CurrentUser } from 'src/state/redux/selector/user';
import { DocumentORM, getAssociatedFileTitle } from 'src/types/types'
import { TenantORM } from 'src/state/redux/selector/tenant';

export const exportData = (allDocumentsCSV: DocumentORM[], currentUser: CurrentUser, tenants: TenantORM[]) => {
  const headers = [
    'Document ID',
    'Title',
    'Short Description',
    'Long Description',
    'Status',
    'Purpose',
    'Distributable',
    'Expires At',
    'Owner',
    'Created At',
    'Updated At',
    'Type',
    'Uploaded File Name',
    'Latest Pages',
    'Published File Name',
    'Published Pages',
    'Hiding',
    'Downloadable',
    'Associated Files',
    ...Object.keys(allDocumentsCSV[0].meta.tenantFields.valuesMap),
  ]

  const removeLineBreaks = (text: string | undefined) => {
    if (!text) return undefined;
    return text.replace(/(\r\n|\n|\r)/gm, '').trim();
  }

  const rows = allDocumentsCSV
    .filter(document => document.model.status !== DocumentStatus.DELETED && document.model.id.trim())
    .reduce((acum, document): string[][] => {
      const valuesMap = document.meta.tenantFields.valuesMap;
      const csvValuesMap = Object.keys(valuesMap).map((e) => valuesMap[e].join('|'));
      const latestFileName = document.relations.version.latestDocumentVersion.model.srcFilename;
      const lastestPages = document.relations.version.latestDocumentVersion.model.pages.length || '';

      /**
       * nullish coalescing operator which first tries the latestDocumentVersionPublished
       * or falls back to latestDocumentVersion if that is not available
       */
      const recentPublishedVersion =
        document.relations.version.latestDocumentVersionPublished ??
        document.relations.version.latestDocumentVersion;
      const publishedFileName = recentPublishedVersion?.model.srcFilename || '';
      const publishedPages = recentPublishedVersion?.model.pages.length || 0;

      const associatedFilesCSV = recentPublishedVersion.relations.associatedFiles.map((assocFile) => {
        return removeLineBreaks(getAssociatedFileTitle(assocFile))
      }).join('|')

      const row = [
        document.model.id,
        removeLineBreaks(recentPublishedVersion.model.title) || '',
        removeLineBreaks(recentPublishedVersion.model.shortDescription) || '',
        removeLineBreaks(recentPublishedVersion.model.longDescription) || '',
        document.model.status,
        recentPublishedVersion.model.purpose || '',
        recentPublishedVersion.model.distributable ? 'YES' : 'NO',
        recentPublishedVersion.model.expiresAt || '',
        recentPublishedVersion.model.owner || '',
        document.model.createdAt,
        document.model.updatedAt,
        document.model.type,
        removeLineBreaks(latestFileName) || '',
        lastestPages.toString(),
        removeLineBreaks(publishedFileName) || '',
        publishedPages.toString(),
        recentPublishedVersion.model.canHideSlides ? 'YES' : 'NO',
        recentPublishedVersion.model.downloadable ? 'YES' : 'NO',
        associatedFilesCSV,
        ...csvValuesMap,
      ]

      acum.push(row)
      return acum;
    }, [headers])

  const csvContent = rows.map(e => `"${e.map(d => d.replace(/"/g, '""')).join('","')}"`).join('\n');
  const link = document.createElement('a');
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const csvUrl = window.webkitURL.createObjectURL(blob);

  const tenant = tenants.find((tenant) => tenant.tenant.id === currentUser.userProfile?.tenantId);
  const tenantName = tenant?.tenant.name.replace(/[^a-z0-9+]+/gi, '_').substring(0, 50)

  link.setAttribute('download', `${tenantName || ''}contentReport${format(new Date(), 'MM-dd-yyyy')}.csv`);
  link.setAttribute('href', csvUrl);

  document.body.appendChild(link);

  link.click();
}

export const download = (url, filename) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
}
