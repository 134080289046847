import React, { useEffect, useRef, useState } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, View } from 'react-native';
import { DNABox, DNAButton, Iffy, InformationMessage, luxColors } from '@alucio/lux-ui';
import Auth from '@aws-amplify/auth';
import InputComponent from 'src/components/Publishers/InputComponent';
import FormWrapper from '../FormWrapper';
import { IAuthPieceProps } from 'aws-amplify-react/lib-esm/Auth/AuthPiece';
import {
  getDisconnectedDue,
  setDisconnectedDue,
  setDisconnectedDueDefault,
} from 'src/components/Authenticator/DisconnectedDue';
import useSSO from 'src/components/DNA/hooks/useSso';
import { Input } from '@ui-kitten/components'
import { useOnlineStatus } from 'src/state/context/AppSettings'
import { useHistory, useLocation } from 'src/router';
import { SessionStatus } from 'src/components/IdleComponent/IdleComponent';
import { isSignInFailureResponse } from 'src/types/typeguards';

const styles = StyleSheet.create({
  beaconText: {
    color: luxColors.alucioPurple.primary,
    fontSize: 24,
    fontWeight: 'bold',
    paddingBottom: 20,
    paddingTop: 20,
  },
  content: {
    flexDirection: 'column',
    width: '100%',
  },
  disabledButton: {
    backgroundColor: luxColors.disabled.quaternary,
    borderColor: luxColors.disabled.quaternary,
  },
  disabledTextColor: {
    color: luxColors.disabled.quinary,
  },
  errorWrapper: {
    height: 20,
    marginTop: 20,
    marginBottom: 10,
  },
  invalidMessage: {
    color: luxColors.error.primary,
    fontSize: 12,
  },
  timeExpired: {
    color: luxColors.warning.quaternary,
    fontSize: 12,
  },
  logOut: {
    color: luxColors.success.tertiary,
    fontSize: 12,
  },
  marginTop: {
    marginTop: 20,
  },
  superchargingWrapper: {
    flexDirection: 'row',
  },
  superchargingText: {
    color: luxColors.headerTextColor.primary,
    fontSize: 14,
  },
});

/** This is run prior to the  react router being instantiated so to support SSO
 * we must use the location property of the window interface on the WebAPI */
const navigation = window.location;

interface CustomSignInProps extends IAuthPieceProps {
  allowChangePassword?: (isSSO: boolean) => void;
}

export interface SignInFailureResponse {
  code: string,
  message: string,
  name: string,
}

const PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException';
const USER_NOT_FOUND = 'UserNotFoundException';
const NOT_AUTHORIZED = 'NotAuthorizedException';
const INVALID_INPUTS = 'InvalidParameterException';
const INVALID_LOGIN_ERRORS = [USER_NOT_FOUND, NOT_AUTHORIZED, INVALID_INPUTS];
const VALID_STATES = ['signIn', 'signedOut', 'signedUp'];

const CustomSignIn = (props: CustomSignInProps) => {
  const onlineStatus = useOnlineStatus()
  const isNetworkConnected = onlineStatus
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(!isNetworkConnected);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [invalidLogin, setInvalidLogin] = useState<boolean>(false);
  const { SSOUserValidation, isSSoUser, ssoError, cleanSSoError } = useSSO();
  const passwordInputRef = useRef<Input>();

  const disabledLogInButton = !isNetworkConnected || (showPassword ? disabled || !password : disabled || !email);
  const disconnectedDueMsg = getDisconnectedDue();

  const location = useLocation()
  const history = useHistory()
  useEffect(() => {
    // If a logout reason was provided in the URL we want to save it to disconnected due
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.has('logoutFor')) {
      const reason = queryParams.get('logoutFor') as SessionStatus
      // Validate Input from Query String
      if ([SessionStatus.end, SessionStatus.timeExpired].includes(reason)) {
        setDisconnectedDue(reason)
      }
      queryParams.delete('logoutFor')
      history.replace({
        search: queryParams.toString(),
      })
    }
  }, [])

  useEffect(() => {
    props.allowChangePassword?.(!isSSoUser && isNetworkConnected)
  }, [isSSoUser, isNetworkConnected])

  function onPasswordChange(pwd: string): void {
    if (!pwd.endsWith(' ')) {
      setPassword(pwd);
    }
  }

  async function onEmailChange(val: string): Promise<void> {
    setEmail(val);
  }

  async function signIn(): Promise<void> {
    setDisabled(true);
    setDisconnectedDueDefault();
    try {
      const user = await Auth.signIn(email.trim(), password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        props.onStateChange?.('requireNewPassword', user);
      } else {
        // Identify is used so Segment knows who is logged, so it links tracked actions to that user
        analytics?.identify(email.trim(), {
          userId: user.attributes['custom:user_id'],
          tenantId: user.attributes['custom:org_id'],
          email: user.attributes.email,
          role: user.signInUserSession.accessToken.payload['cognito:groups'],
        }, function () {
          analytics?.track('LOGIN_LOGIN', {
            action: 'LOGIN',
            category: 'LOGIN',
          });
        });

        props.onStateChange?.('signedIn', user);
      }
    } catch (e) {
      /** TODO: this should be refactored to create a more definitive response
       * interface with constrained props rather than general string typings */
      if (isSignInFailureResponse(e)) {
        if (INVALID_LOGIN_ERRORS.includes(e.code)) {
          setInvalidLogin(true);
          setDisabled(false);
        } else if (e.code === PASSWORD_RESET_REQUIRED) {
          props.onStateChange?.('forgotPassword', { username: email });
        }
      }
    }
  }

  async function doContinue(): Promise<void> {
    setDisabled(true);
    cleanSSoError();

    const { url, isSSO } = await SSOUserValidation(email, false)
    if (url && isSSO) {
      navigation.href = url;
    }
    else {
      setShowPassword(true);
      setTimeout(() => {
        passwordInputRef.current?.focus()
      }, 100)
      setDisabled(false);
    }
  }

  const inputStyle: StyleProp<TextStyle> = showPassword
    /** This style is needed to make the username visible on ipad */
    ? { opacity: 1, color: 'black' }
    : null

  return (
    <>
      <View style={styles.superchargingWrapper}>
        <Text
          testID="login-title"
          style={styles.superchargingText}
        >
          Supercharging scientific exchange
        </Text>
      </View>
      <View style={styles.content}>
        <View style={[styles.errorWrapper, ssoError ? { marginBottom: 30 } : null]}>
          <Iffy is={ssoError}>
            <Text style={styles.invalidMessage}>{ssoError}</Text>
          </Iffy>
          <Iffy is={invalidLogin}>
            <Text
              testID="login-error"
              style={styles.invalidMessage}
            >
              Invalid email / password error
            </Text>
          </Iffy>
          <Iffy is={disconnectedDueMsg.visible && disconnectedDueMsg.type === 'logout'}>
            <Text style={styles.logOut}>{disconnectedDueMsg.msg}</Text>
          </Iffy>
          <Iffy is={disconnectedDueMsg.visible && disconnectedDueMsg.type === 'timeExpired'}>
            <Text style={styles.timeExpired}>{disconnectedDueMsg.msg}</Text>
          </Iffy>
        </View>
        <Iffy is={!isNetworkConnected}>
          <InformationMessage
            variance="warning"
            text="Login is unavailable offline."
          />
        </Iffy>

        <InputComponent
          inputStyle={inputStyle}
          disabled={disabled || showPassword || !isNetworkConnected}
          isPassword={false}
          placeHolder="Email"
          onChangeText={onEmailChange}
          onSubmitEditing={disabledLogInButton ? () => null : doContinue}
          removeMarginPadding={true}
          required={false}
          testID="tb-username"
          title="EMAIL"
          defaultValue={email}
          autoFocus={true}
          autoCapitalize="none"
          autoCorrect={false}
          autoCompleteType="off"
        />

        <DNABox style={{ display: showPassword ? undefined : 'none' }}>
          <InputComponent
            disabled={disabled}
            isPassword={true}
            placeHolder="Password"
            onChangeText={onPasswordChange}
            onSubmitEditing={disabledLogInButton ? () => null : signIn}
            ref={passwordInputRef as any}
            removeMarginPadding={true}
            required={false}
            testID="tb-password"
            title="PASSWORD"
            value={password}
          />
        </DNABox>

        <DNABox spacing="xlarge" childStyle={{ flex: 1, marginTop: 12, marginBottom: 16 }}>
          <Iffy is={showPassword}>
            <DNABox fill alignY="center">
              <DNAButton
                appearance="ghost"
                context="minimum"
                onPress={() => {
                  setDisabled(false);
                  setPassword('')
                  setShowPassword(false)
                }}
              >
                Back
              </DNAButton>

            </DNABox>
          </Iffy>
          <DNABox fill alignX="end">

            <DNAButton
              disabled={disabledLogInButton}
              style={[disabledLogInButton && styles.disabledButton]}
              testID="submit-login"
              onPress={showPassword ? signIn : doContinue}
            >
              <Text style={[disabledLogInButton && styles.disabledTextColor]}>
                {showPassword ? 'Log in' : 'Continue'}
              </Text>
            </DNAButton>
          </DNABox>
        </DNABox>
      </View>
    </>
  );
};

CustomSignIn.displayName = 'CustomSignIn';

const SignInWrapper = (props) => {
  const [changePasswordEnabled, setChangePasswordEnabled] = useState<boolean>(false);
  if (!VALID_STATES.includes(props.authState)) {
    return null;
  }

  return (
    <FormWrapper isLogin={true} title="BEACON" {...props} changePasswordEnabled={changePasswordEnabled}>
      <CustomSignIn {...props} allowChangePassword={(isAllowed) => setChangePasswordEnabled(isAllowed)} />
    </FormWrapper>
  );
};

export default SignInWrapper;
