
import useThumbnailSize from 'src/hooks/useThumbnailSize/useThumbnailSize'

const useEditorState = () => {
  const { thumbnailMode, setThumbnailMode, toggleThumbnailMode, thumbnailSize } = useThumbnailSize()

  return {
    editorThumbnailMode : thumbnailMode,
    setEditorThumbnailMode : setThumbnailMode,
    toggleEditorThumbnailMode : toggleThumbnailMode,
    editorThumbnailSize: thumbnailSize,
  }
}

export default useEditorState
