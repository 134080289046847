import { CustomDeck } from '@alucio/aws-beacon-amplify/src/models'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import addDays from 'date-fns/addDays'
import isPast from 'date-fns/isPast'
import { CustomDeckORM, DocumentVersionORM, VERSION_UPDATE_STATUS } from 'src/types/types';
import { Logger } from '@aws-amplify/core'

import { commonReducers, datastoreSave, initialState, SliceState } from './common'

const sliceName = 'customDeck';
const { reducers, extraReducers } = commonReducers<CustomDeck>(sliceName)
const logger = new Logger('CustomDeckSlice', 'INFO');
export const AUTO_UPDATE_DEFAULT_DATE = '1900-01-01T00:00:00.000Z'

const customDeckSlice = createSlice({
  name: sliceName,
  initialState: initialState<CustomDeck>(),
  reducers: {
    ...reducers,
    acknowledgeAutoUpdate: {
      prepare: (customDeckORMs: CustomDeckORM[]) => {
        return {
          payload: {
            customDeckORMs,
          },
        }
      },
      reducer: (
        state: SliceState<CustomDeck>,
        action: PayloadAction<{
          customDeckORMs: CustomDeckORM[],
        }>,
      ) => {
        const now = new Date().toISOString();
        const { customDeckORMs } = action.payload;

        customDeckORMs.forEach(customDeckORM => {
          datastoreSave<CustomDeck>(CustomDeck, customDeckORM.model, {
            autoUpdateAcknowledgedAt: now,
            updatedAt: now,
          })

          // TODO if analytics is required, it should go here
        })
      },
    },
    updateDocumentVersion: {
      prepare: (
        customDeckORMs: CustomDeckORM[]) => {
        return {
          payload: {
            customDeckORMs,
          },
        }
      },
      reducer: (
        _state: SliceState<CustomDeck>,
        action: PayloadAction<{
          customDeckORMs: CustomDeckORM[],
        }>,
      ) => {
        const { customDeckORMs } = action.payload;
        updateCustomDecks(customDeckORMs)
      },
    },
    applyAutoUpdate: {
      prepare: (
        customDeckORMs: CustomDeckORM[],
        gracePeriodDays: number) => {
        return {
          payload: {
            customDeckORMs,
            gracePeriodDays,
          },
        }
      },
      reducer: (
        state: SliceState<CustomDeck>,
        action: PayloadAction<{
          customDeckORMs: CustomDeckORM[],
          gracePeriodDays: number
        }>,
      ) => {
        const { customDeckORMs, gracePeriodDays } = action.payload;
        // In this case we use the isAutoUpdate flag and the gracePeriodDays for validation
        updateCustomDecks(customDeckORMs, true, gracePeriodDays)
      },
    },
  },
  extraReducers,
});

const updateCustomDecks = (
  customDeckORMs: CustomDeckORM[],
  isAutoUpdate: boolean = false,
  gracePeriodDays?: number) => {
  customDeckORMs.forEach((deck) => {
    if (deck.meta.version.updateStatus !== VERSION_UPDATE_STATUS.CURRENT) {
      let hasChanges = false
      logger.debug(`Checking deck ${deck.model.id} for minor updates`)
      // Deck has minor changes pending now find applicable pages and check auto-update
      const updatedGroups = deck.meta.groupsORM.map((group) => {
        if (group.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MINOR) {
          let newDocVersion: (DocumentVersionORM | undefined)
          const updatedPages = group.pages.map((page) => {
            if (page.documentVersionORM.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MINOR) {
              const docORM = page.documentVersionORM.relations.document
              const latestVersion = docORM.relations.version.latestDocumentVersionPublished!
              // When is a call from applyAutoUpdate we will check the grace period
              const shouldUpdate = !isAutoUpdate || (isAutoUpdate &&
                isPast(addDays(new Date(latestVersion.model.updatedAt), gracePeriodDays!)))

              if (shouldUpdate) {
                // eslint-disable-next-line max-len
                logger.debug(`Identified page that needs update: ${page.model.pageId} needs to be updated to doc ${latestVersion.model.id}`)
                newDocVersion = latestVersion
                return {
                  pageId: `${latestVersion.model.id}_${page.model.pageNumber}`,
                  pageNumber: page.model.pageNumber,
                  documentVersionId: latestVersion.model.id,
                }
              }
            }
            return page.model
          })
          if (newDocVersion) {
            hasChanges = true
            return {
              ...group.model,
              srcId: newDocVersion.model.id,
              pages: updatedPages,
            }
          }
        }
        return group.model
      })
      if (hasChanges) {
        // need to save new version of this deck
        logger.debug(`Updates detected for ${deck.model.id}. Updating...`)
        const now = new Date().toISOString();
        datastoreSave<CustomDeck>(CustomDeck, deck.model, {
          // Thanks to this issue we can't set to undefined
          // https://github.com/aws-amplify/amplify-js/issues/7565
          autoUpdateAcknowledgedAt: isAutoUpdate ? AUTO_UPDATE_DEFAULT_DATE : now,
          updatedAt: now,
          groups: updatedGroups,
        })
      }
    }
  })
}

export default customDeckSlice;
export const customDeckActions = {
  ...customDeckSlice.actions,
}
