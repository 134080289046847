import React from 'react'
import {
  DNABox,
  DNAText,
  luxColors,
} from '@alucio/lux-ui'

import { Controller, useFormContext } from 'react-hook-form'

import InputComponent from 'src/components/Publishers/InputComponent'

interface clientConfigDataType {
  key: string
  value: string
}

// convert this into a variant
const VeevaIntegration = () => {
  const { control, formState: { errors } } = useFormContext()

  return (
    <DNABox fill>
      <DNABox appearance="col" spacing="medium" fill>
        <DNABox appearance="col" spacing="small">
          <Controller
            as={InputComponent}
            name="name"
            title="Integration name"
            characterLimit={250}
            showCharacterCounter={true}
            required={true}
            rules={{ required: 'This field is required' }}
            control={control}
            removeMarginPadding
            inputStyle={errors.name && { borderColor: luxColors.error.primary }}
          />
          {errors.name && errors.name.type === 'required' &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.name.message}
            </DNAText>
          }
          {errors.name && errors.name.type === 'maxLength' &&
            <DNAText style={{ color: luxColors.error.primary }}>
              Maximum character limit is 250
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="small">
          <Controller
            as={InputComponent}
            name="clientConfigurationFields.domain"
            title="Vault domain name"
            required={true}
            rules={{ required: 'This field is required' }}
            control={control}
            removeMarginPadding
            inputStyle={
              (errors.clientConfigurationFields && errors.clientConfigurationFields.domain) &&
              { borderColor: luxColors.error.primary }}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.domain) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.domain.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="small">
          <Controller
            as={InputComponent}
            name="clientConfigurationFields.username"
            title="Username"
            required={true}
            rules={{ required: 'This field is required' }}
            control={control}
            removeMarginPadding
            inputStyle={
              (errors.clientConfigurationFields && errors.clientConfigurationFields.username) &&
              { borderColor: luxColors.error.primary }}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.username) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.username.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="small">
          <Controller
            as={InputComponent}
            name="clientConfigurationFields.relationType"
            title="Relation Type"
            required={false}
            rules={{ required: 'This field is required' }}
            control={control}
            removeMarginPadding
            inputStyle={
              (errors.clientConfigurationFields && errors.clientConfigurationFields.relationType) &&
              { borderColor: luxColors.error.primary }}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.relationType) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.relationType.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="small">
          <Controller
            as={InputComponent}
            name="clientConfigurationFields.namedFilter"
            title="Named Filter"
            control={control}
            removeMarginPadding
          />
        </DNABox>
        <DNABox appearance="col" spacing="small">
          <Controller
            as={InputComponent}
            name="clientConfigurationFields.vqlQuery"
            title="Vql Query"
            control={control}
            removeMarginPadding
            multiline
            numOfLines={3}
          />
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export default VeevaIntegration
