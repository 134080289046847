import { User } from '@alucio/aws-beacon-amplify/src/models'
import { DNABox, DNACard, DNACheckbox, DNADivider, DNAText, Iffy } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import React from 'react'
import { FlatList } from 'react-native'
import { isUserType, ShareFilter } from '../DNAShareFolderModal'

interface UserListProps {
  users: User[]
  onPress: (user: User) => void
  currentItems: ShareFilter[]
  selectedUsers: User[]
  searchText: string
}

// todo: evaluate the usage of DNAFlatList instead of this
const UserList: React.FC<UserListProps> = (props) => {
  const { users, onPress, currentItems, selectedUsers, searchText } = props;

  const userAlreadyExists = (id: string) => {
    return currentItems.some((item) => isUserType(item.filter) && item.filter.id === id && !item.isDeleted);
  }

  const isChecked = (id: string) => {
    return selectedUsers.some((user) => user.id === id);
  }

  const filteredUsers = searchText ? users.filter((user) => {
    const lowerCasedText = searchText.toLocaleLowerCase();
    return user.familyName.toLocaleLowerCase().includes(lowerCasedText) ||
        user.givenName.toLocaleLowerCase().includes(lowerCasedText) ||
        user.email.toLocaleLowerCase().includes(lowerCasedText)
  }) : users;

  return (
    <FlatList
      data={filteredUsers}
      scrollEnabled
      keyExtractor={(item) => item.id}
      renderItem={(row) => {
        return (
          <UserListRow
            user={row.item}
            onPress={onPress}
            isAlreadyAdded={userAlreadyExists(row.item.id)}
            isChecked={isChecked(row.item.id)}
          />
        )
      }}
    />
  )
}

interface UserListRowProps {
  user: User
  onPress: (user: User) => void;
  isAlreadyAdded: boolean
  isChecked: boolean
}

const UserListRow: React.FC<UserListRowProps> = (props) => {
  const { user, onPress, isAlreadyAdded, isChecked } = props;

  const toggleCheck = () => {
    if (!isAlreadyAdded) {
      onPress(user);
    }
  }

  return (
    <DNACard
      appearance="flat"
      interactive="pressableAlt"
      onPress={toggleCheck}
    >
      <DNABox appearance="col">
        <DNADivider />
        <DNABox testID="search-team-user-list" spacing="between" alignY="center" style={{ padding: 6 }}>
          <DNABox appearance="row" spacing="small" alignY="center" testID="search-team-result">
            <DNACheckbox
              testID="search-team-result-checkbox"
              checked={isChecked || isAlreadyAdded}
              onChange={toggleCheck}
              disabled={isAlreadyAdded}
            />
            <DNABox pointerEvents="none" appearance="col">
              <DNAText
                bold
                numberOfLines={1}
                style={{ color: colors['color-flat-1100'] }}
                testID="search-team-result-name"
              >
                {`${user.givenName} ${user.familyName} `}
              </DNAText>
              <DNAText
                style={{ color: colors['color-flat-700'] }}
                numberOfLines={1}
                testID="search-team-result-email"
              >
                {user.email}
              </DNAText>
            </DNABox>
          </DNABox>
          <Iffy is={isAlreadyAdded}>
            <DNAText testID="search-team-result-added" style={{ color: colors['color-flat-700'] }}>ADDED</DNAText>
          </Iffy>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default UserList;
