import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import {
  DNABox,
  DNADivider,
  DNAText,
  Icon,
  Iffy,
  Popover,
  luxColors,
  GenericToast,
  ToastOrientations,
} from '@alucio/lux-ui';
import InputComponent from '../Publishers/InputComponent';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useUserTenant } from 'src/state/redux/selector/user';
import {
  AssociatedFileStatus,
  AssociatedFileType,
  AttachedFile,
  EmailTemplate,
  EmailTemplateStatus,
  FieldConfig,
  LabelValue,
  ShareType,
} from '@alucio/aws-beacon-amplify/src/models';
import SelectInputComponent from '../Publishers/SelectInputComponent';
import { useFormik } from 'formik';
import DNACommonConfirmation from '../DNA/Modal/DNACommonConfirmation';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { useDispatch } from 'react-redux';
import EmailDrawerHeader from './EmailDrawerHeader';
import EmailDrawerFooter from './EmailDrawerFooter';
import { emailTemplateActions } from 'src/state/redux/slice/emailTemplate';
import useToast from '@alucio/lux-ui/lib/components/Toast/useToast';
import { emailTemplatePreview } from 'src/utils/shareLink/shareLink.web';
import { DRAWER_ENTITIES, drawerActions } from 'src/state/redux/slice/drawer';
import { useTemplateById } from 'src/state/redux/selector/emailTemplate';
import DNAEmailTemplateDeleteModal from '../DNA/Modal/DNAEmailTemplateDeleteModal';
import AssociatedFilesManager from '../DNA/AssociatedFiles/AssociatedFilesManager';
import { UploadStatus } from '../DNA/FileUpload/FileUpload';
import { AssociatedFileORM, DocumentORM, EmailTemplateORM, getAssociatedFileTitle, ORMTypes } from 'src/types/types';
import { createAssociatedFile } from 'src/state/redux/slice/documentVersion';
import { getAssociatedFileORMFromFile, useAllDocumentsInstance } from 'src/state/redux/selector/document';

const styles = StyleSheet.create({
  commonPadding: {
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  helpIcon: {
    color: colors['color-flat-700'],
    height: 13.3,
    width: 13.3,
  },
  inputStyle: {
    backgroundColor: colors['color-text-basic'],
    borderColor: colors['color-flat-400'],
  },
});

const BODY_DEFAULT_VALUE = 'The bracketed text below determines where the desired distributable file links ' +
  'will appear when this email template is shared externally. You can place the bracketed text in between paragraphs ' +
  'or anywhere within this template body area. \n\n[Files]';

const LABELS_DESCRIPTION = 'These labels will determine which ends users have \n' +
  'access to this template based on their assign filters.';

const BRACKETS_HELP = 'Inserting the [Files] bracketed text will determine where the desired \n' +
  'distributable file download links will appear when this email \n' +
  'template is shared externally by end users. \n\n' +
  'Inserting the [Attachments] bracketed text will determine where the \n' +
  'download links to the attachments will appear when this email \n' +
  'template is shared externally by end users. \n\n' +
  'Make sure you have only one instance of each in the body text area.';

const EMAIL_PLACEHOLDER = 'Multiple emails should be separated by commas (i.e. janesmith@email.com, johndoe@email.com)';

const REQUIRED_FIELD = 'This field is required.';

const EMAIL_ERROR = 'This is not a valid email format.';

const BODY_ERRORS = {
  noInstances: 'The template body needs to include [placeholder]. ' +
    'Please insert by clicking on the blue [placeholder] link.',
  manyInstances: 'The template body can only have one instance of [placeholder].',
};

const BODY_PLACEHOLDERS = {
  files: '[Files]',
  attachments: '[Attachments]',
}

export enum PERFORMING_ACTION {
  ACTIVATING,
  FIRST_DRAFT,
  SAVE_EXISTING,
}

export enum SUBMITTING_STATUS {
  NONE,
  SUBMITTING,
  SUCCESS
}

interface Props {
  templateId?: string,
  toggleDrawer: () => void,
}

function initFormikValues(emailTemplate?: EmailTemplate, tenantFields?: FieldConfig[]) {
  // FIRST, WE GET THE EMAILTEMPLATE VALUES
  const emailTemplateLabels = emailTemplate?.labelValues?.reduce((acc, labelValue) => {
    if (acc[labelValue.key]) {
      acc[labelValue.key].push(labelValue.value);
    } else {
      acc[labelValue.key] = [labelValue.value];
    }
    return acc;
  }, {}) || {};

  // THEN, WE ADD AN EMPTY ARRAY OF THE TENANTS ONE THAT ARE NOT IN THE TEMPLATE
  tenantFields?.forEach(({ fieldName }) => {
    if (!emailTemplateLabels[fieldName]) {
      emailTemplateLabels[fieldName] = [];
    }
  });

  return {
    title: emailTemplate?.title || '',
    cc: emailTemplate?.cc?.join(', ') || '',
    bcc: emailTemplate?.bcc?.join(', ') || '',
    body: emailTemplate?.body || BODY_DEFAULT_VALUE,
    subject: emailTemplate?.subject || '',
    ...emailTemplateLabels,
  };
}

function areRequiredFieldsFilled(formikValues, tenantfields: FieldConfig[]): boolean {
  const areTextFilled = formikValues.title && formikValues.body && formikValues.subject;
  let areTenantFieldsFilled = true;

  tenantfields.forEach(({ fieldName }) => {
    if (areTenantFieldsFilled) {
      areTenantFieldsFilled = formikValues[fieldName].length;
    }
  });

  return !!areTextFilled && areTenantFieldsFilled;
}

function getFormattedLabelValues(formikValues, tenantFields?: FieldConfig[]): LabelValue[] {
  const formattedValues: LabelValue[] = [];

  tenantFields?.forEach(({ fieldName }) => {
    formikValues[fieldName].forEach((value) => {
      formattedValues.push({
        key: fieldName,
        value: value,
      });
    });
  });

  return formattedValues;
}

function validateBodyBrackets(fieldValue: string, hasAttachments: boolean) {
  const errors: string[] = []
  for (const placeholder in BODY_PLACEHOLDERS) {
    const replaceRegex = new RegExp(/\[placeholder\]/, 'ig')
    const firstIndex = fieldValue.indexOf(BODY_PLACEHOLDERS[placeholder]);
    if ((placeholder === 'files' || (placeholder === 'attachments' && hasAttachments)) && firstIndex === -1) {
      // NO PRESENCE OF PLACEHOLDER
      errors.push(BODY_ERRORS.noInstances.replaceAll(replaceRegex, BODY_PLACEHOLDERS[placeholder]))
      continue;
    }

    const secondIndex = fieldValue.substring(firstIndex + 1).indexOf(BODY_PLACEHOLDERS[placeholder]);

    // MORE THAN ONCE
    if (secondIndex !== -1) {
      errors.push(BODY_ERRORS.manyInstances.replaceAll(replaceRegex, BODY_PLACEHOLDERS[placeholder]))
    }
  }
  return errors.join('\n')
}

const EmailTemplateDrawer = (props: Props) => {
  const tenant = useUserTenant();
  const allDocumentORM = useAllDocumentsInstance()
  const emailTemplateORM = useTemplateById(props.templateId);
  const performingAction = useRef<PERFORMING_ACTION>();
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({});
  const dispatch = useDispatch();
  const [submittingStatus, setSubmittingStatus] = useState<SUBMITTING_STATUS>(SUBMITTING_STATUS.NONE);
  // eslint-disable-next-line max-len
  const tenantFields: FieldConfig[] = tenant?.fields.filter(({ isEmailTemplateFilter }) => !!isEmailTemplateFilter) || [];
  const bodyInputStartCursor = useRef(0);
  const bodyInputEndCursor = useRef(0);
  const lostBodyInputFocusAt = useRef((new Date()).getTime());
  const lastErrorsRef = useRef({});
  const toast = useToast();

  // This state ORM will be used to work with attached files, using a dummy when we are
  // creating a new email template
  const [latestEmailTemplateORM, setLatestEmailTemplateORM] = useState<EmailTemplateORM>({
    model: emailTemplateORM
      ? emailTemplateORM.model
      : {
        id: '',
        tenantId: '',
        status: EmailTemplateStatus.DRAFT,
        createdBy: '',
        updatedBy: '',
      },
    relations: {
      associatedFiles: emailTemplateORM ? [...emailTemplateORM.relations.associatedFiles] : [],
    },
    type: ORMTypes.EMAIL_TEMPLATE,
  })
  const [searching, setSearching] = useState<boolean>(false);
  const [processingAttachedFiles, setProcessingAttachedFiles] = useState<boolean>(false)

  const activeAssociatedFiles = latestEmailTemplateORM
    .relations
    .associatedFiles
    .filter(af => af.model.status === 'ACTIVE')

  const formik = useFormik({
    initialValues: initFormikValues(emailTemplateORM?.model, tenantFields),
    enableReinitialize: false,
    onSubmit: async (values) => {
      // THIS MEANS, THE 3 SECONDS AFTER THE SAVE ACTION HAVEN'T PASSED YET
      if (submittingStatus === SUBMITTING_STATUS.SUCCESS) {
        return;
      }

      setSubmittingStatus(SUBMITTING_STATUS.SUBMITTING);

      const formattedLabelValues = getFormattedLabelValues(values, tenantFields);

      const payload = {
        title: values.title,
        subject: values.subject,
        cc: (values.cc && values.cc.split(',')) || [],
        bcc: (values.bcc && values.bcc.split(',')) || [],
        body: values.body,
        labelValues: formattedLabelValues,
        associatedFiles: latestEmailTemplateORM.relations.associatedFiles.map(afORM =>
          afORM.model,
        ),
      };

      if (emailTemplateORM) {
        const isSavingDraft = emailTemplateORM?.model.status === EmailTemplateStatus.DRAFT &&
          performingAction.current === PERFORMING_ACTION.SAVE_EXISTING;
        const isReactivating = emailTemplateORM?.model.status === EmailTemplateStatus.DEACTIVATED &&
          performingAction.current === PERFORMING_ACTION.ACTIVATING;

        dispatch(emailTemplateActions.batchSave({
          model: EmailTemplate,
          entity: emailTemplateORM?.model,
          updates: {
            ...payload,
            status: isSavingDraft ? EmailTemplateStatus.DRAFT : EmailTemplateStatus.ACTIVE,
          },
        }))

        onComplete(emailTemplateORM?.model, isReactivating)
      } else {
        dispatch(emailTemplateActions.create({
          ...payload,
          status: performingAction.current === PERFORMING_ACTION.FIRST_DRAFT
            ? EmailTemplateStatus.DRAFT
            : EmailTemplateStatus.ACTIVE,
          callback: onComplete,
        }));
      }

      function onComplete(emailTemplateResponse: EmailTemplate, isReactivating?: boolean): void {
        let showToast = false;
        let toastMsg = '';

        if (performingAction.current === PERFORMING_ACTION.ACTIVATING) {
          showToast = true;
          toastMsg = isReactivating
            ? 'Email template reactivated'
            : 'Email template activated';
        }

        if (performingAction.current === PERFORMING_ACTION.SAVE_EXISTING &&
          emailTemplateORM?.model.status === EmailTemplateStatus.ACTIVE) {
          showToast = true;
          toastMsg = 'Email template updated'
        }

        if (showToast) {
          toast?.add(
            <GenericToast
              title={toastMsg}
              status="success"
            />,
            ToastOrientations.TOP_RIGHT,
          );

          props.toggleDrawer();
          return;
        }

        if (performingAction.current === PERFORMING_ACTION.FIRST_DRAFT) {
          dispatch(drawerActions.toggle({
            entity: DRAWER_ENTITIES.EMAIL_TEMPLATE,
            entityId: emailTemplateResponse.id,
            keepOpen: true,
          }));
        }

        setSubmittingStatus(SUBMITTING_STATUS.SUCCESS);
        formik.resetForm({ values });

        setTimeout(() => {
          setSubmittingStatus(SUBMITTING_STATUS.NONE);
        }, 3000);
      }
    },
  });

  const onPreviewPayload = useRef({
    cc: formik.values.cc,
    bcc: formik.values.bcc,
    subject: formik.values.subject,
    body: formik.values.body,
  });

  const [associatedFilesChanged, setAssociatedFilesChanged] = useState(false)
  const isActivateDisabled = Object.keys(errors).some((key) => errors[key]) ||
    !areRequiredFieldsFilled(formik.values, tenantFields);

  useEffect(() => {
    lastErrorsRef.current = errors;
  }, [errors]);

  // THE FOOTER RECEIVES A FUNCTION THAT, GIVEN THAT IS MEMOIZED,
  // IT DOESN'T ALWAYS HAVE THE FORMIK VALUES UPDATED
  useEffect(() => {
    onPreviewPayload.current = {
      cc: formik.values.cc,
      bcc: formik.values.bcc,
      subject: formik.values.subject,
      body: formik.values.body,
    };
  }, [formik.values.body, formik.values.cc, formik.values.bcc, formik.values.subject]);

  useEffect(() => {
    // Revalidate body
    onFieldUpdate('body', formik.values.body);
  }, [latestEmailTemplateORM])

  const onUpdateTitle = useCallback((value) => onFieldUpdate('title', value), []);
  const onUpdateSubjectLine = useCallback((value) => onFieldUpdate('subject', value), []);
  const onUpdateBody = useCallback((value) => onFieldUpdate('body', value), [activeAssociatedFiles]);
  const onUpdateCC = useCallback((value) => onFieldUpdate('cc', value), []);
  const onUpdateBCC = useCallback((value) => onFieldUpdate('bcc', value), []);

  // MEMOIZED COMPONENTS
  const FooterMemoized = useMemo(() =>
    (<EmailDrawerFooter
      onPreview={onPreview}
      closeDrawer={onCancel}
      isFormDirty={formik.dirty || associatedFilesChanged}
      submittingStatus={submittingStatus}
      emailTemplate={emailTemplateORM?.model}
      canActivate={!isActivateDisabled}
      onActivate={onActivate}
      onDelete={onDelete}
    />)
  , [emailTemplateORM,
    formik.dirty,
    isActivateDisabled,
    submittingStatus,
    associatedFilesChanged,
    activeAssociatedFiles]);

  const HeaderMemoized = useMemo(() =>
    (<EmailDrawerHeader
      onChangeTitle={onUpdateTitle}
      closeDrawer={onCancel}
      title={formik.values.title}
      placeHolder="Template Name"
      error={errors.title}
    />), [formik.values.title, formik.dirty]);

  // USED TO CAPTURE BODY TEXT INPUT EVENT AND CURSOR POSITION
  useEffect(() => {
    const bodyInputReference = document.querySelector('#email-template-form-body-input');

    function updateCursorRefPosition(event): void {
      // @ts-ignore
      bodyInputEndCursor.current = event?.target?.selectionEnd;
      // @ts-ignore
      bodyInputStartCursor.current = event?.target?.selectionStart;
    }

    // UPDATE THE CURSOR'S POSITION UPON CLICKING INSIDE THE TEXTAREA
    bodyInputReference?.addEventListener('click', updateCursorRefPosition);

    // UPDATE THE CURSOR'S POSITION UPON TYPING
    bodyInputReference?.addEventListener('keyup', updateCursorRefPosition);

    // AS SOON AS THE USER PRESSES THE BUTTON, THE FOCUS IS LOST
    // BUT WE STILL WANT, AT THIS MOMENT, TO ADD THE TEXT WHERE
    // THE FOCUS WAS (OTHERWISE IT'LL GO TO THE END) SO WE KEEP TRACK
    // OF WHEN THE FOCUS WAS LOST
    bodyInputReference?.addEventListener('blur', () => {
      lostBodyInputFocusAt.current = (new Date()).getTime();
    });

    return () => {
      bodyInputReference?.removeEventListener('click', () => { });
      bodyInputReference?.removeEventListener('keyup', () => { });
      bodyInputReference?.removeEventListener('blur', () => { });
      bodyInputReference?.removeEventListener('focus', () => { });
    };
  });

  function onDelete(): void {
    if (!emailTemplateORM) return;

    const payload = {
      isVisible: true,
      allowBackdropCancel: false,
      component: () =>
        (<DNAEmailTemplateDeleteModal
          toast={toast}
          emailTemplate={emailTemplateORM.model}
          onDelete={props.toggleDrawer}
        />),
    };

    dispatch(DNAModalActions.setModal(payload));
  }

  function onPreview(): void {
    const assocFilesInfo = activeAssociatedFiles
      .filter(af => af.meta.canBeSharedByMSL)
      .map(af => {
        let contentId = af.file?.id!
        if (af.model.type === 'DOCUMENT') {
          const [currentAssociatedDoc] = allDocumentORM
            .filter(currentDoc => af.file?.id! === currentDoc.model.id)
          contentId = currentAssociatedDoc != null
            ? currentAssociatedDoc.relations.version.latestDocumentVersionPublished?.model.id!
            : contentId
        }
        return {
          title: getAssociatedFileTitle(af),
          contentId,
          sharedContentId: af.model.id,
          type: af.model.type === 'ATTACHED_FILE' ? ShareType.ATTACHED_FILE : ShareType.DOCUMENT_VERSION,
        }
      })

    emailTemplatePreview(
      onPreviewPayload.current.subject,
      onPreviewPayload.current.body,
      onPreviewPayload.current.cc.split(','),
      onPreviewPayload.current.bcc.split(','),
      assocFilesInfo,
    );

    analytics?.track('EMAIL_PREVIEW', {
      action: 'EMAIL_PREVIEW',
      category: 'EMAIL',
      emailTemplateId: emailTemplateORM?.model.id,
    });
  }

  function onFieldUpdate(fieldName: string, fieldValue: string): void {
    formik.setFieldValue(fieldName, fieldValue);
    switch (fieldName) {
      case 'title':
        setErrors({ ...lastErrorsRef.current, ...{ [fieldName]: !fieldValue.trim() ? REQUIRED_FIELD : undefined } });
        break;

      case 'subject':
        setErrors({ ...lastErrorsRef.current, ...{ [fieldName]: !fieldValue.trim() ? REQUIRED_FIELD : undefined } });
        break;

      case 'body': {
        const errors = validateBodyBrackets(fieldValue, activeAssociatedFiles.length > 0)
        setErrors({ ...lastErrorsRef.current, ...{ [fieldName]: errors.length > 0 ? errors : undefined } });
        break;
      }

      // CC OR BCC
      default: {
        // FIRST, WE SEPARATE ALL THE EMAILS
        const individualEmails = fieldValue.split(',');
        let isValidEmail = true;

        // THEN, WE CHECK THEM INDIVIDUALLY
        individualEmails.forEach((email) => {
          const trimmed = email.trim();
          if (isValidEmail && trimmed !== '') {
            // eslint-disable-next-line max-len,no-useless-escape
            isValidEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(trimmed);
          }
        });

        setErrors({ ...lastErrorsRef.current, ...{ [fieldName]: isValidEmail ? undefined : EMAIL_ERROR } });
      }
    }
  }

  function onMultipleValuesFieldUpdate(fieldName: string, values: string[]): void {
    formik.setFieldValue(fieldName, values);
    setErrors({ ...lastErrorsRef.current, ...{ [fieldName]: !values.length ? REQUIRED_FIELD : undefined } });
  }

  function onCancel(): void {
    // ONLY SHOW THE CONFIRMATION MODAL IS THERE IS AN UNSAVED MODIFICATION
    if (formik.dirty) {
      const payload = {
        isVisible: true,
        allowBackdropCancel: false,
        component: () =>
          (<DNACommonConfirmation
            status="danger"
            cancelText="Cancel"
            descriptionText="You will lose all unsaved changes."
            confirmActionText="Close without saving"
            onConfirmAction={props.toggleDrawer}
            title="Unsaved changes"
          />),
      };

      dispatch(DNAModalActions.setModal(payload));
    } else {
      props.toggleDrawer();
    }
  }

  function onActivate(action: PERFORMING_ACTION): void {
    performingAction.current = action;

    function activate(): void {
      formik.submitForm().then(() => {
        const analyticsActionName = emailTemplateORM?.model.id
          ? (action === PERFORMING_ACTION.SAVE_EXISTING
            ? 'EMAIL_SAVED'
            : (emailTemplateORM?.model.status === EmailTemplateStatus.ACTIVE
              ? 'EMAIL_SAVED'
              : 'EMAIL_ACTIVATED'))
          : 'EMAIL_CREATED';
        analytics?.track(analyticsActionName, {
          action: analyticsActionName,
          category: 'EMAIL',
          emailTemplateId: emailTemplateORM?.model.id,
        });
      }).catch((err) => {
        console.warn('Error occured submitting email template form', err)
      });
    }

    const isFirstDraft = action !== PERFORMING_ACTION.ACTIVATING && !emailTemplateORM;
    const isUpdateNotActive = action === PERFORMING_ACTION.SAVE_EXISTING &&
      emailTemplateORM?.model.status !== EmailTemplateStatus.ACTIVE;
    if (isFirstDraft || isUpdateNotActive) {
      activate();
      return;
    }

    const isActive = emailTemplateORM?.model.status === EmailTemplateStatus.ACTIVE;
    const payload = {
      isVisible: true,
      allowBackdropCancel: false,
      component: () =>
        (<DNACommonConfirmation
          cancelText="Cancel"
          descriptionText={isActive
            ? 'The changes made to this template will go live.'
            : 'Once activated, this template will go live.'
        }
          confirmActionText={isActive ? 'Update' : 'Activate'}
          onConfirmAction={activate}
          title={isActive ? 'Update Template' : 'Confirm activation?'}
        />),
    };

    dispatch(DNAModalActions.setModal(payload));
  }

  const insertBrackets = (placeholder: string): void => {
    const wasTheInputFocused = (new Date()).getTime() - lostBodyInputFocusAt.current < 300;

    if (wasTheInputFocused) {
      const newBody = formik.values.body.substring(0, bodyInputStartCursor.current) +
        placeholder +
        formik.values.body.substring(bodyInputEndCursor.current);

      const cursorNewPosition = bodyInputStartCursor.current + placeholder.length;
      bodyInputStartCursor.current = cursorNewPosition;
      bodyInputEndCursor.current = cursorNewPosition;

      onFieldUpdate('body', newBody);

      // AFTER ADDING THE [Files] TO THE TEXT, WE NEED TO REFOCUS THE CURSOR AT THAT POINT
      // @ts-ignore
      document.querySelector('#email-template-form-body-input')?.focus();
      setTimeout(() => {
        // @ts-ignore
        // eslint-disable-next-line max-len
        document.querySelector('#email-template-form-body-input')?.setSelectionRange(cursorNewPosition, cursorNewPosition);
      }, 200);
    } else {
      onFieldUpdate('body', formik.values.body + placeholder);
      const cursorNewPosition = formik.values.body.length + 7;
      bodyInputStartCursor.current = cursorNewPosition;
      bodyInputEndCursor.current = cursorNewPosition;
      setTimeout(() => {
        // @ts-ignore
        document.querySelector('#email-template-form-body-input')?.focus();
      }, 100);
    }
  };

  const handleMultipleValuesFieldUpdate = (
    values: string | string[],
    field?: FieldConfig | undefined,
  ) => field && onMultipleValuesFieldUpdate(
    field.fieldName, Array.isArray(values) ? values : [values],
  )

  const handleUploadStatusChange = (status: UploadStatus) => {
    const hasStartedProcessing = processingAttachedFiles === false && status === 'IN_PROGRESS'
    const hasFinishedProcessing = processingAttachedFiles === true && status !== 'IN_PROGRESS'

    if (hasStartedProcessing) {
      setProcessingAttachedFiles(true)
    }
    else if (hasFinishedProcessing) {
      setProcessingAttachedFiles(false)
    }
  }

  const handleSearchBoxSelect = (parentDoc: EmailTemplateORM, linkedDoc: DocumentORM) => {
    const newAssociatedFile = createAssociatedFile({
      attachmentId: linkedDoc.model.id,
      type: AssociatedFileType.DOCUMENT,
      status: AssociatedFileStatus.ACTIVE,
    })

    setLatestEmailTemplateORM({
      ...parentDoc,
      relations: {
        ...parentDoc.relations,
        associatedFiles: [
          ...parentDoc.relations.associatedFiles ?? [],
          getAssociatedFileORMFromFile(linkedDoc.model, newAssociatedFile),
        ],
      },
    })
    setAssociatedFilesChanged(true)
  }

  const handleAssociatedFileDelete = (emailTemplateORM: EmailTemplateORM, item: DocumentORM | AttachedFile) => {
    const itemId = item.type === 'DOCUMENT' ? item.model.id : item.id

    const updatedAssociatedFiles = emailTemplateORM
      .relations
      .associatedFiles
      ?.map(associated => {
        if (associated.file?.id === itemId) {
          return {
            ...associated,
            model: {
              ...associated.model,
              status: AssociatedFileStatus.DELETED,
            },
          }
        }

        return associated
      })

    setLatestEmailTemplateORM({
      ...emailTemplateORM,
      relations: {
        ...emailTemplateORM.relations,
        associatedFiles: [
          ...(updatedAssociatedFiles || []),
        ],
      },
    })

    const markAsChange = emailTemplateORM
      ? emailTemplateORM.relations.associatedFiles.some(af => af.model.attachmentId === itemId)
      : false
    setAssociatedFilesChanged(markAsChange)
  }

  const handleFinishedUpload = (attachedFile: AttachedFile) => {
    const newAssociatedFile = createAssociatedFile({
      attachmentId: attachedFile.id,
      type: AssociatedFileType.ATTACHED_FILE,
      status: 'ACTIVE',
    })
    const newAssocFileORM: AssociatedFileORM = {
      model: newAssociatedFile,
      meta: {
        canBeSharedByMSL: true,
      },
      type: ORMTypes.ASSOCIATED_FILE,
      file: attachedFile,
      relations: {},
    }
    const updatedAssociatedFiles = [
      ...latestEmailTemplateORM.relations.associatedFiles ?? [],
      newAssocFileORM,
    ]

    setLatestEmailTemplateORM({
      ...latestEmailTemplateORM,
      relations: {
        ...latestEmailTemplateORM.relations,
        associatedFiles: [
          ...(updatedAssociatedFiles || []),
        ],
      },
    })
    setAssociatedFilesChanged(true)
  }

  return (
    <DNABox appearance="col" fill>
      { /* HEADER */}
      {HeaderMemoized}
      <DNADivider />
      { /* CONTENT */}
      <DNABox appearance="col" style={[styles.commonPadding, { overflow: 'scroll' }]} fill>
        <Iffy is={tenantFields.length}>
          <DNABox appearance="col" spacing="medium">
            <DNABox spacing="between">
              <DNABox alignY="center">
                <DNAText status="flatAlt" bold>Template Labels </DNAText>
                <Popover>
                  <Popover.Anchor>
                    <Icon style={styles.helpIcon} name="help-circle" />
                  </Popover.Anchor>
                  <Popover.Content>
                    <DNAText status="basic">{LABELS_DESCRIPTION}</DNAText>
                  </Popover.Content>
                </Popover>
              </DNABox>
              <DNAText status="danger">* Required fields</DNAText>
            </DNABox>
            { /* CUSTOM FIELDS */}
            {
              tenantFields.map((field, idx) => (
                <DNABox key={field.fieldName + idx} appearance="col" spacing="tiny">
                  <SelectInputComponent
                    status="flat"
                    removeMarginPadding={true}
                    title={field.fieldName}
                    multiSelect={true}
                    required={true}
                    availableValues={field.values}
                    onChangeSelection={handleMultipleValuesFieldUpdate}
                    value={formik.values[field.fieldName]}
                    field={field}
                    titleColor={colors['color-flat-900']}
                  />
                  <Iffy is={errors[field.fieldName]}>
                    <DNAText c1 status="danger">{errors[field.fieldName]}</DNAText>
                  </Iffy>
                </DNABox>
              ))
            }
          </DNABox>
        </Iffy>
        { /* TEMPLATE CONTENT */}
        <DNABox appearance="col" spacing="medium" style={{ marginTop: 32 }}>
          <DNABox spacing="between">
            <DNAText status="flatAlt" bold>Template Content</DNAText>
            <Iffy is={!tenantFields.length}>
              <DNAText status="danger">* Required fields</DNAText>
            </Iffy>
          </DNABox>
          <DNABox appearance="col" spacing="tiny">
            <InputComponent
              hidePlaceholder
              inputStyle={styles.inputStyle}
              titleColor={colors['color-flat-900']}
              removeMarginPadding
              title="SUBJECT LINE"
              required={true}
              onChangeText={onUpdateSubjectLine}
              numOfLines={1}
              value={formik.values.subject}
            />
            <Iffy is={errors.subject}>
              <DNAText c1 status="danger">{errors.subject}</DNAText>
            </Iffy>
          </DNABox>
          <DNABox appearance="col" spacing="tiny">
            <InputComponent
              placeholderTextColor={luxColors.contentText.quaternary}
              inputStyle={styles.inputStyle}
              titleColor={colors['color-flat-900']}
              removeMarginPadding
              required={false}
              placeHolder={EMAIL_PLACEHOLDER}
              title="CC"
              numOfLines={1}
              onChangeText={onUpdateCC}
              value={formik.values.cc}
            />
            <Iffy is={errors.cc}>
              <DNAText c1 status="danger">{errors.cc}</DNAText>
            </Iffy>
          </DNABox>
          <DNABox appearance="col" spacing="tiny">
            <InputComponent
              placeholderTextColor={luxColors.contentText.quaternary}
              inputStyle={styles.inputStyle}
              titleColor={colors['color-flat-900']}
              removeMarginPadding
              required={false}
              placeHolder={EMAIL_PLACEHOLDER}
              title="BCC"
              onChangeText={onUpdateBCC}
              numOfLines={1}
              value={formik.values.bcc}
            />
            <Iffy is={errors.bcc}>
              <DNAText c1 status="danger">{errors.bcc}</DNAText>
            </Iffy>
          </DNABox>
          <DNABox appearance="col" spacing="tiny">
            <DNABox spacing="between" alignY="center">
              <DNABox>
                <DNAText status="danger">* </DNAText>
                <DNAText c2 bold>BODY</DNAText>
              </DNABox>
              <DNABox alignY="center" spacing="tiny">
                <TouchableOpacity onPress={() => insertBrackets(BODY_PLACEHOLDERS.files)}>
                  <DNAText status="primary" b2>
                    [Files]
                  </DNAText>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => insertBrackets(BODY_PLACEHOLDERS.attachments)}>
                  <DNAText status="primary" b2>
                    [Attachments]
                  </DNAText>
                </TouchableOpacity>
                <Popover>
                  <Popover.Anchor>
                    <Icon style={styles.helpIcon} name="help-circle" />
                  </Popover.Anchor>
                  <Popover.Content>
                    <DNAText status="basic">{BRACKETS_HELP}</DNAText>
                  </Popover.Content>
                </Popover>
              </DNABox>
            </DNABox>
            <DNABox appearance="col" spacing="tiny">
              <InputComponent
                testID="email-template-form-body-input"
                title=""
                hideLabel={true}
                hidePlaceholder
                inputStyle={styles.inputStyle}
                removeMarginPadding
                required={true}
                multiline={true}
                numOfLines={6}
                value={formik.values.body}
                onChangeText={onUpdateBody}
              />
              <Iffy is={errors.body}>
                <DNAText c1 status="danger">{errors.body}</DNAText>
              </Iffy>
            </DNABox>
          </DNABox>
          <DNABox appearance="col" spacing="tiny">
            <AssociatedFilesManager
              entityORM={latestEmailTemplateORM!}
              canLinkFiles={true}
              canUploadFiles={true}
              isDisabled={false}
              associatedFilesMode="EMAIL_ATTACHMENT"
              searching={searching}
              setSearching={setSearching}
              emptyMessage="No associated files"
              files={activeAssociatedFiles}
              onUploadStatusChange={handleUploadStatusChange}
              onSelect={handleSearchBoxSelect}
              onFinishedUpload={handleFinishedUpload}
              onDelete={handleAssociatedFileDelete}
              showTooltip={false}
            />
          </DNABox>
        </DNABox>
      </DNABox>
      <DNABox />
      { /* FOOTER */}
      {FooterMemoized}
    </DNABox>
  );
};

EmailTemplateDrawer.displayName = 'EmailTemplateDrawer';

export default EmailTemplateDrawer;
