import { DNAButton, DNAContextMenu } from '@alucio/lux-ui';
import React from 'react';
import { useDispatch } from 'react-redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { drawerActions, DRAWER_ENTITIES } from 'src/state/redux/slice/drawer';
import { MeetingORM } from 'src/types/orms';
import DNAMeetingDeleteModal from '../DNA/Modal/DNAMeetingDeleteModal';

interface Props {
  meetingORM: MeetingORM
}

const MeetingContextMenu: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { meetingORM } = props;

  const openDeleteModal = () => {
    if (meetingORM) {
      const payload = {
        isVisible: true,
        allowBackdropCancel: false,
        component: () =>
          (<DNAMeetingDeleteModal
            meeting={meetingORM?.model}
          />),
      };
      dispatch(DNAModalActions.setModal(payload));
    }
  }

  const toggleDrawer = () => {
    dispatch(drawerActions.toggle({
      entity: DRAWER_ENTITIES.MEETING,
      entityId: meetingORM.model.id,
    }));
  }

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        <DNAButton
          status="subtle"
          appearance="ghostAlt"
          context="minimum"
          iconLeft="dots-vertical"
        />
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        <DNAContextMenu.Item
          onPress={toggleDrawer}
          status="dark"
          icon="pencil"
          title="Edit"
        />
        <DNAContextMenu.Item
          onPress={openDeleteModal}
          status="dark"
          icon="trash-can-outline"
          title="Delete"
        />
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default MeetingContextMenu;
