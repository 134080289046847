import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import store from '../../redux/store';
import API, { graphqlOperation } from '@aws-amplify/api';
import {
  virtualCreateSession,
  virtualUpdateAttendeeStatus,
  virtualTerminateSession,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import { syncVirtualAttendees } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import {
  ATTENDEE_STATUS,
  ModelVirtualAttendeeFilterInput,
  UpdateVirtualAttendeeInput,
} from '@alucio/aws-beacon-amplify/src/API';
import {
  onCreateVirtualAttendee,
  onUpdateVirtualAttendee,
} from '@alucio/aws-beacon-amplify/src/graphql/subscriptions';

export type SessionCreateGQL = {
  data: {
    virtualCreateSession: {
      joinToken: string
    }
  }
}

type updateAttendeeStatusType = {
  id?: string,
  sessionId: string,
  attendeeId: string,
  status: ATTENDEE_STATUS,
  identity: string,
}

type virtualAttendeeType = {
  joinToken: string,
  phonePin: string,
  session: {
    id: string,
    hostPhonePin: string,
    roomName: string
  },
  existingAttendees: Array<attendeeType>
}

type virtualSliceType = {
  unsubscribe: () => void,
  virtualAttendee: virtualAttendeeType,
  attendees: Record<string, UpdateVirtualAttendeeInput>
}

type subscribeToVirtualAttendeeEventsType = {
  hostId: string
}

type endCallProps = {
  id: string
}

export type SliceState = {
  session: {
    id?: string,
    joinToken?: string
  }
}

type attendeeType = {
  status: string,
  id: string,
  name: string
}

const getToken = createAsyncThunk(
  'virtual/getToken',
  async () => {
    try {
      const payload = await API.graphql(graphqlOperation(virtualCreateSession)) as SessionCreateGQL;
      return payload.data.virtualCreateSession;
    }
    catch (err) {
      // To log in the logger tool
      console.error(`error generating host token ${JSON.stringify(err)}`);
      throw new Error(`error generating token ${JSON.stringify(err)}`);
    }
  },
)
const subscribeToVirtualAttendeeEvents = createAsyncThunk(
  'virtual/onVirtualAttendeeEvent',
  async (props: subscribeToVirtualAttendeeEventsType) => {
    try {
      const createSubscription = API.graphql(
        graphqlOperation(onCreateVirtualAttendee, {
          hostId: props.hostId,
        }),
        // @ts-ignore <For some reason typesccript doesnt accept the subscribe method>
      ).subscribe({
        next: async ({ value }) => {
          store.dispatch(virtualSlice.actions.updateAttendee(
            value.data.onCreateVirtualAttendee,
          ));
        },
      });

      const updateSubscription = API.graphql(
        graphqlOperation(onUpdateVirtualAttendee, {
          hostId: props.hostId,
        }),
        // @ts-ignore <For some reason typesccript doesnt accept the subscribe method>
      ).subscribe({
        next: async ({ value }) => {
          const currentState = store.getState();
          const updatedAttendee = value.data.onUpdateVirtualAttendee;
          // We only want to handle updates of attendees that we're currently dealing with
          if (currentState.virtual.attendees[`${updatedAttendee.id}.video.${updatedAttendee.name}`]) {
            store.dispatch(virtualSlice.actions.updateAttendee(
              updatedAttendee,
            ));
          } else {
            // eslint-disable-next-line no-console
            console.info(`Update received for unknown attendee ${updatedAttendee.id}`);
          }
        },
      });

      return () => {
        createSubscription.unsubscribe();
        updateSubscription.unsubscribe();
      };
    }
    catch (ex) {
      // To log in the logger tool
      console.error(ex);
      // eslint-disable-next-line no-throw-literal
      throw (`Error subscribing to onCreateVirtualAttendee or onUpdateVirtualAttendee ${JSON.stringify(ex)}`)
    }
  },
)

const updateAttendeeStatus = createAsyncThunk(
  'virtual/updateAttendeeStatus',
  async (input: updateAttendeeStatusType) => {
    if (!input.identity.startsWith('recorder')) {
      try {
        // To avoid have an small in the ui, etheir way will be changed at the end of the call of the method
        store.dispatch(virtualSlice.actions.changeAttendeeStatus(input));
        const status = await API.graphql(
          graphqlOperation(virtualUpdateAttendeeStatus, {
            sessionId: input.sessionId,
            attendeeId: input.attendeeId,
            status: input.status,
          }));
        return status;
      } catch (ex) {
        // To log in the logger tool
        console.error(new Error().stack);
        // eslint-disable-next-line no-throw-literal
        throw `error creating updatint the status of ${input.attendeeId} ${JSON.stringify(ex)}`;
      }
    }
  },
);

const getCurrentAttendees = createAsyncThunk('virtual/currentAttendees',
  async (props: ModelVirtualAttendeeFilterInput) => {
    try {
      // @ts-ignore
      const { data: { syncVirtualAttendees: { items } } } = await API.graphql(graphqlOperation(syncVirtualAttendees, {
        filter: props,
      }));
      store.dispatch(virtualSlice.actions.setInitialAttendees(items));
      return items;
    }
    catch (ex) {
      // To log in the logger tool
      console.error(`error getting current attendees ${JSON.stringify(ex)}`);
      // eslint-disable-next-line no-throw-literal
      throw `error getting the current attendees ${JSON.stringify(ex)}`;
    }
  },
)

const endCall = createAsyncThunk('virtual/endCall',
  async (props: endCallProps) => {
    await API.graphql(
      graphqlOperation(virtualTerminateSession, {
        sessionId: props.id,
      }),
    );
    // Cleanup the stores
    store.dispatch(virtualSlice.actions.clearState());
  },
)
const initialState = {
  unsubscribe: () => { },
  virtualAttendee: {
    joinToken: '',
    phonePin: '',
    session: {
      id: '',
    },
  },
  attendees: {},
} as virtualSliceType;

const virtualSlice = createSlice({
  name: 'VirtualState',
  initialState: initialState,
  reducers: {
    setSession: (state, { payload }) => {
      return { ...state, phonePin: payload.hostPhonePin, session: payload };
    },
    updateAttendee: (state, { payload }) => {
      const identity = `${payload.id}.video.${payload.name}`;
      // Ignore content recorder user
      const isAContentRecorderUser = payload.id === 'recorder';
      // Ignore stale updates for attendees (caused by lastSeenAt notifications). An attendee can't go backwards to PENDING which is the first state.
      const isAValidStatus = !state.attendees[identity] ||
      payload.status !== ATTENDEE_STATUS.PENDING ||
      state.attendees[identity].status === payload.status
      if (isAValidStatus && !isAContentRecorderUser)
      {
        state.attendees[identity] = payload;
      }
      return state;
    },
    setInitialAttendees: (state, { payload }) => {
      payload.filter(({ id }) => (id !== 'recorder'))
        .map((e: UpdateVirtualAttendeeInput) => {
          state.attendees[`${e.id}.video.${e.name}`] = e;
        })
      return state;
    },
    changeAttendeeStatus: (state, action: PayloadAction<updateAttendeeStatusType>) => {
      let identity = action.payload.identity;

      // IF WE'RE UPDATING DIRECTLY A "PHONE" PARTICIPANT, IT MEANS THEIR "VIDEO" CONNECTION  GOT LOST
      // BUT IN REDUX, THE PARTICIPANT'S NAME WE USE IS THE VIDEO ONE. SO WE MAKE TO UPDATE THE RIGHT ONE
      if (identity.split('.')[1] === 'phone') {
        const identityParts = identity.split('.');
        identity = `${identityParts[0]}.video.${identityParts.slice(3).join('.')}`;
      }

      state.attendees[identity].status = action.payload.status;
      return state;
    },
    unsubscribe: (state, _) => {
      state.unsubscribe();
    },
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: {
    [`${getToken.fulfilled}`]: (state, action: PayloadAction<virtualAttendeeType>) => {
      const { existingAttendees } = action.payload;
      state.virtualAttendee = action.payload;
      state.virtualAttendee.phonePin = action.payload.session.hostPhonePin;

      // Get current connected users
      state.attendees = existingAttendees.reduce((acc, next) => {
        if (next.status === 'CONNECTED') {
          acc[`${next.id}.video.${next.name}`] = next;
        }
        return acc;
      }, {})
    },
    [subscribeToVirtualAttendeeEvents.fulfilled.toString()]: (state, action) => {
      state.unsubscribe = action.payload;
    },
    [updateAttendeeStatus.fulfilled.toString()]: (state, { payload }) => {
      const attendee = payload?.data?.virtualUpdateAttendeeStatus;
      if (attendee && attendee?.name && attendee.id && attendee?.id !== 'recorder') {
        state.attendees[`${attendee.id}.video.${attendee.name}`] = attendee;
      }
      return state;
    },
  },
});
export default virtualSlice
export const virtualActions = {
  ...virtualSlice.actions,
  getToken,
  endCall,
  updateAttendeeStatus,
  subscribeToVirtualAttendeeEvents,
  getCurrentAttendees,
}
