import React, { useState } from 'react';
import {
  DNABox,
  DNAButton,
  DNAText,
  Iffy,
  Popover,
} from '@alucio/lux-ui';
import { StyleSheet } from 'react-native';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import useFullScreen from 'src/hooks/useFullScreen/useFullScreen';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import PresentationNav from '../SharedComponents/PresentationNav';
import OpenedPresentations from '../PresentationControls/TabContent/OpenedPresentations/OpenedPresentations';

const style = StyleSheet.create({
  actionsContainer: {
    backgroundColor: colors['color-dark-1000'],
    padding: 8,
    borderTopLeftRadius: 4,
    minHeight: 40,
  },
  presentationList: {
    backgroundColor: colors['color-dark-1000'],
    borderRadius: 4,
    width: 480,
    minHeight: 400,
    maxHeight: 500,
  },
})

interface Props {
  setDisplaySlideRoll: React.Dispatch<React.SetStateAction<boolean>>
}

const InPersonControls = (props: Props) => {
  const { endMeeting, setPresentationControlsVisible, presentationControlsVisible } = useMeetingsState();
  const { presentations } = useContent();
  const { isFullScreen, toggleFullScreen } = useFullScreen();
  const [showPresentations, setShowPresentations] = useState<boolean>(false);

  const openSidePanel = (): void => {
    props.setDisplaySlideRoll(false);
    setPresentationControlsVisible(true);
  };

  const toggleShowPresentations = () => {
    setShowPresentations((showPresentations) => !showPresentations)
  }

  return (
    <Iffy is={!presentationControlsVisible}>
      <DNABox spacing="small" alignY="center" alignX="end" style={style.actionsContainer}>
        <Iffy is={presentations.length}>
          <PresentationNav />
        </Iffy>
        <DNAButton
          context="square"
          size="tiny"
          appearance="filled"
          status="slate"
          onPress={openSidePanel}
        >
          My Content
        </DNAButton>
        <Iffy is={presentations.length}>
          <Popover
            lazyMount
            placement="top"
            interactive={true}
            visible={showPresentations}
            onBackdropPress={toggleShowPresentations}
            type="menu"
            offset={30}
          >
            <Popover.Anchor>
              <DNAButton
                context="square"
                size="tiny"
                appearance="filled"
                status="slate"
                onPress={toggleShowPresentations}
              >
                Presentations
              </DNAButton>
            </Popover.Anchor>
            <Popover.Content>
              <DNABox fill style={{ marginRight: 0, minWidth:480, maxHeight:500 }}>
                <OpenedPresentations inPersonControls={true} />
              </DNABox>
            </Popover.Content>
          </Popover>
        </Iffy>
        <Iffy is={presentations.length}>
          <DNAButton
            context="square"
            size="tiny"
            onPress={() => props.setDisplaySlideRoll((isShown) => !isShown)}
            appearance="filled"
            status="slate"
          >
            Slides
          </DNAButton>
        </Iffy>
        <DNAButton
          context="square"
          size="tiny"
          appearance="filled"
          status="slate"
          onPress={toggleFullScreen}
        >
          {isFullScreen ? 'Exit Full screen' : 'Full screen'}
        </DNAButton>
        <DNAButton
          context="square"
          size="tiny"
          status="danger"
          onPress={endMeeting}
        >
          <DNAText status="basic">End</DNAText>
        </DNAButton>
      </DNABox>
    </Iffy>
  );
};

InPersonControls.displayName = 'InPersonControls';

export default InPersonControls;
