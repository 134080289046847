import { useMemo } from 'react'
import { CustomDeck, DocumentStatus, Folder, FolderItem, FolderItemType } from '@alucio/aws-beacon-amplify/src/models'
import {
  CustomDeckORM,
  DocumentVersionORM,
  FolderItemORM,
  FolderORM,
  ORMTypes,
  VERSION_UPDATE_STATUS,
} from 'src/types/types'
import { RootState } from 'src/state/redux/store'

import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { allDocumentVersionMap, documentVersionORMMap } from 'src/state/redux/selector/document'
import { userTenant } from 'src/state/redux/selector/user'
import { FilterAndSortOptions, filterCollection, sortCollection } from 'src/state/redux/selector/common'
import { FOLDER_ITEM_STATUS } from '@alucio/aws-beacon-amplify/src/API'
import { isCustomDeckORM, isDocumentVersionORM, isFolderORM } from 'src/types/typeguards'
import { AUTO_UPDATE_DEFAULT_DATE } from '../slice/customDeck'
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector'
import { IndexedUserORM, indexedUsersList } from './user'
import addDays from 'date-fns/addDays'
import isPast from 'date-fns/isPast'

export type SelectIdOpts = {
  folderId: 'string'
}

export interface FolderRecursiveMetadata {
  itemSum: number,
  folderSum: number,
  containsOutdatedDocVer: boolean,
  containsPendingReviewItem: boolean,
  containsAutoUpdatedItem: boolean
}

export type FolderORMMap = { [folderId: string]: FolderORM }
export type CustomDeckORMMap = { [customDeckId: string]: CustomDeckORM }
export const selectFolders = (state: RootState): Folder[] => state.folder.records
export const selectFolderOptions = (_, __, opts?: FilterAndSortOptions<FolderORM>) => opts
export const selectId = (_, __, opts: SelectIdOpts) => opts.folderId
export const selectSharedFolders = (state: RootState): Folder[] => state.sharedFolder.records
export const selectCustomDecks = (state: RootState): CustomDeck[] => state.customDeck.records

const deriveRecursiveMeta = (
  folder: Folder,
  folderORMMap: FolderORMMap,
  docVerORMMap: documentVersionORMMap,
  customDecksORMMap: CustomDeckORMMap,
  itemSum: number = 0,
  folderSum: number = 0,
  containsOutdatedDocVer: boolean = false,
  containsPendingReviewItem: boolean = false,
  containsAutoUpdatedItem: boolean = false,
): FolderRecursiveMetadata => {
  const rv = folder?.items?.reduce(
    (acc, item) => {
      if (item.status === FOLDER_ITEM_STATUS.REMOVED) {
        return acc
      } else if (item.type === 'FOLDER') {
        // This will skip and orphaned entries
        const nextFolder = folderORMMap[item.itemId]?.model
        if (!nextFolder) return acc

        const summed = deriveRecursiveMeta(
          nextFolder,
          folderORMMap,
          docVerORMMap,
          customDecksORMMap,
          0,
          0,
          acc.containsOutdatedDocVer,
        )

        return {
          itemSum: acc.itemSum + summed.itemSum,
          folderSum: acc.folderSum + summed.folderSum + 1,
          containsOutdatedDocVer: acc.containsOutdatedDocVer || summed.containsOutdatedDocVer,
          containsPendingReviewItem: acc.containsPendingReviewItem || summed.containsPendingReviewItem,
          containsAutoUpdatedItem: acc.containsAutoUpdatedItem || summed.containsAutoUpdatedItem,
        }
      } else if (item.type === 'DOCUMENT_VERSION') {
        // We do not include items if they no longer exist (deleted) or they are not in archived/published status
        if (
          !docVerORMMap[item.itemId] ||
          !['ARCHIVED', 'PUBLISHED'].includes(docVerORMMap[item.itemId].relations.document.model.status)
        ) {
          return acc;
        } else {
          return {
            itemSum: acc.itemSum + 1,
            folderSum: acc.folderSum + 0,
            containsOutdatedDocVer:
              acc.containsOutdatedDocVer ||
              !docVerORMMap[item.itemId].meta.version.isLatestPublished,
            containsAutoUpdatedItem: acc.containsAutoUpdatedItem || !item.updateAcknowledgedAt,
            containsPendingReviewItem: acc.containsPendingReviewItem,
          }
        }
      } else if (item.type === 'CUSTOM_DECK') {
        // item.status === 'REMOVED' is already handled
        const customDeck = customDecksORMMap[item.itemId]
        if (!customDeck) return acc
        return {
          itemSum: acc.itemSum + 1,
          folderSum: acc.folderSum + 0, // folder count does not increase
          containsOutdatedDocVer: acc.containsOutdatedDocVer,
          containsPendingReviewItem: acc.containsPendingReviewItem ||
            customDeck.meta.version.updateStatus === VERSION_UPDATE_STATUS.NOT_PUBLISHED ||
            customDeck.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MAJOR,
          containsAutoUpdatedItem: customDeck.meta.version.autoUpdateUnacknowledged || acc.containsAutoUpdatedItem,
        }
      } else {
        throw Error(`Unknown item type ${item.type}`)
      }
    },
    {
      itemSum,
      folderSum,
      containsOutdatedDocVer,
      containsPendingReviewItem,
      containsAutoUpdatedItem,
    } as FolderRecursiveMetadata
  )

  return rv ?? {
    itemSum,
    folderSum,
    containsOutdatedDocVer,
    containsPendingReviewItem,
    containsAutoUpdatedItem,
  }
}

const toFolderORM = (
  folder: Folder,
  folderORMMap?: FolderORMMap,
  docVerORMMap?: documentVersionORMMap,
  customDeckMap?: CustomDeckORMMap,
  users?: IndexedUserORM,
  isSharedWithTheUser?: boolean,
): FolderORM => {
  // 1st pass to just setup some things
  if (!folderORMMap || !docVerORMMap || !customDeckMap) {
    return {
      model: folder,
      type: ORMTypes.FOLDER,
      relations: {
        parentFolder: null,
        items: [],
      },
      meta: {
        isSharedFolder: false,
        isSharedWithTheUser,
        folderCount: 0,
        itemCount: 0,
        version: {
          containsOutdatedDocVer: false,
          containsAutoUpdatedItem: false,
          containsPendingReviewItem: false,
        },
      },
    }
  }

  folderORMMap[folder.id].meta.isSharedFolder =
    !!folderORMMap[folder.id].model.sharePermissions?.some((permission) => !permission.isDeleted);

  if (folder?.items?.length) {
    const folderORM = folderORMMap[folder.id]
    const mappedItems: (FolderItemORM)[] = folder.items
      // Filter any dead references -- may have been filtered earlier by item status
      // STATUS = REMOVED
      ?.filter(item => {
        const itemExists =
          (
            folderORMMap[item.itemId] ||
            customDeckMap[item.itemId] ||
            (
              docVerORMMap[item.itemId] &&
              ['ARCHIVED', 'PUBLISHED', 'NOT_PUBLISHED'].includes(
                docVerORMMap[item.itemId].relations.document.model.status,
              )
            )
          ) &&
          item.status !== FOLDER_ITEM_STATUS.REMOVED

        return itemExists
      })
      .map(
        folderItem => {
          const isFolder = folderItem.type === FolderItemType.FOLDER
          const isCustomDeck = folderItem.type === FolderItemType.CUSTOM_DECK

          // [TODO] - Cross function mutation, not the cleanest
          if (isFolder) {
            folderORMMap[folderItem.itemId].relations.parentFolder = folderORMMap[folder.id]
            folderORMMap[folderItem.itemId].meta.isSharedFolder = folderORMMap[folder.id].meta.isSharedFolder;
          } else if (isCustomDeck) {
            const deckORM = customDeckMap[folderItem.itemId];

            if (deckORM.meta.title === undefined) {
              deckORM.meta.title = folderItem.customTitle;
            }
            const customDeckItem: FolderItemORM = {
              model: folderItem,
              type: ORMTypes.FOLDER_ITEM,
              meta: {
                assets: {
                  thumbnailKey: deckORM.meta.assets.thumbnailKey,
                },
                hasAutoUpdatedItem: deckORM.meta.version.autoUpdateUnacknowledged,
                hasOutdatedItem: deckORM.meta.version.updateStatus !== VERSION_UPDATE_STATUS.CURRENT,
              },
              relations: {
                item: deckORM,
                parent: folderORM,
              },
            }
            return customDeckItem
          }

          const docVer = docVerORMMap[folderItem.itemId]
          const doc = docVer?.relations.document
          const isModified =
            doc?.meta.permissions.MSLSelectSlides &&
            doc?.relations.version.latestDocumentVersionPublished?.model.canHideSlides &&
            !!folderItem.visiblePages?.length

          const itemORM: FolderItemORM = {
            model: folderItem,
            type: ORMTypes.FOLDER_ITEM,
            meta: isFolder ? {
              assets: {
                thumbnailKey: undefined,
              },
              hasAutoUpdatedItem: folderORMMap[folderItem.itemId].meta.version.containsAutoUpdatedItem,
              hasOutdatedItem: folderORMMap[folderItem.itemId].meta.version.containsOutdatedDocVer,
            } : {
              assets: {
                thumbnailKey: docVerORMMap[folderItem.itemId].meta.assets.thumbnailKey,
              },
              hasAutoUpdatedItem: !folderItem.updateAcknowledgedAt,
              hasOutdatedItem: !docVerORMMap[folderItem.itemId].meta.version.isLatestPublished,
              isModified: isModified,
            },
            relations: {
              item: isFolder
                ? folderORMMap[folderItem.itemId]
                : docVerORMMap[folderItem.itemId],
              parent: folderORM,
            },
          }
          return itemORM
        }
      )
    folderORM.relations.items = mappedItems
    const {
      itemSum,
      folderSum,
      containsOutdatedDocVer,
      containsAutoUpdatedItem,
      containsPendingReviewItem,
    } = deriveRecursiveMeta(
      folderORMMap[folder.id].model,
      folderORMMap,
      docVerORMMap,
      customDeckMap,
    )
    folderORM.meta.folderCount = folderSum
    folderORM.meta.itemCount = itemSum
    folderORM.meta.version.containsOutdatedDocVer = containsOutdatedDocVer
    folderORM.meta.version.containsAutoUpdatedItem = containsAutoUpdatedItem
    folderORM.meta.version.containsPendingReviewItem = containsPendingReviewItem
  }

  folderORMMap[folder.id].relations.owner = users?.[folderORMMap[folder.id].model.createdBy];
  folderORMMap[folder.id].meta.isSharedWithTheUser = isSharedWithTheUser;

  return folderORMMap[folder.id]
}

const mergeUpdateStatus = (a: VERSION_UPDATE_STATUS, b: VERSION_UPDATE_STATUS) => {
  if (a === VERSION_UPDATE_STATUS.NOT_PUBLISHED || b === VERSION_UPDATE_STATUS.NOT_PUBLISHED) {
    return VERSION_UPDATE_STATUS.NOT_PUBLISHED
  } else if (a === VERSION_UPDATE_STATUS.PENDING_MAJOR || b === VERSION_UPDATE_STATUS.PENDING_MAJOR) {
    return VERSION_UPDATE_STATUS.PENDING_MAJOR
  } else if (a === VERSION_UPDATE_STATUS.PENDING_MINOR || b === VERSION_UPDATE_STATUS.PENDING_MINOR) {
    return VERSION_UPDATE_STATUS.PENDING_MINOR
  } else {
    return VERSION_UPDATE_STATUS.CURRENT
  }
}

export const allCustomDecks = createSelector(
  selectCustomDecks,
  allDocumentVersionMap,
  (customDecks, documentVersionsORMMap): CustomDeckORM[] =>
    customDecks.map((customDeck) => {
      let thumbnailKey;

      const isContentCached = customDeck.groups.every(
        group => group.pages.every(
          page => {
            const documentVersionORM = documentVersionsORMMap[page.documentVersionId]
            return documentVersionORM
              ? documentVersionORM.meta.assets.isContentCached
              : true;
          },
        ),
      )

      const groups = customDeck.groups.map((group) => {
        const pages = group.pages.map((page) => {
          const documentVersionORM = documentVersionsORMMap[page.documentVersionId];
          const pageModel = documentVersionORM?.model.pages.find(({ pageId }) => pageId === page.pageId)

          return {
            model: page,
            documentVersionORM,
            page: pageModel ??
              // [TODO-928] - This may need to be checked in other areas, before we were falsely allowing undefined as a Page
              //  - Which would not get caught in further TypeScript causing undocumented workarounds
              //  - Instead, we do a partial of the Page (because we need the pageId as a key for DnD)
              { pageId: page.pageId, number: page.pageNumber, srcHash: '' },
          };
        });

        if (!thumbnailKey && group.visible) {
          thumbnailKey = detectArchivedFileKeyPath(
            pages[0]?.documentVersionORM?.model,
            {
              number: pages[0].page.number,
              pageId: pages[0].page.pageId,
            },
            'small',
          );
        }

        return {
          isGroup: pages.length > 1,
          model: group,
          meta: {
            version: {
              updateStatus: pages.reduce(
                (acc, page) => page.documentVersionORM
                  ? mergeUpdateStatus(acc, page.documentVersionORM.meta.version.updateStatus)
                  : VERSION_UPDATE_STATUS.NOT_PUBLISHED, VERSION_UPDATE_STATUS.CURRENT,
              ),
            },
          },
          pages,
        }
      });
      const updateStatus = groups.reduce(
        (acc, group) => mergeUpdateStatus(acc, group.meta.version.updateStatus),
        VERSION_UPDATE_STATUS.CURRENT,
      )
      return {
        model: customDeck,
        type: ORMTypes.CUSTOM_DECK,
        meta: {
          assets: {
            thumbnailKey,
            isContentCached,
          },
          version: {
            updateStatus,
            requiresReview:
              updateStatus === VERSION_UPDATE_STATUS.PENDING_MAJOR ||
              updateStatus === VERSION_UPDATE_STATUS.NOT_PUBLISHED,
            // Thanks to this issue we have to use a dummy date to indicate undefined
            // https://github.com/aws-amplify/amplify-js/issues/7565
            autoUpdateUnacknowledged:
              !customDeck.autoUpdateAcknowledgedAt ||
              customDeck.autoUpdateAcknowledgedAt === AUTO_UPDATE_DEFAULT_DATE,
          },
          groupsORM: groups,
        },
      }
    }),
);

const customDecksORMMap = createSelector(
  allCustomDecks,
  (customDecks): CustomDeckORMMap =>
    customDecks.reduce(
      (acc, customDeckORM) => {
        acc[customDeckORM.model.id] = customDeckORM;
        return acc;
      },
      {} as CustomDeckORMMap),
);

const allSharedFolders = createSelector(
  allDocumentVersionMap,
  selectSharedFolders,
  indexedUsersList,
  userTenant,
  (docVerORMMap, folders, users, userTenant): FolderORM[] => {
    const gracePeriodDays = userTenant?.folderUpdateGracePeriodDays;

    // WE NEED TO CHECK IF THE FOLDERS HAVE AN OUTDATED FILE.
    // IF SO, WE'LL REPLACE THEM WITH THE MOST RECENT ONE
    if (gracePeriodDays !== undefined && gracePeriodDays !== null) {
      folders = folders.map((folder) => {
        const items = folder.items.reduce((acc, folderItem) => {
          if (folderItem.type !== FolderItemType.DOCUMENT_VERSION) {
            return [...acc, folderItem];
          }

          const docVer = docVerORMMap[folderItem.itemId];
          if (!docVer || docVer.relations.document.model.status !== DocumentStatus.PUBLISHED) {
            return acc;
          }

          // WE CHECK IF THERE'S A GREATER DOCVER
          const latestDocumentVersion = docVer.relations.document.relations.version.latestUsableDocumentVersion;
          if (latestDocumentVersion.model.versionNumber > docVer.model.versionNumber) {
            // WE CHECK IF IT NEEDS TO BE AUTOUPDATED AND REPLACE IT
            if (isPast(addDays(new Date(docVer.model.updatedAt), gracePeriodDays))) {
              return [...acc, {
                ...folderItem,
                itemId: latestDocumentVersion.model.id,
                itemLastUpdatedAt: new Date().toISOString(),
                updateAcknowledgedAt: undefined,
                customTitle: undefined,
                visiblePages: undefined,
              }]
            }
            return [...acc, folderItem];
          }
          return [...acc, folderItem];
        }, [] as FolderItem[]);

        return { ...folder, items };
      }, []);
    }

    const folderORMMap = folders
      .reduce(
        (acc, folder) => {
          acc[folder.id] = toFolderORM(folder)
          acc[folder.id].meta.isSharedWithTheUser = true;
          return acc
        },
        {} as FolderORMMap,
      )

    return folders.map(folder => toFolderORM(folder, folderORMMap, docVerORMMap, {}, users, true));
  },
)

const allFolders = createSelector(
  allDocumentVersionMap,
  selectFolders,
  customDecksORMMap,
  indexedUsersList,
  (docVerORMMap, folders, customDeckMap, users): FolderORM[] => {
    // Do a first pass to create initial references for any nested FolderORM
    const validFolders = folders.filter(folder => folder.status !== 'REMOVED')

    const folderORMMap = validFolders
      .reduce(
        (acc, folder) => {
          acc[folder.id] = toFolderORM(folder)
          return acc
        },
        {} as FolderORMMap,
      )

    const folderORMs = validFolders
      .map(folder => toFolderORM(folder, folderORMMap, docVerORMMap, customDeckMap, users))

    return folderORMs
  },
)

const folderItemORMById = createSelector(
  allFolders,
  allSharedFolders,
  (_, params: GetFolderItemORMParams) => params,
  (folders, sharedFolders, { id, folderId }): FolderItemORM | undefined => {
    if (!id) {
      return undefined;
    }

    for (const folderORM of [...sharedFolders, ...folders]) {
      const folderItemORM = folderORM?.relations.items?.find((folderItemORM) =>
        folderItemORM.model.id === id && folderORM.model.id === folderId);

      if (folderItemORM) {
        return folderItemORM;
      }
    }

    return undefined;
  },
);

const customDeckORMById = createSelector(
  customDecksORMMap,
  (_, id: string) => id,
  (customDeckORMMap, id): CustomDeckORM | undefined => customDeckORMMap[id],
);

const filteredFoldersFactory = () => createSelector(
  allFolders,
  selectFolderOptions,
  filterCollection,
)

const filteredSharedFoldersFactory = () => createSelector(
  allSharedFolders,
  selectFolderOptions,
  filterCollection,
)

export const allFoldersFilteredAndSortedFactory = () => createSelector(
  filteredFoldersFactory(),
  selectFolderOptions,
  sortCollection,
)

export const allSharedFoldersFilteredAndSortedFactory = () => createSelector(
  filteredSharedFoldersFactory(),
  selectFolderOptions,
  sortCollection,
)

const allFoldersFilteredAndSorted = allFoldersFilteredAndSortedFactory()

export const allFolderItems = createSelector(
  allFoldersFilteredAndSorted,
  (folders) => folders.reduce<FolderItemORM[]>(
    (acc, folder) => {
      return [...acc, ...folder.relations.items]
    },
    [],
  ),
)

export const useSharedFolders = ():
  ReturnType<typeof allSharedFolders> =>
  useSelector((state: RootState) =>
    allSharedFolders(state))

export const useIsSharedFoldersHydrated = () =>
  useSelector((state: RootState) =>
    state.sharedFolder.hydrated)

export const useAllFolders = (opts?: FilterAndSortOptions<FolderORM>):
  ReturnType<typeof allFoldersFilteredAndSorted> =>
  useSelector((state: RootState) =>
    allFoldersFilteredAndSorted(state, undefined, opts))

export const useAllFoldersInstance = (opts?: FilterAndSortOptions<FolderORM>, sharedFolders?: boolean):
  ReturnType<typeof allFoldersFilteredAndSorted> => {
  const selectorInstance = useMemo(
    () => allFoldersFilteredAndSortedFactory(),
    [],
  )

  const sharedSelectorInstance = useMemo(
    () => allSharedFoldersFilteredAndSortedFactory(),
    [],
  )

  const selector = sharedFolders ? sharedSelectorInstance : selectorInstance;

  return useSelector((state: RootState) => selector(state, undefined, opts));
}

export const useCustomDeckORMById = (id: string): ReturnType<typeof customDeckORMById> =>
  useSelector((state: RootState) => customDeckORMById(state, id));

type GetFolderItemORMParams = {
  id?: string,
  folderId?: string,
}

export const useFolderItemORMById = (params: GetFolderItemORMParams): ReturnType<typeof folderItemORMById> =>
  /** @ts-ignore TODO: Identify cause of TS error here */
  useSelector((state: RootState) => folderItemORMById(state, params));

export const useCustomDeckORMMap = (): ReturnType<typeof customDecksORMMap> =>
  useSelector((state: RootState) => customDecksORMMap(state));

export enum FolderItemStatus {
  AutoUpdated = 'AutoUpdated',
  Acknowledged = 'Acknowledged',
  Outdated = 'Outdated',
  Updated = 'Updated',
  Review = 'Review'
}

export type DocumentVersionUpdateInfo = {
  path: DocumentVersionUpdateInfoPath[],
  status: FolderItemStatus,
  folderItem: FolderItemORM,
}
export type DocumentVersionUpdateInfoPath = {
  title: string,
  isModified?: boolean,
}
export type DocumentModifiedInfo = Map<DocumentVersionORM, DocumentVersionUpdateInfo[]>

const checkCustomDeck = (
  documents: Map<DocumentVersionORM, DocumentVersionUpdateInfo[]>,
  customDeck: CustomDeckORM,
  folderItemORM: FolderItemORM,
  path: DocumentVersionUpdateInfoPath[]) => {
  const processedDocuments = new Set<DocumentVersionORM>()
  customDeck.meta.groupsORM.forEach(groupORM => {
    if (groupORM.meta.version.updateStatus !== VERSION_UPDATE_STATUS.CURRENT ||
      (groupORM.meta.version.updateStatus === VERSION_UPDATE_STATUS.CURRENT &&
        customDeck.meta.version.autoUpdateUnacknowledged)) {
      groupORM.pages.forEach(page => {
        if (!processedDocuments.has(page.documentVersionORM)) {
          checkItem(documents, page.documentVersionORM, folderItemORM, path)
          processedDocuments.add(page.documentVersionORM)
        }
      })
    }
  })
}

const checkItem = (
  documents: Map<(DocumentVersionORM | undefined), DocumentVersionUpdateInfo[]>,
  docVersionORM: DocumentVersionORM,
  folderItemORM: FolderItemORM,
  path: DocumentVersionUpdateInfoPath[]) => {
  let status = folderItemORM.meta.hasAutoUpdatedItem
    ? FolderItemStatus.AutoUpdated
    : folderItemORM.meta.hasOutdatedItem
      ? FolderItemStatus.Outdated
      : undefined
  let docVersion: (DocumentVersionORM | undefined) = docVersionORM
  // If is a custom deck and has a pending major change we mark it for Review
  if (isCustomDeckORM(folderItemORM.relations.item)) {
    const customDeckORM = folderItemORM.relations.item
    status = (customDeckORM.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MAJOR ||
      customDeckORM.meta.version.updateStatus === VERSION_UPDATE_STATUS.NOT_PUBLISHED)
      ? FolderItemStatus.Review
      : customDeckORM.meta.version.autoUpdateUnacknowledged
        ? FolderItemStatus.AutoUpdated
        : status
    docVersion = docVersion?.relations?.document?.model?.status === 'PUBLISHED' ? docVersion : undefined
  }

  if (status) {
    const info: DocumentVersionUpdateInfo = {
      path: [...path, {
        title: (folderItemORM?.model.customTitle ||
          docVersion?.relations.document.relations.version.latestDocumentVersionPublished?.model.title) ?? '',
        isModified: folderItemORM.meta.isModified || isCustomDeckORM(folderItemORM.relations.item),
      }],
      status,
      folderItem: folderItemORM,
    }
    const currentPublishedVersion = docVersion?.relations.document.relations.version.latestDocumentVersionPublished
    if (!documents.has(currentPublishedVersion)) {
      documents.set(currentPublishedVersion, [info])
    }
    else {
      const pathAlreadyExists = Object.entries(documents.get(currentPublishedVersion) ?? []).filter(
        (docVerUpdateInfo) => folderItemORM.model.id === docVerUpdateInfo[1].folderItem.model.id);
      if (pathAlreadyExists.length === 0) {
        documents.get(currentPublishedVersion)?.push(info);
      }
    }
  }
}

const documentsModifiedFactory = () => createSelector(
  allFoldersFilteredAndSorted,
  (folderORMs): Map<DocumentVersionORM, DocumentVersionUpdateInfo[]> => {
    const documents = new Map<DocumentVersionORM, DocumentVersionUpdateInfo[]>()
    folderORMs.forEach(folderORM => {
      // Only take root folders
      if (folderORM.relations.parentFolder) {
        return;
      }
      if (isFolderORM(folderORM)) {
        // FIRST FOLDER LEVEL
        const path = [{ title: folderORM.model.name }]
        const hasAutoUpdatedItem = folderORM.meta.version.containsAutoUpdatedItem
        const hasOutdatedItem = folderORM.meta.version.containsOutdatedDocVer ||
          folderORM.meta.version.containsPendingReviewItem
        if (hasAutoUpdatedItem || hasOutdatedItem) {
          // Iterate root folders child items
          folderORM.relations.items.forEach(subitem => {
            const child = subitem.relations.item
            if (isDocumentVersionORM(child)) {
              checkItem(documents, child, subitem, path)
            }
            else if (isCustomDeckORM(child)) {
              checkCustomDeck(documents, child, subitem, path)
            }
            else if (isFolderORM(child) &&
              (child.meta.version.containsAutoUpdatedItem ||
                child.meta.version.containsOutdatedDocVer ||
                child.meta.version.containsPendingReviewItem)) {
              // SECOND FOLDER LEVEL
              const subPath = [...path, { title: child.model.name }]

              // Iterate subfolders child items
              child.relations.items.forEach(subitem => {
                const subchild = subitem.relations.item
                if (isDocumentVersionORM(subchild)) {
                  checkItem(documents, subchild, subitem, subPath)
                }
                else if (isCustomDeckORM(subchild)) {
                  checkCustomDeck(documents, subchild, subitem, subPath)
                }
              })
            }
          })
        }
      }
    })

    return documents
  },
);

const documentsModified = documentsModifiedFactory()
export const useAllFoldersUpdatedDocsMap = (opts?: FilterAndSortOptions<FolderORM>):
  ReturnType<typeof documentsModified> => {
  const selectorInstance = useMemo(
    () => documentsModifiedFactory(),
    [],
  )
  return useSelector((state: RootState) => selectorInstance(state, undefined, opts))
}
