import { DNABox, DNADivider, DNAText } from '@alucio/lux-ui'
import format from 'date-fns/format'
import React from 'react'
import { FolderORM } from 'src/types/orms'

interface DetailsProps {
  itemORM?: FolderORM
}

const getFormattedDate = (date?: string): string => {
  if (!date) return ''
  return format(new Date(date), 'MMMM do yyyy, h:mm a')
}

const Details: React.FC<DetailsProps> = (props) => {
  const name = props.itemORM?.model.name
  const owner = props.itemORM?.relations.owner?.meta.formattedName
  const created = getFormattedDate(props.itemORM?.model.createdAt)
  const modified = getFormattedDate(props.itemORM?.model.updatedAt)

  return (
    <DNABox fill appearance="col" style={{ paddingHorizontal: 24 }} spacing="large">

      <DNABox appearance="col">
        {/* SECTION - Header */}
        <DNABox style={{ minHeight: 64 }} alignY="center">
          <DNAText bold numberOfLines={2}>{name}</DNAText>
        </DNABox>
        {/* !SECTION - Header */}

        <DNADivider />

        {/* SECTION - Subheader */}
        <DNABox fill alignY="center" style={{ paddingVertical: 12, minHeight: 56 }}>
          <DNAText h4>Details</DNAText>
        </DNABox>
        {/* !SECTION - Subheader */}
      </DNABox>

      {/* SECTION - Item Details */}
      <DNABox fill spacing="medium" appearance="col">

        <DNABox appearance="col" spacing="small">
          <DNABox>
            <DNAText bold>Owner</DNAText>
          </DNABox>
          <DNABox>
            <DNAText status="flatAlt">{owner}</DNAText>
          </DNABox>
        </DNABox>

        <DNABox appearance="col" spacing="small">
          <DNABox>
            <DNAText bold>Created</DNAText>
          </DNABox>
          <DNABox>
            <DNAText status="flatAlt">{created}</DNAText>
          </DNABox>
        </DNABox>

        <DNABox appearance="col" spacing="small">
          <DNABox>
            <DNAText bold>Last Modified</DNAText>
          </DNABox>
          <DNABox>
            <DNAText status="flatAlt">{modified}</DNAText>
          </DNABox>
        </DNABox>

      </DNABox>
      {/* !SECTION - Item Details */}

    </DNABox>
  )
}

export default Details
