
import { reachabilityMonitor } from '@alucio/core';
import formatISO from 'date-fns/formatISO'
import {
  createStore,
  peek,
  pop,
  push,
} from './queueIdb';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import Auth from '@aws-amplify/auth';

interface User {
    sub: string;
    email_verified: boolean;
    'custom:user_id': string;
    phone_number_verified: boolean;
    'custom:org_id': string;
    'cognito:username': string;
    given_name: string;
    aud: string;
    event_id: string;
    token_use: string;
    auth_time: number;
    phone_number: string;
    exp: number;
    iat: number;
    family_name: string;
    email: string;
}

interface PersistedItem {
  key: number,
  value: {
    user: User,
    payload: string
  }
}

let originalTrackingFunction = noop;

const retentionConfig = { maxNumber: 10000, batchEvictionNumber: 10 };
export const withStore = createStore('analyticsOffline', 'analyticsOffline', 'key');

/** Retrieve current cognito user */
async function getUser() {
  const data = await Auth.currentSession()
  return get(data, 'idToken.payload', undefined) as User
}

/** Utility function to iterate over any stored analytics items in IDB for segment and dispatches them */
async function sendPendingMessages() {
  const user = await getUser()
  let [item] = await peek(1, withStore) as PersistedItem[];
  while (!isEmpty(item)) {
    // Validate the user that enqueued it is the same that send the event
    if (user.email === item.value.user.email) {
      const [removedItem] = await pop<PersistedItem>(1, withStore);
      const [eventName, eventPayload] = JSON.parse(removedItem.value.payload);
      [item] = await peek(1, withStore) as PersistedItem[];
      analytics.track(eventName, Object.assign(eventPayload, { connectionStatus: 'offline' }))
      // Uncomment this one to do debug
      // console.info('dequeue', removedItem);
    }
  }
}

/** Init custom analytics tracker */
window.onload = () => {
  /** Initial processing of any outstanding pending messages  */
  navigator.onLine && sendPendingMessages()

  /** Setup subscription to watch network status for changes and
   * call sendPendingMessages when switching to online mode */
  reachabilityMonitor.subscribe( e => e.online && sendPendingMessages())

  /** Create a copy of the original tracking function */
  originalTrackingFunction = window.analytics.track;

  /** Overwrite the original tracking function with a new function which conditionally either
   * immediately sends our tracking data or stores it in indexdb for when we come back online */
  window.analytics.track = async function myFunction(...options) {
    const isPWA = window.matchMedia('(display-mode: standalone)').matches ||
      !!document.querySelector('[data-testid="tablet-header"]')
    const deviceMode = isPWA ? 'tablet' : 'web'
    options[1] = Object.assign(options[1], { deviceMode })
    if (navigator.onLine) {
      // Add the kind when it is not the offline mode
      const { connectionStatus } = options[1]
      if (!connectionStatus) {
        Object.assign(options[1], { connectionStatus: 'online' })
      }
      return originalTrackingFunction.apply(this, options);
    }
    else {
      const user = await getUser()
      const timeStamp = formatISO(new Date())
      options[1] = Object.assign(options[1], { timeStamp })
      push({ key: Date.now(), value: { payload : JSON.stringify(options), user } }, retentionConfig, withStore);
    }
  }

  /** Populate the 'new' window.analytics.track properties with copies from the original tracking function */
  for (const prop in originalTrackingFunction) {
    if (Object.prototype.hasOwnProperty.call(originalTrackingFunction, prop)) {
      window.analytics.track[prop] = originalTrackingFunction[prop];
    }
  }
}
