import React, { useEffect, useState } from 'react';
import { Pressable } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNAText,
  Iffy,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';

import FileUpload, { ManagedFile } from 'src/components/DNA/FileUpload/FileUpload';
import { getImageFromCloudfront } from 'src/utils/loadCloudfrontAsset/common';
import TenantLogo from 'src/components/DNA/Header/TenantLogo';

const FileUploadWrapper: React.FC<{
  onImageSelected: (url: string) => void,
  onFinish: (url: ManagedFile) => void,
}> = (props) => {
  return (
    <FileUpload>
      {
        (fileUpload) => (
          <>
            {
              fileUpload.files?.map((file) => (
                <FileUpload.RowProgress
                  key={`${file.key}`}
                  file={file}
                  onFinished={(url) => {
                    props.onFinish(url);
                  }}
                >
                  {
                    (e) => (
                      <DNABox appearance="col">
                        <Iffy is={e.progressPercent > 0 && e.progressPercent !== 100}>
                          <DNABox fill style={{ marginVertical: 4 }}>
                            <FileUpload.ProgressBar
                              progress={e.progressPercent}
                              finalValue={100}
                            />
                          </DNABox>
                        </Iffy>
                      </DNABox>
                    )
                  }
                </FileUpload.RowProgress>
              ))
            }

            <FileUpload.Input
              s3PathPrefix="tenant_tmp/"
              fileExt={['png']}
              processOnSelect
              onSelect={(files) => {
                const [file] = files as unknown as File[];
                if (file) {
                  props.onImageSelected(URL.createObjectURL(file));
                }
              }}
            >
              {props.children}
            </FileUpload.Input>
          </>
        )
      }
    </FileUpload>
  )
}

const TenantFileUploadLogo: React.FC<{
  onImageSelected: (url: string) => void,
  onFinish: (url: ManagedFile) => void,
  image: string | undefined,
}> = (props) => {
  return (<FileUploadWrapper
    onImageSelected={props.onImageSelected}
    onFinish={props.onFinish}
  >
    <Pressable style={{ backgroundColor: colors['color-gray-100'], marginTop: 6, height: 120 }}>
      <DNABox fill appearance="col" alignY="center" alignX="center">
        <Iffy is={props.image}>
          <DNABox style={{ width: 200, height: 100 }}>
            <TenantLogo size="medium" imageUrl={props.image} />
          </DNABox>
        </Iffy>
        <Iffy is={!props.image}>
          <DNAText>No Logo</DNAText>
        </Iffy>
      </DNABox>
    </Pressable>
  </FileUploadWrapper>
  )
}

const TenantFileUploadBtn: React.FC<{
  onImageSelected: (url: string) => void,
  onFinish: (url: ManagedFile) => void,
}> = (props) => {
  return (
    <FileUploadWrapper
      onImageSelected={props.onImageSelected}
      onFinish={props.onFinish}
    >
      <DNAButton
        iconLeft="upload"
        appearance="outline"
        status="subtle"
        size="small"
      >
        <DNAText>Upload</DNAText>
      </DNAButton>
    </FileUploadWrapper>
  )
}

const UploadImageTenant: React.FC<{
  tenantId: string,
  onFinishHandler?: (file: ManagedFile) => void,
  removeImage?: () => void,
}> = (props) => {
  const [image, setImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    const init = async () => {
      const img = await getImageFromCloudfront(`${props.tenantId}/logo.png`)
      // TODO: Find a better way to determine if the image is empty
      if (img.length > 50) {
        setImage(img);
      }
    }

    init();
  }, [])

  const imageSelectedHandler = (image: string | undefined) => {
    setImage(image);
    !image && props?.removeImage?.();
  }

  const onFinishHandler = (file: ManagedFile) => {
    props.onFinishHandler && props.onFinishHandler(file);
  }

  return (
    <DNABox fill appearance="col">
      <DNAText>Logo</DNAText>
      <TenantFileUploadLogo image={image} onImageSelected={imageSelectedHandler} onFinish={onFinishHandler} />
      <DNAText style={{ marginTop: 4 }}>Logo maximum dimensions: 98 x 32 pixels</DNAText>
      <DNABox appearance="row" style={{ marginTop: 23 }}>
        <TenantFileUploadBtn onImageSelected={imageSelectedHandler} onFinish={onFinishHandler} />
        <Iffy is={image}>
          <DNAButton
            style={{ marginLeft: 10 }}
            appearance="outline"
            status="neutral"
            size="small"
            iconLeft="close"
            onPress={() => imageSelectedHandler(undefined)}
          >
            Remove
          </DNAButton>
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

export default React.memo(UploadImageTenant);
