import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DocumentORM, FolderORM } from 'src/types/orms'

export interface InfoPanelSliceType {
    activeItemORM?: FolderORM | DocumentORM
}

const initialState: InfoPanelSliceType = {}

const infoPanelSlice = createSlice({
  name: 'InfoPanelState',
  initialState: initialState,
  reducers: {
    setActiveItem: (state, { payload }: PayloadAction<InfoPanelSliceType['activeItemORM']>) => {
      state.activeItemORM = payload
      return state
    },
  },
})

export default infoPanelSlice
export const infoPanelActions = {
  ...infoPanelSlice.actions,
}
