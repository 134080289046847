import { observeSlice } from 'src/state/redux/store'
import { allDocumentsSortedAndFilteredFactory } from 'src/state/redux/selector/document'
import { DocumentORM } from 'src/types/types'
import { DocumentVersion } from '@alucio/aws-beacon-amplify/src/models'
import { FilterAndSortOptions } from 'src/state/redux/selector/common'

const allDocumentsInstance = allDocumentsSortedAndFilteredFactory()

export type DocumentVersionDiff = {
  prev: DocumentVersion,
  current: DocumentVersion,
}

export const observable = (filter: FilterAndSortOptions<DocumentORM>) => {
  let lastDocVerModelCheck: DocumentVersion | undefined
  let lastDocVerModel: DocumentVersion | undefined

  return observeSlice(
    allDocumentsInstance,
    undefined,
    filter,
  )
    .filter(docs => {
      const docORMs = docs as DocumentORM[]

      if (docORMs.length !== 1) {
        throw new Error(`Received ${docORMs.length} too/many or not enough ORMs`)
      }

      const [docORM] = docORMs
      const newDocVerModel = docORM.relations.version.latestDocumentVersion.model

      // [NOTE]: If we need to skip the initial subscription, currently buggy in a few situations
      // Skip initial subscription
      // if (!lastDocVerModelCheck) {
      //   lastDocVerModelCheck = newDocVerModel
      //   return false
      // }

      // @ts-expect-error - _version are internal fields
      if (lastDocVerModelCheck?._version !== newDocVerModel?._version) {
        lastDocVerModelCheck = newDocVerModel
        return true
      }

      return false
    })
    /** We pass the last known and current version in if the consumer wants to do some diff operations */
    .map<{ type: 'VERSION_UPDATE', payload: DocumentVersionDiff }>(docs => {
      const docORMs = docs as DocumentORM[]

      const [docORM] = docORMs
      const newDocVerModel = docORM.relations.version.latestDocumentVersion.model

      const payload = {
        prev: lastDocVerModel ?? newDocVerModel,
        current: newDocVerModel,
      }

      lastDocVerModel = newDocVerModel
      return {
        type: 'VERSION_UPDATE',
        payload,
      }
    })
}

export default { }
