import React, { useReducer, useEffect, createContext, useContext } from 'react'
import im from 'immer'
import Auth from '@aws-amplify/auth'
import { isIOS, isTablet } from 'react-device-detect'
import QueryString from 'qs'

import { useOnlineStatus as useNetworkListener } from '@alucio/core'
import workerChannel from 'src/worker/channels/workerChannel'
import PWALogger from 'src/worker/util/logger'
import config from 'src/config/app.json'
import { Logger } from '@aws-amplify/core'
import { useDispatch } from 'react-redux';
import { cacheActions } from '../redux/slice/Cache/cache'
// @ts-ignore -- this is injected at build time
// and we have backup optional chaining usage on it
const ENV_NAME = config.ENV_NAME as string
const logger = new Logger('AppSettings', 'INFO');

export enum DeviceMode {
  'desktop' = 'desktop',
  'tablet' = 'tablet',
}

type AppSettingsState = {
  deviceMode: DeviceMode | keyof typeof DeviceMode
  isDeviceSwitchEnabled: boolean
  isPWAStandalone: boolean
  isOfflineEnabled?: boolean
  isPWAInstalled?: boolean
  isIdleDisabled: boolean
  isSSO: boolean
  isOnline: boolean
}

type AppSettingsAction =
  | { type: 'toggleDeviceMode' }
  | { type: 'setIsSSO' }
  | { type: 'setIsPWAStandalone', payload: AppSettingsState['isPWAStandalone'] }
  | { type: 'setIsOfflineEnabled', payload: AppSettingsState['isOfflineEnabled'] }
  | { type: 'setIsPWAInstalled', payload: AppSettingsState['isPWAInstalled'] }
  | { type: 'setIsIdleDisabled', payload: AppSettingsState['isIdleDisabled'] }
  | { type: 'setIsOnline', payload: AppSettingsContextValue['isOnline'] }

type AppSettingsContextValue = AppSettingsState & {
  dispatch: React.Dispatch<AppSettingsAction>
}

const AppSettingsContext = createContext<AppSettingsContextValue>(null!)
AppSettingsContext.displayName = 'AppSettingsContext'
export const useAppSettings = () => useContext(AppSettingsContext)

const appSettingsReducer = (state: AppSettingsState, action: AppSettingsAction) => {
  switch (action.type) {
    case 'toggleDeviceMode': {
      return im(state, draft => {
        draft.deviceMode = state.deviceMode === 'desktop'
          ? 'tablet'
          : 'desktop'
      })
    }
    case 'setIsSSO': {
      return im(state, draft => {
        draft.isSSO = true
      })
    }
    case 'setIsOfflineEnabled': {
      return im(state, draft => {
        draft.isOfflineEnabled = !!action.payload
        if (action.payload &&
          !state.isPWAStandalone &&
          state.isPWAInstalled === undefined &&
          !isIOS) {
          // If we don't know for sure the PWA is not installed we assume it is until we
          // get confirmation (via onbeforeinstallprompt that it isn't)
          // This is only for non-iOS since safari doesn't support onbeforeinstallprompt
          logger.debug('Disabling Idle')
          draft.isIdleDisabled = true
        }
      })
    }
    case 'setIsPWAStandalone': {
      return im(state, draft => {
        draft.isPWAStandalone = !!action.payload
        draft.deviceMode = action.payload ? 'tablet' : 'desktop'
      })
    }
    case 'setIsPWAInstalled': {
      return im(state, draft => {
        draft.isPWAInstalled = !!action.payload
        if (action.payload === false && state.isOfflineEnabled === true) {
          logger.debug('re-enabling idle')
          draft.isIdleDisabled = false
        }
      })
    }
    case 'setIsIdleDisabled': {
      return im(state, draft => {
        draft.isIdleDisabled = !!action.payload;
      })
    }
    case 'setIsOnline': {
      return im(state, draft => {
        draft.isOnline = action.payload
      })
    }
    default: return state
  }
}

export const AppSettings: React.FC = (props) => {
  const { children } = props
  const isPWAStandalone = window.matchMedia('(display-mode: standalone)').matches
  const isTabletIOS = isTablet && isIOS;
  const queryParams = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
  const reduxDispatch = useDispatch()
  const enableDeviceSwitch = (
    ((process.env.NODE_ENV === 'development' ||
      ENV_NAME?.includes('develop')) && isPWAStandalone) ||
    queryParams?.enableDeviceSwitch === 'true'
  )
  const isTabletQueryString = queryParams?.tabletMode

  const [
    appSettings,
    dispatch,
  ] = useReducer(
    appSettingsReducer,
    {
      deviceMode: (isTabletIOS || isPWAStandalone || isTabletQueryString)
        ? DeviceMode.tablet
        : DeviceMode.desktop,
      isDeviceSwitchEnabled: enableDeviceSwitch,
      isOfflineEnabled: undefined,
      isSSO: false,
      isPWAStandalone: isPWAStandalone,
      isIdleDisabled: isPWAStandalone,
      isOnline: navigator.onLine,
    },
  )
  const isOnline = useNetworkListener()

  useEffect(() => {
    const checkSSOStatus = async () => {
      try {
        const idToken = await (await Auth.currentSession()).getIdToken()
        if (idToken.payload.identities && idToken.payload.identities.length > 0) {
          dispatch({
            type: 'setIsSSO',
          })
        }
      } catch {
        // Apparently this is the official way to check if a user is logged in
      }
    }
    checkSSOStatus();
    let isRefreshing = false;
    let updateInterval = 0;
    // Update service worker status
    const checkIsServiceWorkerRegistered = async () => {
      const registration = await navigator.serviceWorker.getRegistration('/')
      if (registration) {
        logger.debug('detected serviceworker')
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          logger.debug('serviceworker change detected')
          if (!isRefreshing) {
            window.location.reload()
            isRefreshing = true
          }
        })
        // Every 30 mins we check to see if there's a new version
        updateInterval = window.setInterval(() => {
          logger.debug('Checking for SW Updates')
          registration.update()
        }, 30 * 60 * 1000)
      }
      dispatch({
        type: 'setIsOfflineEnabled',
        payload: !!registration,
      })
    }
    // Service worker is always registered on iOS or when running standalone
    if (isPWAStandalone || isIOS) {
      logger.debug('registering service worker')
      navigator.serviceWorker
        .register('/worker.js')
        .then(registration => {
          logger.info('SW registered: ', registration);
          dispatch({
            type: 'setIsOfflineEnabled',
            payload: true,
          })
        })
        .catch(error => {
          logger.error('SW Registration failed:', error)
          throw Error('SW registration failed: ' + error)
        });
    } else {
      checkIsServiceWorkerRegistered()
    }
    const beforeInstallListener = (e) => {
      logger.debug('beforeInstall Event Triggered', e)
      dispatch({
        type: 'setIsPWAInstalled',
        payload: false,
      })
      // if this event occurs then the PWA is not installed so we should re-enable the idle timer
      dispatch({
        type: 'setIsIdleDisabled',
        payload: false,
      })
    }
    window.addEventListener('beforeinstallprompt', beforeInstallListener);
    logger.debug('adding event listener for display-mode')
    // Watch for changes in install mode (chrome install does not reload the page)
    // Safari only supports addListener even though its deprecated
    window.matchMedia('(display-mode: standalone)').addListener((evt) => {
      logger.debug('Display mode event listener fired', evt.matches)
      dispatch({
        type: 'setIsPWAStandalone',
        payload: evt.matches,
      })
    });
    return () => {
      if (updateInterval) { clearInterval(updateInterval) }
      window.removeEventListener('beforeinstallprompt', beforeInstallListener)
    }
  }, [])

  useEffect(() => {
    if (isOnline !== undefined && appSettings.isOnline !== isOnline) {
      dispatch({ type: 'setIsOnline', payload: isOnline })
      reduxDispatch(cacheActions.setOnline(isOnline))
      // [TODO-PWA]
      //  - Move into separate useEffect?
      //  - We probably shouldn't rely on the client
      //    instead the worker should have its own listener
      const networkStatus = isOnline ? 'ONLINE' : 'OFFLINE'
      PWALogger.info('Sending Network Signal -', networkStatus)
      workerChannel.postMessageExtended({
        type: 'NETWORK_STATUS',
        value: networkStatus,
      })
    }
  }, [isOnline])

  // We mirror a copy of the isOnline value to Redux for selector purposes
  useEffect(
    () => { reduxDispatch(cacheActions.setOnline(appSettings.isOnline)) },
    [appSettings.isOnline],
  )

  return (
    <AppSettingsContext.Provider value={{ ...appSettings, dispatch }}>
      {children}
    </AppSettingsContext.Provider>
  )
}

export const useOnlineStatus = () => {
  const appSettings = useAppSettings()
  return appSettings.isOnline
}

export default AppSettings
