import { DocumentAccessLevel, DocumentStatus } from '@alucio/aws-beacon-amplify/src/models'
import { useOnlineStatus } from '@alucio/core'
import { useLDClient } from 'launchdarkly-react-client-sdk'

import { Variant, DocumentORM } from 'src/types/types'
import { DocumentContextAction } from '../DNADocument.actions'

import useCurrentPage from '../../hooks/useCurrentPage'

/** These types can be removed and replaced with DocumentStatus if we want to
 * define context menu actions for all statuses in both publisher and versioning */
 type PublisherDocumentStatus =
 DocumentStatus.ARCHIVED |
 DocumentStatus.REVOKED |
 DocumentStatus.NOT_PUBLISHED |
 DocumentStatus.PUBLISHED

type VersioningDocumentStatus =
 DocumentStatus.ARCHIVED |
 DocumentStatus.PUBLISHED |
 DocumentStatus.REVOKED

/**
 * This helper allows you to conditionally insert elements into an array based on the evaluated condition.
 * Additionally, allows for the TS compiler to properly recognize the returned array type.
 * @example
 * const someDocumentContextActionArray = [
          'version',
          'archive',
          'revoke',
          ...insertIf(someCondition, ['delete']),
        ]
 */
export const insertIf = (condition: boolean, elements: DocumentContextAction[]) => {
  return condition ? elements : []
}

const useDNADocumentContextMenuActions = (
  documentORM: DocumentORM,
  variant:Variant,
) => {
  const { status, accessLevel } = documentORM.model
  const {
    permissions: {
      MSLDownload,
      MSLPresent,
    },
    tenantFields: {
      labelCompletion,
    },
  } = documentORM.meta

  const isNetworkConnected = !!useOnlineStatus()
  const LDClient = useLDClient()
  const route = useCurrentPage({ exact: true })
  const disablePublisherFolder = LDClient?.variation('beac-2327-disable-publisher-folder', true)
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')

  const isUserDocument = accessLevel === 'USER'
  const canAddFolder = (!isPublisherRoute || (isPublisherRoute && !disablePublisherFolder))

  const actionsToUse: DocumentContextAction[] = []

  switch (variant) {
    /** SECTION - MSL Context Menu Items */
    case Variant.MSL: {
      const mslCanDownload = MSLDownload && isNetworkConnected
      const mslCanShare =
          !isUserDocument &&
          !!documentORM.relations?.version.latestDocumentVersionPublished?.meta.permissions.MSLShare &&
          isNetworkConnected
      // [TODO-2126] - We should have a property that combines isOnline and isContentCached
      //               on the DocumentVersionORM rather than checking both at the component level
      const mslCanPresent =
          MSLPresent &&
          (
            isNetworkConnected ||
            (
              !isNetworkConnected &&
              !!documentORM.relations.version.cachedDocumentVersion?.meta.assets.isContentCached
            )
          )
      const mslCanAddFolder = !isUserDocument && canAddFolder

      /** Based on the document access level, define a mapping of arrays containing available context menu actions */
      const documentAccessLevelActions: { [K in DocumentAccessLevel]: DocumentContextAction[] } = {
        TENANT: [
          ...insertIf(mslCanPresent, ['present']),
          ...insertIf(mslCanDownload, ['download']),
          ...insertIf(mslCanShare, ['shareEMail', 'copyShareLink']),
          ...insertIf(mslCanAddFolder, ['addToFolder']),
        ],
        USER: ['rename', 'delete'],
      }

      /** Push the appropriate context menu action array based on the document access level */
      actionsToUse.push(...documentAccessLevelActions[accessLevel])
      break
    }
    /** !SECTION - End MSL Context Menu Items */

    /** SECTION - Publisher Context Menu Items */
    case Variant.publisher: {
      // console.log('found publisher mode')
      const { conversionStatus } = documentORM
        .relations
        .version
        .latestDocumentVersion
        .model

      /** Based on the document status, define a mapping of arrays containing available context menu actions */
      const documentStatusActions: { [K in PublisherDocumentStatus]: DocumentContextAction[] } = {
        ARCHIVED: ['revoke', 'delete'],
        REVOKED: ['delete'],
        NOT_PUBLISHED: [
          ...insertIf(labelCompletion, ['publish']),
          ...insertIf(canAddFolder, ['addToFolder']),
          'delete',
        ],
        PUBLISHED: [
          'version',
          'archive',
          'revoke',
          'delete',
          ...insertIf(canAddFolder, ['addToFolder']),
        ],
      }

      switch (conversionStatus) {
        case 'PROCESSED':
          /** If a document has processed successfully, push the appropriate context menu action array based on the document status  */
          actionsToUse.push(...documentStatusActions[status])
          break
        case 'ERROR':
          /** Otherwise just push the delete option */
          actionsToUse.push('delete')
          break
      }
      break
    }
    /** !SECTION - Publisher Context Menu Items */

    /** SECTION - Versioning Context Menu Items */
    case Variant.versioning: {
      // [TODO-2126] - There's a key on the ORM that exposes this (latestDocumentVersionPublished)
      const isFirstVersionUnpublished = documentORM.relations.documentVersions.length === 1 &&
          status === DocumentStatus.NOT_PUBLISHED

      /** Based on the document status, define a mapping of arrays containing available context menu actions */
      const documentStatusActions: { [K in VersioningDocumentStatus]: DocumentContextAction[] } = {
        REVOKED: ['revoke'],
        ARCHIVED: ['revoke'],
        PUBLISHED: ['archive', 'revoke'],
      }

      /** Push the appropriate context menu action array based on the document status */
      !isFirstVersionUnpublished && actionsToUse.push(...documentStatusActions[status])

      /** Additionally, always add a delete menu option */
      actionsToUse.push('delete')
      break
    }
  }
  /** !SECTION - Versioning Context Menu Items */
  return actionsToUse
}

export default useDNADocumentContextMenuActions
