import React, { useEffect, useState } from 'react';
import { DNABox, DNAButton, DNAText, Iffy, util } from '@alucio/lux-ui';
import InputComponent, { TextAreaProps } from '../Publishers/InputComponent';
import { StyleProp, ViewStyle } from 'react-native';

interface Props extends TextAreaProps {
  bold?: boolean;
  toggleShowInputIcon?: string;
  triggerInputShown?: (isShown: boolean) => void;
  error?: string;
  wrapperStyle?: StyleProp<ViewStyle>,
}

// THIS IS TO HAVE THE COMPONENT WITH THE SAME HEIGHT
// UPON CHANGING FROM INPUT TO TEXT
const COMPONENT_HEIGHT = {
  small: 36,
  medium: 44,
  large: 52,
};

const EditableText = (props: Props) => {
  const {
    value,
    size = 'large',
    bold,
    onChangeText,
    toggleShowInputIcon = 'pencil',
    title,
    triggerInputShown,
    removeMarginPadding,
    wrapperStyle,
    ...rest
  } = props;
  const [showInput, setShowInput] = useState<boolean>(false);
  const [localText, setLocalText] = useState<string>(value || '');

  function toggleShowInput(): void {
    triggerInputShown?.(!showInput);

    // UPDATE THE PARENT WITH THE NEW TEXT WHEN BLUR TO AVOID UNNECESSARY RE RENDERS OF THE PARENT
    if (showInput) {
      onChangeText?.(localText);
    }

    setShowInput((isShown) => !isShown);
  }
  const textStyles = util.mergeStyles(
    undefined,
    { marginLeft: 8, flex: 1 },
    [{ color: props.placeholderTextColor }, props.placeholderTextColor && !value],
  )

  useEffect(() => {
    if (props.error) {
      toggleShowInput();
    }
  }, [props.error])

  return (
    <DNABox appearance="col" fill style={[wrapperStyle, { paddingTop: 4 }]}>
      <DNABox alignY="center" style={{ minHeight: COMPONENT_HEIGHT[size] }} childFill fill>
        <Iffy is={props.required}>
          <DNAText status="danger">*</DNAText>
        </Iffy>
        <Iffy is={!showInput}>
          <DNABox
            alignY="center"
            style={{
              marginLeft: removeMarginPadding ? 0 : 8,
              flex: 1,
              // THIS IS TO KEEP THE SAME ALIGNMENT WITH THE TEXTINPUT SINCE THE UI KITTEN TEXTINPUT ADDS AN EMPTY DIV
              // WITH A MARGINTOP OF 4, MAKING A BIT NOTICEABLE THE ALIGNMENT WHEN SWITCHING FROM THIS TEXT TO THE INPUT
              marginBottom: 4,
            }}
          >
            <Iffy is={toggleShowInputIcon}>
              <DNAButton
                status="primary"
                appearance="ghost"
                iconLeft={toggleShowInputIcon}
                context="minimum"
                onPress={toggleShowInput}
              />
            </Iffy>
            <DNAText
              bold={bold}
              numberOfLines={2}
              style={textStyles}
              onPress={toggleShowInput}
            >
              {value || props.placeHolder}
            </DNAText>
          </DNABox>
        </Iffy>
        <Iffy is={showInput}>
          <DNABox alignY="center" fill style={{ marginLeft: removeMarginPadding ? 0 : 8 }}>
            <InputComponent
              size={size}
              removeMarginPadding={true}
              hideLabel={true}
              autoFocus={true}
              placeHolder={props.placeHolder}
              placeholderTextColor={props.placeholderTextColor}
              value={localText}
              onBlur={toggleShowInput}
              status={props.error ? 'danger' : undefined}
              onChangeText={setLocalText}
              {...rest}
            />
          </DNABox>
        </Iffy>
      </DNABox>
      <Iffy is={props.error}>
        <DNAText c1 status="danger" style={ { marginTop: 4 }}>{props.error}</DNAText>
      </Iffy>
    </DNABox>
  );
};

export default EditableText;
