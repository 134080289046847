import React from 'react'
import { DNABox, DNAText, DNADivider, useTheme, Iffy } from '@alucio/lux-ui'

type variant = 'default' | 'publisher' | 'uploads'

const getStyleVariant = (theme: Record<string, string>, variant: variant) => {
  const styles = {
    default: {
      backgroundColor: theme['color-text-basic'],
      paddingLeft: 130,
      paddingVertical: 24,
    },
    publisher: {
      backgroundColor: theme['color-text-basic'],
      borderTopWidth: 0,
      paddingLeft: 130,
      paddingVertical: 24,
    },
    uploads: {
      backgroundColor: theme['color-text-basic'],
      paddingLeft: 130,
      paddingRight: 130,
      paddingVertical: 24,
    },
  }

  return styles[variant]
}

export const DNADefaultHeader: React.FC<{
  variant?: variant
}> = (props) => {
  const theme = useTheme()

  // We need to pass the theme here per design, if we import directly the themes for some unknown reason doesnt work
  const style = getStyleVariant(theme, props.variant || 'default')
  return (
    <DNABox appearance="col">
      <DNABox
        testID="header-container"
        alignY="center"
        style={style}
        spacing="between"
      >
        <DNAText b1 testID="header-text">Name</DNAText>
        <Iffy is={props.variant === 'uploads'}>
          <DNAText b1 testID="header-text">Uploaded</DNAText>
        </Iffy>
      </DNABox>
      <DNADivider />
    </DNABox>
  )
}

export default DNADefaultHeader
