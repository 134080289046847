import React, { useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { meetingActions } from 'src/state/redux/slice/meeting';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { DNABox, DNAButton, DNAIcon, DNAText, Icon, Popover } from '@alucio/lux-ui';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { format } from 'date-fns';

const styles = StyleSheet.create({
  content: {
    backgroundColor: colors['color-text-basic'],
    borderColor: colors['color-flat-400'],
    borderRadius: 4,
    borderWidth: 1,
    minWidth: 210,
    paddingVertical: 16,
    paddingHorizontal: 20,
    shadowColor: colors['color-flat-1200'],
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.16,
    shadowRadius: 11.22,
  },
  chevronIcon: {
    color: colors['color-flat-1200'],
    height: 16,
  },
});

const MeetingButton = () => {
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  function toggleOptionsVisibility(): void {
    setOptionsVisible((optionsVisible) => !optionsVisible);
  }

  function startMeeting(inPerson?: boolean): void {
    function onMeetingCreated(meetingId: string): void {
      setTimeout(() => {
        /** Navigate to meeting controls */
        history.push(`/meeting-presentation/${meetingId}`);
      }, 700);
    }

    // WE CREATE THE MEETING
    dispatch(meetingActions.startMeeting({
      type: inPerson ? MeetingType.IN_PERSON : MeetingType.VIRTUAL,
      title :  `Meeting Started at ${format(new Date(), 'MM/dd/yyyy h:mm aa')}`,
      callback: onMeetingCreated,
    }));
  }

  return (
    <Popover
      lazyMount
      placement="bottom-end"
      interactive={true}
      visible={optionsVisible}
      onBackdropPress={toggleOptionsVisibility}
      type="menu"
    >
      <Popover.Anchor>
        <DNAButton
          testID="create-new-version-button"
          onPress={toggleOptionsVisibility}
          appearance="outline"
          status="subtle"
          size="small"
        >
          <DNABox spacing="small" alignY="center">
            <DNAText>
              Start Meeting
            </DNAText>
            <Icon style={styles.chevronIcon} name={`chevron-${optionsVisible ? 'up' : 'down'}`} />
          </DNABox>
        </DNAButton>
      </Popover.Anchor>
      {/* CONTENT */}
      <Popover.Content>
        <DNABox spacing="small" appearance="col" style={styles.content}>
          <Pressable onPress={() => startMeeting(true)}>
            <DNABox spacing="small" alignY="center">
              <DNAIcon.Styled
                appearance="ghost"
                status="dark"
                name="account-multiple"
                style={{ marginTop: 3 }}
                width={20}
                height={18}
              />
              <DNAText>In-Person Meeting</DNAText>
            </DNABox>
          </Pressable>
          <Pressable onPress={() => startMeeting()}>
            <DNABox spacing="small" alignY="center">
              <DNAIcon.Styled
                appearance="ghost"
                status="dark"
                name="video-outline"
                style={{ marginTop: 3 }}
                width={20}
                height={18}
              />
              <DNAText>Virtual Meeting</DNAText>
            </DNABox>
          </Pressable>
        </DNABox>
      </Popover.Content>
    </Popover>
  );
};

MeetingButton.displayName = 'MeetingButton';

export default MeetingButton;
