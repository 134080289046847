import { useLocation } from 'react-router';

function useIsPublisherView() {
  const { pathname } = useLocation();
  const lowerCasedPathName = pathname.toLowerCase();
  return lowerCasedPathName.includes('publisher') ||
  lowerCasedPathName.includes('email-templates') ||
  lowerCasedPathName.includes('reports');
}

export default useIsPublisherView;
