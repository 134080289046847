import { DNABox, DNAText } from '@alucio/lux-ui'
import React from 'react'
import { StyleSheet } from 'react-native'
import { useUserById } from 'src/state/redux/selector/user'
interface UserRowProps {
  userID: string
}

const styles = StyleSheet.create({
  userRow: {
    minHeight: 56,
  },
})

const UserRow: React.FC<UserRowProps> = (props) => {
  const { userID } = props
  const userORM = useUserById(userID)
  return (
    <DNABox style={styles.userRow} alignY="center" spacing="between" childStyle={[0, { flex: 1 }]}>
      <DNABox appearance="col">
        <DNAText bold numberOfLines={1}>{userORM?.meta.formattedName}</DNAText>
        <DNAText status="flatAlt" numberOfLines={1}>{userORM?.user.email}</DNAText>
      </DNABox>
    </DNABox>
  )
}

export default UserRow
