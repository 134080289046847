import { Action } from 'redux'
import {
  createAsyncThunk,
  PayloadAction,
  CreateSliceOptions,
} from '@reduxjs/toolkit'
import { DataStore, PersistentModelConstructor, PersistentModel } from '@aws-amplify/datastore'
import { CognitoUser } from '../../../models/User';
import equal from 'fast-deep-equal'
import debounce from 'lodash/debounce'
import store from '../store';

const DEBOUNCE_TIMEOUT = 2500

/**
 * TYPES
 */
export enum SliceStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  OK = 'OK',
  ERROR = 'ERROR'
}

export type ModelPayload<T extends PersistentModel> = {
  model: PersistentModelConstructor<T>,
  entity: T,
  updates: Partial<T>
}

/**
 * [TODO]: There should be a cleaner way in TS to do this
 *  Can use Parameters<typeof add>: ReturnType<typeof add> to re-export type def from func
 *  but not sure how to forward the generic here
 */
export type CommonReducers<T extends PersistentModel> = {
  reducers: {
    add(state: SliceState<T>, action: PayloadAction<T | T[]>): SliceState<T>,
    update(state: SliceState<T>, action: PayloadAction<T>): SliceState<T>,
    upsert(state: SliceState<T>, action: PayloadAction<T>): SliceState<T>,
    remove(state: SliceState<T>, action: PayloadAction<T>): SliceState<T>
    save(
      state: SliceState<T>,
      action: PayloadAction<ModelPayload<T>>
    ): SliceState<T>,
    setHydrated(state: SliceState<T>): SliceState<T>,
    batchSave(
      state: SliceState<T>,
      action: PayloadAction<
        {
          model: PersistentModelConstructor<T>,
          entity: T,
          updates: Partial<T>
        }>
    ): SliceState<T>,
  },
  asyncActions: {
    asyncSave: (arg: ModelPayload<T>) => Action<T>
  },
  extraReducers: CreateSliceOptions['extraReducers']
}

export interface SliceState<T> {
  cognitoUser?: CognitoUser; // [TODO] - Move this out of common
  hydrated: boolean,
  status: SliceStatus;
  records: Array<T>;
  isLoading: boolean;
  errorStatus?: {
    error: any;
    message?: string;
  }
}

/**
 * UTIL
 */
export function initialState<T>(): SliceState<T> {
  return {
    status: SliceStatus.NEW,
    hydrated: false,
    cognitoUser: undefined as CognitoUser | undefined,
    records: [] as T[],
    isLoading: false,
    errorStatus: {
      error: undefined,
      message: undefined,
    },
  }
}

// [TODO-399] Consider turning other statuses into functions as well for consistency
export const STATUS = {
  SUCCESS: {
    status: SliceStatus.OK,
    isLoading: false,
    errorStatus: {
      error: undefined,
      message: undefined,
    },
  },
  PENDING: {
    status: SliceStatus.PENDING,
    isLoading: true,
    errorStatus: {
      error: undefined,
      message: undefined,
    },
  },
  ERROR: (errorMessage: string) => ({
    status: SliceStatus.ERROR,
    isLoading: false,
    errorStatus: {
      error: undefined,
      message: errorMessage,
    },
  }),
  ITEM_NOT_FOUND: {
    status: SliceStatus.ERROR,
    isLoading: false,
    errorStatus: {
      error: undefined,
      message: 'Could not find index to update',
    },
  },
  ITEM_NOT_FOUND_ADDED_TO_STATE: {
    status: SliceStatus.OK,
    isLoading: false,
    errorStatus: {
      error: undefined,
      message: 'Could not find index to update, added to state',
    },
  },
  RESET: {
    STATUS: SliceStatus.OK,
    isLoading: false,
    errorStatus: {
      error: undefined,
      message: undefined,
    },
    records: [],
  },
}

/** Immer friendly modification */
export function withStatus<T>(
  statusType: Partial<SliceState<T>>,
  state: SliceState<T>,
): SliceState<T> {
  return Object.assign(state, statusType)
}

export const formatDataStorePayload = <T extends PersistentModel>(
  model: PersistentModelConstructor<T>,
  entity: T,
  updates: Partial<T>,
) => {
  return model.copyOf(entity, draft => {
    // Inject some properties as a fallback if not explicitly included in the update payload
    if (draft.updatedAt && !updates.updatedAt) {
      // @ts-ignore
      draft.updatedAt = new Date().toISOString()
    }

    Object
      .entries(updates)
      .forEach(([key, value]) => {
        // Don't apply any undefined values since a schema property
        // may or may not be `nullable`
        if (value !== undefined) {
          // @ts-ignore
          draft[key] = value;
        }
      })
  })
}

// Static Debounce registry to ensure that each reducer has it's own debounced DataStore.Save
// Otherwise it batches save for every reducer
const debounceRegistry: Record<string, {
  fn: ReturnType<typeof debounce>,
  record: Record<string, {
    lastVersionAttempt: number
    backoff: number
  }>
}> = { }
const debouncedDataStoreSave = <T extends PersistentModel>(
  record: PersistentModel,
  reducerName: string,
  model: PersistentModelConstructor<T>,
) => {
  if (!debounceRegistry[reducerName]) {
    /**
     * customSave is a batch save operation that does NOT allow the client
     * to update DataStore with potentially duplicate versions.
     *
     * Since payload versions may become stale, we track the last successful save
     * If an operation is equal or less than the last successful save
     * We attempt to wait for a sub fireback with a newer version
     *
     * If we detect that the latest record in redux is greater than the payload
     * We update our payload's version to match (thus avoiding sending stale versions)
     *
     * This is to mitigate CLIENT side operations
     * There is still potential for conflict resolution from both
     * other users and/or backend processes!!
     */
    // [TODO]: Probably doesn't support offline since subs don't fire back with updated versions?
    const customSave = (...args: Parameters<typeof DataStore.save>) => {
      const updateRecord = args[0]
      const recordEntry = debounceRegistry[reducerName].record

      if (!recordEntry[updateRecord.id]) {
        recordEntry[updateRecord.id] = {
          lastVersionAttempt: 0,
          backoff: 0,
        }
      }

      // console.log(
      // `Executing ${updateRecord.id}: ${updateRecord._version}`,
      // recordEntry[updateRecord.id].lastVersionAttempt
      // )

      let bumpedRecord

      if (updateRecord._version <= recordEntry[updateRecord.id].lastVersionAttempt) {
        const latestRecord = store
          .getState()
          .documentVersion
          .records.find(ver => ver.id === updateRecord.id)

        if (!latestRecord) {
          // console.error('Could not find record for batch save')
          return;
        }

        // @ts-expect-error
        const latestRecordVer = latestRecord._version

        if (latestRecordVer > updateRecord._version) {
          // console.log(`Found version ${latestRecordVer} higher than payload`)
          // @ts-ignore
          bumpedRecord = formatDataStorePayload(model, updateRecord, { _version: latestRecordVer })
        }
        else if (recordEntry[updateRecord.id].backoff < 10) {
          setTimeout(() => {
            // console.log('Attempting batch save again ...', recordEntry[updateRecord.id].backoff)
            recordEntry[updateRecord.id].backoff = recordEntry[updateRecord.id].backoff + 1
            debounceRegistry[reducerName].fn(...args)
          }, 3000)

          return;
        }
        else {
          // console.warn('Skipping batch save operation')
          recordEntry[updateRecord.id].backoff = 0
          return;
        }
      }

      bumpedRecord
        ? DataStore.save(bumpedRecord)
        : DataStore.save(...args)

      const successfulSavedVersion = bumpedRecord?._version ?? updateRecord._version
      // console.log("Successfully saved with version ", successfulSavedVersion)

      recordEntry[updateRecord.id].lastVersionAttempt = successfulSavedVersion
      recordEntry[updateRecord.id].backoff = 0
    }

    debounceRegistry[reducerName] = {
      // [TODO] - Only enabled for documentVersion right now
      fn: reducerName === 'documentVersion'
        ? debounce(customSave, DEBOUNCE_TIMEOUT)
        : debounce(DataStore.save, DEBOUNCE_TIMEOUT),
      record: { },
    }
  }

  return debounceRegistry[reducerName].fn(record)
}

export const datastoreSave = <T extends PersistentModel>(
  model: PersistentModelConstructor<T>,
  entity: T,
  updates: Partial<T>,
  debounce: boolean = false,
  reducerName?: string,
) => {
  const updatedRecord = formatDataStorePayload(model, entity, updates)

  if (debounce) {
    if (!reducerName)
    { throw new Error('Need to provide the reducer name for a debounced save') }
    const sliceName = reducerName.split('/').slice(0, 1).join('')
    debouncedDataStoreSave(updatedRecord, sliceName, model)
  } else {
    DataStore.save(updatedRecord)
  }

  return updatedRecord
}

/**
 * REDUCERS
 */
export function add<T extends PersistentModel> (
  state: SliceState<T>,
  action: PayloadAction<T | T[]>,
): SliceState<T> {
  // NOTE: We don't add any removed items to the store to minimize records that are actually used in the app
  //  If an item is removed during a session, the record's status will update and remain in memory
  //  but on subsequent app load, it should no longer be present
  if (Array.isArray(action.payload)) {
    state.records = [...state.records, ...action.payload.filter(t => t?.status !== 'REMOVED')]
  }
  else if (action.payload?.status !== 'REMOVED') {
    state.records.push(action.payload)
  }

  return withStatus<T>(STATUS.SUCCESS, state)
}

export function update<T extends PersistentModel> (
  state: SliceState<T>,
  action: PayloadAction<T>,
): SliceState<T> {
  const foundIdx = state.records.findIndex(entity => entity.id === action.payload.id)
  if (foundIdx === -1)
  { return withStatus<T>(STATUS.ITEM_NOT_FOUND, state) }

  // Attempt to prevent re-renders
  //  currently deep equality checking is faster than re-renders
  //  However, due to the code below, we actually don't skip re-renders (due to the User bookmark usecase)
  const isEqual = equal(state.records[foundIdx], action.payload)

  if (!isEqual) {
    state.records[foundIdx] = action.payload
  }

  return withStatus<T>(STATUS.SUCCESS, state)
}

export function upsert<T extends PersistentModel> (
  state: SliceState<T>,
  action: PayloadAction<T>,
): SliceState<T> {
  const foundIdx = state.records.findIndex(entity => entity.id === action.payload.id)
  if (foundIdx === -1) {
    state.records.push(action.payload)
    return withStatus<T>(STATUS.ITEM_NOT_FOUND_ADDED_TO_STATE, state)
  }

  // Attempt to prevent re-renders
  //  currently deep equality checking is faster than re-renders
  //  However, due to the code below, we actually don't skip re-renders (due to the User bookmark usecase)
  const isEqual = equal(state.records[foundIdx], action.payload)

  if (!isEqual) {
    state.records[foundIdx] = action.payload
  }

  return withStatus<T>(STATUS.SUCCESS, state)
}

export function save<T extends PersistentModel> (
  state: SliceState<T>,
  action: PayloadAction<{
    model: PersistentModelConstructor<T>,
    entity: T,
    updates: Partial<T> // [TODO] - Should use something other than Partial (where we turned undefineds to null)
  }>,
): SliceState<T> {
  const { model, entity, updates } = action.payload;
  datastoreSave(model, entity, updates)
  return withStatus<T>(STATUS.SUCCESS, state)
}

export function remove<T extends PersistentModel> (
  state: SliceState<T>,
  action: PayloadAction<T>,
): SliceState<T> {
  const foundIdx = state.records.findIndex(entity => entity.id === action.payload.id)
  if (foundIdx === -1) { return withStatus<T>(STATUS.ITEM_NOT_FOUND, state) }
  state.records.splice(foundIdx, 1)
  return withStatus<T>(STATUS.SUCCESS, state)
}

export function setHydrated<T extends PersistentModel> (
  state: SliceState<T>,
): SliceState<T> {
  state.hydrated = true
  return state
}

/**
 * A debounced version of save that modifies redux immediately
 *  instead of waiting for datastore subs to update a record
 */
export function batchSave<T extends PersistentModel> (
  state: SliceState<T>,
  action: PayloadAction<{ model: PersistentModelConstructor<T>, entity: T, updates: Partial<T>}>,
): SliceState<T> {
  const { model, entity, updates } = action.payload;
  const { type } = action

  const record = datastoreSave(model, entity, updates, true, type)
  const recordIdx = state.records.findIndex(r => r.id === record.id)
  state.records[recordIdx] = record

  return withStatus<T>(STATUS.SUCCESS, state)
}

export function createAsyncSave<T extends PersistentModel>(sliceName: string) {
  const asyncSaveThunk = createAsyncThunk(
    `${sliceName}/asyncSave`,
    async (payload: ModelPayload<T>, thunkAPI) => {
      const { model, entity, updates } = payload;

      const updated = formatDataStorePayload(model, entity, updates)

      const res = await DataStore
        .save(updated)
        .catch(() => {
          return thunkAPI.rejectWithValue(`Failed to update ${sliceName} model in DataStore`)
        })

      return res
    },
  )

  const asyncSaveReducers = {
    [asyncSaveThunk.pending.toString()]: (state: SliceState<T>) => withStatus<T>(STATUS.PENDING, state),
    [asyncSaveThunk.fulfilled.toString()]: (state: SliceState<T>) => state, // Avoid a re-render since we rely on DataStore to update the Redux state
    [asyncSaveThunk.rejected.toString()]: (
      state: SliceState<T>,
      action: PayloadAction<string>,
    ) => withStatus<T>(STATUS.ERROR(action.payload), state),
  }

  return {
    thunk: asyncSaveThunk,
    asyncReducers: asyncSaveReducers,
  }
}

export const commonReducers = <T extends PersistentModel>(sliceName: string): CommonReducers<T> => {
  const asyncSave = createAsyncSave<T>(sliceName)

  return {
    reducers: { add, update, upsert, remove, save, setHydrated, batchSave },
    // @ts-ignore - Leverging redux toolkit types are difficult
    //  -- check the source typings for a literal 1600 char type defintion
    asyncActions: { asyncSave: asyncSave.thunk },
    extraReducers: asyncSave.asyncReducers,
  }
}
