import { DNABox, DNAButton, DNAText, Iffy, luxColors, Popover } from '@alucio/lux-ui'
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton'
import React from 'react'
import { uniqueId } from 'xstate/lib/utils'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { StyleSheet } from 'react-native';

import { GroupStatus, usePresentationBuilderState } from '../state/PresentationBuilderStateProvider'
import { PrimaryActionButtons } from './PrimaryActionButtons/PrimaryActionButtons'
import SecondaryActionButtons from './SecondaryActionButtons/SecondaryActionButtons'
import { SlideThumbnails } from '../SlideThumbnails/SlideThumbnails'
import FindAndReplace from './FindAndReplace/FindAndReplace'
import EditableText from '../../EditableText/EditableText';

const styles = StyleSheet.create({
  headerMainWrapper: {
    borderBottomColor: colors['color-flat-300'],
    borderBottomWidth: 1,
  },
  headerRow: {
    minHeight: 47,
    paddingHorizontal: 15,
  },
  headerInformationRowBorder: {
    borderTopColor: colors['color-flat-300'],
    borderTopWidth: 1,
  },
});

export interface Action extends DNAButtonProps {
  label?:string
  fill?:boolean
  hidden?:boolean
  tooltip?:string
}

export const usePresentationBuilderSharedResources = () => {
  const containerMargin = 15
  const getActionEls:(actions: Action[]) => JSX.Element[] = (actions) =>
  {
    return actions.filter(action => !action.hidden).map(action => {
      const {
        disabled = false,
        iconLeft,
        onPress,
        label,
        appearance = 'ghost',
        context,
        status = 'dark',
        fill,
        style,
        size = 'medium',
        tooltip,
      } = action

      const actionButton =
        (<DNAButton
          disabled={disabled}
          iconLeft={iconLeft}
          onPress={onPress}
          appearance={appearance}
          status={status}
          context={context}
          style={style}
          size={size}
        >
          {label}
        </DNAButton>)

      return (
        <DNABox
          key={uniqueId()}
          fill={fill}
        >
          {tooltip
            ? <Popover placement="top">
              <Popover.Anchor>
                {actionButton}
              </Popover.Anchor>
              <Popover.Content>
                <DNAText
                  style={{ color: colors['color-text-basic'] }}
                >{tooltip}</DNAText>
              </Popover.Content>

            </Popover>
            : actionButton}
        </DNABox>
      )
    })
  }

  return {
    containerMargin,
    getActionEls,
  }
}

const Header: React.FC = () => {
  const { selectedGroups, title, setTitle, numOfRequiredSlides, wasSavedWithoutTitle } = usePresentationBuilderState();

  let unresolvedItems = 0;
  let disablePreview = true;

  selectedGroups.forEach(({ groupStatus, visible }) => {
    if (groupStatus !== GroupStatus.ACTIVE) {
      unresolvedItems++;
    } else if (groupStatus === GroupStatus.ACTIVE && visible) {
      disablePreview = false;
    }
  });

  return (
    <DNABox appearance="col" style={styles.headerMainWrapper}>
      <PrimaryActionButtons/>
      <DNABox appearance="col">
        <EditableText
          wrapperStyle={styles.headerRow}
          value={title}
          removeMarginPadding={true}
          onChangeText={setTitle}
          placeHolder="File Name"
          placeholderTextColor={luxColors.contentText.quaternary}
          required
          error={wasSavedWithoutTitle ? 'This field is required' : undefined}
          toggleShowInputIcon="pencil"
        />
        <DNABox alignY="center" style={styles.headerRow}>
          <DNABox spacing="medium" fill childFill={2}>
            <DNAText bold status="flatAlt">{selectedGroups.length} item(s)</DNAText>
            <Iffy is={unresolvedItems > 0}>
              <DNAText style={{ color: colors['color-flat-400'] }}>|</DNAText>
            </Iffy>
            <Iffy is={unresolvedItems > 0}>
              <DNAText numberOfLines={1} bold status="warning">
                {unresolvedItems} item(s) need to be resolved
              </DNAText>
            </Iffy>
          </DNABox>
          <Iffy is={selectedGroups.length}>
            <SecondaryActionButtons disablePreview={disablePreview} />
          </Iffy>
        </DNABox>
        <Iffy is={numOfRequiredSlides > 0}>
          <DNABox alignY="center" style={[styles.headerRow, styles.headerInformationRowBorder]}>
            <DNAText numberOfLines={1} bold status="danger">
              {numOfRequiredSlides} new required slide(s) added.
            </DNAText>
          </DNABox>
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

const ContentPreview: React.FC = () => {
  const { selectedGroups } = usePresentationBuilderState();
  const selectedContent = selectedGroups.length;
  return (
    <DNABox
      fill
      style={{ backgroundColor: colors['color-flat-200'], zIndex: -1 }}
    >
      <Iffy is={!selectedContent}>
        <DNABox fill appearance="col" alignX="center" alignY="center" spacing="small">
          <DNAText h3>
            Build your presentation
          </DNAText>
          <DNAText>Choose a file then add slides here</DNAText>
        </DNABox>
      </Iffy>
      <Iffy is={selectedContent}>
        <SlideThumbnails />
      </Iffy>
    </DNABox>
  )
}

const PresentationEditor: React.FC = () => {
  const { builderMode } = usePresentationBuilderState()
  const isCustomizationMode = builderMode === 'customization'
  const isReplaceMode = builderMode === 'replace'

  return (
    <>
      <Iffy is={isReplaceMode}>
        <FindAndReplace />
      </Iffy>
      <DNABox
        fill={isCustomizationMode}
        appearance="col"
        style={{
          width: isCustomizationMode ? undefined : 446,
          borderLeftColor: colors['color-flat-300'],
          borderLeftWidth: isCustomizationMode ? undefined : 2,
          display: !isReplaceMode ? undefined : 'none',
        }}
      >
        <Header />
        <ContentPreview />
      </DNABox>
    </>
  )
}
export default PresentationEditor
