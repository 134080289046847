import React, { useCallback, useState } from 'react';
import {
  DNABox,
  DNAButton,
  DNACard,
  DNASpinner,
  DNAText,
  Iffy,
  Toggle,
} from '@alucio/lux-ui';
import { StyleSheet } from 'react-native';
import { Divider } from '@ui-kitten/components';
import { API, graphqlOperation } from 'aws-amplify';
import {
  FieldConfig,
  FieldDataType,
  PurposeType,
  IntegrationSettings,
  Tenant,
} from '@alucio/aws-beacon-amplify/src/models';
import {
  submitIntegrationRun,
  createIntegrationSettings,
  updateIntegrationSettings,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import { TenantORM } from 'src/state/redux/selector/tenant';
import IntegrationProvider from '../Integrations/Provider';
import IntegrationForm from './IntegrationForm';
import store from 'src/state/redux/store'
import { tenantActions } from 'src/state/redux/slice/tenant'

import { Controller, useForm, FormProvider } from 'react-hook-form'
import { v4 as uuid } from 'uuid';
import { CurrentUser, useCurrentUser } from 'src/state/redux/selector/user'
import { integrationSettingsActions } from 'src/state/redux/slice/integrationSettings';
import { useDispatch } from 'react-redux';
import { SUBMITTING_STATUS } from 'src/components/EmailTemplateDrawer/EmailTemplateDrawer';

import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'

import DNAIntegrationSyncModal from 'src/components/DNA/Modal/DNAIntegrationSyncModal'
import DNAIntegrationLogsModal from 'src/components/DNA/Modal/DNAIntegrationLogsModal';

export enum FieldType {
  SYSTEM = 'SYSTEM',
  CUSTOM = 'CUSTOM',
}

export interface valueMap {
  srcValue: string,
  targetValue: string,
}

interface MapItem {
  key: string,
  fieldType: FieldType,
  targetFieldName: string,
  srcFieldname: string,
  valueMappings?: valueMap[]
}

const styles = StyleSheet.create({
  backButton: {
    marginRight: 32,
  },
  buttonContainer: {
    marginTop: 16,
    paddingRight: 16,
  },
  sectionContainer: {
    marginHorizontal: 32,
    paddingBottom: 40,
  },
  sectionMarging: {
    marginTop: 80,
  },
  settingMarging: {
    marginTop: 40,
  },
});

export type BeaconAttribute = {
  name: string,
  propName: string,
  isRequired: boolean,
  dataType: FieldDataType,
  fieldType: FieldType,
  fieldValues: any, // TODO: change from any to the correct type
}

export interface BeaconMappingValue {
  [key: string]: BeaconAttribute
}

const getFormData = (
  data,
  beaconMappingValues: BeaconMappingValue,
  tenant: TenantORM,
  currentUser: CurrentUser,
  selectedIntegration?: IntegrationSettings,
) => {
  if (data && data.fieldMapping) {
    // format the mapping field
    const mapping = Object.keys(data.fieldMapping).reduce((mappedArray, fieldMappingKey) => {
      if (data.fieldMapping[fieldMappingKey].srcFieldname) {
        const mapItem = {
          dataType: beaconMappingValues[fieldMappingKey].dataType,
          fieldType: beaconMappingValues[fieldMappingKey].fieldType,
          targetFieldName: beaconMappingValues[fieldMappingKey].propName || beaconMappingValues[fieldMappingKey].name,
          srcFieldname: data.fieldMapping[fieldMappingKey].srcFieldname,
          valueMappings: data.fieldMapping[fieldMappingKey].valueMappings &&
            data.fieldMapping[fieldMappingKey].valueMappings,
          key: uuid(), // not sure why this key is required but backend needs a uniqueID
        }
        return [...mappedArray, mapItem]
      } else {
        return mappedArray
      }
    }, [] as MapItem[])

    // format the clientConfigurationFields
    const clientConfigFormatted = Object.keys(data.clientConfigurationFields).map(configKey => {
      return {
        key: configKey,
        value: data.clientConfigurationFields[configKey],
      }
    }).filter(config => config.value)

    // format integrationType
    const formattedIntegrationType =
      Array.isArray(data.integrationType) ? data.integrationType[0] : data.integrationType

    return {
      clientConfigurationFields: clientConfigFormatted,
      integrationType: formattedIntegrationType,
      name: data.name,
      notificationEmail: data.notificationEmail,
      errorSyncEmail: data.errorSyncEmail,
      mapping,
      syncContentEvery: 24, // this needs to be changed once we add the settings to pick the sync interval
      enabled: data.shouldSync,
      tenantId: tenant.tenant.id,
      id: selectedIntegration && selectedIntegration.id,
      createdBy: currentUser.userProfile?.email!,
      updatedBy: currentUser.userProfile?.email!,
      _version: selectedIntegration ? (selectedIntegration as any)._version : undefined,
    }
  }
}

const formatFormData = (data, beaconMappingValues: BeaconMappingValue, tenant: TenantORM, currentUser: CurrentUser) => {
  const now = new Date().toISOString();
  if (data) {
    // format the mapping field
    const mapping = data.fieldMapping
      ? Object.keys(data.fieldMapping).reduce((mappedArray, fieldMappingKey) => {
        if (data.fieldMapping[fieldMappingKey].srcFieldname) {
          const mapItem = {
            dataType: beaconMappingValues[fieldMappingKey].dataType,
            fieldType: beaconMappingValues[fieldMappingKey].fieldType,
            targetFieldName: beaconMappingValues[fieldMappingKey].propName || beaconMappingValues[fieldMappingKey].name,
            srcFieldname: data.fieldMapping[fieldMappingKey].srcFieldname,
            valueMappings: data.fieldMapping[fieldMappingKey].valueMappings &&
              data.fieldMapping[fieldMappingKey].valueMappings,
            key: uuid(), // not sure why this key is required but backend needs a uiqueID
          }
          return [...mappedArray, mapItem]
        } else {
          return mappedArray
        }
      }, [] as MapItem[])
      : []

    // format the clientConfigurationFields
    const clientConfigFormatted = Object.keys(data.clientConfigurationFields).map(configKey => {
      return {
        key: configKey,
        value: data.clientConfigurationFields[configKey],
      }
    })

    // format integrationType
    const formattedIntegrationType =
      Array.isArray(data.integrationType) ? data.integrationType[0] : data.integrationType

    return {
      clientConfigurationFields: clientConfigFormatted,
      integrationType: formattedIntegrationType,
      mapping,
      syncContentEvery: 24, // this needs to be changed once we add the settings to pick the sync interval
      enabled: data.shouldSync,
      tenantId: tenant.tenant.id,
      id: uuid(),
      name: data.name,
      createdAt: now,
      createdBy: currentUser.userProfile?.email!,
      updatedAt: now,
      updatedBy: currentUser.userProfile?.email!,
      notificationEmail: data.notificationEmail,
      errorSyncEmail: data.errorSyncEmail,
    }
  }
}

const Integration: React.FC<{
  onClose: () => void,
  selectedTenant: TenantORM
  selectedIntegration?: IntegrationSettings,
}> = (props) => {
  const { onClose, selectedTenant, selectedIntegration } = props
  const [submittingStatus, setSubmittingStatus] = useState<SUBMITTING_STATUS>(SUBMITTING_STATUS.NONE);
  const form = useForm({ defaultValues: { shouldSync: true } })
  const { formState: { isDirty }, control, watch } = form
  const shouldSyncVal = watch('shouldSync')
  const currentUser = useCurrentUser()
  const dispatch = useDispatch()

  // TODO: MOVE THIS TO THE FORM
  const [disableSync, setDisableSync] = React.useState(false)
  const [syncError, setSyncError] = React.useState<string | undefined>(undefined)
  const genericFields = {
    'title': {
      name: 'Title',
      propName: 'title',
      isRequired: true,
      dataType: FieldDataType.STRING,
      fieldType: 'SYSTEM',
      fieldValues: null,
    },
    'purpose': {
      name: 'Purpose',
      propName: 'purpose',
      isRequired: true,
      dataType: FieldDataType.STRING,
      fieldType: 'SYSTEM',
      fieldValues: PurposeType,
    },
    'longDescription': {
      name: 'Long description',
      propName: 'longDescription',
      isRequired: false,
      dataType: FieldDataType.STRING,
      fieldType: 'SYSTEM',
      fieldValues: null,
    },
    'shortDescription': {
      name: 'Short description',
      propName: 'shortDescription',
      isRequired: true,
      dataType: FieldDataType.STRING,
      fieldType: 'SYSTEM',
      fieldValues: null,
    },
    'expiresAt': {
      name: 'Expiration date',
      propName: 'expiresAt',
      isRequired: true,
      dataType: FieldDataType.DATE,
      fieldType: 'SYSTEM',
      fieldValues: null,
    },
    'owner': {
      name: 'Business owner',
      propName: 'owner',
      isRequired: true,
      dataType: FieldDataType.STRING,
      fieldType: 'SYSTEM',
      fieldValues: null,
    },
    'canHideSlides': {
      name: 'File is modifiable',
      propName: 'canHideSlides',
      isRequired: true,
      dataType: FieldDataType.STRING,
      fieldType: 'SYSTEM',
      fieldValues: null,
    },
    'distributable': {
      name: 'File is distributable',
      propName: 'distributable',
      isRequired: true,
      dataType: FieldDataType.STRING,
      fieldType: 'SYSTEM',
      fieldValues: null,
    },
    'downloadable': {
      name: 'File is downloadable',
      propName: 'downloadable',
      isRequired: true,
      dataType: FieldDataType.STRING,
      fieldType: 'SYSTEM',
      fieldValues: null,
    },

  }

  function formatTenantFields(tenantFields: FieldConfig[]) {
    return tenantFields.map(tenantField => {
      const { fieldName, required, dataType, values } = tenantField
      return {
        [fieldName]: {
          name: fieldName,
          isRequired: required,
          dataType: dataType,
          fieldType: 'CUSTOM',
          fieldValues: values,
        },
      }
    })
  }

  const formattedFieldConfigList = formatTenantFields(selectedTenant?.tenant.fields || [])
  const beaconMappingValuesNotSorted: BeaconMappingValue = Object.assign(genericFields, ...formattedFieldConfigList)

  // sorting the list alphabetically for the form
  const beaconMappingValues: BeaconMappingValue = Object.keys(beaconMappingValuesNotSorted)
    .sort((a, b) => beaconMappingValuesNotSorted[a].name.localeCompare(beaconMappingValuesNotSorted[b].name) )
    .reduce((acc, value) => ({
      ...acc, [value]: beaconMappingValuesNotSorted[value],
    }), {})

  const onSyncHandler = useCallback(async () => {
    setDisableSync(true)
    // At this moment is a sync operation on the future should be handled as an async operation
    const syncResult = await API.graphql(graphqlOperation(submitIntegrationRun, {
      input: {
        integrationSettingId: props.selectedIntegration?.id,
      },
    }),
    ) as {data: {submitIntegrationRun: {message: string}}};
    setSyncError(syncResult.data.submitIntegrationRun.message)
    setDisableSync(false)
  }, [])

  const onSubmit = async (data) => {
    const updatedFormatedData =
      getFormData(data, beaconMappingValues, selectedTenant, currentUser, selectedIntegration)

    const formattedIntegrationType =
      Array.isArray(data.integrationType) ? data.integrationType[0] : data.integrationType

    store.dispatch(tenantActions.save({
      model: Tenant,
      entity: selectedTenant.tenant,
      updates: {
        integrations: selectedTenant.tenant.integrations && formattedIntegrationType
          ? Array.from(new Set(
            [...selectedTenant.tenant.integrations, formattedIntegrationType],
          ))
          : [formattedIntegrationType],
      },
    }))

    const obj = { ...selectedIntegration, ...updatedFormatedData }
    delete (obj as any)._deleted
    delete (obj as any)._lastChangedAt
    if (selectedIntegration) {
      setSubmittingStatus(SUBMITTING_STATUS.SUBMITTING);

      const updatedIntegration = await API.graphql({
        query: updateIntegrationSettings,
        variables: { input: obj },
      })

      dispatch(integrationSettingsActions.update(updatedIntegration as IntegrationSettings))
      setSubmittingStatus(SUBMITTING_STATUS.SUCCESS);

      setTimeout(() => {
        setSubmittingStatus(SUBMITTING_STATUS.NONE);
      }, 3000);
    } else {
      const formattedData = formatFormData(data, beaconMappingValues, selectedTenant, currentUser)
      if (formattedData) {
        setSubmittingStatus(SUBMITTING_STATUS.SUBMITTING);
        const newIntegration = await API.graphql(graphqlOperation(
          createIntegrationSettings, { input: formattedData },
        ))
        dispatch(integrationSettingsActions.add(newIntegration as IntegrationSettings))
        setSubmittingStatus(SUBMITTING_STATUS.SUCCESS);

        setTimeout(() => {
          setSubmittingStatus(SUBMITTING_STATUS.NONE);
        }, 3000);
      }
    }
  }

  const handleSyncConfirmation = (onChange, syncVal) => {
    if (shouldSyncVal) {
      dispatch(DNAModalActions.setModal(
        {
          isVisible: true,
          allowBackdropCancel: false,
          component: (props) => (
            <DNAIntegrationSyncModal
              {...props}
              onChange={onChange}
              syncVal={syncVal}
            />
          ),
        },
      ))
    } else {
      onChange(syncVal)
    }
  }

  const handleViewLogs = () => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <DNAIntegrationLogsModal
          integrationSettings={selectedIntegration}
          {...props}
        />
      ),
    }))
  }

  const isSuccess = submittingStatus === SUBMITTING_STATUS.SUCCESS;
  const isSubmitting = submittingStatus === SUBMITTING_STATUS.SUBMITTING;

  return (
    <IntegrationProvider>
      <FormProvider {...form}>
        <DNACard
          appearance="flat"
        >
          <DNABox appearance="col">
            <DNABox style={styles.buttonContainer} appearance="row" fill spacing="between">
              <DNAButton
                appearance="ghost"
                style={styles.backButton}
                iconLeft="chevron-left"
                onPress={onClose}
                status="neutral"
              >
                Back
              </DNAButton>
              {/* Save buttons */}
              <DNABox appearance="row" spacing="medium" alignY="center">
                <DNABox spacing="small" alignY="center" fill>
                  <Controller
                    name="shouldSync"
                    control={control}
                    defaultValue={true}
                    render={({ onChange, value }) => {
                      return (
                        <Toggle.Kitten
                          checked={value}
                          onChange={e => handleSyncConfirmation(onChange, e)}
                          status="success"
                        />
                      )
                    }}
                  />
                  <DNAText>{shouldSyncVal ? 'Syncing enabled (syncs every 24 hours)' : 'Syncing disabled'}</DNAText>
                </DNABox>
                <Iffy is={selectedIntegration}>
                  <DNAButton
                    size="small"
                    disabled={disableSync}
                    style={{ marginHorizontal: 8 }}
                    onPress={handleViewLogs}
                    appearance="outline"
                  >
                    View Logs
                  </DNAButton>
                </Iffy>
                <DNAButton
                  size="small"
                  disabled={disableSync}
                  style={{ marginHorizontal: 8 }}
                  onPress={onSyncHandler}
                  appearance="outline"
                >
                  Sync
                </DNAButton>
                <DNAButton
                  style={{ maxHeight: 43 }}
                  onPress={form.handleSubmit(onSubmit)}
                  size="small"
                  disabled={!isDirty}
                  status={isSuccess ? 'success' : undefined}
                  iconLeft={isSuccess ? 'check' : undefined}
                >
                  {
                  isSuccess
                    ? selectedIntegration ? 'Update' : 'Save'
                    : isSubmitting ? <DNASpinner size="tiny" status="info" /> : selectedIntegration ? 'Update' : 'Save'
                }
                </DNAButton>
              </DNABox>
            </DNABox>
            <DNABox appearance="row" fill alignX="end">
              <DNAText style={{ marginHorizontal: 12, marginVertical: 4 }} status="danger">{syncError}</DNAText>
            </DNABox>
            <Divider style={{ marginTop: 16 }} />
            {/* Section Container */}
            <DNABox appearance="col" style={styles.sectionContainer}>
              <IntegrationForm beaconMappingValues={beaconMappingValues} selectedIntegration={selectedIntegration} />
            </DNABox>
          </DNABox>
        </DNACard>
      </FormProvider>
    </IntegrationProvider>)
}

export default Integration;
