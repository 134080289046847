import React from 'react'
import {
  Popover,
  StatusBullet,
  DNAText,
} from '@alucio/lux-ui'
import { DocumentORM } from 'src/types/types'

const DNADocumentStatusDot: React.FC<{ documentORM: DocumentORM }> = (props) => {
  const { documentORM } = props

  if (
    documentORM.model.status !== 'NOT_PUBLISHED' ||
    documentORM.meta.tenantFields.labelCompletion
  ) {
    return null;
  }

  return (
    <Popover>
      <Popover.Anchor>
        <StatusBullet status="warning" />
      </Popover.Anchor>
      <Popover.Content offset={2}>
        <DNAText
          numberOfLines={1}
          style={{ color: 'white' }}
        >
          A few more labels to go before publishing!
        </DNAText>
      </Popover.Content>
    </Popover>
  )
}

export default DNADocumentStatusDot
