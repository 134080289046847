import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import {
  DNASlider,
  DNABox,
  DNAIcon,
  DNAButton,
  DNAText,
  DNADivider,
  Iffy,
  LoadingIndicator,
  Popover,
  Icon,
} from '@alucio/lux-ui'
import { useDispatch } from 'react-redux'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'

import throttle from 'lodash/throttle'
import { TabView, Tab } from '@ui-kitten/components'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip';
import VersionHistory, { SidebarWrapper } from 'src/screens/Publishers/Versioning/VersionHistory'
import DocumentInfo, { DocumentInfoFormRef } from 'src/screens/Publishers/Versioning/DocumentInfo'
import DocumentSettings from 'src/screens/Publishers/Versioning/DocumentSettings/DocumentSettings'
import VersioningPanelContext, { useVersioningPanel } from './VersioningPanelContext'
import FileUpload from 'src/components/DNA/FileUpload/FileUpload';
import DocumentReleaseNotes from './DocumentReleaseNotes';
import DocumentPublish, { DocumentPublishFormRef } from './DocumentPublish';
import DocumentInfoReadonly from 'src/screens/Publishers/Versioning/DocumentInfoReadonly'
import DocumentCriticalError from './VersioningCriticalError';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import { multiSliceActions } from 'src/state/redux/slice/multiSlice'
import * as VersioningModals from 'src/components/DNA/Modal/DNAVersioningModals'
import { useAllDocumentsInstance } from 'src/state/redux/selector/document'

export const S = StyleSheet.create({
  tabContentContainer: {
    paddingTop: 40,
    paddingHorizontal: 40,
  },
  chevronIcon: {
    color: colors['color-flat-1200'],
    height: 16,
  },
  headerActionsWrapper: {
    backgroundColor: colors['color-text-basic'],
    borderColor: colors['color-flat-400'],
    borderRadius: 4,
    borderWidth: 1,
    paddingVertical: 16,
    paddingHorizontal: 20,
    shadowColor: colors['color-flat-1200'],
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.16,
    shadowRadius: 11.22,
  },
  headerWrapper: {
    padding: 8,
    maxHeight: 64,
  },
  tabsContentWrapper: {
    borderTopColor: colors['color-flat-400'],
    borderTopWidth: 1,
    backgroundColor: colors['color-flat-200'],
    zIndex: -1,
  },
})

interface HeaderButtonProps {
  handleCreateFromExistingVersion: () => void,
  handleUploadNewVersion: (files: FileList | null) => void,
}

const TabTitle: React.FC<{
  enableIcon?: boolean,
  text: string,
  idx: number,
  currentIdx: number,
}> = (props) => {
  const { enableIcon, idx, currentIdx, text } = props

  const isSelected = idx === currentIdx
  const status = isSelected ? 'primary' : 'subtle'

  return (
    <DNABox
      alignY="center"
      spacing="small"
      style={{ marginVertical: 10 }}
    >
      <Iffy is={enableIcon}>
        <DNAIcon.Styled
          appearance="outline"
          name={`numeric-${idx + 1}-box`}
          size="large"
          status={status}
        />
      </Iffy>
      <DNAText status={status}>{text}</DNAText>
    </DNABox>
  )
}

const useVersioningPanelHandlers = (
  documentInfoRef: React.RefObject<DocumentInfoFormRef>,
  documentPublishRef: React.RefObject<DocumentPublishFormRef>,
) => {
  const {
    state,
    send,
    toggleSlider,
    currentDocumentVersionORM,
  } = useVersioningPanel()
  const dispatch = useDispatch()

  const handleExitButton = useCallback(
    () => {
      const { documentInfoIsDirty, documentSettingsIsDirty, documentPublishIsDirty } = state.context
      const isAnyFormDirty = documentInfoIsDirty || documentSettingsIsDirty || documentPublishIsDirty

      if (!isAnyFormDirty) {
        toggleSlider()
        return;
      }

      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: () => <VersioningModals.DiscardChangesModal onConfirm={toggleSlider}/>,
      }))
    },
    [state.context],
  )

  const handleDeleteDraft = useCallback(
    () => {
      const confirmDelete = () => {
        const isFirstVersion = !currentDocumentVersionORM
          .relations
          .document
          .relations
          .version
          .latestDocumentVersionPublished

        // [NOTE] - In order to ensure the slick slider animation, we delete out-of-band for the machine
        //          While we could delete through the machine, the panel would go blank as the slider closes
        //          -or- the animation may not be smooth due to panel re-rendering performance
        isFirstVersion
          ? toggleSlider(() => dispatch(multiSliceActions.deleteDocumentDraft(currentDocumentVersionORM)))
          : send({ type: 'DELETE_DRAFT' })
      }

      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: () => <VersioningModals.DeleteDraftModal onConfirm={confirmDelete}/>,
      }))
    },
    [currentDocumentVersionORM],
  )

  const handlePublish = useCallback(
    () => {
      const asyncWrapper = async () => {
        const validateFormData = async () => {
          const documentInfoFormData = await documentInfoRef
            ?.current
            ?.handleCallingFormSubmitFromParent() ?? null

          const documentPublishFormData = await documentPublishRef
            ?.current
            ?.handlePublishDocumentInfo() ?? null

          if (!documentInfoFormData || !documentPublishFormData) {
            if (!documentInfoFormData) {
              send({ type: 'SWITCH_TAB_INFO' })
            } else if (!documentPublishFormData) {
              send({ type: 'SWITCH_TAB_PUBLISH' })
            }

            return;
          }
          return {
            ...documentInfoFormData,
            ...documentPublishFormData,
          }
        }

        const formData = await validateFormData()

        if (!formData) return;

        const documentORM = currentDocumentVersionORM
          .relations
          .document

        const sendPublishEvent = () => {
          send({
            type: 'PUBLISH_VERSION',
            payload: {
              ...formData,
            },
          })
        }

        const isFirstVersion = documentORM
          .relations
          .documentVersions.length === 1

        const componentType = isFirstVersion
          ? () => <VersioningModals.PublishDocumentModal onConfirm={sendPublishEvent}/>
          : () => <VersioningModals.PublishVersionModal onConfirm={sendPublishEvent}/>

        dispatch(DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: componentType,
        }))
      }

      asyncWrapper()
    },
    [state.context, currentDocumentVersionORM],
  )

  return {
    handleExitButton,
    handleDeleteDraft,
    handlePublish,
  }
}

const VersioningPanel: React.FC = () => {
  const {
    state,
    send,
    documentORM,
    currentDocumentVersionORM,
    cond,
  } = useVersioningPanel()

  const documentInfoRef = useRef<DocumentInfoFormRef>(null)
  const documentPublishRef = useRef<DocumentPublishFormRef>(null)
  const handlers = useVersioningPanelHandlers(documentInfoRef, documentPublishRef)
  const selectedIndex: number = state.context.selectedTabIndex

  const setSelectedIndex = useCallback(
    throttle(
      (e) => {
        if (cond.isNavBlocked) {
          return;
        }
        const targetTab = e === 0
          ? 'SWITCH_TAB_INFO'
          : e === 1
            ? 'SWITCH_TAB_SETTINGS'
            : 'SWITCH_TAB_PUBLISH'

        const payload = targetTab === 'SWITCH_TAB_PUBLISH'
          ? { versionForm: documentInfoRef.current?.getPurposeAndModifiable() }
          : undefined
        send({ type: targetTab, payload })
      },
      750,
    ),
    [cond.isNavBlocked],
  );

  const handleUploadNewVersion = (files: FileList | null) => {
    if (files) {
      const [file] = Array.from(files)

      if (!file) return;
      send({ type: 'CREATE_FROM_UPLOAD', payload: { file } })
    }
  }

  const handleCreateFromExistingVersion = () => {
    send({ type: 'CREATE_FROM_EXISTING' })
  }

  // [NOTE] - Drafts can be saved as partial DocumentVersions without form validation
  const handleSaveDraft = async () => {
    console.warn('SAVING??')

    const documentInfoFormData = await documentInfoRef
      ?.current
      ?.handleSavingFormAsDraftFromParent() ?? {}

    const documentPublishFormData = documentPublishRef
      ?.current
      ?.handleSaveDraftForDocumentPublish() ?? {}

    send({
      type: 'SAVE_DRAFT',
      payload: {
        versionForm: {
          ...documentInfoFormData,
          ...documentPublishFormData,
        },
      },
    })
  }

  const onVersionHistorySelect = (docVerId: string) => {
    send({
      type: 'SWITCH_DOCUMENT_VERSION',
      payload: { documentVersionId: docVerId },
    })
  }

  const docORM = currentDocumentVersionORM.relations.document
  const status = ['ARCHIVED', 'REVOKED'].includes(docORM.model.status)
    ? docORM.model.status
    : currentDocumentVersionORM.model.status

  const { documentInfoIsDirty, documentSettingsIsDirty, documentPublishIsDirty } = state.context
  const isAnyFormDirty = documentInfoIsDirty || documentSettingsIsDirty || documentPublishIsDirty

  const getShortenedText = (index: number, text?: string) => {
    if (!text) {
      return '';
    }
    const end = Math.min(text.length, index);
    return text.substring(0, end) + `${end < text.length ? '...' : ''}`;
  }

  return (
    <DNABox fill style={{ backgroundColor: 'white' }}>
      {/* SIDEBAR */}
      <SidebarWrapper>
        <VersionHistory
          isAnyFormDirty={isAnyFormDirty}
          selectedDocVerId={state.context.documentVersionId}
          documentORM={documentORM}
          onSelect={onVersionHistorySelect}
        />
      </SidebarWrapper>

      {/* CONTENT */}
      <DNABox fill appearance="col">

        {/* TITLE */}
        <DNABox spacing="between" style={S.headerWrapper}>
          <DNABox appearance="col" spacing="small">
            <DNAText bold>{getShortenedText(70, currentDocumentVersionORM?.model.title)}</DNAText>
            <DNABox spacing="tiny">
              <DNADocumentChip item={currentDocumentVersionORM} variant="semVer" />
              <DNADocumentChip item={currentDocumentVersionORM} variant="status" status={status} />
            </DNABox>
            <DNAText status="danger">
              {Object.entries(state.context.errors).length ? JSON.stringify(state.context.errors) : ''}
            </DNAText>
          </DNABox>
          <DNABox spacing="tiny">
            <DNABox style={{ paddingTop: 8 }} spacing="small">
              <DNABox spacing="medium">
                {/* DELETE DRAFT BUTTON */}
                <Iffy
                  is={
                    (cond.isInDraftingState && !cond.isProcessingNewVersion) ||
                    cond.hasProcessingError
                  }
                >
                  <DNAButton
                    testID="delete-draft-button"
                    appearance="outline"
                    status="subtle"
                    size="small"
                    onPress={handlers.handleDeleteDraft}
                    disabled={cond.isModifying}
                  >
                    <DNAText>Delete draft</DNAText>
                  </DNAButton>
                </Iffy>

                {/* SAVE DRAFT BUTTON */}
                <Iffy
                  is={(
                    cond.isInDraftingState &&
                    !cond.hasProcessingError &&
                    !cond.isProcessingNewVersion
                  )}
                >
                  <DNAButton
                    testID="save-draft-button"
                    appearance="outline"
                    status="subtle"
                    size="small"
                    onPress={handleSaveDraft}
                    disabled={cond.isModifying || !cond.isDraftDirty}
                  >
                    <DNAText>Save draft</DNAText>
                  </DNAButton>
                </Iffy>

                {/* CANCEL UPLOAD BUTTON */}
                <Iffy is={cond.isUploadingNewVersion}>
                  <DNAButton
                    appearance="outline"
                    status="subtle"
                    size="small"
                    disabled={cond.isCancellingNewVersionUpload}
                    onPress={() => send('CANCEL_UPLOAD')}
                    // @ts-expect-error - Prop incompatibility, but it works fine
                    accessoryLeft={cond.isCancellingNewVersionUpload ? LoadingIndicator.Native : undefined}
                  >
                    <DNAText>Cancel upload</DNAText>
                  </DNAButton>
                </Iffy>
              </DNABox>

              {/* NEW VERSION ACTION BUTTONS */}
              <HeaderNewVersionActions
                handleCreateFromExistingVersion={handleCreateFromExistingVersion}
                handleUploadNewVersion={handleUploadNewVersion}
              />
            </DNABox>
            {/* EXIT BUTTON */}
            <DNAButton
              status="dark"
              appearance="ghost"
              iconLeft="close"
              disabled={cond.isExitBlocked}
              onPress={handlers.handleExitButton}
            />
          </DNABox>
        </DNABox>

        {/* CONTENT TABS */}
        <DNABox fill style={S.tabsContentWrapper}>
          <Iffy is={!cond.isCriticalError}>
            <TabView
              // @ts-ignore - we patch in this prop from an newer version of UI Kitten
              swipeEnabled={false}
              selectedIndex={selectedIndex}
              onSelect={setSelectedIndex}
              style={{ flex: 1 }}
            >
              <Tab
                testID="document-info-tab"
                disabled={cond.isNavBlocked}
                title={() => (
                  <TabTitle
                    idx={0}
                    enableIcon={cond.isInDraftingState}
                    currentIdx={selectedIndex}
                    text="Document Info"
                  />
                )}
              >
                <DNABox fill>
                  {
                    !cond.isInDraftingState || cond.isProcessingNewVersion
                      ? <DocumentInfoReadonly />
                      : <DocumentInfo ref={documentInfoRef} />
                  }
                </DNABox>
              </Tab>
              <Tab
                testID="settings-tab"
                disabled={cond.isNavBlocked}
                title={() => (
                  <TabTitle
                    idx={1}
                    enableIcon={cond.isInDraftingState}
                    currentIdx={selectedIndex}
                    text={`Settings ${!cond.isInDraftingState ? '' : '(optional)'}`}
                  />
                )}
              >
                <DNABox fill>
                  <DocumentSettings />
                </DNABox>
              </Tab>
              <Tab
                testID="release-notes-tab"
                disabled={cond.isNavBlocked}
                title={() => (
                  <TabTitle
                    idx={2}
                    enableIcon={cond.isInDraftingState}
                    currentIdx={selectedIndex}
                    text={!cond.isInDraftingState ? 'Release Notes' : 'Publish'}
                  />
                )}
              >
                <DNABox fill>
                  {
                    !cond.isInDraftingState || cond.isPublishing
                      ? <DocumentReleaseNotes />
                      : <DocumentPublish ref={documentPublishRef} />
                  }
                </DNABox>
              </Tab>
            </TabView>
          </Iffy>
          {/* CRITICAL ERROR SCREEN */}
          <Iffy is={cond.isCriticalError}>
            <DocumentCriticalError />
          </Iffy>
        </DNABox>

        {/* BOTTOM BAR */}
        <Iffy is={cond.isInDraftingState && !cond.isCriticalError}>
          <DNADivider />
          <DNABox spacing="between" style={{ margin: 12 }}>
            <DNABox>
              <Iffy is={selectedIndex !== 0}>
                <DNAButton onPress={() => send('SWITCH_TAB_PREV')}>
                  Back
                </DNAButton>
              </Iffy>
            </DNABox>

            <DNABox>
              <Iffy is={selectedIndex !== 2}>
                <DNAButton
                  disabled={cond.isNavBlocked}
                  onPress={
                    () => {
                      send({
                        type: 'SWITCH_TAB_NEXT',
                        payload: { versionForm: documentInfoRef.current?.getPurposeAndModifiable() },
                      })
                    }
                  }
                >
                  Next
                </DNAButton>
              </Iffy>
              <Iffy is={selectedIndex === 2}>
                <DNAButton
                  testID="publish-button"
                  onPress={handlers.handlePublish}
                >
                  Publish
                </DNAButton>
              </Iffy>
            </DNABox>
          </DNABox>
        </Iffy>

        {/* READ ONLY */}
        <Iffy is={!cond.isInDraftingState}>
          <DNADivider />
          <DNABox style={{ backgroundColor: 'white', padding: 16 }}>
            <DNAText bold status="warning">
              This is view-only. You can only make edits when versioning up.
            </DNAText>
          </DNABox>
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

const HeaderNewVersionActions = (props: HeaderButtonProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { documentORM, cond } = useVersioningPanel();
  const canUploadNewVersion = cond.canCreateNewVersion && !cond.isUploadingNewVersion;

  // IF NO ACTION CAN BE MADE, NOTHING NEEDS TO BE RENDERED
  if (!cond.canCreateNewVersion && !canUploadNewVersion) {
    return null;
  }

  function toggleVisibility(callback?: () => void): void {
    typeof callback === 'function' && callback();
    setIsVisible((visible) => !visible);
  }

  function selectNewVersion(files: FileList | null): void {
    toggleVisibility();
    props.handleUploadNewVersion(files);
  }

  return (
    <Popover
      lazyMount
      placement="bottom-end"
      interactive={true}
      visible={isVisible}
      onBackdropPress={toggleVisibility}
      type="menu"
      style={{ width: 285 }}
    >
      <Popover.Anchor>
        <DNAButton
          testID="create-new-version-button"
          onPress={() => toggleVisibility()}
          appearance="outline"
          status="subtle"
          size="small"
        >
          <DNABox spacing="small" alignY="center">
            <DNAText>
              Create new version
            </DNAText>
            <DNABox>
              <Icon style={S.chevronIcon} name="chevron-down" />
            </DNABox>
          </DNABox>
        </DNAButton>
      </Popover.Anchor>
      {/* CONTENT */}
      <Popover.Content offset={5}>
        <DNABox style={S.headerActionsWrapper} appearance="col" spacing="medium">
          {/* CREATE FROM EXISTING */}
          { cond.canCreateNewVersion &&
            <Pressable onPress={() => toggleVisibility(props.handleCreateFromExistingVersion)}>
              <DNAText numberOfLines={1}>
                Create new version from existing file
              </DNAText>
            </Pressable>
          }
          {/* UPLOAD NEW VERSION BUTTON */}
          { canUploadNewVersion &&
            <FileUpload.Input
              fileExt={
                documentORM.relations.version.latestDocumentVersionPublished
                  ? [documentORM.model.type]
                  : ['PPTX', 'PDF']
              }
              multiFile={false}
              processOnSelect={false}
              onSelect={selectNewVersion}
            >
              <Pressable>
                <DNAText>Upload new version</DNAText>
              </Pressable>
            </FileUpload.Input>
          }
        </DNABox>
      </Popover.Content>
    </Popover>
  );
};

const VersioningPanelWrapper: React.FC<{
  documentId?: string,
  setDocumentId?: React.Dispatch<React.SetStateAction<string | undefined>>
}> = (props) => {
  const { documentId, setDocumentId } = props
  const [visible, setVisible] = useState<boolean>(false)
  const onInvisibleCallback = useRef<() => void>()
  const toggleSlider = (onInvisCb?: () => void) => {
    onInvisibleCallback.current = onInvisCb
    setVisible(p => !p)
  }

  const documentFilter = useMemo(
    () => ({ filter: { model: { id: documentId } } }),
    [documentId],
  );

  const [documentORM] = useAllDocumentsInstance(documentFilter)

  useEffect(
    () => { if (documentId) setVisible(true) },
    [documentId],
  )

  useEffect(
    () => {
      const gracefullyHandleDeletedDocument = () => {
        if (!documentORM && visible)
        { setVisible(false) }
      }

      gracefullyHandleDeletedDocument()
    },
    [documentORM, visible],
  )

  return (
    <DNABox appearance="col">
      {/* [NOTE] - This is playground specific */}
      <Iffy is={!setDocumentId}>
        <DNAText>Versioning Redesign</DNAText>
        <DNAButton onPress={() => toggleSlider()}>
          Toggle Slider
        </DNAButton>
      </Iffy>

      <DNASlider
        visible={visible}
        setVisible={setVisible}
        onInvisible={() => {
          if (typeof onInvisibleCallback.current === 'function')
          { onInvisibleCallback.current?.() }
          setDocumentId?.(undefined)
        }}
        orientation="horizontal"
      >
        {
          documentORM
            ? (
              <VersioningPanelContext
                documentORM={documentORM}
                toggleSlider={toggleSlider}
              >
                <VersioningPanel />
              </VersioningPanelContext>
            )
            : null
        }
      </DNASlider>
    </DNABox>
  )
}

export default VersioningPanelWrapper
