import { AttachedFile } from '@alucio/aws-beacon-amplify/src/models';

import { createSlice } from '@reduxjs/toolkit';

import { commonReducers, initialState } from './common';

const sliceName = 'attachedFile';
const { reducers, extraReducers } = commonReducers<AttachedFile>(sliceName);

const attachedFileSlice = createSlice({
  name: sliceName,
  initialState: initialState<AttachedFile>(),
  reducers: {
    ...reducers,
  },
  extraReducers,
});

export default attachedFileSlice;
export const attachedFileActions = {
  ...attachedFileSlice.actions,
};
