import React, { useState, useEffect } from 'react';
import { Image, StyleSheet } from 'react-native';
import { Logger } from '@aws-amplify/core';
import {
  Iffy,
  DNABox,
  DNAButton,
  DNAText,
} from '@alucio/lux-ui'
import { WebLinking as Linking } from '@alucio/core'
import workerChannel from 'src/worker/channels/workerChannel'
import { useAppSettings } from 'src/state/context/AppSettings';

import { useSyncState } from 'src/state/redux/selector/cache';
import { SyncStatusInfo } from 'src/components/DNA/Header/SyncStatusButton';
const logger = new Logger('UserInit', 'INFO');
const installScreenshot = require('../../../assets/images/pwa_install.png')

const styles = StyleSheet.create({
  mainWrapper: {
    minWidth: 500,
    paddingTop: 40,
  },
});

const Offline = () => {
  const { isOfflineEnabled } = useAppSettings()
  const [isWaitingToDisable, setIsWaitingToDisable] = useState<boolean>(false)
  const syncState = useSyncState()
  const disableServiceWorker = async () => {
    // We subscribe to watch for a purge success message from the SW before terminating
    // the service worker
    workerChannel
      .observable
      .filter(msg => msg.type === 'CACHE_DB' && msg.value === 'PURGE_COMPLETE')
      .subscribe(async () => {
        logger.debug('DB Purge Successful.')
        const registration = await navigator.serviceWorker.getRegistration('/')
        registration?.unregister().then((isUnregistered) => {
          logger.info('SW Unregistered', isUnregistered)
          Linking.openURL('/profile/offline', '_self');
        })
      })

    workerChannel.postMessageExtended({
      type: 'CACHE_DB',
      value: 'PURGE',
    })
  }
  useEffect(() => {
    if (isWaitingToDisable && syncState.matches('online.paused')) {
      disableServiceWorker()
    }
  }, [isWaitingToDisable, syncState])

  const toggleOffline = async () => {
    if (isOfflineEnabled) {
      if (syncState.matches('online.sync')) {
        workerChannel.postMessageExtended({ type: 'PAUSE_SYNC' })
        setIsWaitingToDisable(true)
      } else {
        disableServiceWorker();
      }
    } else {
      // Register the service worker and reload
      navigator.serviceWorker
        .register('/worker.js')
        .then(registration => {
          logger.info('SW registered: ', registration);
          Linking.openURL('/profile/offline', '_self');
        })
        .catch(registrationError => {
          logger.error('SW registration failed: ', registrationError);
        });
    }
  }
  return (
    <DNABox style={styles.mainWrapper} childFill spacing="between" fill alignY="center" appearance="row" >
      <DNABox appearance="col" fill childFill spacing="large">
        <DNAText>Beacon can be installed on your computer as an application and can be used offline</DNAText>
        <DNABox appearance="col" fill childFill spacing="small">
          <DNAText bold>To install the Beacon application:</DNAText>
          <DNAText>1. You must be connected to the internet</DNAText>
          <DNAText>2. Don't use private or Incognito browsing</DNAText>
          <DNAText>3. Click the "Enable offline mode" button below</DNAText>
          <DNAText numberOfLines={3}>
            4. Once enabled, an install icon will appear in the address bar. Click to install the application.
          </DNAText>
          <Image
            source={installScreenshot}
            style={{ width: 300, height: 157 }}
          />
        </DNABox>
        <DNAButton onPress={toggleOffline}>
          {isOfflineEnabled ? 'Disable offline mode' : 'Enable offline mode'}
        </DNAButton>
      </DNABox>
      <Iffy is={isOfflineEnabled}>
        <DNABox alignY="center" alignX="center" fill>
          <DNABox style={{ maxWidth: 400 }} >
            <SyncStatusInfo />
          </DNABox>
        </DNABox>
      </Iffy>
    </DNABox>
  );
};

export default Offline;
