import React from 'react';
import { useDispatch } from 'react-redux';

import { DNABox, DNAButton, DNAModal, DNAText, luxColors } from '@alucio/lux-ui';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';

interface Props {
  closeUserDrawer: () => void;
}

const DNACloseUserDrawerConfirmation = (props: Props) => {
  const { closeUserDrawer } = props;
  const dispatch = useDispatch();

  function closeModal(): void {
    dispatch(DNAModalActions.closeModal());
  }

  function closeWithoutSaving(): void {
    closeUserDrawer();
    closeModal();
  }

  return (
    <DNAModal>
      <DNAModal.Header onClose={closeModal}>
        <DNAText h5>Unsaved changes</DNAText>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox style={{ padding: 8 }}>
          <DNAText style={{ color: luxColors.flat.primary }}>All changes will be lost.</DNAText>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <DNAButton status="flat" onPress={closeModal}>
          <DNAText status="flat">Continue editing</DNAText>
        </DNAButton>
        <DNAButton onPress={closeWithoutSaving} status="primary">Close without saving</DNAButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
};

export default DNACloseUserDrawerConfirmation;
