import React from 'react'
import { DNABox, DNASlider } from '@alucio/lux-ui'
import PresentationEditor from './PresentationEditor/PresentationEditor'
import PresentationBuilderStateProvider from './state/PresentationBuilderStateProvider'
import PresentationSelector from './PresentationSelector/PresentationSelector'
import { useSelector } from 'react-redux'
import { RootState } from 'src/state/redux/store'
import { presentationBuilderActions } from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder'
import { StyleProp, ViewStyle } from 'react-native'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'

interface PresentationBuilderProps {
}

const PresentationBuilderComponents: React.FC = () => {
  const containerStyle:StyleProp<ViewStyle> = {
    backgroundColor: colors['color-text-basic'],
  }
  return (
    <DNABox
      fill
      style={containerStyle}
    >
      <PresentationSelector/>
      <PresentationEditor />
    </DNABox>
  )
}

const PresentationBuilder:React.FC<PresentationBuilderProps> = () => {
  const globalBuilderState = useSelector((state: RootState) => state.PresentationBuilder)

  return (
    <DNASlider
      visible={globalBuilderState.visible}
      setVisible={() => presentationBuilderActions.closePresentationBuilder()}
    >
      <PresentationBuilderStateProvider>
        <PresentationBuilderComponents/>
      </PresentationBuilderStateProvider>
    </DNASlider>
  )
}

export default PresentationBuilder
