import { LabelValues, ShareTargetType } from '@alucio/aws-beacon-amplify/src/models'
import { DNABox, DNAButton, DNADivider, DNAModal, DNAText, Iffy, luxColors } from '@alucio/lux-ui'
import React, { useMemo } from 'react'
import { FlatList, StyleSheet } from 'react-native'
import { FolderORM } from 'src/types/orms'
import Folder from '../../Folder/DNAFolder'
import { DNAModalVariant } from '../DNAConnectedModal'
import { isEveryoneType, isFilterType, isUserType, ShareFilter, ShareFolderMode } from './DNAShareFolderModal'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import isEqual from 'lodash/isEqual'

const styles = StyleSheet.create({
  icon: {
    height: 25,
    width: 25,
  },
  hoveredBackgroundColor: {
    backgroundColor: luxColors.hoveredBackground.primary,
  },
  menu: {
    maxHeight: 300,
  },
  titleText: {
    color: colors['color-flat-900'],
    fontSize: 14,
    lineHeight: 16,
  },
  searchBarStyle: {
    backgroundColor: colors['color-text-basic'],
    // TODO: Color not available on the theming yet
    borderColor: luxColors.disabled.secondary,
    borderRadius: 4,
    width: 316,
    overflow: 'hidden',
  },
  searchBarWrapper: {
    marginTop: 20,
    flex: 1,
  },
  recordRow: {
    flex: 1,
    flexDirection: 'row',
    height: 52,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
  },
  noResults: {
    marginLeft: 4,
    marginBottom: 6,
    marginTop: 6,
  },
  resultsWrapperStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  customFieldsText: {
    color: colors['color-flat-500'],
    fontSize: 12,
    lineHeight: 20,
  },
  folderMargins: {
    marginLeft: 24,
    marginRight: 12,
  },
})

interface PermissionsListProps {
  currentItems: ShareFilter[]
  onRemoveItem: (id: string) => void
}

const PermissionsList: React.FC<PermissionsListProps> = React.memo((props) => {
  const { currentItems, onRemoveItem } = props

  const items = useMemo(() => {
    const shareFilters: ShareFilter[] = []
    if (currentItems) {
      currentItems.forEach((item) => {
        if (isUserType(item.filter)) {
          shareFilters.push(item)
        }
      })
    }
    shareFilters.sort((a, b) => {
      if (isUserType(a.filter) && isUserType(b.filter)) {
        const aName  = a.filter.givenName + ' ' + a.filter.familyName;
        const bName  = b.filter.givenName + ' ' + b.filter.familyName;
        return aName.toLocaleLowerCase().localeCompare(bName.toLocaleLowerCase());
      }
      return 0;
    })
    if (currentItems) {
      currentItems.forEach((item) => {
        if (isFilterType(item.filter)) {
          shareFilters.unshift(item)
        }
      })
      currentItems.forEach((item) => {
        if (isEveryoneType(item.filter)) {
          shareFilters.unshift(item);
        }
      })
    }
    return shareFilters
  }, [currentItems])

  return (
    <FlatList
      data={items.filter((item) => !item.isDeleted)}
      keyExtractor={({ id }) => id}
      ListEmptyComponent={(
        <DNABox>
          <DNAText status="subtle">Only you have access</DNAText>
        </DNABox>
      )}
      ItemSeparatorComponent={() => <DNADivider style={{ marginVertical: 12 }} />}
      scrollEnabled
      renderItem={({ item }) => {
        return (
          <DNABox appearance="row" spacing="between" fill alignY="center">
            {isEveryoneType(item.filter) &&
              <DNABox>
                <DNAText bold status="basic" numberOfLines={1} style={styles.titleText}>
                  Everyone
                </DNAText>
              </DNABox>
            }
            {isUserType(item.filter) &&
              <DNABox testID="sharee-users-container" shrink appearance="col" alignY="center" fill>
                <DNAText bold status="basic" numberOfLines={1} style={styles.titleText}>
                  {`${item.filter.givenName} ${item.filter.familyName}`}
                </DNAText>
                <DNAText style={styles.customFieldsText} numberOfLines={1}>
                  {item.filter.email}
                </DNAText>
              </DNABox>
            }
            {isFilterType(item.filter) &&
              <DNABox appearance={'col'} fill alignY="center">
                {Object.keys(item.filter).map(key => {
                  const filters = item.filter[key] as LabelValues
                  const joinedValues = filters.values.join(', ')
                  return (
                    <DNABox appearance="row" key={filters.key + filters.values.join(', ')} >
                      {/* TODO-2340 fix wrapping with long filter names */}
                      <DNAText bold status="basic" numberOfLines={3} style={styles.titleText}> {`${key}:`} </DNAText>
                      <DNAText style={[styles.customFieldsText, { marginLeft: 4, width: 300 }]}>{joinedValues}</DNAText>
                    </DNABox>
                  )
                })}
              </DNABox>
            }
            <DNABox appearance="row" alignY="center">
              <DNAButton
                size="tiny"
                appearance="outline"
                status="info"
                disabled={true}
              >
                Can view
              </DNAButton>
              <DNAButton
                iconLeft="close"
                onPress={() => onRemoveItem(item.id)}
                appearance="ghost"
                status="neutral"
                size="medium"
              />
            </DNABox>
          </DNABox>
        )
      }}
    />
  )
})
interface ShareFolderProps {
  folderORM: FolderORM
  setMode: (mode: ShareFolderMode) => void
  currentItems: ShareFilter[]
  onRemoveItem: (id: string) => void
  onConfirm: () => void
  initialValues: ShareFilter[]
  display: boolean
  isDesktop: boolean
  onShareWithEveryone: () => void
}

const ShareFolder: DNAModalVariant<ShareFolderProps> = React.memo((props) => {
  const {
    folderORM,
    toggleModal,
    setMode,
    currentItems,
    onRemoveItem,
    onConfirm,
    onShareWithEveryone,
    initialValues,
    display,
    isDesktop,
  } = props

  const canUnshare = initialValues.length > 0 && initialValues.some((item) => !item.isDeleted)
  const unshareOnly = initialValues?.some((item) => !item.isDeleted) && currentItems?.every((item) => item.isDeleted)

  const isSharedWithEveryone = currentItems?.some(({ targetType, isDeleted }) => {
    return targetType === ShareTargetType.ALL && !isDeleted;
  });

  return (
    <DNABox style={{ display: display ? undefined : 'none' }}>
      <DNAModal>
        <DNAModal.Header>
          {/* DESKTOP HEADER */}
          <Iffy is={isDesktop}>
            <DNABox
              testID="share-folder-modal-header"
              appearance="row"
              spacing="between"
              style={{ minWidth: 600 }}
              alignY="center"
            >
              <DNABox appearance="row" alignY="center">
                <DNABox appearance="col" style={styles.folderMargins}>
                  <Folder folder={folderORM} mode={'SHARING'} />
                </DNABox>
                <DNABox appearance="col" alignY="center">
                  <DNAText h4>Share Folder</DNAText>
                  <DNAText status="subtle">{folderORM.model.name}</DNAText>
                </DNABox>
              </DNABox>
              <DNAButton
                testID="cancel-icon-button"
                iconLeft="close"
                onPress={toggleModal}
                appearance="ghost"
                status="neutral"
                size="medium"
              />
            </DNABox>
          </Iffy>
          {/* TABLET HEADER */}
          <Iffy is={!isDesktop}>
            <DNABox
              testID="share-folder-modal-header"
              appearance="row"
              spacing="between"
              style={{ minWidth: 600 }}
              alignY="center"
            >
              <DNABox alignY="center">
                <DNAButton iconLeft="close" onPress={toggleModal} appearance="ghost" status="neutral" size="large" />
                <DNAText status="dark" h4>{folderORM.model.name}</DNAText>
              </DNABox>
              <DNABox alignY="center">
                <Iffy is={canUnshare && !unshareOnly}>
                  <DNAButton
                    size="large"
                    appearance="ghost"
                    status="dark"
                    onPress={() => setMode(ShareFolderMode.UNSHARE)}
                  >
                    Unshare
                  </DNAButton>
                </Iffy>
                <DNAButton
                  size="small"
                  onPress={onConfirm}
                  status="primary"
                  appearance="ghost"
                  disabled={isEqual(initialValues, currentItems)}
                >
                  {unshareOnly ? 'Unshare' : 'Share'}
                </DNAButton>
              </DNABox>
            </DNABox>
          </Iffy>
        </DNAModal.Header>
        <DNAModal.Body>
          <DNABox appearance="col" fill>
            <DNABox appearance="col" style={{ margin: 24 }}>
              <DNABox appearance="row" spacing="small">
                <DNAButton
                  appearance="outline"
                  status="info"
                  size="small"
                  testID="add-people-button"
                  onPress={() => setMode(ShareFolderMode.ADD_PEOPLE)}
                >
                  Add people
                </DNAButton>
                <DNAButton
                  appearance="outline"
                  status="info"
                  size="small"
                  testID="share-with-everyone-button"
                  onPress={onShareWithEveryone}
                  disabled={isSharedWithEveryone}
                >
                  Share with everyone
                </DNAButton>
                <DNAButton
                  appearance="outline"
                  status="info"
                  testID="create-access-filter-button"
                  onPress={() => setMode(ShareFolderMode.CREATE_ACCESS_FILTER)}
                  size="small"
                >
                  Create access filter
                </DNAButton>
              </DNABox>
              <DNADivider style={{ marginTop: 24 }} />
              <DNAText label style={{ marginTop: 24 }}>Who Has Access</DNAText>
              <DNABox style={{ marginTop: 16, height: 308 }}>
                <PermissionsList currentItems={currentItems} onRemoveItem={onRemoveItem} />
              </DNABox>
            </DNABox>
          </DNABox>
        </DNAModal.Body>
        <Iffy is={isDesktop}>
          <DNAModal.Footer>
            <DNABox appearance="row" spacing="between" style={{ width: '100%' }}>
              <DNABox>
                <Iffy is={canUnshare && !unshareOnly}>
                  <DNAButton
                    size="small"
                    appearance="outline"
                    status="info"
                    onPress={() => setMode(ShareFolderMode.UNSHARE)}
                  >
                    Unshare
                  </DNAButton>
                </Iffy>
              </DNABox>
              <DNABox appearance="row" spacing="small">
                <DNAButton
                  size="small"
                  testID="cancel-button"
                  appearance="outline"
                  status="info"
                  onPress={toggleModal}
                >
                  Cancel
                </DNAButton>
                <DNAButton
                  size="small"
                  onPress={onConfirm}
                  status="primary"
                  disabled={isEqual(initialValues, currentItems)}
                >
                  {unshareOnly ? 'Unshare' : 'Share Folder'}
                </DNAButton>
              </DNABox>
            </DNABox>
          </DNAModal.Footer>
        </Iffy>
      </DNAModal>
    </DNABox>
  )
})

export default ShareFolder
