import { FolderORM } from '../types/orms';
import { isCustomDeckORM, isFolderORM } from 'src/types/typeguards'

export function getRootFolder(folderORM: FolderORM): FolderORM {
  if (folderORM.relations.parentFolder) {
    return getRootFolder(folderORM.relations.parentFolder);
  } else {
    return folderORM;
  }
}

export function doesTreeContainsCustomDeck(folderORM: FolderORM): boolean {
  const rootFolder = getRootFolder(folderORM);

  function hasCustomDeck(folderORM: FolderORM): boolean {
    let containsCustomDeck = false;

    for (const item of folderORM.relations.items) {
      if (isCustomDeckORM(item.relations.item)) {
        containsCustomDeck = true;
        break;
      } else if (isFolderORM((item.relations.item))) {
        containsCustomDeck = hasCustomDeck(item.relations.item);
      }
    }

    return containsCustomDeck;
  }
  return hasCustomDeck(rootFolder);
}
