import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FolderItemORM, FolderORM } from 'src/types/types';

export interface PresentationBuilderSliceType {
  visible?: boolean
  targetFolder?: FolderORM
  customDeckId?: string
  folderItemORM?: FolderItemORM
  context?: string,
}

const initialState: PresentationBuilderSliceType = {
  visible: false,
}

const presentationBuilderSlice = createSlice({
  name: 'presentationBuilderState',
  initialState: initialState,
  reducers: {
    /**
     * This reducer will either toggle the current visibility of the builder or,
     * if included, set the visibility to the provided state
     * @param state
     * @param action
     * @returns global presentation builder state values
     */
    closePresentationBuilder : () => {
      return initialState;
    },
    openPresentationBuilderEdit: (state, action: PayloadAction<PresentationBuilderSliceType>) => {
      const { payload } = action;
      state.visible = true;
      state.targetFolder = payload.targetFolder;
      state.customDeckId = payload.customDeckId;
      state.folderItemORM = payload.folderItemORM;

      analytics?.track('CUSTOM_OPEN_BUILDER', {
        action: 'OPEN_BUILDER',
        category: 'CUSTOM',
        context: payload.context ?? 'EDIT',
        customDeckId: payload.customDeckId,
      })
      return state;
    },
    openPresentationBuilderNew: (state, action: PayloadAction<PresentationBuilderSliceType>) => {
      const { payload } = action;
      state.visible = true;
      state.targetFolder = payload.targetFolder;
      state.customDeckId = undefined;
      state.folderItemORM = undefined;

      analytics?.track('CUSTOM_OPEN_BUILDER', {
        action: 'OPEN_BUILDER',
        category: 'CUSTOM',
        context: 'CREATE',
      })
      return state;
    },
  },
});

export default presentationBuilderSlice;
export const presentationBuilderActions = {
  ...presentationBuilderSlice.actions,
}
