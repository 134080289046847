import React from 'react';
import {
  Iffy,
  DNABox,
} from '@alucio/lux-ui';
import PresentationPanel from './PresentationControls/PresentationControls';
import InPersonContent from './InPersonContent/InPersonContent';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';

const MeetingsContent = () => {
  const { meetingORM } = useMeetingsState()
  return (
    <DNABox fill childFill={0}>
      <PresentationPanel />
      <Iffy is={meetingORM?.model?.type === MeetingType.IN_PERSON}>
        <InPersonContent />
      </Iffy>
    </DNABox>
  );
};

MeetingsContent.displayName = 'MeetingsContent';

export default MeetingsContent;
