/** MODULES */
import { createMachine, spawn } from 'xstate'
import { assign } from '@xstate/immer'
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import Storage from '@aws-amplify/storage'

/** BEACON TYPES */
import {
  DocumentVersion,
  Document,
  DocumentVersionChangeType,
  AssociatedFileType,
} from '@alucio/aws-beacon-amplify/src/models'
import {
  CreateDocumentVersionFromS3UploadInput,
  CreateDocumentVersionFromS3UploadMutation,
} from '@alucio/aws-beacon-amplify/src/API'
import {
  createDocumentVersionFromExisting,
  createDocumentVersionFromS3Upload,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations'

/** VERSIONING TYPES */
import * as Ver from 'src/state/machines/versioning/versioningTypes'
import { StateTags } from 'src/state/machines/versioning/versioningTypes'
import { INFO_MESSAGES } from 'src/state/machines/versioning/versioningConstants'
import { UPLOAD_STATUS } from 'src/components/DNA/FileUpload/FileUpload'

/** BEACON UTIL */
import store from 'src/state/redux/store'
import { observable } from 'src/state/machines/versioning/observableVersion'
import { documentActions } from 'src/state/redux/slice/document'
import { documentVersionActions, createAssociatedFile } from 'src/state/redux/slice/documentVersion'
import versioningUtil from 'src/state/machines/versioning/versioningUtil';
import { multiSliceActions } from 'src/state/redux/slice/multiSlice';

/** RE-EXPORTS */
export * from 'src/state/machines/versioning/versioningTypes'
export * from 'src/state/machines/versioning/versioningConstants'

const updateVersion = (docVer: DocumentVersion) => createMachine<
  Ver.UpdateVersionContext,
  Ver.UpdateVersionEvents,
  Ver.UpdateVersionState
>(
  {
    id: 'UpdateVersion',
    strict: true,
    context: {
      cancelUpload: false,
      documentVersionId: docVer.id,
      documentInfoIsDirty: false,
      documentSettingsIsDirty: false,
      documentPublishIsDirty: false,
      errors: { },
      getDocumentORM: versioningUtil.getDocumentORMFactory(docVer.documentId),
      hasOptimizedFinishedThisSession: false,
      selectedTabIndex: 0,
      versionActor: undefined,
      versionForm: versioningUtil.omitInternalFields(docVer),
    },
    // Spawn an observer to watch for updates from AppSync
    entry: assign(ctx => {
      if (!ctx.versionActor) {
        ctx.versionActor = spawn(
          observable({ filter: { model: { id: docVer.documentId } } }),
          'VersionActor',
        )
      }
    }),
    initial: 'determine',
    states: {
      determine: {
        description: 'Determines whether we should go to the Publish or Draft node branches',
        always: [
          { target: 'published', cond: 'isSealed' },
          { target: 'published', cond: 'isPublished' },
          { target: 'draft' },
        ],
      },
      published: {
        description: 'The published nodes intended for viewing and creating new versions',
        initial: 'documentInfo',
        states: {
          documentInfo: {
            tags: [StateTags.DOCUMENT_INFO_TAB],
          },
          documentSettings: {
            tags: [StateTags.DOCUMENT_SETTINGS_TAB],
          },
          documentReleaseNotes: {
            tags: [StateTags.DOCUMENT_RELEASE_NOTES_TAB],
          },
        },
        on: {
          CREATE_FROM_EXISTING: {
            target: 'draft.documentInfo.processing.existing',
            cond: 'canCreateNewVersion',
          },
          CREATE_FROM_UPLOAD: {
            target: 'draft.documentInfo.processing.upload',
            cond: 'canCreateNewVersion',
          },
          SWITCH_TAB_INFO: { target: '.documentInfo', actions: 'switchTabIdx' },
          SWITCH_TAB_SETTINGS: { target: '.documentSettings', actions: 'switchTabIdx' },
          SWITCH_TAB_PUBLISH: { target: '.documentReleaseNotes', actions: 'switchTabIdx' },
        },
      },
      draft: {
        initial: 'documentInfo',
        states: {
          documentInfo: {
            description: 'Document Info tab in draft mode where most file processing happens',
            tags: [StateTags.DOCUMENT_INFO_TAB],
            initial: 'determine',
            states: {
              determine: {
                description: 'Determines current draft state node if file is queued, processing or idle',
                always: [
                  { target: 'processing.upload.queued', cond: 'isQueued' },
                  { target: 'processing.upload.optimizing', cond: 'isOptimizing' },
                  { target: 'processing.upload.error', cond: 'hasProcessingError' },
                  { target: 'idle' },
                ],
              },
              idle: { },
              processing: {
                description: 'Processing node for existing or new uploaded files',
                entry: assign((ctx) => { ctx.selectedTabIndex = 0 }),
                states: {
                  existing: {
                    initial: 'optimizing',
                    tags: [StateTags.DISABLE_DRAFT_DELETE],
                    states: {
                      optimizing: {
                        description: 'Creating an existing file via Lambda function',
                        tags: [StateTags.DISABLE_EXIT, StateTags.DISABLE_MODIFY, StateTags.DISABLE_NAV],
                        meta: { infoMessage: INFO_MESSAGES.QUEUED },
                        invoke: {
                          src: 'createFromExistingOptimizing',
                          onDone: { target: 'processing' },
                          onError: {
                            target: '#UpdateVersion.draft.documentInfo.idle',
                            actions: 'handleError',
                          },
                        },
                      },
                      processing: {
                        description: 'Waiting to receive an AppSync subscription to update the current form values',
                        tags: [StateTags.DISABLE_MODIFY, StateTags.DISABLE_NAV],
                        meta: { infoMessage: INFO_MESSAGES.OPTIMIZING },
                        on: {
                          VERSION_UPDATE: [
                            {
                              target: '#UpdateVersion.published',
                              cond: 'isDraftDeleted',
                              actions: ['switchToLatestPublishedVersion'],
                            },
                            {
                              target: '#UpdateVersion.draft.documentInfo.idle',
                              actions: [
                                'setDocumentVersionIdFromUpdate',
                                'setVersionFormFromUpdate',
                              ],
                            },
                          ],
                        },
                      },
                    },
                  },
                  upload: {
                    description: 'A multistep process to upload a file and subsequently create records',
                    initial: 'uploading',
                    states: {
                      uploading: {
                        description: 'Step 1. Uploading the file to S3',
                        tags: [StateTags.DISABLE_MODIFY, StateTags.DISABLE_NAV, StateTags.DISABLE_DRAFT_DELETE],
                        meta: {
                          infoMessage: INFO_MESSAGES.UPLOADING,
                          cancelMessage: INFO_MESSAGES.UPLOAD_CANCELLING,
                        },
                        entry: ['resetFileUploadCancel', 'switchToInfoTab'],
                        invoke: {
                          src: 'uploadFile',
                          onDone: [
                            { target: '#UpdateVersion.published', cond: 'isFileUploadCancelled' },
                            { target: 'createRecord' },
                          ],
                          onError: {
                            actions: 'handleError',
                            target: '#UpdateVersion.draft.documentInfo.idle',
                          },
                        },
                        on: {
                          CANCEL_UPLOAD: {
                            actions: 'flagFileUploadCancel',
                            // [NOTE] - This meta property is available until the next event
                            //          (which is dispatched after upload/cancel is finished)
                            meta: { infoMessage: INFO_MESSAGES.UPLOAD_CANCELLING },
                          },
                        },
                      },
                      createRecord: {
                        description: 'Step 1.5 - Create the records via Lambda function or bail if upload cancelled',
                        tags: [
                          StateTags.DISABLE_MODIFY,
                          StateTags.DISABLE_EXIT,
                          StateTags.DISABLE_NAV,
                          StateTags.DISABLE_DRAFT_DELETE,
                        ],
                        meta: { infoMessage: INFO_MESSAGES.UPLOADING },
                        invoke: {
                          src: 'createRecordForUpload',
                          onDone: { target: 'processing' },
                          onError: {
                            actions: 'handleError',
                            target: '#UpdateVersion.draft.documentInfo.processing.upload.error',
                          },
                        },
                        // [NOTE] - There is a race condition between the GQL call finishing
                        //          and the AppSync updates being sent out early
                        //        - If we receive an optimistic AppSync update before the GQL call finishes
                        //          Skip the next step and go straight to queued
                        on: {
                          VERSION_UPDATE: [
                            {
                              target: '#UpdateVersion.published',
                              cond: 'isDraftDeleted',
                              actions: ['switchToLatestPublishedVersion'],
                            },
                            {
                              target: 'queued',
                              // [TODO-2126] - Add guards to make sure we're not receiving outside updates
                              //          not intended for this session
                              actions: 'setDocumentVersionIdFromUpdate',
                            },
                          ],
                        },
                      },
                      processing: {
                        description: 'Step 1.75 - An interim step to wait for the official queued status',
                        tags: [StateTags.DISABLE_NAV, StateTags.DISABLE_MODIFY, StateTags.DISABLE_DRAFT_DELETE],
                        meta: { infoMessage: INFO_MESSAGES.UPLOADING },
                        on: {
                          VERSION_UPDATE: [
                            {
                              target: '#UpdateVersion.published',
                              cond: 'isDraftDeleted',
                              actions: ['switchToLatestPublishedVersion'],
                            },
                            {
                              target: 'queued',
                              actions: 'setDocumentVersionIdFromUpdate',
                            },
                          ],
                        },
                      },
                      queued: {
                        description: 'Step 2 - In the queued status waiting for the optimized status',
                        tags: [StateTags.DISABLE_NAV, StateTags.DISABLE_MODIFY, StateTags.DISABLE_DRAFT_DELETE], // [NOTE] - We only block nav because tab switching is buggy here
                        meta: { infoMessage: INFO_MESSAGES.QUEUED },
                      },
                      optimizing: {
                        description: 'Step 3 - In the optimizing status waiting for the final processed status',
                        tags: [StateTags.DISABLE_NAV, StateTags.DISABLE_MODIFY, StateTags.DISABLE_DRAFT_DELETE], // [NOTE] - We only block nav because tab switching is buggy here
                        meta: { infoMessage: INFO_MESSAGES.OPTIMIZING },
                      },
                      error: {
                        description: 'A critical error state, the user must take action before resuming',
                        tags: [StateTags.PROCESSING_ERROR],
                        meta: { infoMessage: INFO_MESSAGES.PROCESSING_ERROR },
                      },
                    },
                    on: {
                      // [NOTE] - When we are in an "idle processing" state waiting for next conversion updates
                      VERSION_UPDATE: [
                        { target: '#UpdateVersion.published', cond: 'isDraftDeleted' },
                        { target: '.queued', cond: 'isQueued' },
                        { target: '.optimizing', cond: 'isOptimizing' },
                        {
                          target: '#UpdateVersion.draft.documentInfo.idle',
                          cond: 'isConversionProcessedFromUpdate',
                          actions: [
                            'flagOptimizedFinishedThisSession',
                            'setVersionFormFromUpdate',
                          ],
                        },
                        { target: '.error', cond: 'isConversionErrorFromUpdate' },
                      ],
                    },
                  },
                },
              },
            },
            on: {
              // [TODO-2126] - This is pretty similar to sync forms on next/tab navigation (used for semver calculations dependencies)
              //             - We sync form data either way, but note that there are two different ways it's being handled right now
              SYNC_DISTRIBUTABLE: {
                description: 'Trigger to disable Associated Files modifications if file is no longer distributable',
                actions: [
                  'setVersionFormFromComponentSync',
                ],
              },
              SWITCH_TAB_NEXT: {
                target: 'documentSettings',
                cond: 'isNavUnblocked',
                actions: 'switchTabIdx',
              },
              SYNC_VERSION_FORM: {
                actions: 'setVersionFormFromComponentSync',
              },
            },
          },
          documentSettings: {
            description: 'Document Settings tab where Associated Files and Slide settings are configured',
            tags: [StateTags.DOCUMENT_SETTINGS_TAB],
            initial: 'idle',
            states: {
              idle: { },
              processing: {
                description: 'The state during attached file uploading',
                tags: [StateTags.DISABLE_NAV, StateTags.DISABLE_MODIFY],
                on: {
                  ATTACHED_FILE_UPLOADED: {
                    description: 'On attached file(s) uploaded, create temporary records who commit on save draft',
                    actions: ['createAttachedFileRecords', 'checkIfDirty'],
                  },
                },
              },
            },
            on: {
              ATTACHED_FILE_UPLOAD_STATUS_CHANGE: [
                { target: '.processing', cond: 'isAssociatedFileUploadProcessing' },
                { target: '.idle' },
              ],
              ASSOCIATED_DOCUMENT_LINK: {
                description: 'On linked documents, create temporary records who commit on save draft',
                actions: ['associateDocumentLink', 'checkIfDirty'],
              },
              ASSOCIATED_FILE_DELETE: { actions: ['deleteAssociatedFile', 'checkIfDirty'] },
              ASSOCIATED_FILE_UPDATED: { actions: ['updateAssociatedFile', 'checkIfDirty'] },
              SWITCH_TAB_PREV: { target: 'documentInfo', actions: 'switchTabIdx' },
              SWITCH_TAB_NEXT: {
                target: 'documentPublish',
                actions: [
                  'switchTabIdx',
                  'setVersionFormFromComponentSync',
                ],
              },
              SYNC_VERSION_FORM: {
                description: 'Sync slide settings from component to machine',
                actions: [
                  'setVersionFormFromComponentSync',
                  'checkIfDirty',
                ],
              },
            },
          },
          documentPublish: {
            description: 'Document Publish tab where notifications, semVer, and release notes are set',
            tags: [StateTags.DOCUMENT_PUBLISH_TAB],
            initial: 'idle',
            states: {
              idle: {
                description: 'On entry, recalculate the SemVer based on latest form info',
                entry: 'setSemVerChanges',
                on: {
                  PUBLISH_VERSION: {
                    target: 'publishing',
                    cond: 'canPublishDraft',
                    actions: 'publishVersion',
                  },
                },
              },
              publishing: {
                tags: [StateTags.DISABLE_NAV, StateTags.DISABLE_MODIFY, StateTags.DISABLE_EXIT],
                on: {
                  // [TODO-2126] - It's possible we could get stuck here if the subscription never comes back
                  //          or the update comes back early
                  //        - Consider a timeout?
                  VERSION_UPDATE: [
                    {
                      target: '#UpdateVersion.published',
                      cond: 'isDraftDeleted',
                      actions: ['switchToLatestPublishedVersion'],
                    },
                    {
                      description: 'After publishing, switch over to published view',
                      target: '#UpdateVersion.published',
                      actions: [
                        'setDocumentVersionIdFromUpdate',
                        'resetSemVerChanges',
                        'resetForm',
                      ],
                    },
                  ],
                },
              },
            },
            on: {
              SWITCH_TAB_PREV: { target: 'documentSettings', actions: 'switchTabIdx' },
              SYNC_VERSION_FORM: {
                actions: [
                  'setVersionFormFromComponentSync',
                  'checkIfDirty',
                ],
              },
            },
          },
          // [TODO-2126] - This is probably better served as a parallel state
          //            This only works because we keep track of the last known tab via selectedTabIdx
          //            and we disable most other actions so the user cannot do anything
          //        - This might also work without switch if we configure the draft node as a history node
          draftSaveProcessing: {
            description: 'A "history" type node that handles saving draft (or publishing) from any tab',
            tags: [StateTags.DISABLE_NAV, StateTags.DISABLE_MODIFY],
            on: {
              VERSION_UPDATE: [
                // [TODO-2126] - Experimental check to avoid version updates until the appsync version is actually bumped
                //               since we receive 3 subscription updates.
                //             - We should probably apply this to all VERSION_UPDATE actions, but test here for now
                { cond: 'updateVerNotBumped' },
                { target: '#UpdateVersion.published', cond: 'isDraftDeleted' },
                {
                  target: '#UpdateVersion.draft.documentInfo',
                  cond: 'isDocumentInfoTab',
                  actions: 'resetForm',
                },
                {
                  target: '#UpdateVersion.draft.documentSettings',
                  cond: 'isDocumentSettingsTab',
                  actions: 'resetForm',
                },
                {
                  target: '#UpdateVersion.draft.documentPublish',
                  cond: 'isDocumentPublishTab',
                  actions: 'resetForm',
                },
              ],
            },
          },
        },
        on: {
          SET_IS_DIRTY: {
            description: 'Mark the various tabs as dirty from the form components',
            actions: 'setDirtyTabs',
          },
          VERSION_UPDATE: [
            {
              // [TODO-2126] - This is also repeated in other VERSION_UPDATE events -- we should figure out a way where it's more centralized
              //               This is because nested VERSION_UPDATES will take precdence but each of those also need to handle their own usecase
              //             - Try to see if there's a better global catch all level pattern we could use
              //             - Also, this still doesn't 100% absolve crashing the app, we still have to put a workaround in component side
              //               because selector updates there will return a null currentDocumentVersionORM
              //               before the machine can properly transition
              //             - This probably also doesn't work because the observed delete event will never come in as the record by that time
              //               has already been removed in Redux 🤦‍♂️
              description: 'Handles the concurrent use-case of when another publisher deletes a draft',
              target: '#UpdateVersion.published',
              cond: 'isDraftDeleted',
              actions: ['switchToLatestPublishedVersion'],
            },
            {
              description: 'Handles the concurrent use-case of when another publisher publishes the same version',
              target: '#UpdateVersion.published',
              cond: 'isPublished',
              // [TODO-2126] - Use an action to set the current form values to the newly published version
              //        - Or do read states not source from State machine's context?
            },
          ],
          DELETE_DRAFT: {
            target: '#UpdateVersion.published',
            cond: 'isDraftDeleteEnabled',
            actions: [
              'switchToLatestPublishedVersion',
              'deleteDraft',
              'resetDirty',
            ],
          },
          SAVE_DRAFT: {
            target: '#UpdateVersion.draft.draftSaveProcessing',
            actions: ['saveDraft', 'resetDirty'],
          },
          SWITCH_TAB_INFO: {
            target: '.documentInfo',
            cond: 'isNavUnblocked',
            actions: 'switchTabIdx',
          },
          SWITCH_TAB_SETTINGS: {
            target: '.documentSettings',
            cond: 'isNavUnblocked',
            actions: 'switchTabIdx',
          },
          SWITCH_TAB_PUBLISH: {
            target: '.documentPublish',
            cond: 'isNavUnblocked',
            actions: [
              'switchTabIdx',
              'setVersionFormFromComponentSync',
            ],
          },
        },
      },
    },
    on: {
      SWITCH_DOCUMENT_VERSION: {
        target: 'determine',
        cond: 'isNavUnblocked',
        actions: [
          'switchToInfoTab',
          'setDocumentVersionIdFromVersionSelect',
          'resetForm',
        ],
      },
    },
  },
  {
    actions: {
      /** NAVIGATION */
      switchToInfoTab: assign((ctx) => { ctx.selectedTabIndex = 0 }),
      switchTabIdx: assign((ctx, event) => {
        const evt = event as Ver.SwitchTabEvents
        // [NOTE] - We noop tab switches that would result in the same value to (possibly) avoid re-renders
        if (evt.type === 'SWITCH_TAB_INFO' && ctx.selectedTabIndex !== 0)
        { ctx.selectedTabIndex = 0 }
        else if (evt.type === 'SWITCH_TAB_SETTINGS' && ctx.selectedTabIndex !== 1)
        { ctx.selectedTabIndex = 1 }
        else if (evt.type === 'SWITCH_TAB_PUBLISH' && ctx.selectedTabIndex !== 2)
        { ctx.selectedTabIndex = 2 }
        else if (evt.type === 'SWITCH_TAB_NEXT' && ctx.selectedTabIndex !== 2)
        { ctx.selectedTabIndex++ }
        else if (evt.type === 'SWITCH_TAB_PREV' && ctx.selectedTabIndex !== 0)
        { ctx.selectedTabIndex-- }
      }),

      /** DOCUMENT/VERSION META */
      setDocumentVersionIdFromUpdate: assign((ctx, event) => {
        const evt = event as Ver.EVT_VERSION_UPDATE
        ctx.documentVersionId = evt.payload.current.id
      }),
      setDocumentVersionIdFromVersionSelect: assign((ctx, event) => {
        const evt = event as Ver.EVT_SWITCH_DOCUMENT_VERSION
        ctx.documentVersionId = evt.payload.documentVersionId
      }),

      /** DRAFT - DIRTY */
      setDirtyTabs: assign((ctx, event) => {
        const evt = event as Ver.EVT_SET_IS_DIRTY
        switch (evt.payload.type) {
          case 'info': {
            ctx.documentInfoIsDirty = evt.payload.isDirty
            return;
          }
          case 'settings': {
            ctx.documentSettingsIsDirty = evt.payload.isDirty
            return;
          }
          case 'publish': {
            ctx.documentPublishIsDirty = evt.payload.isDirty
          }
        }
      }),
      checkIfDirty: assign((ctx) => {
        // [NOTE] - Deep equality checking (lodash, fast-deep-equal) isn't working
        //          after initial form touching, not sure what values are the offenders,
        //          string comparison works though and should be good enough (hopefully)
        const currentDocVer = ctx.getDocumentORM().relations.version.latestDocumentVersion
        const one = JSON.stringify(versioningUtil.omitInternalFields(currentDocVer.model))
        const two = JSON.stringify(ctx.versionForm)

        ctx.documentSettingsIsDirty = one !== two
      }),
      resetDirty: assign((ctx) => {
        ctx.documentInfoIsDirty = false
        ctx.documentPublishIsDirty = false
        ctx.documentSettingsIsDirty = false
      }),

      /** DRAFT - SETTERS */
      setVersionFormFromUpdate: assign((ctx, event) => {
        const evt = event as Ver.EVT_VERSION_UPDATE
        // [NOTE] - We override the entire draft form, not merge
        ctx.versionForm = versioningUtil.omitInternalFields(evt.payload.current)
      }),
      setVersionFormFromComponentSync: assign((ctx, event) => {
        const evt = event as Ver.SyncFormEvents
        ctx.versionForm = { ...ctx.versionForm, ...(evt.payload?.versionForm ?? { }) }
      }),
      associateDocumentLink: assign((ctx, evt) => {
        const event = evt as Ver.EVT_ASSOCIATED_DOCUMENT_LINK

        if (!ctx.versionForm.associatedFiles)
        { ctx.versionForm.associatedFiles = [] }

        const newAssociatedFile = createAssociatedFile({
          attachmentId: event.payload.documentId,
          type: AssociatedFileType.DOCUMENT,
          status: 'ACTIVE',
        })

        ctx.versionForm.associatedFiles.push(newAssociatedFile)
      }),
      deleteAssociatedFile: assign((ctx, evt) => {
        const event = evt as Ver.EVT_ASSOCIATED_FILE_DELETE

        ctx.versionForm.associatedFiles = ctx
          .versionForm
          .associatedFiles
          ?.filter(file => file.attachmentId !== event.payload.attachmentId)
      }),
      updateAssociatedFile: assign((ctx, evt) => {
        const event = evt as Ver.EVT_ASSOCIATED_FILE_UPDATED

        if (!ctx.versionForm.associatedFiles)
        { ctx.versionForm.associatedFiles = [] }

        const targetFileIdx = ctx
          .versionForm
          .associatedFiles
          .findIndex(file => file.attachmentId === event.payload.associatedFile.attachmentId)

        ctx.versionForm.associatedFiles[targetFileIdx] = event.payload.associatedFile
      }),
      setSemVerChanges: (ctx) => {
        const documentORM = ctx.getDocumentORM()
        const versionChanges = versioningUtil.determineSemVerChange(
          ctx.versionForm,
          documentORM.relations.version.latestDocumentVersionPublished,
        )

        ctx.changesCheckResult = {
          disableMinor: versionChanges.isMajorVersionRequired,
          detectedChangeType: versionChanges.isMajorVersionProposed
            ? DocumentVersionChangeType.MAJOR
            : DocumentVersionChangeType.MINOR,
        }
      },
      createAttachedFileRecords: assign((ctx, evt) => {
        const event = evt as Ver.EVT_ATTACHED_FILE_UPLOADED
        const { attachedFile } = event.payload

        if (!ctx.versionForm.associatedFiles)
        { ctx.versionForm.associatedFiles = [] }

        // Create temporary attached file record
        const newAssociatedFile = createAssociatedFile({
          attachmentId: attachedFile.id,
          type: AssociatedFileType.ATTACHED_FILE,
          status: 'ACTIVE',
        });

        ctx.versionForm.associatedFiles.push(newAssociatedFile)
      }),
      resetForm: assign((ctx) => {
        // [TODO-2126] - Should probably just send an action before this (resetDirty) instead of doing it here
        ctx.documentInfoIsDirty = false
        ctx.documentSettingsIsDirty = false
        ctx.documentPublishIsDirty = false

        const currentReduxVersion = ctx
          .getDocumentORM()
          .relations
          .documentVersions
          .find(ver => ver.model.id === ctx.documentVersionId)?.model ??
          { }

        ctx.versionForm = currentReduxVersion
      }),
      resetSemVerChanges: assign((ctx) => { ctx.changesCheckResult = undefined }),
      applyDistributableChanges: assign((ctx) => {
        if (!ctx.versionForm.distributable) {
          ctx.versionForm.associatedFiles?.forEach(asscFile => {
            if (asscFile.isDefault) {
              asscFile.isDefault = false
            }
            if (asscFile.isDistributable && asscFile.type === 'ATTACHED_FILE') {
              asscFile.isDistributable = false
            }
          })
        }
      }),
      /** NEW VERSION PROCESSING */
      flagOptimizedFinishedThisSession: assign((ctx) => { ctx.hasOptimizedFinishedThisSession = true }),
      resetFileUploadCancel: assign((ctx) => { ctx.cancelUpload = false }),
      flagFileUploadCancel: assign((ctx) => { ctx.cancelUpload = true }),

      /** DRAFT - SAVE & PUBLISH */
      // [TODO-2126] - Figure out an elegant way to glue together multiple actions from different slices
      //          i.e. Document (first time) and Version publish
      publishVersion: (ctx, event) => {
        const evt = event as Ver.EVT_PUBLISH_VERSION
        const documentORM = ctx.getDocumentORM()

        const isFirstVersion = !documentORM.relations.version.latestDocumentVersionPublished
        const latestDocumentVersion = documentORM.relations.version.latestDocumentVersion

        // Publish the document if this is the very first version
        if (isFirstVersion) {
          store.dispatch(documentActions.publish(documentORM));
        }
        // Otherwise bump the updatedAt timestamp and track analytics
        else {
          store.dispatch(documentActions.save({
            model: Document,
            entity: documentORM.model,
            updates: { },
          }));

          // We only track on new version for existing published doc
          analytics?.track('DOCUMENT_PUBLISH_VERSION', {
            action: 'PUBLISH_VERSION',
            category: 'DOCUMENT',
            documentId: latestDocumentVersion.relations.document.model.id,
            documentVersionId: latestDocumentVersion.model.id,
          });
        }

        const updates = { ...ctx.versionForm, ...evt.payload }
        const omittedValues = versioningUtil.omitInternalFields(updates)

        store.dispatch(documentVersionActions.newPublish(latestDocumentVersion, omittedValues));
      },
      saveDraft: (ctx, event) => {
        const evt = event as Ver.EVT_SAVE_DRAFT;
        const newVersionForm = evt.payload?.versionForm ?? {};

        // [TODO-2126] - Needs to be document version now
        analytics?.track('DOCUMENT_SAVE', {
          action: 'SAVE',
          category: 'DOCUMENT',
          documentId: ctx.documentVersionId,
        });

        // We need to grab the latest version
        // The model id is not being bumped?
        const documentVersion = ctx
          .getDocumentORM()
          .relations
          .documentVersions
          .find(docVer => docVer.model.id === ctx.documentVersionId)

        if (!documentVersion) {
          console.error('Something went wrong during saving')
          return;
        }

        // We need to update the version form after hitting Edit Properties
        const updates = versioningUtil.omitInternalFields({ ...ctx.versionForm, ...newVersionForm })

        store.dispatch(documentVersionActions.save({
          model: DocumentVersion,
          entity: documentVersion.model,
          updates,
        }));
      },
      switchToLatestPublishedVersion: assign((ctx) => {
        const latestPublishedVersion = ctx
          .getDocumentORM()
          .relations
          .version
          .latestDocumentVersionPublished

        if (latestPublishedVersion)
        { ctx.documentVersionId = latestPublishedVersion.model.id }
      }),
      deleteDraft: assign((ctx) => {
        const currentDocumentVersion = ctx
          .getDocumentORM()
          .relations
          .version
          .latestDocumentVersion
        // [TODO-2126] - Temp workaround to allow concurrent deletion, normally we don't need to check here
        //               but in this case, we want to make sure we don't execute the action
        if (currentDocumentVersion.model.status === 'NOT_PUBLISHED')
        { store.dispatch(multiSliceActions.deleteDocumentDraft(currentDocumentVersion)) }
      }),
      /** ERROR HANDLERS */
      // [TODO-2126] - Curently we are still in draft mode, need to switch back to published
      handleError: assign((ctx, event) => {
        console.error({ errEvt: event })
        // @ts-expect-error
        ctx.errors[event.type] = event?.data?.message ?? ''
      }),
    },
    guards: {
      /** DOCUMENT/VERSION META */
      isPublished: (ctx) => {
        const documentORM = ctx.getDocumentORM()
        const selectedDocVerORM = documentORM
          .relations
          .documentVersions
          .find(docVerORM => docVerORM.model.id === ctx.documentVersionId)

        return selectedDocVerORM?.model.status === 'PUBLISHED'
      },
      updateVerNotBumped: (_, event) => {
        const evt = event as Ver.EVT_VERSION_UPDATE
        // @ts-expect-error - these are untyped, meta fields from appsync
        const skipUpdate = evt.payload.prev._version === evt.payload.current._version
        return skipUpdate
      },
      isDraftDeleteEnabled: (_, __, meta) => {
        return !meta.state.hasTag(StateTags.DISABLE_DRAFT_DELETE)
      },
      isDraftDeleted: (_, event) => {
        const evt = event as Ver.EVT_VERSION_UPDATE
        const isDraftDeleted = evt.payload.current.status === 'DELETED'
        return isDraftDeleted
      },
      isSealed: (ctx) => {
        const documentORM = ctx.getDocumentORM()
        return ['ARCHIVED', 'REVOKED', 'DELETED'].includes(documentORM?.model.status)
      },
      /** NAVIGATION */
      isDocumentInfoTab: (ctx) => ctx.selectedTabIndex === 0,
      isDocumentSettingsTab: (ctx) => ctx.selectedTabIndex === 1,
      isDocumentPublishTab: (ctx) => ctx.selectedTabIndex === 2,
      isNavUnblocked: (_, __, meta) => !meta.state.hasTag(StateTags.DISABLE_NAV),

      /** DOCUMENT SETTINGS */
      isAssociatedFileUploadProcessing: (_, event) => {
        const evt = event as Ver.EVT_ATTACHED_FILE_UPLOAD_STATUS_CHANGE
        const isProcessing = [
          UPLOAD_STATUS.IN_PROGRESS,
          UPLOAD_STATUS.CANCELLING,
        ].some(status => evt.payload.status === status)

        return isProcessing
      },

      /** NEW VERSION CREATION */
      canCreateNewVersion: (ctx) => {
        const documentORM = ctx.getDocumentORM()
        const draftInProgress = documentORM.meta.hasUnpublishedVersion
        const isPublished = documentORM.model.status === 'PUBLISHED'
        return !draftInProgress && isPublished
      },
      canPublishDraft: (ctx) => {
        const hasNoErrors = !Object.values(ctx.errors).length
        return hasNoErrors
      },
      /** NEW VERSION PROCESSING */
      hasProcessingError: (ctx) => {
        return ctx.getDocumentORM()
          .relations
          .version
          .latestDocumentVersion
          .model
          .conversionStatus === 'ERROR';
      },
      isOptimizing: (ctx) => {
        return ctx.getDocumentORM()
          .relations
          .version
          .latestDocumentVersion
          .model
          .conversionStatus === 'PROCESSING';
      },
      isQueued: (ctx) => {
        return ctx.getDocumentORM().relations.version.latestDocumentVersion.model.conversionStatus === 'PENDING';
      },
      isConversionProcessedFromUpdate: (_, event) => {
        const evt = event as Ver.EVT_VERSION_UPDATE
        return evt.payload.current.conversionStatus === 'PROCESSED'
      },
      isConversionErrorFromUpdate: (_, event) => {
        const evt = event as Ver.EVT_VERSION_UPDATE
        return evt.payload.current.conversionStatus === 'ERROR'
      },
      isFileUploadCancelled: (ctx) => ctx.cancelUpload,
    },
    services: {
      uploadFile: async (ctx, event) => {
        const documentORM = ctx.getDocumentORM()
        const evt = event as Ver.EVT_CREATE_FROM_UPLOAD
        const file = evt.payload.file
        try {
          const fileExtension = file.name.split('.').pop();
          const key = `publish_tmp/${Date.now()}.${fileExtension}`;

          // UPLOAD THE FILE TO THE TEMPORARY PATH IN S3
          await Storage.put(key, file, {
            contentType: file!.type,
            contentDisposition: `attachment; filename="${file.name}"`,
            level: 'private',
          });

          const apiInputObj: CreateDocumentVersionFromS3UploadInput = {
            srcFilename: file.name,
            fileS3Key: key,
            documentId: documentORM.model.id,
            existingVersionId: documentORM.relations.version.latestDocumentVersionPublished?.model.id!,
            version: documentORM.relations.documentVersions.length + 1,
          };

          if (ctx.cancelUpload) {
            // Since it was cancelled but can't stop the put, proceed to delete the item
            Storage.remove(key);
            return 'CANCEL';
          }

          const evtData: Ver.EVT_CREATE_FILE['data'] = {
            file,
            S3Key: key,
            apiInputObj,
          }

          return evtData
        } catch (e) {
          console.error(e);
          throw e
        }
      },
      createRecordForUpload: async (_, event) => {
        const evt = event as Ver.EVT_CREATE_FILE
        // [NOTE] - This data comes from the previous step/action above (uploadFile)
        const { apiInputObj } = evt.data

        const result = await API.graphql(
          graphqlOperation(
            createDocumentVersionFromS3Upload,
            { inputVersion: apiInputObj },
          ),
        ) as GraphQLResult<CreateDocumentVersionFromS3UploadMutation>;

        analytics?.track('DOCUMENT_UPLOAD_VERSION', {
          action: 'UPLOAD_VERSION',
          category: 'DOCUMENT',
          documentId: result.data?.createDocumentVersionFromS3Upload?.documentId,
          documentVersionId: result.data?.createDocumentVersionFromS3Upload?.id,
        });

        return result
      },
      createFromExistingOptimizing: async (ctx: Ver.UpdateVersionContext) => {
        const documentORM = ctx.getDocumentORM()
        const latestDocumentVersion = documentORM.relations.version.latestDocumentVersion

        try {
          const newDocVer = await API.graphql(
            graphqlOperation(createDocumentVersionFromExisting, {
              documentId: latestDocumentVersion.model.documentId,
              newVersionNumber: latestDocumentVersion.model.versionNumber + 1,
              existingVersionId: latestDocumentVersion.model.id,
            }),
          );

          return newDocVer
        } catch (e) {
          console.warn('Error when creating existing version from existing', e)
          throw e
        }
      },
    },
  },
)

export default updateVersion
