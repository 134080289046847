/* eslint-disable max-len */
import React from 'react';
import Svg, { SvgProps, Defs, ClipPath, Path, G } from 'react-native-svg';

function SvgMolecule(props: SvgProps) {
  return (
    <Svg
      viewBox="3.268 5.005 37.149 35.822"
      fill="none"
      // @ts-ignore
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs>
        <ClipPath id="Molecule_svg__a">
          <Path
            fill={props.color ?? '#fff'}
            transform="rotate(-114.225 18.952 13.041)"
            d="M0 0h12.028v19.999H0z"
          />
        </ClipPath>
      </Defs>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.981 15.5C7 15.5 6 16.5 6 17.606v17.038C6 35.5 7 36.5 7.981 36.5h28.038C37 36.5 38 35.5 38 34.644V17.606c0-1.106-1-2.106-1.981-2.106H7.981zm-3.856 2.106a3.856 3.856 0 013.856-3.856h28.038a3.856 3.856 0 013.856 3.856v17.038a3.856 3.856 0 01-3.856 3.856H7.981a3.856 3.856 0 01-3.856-3.856V17.606zM11 8.5c0-.552.616-1 1.375-1h19.25c.76 0 1.375.448 1.375 1s-.616 1-1.375 1h-19.25c-.76 0-1.375-.448-1.375-1zM8.25 11.625c0-.552.616-1 1.375-1h24.75c.76 0 1.375.448 1.375 1s-.616 1-1.375 1H9.625c-.76 0-1.375-.448-1.375-1z"
        fill={props.color ?? '#fff'}
      />
      <G clipPath="url(#Molecule_svg__a)">
        <Path
          d="M15.532 33.163l-.411-.914 1.52-.684c1.094-.493 1.843-1.204 2.486-2.152.453-.677.825-1.476 1.178-2.294l1.281.429a13.16 13.16 0 01-1.089 2.007c-.762 1.129-1.85 2.206-3.445 2.924l-1.52.684zm-2.879-6.399l-.411-.914 1.52-.684c1.595-.718 3.123-.817 4.473-.639 1.338.173 2.575.606 3.778 1.026 1.211.417 2.39.821 3.527.974 1.136.149 2.165.06 3.26-.433l1.519-.684.411.914-1.52.684c-1.595.718-3.123.817-4.473.639-1.338-.173-2.575-.605-3.778-1.026-1.211-.417-2.39-.821-3.527-.974-1.136-.148-2.165-.06-3.259.433l-1.52.684zm8.733-2.138c.306-.681.655-1.366 1.09-2.007.761-1.128 1.848-2.205 3.444-2.923l1.52-.684.411.914-1.52.684c-1.094.492-1.843 1.203-2.485 2.151-.454.677-.826 1.476-1.18 2.295l-1.28-.43zm-5.71 6.824l-2.057-4.57.76-.343.392-.143 2.032 4.515-.368.199-.76.342zm2.139-1.337l-1.777-3.949a6.43 6.43 0 01.99.05l.44.082 1.201 2.669-.233.374c-.204.3-.412.548-.621.774zm7.689-4.235l-1.201-2.669.234-.374c.203-.3.411-.548.62-.774l1.777 3.95a6.404 6.404 0 01-.99-.05l-.44-.082zm2.697-.097l-2.032-4.516.368-.198.76-.342 2.056 4.57-.76.342-.392.144z"
          fill={props.color ?? '#fff'}
        />
      </G>
    </Svg>
  );
}

export default SvgMolecule;
