import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api';
// @ts-ignore
import config from '../../config/app.json';
import { ShareType } from '@alucio/aws-beacon-amplify/src/models'
import { generateContentShare } from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import { GenerateContentShareMutation } from '@alucio/aws-beacon-amplify/src/API';
import format from 'date-fns/format';

export interface ShareableLinkResult {
  contentId: string,
  expiresAt: string,
  id: string,
  link: string,
  title: string,
  type: string,
}

async function getShareableLink(contentId: string, type: ShareType, title: string = ''): Promise<ShareableLinkResult> {
  const expirationOverride = localStorage.getItem('shareExpirationMins')
  // 30 days is the current default (and maximum)
  const expiration = expirationOverride
    ? parseInt(expirationOverride, 10)
    : 30 * 24 * 60;
  const params = {
    type,
    contentId,
    expiresInMins: expiration,
  }
  const shareResult: GraphQLResult<GenerateContentShareMutation> =
    await API.graphql(graphqlOperation(generateContentShare, params))! as GraphQLResult<GenerateContentShareMutation>;
  const { expiresAt, id, token } = shareResult.data!.generateContentShare!;
  const expiresAtFormatted = format(new Date(expiresAt), 'yyyy-MM-dd')

  return {
    contentId,
    expiresAt: expiresAtFormatted,
    link: `${config.contentURL}/share/${token}`,
    id,
    title,
    type,
  };
}

export {
  getShareableLink,
}
