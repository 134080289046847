import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { DNAButton, DNAChip, DNAText, GridList, luxColors, LuxStatus } from '@alucio/lux-ui';
import { User, UserRole, UserStatus } from '@alucio/aws-beacon-amplify/src/models';
import DNAUserContextMenu from 'src/components/DNA/Users/DNAUserContextMenu';
import { DRAWER_ENTITIES, drawerActions } from 'src/state/redux/slice/drawer';
import { useDispatch } from 'react-redux';

const styles = StyleSheet.create({
  column: {
    paddingVertical: 12,
  },
  hoveredRow: {
    backgroundColor: luxColors.backgroundColor.secondary,
  },
  itemsWrapper: {
    flex: 1,
    flexDirection: 'column',
  },
  itemTextColor: {
    color: luxColors.contentText.tertiary,
  },
});

export const MAPPED_ROLES = {
  [UserRole.TENANT_PUBLISHER]: 'Publisher',
  [UserRole.TENANT_VIEWER]: 'Viewer',
  [UserRole.TENANT_ADMIN]: 'Admin',
};

export const USER_STATUS_VARIANT: {[key in UserStatus]: LuxStatus} = {
  [UserStatus.ACTIVE]: 'success',
  [UserStatus.DEACTIVATED]: 'flat',
};

interface Props {
  user: User;
}

const DNAUserDefaultRow = (props: Props) => {
  const { user } = props;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const dispatch = useDispatch();

  let defaultFilterValues: string[] = [];

  if (user?.defaultFilterValues) {
    user.defaultFilterValues.forEach(({ values = [] }) => {
      defaultFilterValues.push(...values);
    });

    defaultFilterValues = [...new Set(defaultFilterValues)].sort();
  }

  function onMouseEnter(): void {
    setIsHovered(true);
  }

  function onMouseLeave(): void {
    setIsHovered(false);
  }

  function openEditModal(): void {
    dispatch(drawerActions.toggle({
      entityId: user.id,
      entity: DRAWER_ENTITIES.USER,
    }));
  }

  return (
    <GridList.Row
      style={isHovered && styles.hoveredRow}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onPress={openEditModal}
    >
      <GridList.Col style={[styles.column, { justifyContent: 'center' }]} span={1}>
        <DNAUserContextMenu user={user}>
          <DNAButton
            status="subtle"
            appearance="ghostAlt"
            context="minimum"
            iconLeft="dots-vertical"
          />
        </DNAUserContextMenu>
      </GridList.Col>
      <GridList.Col style={[styles.column, { paddingLeft: 16 }]} span={2}>
        <DNAText numberOfLines={1} style={styles.itemTextColor}>{ `${user.givenName} ${user.familyName}` } </DNAText>
      </GridList.Col>
      <GridList.Col style={styles.column} span={1}>
        <DNAChip status={USER_STATUS_VARIANT[user.status!]} style={{ borderRadius: 2 }}>
          {user.status}
        </DNAChip>
      </GridList.Col>
      <GridList.Col style={styles.column} span={3}>
        <View style={styles.itemsWrapper}>
          <DNAText style={styles.itemTextColor} numberOfLines={1}>{user.email}</DNAText>
          <DNAText style={styles.itemTextColor}>{user.phoneNumber}</DNAText>
        </View>
      </GridList.Col>
      <GridList.Col style={styles.column} span={1}>
        <DNAText style={styles.itemTextColor}>{MAPPED_ROLES[user.role]}</DNAText>
      </GridList.Col>
      <GridList.Col style={styles.column} span={3}>
        <DNAText numberOfLines={1} style={styles.itemTextColor}>{ defaultFilterValues.join(', ') }</DNAText>
      </GridList.Col>
      <GridList.Col style={styles.column} span={1}>
        <View style={styles.itemsWrapper}>
          {
            user.shareCC?.map((email, idx) => (
              <DNAText key={`${email}${idx}`} style={styles.itemTextColor} numberOfLines={1}>{email}</DNAText>
            ))
          }
        </View>
      </GridList.Col>
      <GridList.Col style={styles.column} span={2}>
        <View style={styles.itemsWrapper}>
          {
            user.shareBCC?.map((email, idx) => (
              <DNAText key={`${email}${idx}`} style={styles.itemTextColor} numberOfLines={1}>{email}</DNAText>
            ))
          }
        </View>
      </GridList.Col>
    </GridList.Row>
  );
};

DNAUserDefaultRow.displayName = 'DNAUserDefaultRow';

export default DNAUserDefaultRow;
