import React, { useState } from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import {
  Iffy,
  TextField,
  Popover,
  Icon,
  luxColors,
  util,
} from '@alucio/lux-ui'
import { useOnlineStatus } from 'src/state/context/AppSettings'
import { useCurrentUser } from '../../state/redux/selector/user';
import appConfig from '../../config/app.json'

const S = StyleSheet.create({
  SplitButtonContainer: {
    flexDirection: 'row',
  },
  Button: {
    backgroundColor: luxColors.thumbnailBorder.primary,
    borderRadius: 50,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  ButtonLeft: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    marginRight: 1,
  },
  ButtonRight: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  ButtonText: {
    color: luxColors.info.primary,
    fontWeight: 'bold',
  },
  ButtonIcon: {
    color: luxColors.info.primary,
    height: 25,
    width: 25,
  },
  CopyLinkContainer: {
    borderRadius: 8,
    padding: 16,
    backgroundColor: luxColors.info.primary,
    borderWidth: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.16,
    shadowRadius: 11.22,
  },
  CopyLinkTextField: {
    marginTop: 12,
    marginBottom: 16,
    backgroundColor: luxColors.info.primary,
    borderRadius: 8,
    borderColor: luxColors.disabled.primary,
    width: 250,
  },
  CopyLinkTitle: {
    fontWeight: 'bold',
  },
  DisabledButton: {
    opacity: 0.4,
  },
  Success: {
    color: luxColors.success.primary,
  },
  SuccessIcon: {
    height: 30,
    width: 30,
    marginLeft: 14,
    marginRight: 6,
  },
})

export const SplitButton: React.FC<{ onPressLeft: () => void }> = (props) => {
  const { onPressLeft } = props
  const [contentVisible, setContentVisible] = useState<boolean>(false);
  const toggleContent = () => setContentVisible(p => !p)
  const onlineStatus = useOnlineStatus()
  const isNetworkConnected = onlineStatus

  const LeftButton: React.FC = () => (
    <TouchableOpacity
      activeOpacity={1}
      onPress={onPressLeft}
      style={util.mergeStyles(
        undefined,
        S.Button,
        S.ButtonLeft,
        [S.DisabledButton, !isNetworkConnected],
      )}
      testID="virtual-room"
      disabled={!isNetworkConnected}
    >
      <Text style={S.ButtonText}>Virtual Exchange</Text>
    </TouchableOpacity>
  )

  const RightButton: React.FC = () => (
    <Popover
      lazyMount
      placement="bottom-end"
      interactive={true}
      visible={contentVisible}
      onBackdropPress={toggleContent}
      type="menu"
    >
      <Popover.Anchor style={{ height: '100%' }}>
        <TouchableOpacity
          activeOpacity={1}
          onPress={toggleContent}
          style={[S.Button, S.ButtonRight, { height: '100%' }]}
        >
          <Icon style={S.ButtonIcon} name="chevron-down" />
        </TouchableOpacity>
      </Popover.Anchor>

      <Popover.Content offset={10}>
        <CopyLink />
      </Popover.Content>
    </Popover>
  )

  return (
    <View style={S.SplitButtonContainer}>
      <LeftButton />
      <RightButton />
    </View>
  )
}

export const CopyLink: React.FC = () => {
  const [hasCopied, setHasCopied] = useState<boolean>(false)
  const user = useCurrentUser();

  const roomId = user?.userProfile?.meetingId
  const roomURL = roomId
    ? `https://${appConfig.virtualURL}/${roomId}` // [TODO]: Support multiple envs (i.e. QA)
    : undefined;

  const writeToClipboard = () => {
    if (!roomURL) return;

    navigator.clipboard.writeText(roomURL)
      .then(() => setHasCopied(true))
      .catch(err => {
        console.error('Could not copy text: ', err); // [TODO] - Error handle when permissions are denied
      });
  }

  return (
    <View style={S.CopyLinkContainer}>
      <Text style={S.CopyLinkTitle}>Virtual Exchange Room</Text>

      <TextField.Kitten
        style={S.CopyLinkTextField}
        value={roomURL}
        editable={false}
      />

      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <TouchableOpacity activeOpacity={1} style={S.Button} onPress={writeToClipboard}>
          <Text style={S.ButtonText}>Copy link</Text>
        </TouchableOpacity>
        <Iffy is={hasCopied}>
          <Icon name="check-circle" style={[S.Success, S.SuccessIcon]} />
          <Text style={S.Success}>Link Copied!</Text>
        </Iffy>
      </View>
    </View>
  )
}

export default SplitButton
