import { useSelector } from 'react-redux'
import im, { Draft } from 'immer'
import { createSelector } from '@reduxjs/toolkit'
import {
  User,
  Tenant,
  LabelValues,
  UserStatus,
} from '@alucio/aws-beacon-amplify/src/models'
import { RootState } from '../store'
import { CognitoUser } from '../../../models/User';
import { mapFieldFilterEntries } from 'src/components/DNA/Document/DNADocumentFilters/DNADocumentFilters'
import { FilterEntry } from 'src/state/redux/document/query';

export interface UserORM {
  user: User,
  meta: {
    formattedName?: string;
  }
}

export interface LockedFilterEntry {
  [key: string]: string[]
}

export interface IndexedUserORM {
  [key: string]: UserORM
}

export interface CurrentUser {
  authProfile?: CognitoUser,
  userProfile?: User
  meta: {
    formattedFilters?: FilterEntry[],
    formattedLockedFilters?: LockedFilterEntry,
  }
}

export const selUsers = (state: RootState): User[] => state.user.records
export const selCognitoUser = (state: RootState): CognitoUser | undefined => state.user.cognitoUser;
export const selTenants = (state: RootState): Tenant[] => state.tenant.records;
export const selIsLoading = (state: RootState): boolean => state.user.isLoading

export const usersList = createSelector(
  selUsers,
  (users): UserORM[] => {
    const mapped = im([] as UserORM[], (draft: Draft<UserORM[]>) => {
      users.forEach(user => {
        const ORM = {
          user,
          meta:{
            formattedName:
            `${user.givenName} ${user.familyName} ${user.status === UserStatus.DEACTIVATED ? '(deactivated)' : ''} `,
          },
        }
        draft.push(ORM)
      })
    })

    return mapped
  },
)

export const indexedUsersList = createSelector(
  usersList,
  (users): IndexedUserORM => {
    return users.reduce((acc, userORM) => {
      acc[userORM.user.id] = userORM;
      return acc;
    }, { } as IndexedUserORM);
  },
);

export const publisherList = createSelector(
  usersList,
  (usersList): UserORM[] => usersList
    .filter(ORM => ORM.user.role === 'TenantPublisher'), // [TODO]: ENUM?
)

// TODO: What happens if the user is in cognito but no in Dynamo ?
// A Pre-SignIn lambda function should be created to verify before allowing the signIn (?)
export const loggedUser = createSelector(
  selUsers,
  selCognitoUser,
  selTenants,
  (users: User[], cognitoUser: CognitoUser | undefined, tenants: Tenant[]): CurrentUser => {
    const user = users.find(({ id }) => id === cognitoUser?.attributes['custom:user_id'])
    const userTenant = tenants.find(({ id }) => id === cognitoUser?.attributes['custom:org_id']);

    const mapLockedFilters = (lockedFilters: LabelValues[]) => {
      return lockedFilters?.reduce((acc, lockedFilter) => {
        acc[lockedFilter.key] = lockedFilter.values;
        return acc;
      }, {});
    }

    return {
      authProfile: cognitoUser,
      userProfile: user,
      meta: {
        formattedFilters: mapFieldFilterEntries(
          user?.defaultFilterValues,
          userTenant,
        ).filter(entry => entry.default),
        formattedLockedFilters: mapLockedFilters(user?.lockedFilters!),
      },
    };
  },
);

export const userByEmail = createSelector(
  usersList,
  (_, email: string | undefined) => email,
  (usersList, email ): UserORM | undefined => email ? usersList
    .find(ORM => ORM.user.email === email) : undefined, // [TODO]: ENUM?
)

export const userById = createSelector(
  indexedUsersList,
  (_, id: string | undefined) => id,
  (indexedUsersList, id ): UserORM | undefined => (id && indexedUsersList[id]) || undefined,
)

export const userTenant = createSelector(
  loggedUser,
  selTenants,
  (user, tenants): Tenant | undefined => {
    return tenants.find(({ id }) => id === user?.authProfile?.attributes['custom:org_id']);
  },
);

export const userTenantDefaultFilterKeys = createSelector(
  userTenant,
  (tenant?: Tenant): string[] => {
    return tenant?.fields?.reduce((acc, customField) => {
      if (customField.defaultSearchFilter) {
        acc.push(customField.fieldName);
      }
      return acc;
    }, [] as string[]) || [];
  },
);

export const userDefaultFilterValues = createSelector(
  loggedUser,
  ({ userProfile }: CurrentUser): string[] | {} => {
    return userProfile?.defaultFilterValues?.reduce((acc, { key, values }) => {
      acc[key] = values;
      return acc;
    }, {}) ?? {};
  },
)

export const usePublisherList = (): ReturnType<typeof publisherList> =>
  useSelector((state: RootState) => publisherList(state))

export const useCurrentUser = (): ReturnType<typeof loggedUser> =>
  useSelector((state: RootState) => loggedUser(state));

export const useUserTenant = (): ReturnType<typeof userTenant> =>
  useSelector((state: RootState) => userTenant(state));

export const useUserByEmail = (email:string | undefined): ReturnType<typeof userByEmail> =>
  useSelector((state: RootState) => userByEmail(state, email));

export const useUserById = (id: string): ReturnType<typeof userById> =>
  useSelector((state: RootState) => userById(state, id));

export const useIndexedUsers = (): ReturnType<typeof indexedUsersList> =>
  useSelector((state: RootState) => indexedUsersList(state));

export const useUserDefaultFilterValues = (): ReturnType<typeof userDefaultFilterValues> =>
  useSelector((state: RootState) => userDefaultFilterValues(state));
