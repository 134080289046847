import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { matchesState, StateValue } from 'xstate'

import { RootState } from 'src/state/redux/store'
import { CacheSliceState } from 'src/state/redux/slice/Cache/cache'
import { SyncManagerStateValue } from 'src/worker/machines/sync/sync.types'

export const selectCacheManifests =
  (
    state: RootState,
  ): CacheSliceState['manifestEntries'] =>
    state.cache.manifestEntries
export const selectCacheSync = (state: RootState): CacheSliceState['sync'] => state.cache.sync
export const selectCacheSyncValue = (state: RootState): CacheSliceState['sync']['value'] => state.cache.sync.value

type SyncStateORM = Pick<
  SyncManagerStateValue,
  'context' | 'value'
> & {
  matches: (value: StateValue) => ReturnType<typeof matchesState>
}

export type SyncStatusORM = Pick<SyncManagerStateValue, 'value'> & {
  matches: (value: StateValue) => ReturnType<typeof matchesState>
}

const syncManifests = createSelector(
  selectCacheManifests,
  entries => entries,
)

// This is the full sync machine selector (includes context)
const syncState = createSelector(
  selectCacheSync,
  (syncSlice): SyncStateORM => {
    return {
      context: syncSlice.context,
      value: syncSlice.value,
      matches: (match: StateValue) => matchesState(match, syncSlice.value),
    }
  },
)

// This is a lighter selector that is only concered with the current state value
//  [TODO-PWA]
//    - Consider some type of error status
//      maybe a simple boolean to check if there are error entries
const syncStatus = createSelector(
  selectCacheSyncValue,
  (syncValue): SyncStatusORM => ({
    value: syncValue,
    matches: (match: StateValue) => matchesState(match, syncValue),
  }),
)

export const useSyncState = (): ReturnType<typeof syncState> =>
  useSelector((state: RootState) => syncState(state))

export const useSyncStatus = (): ReturnType<typeof syncStatus> =>
  useSelector((state: RootState) => syncStatus(state))

export const useSyncManifests = (): ReturnType<typeof syncManifests> =>
  useSelector((state: RootState) => syncManifests(state))
