/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateVirtualAttendee = /* GraphQL */ `
  subscription OnCreateVirtualAttendee($hostId: ID!) {
    onCreateVirtualAttendee(hostId: $hostId) {
      id
      attendeeKey
      tenantId
      hostId
      meetingId
      sessionId
      status
      statusDetail
      name
      phonePin
      clientIP
      joinToken
      createdAt
      terminatedAt
      acceptedAt
      lastSeenAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateVirtualAttendee = /* GraphQL */ `
  subscription OnUpdateVirtualAttendee($hostId: ID!) {
    onUpdateVirtualAttendee(hostId: $hostId) {
      id
      attendeeKey
      tenantId
      hostId
      meetingId
      sessionId
      status
      statusDetail
      name
      phonePin
      clientIP
      joinToken
      createdAt
      terminatedAt
      acceptedAt
      lastSeenAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($tenantId: String, $id: String) {
    onCreateUser(tenantId: $tenantId, id: $id) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($tenantId: String, $id: String) {
    onUpdateUser(tenantId: $tenantId, id: $id) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($tenantId: String, $id: String) {
    onDeleteUser(tenantId: $tenantId, id: $id) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTenant = /* GraphQL */ `
  subscription OnCreateTenant($id: String) {
    onCreateTenant(id: $id) {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
      }
      folderUpdateGracePeriodDays
      medInfoURL
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTenant = /* GraphQL */ `
  subscription OnUpdateTenant($id: String) {
    onUpdateTenant(id: $id) {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
      }
      folderUpdateGracePeriodDays
      medInfoURL
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTenant = /* GraphQL */ `
  subscription OnDeleteTenant($id: String) {
    onDeleteTenant(id: $id) {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
      }
      folderUpdateGracePeriodDays
      medInfoURL
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDocumentVersion = /* GraphQL */ `
  subscription OnCreateDocumentVersion($tenantId: String) {
    onCreateDocumentVersion(tenantId: $tenantId) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      pages {
        pageId
        number
        srcId
        srcHash
        isRequired
        speakerNotes
      }
      pageGroups {
        id
        pageIds
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDocumentVersion = /* GraphQL */ `
  subscription OnUpdateDocumentVersion($tenantId: String) {
    onUpdateDocumentVersion(tenantId: $tenantId) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      pages {
        pageId
        number
        srcId
        srcHash
        isRequired
        speakerNotes
      }
      pageGroups {
        id
        pageIds
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDocumentVersion = /* GraphQL */ `
  subscription OnDeleteDocumentVersion($tenantId: String) {
    onDeleteDocumentVersion(tenantId: $tenantId) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      pages {
        pageId
        number
        srcId
        srcHash
        isRequired
        speakerNotes
      }
      pageGroups {
        id
        pageIds
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAttachedFile = /* GraphQL */ `
  subscription OnCreateAttachedFile($tenantId: String) {
    onCreateAttachedFile(tenantId: $tenantId) {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAttachedFile = /* GraphQL */ `
  subscription OnUpdateAttachedFile($tenantId: String) {
    onUpdateAttachedFile(tenantId: $tenantId) {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAttachedFile = /* GraphQL */ `
  subscription OnDeleteAttachedFile($tenantId: String) {
    onDeleteAttachedFile(tenantId: $tenantId) {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDocument = /* GraphQL */ `
  subscription OnCreateDocument($tenantId: String) {
    onCreateDocument(tenantId: $tenantId) {
      id
      tenantId
      accessLevel
      status
      owner
      expiresAt
      integration {
        integrationId
        externalId
        firstSync
        lastSync
      }
      integrationType
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDocument = /* GraphQL */ `
  subscription OnUpdateDocument($tenantId: String) {
    onUpdateDocument(tenantId: $tenantId) {
      id
      tenantId
      accessLevel
      status
      owner
      expiresAt
      integration {
        integrationId
        externalId
        firstSync
        lastSync
      }
      integrationType
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDocument = /* GraphQL */ `
  subscription OnDeleteDocument($tenantId: String) {
    onDeleteDocument(tenantId: $tenantId) {
      id
      tenantId
      accessLevel
      status
      owner
      expiresAt
      integration {
        integrationId
        externalId
        firstSync
        lastSync
      }
      integrationType
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateEmailTemplate = /* GraphQL */ `
  subscription OnCreateEmailTemplate($tenantId: String) {
    onCreateEmailTemplate(tenantId: $tenantId) {
      id
      tenantId
      labelValues {
        key
        value
      }
      subject
      title
      cc
      bcc
      body
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      status
      editPermissions
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateEmailTemplate = /* GraphQL */ `
  subscription OnUpdateEmailTemplate($tenantId: String) {
    onUpdateEmailTemplate(tenantId: $tenantId) {
      id
      tenantId
      labelValues {
        key
        value
      }
      subject
      title
      cc
      bcc
      body
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      status
      editPermissions
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteEmailTemplate = /* GraphQL */ `
  subscription OnDeleteEmailTemplate($tenantId: String) {
    onDeleteEmailTemplate(tenantId: $tenantId) {
      id
      tenantId
      labelValues {
        key
        value
      }
      subject
      title
      cc
      bcc
      body
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      status
      editPermissions
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCustomDeck = /* GraphQL */ `
  subscription OnCreateCustomDeck($createdBy: String) {
    onCreateCustomDeck(createdBy: $createdBy) {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        visible
        srcId
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCustomDeck = /* GraphQL */ `
  subscription OnUpdateCustomDeck($createdBy: String) {
    onUpdateCustomDeck(createdBy: $createdBy) {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        visible
        srcId
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCustomDeck = /* GraphQL */ `
  subscription OnDeleteCustomDeck($createdBy: String) {
    onDeleteCustomDeck(createdBy: $createdBy) {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        visible
        srcId
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateFolder = /* GraphQL */ `
  subscription OnCreateFolder($createdBy: String) {
    onCreateFolder(createdBy: $createdBy) {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateFolder = /* GraphQL */ `
  subscription OnUpdateFolder($createdBy: String) {
    onUpdateFolder(createdBy: $createdBy) {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteFolder = /* GraphQL */ `
  subscription OnDeleteFolder($createdBy: String) {
    onDeleteFolder(createdBy: $createdBy) {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateContentShare = /* GraphQL */ `
  subscription OnCreateContentShare {
    onCreateContentShare {
      id
      token
      tenantId
      type
      contentId
      expiresAt
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateContentShare = /* GraphQL */ `
  subscription OnUpdateContentShare {
    onUpdateContentShare {
      id
      token
      tenantId
      type
      contentId
      expiresAt
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteContentShare = /* GraphQL */ `
  subscription OnDeleteContentShare {
    onDeleteContentShare {
      id
      token
      tenantId
      type
      contentId
      expiresAt
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateVirtualSession = /* GraphQL */ `
  subscription OnCreateVirtualSession($createdBy: String) {
    onCreateVirtualSession(createdBy: $createdBy) {
      id
      tenantId
      meetingId
      roomName
      status
      recordingStatus
      recordingTaskId
      hostName
      hostPhonePin
      createdAt
      createdBy
      terminatedAt
      meetingLog {
        type
        msg
        timestamp
        action
        param
        user
        isHost
      }
      documentHistory {
        contentId
        contentType
        totalDurationSec
      }
      attendees {
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateVirtualSession = /* GraphQL */ `
  subscription OnUpdateVirtualSession($createdBy: String) {
    onUpdateVirtualSession(createdBy: $createdBy) {
      id
      tenantId
      meetingId
      roomName
      status
      recordingStatus
      recordingTaskId
      hostName
      hostPhonePin
      createdAt
      createdBy
      terminatedAt
      meetingLog {
        type
        msg
        timestamp
        action
        param
        user
        isHost
      }
      documentHistory {
        contentId
        contentType
        totalDurationSec
      }
      attendees {
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteVirtualSession = /* GraphQL */ `
  subscription OnDeleteVirtualSession($createdBy: String) {
    onDeleteVirtualSession(createdBy: $createdBy) {
      id
      tenantId
      meetingId
      roomName
      status
      recordingStatus
      recordingTaskId
      hostName
      hostPhonePin
      createdAt
      createdBy
      terminatedAt
      meetingLog {
        type
        msg
        timestamp
        action
        param
        user
        isHost
      }
      documentHistory {
        contentId
        contentType
        totalDurationSec
      }
      attendees {
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateMeeting = /* GraphQL */ `
  subscription OnCreateMeeting($createdBy: String) {
    onCreateMeeting(createdBy: $createdBy) {
      id
      tenantId
      title
      startTime
      endTime
      contentPresented {
        contentId
        folderItemId
        status
        contentType
        openedAt
        closedAt
      }
      type
      notes
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateMeeting = /* GraphQL */ `
  subscription OnUpdateMeeting($createdBy: String) {
    onUpdateMeeting(createdBy: $createdBy) {
      id
      tenantId
      title
      startTime
      endTime
      contentPresented {
        contentId
        folderItemId
        status
        contentType
        openedAt
        closedAt
      }
      type
      notes
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteMeeting = /* GraphQL */ `
  subscription OnDeleteMeeting($createdBy: String) {
    onDeleteMeeting(createdBy: $createdBy) {
      id
      tenantId
      title
      startTime
      endTime
      contentPresented {
        contentId
        folderItemId
        status
        contentType
        openedAt
        closedAt
      }
      type
      notes
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateIntegrationLog = /* GraphQL */ `
  subscription OnCreateIntegrationLog {
    onCreateIntegrationLog {
      integrationId
      id
      log {
        level
        timestamp
        message
        srcDocId
        srcDocVersion
      }
      status
      tenantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateIntegrationLog = /* GraphQL */ `
  subscription OnUpdateIntegrationLog {
    onUpdateIntegrationLog {
      integrationId
      id
      log {
        level
        timestamp
        message
        srcDocId
        srcDocVersion
      }
      status
      tenantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteIntegrationLog = /* GraphQL */ `
  subscription OnDeleteIntegrationLog {
    onDeleteIntegrationLog {
      integrationId
      id
      log {
        level
        timestamp
        message
        srcDocId
        srcDocVersion
      }
      status
      tenantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateIntegrationSettings = /* GraphQL */ `
  subscription OnCreateIntegrationSettings {
    onCreateIntegrationSettings {
      id
      tenantId
      syncContentEvery
      name
      mapping {
        key
        fieldType
        dataType
        targetFieldName
        srcFieldname
      }
      clientConfigurationFields {
        key
        value
      }
      integrationType
      enabled
      notificationEmail
      errorSyncEmail
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateIntegrationSettings = /* GraphQL */ `
  subscription OnUpdateIntegrationSettings {
    onUpdateIntegrationSettings {
      id
      tenantId
      syncContentEvery
      name
      mapping {
        key
        fieldType
        dataType
        targetFieldName
        srcFieldname
      }
      clientConfigurationFields {
        key
        value
      }
      integrationType
      enabled
      notificationEmail
      errorSyncEmail
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteIntegrationSettings = /* GraphQL */ `
  subscription OnDeleteIntegrationSettings {
    onDeleteIntegrationSettings {
      id
      tenantId
      syncContentEvery
      name
      mapping {
        key
        fieldType
        dataType
        targetFieldName
        srcFieldname
      }
      clientConfigurationFields {
        key
        value
      }
      integrationType
      enabled
      notificationEmail
      errorSyncEmail
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateVirtualAttendeePublic = /* GraphQL */ `
  subscription OnUpdateVirtualAttendeePublic($attendeeKey: ID!) {
    onUpdateVirtualAttendeePublic(attendeeKey: $attendeeKey) {
      id
      attendeeKey
      tenantId
      meetingId
      status
      statusDetail
      name
      phonePin
      joinToken
    }
  }
`;
