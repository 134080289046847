import format from 'date-fns/format'
import { CurrentUser } from 'src/state/redux/selector/user';
import { TenantORM } from 'src/state/redux/selector/tenant';

const config = {
  'version': '0.0.1',
  'defaultFields': [
    {
      'name': 'EMAIL',
      'systemName': 'email',
      'required': true,
      'dataType': 'STRING',
      'fieldType': 'SYSTEM',
    },
    {
      'name': 'STATUS',
      'systemName': 'status',
      'required': true,
      'dataType': 'STRING',
      'fieldType': 'SYSTEM',
    },
    {
      'name': 'GIVEN_NAME',
      'systemName': 'givenName',
      'required': true,
      'dataType': 'STRING',
      'fieldType': 'SYSTEM',
    },
    {
      'name': 'FAMILY_NAME',
      'systemName': 'familyName',
      'required': true,
      'dataType': 'STRING',
      'fieldType': 'SYSTEM',
    },
    {
      'name': 'PHONE_NUMBER',
      'systemName': 'phoneNumber',
      'required': false,
      'dataType': 'STRING',
      'fieldType': 'SYSTEM',
    },
    {
      'name': 'ROLE',
      'systemName': 'role',
      'required': true,
      'dataType': 'CATEGORICAL',
      'fieldType': 'SYSTEM',
      'values': [
        'TenantPublisher',
        'TenantViewer',
      ],
    },
    {
      'name': 'EXCLUDE_REPORTING',
      'systemName': 'isExcludedFromReporting',
      'required': false,
      'dataType': 'BOOLEAN',
      'fieldType': 'SYSTEM',
    },
  ],
}

function getFieldConfigFromTenant(tenant) {
  return tenant.fields.reduce((acc, field) => {
    acc.push({
      name: field.fieldName,
      dataType: field.dataType,
      values: field.values,
    });
    return acc
  }, [])
}

function getUserFieldConfigForTenant(tenant:any) {
  const tenantLabelConfig = getFieldConfigFromTenant(tenant);
  const expandedTenantlabelConfigs = tenantLabelConfig.reduce((acc, config) => {
    acc.push({
      ...config,
      fieldType: 'TENANT-RESTRICT',
      name: `RESTRICT:${config.name.toUpperCase()}`,
      labelName: config.name,
    });
    acc.push({
      ...config,
      fieldType: 'TENANT-DEFAULT',
      name: `DEFAULT:${config.name.toUpperCase()}`,
      labelName: config.name,
    });
    return acc;
  }, []);
  const fieldConfigs = [...config.defaultFields, ...expandedTenantlabelConfigs]
  const fieldConfigLookup = fieldConfigs.reduce((acc, field) => {
    acc[field.name] = field;
    return acc;
  }, {});
  return {
    fieldConfigs,
    fieldConfigLookup,
    tenant,
  }
}

export const exportData = (users : any, currentUser: CurrentUser, tenant : TenantORM | undefined) => {
  if (!users) return;

  const { fieldConfigs } = getUserFieldConfigForTenant(tenant?.tenant);

  const rows = users.map((user) => {
    const restrictedFilters = user.lockedFilters.reduce((acc, labelValue) => {
      acc[labelValue.key] = labelValue;
      return acc;
    }, {});
    const defaultFilters = user.defaultFilterValues.reduce((acc, labelValue) => {
      acc[labelValue.key] = labelValue;
      return acc;
    }, {});
    return fieldConfigs.map((field) => {
      if (field.fieldType === 'SYSTEM') {
        if (field.dataType === 'STRING_ARRAY') {
          return user[field.systemName] ? user[field.systemName].join('|') : '';
        }
        else if (field.dataType === 'BOOLEAN') {
          return user[field.systemName] === null ? '' : `${user[field.systemName] ? 'YES' : 'NO'}`;
        }
        return user[field.systemName] ? user[field.systemName] + '' : '';
      } else if (field.fieldType === 'TENANT-DEFAULT') {
        if (defaultFilters[field.labelName]) {
          return defaultFilters[field.labelName].values.join('|');
        }
        return '';
      } else if (field.fieldType === 'TENANT-RESTRICT') {
        if (restrictedFilters[field.labelName]) {
          return restrictedFilters[field.labelName].values.join('|');
        }
        return '';
      }
    })
  });

  const headerRow = fieldConfigs.map((field) => field.name);
  const headerLine = `"${headerRow.join('","')}"`;
  const lines = rows.map((row) => `"${row.map(d => d.replace(/"/g, '""')).join('","')}"`);

  // add headers
  const csvContent = 'data:text/csv;charset=utf-8,' + `${headerLine}\n${lines.join('\n')}`;
  const tenantName = tenant?.tenant.name.replace(/[^a-z0-9+]+/gi, '_').substring(0, 50)

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${tenantName || ''}usersReport${format(new Date(), 'MM-dd-yyyy')}.csv`);
  document.body.appendChild(link);

  link.click();
}
