import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FileEditTabBarSelected } from 'src/screens/Publishers/TabBar';

export enum DRAWER_ENTITIES {
  EMAIL_TEMPLATE,
  USER,
  MEETING,
  ORGANIZATION,
}

type drawerSliceType = {
  entity?: DRAWER_ENTITIES,
  entityId?: string,
  tenantId?: string;
  isOpen?: boolean,
  orientation?: 'left' | 'right',
  selectedTab?: FileEditTabBarSelected,
  width?: number,
  keepOpen?: boolean,
}

const drawerSlice = createSlice({
  name: 'DrawerState',
  initialState: {
    entity: undefined,
    entityId: undefined,
    tenantId: undefined,
    orientation: 'right',
    width: 960,
    isOpen: false,
    selectedTab : FileEditTabBarSelected.LABELS,
  } as drawerSliceType,
  reducers: {
    toggle: (state, action: PayloadAction<drawerSliceType> ) => {
      const { entityId, width, orientation, selectedTab, entity, tenantId, keepOpen = false } = action.payload;

      const tabSelected = state.isOpen && !keepOpen ? FileEditTabBarSelected.LABELS : selectedTab;
      return {
        ...state,
        isOpen: keepOpen
          ? true
          : !state.isOpen,
        entityId,
        tenantId,
        width,
        orientation,
        selectedTab: tabSelected,
        entity,
      }
    },
    hide: (state ) => {
      return { ...state, isOpen: false, currentDocId: undefined }
    },
  },
})

export default drawerSlice
export const drawerActions = drawerSlice.actions
