import { useEffect, useRef, useState } from 'react'
import { ZenObservable } from 'zen-observable-ts';
import { API, graphqlOperation } from '@aws-amplify/api'
import { useDispatch } from 'react-redux'
import { DataStore } from '@aws-amplify/datastore';
import {
  AttachedFile,
  CustomDeck,
  Document,
  DocumentStatus,
  DocumentVersion,
  EmailTemplate,
  Folder,
  Meeting,
  Tenant,
  User,
} from '@alucio/aws-beacon-amplify/src/models';
import { Logger } from '@aws-amplify/core'
import { SYNC_STATE_EVENT, LOG_MESSAGE_EVENT } from '@alucio/core'
import store from '../redux/store';
import { attachedFileActions } from '../redux/slice/attachedFile';
import { customDeckActions } from '../redux/slice/customDeck';
import { documentActions } from '../redux/slice/document'
import { documentVersionActions } from '../redux/slice/documentVersion'
import { folderActions } from '../redux/slice/folder';
import { tenantActions } from '../redux/slice/tenant'
import { userActions } from '../redux/slice/user';
import { meetingActions } from '../redux/slice/meeting';
import { emailTemplateActions } from '../redux/slice/emailTemplate';
import { useCurrentUser, useUserTenant } from '../redux/selector/user'
import { matchesLockedFilters } from './query'
import workerChannel from 'src/worker/channels/workerChannel'
import PWALogger from 'src/worker/util/logger'
import { cacheActions } from 'src/state/redux/slice/Cache/cache'
import { getAuthHeaders } from 'src/utils/loadCloudfrontAsset/common'
import { useAppSettings } from 'src/state/context/AppSettings'
import CacheDB from 'src/worker/db/cacheDB'
import { allCustomDecks } from '../redux/selector/folder';
import { isIOS } from 'react-device-detect';
import { getUser } from '@alucio/aws-beacon-amplify/src/graphql/queries';
// [TODO: Unsub and clear redux on]
const logger = new Logger('SUBSCRIPTIONS', 'INFO');

const DOC_MAP = {
  attachedFile: {
    INSERT: attachedFileActions.add,
    // [TODO] - It's possible that the lambda function using a combined mutation is
    //  causing an "update" sub to fire instead of an "add"
    UPDATE: attachedFileActions.upsert,
  },
  customDeck: {
    INSERT: customDeckActions.add,
    UPDATE: customDeckActions.update,
  },
  document: {
    INSERT: documentActions.add,
    // for docs we are doing an upsert instead of update
    // because if doc has a lockedFilter and Publisher updates the doc to not lockedFilters we modify redux accordingly
    UPDATE: documentActions.upsert,
    // remove actions is to remove docs that DO NOT satisfy the lockedFilters or are DELETED docs
    REMOVE: documentActions.remove,
  },
  documentVersion: {
    INSERT: documentVersionActions.add,
    UPDATE: documentVersionActions.upsert,
    REMOVE: documentVersionActions.remove,
  },
  emailTemplate: {
    INSERT: emailTemplateActions.add,
    UPDATE: emailTemplateActions.update,
  },
  meetings: {
    INSERT: meetingActions.add,
    UPDATE: meetingActions.update,
  },
  folder: {
    INSERT: folderActions.add,
    UPDATE: folderActions.upsert,
  },
  tenant: {
    INSERT: tenantActions.add,
    UPDATE: tenantActions.update,
  },
  user: {
    INSERT: userActions.add,
    UPDATE: userActions.update,
  },
}

const setupWorkerChannel = () => {
  // We want to attempt to wake up the worker before sending a message
  workerChannel.registerBeforeHook(async () => {
    await fetch('/keepalive')
  })
}

setupWorkerChannel()

export function useDataStoreSubscription(isDataStoreReady: boolean) {
  const [isReady, setIsReady] = useState<boolean>(false)
  const subscriptions = useRef<ZenObservable.Subscription[]>([])
  const currentUser = useCurrentUser()
  const currentTenant = useUserTenant();

  useEffect(() => {
    // Prevent subscribing to datastore before it's ready
    // This prevents duplicate records on initial login (1 set from subscriptions and 1 from hydrating)
    if (!isDataStoreReady) return;

    // [TODO]: Can probably make this generic
    // [TODO]: BEAC-999 the doc subscription is slightly different because we are filtering DELETED and lockedFilter files before populating redux
    const docsSub = DataStore
      .observe(Document)
      .subscribe(async msg => {
        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        if (updatedObj) {
          if (updatedObj.status === 'DELETED') {
            store.dispatch(DOC_MAP.document.REMOVE(updatedObj))
            // [TODO-PWA]: If a file is removed, evict cache or remove the manifest
          } else {
            store.dispatch(DOC_MAP.document[opType](updatedObj))
          }
        }
      });

    const verSub = DataStore
      .observe(DocumentVersion)
      .subscribe(async msg => {
        const { model, opType, element } = msg
        const updatedObj = await DataStore.query<DocumentVersion>(model, element.id)
        logger.debug(`Got Subscription Update for DocVer ${element.id}`)
        if (updatedObj) {
          if (updatedObj.status === 'DELETED') {
            // This doc version draft was deleted so we remove the record from redux
            store.dispatch(DOC_MAP.documentVersion.REMOVE(updatedObj))
          }
          else {
            let matchesFilter = true;

            // [TODO-2126] - Do a performance analysis since runs on every version subscription (which could fire 3 off for any "singular" update)
            if (updatedObj.status === DocumentStatus.PUBLISHED) {
              logger.debug('Update for Published DocVer...checking filters')
              // Need to check for locked filters
              const docVersions = await DataStore.query<DocumentVersion>(
                DocumentVersion,
                c => c.id('beginsWith', updatedObj.documentId).status('ne', DocumentStatus.DELETED),
                { sort: s => s.versionNumber('DESCENDING') },
              )
              const latestPublished = docVersions.find((ver) => ver.status === DocumentStatus.PUBLISHED)
              logger.debug(`Fetched ${docVersions.length} versions. Latest Published ID is: ${latestPublished?.id}`)

              if (latestPublished?.id === updatedObj.id) {
                logger.debug('Updated object is latest published evaluating filters')
                matchesFilter = matchesLockedFilters(currentUser.userProfile?.lockedFilters!)(updatedObj)

                if (!matchesFilter) {
                  logger.debug(`Doc no longer matches filters removing document ${updatedObj.documentId}`)
                  const doc = await DataStore.query<Document>(Document, updatedObj.documentId)
                  doc && store.dispatch(DOC_MAP.document.REMOVE(doc))
                  docVersions.forEach((ver) => { store.dispatch(DOC_MAP.documentVersion.REMOVE(ver)) })
                } else {
                  // Repopulate Redux if needed
                  const docVerFromRedux = store
                    .getState()
                    .documentVersion
                    .records
                    .filter((ver) => ver.documentId === updatedObj.documentId)

                  if (docVerFromRedux.length !== docVersions.length) {
                    logger.debug('Refreshing Redux with doc and docversions')
                    const doc = await DataStore.query<Document>(Document, updatedObj.documentId)
                    if (!doc) {
                      logger.warn(`Unable to locate doc ${updatedObj.documentId} from datastore`)
                    } else {
                      store.dispatch(DOC_MAP.document.UPDATE(doc))
                      if (opType !== 'INSERT') {
                        docVersions.forEach((ver) => { store.dispatch(DOC_MAP.documentVersion.UPDATE(ver)) })
                      }
                    }
                  }
                }
              }
            }
            if (matchesFilter) {
              store.dispatch(DOC_MAP.documentVersion[opType](updatedObj))

              // @ts-ignore - batchSave debugging
              // console.log('Receieved Sub', updatedObj.id + ' ' + updatedObj._version)
              // Make sure folders are updated to the latest version if auto-updating
              // NOTE: Including validation for null because that whats we get instead of undefined if the
              // field does not exist in DynamoDB Tenant record
              if (updatedObj.status === DocumentStatus.PUBLISHED &&
                currentTenant?.folderUpdateGracePeriodDays !== null &&
                currentTenant?.folderUpdateGracePeriodDays !== undefined) {
                store.dispatch(folderActions.applyAutoUpdate([updatedObj], currentTenant?.folderUpdateGracePeriodDays))
                const customDecks = allCustomDecks(store.getState())
                store.dispatch(customDeckActions.applyAutoUpdate(
                  customDecks, currentTenant?.folderUpdateGracePeriodDays))
              }
            }
          }
        }
      });

    const attachedFileSub = DataStore
      .observe(AttachedFile)
      .subscribe(async msg => {
        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        store.dispatch(DOC_MAP.attachedFile[opType](updatedObj))
      });

    const customDeckSub = DataStore
      .observe(CustomDeck)
      .subscribe(async msg => {
        const { model, opType, element } = msg;
        const updatedObj = await DataStore.query(model, element.id)
        store.dispatch(DOC_MAP.customDeck[opType](updatedObj))
      })

    const tenantSub = DataStore
      .observe(Tenant)
      .subscribe(async msg => {
        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        store.dispatch(DOC_MAP.tenant[opType](updatedObj))
      });

    const userSub = DataStore
      .observe<User>(User)
      .subscribe(async msg => {
        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        // AppSync subscription notifications do not include the bookmark array due to field level
        // permissions. As such we need to check to see if it exists and if not query the API directly
        if (updatedObj?.bookmarkedDocs || element.id !== currentUser.userProfile?.id) {
          store.dispatch(DOC_MAP.user[opType](updatedObj))
        } else {
          // Need to query AppSync directly. Data in datastore was updated via subscription and is missing
          // bookmarks
          const queryParams = {
            id: element.id,
          }
          const updatedUser: any = await API.graphql(graphqlOperation(getUser, queryParams));
          store.dispatch(DOC_MAP.user[opType](updatedUser.data.getUser))
        }
      });

    const emailTemplateSub = DataStore
      .observe(EmailTemplate)
      .subscribe(async msg => {
        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        store.dispatch(DOC_MAP.emailTemplate[opType](updatedObj))
      });

    const meetingsSub = DataStore
      .observe(Meeting)
      .subscribe(async msg => {
        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        store.dispatch(DOC_MAP.meetings[opType](updatedObj))
      });

    const folderSub = DataStore
      .observe(Folder)
      .subscribe(async msg => {
        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        store.dispatch(DOC_MAP.folder[opType](updatedObj))
      });

    subscriptions.current = [
      docsSub,
      verSub,
      tenantSub,
      userSub,
      emailTemplateSub,
      folderSub,
      attachedFileSub,
      customDeckSub,
      meetingsSub,
    ]
    setIsReady(true)

    return () => {
      subscriptions.current.forEach(sub => sub.unsubscribe())
    }
  }, [isDataStoreReady])

  return isReady;
}

export const useSyncMachineSubscription = (isDataStoreReady: boolean, isOfflineEnabled?: boolean) => {
  const workerSubs = useRef<ZenObservable.Subscription[]>([])
  const [isReady, setIsReady] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { isPWAStandalone } = useAppSettings()
  // PWALogger callback to save logs into Redux
  // [TODO-PWA] - DISABLE LOGGING IN PRODUCTION
  // useEffect(() => {
  //   PWALogger.setCallback(msg => store.dispatch(cacheActions.addLog('CLIENT - \t\t' + msg)))
  // }, [])

  useEffect(() => {
    const startSubs = async () => {
      if (!isDataStoreReady || !isOfflineEnabled) return;

      PWALogger.debug('Starting subscriptions')

      // [TODO-PWA]
      //  - Correctly handle if Broadcast-Channel on iOS ever closes for any reason
      //    https://github.com/pubkey/broadcast-channel#handling-indexeddb-onclose-events
      workerChannel
        .setOnCloseCallback(() => {
          dispatch(cacheActions.addLog('CLIENT - IDB Closed'))
        })

      // Temp sub to fire back that
      const TempSub = workerChannel
        .observable
        .filter(msg => msg.type === 'SYNC_STATE')
        .subscribe(_ => {
          PWALogger.debug('Received initial SyncMachine State')
          setIsReady(true)
          TempSub.unsubscribe()
        })

      //  Subscribe to SyncManager
      const syncMachineSub = workerChannel
        .observable
        .filter(msg => msg.type === 'SYNC_STATE')
        .subscribe(m => {
          const msg = m as SYNC_STATE_EVENT
          dispatch(cacheActions.updateSync(msg.state))
        })

      const authSub = workerChannel
        .observable
        .filter(msg => msg.type === 'REQUEST_AUTH_HEADERS')
        .subscribe(async () => {
          PWALogger.debug('Sending requested auth headers')

          // [TODO-PWA] - Should handle an error or timeout here or in the machine
          const authHeaders = await getAuthHeaders()
          workerChannel.postMessageExtended({
            type: 'AUTH_HEADERS',
            headers: authHeaders,
          })
        })

      const logSub = workerChannel
        .observable
        .filter(msg => msg.type === 'LOG_MESSAGE')
        .subscribe(m => {
          const msg: LOG_MESSAGE_EVENT = m as LOG_MESSAGE_EVENT
          PWALogger[msg.level.toLowerCase()](msg.message)
        })

      PWALogger.debug('Sending Client "Ready" state to WORKER')

      // For iOS we don't start syncing immediately if they're not in PWA to prevent excess syncing
      workerChannel.postMessageExtended(
        {
          type: 'CLIENT_CONNECTED',
          value: isIOS && !isPWAStandalone
            ? 'PAUSE_SYNC'
            : 'START_SYNC',
        })
      PWALogger.debug('Waiting for SyncMachine State')

      workerSubs.current.push(syncMachineSub)
      workerSubs.current.push(authSub)
      workerSubs.current.push(logSub)
    }

    startSubs()
  }, [isDataStoreReady])

  useEffect(() => {
    return () => { workerSubs.current.forEach(sub => sub.unsubscribe()) }
  }, [])

  return isReady
}

/**
 * We only need to derive the status for Cached on the DocumentORM ONCE whenever we go offline
 * Setting the entries here will trigger a re-render to the Document selector
 */
export const useOfflineManifestSubscription = (): void => {
  const { isOnline, isOfflineEnabled } = useAppSettings()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOnline === false && isOfflineEnabled) {
      const updateManifests = async () => {
        const cacheDB = new CacheDB()
        await cacheDB.open()

        const cacheManifest = await cacheDB.getCacheManifest()
        dispatch(cacheActions.setManifestEntries(cacheManifest))

        await cacheDB.close()
      }

      updateManifests()
    }
  }, [isOnline, isOfflineEnabled])
}
