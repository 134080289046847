import React from 'react'

import { DNAModal, DNAText } from '@alucio/lux-ui'
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'

export type DNAIntegrationSyncModalProps = {
  onChange: (syncVal: boolean) => void
  syncVal: boolean
}

const DNAIntegrationSyncModal: DNAModalVariant<DNAIntegrationSyncModalProps> = (props) => {
  const { toggleModal, onChange, syncVal } = props

  const onAction = () => {
    onChange(syncVal)
    toggleModal()
  }

  return (
    <DNAModal>
      <DNAModal.Header>Disable syncing?</DNAModal.Header>
      <DNAModal.Message>
        This will disable the integration.
      </DNAModal.Message>
      <DNAModal.Body>
        <DNAText bold>{}</DNAText>
      </DNAModal.Body>
      <DNAModal.Confirmation
        onCancel={toggleModal}
        onAction={onAction}
        actionLabel="Disable"
        actionStatus="primary"
      />
    </DNAModal>
  )
}

export default DNAIntegrationSyncModal
