import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { EmailTemplate, EmailTemplateStatus } from '@alucio/aws-beacon-amplify/src/models';
import { RootState } from '../store';
import { EmailTemplateORM, ORMTypes } from 'src/types/types';
import {
  AttachedFilesMap,
  DocumentsMap,
  DocumentVersionsMap,
  getAssociatedFilesORM,
  selAttachedFilesMap,
  selDocumentsMap,
  selDocumentVersionsMap,
} from './document';

export type EmailTemplateORMMap = { [emailTemplateId: string]: EmailTemplateORM }
export const selEmailTemplates = (state: RootState): EmailTemplate[] => state.emailTemplate.records;

const toEmailTemplateORM = (
  emailTemplate: EmailTemplate,
  attachedFilesMap: AttachedFilesMap,
  documentsMap: DocumentsMap,
  documentVersionsMap: DocumentVersionsMap,
): EmailTemplateORM => {
  const associatedFiles = getAssociatedFilesORM(
    emailTemplate.associatedFiles ?? [],
    attachedFilesMap,
    documentsMap,
    documentVersionsMap,
  );

  const emailTemplateORM : EmailTemplateORM = {
    model: emailTemplate,
    type: ORMTypes.EMAIL_TEMPLATE,
    relations: {
      associatedFiles: associatedFiles,
    },
  }

  return emailTemplateORM
}

export const emailTemplateList = createSelector(
  selEmailTemplates,
  selAttachedFilesMap,
  selDocumentsMap,
  selDocumentVersionsMap,
  (emailTemplates, attachedFilesMap, documentsMap, documentVersionsMap): EmailTemplateORM[] =>
    emailTemplates.map(emailTemplate =>
      toEmailTemplateORM(emailTemplate, attachedFilesMap, documentsMap, documentVersionsMap),
    ),
);

export const allEmailTemplatesMap = createSelector(
  emailTemplateList,
  (emailTemplates): EmailTemplateORMMap => {
    return emailTemplates.reduce(
      (acc, emailTemplate) => {
        acc[emailTemplate.model.id] = emailTemplate;
        return acc;
      },
      {} as EmailTemplateORMMap,
    )
  },
);

export const emailTemplateById = createSelector(
  allEmailTemplatesMap,
  (_, id: string) => id,
  (templates, id): EmailTemplateORM | undefined => templates[id],
)

/** Returns the list of Email Templates */
export const useEmailTemplateList = (): ReturnType<typeof emailTemplateList> =>
  useSelector((state: RootState) => emailTemplateList(state));

/** Returns an Email Template by Id */
export const useTemplateById = (templateId?: string): EmailTemplateORM | undefined =>
  useSelector((state: RootState) => templateId
    ? emailTemplateById(state, templateId)
    : undefined);

/** Returns the list of active Email Templates for MSL */
export const useEmailTemplateListForMSL = (): ReturnType<typeof emailTemplateList> =>
  useSelector((state: RootState) => emailTemplateList(state)
    .filter(tpl => tpl.model.status === EmailTemplateStatus.ACTIVE));
