import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { LoadedPresentation, useContent } from 'src/state/context/ContentProvider/ContentProvider';
import DNASlideRoll from 'src/components/DNA/SlideRoll/DNASlideRoll';
import { SLIDE_ROLL_VARIANT } from 'src/components/DNA/SlideRoll/StyleSetting';
import { DNABox } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: colors['color-dark-1000'],
    paddingHorizontal: 14,
    paddingVertical: 14,
  },
});

interface Props {
  presentation: LoadedPresentation,
  onPageSelect: (presentationPageNumber: number) => void,
}

const SlideRollWrapper = (props: Props) => {
  const { presentation, onPageSelect } = props;

  const memoizedSlideRoll = useMemo(() => (
    <DNASlideRoll
      activeSlide={presentation.currentPresentablePage}
      presentable={presentation.presentable}
      variant={SLIDE_ROLL_VARIANT.MEETINGS}
      horizontal={false}
      onSelect={onPageSelect}
    />
  ), [presentation]);

  return (
    <DNABox style={styles.mainWrapper}>
      {memoizedSlideRoll}
    </DNABox>
  );
};

const SlideRollConnector = () => {
  const { activePresentation, setActiveSlideByPresentationPageNumber } = useContent();

  return (
    <SlideRollWrapper
      presentation={activePresentation!}
      onPageSelect={setActiveSlideByPresentationPageNumber}
    />
  );
};

SlideRollWrapper.title = 'SlideRollWrapper';

export default SlideRollConnector;
