import React, { ReactElement, useMemo, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { luxColors, DNABox, Iffy, MenuItem, Hoverable, DNAText } from '@alucio/lux-ui';
import { useCanPerformSearch, useMSLDocumentSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import { DocumentORM } from 'src/types/types';
import { useUserTenant } from 'src/state/redux/selector/user';
import { useDispatch } from 'react-redux';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip';

interface Props {
  moveToResultsPage?: () => void;
  searchText: string;
  onSelectItem(selectedDocument: DocumentORM)
}

interface RecordRowProps {
  customFieldNames: string[];
  document: DocumentORM;
  onSelectItem: (selectedDocument: DocumentORM) => void;
  tokenizedSearchText: string[];
}

const styles = StyleSheet.create({
  badgeWrapper: {
    marginLeft: 4,
    marginRight: 16,
  },
  boldedText: {
    color: luxColors.contentText.tertiary,
    fontSize: 12,
  },
  customFieldsText: {
    color: luxColors.subtitle.quinary,
    fontSize: 12,
    lineHeight: 20,
  },
  hoveredBackgroundColor: {
    backgroundColor: luxColors.hoveredBackground.primary,
  },
  mobileGridViewHeader: {
    backgroundColor: colors['color-flat-200'],
    paddingHorizontal: 16,
    paddingVertical: 5,
  },
  noResults: {
    marginLeft: 4,
    marginBottom: 6,
    marginTop: 6,
  },
  recordRow: {
    flex: 1,
    flexDirection: 'row',
    height: 52,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
  },
  resultsWrapperStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  selectedFilters: {
    backgroundColor: colors['color-brand-compliment-500'],
    borderRadius: 3,
    left: 20,
    paddingHorizontal: 4,
    paddingVertical: 2,
    position: 'absolute',
    top: 0,
  },
  titleText: {
    color: luxColors.contentText.tertiary,
    fontSize: 14,
    lineHeight: 16,
  },
  viewAllWrapper: {
    borderTopColor: luxColors.disabled.quaternary,
    borderTopWidth: 1,
    justifyContent: 'center',
  },
});

// RETURNS THE TEXT FIELD AS AN ARRAY OF STRING SEPARATED BY SPACES AND PUNCTUATION MARKS
function getTokenizedText(text: string): string[] {
  const tokens: string[] = [];
  let lastBreakPoint = 0;

  for (let i = 0; i < text.length; i++) {
    if (text[i].match(/[^\w\s]|_/g) || text[i] === ' ') {
      if (lastBreakPoint !== i) {
        tokens.push(text.substring(lastBreakPoint, i));
      }
      tokens.push(text.substring(i, i + 1));
      lastBreakPoint = i + 1;
    } else if (i === text.length - 1) {
      tokens.push(text.substring(lastBreakPoint, text.length));
    }
  }

  return tokens;
}
const useResultsBox = (props: Props) => {
  const { moveToResultsPage, searchText = '' } = props;
  const [hoveredViewAll, setHoveredViewAll] = useState<boolean>(false);
  const searchedText = useMemo(() => searchText.toLowerCase().trim(), [searchText]);
  const canPerformSearch = useCanPerformSearch();
  const tokenizedSearchText = getTokenizedText(searchedText);
  // [TODO] We should debounce this
  // Consider checking out https://github.com/xnimorz/use-debounce
  const resultSearch: DocumentORM[] = useMSLDocumentSearch({ text: searchedText });
  const displayEmptyMessage = canPerformSearch(searchedText) && !resultSearch.length;
  const tenant = useUserTenant();
  const customFieldNames = useMemo(() => {
    return tenant?.fields?.reduce((acc, customField) => {
      if (customField.defaultSearchFilter) {
        acc.push(customField.fieldName);
      }
      return acc;
    }, [] as string[]) || [];
  }, [tenant]);

  const dispatch = useDispatch();

  // [TODO-2126] - Should handle offline cache fallback?
  function onSelectItem(selectedDocument: DocumentORM): void {
    dispatch(contentPreviewModalActions.setModalVisibility({
      isOpen: true,
      documentVersionId: (
        selectedDocument.relations.version.latestUsableDocumentVersion?.model.id
      ),
    }));
  }

  return {
    onSelectItem,
    setHoveredViewAll,
    hoveredViewAll,
    displayEmptyMessage,
    resultSearch,
    tokenizedSearchText,
    customFieldNames,
    moveToResultsPage,
    searchText,
  }
}

// RETURNS THE TEXT AS BOLDED COMPONENTS (IF THEY MATCH THE SEARCH TEXT)
function getTextComponents(
  tokenizedSearchText: string[],
  textFieldValue: string,
  isTitle = false,
): (string | ReactElement)[] {
  const formattedText: (string | ReactElement)[] = [];
  const tokenizedField = getTokenizedText(textFieldValue);
  const boldTextStyle = { bold: true, style: isTitle ? styles.titleText : styles.boldedText };

  // ITERATE OVER THE TOKENS TO FIND IF THERE'S A MATCH
  tokenizedField.forEach((token) => {
    // CHECK IF ONE OF THE SEARCH ITEMS MATCHES ANY TOKEN OF THE FIELD
    const matchedToken = tokenizedSearchText.find((searchToken) => token.toLowerCase().includes(searchToken));

    // IF THERE'S A MATCH, BOLD IT. OTHERWISE, RETURN IT AS IT IS.
    if (matchedToken) {
      const index = token.toLowerCase().indexOf(matchedToken);

      if (index > 0) {
        formattedText.push(token.substring(0, index));
        formattedText.push(
          React.createElement(DNAText, boldTextStyle, [token.substring(index, index + matchedToken.length)]),
        )
        formattedText.push(token.substring(index + matchedToken.length, token.length));
      } else {
        formattedText.push(React.createElement(DNAText, boldTextStyle, [token.substring(0, matchedToken.length)]));
        formattedText.push(token.substring(matchedToken.length, token.length));
      }
    } else {
      formattedText.push(token);
    }
  });

  return formattedText;
}

function RecordRow(props: RecordRowProps): ReactElement {
  const { customFieldNames, document, tokenizedSearchText, onSelectItem } = props;
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const integrationSource = document.meta.integration.source
    ? `, \u2022 ${document.meta.integration.source}`
    : ''
  // THE DISPLAYED CUSTOM FIELDS WILL DEPEND ON THE DEFAULTS OF THE TENANT
  const tenantValues = customFieldNames.reduce((acc, fieldName) => {
    const value = document.meta.tenantFields.valuesMap[fieldName].filter((value) => !!value);
    if (value && value.length > 0) {
      acc += acc ? `, ${value.join(', ')}` : ` ⋅ ${value.join(', ')}`;
    }
    return acc;
  }, '');

  function toggleHover(): void {
    setIsHovered(!isHovered);
  }

  function onSelect(): void {
    onSelectItem(document);
  }

  return (
    <Hoverable onHoverIn={toggleHover} onHoverOut={toggleHover}>
      <TouchableOpacity onPress={onSelect} style={[styles.recordRow, isHovered && styles.hoveredBackgroundColor]}>
        <DNABox alignY="start" style={styles.badgeWrapper}>
          <DNADocumentChip
            item={document}
            variant="purpose"
          />
        </DNABox>
        <DNABox shrink appearance="col">
          <DNAText status="basic" numberOfLines={1} style={styles.titleText}>
            {getTextComponents(tokenizedSearchText,
              document.relations.version.latestUsableDocumentVersion.model.title || '', true)}
          </DNAText>
          <DNAText style={styles.customFieldsText} numberOfLines={1}>
            {
                getTextComponents(tokenizedSearchText, `${document.model.type} ${tenantValues} ${integrationSource}`)
            }
          </DNAText>
        </DNABox>
      </TouchableOpacity>
    </Hoverable>
  );
}

const SearchInputResultsDesktop = (props: Props) => {
  const {
    onSelectItem,
    setHoveredViewAll,
    hoveredViewAll,
    displayEmptyMessage,
    resultSearch,
    tokenizedSearchText,
    customFieldNames,
    moveToResultsPage,
  } = useResultsBox(props);

  function toggleViewAllHover(): void {
    setHoveredViewAll(!hoveredViewAll);
  }

  function selectItemHandler(selectedDocument: DocumentORM) {
    onSelectItem(selectedDocument);
    props.onSelectItem(selectedDocument)
  }

  return (
    <React.Fragment>
      <Iffy is={!displayEmptyMessage}>
        <MenuItem
          style={styles.resultsWrapperStyle}
          title={() =>
            (<DNABox appearance="col" fill>
              {resultSearch.slice(0, 5).map((document, index) =>
                (<RecordRow
                  key={index}
                  tokenizedSearchText={tokenizedSearchText}
                  document={document}
                  customFieldNames={customFieldNames}
                  onSelectItem={selectItemHandler}
                />))
            }
            </DNABox>)
        }
        />
      </Iffy>
      <Iffy is={resultSearch && resultSearch.length > 5}>
        <Hoverable onHoverIn={toggleViewAllHover} onHoverOut={toggleViewAllHover}>
          <MenuItem
            style={[styles.viewAllWrapper, hoveredViewAll && styles.hoveredBackgroundColor]}
            onPress={moveToResultsPage}
            title={() => <DNAText b2 status="info" style={styles.noResults}>View all</DNAText>}
          />
        </Hoverable>
      </Iffy>
      <Iffy is={displayEmptyMessage}>
        <MenuItem
          disabled
          title={ () =>
            (<DNAText b2 style={styles.noResults} numberOfLines={1}>
              No items match your search. Please try a different search.
            </DNAText>)
          }
        />
      </Iffy>
    </React.Fragment>
  );
}

SearchInputResultsDesktop.displayName = 'SearchInput';

export default SearchInputResultsDesktop;
