import React from 'react';
import { User, UserRole, UserStatus } from '@alucio/aws-beacon-amplify/src/models';
import {
  useDNAUserActions,
  UserActions, userContextActions,
} from './DNAUser.actions';
import { DNAContextMenu } from '@alucio/lux-ui';
import { useCurrentUser } from 'src/state/redux/selector/user';

export interface DNAUserContextMenuProps {
  user: User,
  actions?: UserActions[],
  children: React.ReactElement,
}

export const DNAUserContextMenu: React.FC<DNAUserContextMenuProps> = (props) => {
  const {
    user,
    actions,
    children,
  } = props;
  const DNAUserActions = useDNAUserActions();
  const { userProfile } = useCurrentUser();

  const actionsToUse: UserActions[] = [];

  if ([UserRole.TENANT_ADMIN, UserRole.ALUCIO_ADMIN].includes(userProfile?.role as UserRole)) {
    actionsToUse.push('edit');
  }

  user.status === UserStatus.ACTIVE ? actionsToUse.push('deactivate') : actionsToUse.push('reactivate')

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        { children }
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        {
          (actions ?? actionsToUse).map(action => {
            const { onPress, ...rest } = userContextActions[action];
            return (
              <DNAContextMenu.Item
                key={action}
                onPress={DNAUserActions[action](user)}
                {...rest}
              />
            )
          })
        }
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default DNAUserContextMenu;
