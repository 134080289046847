import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'src/state/redux/store'

import { Modal, DNABox } from '@alucio/lux-ui'
import { DNAModalActions, DNAModalComponent, DNASetModalArgs } from 'src/state/redux/slice/DNAModal/DNAModal'

export type DNAModalVariant<P> = React.FC<{
  isVisible: boolean,
  setModal: (args: DNASetModalArgs) => void,
  closeModal: () => void,
  toggleModal: () => void,
  pushModal: (c: DNAModalComponent) => void
} & P>

export const DNAConnectedModal: React.FC = () => {
  const dispatch = useDispatch()
  const {
    isVisible,
    allowBackdropCancel,
    component: components,
  } = useSelector((state: RootState) => state.DNAModal)
  const closeModal = () => { dispatch(DNAModalActions.closeModal()) }
  const setModal = (args: DNASetModalArgs) => { dispatch(DNAModalActions.setModal(args)) }
  const toggleModal = () => { dispatch(DNAModalActions.toggleModal()) }
  const pushModal = (c: DNAModalComponent) => { dispatch(DNAModalActions.pushModal(c)) }

  if (!components || !components.length) {
    return null;
  }

  return (
    <Modal
      visible={isVisible}
      onBackdropPress={allowBackdropCancel ? closeModal : () => { }}
      backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      {
        components.map((Component, idx) => (
          <DNABox
            key={idx}
            style={{
              display: idx === components.length - 1
                ? 'flex'
                : 'none',
            }}
          >
            <Component
              isVisible={isVisible}
              setModal={setModal}
              closeModal={closeModal}
              toggleModal={toggleModal}
              pushModal={pushModal}
            />
          </DNABox>
        ))
      }
    </Modal>
  )
}

export default DNAConnectedModal
