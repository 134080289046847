import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Text, ScrollView, View } from 'react-native';
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import {
  Icon,
  Box,
  luxColors,
  Iffy,
  DNABox,
  DNACheckbox,
  DNAText,
  Popover,
  InformationMessage,
  DNADivider,
} from '@alucio/lux-ui'

import {
  DocumentVersion,
  FieldDataType,
  FieldConfig,
  LabelValue,
} from '@alucio/aws-beacon-amplify/src/models'

import im from 'immer'

// form and validator
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

// Components
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import { styles } from '@alucio/beacon/src/components/Publishers/Styles'
import InputComponent from '@alucio/beacon/src/components/Publishers/InputComponent'
import SelectInputComponent from '@alucio/beacon/src/components/Publishers/SelectInputComponent'
import RadioInputComponent from '@alucio/beacon/src/components/Publishers/RadioInputComponent'
import DateComponent from '@alucio/beacon/src/components/Publishers/DateComponent'
import { S } from 'src/screens/Publishers/Versioning/VersioningPanel'

// redux state
import { usePublisherList } from 'src/state/redux/selector/user'
import { useVersioningPanel } from './VersioningPanelContext'
import { getMappedTenantFields } from 'src/state/redux/selector/document'
import { useSelector } from 'react-redux'
import { RootState } from 'src/state/redux/store'
import { DocumentVersionORM } from 'src/types/orms'
import GroupSelectInputComponent from 'src/components/Publishers/GroupSelectInputComponent'

import { INFO_MESSAGES } from 'src/state/machines/versioning/updateVersionRedesign'
import { FeatureFlags } from 'src/types/featureFlags'
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag'

const ERROR_MAP = {
  too_small: 'Missing required fields',
  invalid_date: 'Expiration date is invalid',
  nonempty_array_is_empty: 'Missing required fields',
}

// for some reason the message does not show up, it could be related to the older zersion of zod we are using
const formSchema = z.object({
  title: z
    .string()
    .nonempty({ message: 'This field is required' }),
  purpose: z
    .string()
    .nonempty({ message: 'This field is required' }), // convert to z.enum
  canHideSlides: z
    .boolean()
    .optional(),
  distributable: z
    .boolean()
    .optional(),
  downloadable: z
    .boolean()
    .optional(),
  longDescription: z
    .string()
    .optional(),
  owner: z
    .string()
    .nonempty({ message: 'This field is required' }),
  shortDescription: z
    .string()
    .nonempty({ message: 'This field is required' }),
  expiresAt: z
    .date()
    .optional()
    .refine((val) => val, { message: 'This field is required' })
    .refine(
      (date) => date && date > new Date(Date.now()),
      { message: 'The date must be after today' },
    ),
})

export type DocumentInfoFormRef = {
  // [TODO-2126] - This is a temporary measure, we should have a better mechanism of accessing these forms
  //             - Consider having a single form live at the Versioning Context level?
  getPurposeAndModifiable: () => Partial<DocumentVersion>,
  handleCallingFormSubmitFromParent: () => Promise<Partial<DocumentVersion> | null>,
  handleSavingFormAsDraftFromParent: () => Partial<DocumentVersion>,
  isDirty: boolean
}

interface CustomFieldProps {
  fieldName: string
  onChangeSelection: (fieldName: string, value: string[] | string) => void
  scrollFormView?: (y: number) => void
  fieldConfig: FieldConfig
  value: string[] | string
  disabled?: boolean
  validationError?: string
}

// function to convert fieldLabels from form input to labelValues model definition
function convertFieldLabelsToLabelValues(fieldLabels: Record<string, string[]>): LabelValue[] {
  const labelValues = Object
    .entries(fieldLabels)
    .reduce<LabelValue[]>(
      (acc, [labelName, labelValues]) => {
        acc.push(...labelValues.map(labelValue => ({ key: labelName, value: labelValue })))
        return acc
      },
      [],
    )

  return labelValues
}

function CustomFieldComponent(props: CustomFieldProps) {
  const {
    fieldName,
    disabled,
    fieldConfig,
    onChangeSelection,
    scrollFormView,
    validationError,
    value: fieldValue,
  } = props

  const { dataType, required, values, description } = fieldConfig

  function onSelected(value: string[] | string): void {
    onChangeSelection(fieldName, value)
  }

  switch (dataType) {
    case FieldDataType.CATEGORICAL: return (
      <SelectInputComponent
        title={fieldName}
        allowDeselect={true}
        multiSelect={false}
        required={required}
        availableValues={values ?? []}
        descriptionText={description}
        onChangeSelection={onSelected}
        status={validationError ? 'danger' : 'flat'}
        testID={`${fieldName.toLowerCase().replaceAll(' ', '-')}`}
        value={fieldValue}
        disabled={disabled}

      />
    )
    case FieldDataType.MULTICATEGORICAL: return (
      <SelectInputComponent
        title={fieldName}
        testID={`${fieldName.toLowerCase().replaceAll(' ', '-')}`}
        multiSelect={true}
        required={required}
        availableValues={values ?? []}
        descriptionText={description}
        onChangeSelection={onSelected}
        status={validationError ? 'danger' : 'flat'}
        value={fieldValue}
        disabled={disabled}
      />
    )
    case FieldDataType.DATE: return (
      <DateComponent
        title={fieldName}
        disabled={disabled}
        selectedDate={fieldValue && !Array.isArray(fieldValue) ? new Date(fieldValue) : undefined}
        onDateSelected={(value) => {
          const strValue = value.toLocaleDateString('en-US')
          onSelected(strValue)
        }}
        onShowCalendar={(e) => {
          scrollFormView?.(e.nativeEvent.locationY)
        }}
        descriptionText={description}
      />
    )
    default: return (
      <InputComponent
        title={fieldName}
        disabled={disabled}
        required={required}
        multiline={false}
        status={validationError ? 'danger' : 'flat'}
        value={Array.isArray(fieldValue) ? fieldValue[0] : fieldValue}
      />
    )
  }
}

export const CustomField = React.memo(CustomFieldComponent)

const useDocumentInfoConditionals = (latestFormValues: Partial<DocumentVersion>) => {
  const { documentORM, currentDocumentVersionORM } = useVersioningPanel()

  const latestPublished = documentORM?.relations.version.latestDocumentVersionPublished
  const canHideSlides = ['External - Proactive', 'External - Reactive']
    .includes(currentDocumentVersionORM?.model.purpose!)

  const isInternalDisabled = latestPublished &&
    (currentDocumentVersionORM?.model.purpose !== 'Internal Use Only')

  const isModifiableDisabled = isInternalDisabled && currentDocumentVersionORM?.model.canHideSlides

  const isPurposeInternalOnly = latestFormValues.purpose
    ? latestFormValues.purpose === 'Internal Use Only'
    : currentDocumentVersionORM?.model.purpose === 'Internal Use Only'

  return {
    latestPublished,
    canHideSlides,
    isInternalDisabled,
    isModifiableDisabled,
    isPurposeInternalOnly,
  }
}

type Error = { message?: string, type: string }

// [NOTE] - This is not the "real" error type
const useDocumentInfoFormatted = (errors: Record<string, Error | Record<string, Error>> = {}) => {
  const { currentDocumentVersionORM } = useVersioningPanel()
  const publishers = usePublisherList()

  const customFieldsLabels = Object.entries(currentDocumentVersionORM?.meta.tenantFields.configsMap ?? {})
  const selectedDate = currentDocumentVersionORM?.model.expiresAt
    ? new Date(format(
      parseISO(currentDocumentVersionORM.model.expiresAt),
      'MM/dd/yyyy',
    ))
    : undefined
  const publishersFormatted = publishers.reduce<Record<string, string[]>>(
    (acc, publisher) => {
      if (!acc[publisher.user.email]) {
        acc[publisher.user.email] = [publisher.meta.formattedName ?? '']
      } else {
        acc[publisher.user.email] = []
      }

      return acc
    },
    {},
  )

  // [TODO-2126] - The informational banner error messaging is different than the error messaging
  //          underneath each individual field, consult Thai on what the banner messaging should be
  //        - It's easy enough for required, but for other fields, (Incorrect date), what do we want set the message as?
  //        - Form errors are generally straight forward, but we need to consider other machine errors as well
  const consolidatedFormErrors = [...new Set(
    Object
      .values(errors)
      .map(err => {
        // Non-nested errors
        if (typeof err.type === 'string') {
          const error = err as Error
          const isCustomDateErr = error.type === 'custom' && error.message?.includes('date')

          return [
            isCustomDateErr
              ? 'invalid_date'
              : error.type,
          ]
        }
        // Shallow nested errors
        else {
          const error = err as Record<string, Error>
          return [...new Set(Object.values(error).map(nestedErr => nestedErr.type))]
        }
      })
      .flat(),
  )]

  const formattedFormErrors = [...new Set(consolidatedFormErrors.map(err => ERROR_MAP[err]))].join('\n')

  return {
    customFieldsLabels,
    publishersFormatted,
    selectedDate,
    errorMessages: formattedFormErrors,
  }
}

const useDocumentInfoForm = (ref:
  | ((instance: DocumentInfoFormRef | null) => void)
  | React.MutableRefObject<DocumentInfoFormRef | null>
  | null,
) => {
  const { currentDocumentVersionORM, send, state } = useVersioningPanel()
  const formatted = useDocumentInfoFormatted()
  const tenants = useSelector((state: RootState) => state.tenant.records)
  const userTenant = tenants.find(tenant => tenant.id === currentDocumentVersionORM?.model.tenantId)

  const formAndTenantFieldsSchema = useMemo(() => {
    const customTenantSchema = formatted.customFieldsLabels.reduce(
      (acc, [fieldLabel, fieldConfig]) => {
        return {
          ...acc,
          // Zod v3 allows custom message in nonempty, we are currently using v2 (noncompatible TS version)
          [fieldLabel]: fieldConfig.required
            ? z.string().array().nonempty()
            : z.string().array(),
        }
      },
      {},
    )

    return formSchema.merge(
      z.object({ fieldLabels: z.object(customTenantSchema).optional() }),
    )
  }, [])

  type DocumentInfoFormSchema = z.infer<typeof formAndTenantFieldsSchema>

  const computeInitialValues = (docVerORM?: DocumentVersionORM): Partial<DocumentInfoFormSchema> => {
    if (!docVerORM?.model) { return { fieldLabels: {} } }

    const {
      title,
      purpose,
      canHideSlides,
      distributable,
      downloadable,
      longDescription,
      owner,
      shortDescription,
      expiresAt,
    } = docVerORM.model

    const initialValues = {
      title,
      purpose,
      canHideSlides,
      distributable,
      downloadable,
      longDescription,
      owner,
      shortDescription,
      // [TODO-2126] - DocumentORM had a computed label values map, we need to migrate the same to DocVer
      //             - This code is probably prone to bugs if we do it ad-hoc here since there could be variations in implementation
      fieldLabels: getMappedTenantFields(docVerORM!.model, userTenant!).values,
      expiresAt: expiresAt
        ? new Date(format(parseISO(expiresAt), 'MM/dd/yyyy'))
        : formatted.selectedDate,
    }

    const nullishValues = im(initialValues, (draft) => {
      for (const key in draft) {
        if (draft[key] === null) { draft[key] = undefined }
      }
    })

    return nullishValues
  }

  const rhForm = useForm({
    resolver: zodResolver(formAndTenantFieldsSchema),
    defaultValues: computeInitialValues(currentDocumentVersionORM),
  })
  const { formState: { isDirty } } = rhForm

  // [TODO-2126] - This is a whack dependenency
  //             - We attempt to automatically set dirty flags to the machine
  useEffect(
    () => {
      if (!isDirty && state.context.documentInfoIsDirty) {
        send({ type: 'SET_IS_DIRTY', payload: { type: 'info', isDirty } })
      }

      if (
        isDirty &&
        state.event.type !== 'SAVE_DRAFT' &&
        !state.context.documentInfoIsDirty
      ) { send({ type: 'SET_IS_DIRTY', payload: { type: 'info', isDirty } }) }

      if (
        isDirty &&
        state.event.type === 'SAVE_DRAFT' &&
        !state.context.documentInfoIsDirty) { rhForm.reset(rhForm.getValues(), { isDirty: false }) }
    },
    [state, isDirty],
  )

  // [TODO-2126] - This resets the form values on DocVer switching
  //             - This is probably a better way to do this explicitly rather than useEffect observation
  useEffect(
    () => {
      const newForm = computeInitialValues(currentDocumentVersionORM)
      rhForm.reset(newForm)
    },
    [currentDocumentVersionORM?.model.id],
  )

  const handleCallingFormSubmitFromParent = async (): Promise<Partial<DocumentVersion> | null> => {
    const formValues = rhForm.getValues()
    const isValid = await rhForm.trigger()

    if (!isValid) { return null }

    let labelValues: LabelValue[] | undefined
    if (formValues.fieldLabels) {
      labelValues = convertFieldLabelsToLabelValues(formValues.fieldLabels)
      delete formValues.fieldLabels
    }

    return {
      ...formValues,
      expiresAt: formValues.expiresAt?.toISOString(),
      labelValues,
    }
  }

  // this function does not validate form for save draft
  const handleSavingFormAsDraftFromParent = (): Partial<DocumentVersion> => {
    const formValues = rhForm.getValues()
    let labelValues: LabelValue[] | undefined

    if (formValues.fieldLabels) {
      labelValues = convertFieldLabelsToLabelValues(formValues.fieldLabels)
      delete formValues.fieldLabels
    }

    return {
      ...formValues,
      expiresAt: formValues.expiresAt?.toISOString(),
      labelValues,
      // We nullish coalesce these to false for form validation
      canHideSlides: formValues.canHideSlides ?? false,
      distributable: formValues.distributable ?? false,
      downloadable: formValues.downloadable ?? false,
    }
  }

  useImperativeHandle(
    ref,
    () => ({
      getPurposeAndModifiable: () => {
        // [TODO-2126] - Consider just sending all values?
        const { purpose, canHideSlides, distributable } = rhForm.getValues()
        return { purpose, canHideSlides, distributable }
      },
      handleCallingFormSubmitFromParent,
      handleSavingFormAsDraftFromParent,
      isDirty: rhForm.formState.isDirty,
    }),
  )

  return {
    ...rhForm,
    formAndTenantFieldsSchema,
  }
}

const DocumentInfo = forwardRef<DocumentInfoFormRef>((props, ref) => {
  const {
    cond: stateCond,
    meta,
    send,
    currentDocumentVersionORM,
  } = useVersioningPanel()

  const { control, formState: { errors }, getValues } = useDocumentInfoForm(ref)
  const calendarContainerRef = useRef<HTMLDivElement>()
  const formatted = useDocumentInfoFormatted(errors as any)

  /** SECTION: Feature flags */
  const enableDistributable = useFeatureFlag(FeatureFlags.BEAC_2516_Enable_Document_Sharing_Templates)
  /** !SECTION: End Feature flags */

  const isFormDisabled = !stateCond.isInDraftingState
  const formScrollPanel = React.createRef<ScrollView>()
  const docVersion = currentDocumentVersionORM
  // @ts-ignore [TODO-2126] - Fix types for this due to custom labels form values not quite matching the model schema
  //                          (convenient object access vs array of labels)
  const cond = useDocumentInfoConditionals(getValues())
  const onShowCalendar = useCallback(() => {
    const expirationContainerTop = (calendarContainerRef.current?.offsetTop ?? 0)
    const expirationContainerHeight = (calendarContainerRef.current?.offsetHeight ?? 0)
    const calendarHeight = 250
    const threshold = (expirationContainerTop + expirationContainerHeight) - calendarHeight

    const scrollY = formScrollPanel.current?.getScrollableNode().scrollTop
    const shouldScroll = scrollY > threshold
    const scrollPos = expirationContainerTop - calendarHeight

    return scrollFormView(scrollPos, shouldScroll)
  }, [])

  const scrollForm = useCallback((yPos) => scrollFormView(yPos), [])

  function scrollFormView(yPos: number, shouldScroll: boolean = true): void {
    if (shouldScroll)
    { formScrollPanel.current?.scrollTo({ x: 0, y: yPos, animated: true }) }
  }

  return (
    <DNABox fill appearance="col" style={S.tabContentContainer}>
      {/* INFORMATIONAL BANNERS */}
      {/* FORM ERRORS */}
      {/* DNABox added to wrap the messages to fix the issue in BEAC-2689 */}
      <DNABox appearance="col">
        <Iffy is={formatted.errorMessages}>
          <InformationMessage
            text={formatted.errorMessages}
            variance="danger"
          />
        </Iffy>

        {/* EDIT READY */}
        <Iffy is={stateCond.hasOptimizedFinishedThisSession}>
          <InformationMessage
            text={INFO_MESSAGES.UPLOADED.message}
            variance={INFO_MESSAGES.UPLOADED.status}
          />
        </Iffy>

        {/* GENERIC MACHINE STATES */}
        <Iffy is={stateCond.hasInfoMessage}>
          <InformationMessage
            text={
              stateCond.isCancellingNewVersionUpload
                ? meta.cancelMessage?.message
                : meta.infoMessage?.message
            }
            variance={
              stateCond.isCancellingNewVersionUpload
                ? meta.cancelMessage?.status
                : meta.infoMessage?.status
            }
          />
        </Iffy>
      </DNABox>

      <Box style={styles.OptionsContainer}>
        <View style={styles.ThumbnailContainer}>
          <DNADocumentThumbnail
            documentVersionORM={docVersion}
            width={137}
            height={75}
            showProcessing={true}
          />
        </View>
        <Box style={[styles.titleInput, { borderColor: 'red' }]}>
          <Controller
            as={InputComponent}
            name="title"
            title="Title"
            control={control}
            removeMarginPadding
            inputStyle={errors.title && { borderColor: luxColors.error.primary }}
            disabled={isFormDisabled}
          />
          {
            errors.title &&
              <DNAText style={{ color: luxColors.error.primary, paddingLeft: 25 }}>
                {errors.title.message}
              </DNAText>
          }
        </Box>
      </Box>

      <ScrollView style={styles.ScrollViewContainer} ref={formScrollPanel}>
        <Box style={styles.OptionsContainer}>
          {/* LEFT SIDE */}
          <Box style={styles.ColumnSize}>
            <Box>
              <Controller
                control={control}
                name="purpose"
                render={({ onChange, value }) => (
                  <RadioInputComponent
                    title="PURPOSE"
                    required={true}
                    descriptionText={
                      'Expected use, and handling purpose designated for this file.\n' +
                      '  EP: Can be presented to external audience, in proactive setting\n' +
                      '  ER: Can be presented to external audience, in reactive setting\n' +
                      '  INT: Cannot be shared outside of the company'
                    }
                    values={['External - Proactive', 'External - Reactive', 'Internal Use Only']}
                    selectedValue={value}
                    disabled={isFormDisabled}
                    onChangeSelection={(e) => {
                      if (e === 'Internal Use Only') { control.setValue('canHideSlides', false) }
                      onChange(e)
                      // [TODO] - Consider watching the value instead of refreshing the entire form
                      control.updateFormState()
                    }}
                  />
                )}
                title="Purpose"
              />
            </Box>
            <Box style={[styles.RadioContainer, { marginTop: 14, marginBottom: 14 }]}>
              {/* PERMISSIONS - TITLE */}
              <Text style={[styles.Title, { paddingBottom: 15, paddingLeft: 4 }]}>{'File Permissions:'}</Text>
              <DNABox appearance="col" spacing="medium">
                <DNABox spacing="small">
                  <Controller
                    name="canHideSlides"
                    control={control}
                    render={({ onChange, value }) => {
                      return (
                        <DNACheckbox
                          checked={value}
                          disabled={isFormDisabled || cond.isPurposeInternalOnly}
                          onChange={e => onChange(e)}
                        />
                      )
                    }}
                  />
                  <Box style={styles.TitleContainer}>
                    <DNAText>File is modifiable</DNAText>
                    <Popover>
                      <Popover.Anchor>
                        <Icon style={styles.HelpToolTipIconStyle} name="help-circle-outline" />
                      </Popover.Anchor>
                      <Popover.Content>
                        <Text style={{ color: 'white' }}>
                          {'Enabling this option allows your audience to hide slides or pages within ' +
                            'this file\nas well as use this file to build personalized presentations ' +
                            'if your organization\noffers this feature.'}
                        </Text>
                      </Popover.Content>
                    </Popover>
                  </Box>
                </DNABox>
                <Iffy is={enableDistributable}>
                  <DNABox spacing="small">
                    <Controller
                      name="distributable"
                      control={control}
                      render={({ onChange, value }) => {
                        return (
                          <DNACheckbox
                            checked={value}
                            // [TODO-2126] - Fix disabled style via LUX-UI theming probably
                            //               Currently, it is showing no checkbox (instead of empty outline)
                            disabled={isFormDisabled}
                            onChange={e => {
                              // [TODO-2126] - Probably a better way to do this, like by observing the value and using a useEffect
                              send({
                                type: 'SYNC_DISTRIBUTABLE',
                                payload: { versionForm: { distributable: e } },
                              })
                              onChange(e)
                            }}
                          />
                        )
                      }}
                    />
                    <Box style={styles.TitleContainer}>
                      <DNAText>File is distributable</DNAText>
                      <Popover>
                        <Popover.Anchor>
                          <Icon style={styles.HelpToolTipIconStyle} name="help-circle-outline" />
                        </Popover.Anchor>
                        <Popover.Content>
                          <Text style={{ color: 'white' }}>
                            {'This determines whether a file can be distributed outside of the company.'}
                          </Text>
                        </Popover.Content>
                      </Popover>
                    </Box>
                  </DNABox>
                </Iffy>
                <DNABox spacing="small">
                  <Controller
                    name="downloadable"
                    control={control}
                    render={({ onChange, value }) => {
                      return (
                        <DNACheckbox
                          checked={value}
                          disabled={isFormDisabled}
                          onChange={e => onChange(e)}
                        />
                      )
                    }}
                  />
                  <DNAText>File is downloadable by viewers</DNAText>
                </DNABox>
              </DNABox>
            </Box>
            <Box>
              <Controller
                name="shortDescription"
                title="Short Description"
                control={control}
                as={InputComponent}
                required={true}
                hideLabel={false}
                multiline={true}
                numOfLines={2}
                characterLimit={250}
                disabled={isFormDisabled}
                showCharacterCounter
                inputStyle={errors.shortDescription && { borderColor: luxColors.error.primary }}
              />
              {
                errors.shortDescription &&
                  <DNAText style={{ color: luxColors.error.primary, paddingLeft: 25 }}>
                    {errors.shortDescription.message}
                  </DNAText>
              }
            </Box>
            <Box>
              <Controller
                collapsible
                name="longDescription"
                title="Long Description"
                control={control}
                as={InputComponent}
                required={false}
                multiline={true}
                hideLabel={false}
                numOfLines={4}
                characterLimit={1000}
                showCharacterCounter
                disabled={isFormDisabled}
                initCollapsed={true}
                // [TODO-2126] - Identify reason paddingLeft does not work by itself here
                buttonStyle={{ paddingHorizontal: 10, paddingLeft: 0 }}
              />
            </Box>
            <Box ref={calendarContainerRef}>
              <Controller
                control={control}
                name="expiresAt"
                title="Expiration Date"
                style={errors.expiresAt && { borderColor: luxColors.error.primary }}
                render={props => (
                  <DateComponent
                    title="Expiration Date"
                    selectedDate={formatted.selectedDate}
                    onDateSelected={value => props.onChange(value)}
                    onShowCalendar={onShowCalendar}
                    disabled={isFormDisabled}
                    descriptionText=
                    "This is the expected document expiration date, typically one year from approval date."
                  />
                )}
              />
              {
                errors.expiresAt &&
                  <DNAText style={{ color: luxColors.error.primary, paddingLeft: 25 }}>
                    {errors.expiresAt.message}
                  </DNAText>
              }
            </Box>
            <Iffy is={docVersion.meta.integration.source}>
              <Box>
                <Controller
                  name="contentSource"
                  title="Content Source"
                  control={control}
                  as={InputComponent}
                  required={false}
                  hideLabel={false}
                  numOfLines={1}
                  disabled={true}
                  defaultValue={docVersion.meta.integration.source}
                  inputStyle={errors.shortDescription && { borderColor: luxColors.error.primary }}
                />
              </Box>
            </Iffy>
            <Box>
              <Controller
                name="owner"
                title="Business Owner"
                control={control}
                style={errors.owner && { boderColor: luxColors.error.primary }}
                render={props => (
                  <GroupSelectInputComponent
                    title="Business Owner"
                    required={true}
                    availableValues={formatted.publishersFormatted}
                    onChangeSelection={props.onChange}
                    disabled={isFormDisabled}
                    value={props.value}
                  />
                )}
              />
              {
                errors.owner &&
                  <DNAText style={{ color: luxColors.error.primary, paddingLeft: 25 }}>
                    {errors.owner.message}
                  </DNAText>
              }
            </Box>
          </Box>

          {/* COLUMN DIVIDER */}
          <DNADivider vertical style={styles.ColumnDivider} />

          {/* RIGHT SIDE */}
          <Box style={styles.ColumnSize}>
            <Box>
              {docVersion &&

                <Controller
                  name={'fieldLabels'}
                  title={'Field Labels'}
                  control={control}
                  render={props => (
                    <>
                      {
                        formatted.customFieldsLabels.map(([fieldName]) => (
                          <React.Fragment key={fieldName}>
                            <CustomField
                              onChangeSelection={(fieldName, fieldValues) => {
                                props.onChange({ ...props.value, [fieldName]: fieldValues })
                              }}
                              fieldName={fieldName}
                              scrollFormView={scrollForm}

                              value={props.value[fieldName]}
                              disabled={isFormDisabled}
                              fieldConfig={docVersion?.meta.tenantFields.configsMap[fieldName]}
                              validationError={errors[fieldName]}
                            />
                            {
                              errors.fieldLabels && errors.fieldLabels[fieldName] &&
                                <DNAText style={{ color: luxColors.error.primary, paddingLeft: 25 }}>
                                  {
                                  errors.fieldLabels[fieldName].type === 'nonempty_array_is_empty'
                                    ? 'This field is required'
                                    : errors.fieldLabels[fieldName].message
                                }
                                </DNAText>
                            }
                          </React.Fragment>
                        ))
                      }
                    </>
                  )}
                />
              }
            </Box>
          </Box>
        </Box>
      </ScrollView>
    </DNABox>
  )
})

export default DocumentInfo
