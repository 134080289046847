import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import {
  DNABox,
  DNAButton,
  DNAText,
  Popover,
} from '@alucio/lux-ui'
import { colors } from '@alucio/lux-ui/src/theming/themes/alucio/colors'

const styles = StyleSheet.create({
  offlineContent: {
    backgroundColor: colors['text-basic'],
    borderRadius: 8,
    padding: 24,
    marginRight: 24,
    borderColor: colors['brand-compliment-100'],
    shadowColor: colors['brand-compliment-100'],
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 6,
  },
  title: {
    paddingBottom: 16,
  },
})

const NetwrokOfflineButton: React.FC = () => {
  const [contentVisible, setContentVisible] = useState<boolean>(false);
  const toggleContent = () => setContentVisible(p => !p)

  return (
    <Popover
      lazyMount
      placement="bottom-end"
      interactive={true}
      visible={contentVisible}
      onBackdropPress={toggleContent}
      type="menu"
      style={{ marginRight: 40 }}
    >
      <Popover.Anchor style={{ height: '100%' }}>
        <DNAButton
          onPress={toggleContent}
          status="warning"
          context="roundTablet"
          appearance="ghost"
          size="xlarge"
          iconLeft="wifi-off"
          style={{ paddingLeft: 10 }}
        />
      </Popover.Anchor>
      <Popover.Content offset={10} style={{ marginRight: 40 }}>
        <NetworkOfflineContent />
      </Popover.Content>
    </Popover>
  )
}

export const NetworkOfflineContent: React.FC = () => {
  return (
    <DNABox appearance="row" fill>
      <DNABox appearance="col" style={styles.offlineContent}>
        <DNAText b1 bold style={styles.title}>No internet connection</DNAText>
        <DNAText b1>Some features will be unavailable in offline mode.</DNAText>
      </DNABox>
    </DNABox>
  )
}

export default NetwrokOfflineButton
