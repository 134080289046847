import React from 'react'
import { useDispatch } from 'react-redux'
import { Meeting } from '@alucio/aws-beacon-amplify/src/models';
import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui';
import { meetingActions } from 'src/state/redux/slice/meeting';
import DNACommonConfirmation from './DNACommonConfirmation';

interface DeleteConfirmationProps {
  meeting: Meeting,
  onDelete?: () => void,
}

const DNAMeetingDeleteModal = (props: DeleteConfirmationProps) => {
  const { meeting, onDelete } = props
  const dispatch = useDispatch()
  const toast = useToast();

  const onConfirm = () => {
    dispatch(meetingActions.deleteMeeting(meeting))
    toast?.add(
      <GenericToast title="Meeting deleted" status="error" />,
      ToastOrientations.TOP_RIGHT,
    )
    onDelete?.()
  }

  return (<DNACommonConfirmation
    status="danger"
    cancelText="Cancel"
    confirmActionText="Delete"
    onConfirmAction={onConfirm}
    title="Delete meeting?"
  />)
}

export default DNAMeetingDeleteModal
