import React, { ReactElement, useMemo, useEffect, useState } from 'react';
import { RootState } from 'src/state/redux/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  DNABox,
  GenericToast,
  useToast,
  ToastOrientations,
  Iffy,
  useDisableSwipe,
} from '@alucio/lux-ui';
import {
  AppStateProvider,
  generateConnectionOptions,
  VideoProvider,
  useAppState,
  VirtualLayout,
  MODE_TYPE,
  useVideoContext,
} from '@alucio/video';
import { Logger } from '@aws-amplify/core';
import { VirtualPresentationContext, usePresentationContext } from '@alucio/core'
import MyContentPanel from '../../components/MyContentPanel/MyContentPanel';
import { virtualActions } from '../../state/redux/slice/virtual'
import { useCurrentUser } from '../../state/redux/selector/user';
import { ATTENDEE_STATUS } from '@alucio/aws-beacon-amplify/src/API';
import subSeconds from 'date-fns/subSeconds'
import { User } from '@alucio/aws-beacon-amplify/src/models';
import SideSlide from './SideSlide/SideSlide';
import PresenterNotes from './PresenterNotes/PresenterNotes';
import Presentation, { ControlsMode } from './Presentation/Presentation';
import { useContentViewerModalState } from '../../components/ContentPreviewModal/state/ContentViewerModalStateProvider';
import { useAppSettings } from 'src/state/context/AppSettings';
import config from 'src/config/app.json';
import TextSearchPanel from 'src/components/ContentPreviewModal/TextSearchPanel';

const logger = new Logger('VIRTUAL', 'INFO');

const soundOne = require('../../../assets/sounds/chords.mp3')

const audioFiles = [
  soundOne,
]

interface VirtualProps {
  onClose: (isVisible: boolean) => void;
}
interface LayoutProps {
  userName: string,
  room: string,
  onCallEnded: () => void
}

function Layout(props: LayoutProps) {
  useDisableSwipe()
  const dispatch = useDispatch();
  const { dispatch: dispatchAppSettings } = useAppSettings();
  const {
    activeSlide,
    activeDocVersion,
    setActiveSlide,
    setActiveDoc,
    customDeck,
    setFolderItemORM,
    visibleSearch,
    setVisibleSearch,
    visiblePages,
  } = useContentViewerModalState();
  const { userProfile: user } = useCurrentUser();
  const virtual = {
    attendeeId: 'host',
    ...useSelector((state: RootState) => state.virtual.virtualAttendee),
  };
  const currentAttendees = useSelector((state: RootState) => state.virtual.attendees);
  const [displayContentPanel, setDisplayContentPanel] = useState<boolean>(false);
  const isPresenting = !!activeSlide && (!!activeDocVersion || !!customDeck);
  const [displayNotes, setDisplayNotes] = useState<boolean>(true);
  const MemoizedSideSlideWrapper =
    useMemo(() => sideSlideWrapper(), [activeDocVersion, activeSlide, customDeck]);
  const MemoizedNotesChatWrapper =
    useMemo(() => notesChatWrapper(), [isPresenting, displayNotes]);
  const MemoizedPresentation = useMemo(() => presentationContent(), [isPresenting]);
  const addGap = displayNotes && isPresenting;
  const toast = useToast()
  const { room } = useVideoContext()
  const presentationContext = usePresentationContext()
  const [isScreenSharing, setIsScreenSharing] = useState<boolean>(false);

  useEffect(() => {
    dispatchAppSettings({
      type: 'setIsIdleDisabled',
      payload: isScreenSharing,
    });
  }, [isScreenSharing]);

  useEffect(() => {
    if (!isPresenting && !isScreenSharing) {
      presentationContext.dispatch({
        type: 'stopPresenting',
        payload: undefined,
      })
    } else if (isScreenSharing) {
      presentationContext.dispatch({
        type: 'startScreenShare',
        payload: undefined,
      })
    }
  }, [isPresenting, isScreenSharing])

  function showContentPanel(): void {
    setDisplayContentPanel(true);
  }

  function hideContentPanel(): void {
    setDisplayContentPanel(false);
  }

  function getCurrentAttendeees(timeStamp: string, status: ATTENDEE_STATUS) {
    dispatch(virtualActions.getCurrentAttendees({
      hostId: {
        eq: user?.id,
      },
      lastSeenAt: {
        gt: timeStamp,
      },
      status: {
        eq: status,
      },
    }));
  }

  function initializeMeeting(): void {
    dispatch(virtualActions.getToken());
  }

  function acceptCall(attendeeId, identity): void {
    dispatch(virtualActions.updateAttendeeStatus({
      attendeeId: attendeeId,
      sessionId: virtual.session.id,
      status: ATTENDEE_STATUS.ACCEPTED,
      identity,
    }))
  }
  function attendeeConnected(attendeeId, identity): void {
    dispatch(virtualActions.updateAttendeeStatus({
      attendeeId: attendeeId,
      sessionId: virtual.session.id,
      status: ATTENDEE_STATUS.CONNECTED,
      identity,
    }))
  }
  function attendeeDisconnected(attendeeId, identity): void {
    const isPhone = identity.split('.')[1] === 'phone';
    let attendeeHasPhoneTrack;

    if (!isPhone) {
      // If it's not phone but there's a phone track, it means the video got disconnected.
      // We don't want to disconnect the participant if that's the case so we keep the audio track.
      room.participants.forEach((participant) => {
        if (participant.identity.startsWith(`${attendeeId}.phone`)) {
          attendeeHasPhoneTrack = true;
        }
      });
    }

    // We only want to do these updates if the video attendee leaves
    if (!isPhone && !attendeeHasPhoneTrack) {
      const toastName = (identity as string).split('.')[2];
      const id = (identity as string).split('.')[0];
      id !== 'recorder' && toast.add(<GenericToast
        title={`${toastName.length > 12 ? toastName.substring(0, 11) + '...' : toastName} has left the room.`}
        status="information"
      />, ToastOrientations.TOP_RIGHT_MEETINGS)
    }
  }

  function onRemoveFromCall(attendeeId, identity): void {
    dispatch(virtualActions.updateAttendeeStatus({
      attendeeId: attendeeId,
      sessionId: virtual.session.id,
      status: ATTENDEE_STATUS.REMOVED,
      identity,
    }))
  }
  function denyCall(attendeeId, identity): void {
    dispatch(virtualActions.updateAttendeeStatus({
      attendeeId: attendeeId,
      sessionId: virtual.session.id,
      status: ATTENDEE_STATUS.DECLINED,
      identity,
    }))
  }
  function onLeaving(): void {
    logger.debug('onLeaving Called')
    // This handler is called when the host hits the end call button
    // To prevent issue /w race conditions writing to the session object
    // we first save the meeting log and then call endCall
    // endCall will also cause a disconnect of the host from twilio which
    // will then call onDisconnect to be called
    const endCallHandler = () => {
      logger.debug('onLeaving.endCallHandler Called')
      dispatch(virtualActions.endCall({ id: virtual.session.id }));
      dispatch(virtualActions.setSession({}));
      dispatch(virtualActions.unsubscribe({}));
    }

    if (isScreenSharing) {
      dispatchAppSettings({
        type: 'setIsIdleDisabled',
        payload: false,
      });
    }

    presentationContext.dispatch({
      type: 'saveMeetingLog',
      payload: endCallHandler,
    })
  }

  function onDisconnect(): void {
    logger.debug('onDisconnect Called')
    analytics?.track('VIRTUAL_MEETING_ENDED', {
      action: 'MEETING_ENDED',
      category: 'VIRTUAL',
      sessionId: virtual.session.id,
    });
    props.onCallEnded();
  }

  function onHostConnected(): void {
    const last8Seconds = subSeconds(new Date(), 8).toISOString();
    if (user?.id) {
      dispatch(virtualActions.subscribeToVirtualAttendeeEvents({ hostId: user?.id }));
      analytics?.track('VIRTUAL_MEETING_STARTED', {
        action: 'MEETING_STARTED',
        category: 'VIRTUAL',
        sessionId: virtual.session.id,
      });

      getCurrentAttendeees(last8Seconds, ATTENDEE_STATUS.PENDING);
    }
  }

  function onToggleShowNotes(): void {
    setDisplayNotes(!displayNotes);
  }

  function onStopPresenting(): void {
    setActiveSlide(undefined);
    setActiveDoc(undefined);
    setFolderItemORM(undefined);
  }

  function onError(error: string, isFatal: boolean) {
    if (isFatal) {
      toast.add(<GenericToast
        title={error}
        status="error"
      />, ToastOrientations.TOP_RIGHT, true, 0)
      props.onCallEnded();
    } else {
      toast.add(<GenericToast
        title={error}
        status="error"
      />, ToastOrientations.TOP_RIGHT_MEETINGS)
    }
  }

  function sideSlideWrapper(): ReactElement | null {
    return (
      <Iffy is={isPresenting}>
        <SideSlide />
      </Iffy>
    );
  }

  function notesChatWrapper(): ReactElement | null {
    if (!isPresenting) {
      return null;
    }

    return (
      <Iffy is={isPresenting}>
        <DNABox appearance="col" fill>
          <Iffy is={displayNotes}>
            <PresenterNotes />
          </Iffy>
        </DNABox>
      </Iffy>
    );
  }

  const MemoizedMyContentPanel = useMemo(() => (
    <MyContentPanel
      variant={'Virtual'}
      sessionId={virtual.session.id}
      addGap={!!addGap}
      displayContentPanel={displayContentPanel}
      onClosePanel={hideContentPanel}
    />
  ), [addGap, displayContentPanel, hideContentPanel, virtual.session.id]);

  function presentationContent(): ReactElement {
    return (
      <Iffy is={isPresenting}>
        <Presentation ControlsMode={ControlsMode.Above} onStopPresenting={onStopPresenting} />
      </Iffy>
    );
  }

  return (
    <VirtualLayout
      audioFiles={audioFiles}
      setIsScreenSharing={setIsScreenSharing}
      attendeesPerPage={5}
      background={{ uri: require('../../../assets/images/sharingPlaceholder.jpg') }}
      currentAttendees={currentAttendees}
      currentUser={user as User}
      deniedMediaImage={require('../../../assets/images/AllowMedia.svg')}
      dialInNumbers={config.virtualDialInNumbers}
      displayContentPanel={displayContentPanel}
      hostName={`${user?.givenName} ${user?.familyName}`}
      meetingURL={`https://${config.virtualURL}/${user?.meetingId}`}
      headerBackground={{ uri: require('../../../assets/images/MoleculesRight.svg') }}
      isHostPresenting={!!isPresenting}
      leftSide={MemoizedSideSlideWrapper}
      mode={MODE_TYPE.HOST}
      myContentPanel={MemoizedMyContentPanel}
      onAcceptCall={acceptCall}
      onAttendeeConnected={attendeeConnected}
      onStopPresenting={onStopPresenting}
      hideContentPanel={hideContentPanel}
      onRemoveFromCall={onRemoveFromCall}
      onAttendeeDisconnected={attendeeDisconnected}
      onConnected={onHostConnected}
      onJoinMeeting={initializeMeeting}
      onError={onError}
      onDenyCall={denyCall}
      onDisconnect={onDisconnect}
      onLeaving={onLeaving}
      presentation={MemoizedPresentation}
      recImage={require('../../../assets/images/Icons/Record.svg')}
      rightSide={MemoizedNotesChatWrapper}
      showContentPanel={showContentPanel}
      toggleHostNotes={onToggleShowNotes}
      virtual={virtual}
      // eslint-disable-next-line max-len
      searchPanel={<TextSearchPanel documentVersion={activeDocVersion} onClose={() => setVisibleSearch(false)} visiblePages={visiblePages} />}
      visibleSearch={visibleSearch}
      setVisibleSearch={setVisibleSearch}
    />
  )
}

function Providers(props) {
  const { setError, settings } = useAppState();
  const connectionOptions = generateConnectionOptions(settings);

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <VirtualPresentationContext isHost={true}>
        <Layout {...props} onEndCall={props.onEndCall} />
      </VirtualPresentationContext>
    </VideoProvider>
  );
}
function VirtualExchange(props: VirtualProps) {
  return (
    <AppStateProvider settings={{ maxTracks: '6' }}>
      <Providers onCallEnded={props.onClose} />
    </AppStateProvider>
  )
}
export default VirtualExchange;
