import React, { useState } from 'react';
import { DNABox, Iffy, Stack, useMouseEvent } from '@alucio/lux-ui';
import InPersonControls from './InPersonControls';
import SlideRollWrapper from '../SharedComponents/SlideRollWrapper';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import PlayerWrapper from 'src/components/Presentation/PlayerWrapper'
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import { ImageBackground } from 'react-native';

const InPersonContent = () => {
  const { activePresentation } = useContent();
  const [displaySlideRoll, setDisplaySlideRoll] = useState<boolean>(false);
  const handleControlsVisibility = (e: MouseEvent | Event | TouchEvent) => {
    if (e instanceof PointerEvent) {
      setDisplaySlideRoll(false);
    }
  }
  useMouseEvent(handleControlsVisibility);
  const { meetingORM } = useMeetingsState()

  if (!meetingORM) return null

  return (
    <DNABox fill>
      <Stack
        anchor="bottom"
        style={{
          backgroundColor: 'black',
          flex: 1,
          width: '100%',
          height: '100%',
        }}
      >
        {/* CONTENT */}
        <Stack.Layer
          style={{
            flex: 1,
            width: '100%',
            height: '100%',
          }}
        >
          <DNABox fill style={{ position: 'absolute', width: '100%', height: '100%' }}>
            <Iffy is={!activePresentation}>
              <ImageBackground
                source={require('../../../../assets/images/beacon-presentation-bg.jpg')}
                style={{ flex: 1 }}
              />
            </Iffy>

            <Iffy is={activePresentation}>
              <PlayerWrapper isFullWindow={true} meetingId={meetingORM.model.id} mode="INTERACTIVE" />
            </Iffy>
          </DNABox>
        </Stack.Layer>
        {/* SLIDE ROLL */}
        <Stack.Layer anchor="topLeft" style={{ height: '100%' }}>
          <Iffy is={activePresentation}>
            <DNABox style={{ display: displaySlideRoll ? 'flex' : 'none' }} fill>
              <SlideRollWrapper />
            </DNABox>
          </Iffy>
        </Stack.Layer>
        {/* CONTROLS */}
        <Stack.Layer anchor="bottomRight">
          <InPersonControls setDisplaySlideRoll={setDisplaySlideRoll} />
        </Stack.Layer>
      </Stack>
    </DNABox>
  );
};

InPersonContent.displayName = 'InPersonContent';

export default InPersonContent;
