import React from 'react';
import { View } from 'react-native';
import { luxColors, responsive } from '@alucio/lux-ui';
import { cleanDisconnectedDue } from 'src/components/Authenticator/DisconnectedDue';
import useRetryOnErrorsSSO from './components/DNA/hooks/useRetryOnSSoError';
import { SearchTextContextProvider } from './components/Header/SearchInput/SearchInput';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useCurrentPage from './components/DNA/hooks/useCurrentPage';

const styles = {
  AppContainer: {
    backgroundColor: luxColors.applicationBackground.primary,
    flex: 1,
    height: responsive.dimensions.trueHundredHeight,
    maxHeight: '-webkit-fill-available',
  },
};

const AppContainer = (props) => {
  cleanDisconnectedDue();
  useRetryOnErrorsSSO();
  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const viewStyle = [
    styles.AppContainer,
    isPublisherRoute && { backgroundColor: colors['color-text-basic'] },
  ]

  return (
    <SearchTextContextProvider>
      <View style={viewStyle}>
        {props.children}
      </View>
    </SearchTextContextProvider>
  )
}
export default AppContainer;
