import React, { useEffect, useState, useRef, useCallback, Fragment } from 'react';
import { DNABox, DNAButton, DNADivider, DNAIcon, DNAText, Icon, Iffy } from '@alucio/lux-ui';
import InputComponent from 'src/components/Publishers/InputComponent';
import useFileTextSearch, { MatchedPage, PageData } from './useFileTextSearch';
import { FlatList, Pressable, StyleSheet, TouchableOpacity } from 'react-native';

import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useContentViewerModalState } from '../state/ContentViewerModalStateProvider';
import { useDispatch } from 'react-redux';
import { presentationControlActions } from 'src/state/redux/slice/PresentationControl/presentationControl';
import { PlayerActions } from '@alucio/video';
import { DocumentVersionORM, PageExtended } from 'src/types/types';
import { fetchJsonFromCloudfront } from 'src/utils/loadCloudfrontAsset/common';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import debounce from 'lodash/debounce';

const styles = StyleSheet.create({
  searchBar: {
    backgroundColor: colors['color-text-basic'],
    borderColor: colors['color-flat-400'],
    borderRadius: 4,
    width: 252,
    overflow: 'hidden',
  },
  subtitle: {
    color: colors['color-flat-500'],
  },
  menuButtonContainer: {
    borderColor: colors['color-flat-400'],
    borderRadius: 4,
    borderWidth: 1,
    marginRight: 16,
  },
  searchIcon: {
    color: colors['color-flat-500'],
    height: 16,
    width: 16,
  },
  thumbnail: {
    marginLeft: 16,
    marginRight: 8,
    marginVertical: 8,
    height: 54,
    width: 96,
  },
  mainContainer: {
    borderColor: colors['color-flat-400'],
    borderRightWidth: 1,
    width: 332,
    backgroundColor: colors['color-text-basic'],
  },
})

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const processText = (text : string, shouldBeInBold: string) => {
  const parts = text.split(new RegExp(`(${escapeRegExp(shouldBeInBold)})`, 'gi'));
  return (
    <>
      {parts.map((item, index) => (
        <DNAText key={index} bold={item.toLowerCase() === shouldBeInBold.toLowerCase()}>{item}</DNAText>
      ),
      )}
    </>
  )
}

interface SearchResultsItemProps {
  item: MatchedPage,
  folderKey: string,
  searchQuery: string,
}
const SearchResultsItem: React.FC<SearchResultsItemProps> = React.memo(({ item, folderKey, searchQuery }) => {
  const matches = item.matches.slice(0, 3); // render only up to 3 matches
  return (
    <DNABox appearance="row">
      <DNABox style={styles.thumbnail}>
        <DNAThumbnail
          s3URL={`${folderKey}${item.pageNumber}_small_thumb.png`}
          height={54}
          width={96}
        />
      </DNABox>
      <DNABox fill appearance="col">
        <DNABox appearance="row" spacing="between" style={{ marginTop: 8, marginRight: 16 }}>
          <DNAText bold>Slide {item.labelNumber}</DNAText>
          <DNAText style={styles.subtitle}>
            {item.matches.length} match{item.matches.length > 1 ? 'es' : ''}
          </DNAText>
        </DNABox>
        {matches.map((match, index) => {
          return (
            <Fragment key={`slide-${item.pageNumber}-match-${index}`}>
              <DNAText style={{ marginBottom: 8, marginRight: 16 }} numberOfLines={3}>
                {processText(match, searchQuery)}
              </DNAText>
              <Iffy is={index < item.matches.length - 1}>
                <DNADivider />
              </Iffy>
            </Fragment>
          )
        })}
      </DNABox>
    </DNABox>
  )
});

interface SearchResultsProps {
  matches: MatchedPage[],
  searchQuery: string,
  folderKey: string,
  activeIndex: number,
  setActiveIndex: (index: number) => void,
}

const SearchResults: React.FC<SearchResultsProps> = React.memo((
  { matches, searchQuery, folderKey, activeIndex, setActiveIndex }) => {
  const { setActiveSlide } = useContentViewerModalState();
  const dispatch = useDispatch();
  const flatlistRef = useRef<FlatList<MatchedPage> | null>(null);

  useEffect(() => {
    if (flatlistRef.current && matches.length && activeIndex > -1) {
      flatlistRef.current.scrollToIndex({ animated: true, index: activeIndex })
    }
  }, [activeIndex]);

  return (
    <FlatList
      data={matches}
      keyExtractor={(item) => `slide-${item.pageNumber}`}
      renderItem={({ item, index }) => {
        return (
          <Pressable
            onPress={() => {
              setActiveIndex(index);
              setActiveSlide(item.pageNumber);
              dispatch(presentationControlActions.triggerPresentationAction({
                action: PlayerActions.gotoSlide,
                param: item.pageNumber,
              }))
            }}
            style={{ backgroundColor : activeIndex === index ? colors['color-flat-300'] : undefined }}
          >
            <SearchResultsItem item={item} folderKey={folderKey} searchQuery={searchQuery} />
          </Pressable>
        )
      }}
      style={{ height: 300, overflow: 'scroll' }}
      ref={flatlistRef}
      ItemSeparatorComponent={DNADivider}
    />
  )
});
interface TextSearchPanelProps {
  documentVersion: DocumentVersionORM
  onClose: () => void,
  visiblePages: PageExtended[],
}
const TextSearchPanel: React.FC<TextSearchPanelProps> = React.memo(({ documentVersion, onClose, visiblePages }) => {
  const [pages, setPages] = useState<PageData[] | []>([]);
  const [thumbnailKey, setThumbnailKey] = useState<string>('');
  const {
    matches,
    searchQuery,
    search,
    activeIndex,
    setActiveIndex,
    totalMatchCount,
  } = useFileTextSearch(pages, visiblePages, documentVersion);
  const { searchText, setSearchText } = useContentViewerModalState();
  const [, refresh] = useState(false);

  useEffect(() => {
    const loadJson = async () => {
      const tenantId = documentVersion.model.tenantId;
      const documentId = documentVersion.model.documentId;
      const modelId = documentVersion.model.id;
      // TODO: update key to documentVersion.model.convertedFolderKey/json/ after clean up for v2
      const res = await fetchJsonFromCloudfront(`${tenantId}/${documentId}/${modelId}/v2/json/Pages.json`);
      setThumbnailKey(`${tenantId}/${documentId}/${modelId}/v2/thumbnails/`);
      const json = await res.json();
      setPages(json.pages)
    }
    if (documentVersion) {
      loadJson();
    }
  }, [documentVersion])

  const getMatchNumber = useCallback(() => {
    let num = 1;
    for (let i = 0; i < activeIndex; i++) {
      num += matches[i].matches.length;
    }
    return num;
  }, [matches, activeIndex]);

  useEffect(() => {
    if (searchText) {
      debouncedSearch(searchText);
    }
    else {
      search(searchText);
    }
  }, [searchText])

  const debouncedSearch = useCallback(debounce((text) => {
    search(text);
    refresh(false); // this is a work around for 'No results found' briefly flashing
  }, 500), [])

  return (
    <DNABox
      appearance="col"
      style={styles.mainContainer}
    >
      <DNABox appearance="row" style={{ marginLeft: -8 }}>
        <InputComponent
          value={searchText}
          onChangeText={setSearchText}
          required={false}
          size="small"
          autoFocus={true}
          placeHolder="Search"
          inputStyle={styles.searchBar}
          getLeftIconFunction={() => (
            <TouchableOpacity>
              <Icon style={styles.searchIcon} name="magnify" />
            </TouchableOpacity>
          )
        }
          getRightIconFunction={() => (
            <Iffy is={searchText}>
              <Pressable onPress={() => setSearchText('')}>
                <DNAIcon.Styled
                  name="close-circle"
                  appearance="ghost"
                  status="flat"
                  context="round"
                />
              </Pressable>
            </Iffy>
          )
      }
        />
        <DNAButton
          appearance="outline"
          status="info"
          context="round"
          iconLeft="close"
          onPress={onClose}
          style={{ marginTop: 16, marginRight: 16, height: 32, width: 32 }}
        />
      </DNABox>
      <Iffy is={searchQuery.length >= 3}>
        <DNABox appearance="row" alignY="center" style={{ marginHorizontal: 16, marginBottom: 16 }}>
          <DNABox style={styles.menuButtonContainer}>
            <DNAButton
              appearance="ghost"
              status="subtle"
              context="square"
              size="small"
              iconLeft="menu-up"
              onPress={() => setActiveIndex((prev) => Math.max(0, prev - 1))}
              disabled={!matches.length || activeIndex < 0}
            />
            <DNADivider vertical />
            <DNAButton
              appearance="ghost"
              status="subtle"
              context="square"
              size="small"
              iconLeft="menu-down"
              onPress={() => setActiveIndex((prev) => Math.min(matches.length - 1, prev + 1))}
              disabled={!matches.length}
            />
          </DNABox>
          {matches.length > 0 ? (
            <DNAText style={styles.subtitle}>
              {getMatchNumber()} of {totalMatchCount} match{totalMatchCount > 1 ? 'es' : ''}
            </DNAText>)
            : <DNAText style={{ color: colors['color-danger-500'] }}>No results found</DNAText>}
        </DNABox>
      </Iffy>
      <DNADivider />
      <SearchResults
        matches={matches}
        searchQuery={searchQuery}
        folderKey={thumbnailKey}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />

    </DNABox>
  )
});

export default TextSearchPanel;
