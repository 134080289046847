import React from 'react'
import { DocumentORM } from 'src/types/types'
import { DNACardComponent } from 'src/components/DNA/Grid/Cards/types'

import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions'

import {
  DNAButton,
  DNABox,
  DNAText,
  DNACard,
  DNADivider,
  Iffy,
  DNAChip,
  luxColors,
} from '@alucio/lux-ui'
import DNADocumentContextMenu from 'src/components/DNA/Document/DNADocumentContextMenu/DNADocumentContextMenu'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import { CUSTOM_DECK_CARD_MAX_HEIGHT } from './constants'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'

export const DNADocumentDefaultCard: DNACardComponent<DocumentORM> = (props) => {
  const { isDesktop, ORM: documentORM, isNetworkConnected, onPress } = props
  const documentActions = useDNADocumentActions()
  const isBookmarked = documentORM.meta.bookmark.isBookmarked;
  // [TODO-2126] - We should have a property that combines isOnline and isContentCached
  //               on the DocumentVersionORM rather than checking both at the component level
  const unavailableContent =
    !isNetworkConnected &&
    !documentORM.relations.version.cachedDocumentVersion?.meta.assets.isContentCached
  const canPresent = documentORM.meta.permissions.MSLPresent
  const safeOnPress = onPress
    ? () => { onPress?.(documentORM) }
    : undefined

  const thumbnailOnPress = canPresent
    ? documentActions.present(documentORM)
    : documentActions.preview(documentORM)
  const cardOnPress = documentActions.preview(documentORM)

  const route = useCurrentPage()
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')

  return (
    <DNABox
      alignX="start"
      style={{ minWidth: 272, height: CUSTOM_DECK_CARD_MAX_HEIGHT }}
    >
      <DNACard
        appearance={isDesktop ? 'float' : 'outline'}
        interactive="pressable"
        onPress={safeOnPress || cardOnPress}
        style={{ padding: 12 }}
      >
        <DNABox appearance="col" spacing="between" style={{ minHeight:'100%', opacity: unavailableContent ? 0.4 : 1 }}>
          <DNADocumentThumbnail
            documentVersionORM={
              documentORM.relations.version.latestUsableDocumentVersion
            }
            width={248}
            height={142}
            unavailableContent={unavailableContent}
            /** Custom behavior here. Thumbs in Publisher routes just open the preview modal,
            * whereas MSL views will either present or preview based on whether a doc is internal */
            onPress={
              isPublisherRoute ? safeOnPress || thumbnailOnPress : thumbnailOnPress
            }
          />
          <DNABox appearance="col" spacing="medium">
            <DNAText
              testID="document-card-title"
              style={{ width: 208, overflow: 'hidden', height: 38 }}
              numberOfLines={2}
            >
              {documentORM.relations.version.latestUsableDocumentVersion.model.title}
            </DNAText>
            <DNABox appearance="col" spacing="small">
              <DNADivider />
              <DNABox alignY="center" spacing="between">
                <DNADocumentChip item={documentORM} variant="purpose" />
                <Iffy is={unavailableContent}>
                  <DNAChip appearance="tag" style={{ backgroundColor: luxColors.basicBlack.primary }}>
                    CONTENT NOT AVAILABLE
                  </DNAChip>
                </Iffy>
                <DNABox>
                  <Iffy is={!unavailableContent}>
                    <DNAButton
                      status={!isDesktop && documentORM.meta?.bookmark?.isBookmarked ? 'primary' : 'subtle'}
                      appearance="ghostAlt"
                      context={isDesktop ? 'minimum' : 'roundTablet'}
                      onPress={documentActions.bookmark(documentORM)}
                      iconLeft={isBookmarked ? 'bookmark' : 'bookmark-outline' }
                      size={isDesktop ? undefined : 'xlarge'}
                      style={{ width:32, height:32 }}
                    />
                  </Iffy>
                  <DNADocumentContextMenu documentORM={documentORM}>
                    <DNAButton
                      status="subtle"
                      appearance="ghostAlt"
                      context={isDesktop ? 'minimum' : 'roundTablet'}
                      iconLeft="dots-vertical"
                      size={isDesktop ? undefined : 'xlarge'}
                      style={{ width:32, height:32 }}
                    />
                  </DNADocumentContextMenu>
                </DNABox>
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNACard>
    </DNABox>
  );
}

export default DNADocumentDefaultCard
