import {
  AssociatedFile,
  EmailTemplate,
  EmailTemplateStatus,
  LabelValue,
} from '@alucio/aws-beacon-amplify/src/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonReducers, initialState, SliceState } from './common';
import ActiveUser from '../../global/ActiveUser';
import { DataStore } from '@aws-amplify/datastore';

const sliceName = 'emailTemplate';
const { reducers, extraReducers } = commonReducers<EmailTemplate>(sliceName);

interface CreateEmailTemplatePayload {
  title: string;
  subject: string;
  cc: string[];
  bcc: string[];
  body: string;
  labelValues: LabelValue[];
  associatedFiles: AssociatedFile[];
  status: EmailTemplateStatus;
  callback: (emailTemplate: EmailTemplate) => void
}

const emailTemplateSlice = createSlice({
  name: sliceName,
  initialState: initialState<EmailTemplate>(),
  reducers: {
    ...reducers,
    create: (
      _state: SliceState<EmailTemplate>,
      action: PayloadAction<CreateEmailTemplatePayload>,
    ): void => {
      const { payload } = action
      const user = ActiveUser.user;

      if (!user) {
        throw new Error('Could not get current user')
      }

      DataStore.save(
        new EmailTemplate({
          tenantId: user.tenantId,
          title: payload.title,
          subject: payload.subject,
          cc: payload.cc,
          bcc: payload.bcc,
          body: payload.body,
          createdBy: user.id,
          updatedBy: user.id,
          labelValues: payload.labelValues,
          status: payload.status,
          editPermissions: [`TenantPublisher-${user.tenantId}`],
          associatedFiles: payload.associatedFiles,
        }),
      ).then(payload.callback);
    },
    deactivate: {
      prepare: (emailTemplate: EmailTemplate) => {
        analytics?.track('EMAIL_DEACTIVATE', {
          action: 'EMAIL_DEACTIVATE',
          category: 'EMAIL',
          emailTemplateId: emailTemplate?.id,
        });
        return {
          payload: {
            model: EmailTemplate,
            entity: emailTemplate,
            updates: { status: EmailTemplateStatus.DEACTIVATED },
          },
        }
      },
      reducer: reducers.save,
    },
    delete: {
      prepare: (emailTemplate: EmailTemplate) => {
        analytics?.track('EMAIL_DELETE', {
          action: 'EMAIL_DELETE',
          category: 'EMAIL',
          emailTemplateId: emailTemplate?.id,
        });
        return {
          payload: {
            model: EmailTemplate,
            entity: emailTemplate,
            updates: { status: EmailTemplateStatus.REMOVED },
          },
        }
      },
      reducer: reducers.save,
    },
  },
  extraReducers,
});

export default emailTemplateSlice;
export const emailTemplateActions = {
  ...emailTemplateSlice.actions,
};
