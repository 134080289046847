import React, { useMemo, useState, createContext, useContext } from 'react'
import im from 'immer'

import { Variant, DocumentORM, DocumentVersionORM } from 'src/types/types'
import { SortOptions, FilterAndSortOptions, multiTypeSort } from 'src/state/redux/selector/common'
import { useUserTenant } from 'src/state/redux/selector/user'
// [TODO] - Move this implementation out of GridList
import { getFieldConfigsForTenant, getSortConfigForFields } from 'src/components/DNA/GridList/common'
import { useAppSettings } from 'src/state/context/AppSettings'

export enum SortDir {
  asc = 'asc',
  desc = 'desc',
  none = 'none',
}

export enum SortIcons {
  asc = 'menu-up',
  desc = 'menu-down'
}

export enum SortFieldType {
  ver_model = 'ver_model',
  doc_model = 'doc_model',
  label = 'label'
}

export type SortEntry = {
  dir: SortDir,
  fieldType: SortFieldType
  fieldResolver?: (docVersion: DocumentVersionORM) => any,
  key: string,
  label: string,
  icon?: 'menu-down' | 'menu-up',
}

// [TODO]: Make this a generic to have typechecking on keys?
export type SortMap = {
  [key: string]: SortEntry
}

export interface SortContext {
  sorts: SortMap,
  activeSort?: SortEntry,
  sortSelectorOpts: FilterAndSortOptions<DocumentORM>,
  setSorts: (state: SortMap) => void,
  toggleSort: (targetKey: string) => void
}

const nextSortDir = {
  [SortDir.asc]: SortDir.desc,
  [SortDir.desc]: SortDir.asc,
  [SortDir.none]: SortDir.asc,
}

const SortContext = createContext<SortContext>({
  sorts: {},
  activeSort: undefined,
  sortSelectorOpts: {},
  setSorts: () => { },
  toggleSort: () => { },
})
SortContext.displayName = 'UseSortContext'

const SortContextProvider: React.FC = (props) => {
  const { children } = props
  const userTenant = useUserTenant()
  const { deviceMode } = useAppSettings()
  const isDesktop = deviceMode === 'desktop';
  const fieldConfigs = getFieldConfigsForTenant(userTenant, Variant.MSL, isDesktop)
  const [sorts, setSorts] = useState<SortMap>(getSortConfigForFields(fieldConfigs))

  const activeSort = useMemo(() => {
    let activeSort: (SortEntry | undefined)

    for (const sort in sorts) {
      if (sorts[sort].dir !== SortDir.none) {
        activeSort = sorts[sort]
        break; // Only returns the first active sort found
      }
    }

    return activeSort
  }, [sorts])

  const toggleSort = useMemo(
    () => {
      return (targetKey: string) => {
        setSorts(p => im(p, sortMap => {
          for (const sortKey in sortMap) {
            if (sortMap[sortKey].key === targetKey) {
              const currDir = sortMap[sortKey].dir
              const nextDir = nextSortDir[currDir]

              sortMap[sortKey] = {
                ...sortMap[sortKey],
                dir: nextDir,
                icon: SortIcons[nextDir],
              }
            } else {
              sortMap[sortKey].dir = SortDir.none
              sortMap[sortKey].icon = undefined
            }
          }
        }))
      }
    },
    [sorts],
  )

  // Selector filter
  const sortSelectorOpts = useMemo(() => {
    let sort: SortOptions<DocumentORM>[] = []

    if (activeSort) {
      switch (activeSort.fieldType) {
        case SortFieldType.label:
          sort = [{
            meta: {
              tenantFields: (a, b) => {
                return multiTypeSort(
                  a.valuesMap[activeSort?.key],
                  b.valuesMap[activeSort?.key],
                  { dir: activeSort.dir } as any,
                )
              },
            },
          }]
          break
        case SortFieldType.doc_model:
          sort = [{ model: { [activeSort.key]: activeSort.dir } }]
          break
        case SortFieldType.ver_model:
          sort = [{
            relations: {
              version: (a, b) => {
                if (activeSort.fieldResolver) {
                  return multiTypeSort(
                    activeSort.fieldResolver(a.latestDocumentVersion),
                    activeSort.fieldResolver(b.latestDocumentVersion),
                    { dir: activeSort.dir } as any,
                  )
                }

                return multiTypeSort(
                  a.latestUsableDocumentVersion.model[activeSort.key],
                  b.latestUsableDocumentVersion.model[activeSort.key],
                  { dir: activeSort.dir } as any,
                )
              },
            },
          }]
          break
      }
    }

    return { sort }
  }, [sorts])

  const value = {
    sorts,
    activeSort,
    sortSelectorOpts,
    setSorts,
    toggleSort,
  }

  return (
    <SortContext.Provider value={value}>
      {children}
    </SortContext.Provider>
  )
}

export const withSort = <T extends unknown = {}>(Component) => (props: T) => (
  <SortContextProvider>
    <Component {...props as T} />
  </SortContextProvider>
)

export const useSort = () => useContext(SortContext)
