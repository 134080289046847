import { DocumentVersion, Folder } from '@alucio/aws-beacon-amplify/src/models'
import { Middleware } from 'redux'

import { RootState } from 'src/state/redux/store'
import { sliceName as versionSliceName } from 'src/state/redux/slice/documentVersion'
import { sliceName as folderSliceName } from 'src/state/redux/slice/folder'
import { syncCacheManifestDebounced } from 'src/state/cache/syncManifests'
import PWALoaded from 'src/state/global/PWALoaded'

import PWALogger from 'src/worker/util/logger'
import { isIOS } from 'react-device-detect'

const cacheSyncMiddleware: Middleware<{}, RootState> = (storeApi) => {
  return next => action => {
    if (PWALoaded.isPWA) {
      // For any document version updates
      // [TODO-1837]: This is not updating, check why
      if (action.type === `${versionSliceName}/update` || action.type === `${versionSliceName}/upsert`) {
        const state = storeApi.getState()
        const versionSlice = state.documentVersion
        PWALogger.debug('Received notification of update of docVersion')
        if (versionSlice.hydrated) {
          const nextVersion = action.payload as DocumentVersion
          const prevVersion = versionSlice.records.find(version => version.id === nextVersion.id)

          if (prevVersion) {
            const isNewlyPublished = prevVersion.status === 'NOT_PUBLISHED' && nextVersion.status === 'PUBLISHED'
            if (isNewlyPublished) {
              PWALogger.debug('Starting sync due to updated version')
              // We don't auto-sync on iOS non-PWA
              syncCacheManifestDebounced(PWALoaded.isPWA || !isIOS)
            }
          }
        }
      }
      // For any folder updates
      else if (
        action.type === `${folderSliceName}/add` ||
        action.type === `${folderSliceName}/update` ||
        action.type === `${folderSliceName}/upsert`
      ) {
        const state = storeApi.getState()
        const folderSlice = state.folder

        if (folderSlice.hydrated) {
          const nextVersion = action.payload as Folder

          if (nextVersion.items.length) {
            PWALogger.debug('Starting sync due to new/updated folder items')
            // We don't auto-sync on iOS non-PWA
            syncCacheManifestDebounced(PWALoaded.isPWA || !isIOS)
          }
        }
      }
    }
    return next(action)
  }
}

export default cacheSyncMiddleware
