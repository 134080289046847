import React from 'react';
import { ImageStyle, Pressable } from 'react-native';
import { DNABox, DNAText } from '@alucio/lux-ui';
import { PresentablePage } from 'src/types/types';
import { detectArchivedFileKeyPath } from '../../SlideSelector/useThumbnailSelector';
import DNAThumbnail from '../Thumbnail/DNAThumbnail';
import {
  SLIDE_ROLL_VARIANT,
  ThumbnailNumberPosition,
  thumbnailNumberPositionProps,
  thumbnailVariantSettings,
  thumbnailVariantStyles,
} from './StyleSetting';

interface DNAPresentableThumbnailProps {
  page: PresentablePage,
  isContentUnavailable?: boolean,
  isSelected?: boolean,
  variant?: SLIDE_ROLL_VARIANT,
  onSelect?: () => void,
  numberPosition?: ThumbnailNumberPosition,
  height?: number,
  width?: number,
}

const DNASlideRollThumbnail: React.FC<DNAPresentableThumbnailProps> = (props) => {
  const {
    height,
    isContentUnavailable,
    isSelected,
    numberPosition,
    onSelect,
    page,
    variant = SLIDE_ROLL_VARIANT.DEFAULT,
    width,
  } = props;
  const s3URL = detectArchivedFileKeyPath(page.documentVersion.model, page.page)!;
  const variantStyles = thumbnailVariantStyles[variant];
  const variantSettings = thumbnailVariantSettings[variant];
  const thumbnailPlacementProps = thumbnailNumberPositionProps[numberPosition ?? variantSettings.numberPosition];

  if (isContentUnavailable) {
    return (
      <DNABox style={[variantStyles.container, { margin: 3 }]} />
    );
  }

  return (
    <Pressable
      onPress={onSelect}
      style={variantStyles.pressableContainer}
    >
      {/* CONTAINER  */}
      <DNABox {...thumbnailPlacementProps} spacing="tiny">
        {/* OUTER THUMBNAIL CONTAINER */}
        <DNABox
          key={page.id}
          style={[variantStyles.container, isSelected && variantStyles.selected]}
          alignX="center"
          alignY="center"
        >
          {/* INNER THUMBNAIL CONTAINER */}
          <DNABox
            alignX="center"
            alignY="center"
            style={[
              variantStyles.innerContainer,
              !isSelected
                ? variantStyles.unselected
                : variantStyles.selected,
            ]}
          >
            {/* THUMBNAIL */}
            <DNAThumbnail
              style={variantStyles.thumbnail as ImageStyle}
              s3URL={s3URL}
              height={height ?? variantSettings.thumbnailHeight}
              width={width ?? variantSettings.thumbnailWidth}
            />
          </DNABox>
        </DNABox>
        {/* PAGE NUMBER */}
        <DNAText
          style={[
            variantStyles.pageText,
            isSelected && variantStyles.pageTextSelected,
          ]}
        >
          {page.presentationPageNumber}
        </DNAText>
      </DNABox>
    </Pressable>
  );
};

DNASlideRollThumbnail.displayName = 'DNASlideRollThumbnail';

export default DNASlideRollThumbnail;
