import React from 'react'
import { DNAChip, DNAText, LuxStatusEnum, Popover } from '@alucio/lux-ui'
import { PurposeType, DocumentStatus } from '@alucio/aws-beacon-amplify/src/models'
import { toPurposeText } from 'src/components/Library/Util/util'
import { DocumentORM, DocumentVersionORM, ORMTypes } from 'src/types/types'

export enum Variant {
  docType = 'docType',
  purpose = 'purpose',
  status = 'status',
  semVer = 'semVer',
}

// [TODO-731] Might be able to throw a TS error if DocumentVersionORM and variant is Purpose
export interface DNADocumentChipProps {
  item: DocumentORM | DocumentVersionORM,
  variant: keyof typeof Variant | Variant,
  status?: DocumentStatus | keyof typeof DocumentStatus
}

// [TODO] - This can probably be handled better
export const statusMap = {
  purpose: {
    [PurposeType.INTERNAL_USE_ONLY]: 'danger',
    [PurposeType.EXTERNAL_PROACTIVE]: 'success',
    [PurposeType.EXTERNAL_REACTIVE]: 'warning',
  },
  acronym: {
    INT: 'danger',
    EP: 'success',
    ER: 'warning',
    [DocumentStatus.ARCHIVED]: 'ARCHIVED',
    [DocumentStatus.REVOKED]: 'REVOKED',
    [DocumentStatus.NOT_PUBLISHED]: 'NOT PUBLISHED',
    [DocumentStatus.PUBLISHED]: 'PUBLISHED',
    [DocumentStatus.DELETED]: 'DELETED',
  },
  status: {
    // Status
    [DocumentStatus.ARCHIVED]: LuxStatusEnum.subtle,
    [DocumentStatus.REVOKED]: LuxStatusEnum.danger,
    [DocumentStatus.NOT_PUBLISHED]: LuxStatusEnum.warning,
    [DocumentStatus.PUBLISHED]: LuxStatusEnum.success,
    [DocumentStatus.DELETED]: LuxStatusEnum.danger,
  },
}

export const DNADocumentChip: React.FC<DNADocumentChipProps> = (props) => {
  const { item, variant, status } = props

  // DocumentVersion SemVer
  const isDocVerSemVer = variant === Variant.semVer

  if (isDocVerSemVer && item.type === ORMTypes.DOCUMENT_VERSION) {
    return (
      <DNAChip status="subtle" style={{ borderRadius: 2 }}>
        {
          item.model.status === 'PUBLISHED'
            ? `V ${item.meta.version.semVerLabel}`
            : 'DRAFT IN PROGRESS'
        }
      </DNAChip>
    )
  }

  // Document Status type Chip
  const isDocStatusVariant = variant === Variant.status
  if (isDocStatusVariant) {
    const statusToUse = status || item.meta.sealedStatus || item.model.status

    return (
      <DNAChip status={statusMap.status[statusToUse]} style={{ borderRadius: 2 }}>
        { statusMap.acronym[statusToUse] }
      </DNAChip>
    )
  }

  // Document file type Chip
  const isDocTypeVariant = variant === Variant.docType
  if (isDocTypeVariant) {
    return (
      <DNAChip appearance="tag" status="dark">
        { item.model.type }
      </DNAChip>
    )
  }

  // NOTE: The purpose variant only applies to Document types
  const isDocPurposeVariant =  variant === Variant.purpose
  if (isDocPurposeVariant) {
    const docVer = item.type === ORMTypes.DOCUMENT ? item.relations.version.latestUsableDocumentVersion : item
    // Document purpose Chip
    const docPurpose = (docVer.model.purpose ||
        PurposeType.NOT_DEFINED) as PurposeType
    const purposeStatus = statusMap.purpose[docPurpose] ?? 'dark' // Fallbacks
    const purposeText = toPurposeText(docPurpose) || '???' // Fallbacks

    return (
      <Popover placement="top">
        <Popover.Anchor>
          <DNAChip
            testID="chip-purpose-type"
            appearance="tag"
            status={purposeStatus}
          >
            { purposeText }
          </DNAChip>
        </Popover.Anchor>
        <Popover.Content offset={2}>
          <DNAText status="basic">
            { docPurpose }
          </DNAText>
        </Popover.Content>
      </Popover>
    )
  }

  return null;
}

export default DNADocumentChip
