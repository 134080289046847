import React from 'react';
import { DNABox, DNAButton, DNAModal, DNAText } from '@alucio/lux-ui';
import { DNAModalVariant } from '../DNAConnectedModal';
import { PayloadGroup } from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider';

interface ClearSlidesModalProps {
  setSelectedGroups: React.Dispatch<React.SetStateAction<PayloadGroup[]>>
  onClear?: () => void
}

const ClearSlidesModal:DNAModalVariant<ClearSlidesModalProps> = (props) => {
  const { toggleModal, setSelectedGroups } = props

  const handleCancel = () => {
    toggleModal()
  }
  const handleClear = () => {
    setSelectedGroups([]);

    toggleModal()
    props.onClear && props.onClear()
  }

  return (
    <DNAModal>
      <DNAModal.Header>
        <DNAText h5>Clear all slides?</DNAText>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox style={{ padding: 8 }}>
          <DNAText status="flat">This will remove all the slides from the canvas.</DNAText>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <DNAButton size="small" status="flat" onPress={handleCancel}>Cancel</DNAButton>
        <DNAButton size="small" status="primary" onPress={handleClear}>Clear</DNAButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
};

export default ClearSlidesModal;
