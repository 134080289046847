import React from 'react';
import VirtualExchange from './VirtualExchange';
import { useHistory } from '../../router';
import ContentViewerModalStateProvider from 'src/components/ContentPreviewModal/state/ContentViewerModalStateProvider';
import { PresentationPlayerProvider } from '@alucio/video';
import ROUTES from 'src/router/routeDef';

function Virtual() {
  const history = useHistory();
  function onClose() {
    history.push(ROUTES.MEETING_HISTORY.PATH)
  }
  return (
    <PresentationPlayerProvider>
      <ContentViewerModalStateProvider context="VIRTUAL">
        <VirtualExchange onClose={onClose} />
      </ContentViewerModalStateProvider>
    </PresentationPlayerProvider>
  )
}

export default Virtual;
