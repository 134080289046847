import { DocumentVersionORM, ORMTypes } from 'src/types/types'
import debounce from 'lodash/debounce'
import CacheDB from 'src/worker/db/cacheDB'
import PWALogger from 'src/worker/util/logger'
import store from 'src/state/redux/store'
import { cacheActions } from 'src/state/redux/slice/Cache/cache'
import { allDocumentsSortedAndFilteredFactory } from 'src/state/redux/selector/document'
import { allFolderItems } from 'src/state/redux/selector/folder'
import docQuery from 'src/state/redux/document/query'
import workerChannel from 'src/worker/channels/workerChannel'

export const getOfflineDocumentVersions = (): DocumentVersionORM[] => {
  const allDocumentsSelector = allDocumentsSortedAndFilteredFactory()
  const publishedDocuments = allDocumentsSelector(
    store.getState(),
    undefined,
    docQuery.filters.published,
  )
  // 1. All latest, published versions
  const latestPublishedVersions = publishedDocuments
    .map(doc => doc.relations.version.latestDocumentVersionPublished)
    .reduce<Record<string, DocumentVersionORM>>(
      (acc, doc) => {
        if (doc) { acc[doc.model.id] = doc }
        return acc
      },
      {},
    )

  const folderItems = allFolderItems(store.getState(), undefined)
  // 2. All versions found in folders (even if outdated)
  const folderDocVersions = folderItems.reduce<Record<string, DocumentVersionORM>>(
    (acc, folderItem) => {
      if (folderItem.relations.item.type === ORMTypes.DOCUMENT_VERSION) {
        acc[folderItem.relations.item.model.id] = folderItem.relations.item
      }
      return acc
    },
    {},
  )

  // 3. All versions found in custom decks (even if outdated)
  const customDeckDocVersions = folderItems.reduce<Record<string, DocumentVersionORM>>(
    (acc, folderItem) => {
      if (folderItem.relations.item.type === ORMTypes.CUSTOM_DECK) {
        const groups = folderItem.relations.item.meta.groupsORM

        groups.forEach((group) => {
          if (group.pages[0]?.documentVersionORM) {
            acc[group.pages[0].documentVersionORM.model.id] = group.pages[0].documentVersionORM
          }
        })
      }
      return acc
    },
    {},
  )

  const docVersionsToSyncMap = {
    ...latestPublishedVersions,
    ...folderDocVersions,
    ...customDeckDocVersions,
  }

  const docVersionsToSync = Object.values(docVersionsToSyncMap)
  PWALogger.info(`Unique Document Versions to Sync ${docVersionsToSync.length}`)
  return docVersionsToSync
}

export const syncCacheManifest = async (): Promise<void> => {
  PWALogger.info('SyncMachine - Syncing latest content manifest')
  const targetDocVers = getOfflineDocumentVersions()

  const cacheDB = new CacheDB()
  await cacheDB.open()
  await cacheDB.syncCacheManifest(targetDocVers)

  const syncManifest = await cacheDB.getCacheManifest()
  store.dispatch(cacheActions.setManifestEntries(syncManifest))

  await cacheDB.close()
}

/**
 * Debounce to have more of a "batched" effect
 * Because if a machine is currently syncing, it will ignore
 * any other START_SYNC signals and miss potential mainfest
 * changes that happened after the in-progress Sync
 */
export const syncCacheManifestDebounced = debounce(async function (startSync: boolean) {
  await syncCacheManifest()
  if (startSync) {
    workerChannel.postMessageExtended({ type: 'CLIENT_CONNECTED', value: 'START_SYNC' })
  }
}, 5000)
