import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import {
  DNACollapsibleCard,
} from '@alucio/lux-ui'

import SlideDocumentVersionSelector from 'src/components/SlideSelector/SlideDocumentVersionSelector'

import { slideSelectorModalActions } from 'src/state/redux/slice/SlideSelectorModal/SlideSelectorModal';
import { useVersioningPanel } from '../VersioningPanelContext'
import { PageGroup } from '@alucio/aws-beacon-amplify/src/models'
import { ThumbnailPage } from 'src/components/SlideSelector/SlideSelector'
import omit from 'lodash/omit';
import { RootState } from 'src/state/redux/store';

const SLIDE_SETTINGS_DESCRIPTION = 'In Slide Settings, you can set slides as required to ensure they \n' +
  'get presented by the field team during external engagements.\n' +
  'You can group slides to ensure they go together when this file \n' +
  'is used for creating custom presentations.'

const SlideSettings: React.FC = () => {
  const {
    send,
    state,
    cond,
    currentDocumentVersionORM,
  } = useVersioningPanel()
  const dispatch = useDispatch()
  const modalState = useSelector((state: RootState) => state.SlideSelectorModal);

  useEffect(
    () => {
      const documentVerisonIdNotMatching = state.context.documentVersionId !== modalState.documentVersionId
      const isCurrentVersionProcessed = currentDocumentVersionORM.model.conversionStatus === 'PROCESSED'

      if (documentVerisonIdNotMatching && isCurrentVersionProcessed) {
        dispatch(
          slideSelectorModalActions.setActiveDocumentVersion(
            {
              documentVersionId: currentDocumentVersionORM.model.id,
              isOpen: false,
              isReadOnly: cond.isPublishedViewMode,
            },
          ),
        )
      }
    },
    [currentDocumentVersionORM.model],
  )

  // [TODO-2126] - This is a callback whenever GroupDrafts is updated
  //  We have to use a callback instead of the useThumbnailSelector hook
  //  Due to this parent Slider being outside the scope of the provider
  const onAction = (pages?: ThumbnailPage[], pageGroups?: PageGroup[]) => {
    // [TODO-2073] - This callback is chatty since it fires on every selection
    //             - Consider a callback that only fires on meaningful actions (set cover, set required, set group)
    const undecoratedPages = pages?.map(page => omit(page, 'thumbnailObjectURL', 'checked', 'isCover', 'disabled'))
    const selectedThumbnailPageNumber = pages?.find(page => page.isCover)?.number || 1

    send({
      type: 'SYNC_VERSION_FORM',
      payload: {
        versionForm: {
          ...(
            pages
              ? {
                selectedThumbnail: selectedThumbnailPageNumber,
                pages: undecoratedPages,
              }
              : {}
          ),
          ...(pageGroups ? { pageGroups } : {}),
        },
      },
    })
  }

  return (
    <DNACollapsibleCard
      headerTitle="Slide Settings"
      popoverInfoText={SLIDE_SETTINGS_DESCRIPTION}
      customWrapperStyle={{ paddingVertical: 0, paddingHorizontal: 0 }}
    >
      <View style={{ maxHeight: '70vh' }}>
        <SlideDocumentVersionSelector
          disableHeader
          onAction={onAction}
          isReadOnly={cond.isPublishedViewMode}
        />
      </View>
    </DNACollapsibleCard>
  )
}

export default SlideSettings
