import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isDocumentVersionORM, isFolderItemORM, MeetingORM } from 'src/types/types';
import { useMeeting } from 'src/state/redux/selector/meeting';
import { meetingActions } from 'src/state/redux/slice/meeting';
import useScreenNav from 'src/components/DNA/hooks/useScreenNav';
import { Tab } from 'src/screens/Meetings/PresentationControls/PresentationControls';
import { Meeting, MeetingContentType } from '@alucio/aws-beacon-amplify/src/models';
import { drawerActions, DRAWER_ENTITIES } from 'src/state/redux/slice/drawer';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { CONTENT_PRESENTED_STATUS } from '@alucio/aws-beacon-amplify/src/API';
import useMeetingsFormState from './useMeetingsFormState';
import { UseFormMethods } from 'react-hook-form';

export interface MeetingsStateType {
  meetingORM: MeetingORM | undefined,
  presentationControlsVisible: boolean,
  setPresentationControlsVisible: Dispatch<SetStateAction<boolean>>,
  togglePanelVisibility,
  setCurrentTab: Dispatch<SetStateAction<Tab>>,
  currentTab: Tab,
  endMeeting: () => void,
  editMeeting: (meeting: Meeting) => void,
  meetingForm: UseFormMethods<{ notes?: string | undefined; title: string; }>
}

export const MeetingsStateContext = createContext<MeetingsStateType>(null!);
MeetingsStateContext.displayName = 'MeetingsContext';

interface InitialValues {
  meetingId: string
}

const MeetingsStateProvider: React.FC<InitialValues> = (props) => {
  const meetingORM = useMeeting(props.meetingId);
  const { goTo } = useScreenNav();
  const dispatch = useDispatch();
  const [presentationControlsVisible, setPresentationControlsVisible] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState<Tab>('BROWSE_CONTENT');
  const { activePresentation, presentations } = useContent();
  const popupReference = useRef<Window | null>(null)
  const { meetingForm } = useMeetingsFormState({ meetingORM })

  const endMeeting = () => {
    meetingORM && dispatch(meetingActions.endMeeting(meetingORM.model));
    popupReference.current?.window.close()
    goTo.MEETING_HISTORY_V2();
    meetingORM && dispatch(drawerActions.toggle({
      entity: DRAWER_ENTITIES.MEETING,
      entityId: meetingORM.model.id,
    }));
  }

  const editMeeting = (meeting: Meeting) => {
    meetingORM && dispatch(meetingActions.updateMeeting(meetingORM.model, meeting));
  }

  const togglePanelVisibility = () => {
    setPresentationControlsVisible(p => !p)
  }

  function addContentPresented() {
    if (!activePresentation) return null
    const { orm } = activePresentation.presentable
    const isDocumentVersion =
      isDocumentVersionORM(orm) ||
      isDocumentVersionORM(orm.relations.item)
    const contentId =
      (isFolderItemORM(orm) && orm.relations.item.model.id) ||
      (isDocumentVersionORM(orm) && orm.model.id)
    if (!contentId) return null
    const folderItemId = isFolderItemORM(orm)
      ? orm.model.id
      : undefined

    const contentPresented = meetingORM?.model.contentPresented?.find(
      p =>
        (p.contentId === contentId && !folderItemId && !p.folderItemId) ||
        (p.folderItemId === folderItemId && p.folderItemId === folderItemId)
    )
    if (!contentPresented) {
      const content = {
        contentId: contentId,
        folderItemId: folderItemId,
        status: CONTENT_PRESENTED_STATUS.ACTIVE,
        contentType: isDocumentVersion
          ? MeetingContentType.DOCUMENT_VERSION
          : MeetingContentType.CUSTOM_DECK,
        openedAt: (new Date().toISOString()),
        events: [{
          pageNumber: activePresentation?.currentPresentablePage.presentationPageNumber!,
          timestamp: new Date().toISOString(),
          pageId: activePresentation?.currentPresentablePage.page.pageId!,
        }],
      }
      return [...meetingORM?.model.contentPresented!, content];
    }

    const newEvent = {
      pageNumber: activePresentation?.currentPresentablePage.presentationPageNumber!!,
      timestamp: new Date().toISOString(),
      pageId: activePresentation?.currentPresentablePage.page.pageId!,
    }

    if (contentPresented.events?.some(p => p?.pageId === newEvent.pageId)) return null

    const events = contentPresented.events?.length
      ? [...contentPresented.events, newEvent] : [newEvent]

    return meetingORM?.model.contentPresented?.map(p =>
      (p.contentId === contentId && !folderItemId && !p.folderItemId) ||
      (p.folderItemId === folderItemId && p.folderItemId === folderItemId)
        ? { ...p, events: events }
        : p
    )
  }

  const launchPopoutContentWindow = (meetingId:string) => {
    const url = new URL(`${window.location.origin}/meeting-popout-content/${meetingId}`)
    url.searchParams.append('meetingId', meetingId)

    const target = '_blank'
    const { width } = window.screen
    const position = width > 999
      ? width - 1000
      : 0
    // eslint-disable-next-line max-len
    const features = `directories=no, titlebar=no, toolbar=no, location=no, status=no, menubar=no, scrollbars=no, toolbar=0, scrollbars=0, location=0, statusbar=0, menubar=0, height=563, width=1000, left=${position}`

    /**  @ts-ignore - the compiler does not appear to recognize a URL instance as a valid value for the first param here (but it is) */
    const popupContentWindow = window.open(url, target, features)

    /** TODO: This will need to be refactored in BEAC-2851
     * https://alucioinc.atlassian.net/browse/BEAC-2851
     * Also, timeouts are hacky. 🥲
     */
    setTimeout(() => {
      popupContentWindow && (popupContentWindow.document.title = 'Beacon Meeting')
    }, 1000);

    popupReference.current = popupContentWindow
  }

  const handleMeetingStatusUpdate = () => {
    if (meetingORM) {
      const { status, type, id } = meetingORM.model

      const shouldLaunchPopoutContentWindow =
        type === 'VIRTUAL' &&
        status === 'IN_PROGRESS' &&
        (!popupReference.current || popupReference.current.closed)

      /** Virtual Meeting Popup */
      shouldLaunchPopoutContentWindow && launchPopoutContentWindow(id)
    }
  }

  useEffect(handleMeetingStatusUpdate, [meetingORM])

  useEffect(() => {
    if (meetingORM?.model && presentations.length < meetingORM?.model.contentPresented.length) {
      const contendPresentedIds = presentations.map(({ presentable: { orm } }) => {
        return (isFolderItemORM(orm) && orm.relations?.item?.model.id) ||
          (isDocumentVersionORM(orm) && orm.model.id)
      })
      const payload = {
        ...meetingORM?.model,
        contentPresented: meetingORM?.model.contentPresented.
          map(p => {
            if (contendPresentedIds.includes(p.contentId) || p.closedAt) {
              return p
            }
            return { ...p, closedAt: new Date().toISOString() }
          }),

      }
      editMeeting(payload);
    }
  }, [presentations])

  useEffect(() => {
    const newContent = activePresentation && addContentPresented();
    if (meetingORM && newContent) {
      const payload = {
        ...meetingORM.model,
        contentPresented: newContent,
      }
      editMeeting(payload);
    }
  }, [activePresentation]);

  const contextValue: MeetingsStateType = {
    endMeeting,
    editMeeting,
    togglePanelVisibility,
    currentTab,
    setCurrentTab,
    presentationControlsVisible,
    setPresentationControlsVisible,
    meetingORM,
    meetingForm,
  }

  return (
    <MeetingsStateContext.Provider value={contextValue}>
      {props.children}
    </MeetingsStateContext.Provider>
  )
}
MeetingsStateProvider.displayName = 'MeetingsStateProvider';

export const useMeetingsState = () => {
  const context = useContext(MeetingsStateContext)
  if (!context) {
    throw new Error('useMeetingsState must be used within the MeetingsStateProvider')
  }
  return context;
}

export default MeetingsStateProvider;
