import React, { useEffect, useState } from 'react';
import { Pressable, ScrollView } from 'react-native';
import {
  DocumentORM,
  DocumentVersionORM,
  FolderItemORM,
  FolderORM,
  isCustomDeckORM,
  ORMTypes,
} from 'src/types/types'
import { useAllDocumentsInstance } from 'src/state/redux/selector/document'
import documentQuery from 'src/state/redux/document/query';

import { ContentORMs } from 'src/components/DNA/types'
import { DNABox, DNAIcon, Iffy, luxColors, DNAText } from '@alucio/lux-ui';
import SlideRoll from 'src/components/ContentPreviewModal/SlideRoll/SlideRoll';

import { useDNAFolderNav, withDNAFolderNav } from 'src/components/DNA/Folder/Nav/DNAFolderNav'

import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList'
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import { filters, merge, sorts } from 'src/state/redux/folder/query'
import { DisplayMode, DisplayModes } from 'src/components/DNA/hooks/useDisplayMode'
import DNAGrid from 'src/components/DNA/Grid/DNAGrid'
import { useMSLDocumentSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import { getVisiblePages } from 'src/utils/documentHelpers';

export const Options = {
  FOLDERS: 'My Folders',
  BOOKMARKS: 'Bookmarks',
  LIBRARY: 'Library',
  SEARCH_RESULTS: 'Search results',
};

interface ContentProps {
  localSearchText: string,
  selectedTab: string,
  setSelectedDocumentVersion: (doc: DocumentVersionORM | undefined, folderItemORM?: FolderItemORM) => void,
  setSelectedPage: (index: number | undefined, folderItemORM?: FolderItemORM) => void,
  displayMode: DisplayMode,
  searchText?: string,
  selectedDocumentVersion?: DocumentVersionORM,
  sessionId?: string,
}

const BOOKMARKED_DOCS_QUERY = documentQuery.merge(
  documentQuery.filters.published,
  documentQuery.filters.bookmarked,
  documentQuery.sorts.bookmarkedAsc,
)

const ALL_DOCS_QUERY = documentQuery.merge(
  documentQuery.sorts.updatedAtDesc,
  documentQuery.sorts.titleAsc,
  documentQuery.filters.published,
  documentQuery.sorts.bookmarkedAsc,
)

function Content(props: ContentProps) {
  const {
    localSearchText,
    selectedTab,
    displayMode,
    searchText = '',
    selectedDocumentVersion,
    setSelectedDocumentVersion,
  } = props
  const {
    currentItems,
    pushFolder,
    popToFolder,
    folderStack,
    isCurrentlyNested,
  } = useDNAFolderNav()

  const [folderItemORM, setFolderItemORM] = useState<FolderItemORM | undefined>();
  const customDeck = (isCustomDeckORM(folderItemORM?.relations.item) && folderItemORM?.relations.item) ||
    undefined;
  const bookmarkedDocs = useAllDocumentsInstance(BOOKMARKED_DOCS_QUERY)
  const documents = useAllDocumentsInstance(ALL_DOCS_QUERY)
  const resultsFromSearch: DocumentORM[] = useMSLDocumentSearch({ text: searchText })
  const title = selectedDocumentVersion?.model.title
  const currentTab = {
    [Options.FOLDERS]: {
      items: currentItems,
      label: 'My Folders',
    },
    [Options.BOOKMARKS]: {
      items: bookmarkedDocs,
      label: 'Bookmarks',
    },
    [Options.LIBRARY]: {
      items: documents,
      label: 'Library',
    },
    // "Ghost tab". It works as a tab but is not shown as an option to be selected.
    // Will be applied upon a valid search text input value.
    [Options.SEARCH_RESULTS]: {
      items: resultsFromSearch,
      label: 'Search results',
    },
  }
  const onItemPress = (item: ContentORMs) => {
    const isDocumentVersionInFolder = item.type === ORMTypes.FOLDER_ITEM &&
      item.relations.item.type === ORMTypes.DOCUMENT_VERSION &&
      item.relations.item.relations.document.meta.permissions.MSLPresent

    // IF IT'S NOT A DOCUMENT VERSION FROM A FOLDER, WE WANT TO CLEAN THE STATE
    if (!isDocumentVersionInFolder) {
      setFolderItemORM(undefined);
    }

    if (item.type === ORMTypes.FOLDER) {
      pushFolder(item)
    }
    else if (item.type === ORMTypes.FOLDER_ITEM) {
      if (isDocumentVersionInFolder) {
        setFolderItemORM(item);
        setSelectedDocumentVersion(item.relations.item as DocumentVersionORM);
      } else if (item.relations.item.type === ORMTypes.FOLDER) {
        pushFolder(item.relations.item)
      } else if (item.relations.item.type === ORMTypes.CUSTOM_DECK) {
        setFolderItemORM(item);
        setSelectedDocumentVersion(undefined);
      }
    } else if (item.type === ORMTypes.DOCUMENT && item.meta.permissions.MSLPresent) {
      setSelectedDocumentVersion(item.relations.version.latestDocumentVersionPublished)
    }
  }

  let emptyVariant: EmptyVariant;
  switch (selectedTab) {
    case Options.BOOKMARKS:
      emptyVariant = EmptyVariant.FolderBookmarkPresentEmpty
      break
    case Options.SEARCH_RESULTS:
      emptyVariant = EmptyVariant.DocumentResultsListEmpty
      break
    case Options.LIBRARY:
      emptyVariant = EmptyVariant.DocumentListEmpty
      break
    default:
      emptyVariant = EmptyVariant.FolderListEmpty
  }

  emptyVariant = selectedTab !== Options.FOLDERS ? emptyVariant
    : (isCurrentlyNested ? EmptyVariant.FileListPresentEmpty : EmptyVariant.FolderListPresentEmpty)

  function clearNavStack() {
    popToFolder();
    setFolderItemORM(undefined);
    setSelectedDocumentVersion(undefined);
  }

  function presentPage(index: number): void {
    if (!selectedDocumentVersion && !customDeck) {
      console.error('Could not find selected document to present')
      return;
    }
    props.setSelectedPage(index, folderItemORM);
  }

  // On tab switch, clear the Nav Stack
  useEffect(() => { clearNavStack() }, [selectedTab]);

  // Disable display toggle if document is selected (slide roll view)
  useEffect(() => {
    displayMode.setEnableDisplayMode(!selectedDocumentVersion)
  }, [selectedDocumentVersion])

  const searchResultsText: string = selectedTab !== Options.SEARCH_RESULTS
    ? `${currentTab[selectedTab].items.length} item(s)` : `Search results for "${localSearchText}" ` +
    `| ${currentTab[selectedTab].items.length} result(s)`;

  return (
    <DNABox appearance="col" fill style={{ marginHorizontal: 32, marginTop: 16 }}>
      {/* Bread crumbs */}

      <DNABox alignY="center" style={{ marginBottom: 16 }}>
        <Pressable onPress={clearNavStack}>
          <DNAText numberOfLines={1} status="subtle">
            {currentTab[selectedTab].label}
          </DNAText>
        </Pressable>

        {/* Render the Folder Stack as Breadcrumbs */}
        {
          folderStack.map(folderORM => (
            <DNABox shrink alignY="center">
              <DNAIcon.Styled
                name="chevron-right"
                appearance="ghost"
                status="subtle"
                context="minimum"
                style={{ marginTop: 2 }}
              />
              <Pressable
                style={{ flexShrink: 1 }}
                onPress={() => {
                  popToFolder(folderORM);
                  setFolderItemORM(undefined);
                  setSelectedDocumentVersion(undefined)
                }}
              >
                <DNAText numberOfLines={1} status="subtle">
                  {folderORM.model.name}
                </DNAText>
              </Pressable>
            </DNABox>
          ))
        }

        <Iffy is={selectedDocumentVersion || customDeck}>
          <DNAIcon.Styled
            name="chevron-right"
            appearance="ghost"
            status="subtle"
            context="minimum"
            style={{ marginTop: 2 }}
          />
          <Pressable style={{ flexShrink: 1 }}>
            <DNAText numberOfLines={1} status="subtle">
              {title}
            </DNAText>
          </Pressable>
        </Iffy>
        <Iffy is={selectedTab !== Options.FOLDERS && !selectedDocumentVersion}>
          <DNAText b2 status="subtle" style={{ marginLeft: 4 }}>
            {searchResultsText}
          </DNAText>
        </Iffy>
      </DNABox>

      {/* FlatList */}
      <Iffy is={(!selectedDocumentVersion && displayMode.displayMode === DisplayModes.list) && !customDeck}>
        <DNAFlatList<(DocumentORM | DocumentVersionORM | FolderORM) >
          items={currentTab[selectedTab].items}
          variant="virtual"
          emptyVariant={emptyVariant}
          onPress={onItemPress}
          useEmptyContainer={false}
        />
      </Iffy>

      {/* Grid */}
      <Iffy is={(!selectedDocumentVersion && displayMode.displayMode === DisplayModes.grid) && !customDeck}>
        <DNABox fill appearance="col" as={ScrollView}>
          <DNAGrid<(DocumentORM | DocumentVersionORM | FolderORM) >
            items={currentTab[selectedTab].items}
            variant="virtual"
            emptyVariant={emptyVariant}
            onPress={onItemPress}
            useEmptyContainer={false}
          />
        </DNABox>
      </Iffy>

      <Iffy is={selectedDocumentVersion || customDeck}>
        <SlideRoll
          activeSlide={1}
          documentVersion={selectedDocumentVersion?.model!}
          isCustomDeck={!!customDeck}
          isGridView={true}
          itemHeight={126}
          itemWidth={216}
          numberColor={luxColors.subtitle.quaternary}
          setActiveSlide={presentPage}
          visiblePages={getVisiblePages(selectedDocumentVersion, folderItemORM, customDeck)}
        />
      </Iffy>
    </DNABox>
  );
}

Content.displayName = 'PanelContent';

export default withDNAFolderNav(
  Content,
  merge(filters.active, sorts.pinnedAsc),
);
