import { DNAFlatListRows } from 'src/components/DNA/FlatList/Rows/types'
import DNADocumentMeetingRow from './DNADocumentMeetingRow'
import DNADocumentFolderItemMeetingRow from './DNADocumentFolderItemMeetingRow'
import DNADocumentFolderItemCustomDeckMeetingRow from './DNADocumentFolderItemCustomDeckMeetingRow'
import DNAFolderMeetingRow from './DNAFolderMeetingRow'

export const DNAMeetingsRows: DNAFlatListRows = {
  DNADocumentRow: DNADocumentMeetingRow,
  DNADocumentFolderItemRow: DNADocumentFolderItemMeetingRow,
  DNADocumentFolderItemCustomDeckRow: DNADocumentFolderItemCustomDeckMeetingRow,
  DNAFolderRow: DNAFolderMeetingRow,
}

export default DNAMeetingsRows
