import React, { useCallback, useState, useMemo } from 'react'
import { StyleSheet, TouchableWithoutFeedback } from 'react-native';
import {
  Popover,
  DNAButton,
  DNANotifications,
  Icon,
  DNABox,
  Iffy,
} from '@alucio/lux-ui'
import { useHistory } from 'src/router'
import { useAppSettings } from 'src/state/context/AppSettings'
import folderQuery from 'src/state/redux/folder/query'
import { useAllFoldersUpdatedDocsMap } from 'src/state/redux/selector/folder';
import format from 'date-fns/format';
import { DNANotificationsItemProps } from '@alucio/lux-ui/src/components/layout/DNANotifications/DNANotifications';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

const styles = StyleSheet.create({
  notificationIcon: {
    width: 8,
    height: 8,
    color: colors['color-danger-500'],
    marginLeft: 8,
    fontSize: 16,
  },
  notificationOverlay: {
    position: 'absolute',
    right: 9,
    top: 3,
    cursor: 'pointer',
  },
})

export const Notification = () => {
  const history = useHistory();
  const [contentVisible, setContentVisible] = useState<boolean>(false);
  const currentActiveFilter = useMemo(() => folderQuery.merge(folderQuery.filters.active), [])
  const updatedDocumentsMap = useAllFoldersUpdatedDocsMap(currentActiveFilter)

  const updatedDocumentsArray = Array.from(updatedDocumentsMap.entries())
  const hasUpdates = updatedDocumentsArray.length > 0
  const toggleContent = () => setContentVisible(p => !p)

  const BellIcon = () => {
    const { deviceMode } = useAppSettings()
    return deviceMode === 'desktop'
      ? <DNAButton
          context="minimum"
          appearance="ghost"
          status="dark"
          size="xlarge"
          iconLeft="bell-outline"
          onPress={toggleContent}
      />

      : <DNAButton
          context="minimum"
          appearance="ghost"
          status="flat"
          size="xlarge"
          iconLeft="bell-outline"
          onPress={toggleContent}
      />
  }

  const items: DNANotificationsItemProps[] = []
  if (hasUpdates) {
    let maxDate: Date | undefined
    updatedDocumentsArray.forEach(obj => {
      const doc = obj[0]
      if (doc) {
        const date = new Date(doc.model.updatedAt)
        if (!maxDate || date > maxDate) {
          maxDate = date
        }
      }
    })

    items.push({
      date: format(maxDate || new Date(), 'MMMM dd, yyyy'),
      shortDescription: 'New version updates',
      actionLabel: 'Manage updates',
      icon: 'file',
      onPress: () => {
        history.push('/folders/updates')
        toggleContent();
      },
    })
  }

  // Current default notification
  items.push({
    date: 'April 7th, 2022',
    shortDescription: 'New features available!',
    actionLabel: 'See what’s new',
    icon: 'star',
    highlight: true,
    onPress: useCallback(() => {
      history.push('/notifications')
      toggleContent();
    }, []),
  })

  const BellButton: React.FC = () => (
    <Popover
      lazyMount
      placement="bottom-end"
      interactive={true}
      visible={contentVisible}
      onBackdropPress={toggleContent}
      type="menu"
    >
      <Popover.Anchor style={{ height: '100%' }}>
        <BellIcon />
        <Iffy is={hasUpdates}>
          <TouchableWithoutFeedback onPress={toggleContent}>
            <DNABox style={styles.notificationOverlay}>
              <Icon name={'record'} style={styles.notificationIcon} />
            </DNABox>
          </TouchableWithoutFeedback>
        </Iffy>
      </Popover.Anchor>

      <Popover.Content offset={10}>
        <DNANotifications
          items={items}
          onPress={useCallback(() => {
            history.push('/notifications')
            toggleContent();
          }, [])}
        />
      </Popover.Content>
    </Popover>
  )

  return <BellButton />
}

export default Notification
