import React from 'react'
import { FolderORM } from 'src/types/types'
import { useDNAFolderActions } from 'src/components/DNA/Folder/DNAFolder.actions'
import { DNABox, DNAButton, DNACard, DNAText, Iffy, useTheme } from '@alucio/lux-ui'
import Folder from 'src/components/DNA/Folder/DNAFolder'
import PinButton from 'src/components/DNA/Folder/DNAFolderPinButton'
import DNAFolderContextMenu from 'src/components/DNA/Folder/DNAFolderContextMenu'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import { StyleSheet } from 'react-native'

export interface DNAFolderDefaultRowProps {
  onPin?: () => void,
}

const styles = StyleSheet.create({
  containerStyle: {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: 4,
    marginVertical: 4,
  },
})

export const DNAFolderDefaultRow: DNARowsComponent<FolderORM, DNAFolderDefaultRowProps> = (props) => {
  const {
    ORM: folderORM,
    onPin,
    onPress,
    isDesktop = true,
  } = props

  const folderActions = useDNAFolderActions()
  const theme = useTheme()

  const isPinned = folderORM.model.isPinned

  const allStyle = {
    paddingHorizontal: theme['row-padding-horizontal'],
    paddingVertical: theme['row-padding-vertical'],
  }

  return (
    <DNACard
      appearance="flat"
      style={[
        props.style,
        allStyle,
        styles.containerStyle,
      ]}
      interactive="pressable"
      onPress={() => {
        onPress?.(folderORM);
      }}
    >
      <DNABox
        testID="folder-row"
        childFill={1}
        spacing={isDesktop ? 'between' : 'medium'}
        alignY="center"
      >
        <Iffy is={!isDesktop}>
          <DNABox alignY="center" spacing="small">
            <Iffy is={!folderORM.meta.isSharedWithTheUser}>
              <PinButton
                onPin={() => {
                  folderActions.pin(folderORM)();
                  onPin?.();
                }}
                isHidden={!isPinned}
                variant="meetings"
                interactive={false}
              />
            </Iffy>
            <DNAFolderContextMenu folderORM={folderORM}>
              <DNAButton
                status="subtle"
                appearance="ghostAlt"
                context="roundTablet"
                iconLeft="dots-vertical"
                size="xlarge"
              />
            </DNAFolderContextMenu>
          </DNABox>
        </Iffy>
        <DNABox spacing="large" alignY="center" childFill={1} style={{ marginLeft: 7 }}>
          <Folder folder={folderORM} />
          <DNAText
            testID="folder-name"
            b1
            style={{ marginLeft: 9 }}
            numberOfLines={2}
            status="basic"
          >
            { folderORM.model.name }
          </DNAText>
        </DNABox>
        <Iffy is={isDesktop}>
          <DNABox alignY="center">
            <Iffy is={!folderORM.meta.isSharedWithTheUser}>
              <DNABox alignY="center">
                <PinButton
                  onPin={() => {
                    folderActions.pin(folderORM)();
                    onPin?.();
                  }}
                  isHidden={!isPinned}
                  variant="meetings"
                  interactive={false}
                />
              </DNABox>
            </Iffy>
          </DNABox>
        </Iffy>
      </DNABox>
    </DNACard>
  );
}

export default DNAFolderDefaultRow
