import { DNAFlatListRows } from 'src/components/DNA/FlatList/Rows/types'
import { DNADivider } from '@alucio/lux-ui'
import DNADocumentDefaultRow from './DNADocumentDefaultRow'
import DNADocumentFolderItemDefaultRow from './DNADocumentFolderItemDefaultRow'
import DNADocumentFolderItemCustomDeckDefaultRow from './DNADocumentFolderItemCustomDeckDefaultRow'
import DNAFolderDefaultRow from './DNAFolderDefaultRow'

export const DNADefaultRows: DNAFlatListRows = {
  DNADocumentRow: DNADocumentDefaultRow,
  DNADocumentFolderItemRow: DNADocumentFolderItemDefaultRow,
  DNADocumentFolderItemCustomDeckRow: DNADocumentFolderItemCustomDeckDefaultRow,
  DNAFolderRow: DNAFolderDefaultRow,
  DNAListDivider: DNADivider,
}

export default DNADefaultRows
