import { FolderItemORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import { useEffect } from 'react'

// Not implemented
const DNADocumentFolderItemCustomDeckRow: DNARowsComponent<FolderItemORM> = () => {
  useEffect(() => {
    console.error('DNADocumentFolderItemCustomDeckRow not implemented yet')
  }, [])

  return (null)
}

export default DNADocumentFolderItemCustomDeckRow
