import React, { useMemo, ReactElement } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { DNABox, Icon, Iffy, luxColors, Stack, useInputConfig } from '@alucio/lux-ui';
import { useContentViewerModalState } from 'src/components/ContentPreviewModal/state/ContentViewerModalStateProvider';
import { useDispatch } from 'react-redux';
import { presentationControlActions } from 'src/state/redux/slice/PresentationControl/presentationControl';
import { PlayerActions } from '@alucio/video'
import IFrame from 'src/components/ContentPreviewModal/ContentPreview/IFrame/IFrame'
import GetInputConfig from 'src/components/ContentPreviewModal/ContentPreviewModal.inputConfig';

const styles = StyleSheet.create({
  actionText: {
    color: luxColors.info.primary,
    fontSize: 13,
    fontWeight: 'bold',
  },
  arrowIcon: {
    color: luxColors.info.primary,
    height: 28,
    width: 28,
  },
  controlsWrapper: {
    borderRadius: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 15,
  },
  disabledIcon: {
    backgroundColor: luxColors.subtitle.primary,
  },
  iconButton: {
    alignItems: 'center',
    borderRadius: 25,
    maxHeight: 40,
    justifyContent: 'center',
    overflow: 'hidden',
  },
  pagesText: {
    color: luxColors.info.primary,
    fontSize: 15,
    marginLeft: 14,
    marginRight: 14,
  },
  presentationWrapper: {
    borderColor: luxColors.success.primary,
    borderWidth: 4,
    flex: 1,
    width: '100%',
  },
  presentTextWrapper: {
    backgroundColor: luxColors.success.primary,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2,
  },
  stopPresentingWrapper: {
    backgroundColor: luxColors.error.primary,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2,
  },
  topActions: {
    flexDirection: 'row',
    justifyContent: 'center',
    // Doing the center using absolute position, to do not hide the zoom controls for the player
    width: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
  },
});

export enum ControlsMode {
  Above,
  Bottom,
}

interface Props {
  onStopPresenting: () => void;
  ControlsMode: ControlsMode
}

const Controls = (): ReactElement<View> => {
  const { totalSlides: totalPages, adjustedSlideNumber } = useContentViewerModalState();
  const dispatch = useDispatch()

  return (
    <View style={styles.controlsWrapper}>
      <TouchableOpacity
        onPress={
          () => dispatch(presentationControlActions.triggerPresentationAction({ action:PlayerActions.prev }))
        }
        style={styles.iconButton}
      >
        <Icon
          name="chevron-left"
          style={styles.arrowIcon}
        />
      </TouchableOpacity>
      <Text style={styles.pagesText}>{adjustedSlideNumber} of {totalPages}</Text>
      <TouchableOpacity
        onPress={
          () => dispatch(presentationControlActions.triggerPresentationAction({ action:PlayerActions.next }))
        }
        style={styles.iconButton}
      >
        <Icon
          name="chevron-right"
          style={styles.arrowIcon}
        />
      </TouchableOpacity>
    </View>
  );
};

const Presentation = (props: Props) => {
  const MemoizedIframe = useMemo(() => ContentPreview(), [])

  function ContentPreview(): ReactElement {
    return <IFrame virtual/>;
  }

  useInputConfig(GetInputConfig());

  return (
    <DNABox
      appearance="col"
      alignY="center"
      fill
      childFill={0}
    >
      <Stack>
        <Stack.Layer alignSelf="center" style={styles.presentationWrapper}>
          { MemoizedIframe }
        </Stack.Layer>
        <Stack.Layer style={styles.topActions}>
          <DNABox alignX="center">
            <View style={styles.presentTextWrapper}>
              <Text style={styles.actionText}>You are sharing content in this container</Text>
            </View>
            <TouchableOpacity style={styles.stopPresentingWrapper} onPress={props.onStopPresenting}>
              <Text style={styles.actionText}>Stop Presenting</Text>
            </TouchableOpacity>
          </DNABox>
        </Stack.Layer>
        <Stack.Layer style={{ bottom: 20, width: '100%', top: undefined }}>
          <Iffy is={props.ControlsMode === ControlsMode.Above}>
            <DNABox fill alignX="center">
              <Controls />
            </DNABox>
          </Iffy>
        </Stack.Layer>
      </Stack>
      {/* TODO: Consolidate this controls component and the contentpreviewmodal
      controls component should be merged into a shared component */}
      <Iffy is={props.ControlsMode === ControlsMode.Bottom}>
        <Controls />
      </Iffy>
    </DNABox>
  )
}

Presentation.displayName = 'Presentation';

export default Presentation;
