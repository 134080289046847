import React from 'react'
import { FolderORM } from 'src/types/types'

import {
  DNAButton,
  DNACard,
  DNABox,
  DNAText,
  DNACheckbox,
  Iffy,
  Popover,
} from '@alucio/lux-ui'
import DNAFolder from 'src/components/DNA/Folder/DNAFolder'
import DNAFolderPinButton from 'src/components/DNA/Folder/DNAFolderPinButton'

type DNANestedFolderRowProps = {
  folderORM: FolderORM,
  isChecked?: boolean,
  isCheckedDisabled?: boolean,
  disabledMessage?: string,
  onExpand?: () => void,
  isExpanded?: boolean,
  onPress?: () => void
}

const DNANestedFolderRow: React.FC<DNANestedFolderRowProps> = (props) => {
  const {
    folderORM,
    isChecked,
    isExpanded,
    disabledMessage,
    isCheckedDisabled,
    onPress,
    onExpand,
  } = props

  const showPopover = disabledMessage && isCheckedDisabled;
  const checkbox = (
    <DNACheckbox
      checked={isChecked}
      disabled={isCheckedDisabled}
      onChange={onPress}
    />
  );

  return (
    <DNACard
      appearance="outline"
      interactive="pressable"
      onPress={onPress}
      disabled={isCheckedDisabled}
      style={{ padding: 12, marginRight: 16 }}
    >
      <DNABox alignY="center" spacing="between">
        <DNABox alignY="center" spacing="medium">
          {/* Expand Chevron */}
          <Iffy is={folderORM.meta.folderCount}>
            <DNAButton
              appearance="ghost"
              context="minimum"
              iconLeft={isExpanded ? 'chevron-up' : 'chevron-right'}
              onPress={onExpand}
            />
          </Iffy>
          {/* Chevron Placeholder */}
          <Iffy is={!folderORM.meta.folderCount}>
            <DNABox style={{ width: 22, height: 25 }} />
          </Iffy>

          {/* Folder Info */}
          <DNAFolder mode={isChecked ? 'SELECTED' : 'ADD_TO_FOLDER' } folder={folderORM} />
          <DNAText>{folderORM.model.name}</DNAText>
        </DNABox>

        {/* Right side controls */}
        <DNABox alignY="center" spacing="medium">
          <DNAFolderPinButton
            isHidden={!folderORM.model.isPinned}
          />
          {
            showPopover
              ? <Popover placement="top">
                <Popover.Anchor>
                  {checkbox}
                </Popover.Anchor>
                <Popover.Content>
                  <DNAText status="basic">{disabledMessage}</DNAText>
                </Popover.Content>
              </Popover> : checkbox
          }
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default DNANestedFolderRow
