import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useContentViewerModalState } from '../../state/ContentViewerModalStateProvider'
import { Text } from '@alucio/lux-ui';

const styles = StyleSheet.create({
  subtitle: {
    fontWeight: 'bold',
    fontSize: 13,
  },
  notes: {
    paddingLeft: 20,
  },
  notesUndefined: {
    fontStyle: 'italic',
    textAlign: 'center',
    color: 'rgba(0,0,0,0.5)',
  },
})

function NotesSidebar(props) {
  const { activeSlide, visiblePages, customDeck, activeDocVersion } = useContentViewerModalState()
  const pages = activeDocVersion?.model.pages
  // When switching versions the doc is updated first then the slide #
  const notes = customDeck
    ? visiblePages[activeSlide - 1]?.speakerNotes
    : (pages.length > (activeSlide - 1))
      ? pages?.[activeSlide - 1]?.speakerNotes
      : '';

  return (
    <View style={[styles.notes, props.notesStyle]}>
      <Text style={notes ? null : styles.notesUndefined}>
        {notes || '-No speaker notes-'}
      </Text>
    </View>
  )
}

export default NotesSidebar;
