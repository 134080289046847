import _merge from 'lodash/merge'
import _capitalize from 'lodash/capitalize'
import { PurposeType, DocumentStatus, FileType } from '@alucio/aws-beacon-amplify/src/models'
import { DocumentORM } from 'src/types/types'
import { FilterAndSortOptions, multiTypeSort } from 'src/state/redux/selector/common'

export type FilterEntry = {
  fieldName: string,
  fieldValue: string,
  default: boolean,
  active: boolean,
}

type FilterSortMap<T extends string> = {
  [K in T]: FilterAndSortOptions<DocumentORM>
}

type FilterMap =
  FilterSortMap<
  'published'|
  'nonInternal'|
  'bookmarked'|
  'unpublished'|
  'notPublished'|
  'hidingEnabled'|
  'hasUnpublishedVersion'|
  'hasntUnpublishedVersion'|
  'nonDeleted'> &
  { defaultFilters: (requiredFilterFieldNames, activeFilters) => FilterAndSortOptions<DocumentORM> }

// [TODO-1793]
// - Merge should be able to take in a configuration to accept multiple
//    filter criteria on the same field
// - By default, it could be AND but the user should be able to configure it to OR
// - Would have to update the filterCollection to be able to handle an array of functions that apply the given criteria
export const merge: (
  ...args:FilterAndSortOptions<DocumentORM>[]
  ) => FilterAndSortOptions<DocumentORM> = (...args) => _merge({}, ...args)

export const filters: FilterMap = {
  published: {
    filter: { model: { status: 'PUBLISHED' } },
  },
  bookmarked: {
    filter: { meta: { bookmark: (b) => b.isBookmarked } },
  },
  nonInternal: {
    filter: {
      relations: {
        version: (v) => v.latestUsableDocumentVersion.model.purpose !== PurposeType.INTERNAL_USE_ONLY,
      },
    },
  },
  hidingEnabled: {
    filter: { relations: { version: (v) => v.latestUsableDocumentVersion.model.canHideSlides === true } },
  },
  unpublished: {
    filter: { model: { status: (s) => s === 'NOT_PUBLISHED' } },
  },
  notPublished: {
    filter: {
      model: { status: (s) => s !== 'NOT_PUBLISHED' },
    },
  },
  nonDeleted: {
    filter: { model: { status: (s) => s !== 'DELETED' } },
  },
  hasUnpublishedVersion: {
    filter: { meta: { hasUnpublishedVersion : true } },
  },
  hasntUnpublishedVersion: {
    filter: { meta: { hasUnpublishedVersion : false } },
  },
  defaultFilters: (requiredFilterFieldNames, activeFilters) => ({
    filter: {
      meta: {
        tenantFields: defaultTenantFieldDocumentFilter(requiredFilterFieldNames, activeFilters),
      },
    },
  }),
}

export const modalFilterSystemFieldsQueries:
  Record<string, (activeFilterFieldValues: string[]) => FilterAndSortOptions<DocumentORM>> =
  {
    Downloadable: (activeFilterFieldValues: string[]) => ({
      filter: {
        relations: {
          version: (v) => {
            const docVer = v.latestUsableDocumentVersion.model
            return activeFilterFieldValues.some(activeFilterFieldValue => (
              ((activeFilterFieldValue === 'Downloadable') &&
              (docVer?.downloadable || (docVer?.downloadable === null))) ||
            ((activeFilterFieldValue === 'Non-downloadable') && (docVer?.downloadable === false))
            ))
          },
        },
      },
    }),
    'Content Source': (activeFilterFieldValues: string[]) => ({
      filter: {
        relations: {
          version: (v) => {
            const docVer = v.latestUsableDocumentVersion.model
            return docVer.integrationType
              ? activeFilterFieldValues.includes(_capitalize(docVer.integrationType))
              : false
          },
        },
      },
    }),
    Distributable: (activeFilterFieldValues: string[]) => ({
      filter: {
        relations: {
          version: (v) => {
            const docVer = v.latestUsableDocumentVersion.model
            return activeFilterFieldValues.some(activeFilterFieldValue => (
              ((activeFilterFieldValue === 'Distributable') && docVer?.distributable) ||
            ((activeFilterFieldValue === 'Non-distributable') && !docVer?.distributable)
            ))
          },
        },
      },
    }),
    'Associated Files': (activeFilterFieldValues: string[]) => ({
      filter: {
        relations: {
          version: (v) => {
            const hasAsociatedFiles = v.latestUsableDocumentVersion.relations.associatedFiles.length
            return activeFilterFieldValues.some(activeFilterFieldValue => (
              ((activeFilterFieldValue === 'Has associated files') && hasAsociatedFiles)
            ))
          },
        },
      },
    }),
    Modifiable: (activeFilterFieldValues: string[]) => ({
      filter: {
        relations: {
          version: (v) => {
            const docVer = v.latestUsableDocumentVersion.model
            return activeFilterFieldValues.some(activeFilterFieldValue => (
              ((activeFilterFieldValue === 'Modifiable') && docVer?.canHideSlides) ||
            ((activeFilterFieldValue === 'Non-modifiable') && !docVer?.canHideSlides)
            ))
          },
        },
      },
    }),
    Status: (activeFilterFieldValues: string[]) => ({
      filter: {
        model: {
          status: (status) => {
            return activeFilterFieldValues.some(activeFilterFieldValue => (
              ((activeFilterFieldValue === 'Archived') && (status === DocumentStatus.ARCHIVED)) ||
            ((activeFilterFieldValue === 'Published') && (status === DocumentStatus.PUBLISHED)) ||
            ((activeFilterFieldValue === 'Not Published') && (status === DocumentStatus.NOT_PUBLISHED)) ||
            ((activeFilterFieldValue === 'Revoked') && (status === DocumentStatus.REVOKED))
            ))
          },
        },
      },
    }),
    Purpose: (activeFilterFieldValues: string[]) => ({
      filter: {
        relations: {
          version: (v) => {
            const docVer = v.latestUsableDocumentVersion.model
            return activeFilterFieldValues.some(activeFilterFieldValue => (
              ((activeFilterFieldValue === 'External - Proactive') &&
              (docVer?.purpose === PurposeType.EXTERNAL_PROACTIVE)) ||
            ((activeFilterFieldValue === 'External - Reactive') &&
            (docVer?.purpose === PurposeType.EXTERNAL_REACTIVE)) ||
            ((activeFilterFieldValue === 'Internal') && (docVer?.purpose === PurposeType.INTERNAL_USE_ONLY))
            ))
          },
        },
      },
    }),
    'Type': (activeFilterFieldValues: string[]) => ({
      filter: {
        model: {
          type : (type) => {
            return activeFilterFieldValues.some(activeFilterFieldValue => (
              ((activeFilterFieldValue === 'PDF') && (type === FileType.PDF)) ||
            ((activeFilterFieldValue === 'PPTX') && (type === FileType.PPTX))
            ))
          },
        },
      },
    }),
  }

// For every tenant field that has an active filter value
//  The document must match at least 1 value for the identified tenant field
export function defaultTenantFieldDocumentFilter(requiredFilterFieldNames: string[], activeFilters: FilterEntry[]) {
  return (tenantFields) => {
    if (!activeFilters.length) return true;
    const satisfyRequiredFieldNames = {}

    for (const activeFilter of activeFilters) {
      const documentFieldValue = tenantFields.valuesMap[activeFilter.fieldName]

      if (documentFieldValue == null) {
        satisfyRequiredFieldNames[activeFilter.fieldName] = false;
      } else if (documentFieldValue.includes(activeFilter.fieldValue)) {
        satisfyRequiredFieldNames[activeFilter.fieldName] = true
      }

      if (Object.keys(satisfyRequiredFieldNames).length === requiredFilterFieldNames.length) { return true }
    }

    return false
  }
}

export const sorts: FilterSortMap<
'updatedAtAsc'|
'updatedAtDesc'|
'titleAsc'|
'titleDesc'|
'bookmarkedAsc'|
'bookmarkedDesc'
> = {
  updatedAtAsc: {
    sort: [{ model: { updatedAt: 'asc' } }],
  },
  updatedAtDesc: {
    sort: [{ model: { updatedAt: 'desc' } }],
  },
  titleAsc: {
    sort: [{
      relations: {
        version: (a, b) => {
          const docVerA = a.latestUsableDocumentVersion.model
          const docVerB = b.latestUsableDocumentVersion.model
          return multiTypeSort(docVerA?.title, docVerB?.title, { dir: 'asc' })
        },
      },
    }],
  },
  titleDesc: {
    sort: [{
      relations: {
        version: (a, b) => {
          const docVerA = a.latestUsableDocumentVersion.model
          const docVerB = b.latestUsableDocumentVersion.model
          return multiTypeSort(docVerA?.title, docVerB?.title, { dir: 'desc' })
        },
      },
    }],
  },
  bookmarkedAsc: {
    sort: [{ meta: { bookmark: (a, b) => multiTypeSort(a.createdAt, b.createdAt, { dir: 'asc' } ) } }],
  },
  bookmarkedDesc: {
    sort: [{ meta: { bookmark: (a, b) => multiTypeSort(a.createdAt, b.createdAt, { dir: 'desc' } ) } }],
  },
}

export default {
  merge,
  filters,
  sorts,
}
