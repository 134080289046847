import { createMachine, spawn, InvokeCallback } from 'xstate'
import { assign } from '@xstate/immer'
import {
  Dimensions,
  PDFChannelMessage,
  PDFMessageTypes,
  PlayerMode,
  PPZTransform,
  PresentationContentState,
  SetPresentationMeta,
} from '@alucio/core'
import { RATIOS } from '@alucio/lux-ui/src/components/layout/DNAAspectRatio/DNAAspectRatio';
import { v4 } from 'uuid'
import { Logger } from '@aws-amplify/core';
import { BroadcastChannel } from 'broadcast-channel'
import * as PW from './playerWrapperTypes'
import { AlucioChannel } from '@alucio/lux-ui';
import equal from 'fast-deep-equal';

export * as PW from './playerWrapperTypes'
const logger = new Logger('PlayerWrapperSM', 'INFO');

type ChannelObserverCallback<T extends PW.PlayerWrapperEvents> = InvokeCallback<T, PW.PlayerWrapperEvents>

// [TODO] - Consider bumping XState for some new features
//          - Arbitrary arguments in guards
//          - Better TS experience

// [TODO] - Consider creating a generic observer interface (zen-observable -- set workerChannel)
const presentationStateObserver = (channel: PW.PlayerWrapperContext['presentationStateChannel']):
  ChannelObserverCallback<PW.EVT_PRESENTATION_STATE_SYNC> =>
  (send) => {
    const handler = (msg: PW.PresentationChannelMessage) => {
      // We only pay attention to PRESENTATION_STATE_SYNC events which are broadcast
      // by the PresentationBroadCastProvider
      logger.debug('Got Presentation Channel Message', msg)
      if (msg.type === 'PRESENTATION_STATE_SYNC') {
        send({
          type: 'PRESENTATION_STATE_SYNC',
          meetingId: msg.meetingId,
          payload: msg.payload,
        })
      }
      if (msg.type === 'PRESENTATION_STATE_IDLE') {
        send({
          type: 'PRESENTATION_STATE_IDLE',
          meetingId: msg.meetingId,
        })
      }
    }

    logger.debug('attaching listener to presentation channel', channel)
    channel.addEventListener(
      'message',
      handler,
    )
  }

const playerStateObserver = (channel: PW.PlayerWrapperContext['playerStateChannel']):
  ChannelObserverCallback<PW.EVT_PLAYER_ACTION> =>
  (send) => {
    const handler = (msg: PDFChannelMessage) => {
      logger.debug('Got message from Player', msg)
      send({
        type: 'PLAYER_ACTION',
        payload: msg,
      })
    }
    logger.debug('attaching listener to channel', channel)
    channel.addEventListener(
      'message',
      handler,
    )
  }

const PlayerWrapper = (
  meetingId: string = 'aaaaaa',
  playerMode: PlayerMode,
  frameId: string = v4(),
) => createMachine<
  PW.PlayerWrapperContext,
  PW.PlayerWrapperEvents,
  PW.PlayerWrapperState
>(
  {
    id: 'PlayerWrapper',
    context: {
      aspectRatio: RATIOS['16_9'],
      frameId,
      meetingId,
      playerMode,
      // We need to create a new channel rather than use AlucioChannel.get otherwise the context and wrapper
      // are using the same channel object you we won't receive any messages
      presentationStateChannel: new BroadcastChannel<PW.PresentationChannelMessage>('PRESENTATION_CHANNEL'),
      playerStateChannel: AlucioChannel.get(AlucioChannel.commonChannels.PDF_CHANNEL),
    },
    initial: 'idle',
    entry: assign(ctx => {
      if (!ctx.presentationStateObserver) {
        // [TODO] - Try to get types working (returns an unsub function)
        const actor = spawn(
          presentationStateObserver(ctx.presentationStateChannel),
          'presentationStateObserverActor',
        )

        ctx.presentationStateObserver = actor
      }

      if (!ctx.playerStateObserver) {
        const actor = spawn(
          playerStateObserver(ctx.playerStateChannel),
          'playerStateObserverActor',
        )

        ctx.playerStateObserver = actor
      }
    }),
    states: {
      idle: {
        tags: [PW.Tags.IS_IDLE],
        on: {
          PRESENTATION_STATE_SYNC: {
            target: 'presenting.loading.initializing',
            actions: [
              'logEvent',
              'setPresentableState',
              'clearPlayerState',
            ],
          },
        },
      },
      presenting: {
        states: {
          loading: {
            initial: 'initializing',
            states: {
              initializing: {
                entry: assign((ctx) => { ctx.frameId = v4() }),
                on: {
                  PLAYER_ACTION: {
                    cond: 'playerInitialized',
                    actions: [
                      'logEvent',
                      'setPlayerMode',
                    ],
                    target: 'initialized',
                  },
                },
              },
              initialized: {
                on: {
                  // An empty event name causes this to be evaluated anytime there's a transition either to
                  // or within the state. This allows for re-evaluation of this condition when a new presentable
                  // comes in
                  '': {
                    cond: (ctx) => !!ctx.presentableState && ctx.presentableState.JWT !== 'PENDING',
                    actions: [
                      'loadDocument',
                    ],
                    target: 'loading',
                  },
                  PRESENTATION_STATE_SYNC: {
                    actions: [
                      'logEvent',
                      'setPresentableState',
                    ],
                  },
                },
              },
              loading: {
                on: {
                  PLAYER_ACTION: {
                    cond: 'playerDocumentLoaded',
                    target: '#PlayerWrapper.presenting.ready',
                    actions: assign((ctx, evt) => {
                      logger.debug(evt.type, { ctx, evt })
                      ctx.playerState = ctx.presentableState
                    }),
                  },
                },
              },
            },
            on: {
              PRESENTATION_STATE_SYNC: [
                {
                  cond: 'loadNewDocument',
                  target: '#PlayerWrapper.presenting.loading.initializing',
                  actions: [
                    'logEvent',
                    'setPresentableState',
                    'clearPlayerState',
                  ],
                },
              ],
            },
          },
          ready: {
            tags: [PW.Tags.IS_CONTENT_LOADED],
            on: {
              PLAYER_ACTION: {
                cond: 'isMyIFrameEvt',
                actions: [
                  'logEvent',
                  'resolvePlayerEvent',
                ],
              },
              PRESENTATION_STATE_SYNC: [
                {
                  cond: 'loadNewDocument',
                  target: '#PlayerWrapper.presenting.loading.initializing',
                  actions: [
                    'logEvent',
                    'setPresentableState',
                    'clearPlayerState',
                  ],
                },
                {
                  cond: 'presentationStateChanged',
                  actions: [
                    'logEvent',
                    'setPresentableState',
                    'applyPresentableState',
                  ],
                },
              ],
            },
          },
        },
      },
    },
    on: {
      PRESENTATION_STATE_IDLE:
      {
        cond: 'isMyMeetingEvt',
        target: 'idle',
        actions: [
          'logEvent',
          'setPresentableState',
          'clearPlayerState',
        ],
      },
    },
  },
  {
    guards: {
      isMyIFrameEvt: (ctx, event) => {
        const evt = event as PW.EVT_PLAYER_ACTION
        return ctx.frameId === evt.payload.frameId
      },
      isMyMeetingEvt: (ctx, event) => {
        const evt = event as (PW.EVT_PRESENTATION_STATE_IDLE | PW.EVT_PRESENTATION_STATE_SYNC)
        return ctx.meetingId === evt.meetingId
      },
      loadNewDocument: (ctx, event) => {
        const evt = event as PW.EVT_PRESENTATION_STATE_SYNC
        return ctx.meetingId === evt.meetingId &&
          !!evt.payload.documentVersionId &&
          !!ctx?.playerState?.documentVersionId &&
          (ctx?.presentableState?.documentVersionId !== evt.payload.documentVersionId ||
            ctx?.playerState?.documentVersionId !== evt.payload.documentVersionId)
      },
      presentationStateChanged: (ctx, event) => {
        const evt = event as PW.EVT_PRESENTATION_STATE_SYNC
        return evt.meetingId === ctx.meetingId && !equal(ctx.presentableState, evt.payload)
      },
      playerInitialized: (ctx, event) => {
        const evt = event as PW.EVT_PLAYER_ACTION
        return evt.payload.frameId === ctx.frameId && evt.payload.type === 'IFRAME_LOADED'
      },
      playerDocumentLoaded: (ctx, event) => {
        const evt = event as PW.EVT_PLAYER_ACTION
        return evt.payload.frameId === ctx.frameId && evt.payload.type === 'DOCUMENT_LOADED'
      },
    },
    actions: {
      logEvent: (ctx, evt, meta) => {
        logger.debug(evt.type, { ctx, evt, meta })
      },
      loadDocument: (ctx, _, __) => {
        logger.debug('actions.loadDocument')
        const contentState = ctx.presentableState
        if (contentState) {
          const msg = {
            type: PDFMessageTypes.LOAD_FILE,
            frameId: ctx.frameId,
            value: {
              JWT: contentState.JWT,
              bucket: contentState.bucket,
              docPath: contentState.docPath,
              documentVersionId: contentState.documentVersionId,
              documentId: contentState.documentId,
              groupId: contentState.groupId,
              contentType: contentState.contentType,
              visiblePages: contentState.visiblePages,
              state: {
                page: contentState.state.page,
                step: contentState.state.step,
              },
            },
          }
          logger.debug('sending LOAD_DOCUMENT message')
          ctx.playerStateChannel.postMessage(msg)
        }
      },
      clearPlayerState: assign((ctx) => {
        ctx.playerState = undefined
      }),
      applyPresentableState: assign((ctx, _, __) => {
        logger.debug('actions.applyPresentableState')
        if (ctx.presentableState && ctx.playerState) {
          const presentableState = ctx.presentableState
          const playerState = ctx.playerState
          if (presentableState.groupId !== playerState.groupId) {
            logger.debug('Detected GroupID Change from Presentable')
            const payload: SetPresentationMeta = {
              groupId: presentableState.groupId,
              slide: presentableState.state.page,
              step: presentableState.state.step,
              visiblePages: [...presentableState.visiblePages],
            }
            ctx.playerStateChannel.postMessage({
              type: PDFMessageTypes.SET_PRESENTATION_META,
              frameId: ctx.frameId,
              value: payload,
            })
            playerState.groupId = presentableState.groupId
            playerState.state = presentableState.state
            playerState.visiblePages = presentableState.visiblePages
          } else if (!equal(presentableState.state, playerState.state)) {
            logger.debug('Detected Presentation State (Progress) Change from Presentable')
            const payload: PresentationContentState = {
              documentVersionId: presentableState.documentVersionId,
              groupId: presentableState.groupId,
              page: presentableState.state.page,
              step: presentableState.state.step,
              viewport: {
                positionX: presentableState.ppzCoords?.positionX ?? 0,
                positionY: presentableState.ppzCoords?.positionY ?? 0,
                scale: presentableState.ppzCoords?.scale ?? 1,
              },
            }
            ctx.playerStateChannel.postMessage({
              type: PDFMessageTypes.SET_PRESENTATION_STATE,
              frameId: ctx.frameId,
              value: payload,
            })
            playerState.state.page = presentableState.state.page
            playerState.state.step = presentableState.state.step
          } else if (!equal(presentableState.ppzCoords, playerState.ppzCoords) && presentableState.ppzCoords) {
            logger.debug('Detected PPZ Change from Presentable')
            const payload: PPZTransform = {
              positionX: presentableState.ppzCoords.positionX,
              positionY: presentableState.ppzCoords.positionY,
              scale: presentableState.ppzCoords.scale,
            }
            ctx.playerStateChannel.postMessage({
              type: PDFMessageTypes.PPZ_TRANSFORM,
              frameId: ctx.frameId,
              value: payload,
            })
          }
        } else {
          logger.debug('No changes detected between Presentable and Player State')
        }
      }),
      resolvePlayerEvent: assign((ctx, event) => {
        logger.debug('actions.resolvePlayerEvent')
        const evt = event as PW.EVT_PLAYER_ACTION
        const shouldBroadcast = ctx.playerMode === 'INTERACTIVE'
        switch (evt.payload.type) {
          case 'PDF_PAGE_LOADED': {
            const pageBounds = evt.payload.value as Dimensions
            const pageRatio = parseFloat(
              (pageBounds.height / pageBounds.width).toFixed(2),
            )
            ctx.aspectRatio = pageRatio === RATIOS['4_3']
              ? RATIOS['4_3']
              : RATIOS['16_9']
            break
          }
          case 'NAVIGATE_PAST_FIRST': {
            logger.debug('Broadcasting NAVIGATE_PAST_FIRST')
            shouldBroadcast && ctx.presentationStateChannel.postMessage({
              type: 'NAVIGATE_PAST_FIRST',
              meetingId: ctx.meetingId,
            })
            break
          }
          case 'NAVIGATE_PAST_LAST': {
            logger.debug('Broadcasting NAVIGATE_PAST_LAST')
            shouldBroadcast && ctx.presentationStateChannel.postMessage({
              type: 'NAVIGATE_PAST_LAST',
              meetingId: ctx.meetingId,
            })
            break
          }
          case 'SHOW_SEARCH': {
            logger.debug('Broadcasting SHOW_SEARCH')
            shouldBroadcast && ctx.presentationStateChannel.postMessage({
              type: 'SHOW_SEARCH',
              meetingId: ctx.meetingId,
            })
            break
          }
          case 'SHOW_MY_CONTENT': {
            logger.debug('Broadcasting SHOW_MY_CONTENT')
            shouldBroadcast && ctx.presentationStateChannel.postMessage({
              type: 'SHOW_MY_CONTENT',
              meetingId: ctx.meetingId,
            })
            break
          }
          case 'PPZ_TRANSFORM': {
            const newPPZ = evt.payload.value as PPZTransform
            if (ctx.playerState) {
              ctx.playerState.ppzCoords = newPPZ
            }
            if (!equal(ctx.presentableState?.ppzCoords, newPPZ)) {
              logger.debug('Broadcasting PPZ Change', newPPZ)
              shouldBroadcast && ctx.presentationStateChannel.postMessage({
                type: 'PPZ_TRANSFORM',
                meetingId: ctx.meetingId,
                payload: newPPZ,
              })
            }
            break
          }
          case 'PAGE_CHANGE': {
            const newState = evt.payload.value as PresentationContentState
            if (ctx.playerState) {
              ctx.playerState.state.page = newState.page ?? ctx.playerState.state.page
              ctx.playerState.state.step = newState.step ?? ctx.playerState.state.step
            }
            if (newState.page !== ctx.presentableState?.state.page ||
              newState.step !== ctx.presentableState?.state.step) {
              const payload = {
                page: newState.page ?? 0,
                step: newState.step ?? 0,
                groupId: newState.groupId ?? '',
              }
              logger.debug('Broadcasting Presentation Progress Change', payload)
              shouldBroadcast && ctx.presentationStateChannel.postMessage({
                type: 'PRESENTATION_PROGRESS',
                meetingId: ctx.meetingId,
                payload: payload,
              })
            }
            break
          }
          default: {
            logger.warn(`Unrecognized Player Event ${evt.payload.type} encountered`)
          }
        }
      }),
      setPlayerMode: (ctx) => {
        logger.debug('actions.setPlayerMode')
        ctx.playerStateChannel.postMessage({
          type: PDFMessageTypes.PLAYER_MODE,
          frameId: ctx.frameId,
          value: {
            mode: ctx.playerMode,
          },
        })
      },
      setPresentableState: assign((ctx, event) => {
        logger.debug('actions.setPresentableState')
        const evt = event as PW.EVT_PRESENTATION_STATE_SYNC
        ctx.presentableState = evt.payload
      }),
    },
  },
)

export default PlayerWrapper
