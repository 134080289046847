import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui';
import React from 'react';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';

const PresentationNav = () => {
  const { meetingORM } = useMeetingsState();
  const { activePresentation, nextPage, prevPage } = useContent();
  const isVirtual = meetingORM?.model.type === 'VIRTUAL';
  const presentable = activePresentation?.presentable;
  const currentPageNumber = activePresentation?.currentPresentablePage.presentationPageNumber;

  const goToPreviousSlide = () => {
    prevPage()
  }

  const goToNextSlide = () => {
    nextPage()
  }

  return (
    <DNABox spacing="small" alignY="center">
      <DNAButton
        context="square"
        size="medium"
        appearance="filled"
        status={isVirtual ? 'slate' : 'flat'}
        iconLeft="chevron-left"
        onPress={goToPreviousSlide}
        testID="previous-slide-button"
      />
      <DNAText status="basic">
        {`Slide ${currentPageNumber} of ${presentable?.numberOfPages}`}
      </DNAText>
      <DNAButton
        context="square"
        size="medium"
        appearance="filled"
        status={isVirtual ? 'slate' : 'flat'}
        iconLeft="chevron-right"
        onPress={goToNextSlide}
        testID="next-slide-button"
      />
    </DNABox>
  )
}

export default PresentationNav;
