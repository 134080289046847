import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { Tenant, FieldConfig, User } from '@alucio/aws-beacon-amplify/src/models';
import { RootState } from '../store';

type Options = {
  defaultSearchFilter: FieldConfig['defaultSearchFilter']
}

// [TODO]: REFACTOR THE ORM STRUCTURE
export interface TenantORM {
  tenant: Tenant,
  meta: {
    users: User[],
  }
}

const selUsers = (state: RootState): User[] => state.user.records;
export const selTenants = (state: RootState): Tenant[] => state.tenant.records;
export const selProps = (_, props) => props;
export const selOptions = (_, __, opts: Options = { defaultSearchFilter: true }) => opts;
export const selTenantId = (_, __, tenantId: string) => tenantId;

export const tenantListORM = createSelector(
  selUsers,
  selTenants,
  (users, tenants): TenantORM[] =>
    tenants.map((tenant) => ({
      tenant,
      meta: {
        users: users.filter((user) => user.tenantId === tenant.id),
      },
    })),
);

export const tenantList = createSelector(
  selTenants,
  (tenants): Tenant[] => tenants,
);

export const tenantFields = createSelector(
  tenantList,
  selProps,
  selOptions,
  (tenants, _, opts): FieldConfig[] => {
    const [tenant]  = tenants
    const { defaultSearchFilter } = opts

    return tenant?.fields
      ?.filter(field => defaultSearchFilter ? field.defaultSearchFilter : true) ??
      []
  },
);

export const tenant = createSelector(
  tenantListORM,
  selTenantId,
  (tenants, tenantId): TenantORM | undefined => {
    return tenants.find(({ tenant }) => tenant.id === tenantId);
  },
);

/** Returns the list of Tenants */
export const useTenantList = (): ReturnType<typeof tenantListORM> =>
  useSelector((state: RootState) => tenantListORM(state));

/** Returns a tenant based on the given id */
export const useTenant = (tenantId: string): ReturnType<typeof tenant> =>
  useSelector((state: RootState) => tenant(state, undefined, tenantId));

/** Returns tenant fields who match the provided filter options */
export const useTenantFields = (opts?: Options): ReturnType<typeof tenantFields> =>
  useSelector((state: RootState) => tenantFields(state, undefined, opts))
