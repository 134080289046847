import React from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { DNABox, Iffy } from '@alucio/lux-ui';
import Header from './Header/Header';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import OpenedPresentations from './TabContent/OpenedPresentations/OpenedPresentations';
import MeetingDetails from './TabContent/MeetingDetails/MeetingDetails';
import Presenting from './TabContent/Presenting';
import Modal from 'react-native-modal'
import Footer from './Footer/Footer';
import BrowseContent from './TabContent/BrowseContent';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { Animation } from 'react-native-animatable'
import CurrentlyPresentingPanel from './SidePanels/CurrentlyPresenting';

/** Define the structure of a variant */
interface VariantConfig {
  /** Used to define unique variant styles */
  styles?: ViewStyle,
  animations?: {
    in?: Animation,
    out?: Animation
  }
}
/** Define a mapping for each variant type */
type Variants = Record<MeetingType, VariantConfig>
/** Define the values for each variant type */
const variants: Variants = {
  IN_PERSON: {
    styles: {
      maxWidth: 700,
    },
    animations: {
      in: 'slideInLeft',
      out: 'slideOutLeft',
    },
  },
  VIRTUAL: {
    animations: {
      in: 'slideInUp',
      out: 'slideOutDown',
    },
  },
  MANUAL: {},
}

const styles = StyleSheet.create({
  /** Styles which are common across variants should be placed here */
  tabContainer: {
    /** TODO: update this in upcoming theming refactor */
    backgroundColor: '#0F0F0F',
  },
})

export enum Tabs {
  BROWSE_CONTENT,
  OPENED_PRESENTATIONS,
  MEETING_DETAILS,
  PRESENTING,
}
export type Tab = keyof typeof Tabs
interface TabConfig {
  testID: string,
  title: string,
  component: React.ElementType
}

type TabOptions = Record<Tab, TabConfig>

export const tabs: TabOptions = {
  BROWSE_CONTENT: {
    testID: 'browse-content',
    title: 'Browse Content',
    component: BrowseContent,
  },
  OPENED_PRESENTATIONS: {
    testID: 'opened-presentations',
    title: 'Opened Presentations',
    component: OpenedPresentations,
  },
  MEETING_DETAILS: {
    testID: 'meeting-details',
    title: 'Meeting Details',
    component: MeetingDetails,
  },
  PRESENTING: {
    testID: 'presenting',
    title: 'Presenting',
    component: Presenting,
  },
};

const PresentationControls = () => {
  const { presentationControlsVisible, currentTab, meetingORM } = useMeetingsState()
  const { presentations } = useContent()

  if (!meetingORM) {
    console.error('Meeting ORM not found');
    return null;
  }

  const { type } = meetingORM.model
  const variant = variants[type]
  const isVirtualMode = type === 'VIRTUAL'
  const isInPerson = type === 'IN_PERSON'
  const availableTabs = Object.entries(tabs).filter((tab) => isVirtualMode || tab[0] !== 'PRESENTING')
  const tabContentElements = availableTabs.map((tab) => {
    const isActive = currentTab === tab[0]
    const Component = tab[1].component
    const containerStyle: ViewStyle | undefined = isActive
      ? undefined
      : {
        position: 'absolute',
        zIndex: -1,
        opacity: 0,
      }

    return (
      <DNABox fill style={containerStyle}>
        <Component />
      </DNABox>
    )
  })

  return (
    <Modal
      animationIn={variant.animations?.in}
      animationOut={variant.animations?.out}
      isVisible={presentationControlsVisible}
      hasBackdrop={false}
      style={{ margin: 0 }}
    >
      <DNABox
        style={[styles.tabContainer, variant.styles]}
        appearance="col"
        fill
      >
        {/* HEADER */}
        <Header />
        {/* CONTENT */}
        <DNABox fill appearance="row">
          {/* <CurrentTab /> */}
          {tabContentElements}
          <Iffy is={isVirtualMode && currentTab !== 'PRESENTING'}>
            <CurrentlyPresentingPanel />
          </Iffy>
        </DNABox>
        {/* CURRENT STATUS FOOTER */}
        <Iffy is={presentations.length <= 0 || isInPerson}>
          <Footer />
        </Iffy>
      </DNABox>
      {/* CURRENT STATUS FOOTER */}
    </Modal>
  );
};

PresentationControls.displayName = 'PresentationControls';

export default PresentationControls;
