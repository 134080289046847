import React from 'react'
import { useDispatch } from 'react-redux'
import { folderActions } from 'src/state/redux/slice/folder'

import { DNAModal, DNAText, GenericToast, ToastOrientations } from '@alucio/lux-ui'
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import { FolderORM } from 'src/types/types'
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast'

export type DNAFolderDeletetModalProps = {
  folderORM: FolderORM
  toast?: ToastActions
}

export const DNAFolderDeleteModal: DNAModalVariant<DNAFolderDeletetModalProps> = (props) => {
  const { folderORM, toggleModal, toast } = props
  const dispatch = useDispatch()

  const onAction = () => {
    dispatch(folderActions.removeFolder(folderORM))
    toast?.add(
      <GenericToast
        title="Folder deleted."
        status="error"
      />,
      ToastOrientations.TOP_RIGHT,
    )
    toggleModal()
  }

  return (
    <DNAModal>
      <DNAModal.Header>Sure you want to delete?</DNAModal.Header>
      <DNAModal.Message>
        The following folder will be removed from the Beacon database permanently
      </DNAModal.Message>
      <DNAModal.Body>
        <DNAText bold>{folderORM.model.name}</DNAText>
      </DNAModal.Body>
      <DNAModal.Confirmation
        onCancel={toggleModal}
        onAction={onAction}
        actionLabel="Delete"
        actionStatus="danger"
      />
    </DNAModal>
  )
}

export default DNAFolderDeleteModal
