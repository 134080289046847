import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
  FlexStyle,
  Pressable,
  ViewStyle,
  StyleProp,
} from 'react-native';
import {
  DNABox,
  DNAChip,
  DNAText,
  Icon,
  Iffy,
  luxColors,
  Spinner,
  Stack,
  useMouseEvent,
  util,
} from '@alucio/lux-ui';
import { DocumentVersion as DocumentVersionModel } from '@alucio/aws-beacon-amplify/src/models'
import ItemWrapper from './Wrapper/Wrapper';

import './Style'
import { presentationControlActions } from 'src/state/redux/slice/PresentationControl/presentationControl';
import { PlayerActions } from '@alucio/video';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useDispatch } from 'react-redux';
import { PageExtended } from 'src/types/types';
import { v4 as uuidv4 } from 'uuid'
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
import { getImageObjectURLFromCloudfront } from 'src/utils/loadCloudfrontAsset/common';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import SlidePreviewModal from '../SlidePreviewModal';

const styles = StyleSheet.create({
  slideRollModal: {
  },
  slideRollModalTablet: {
    maxWidth: 'auto',
    backgroundColor: 'white',
    marginLeft: -1,
    marginBottom: -1,
    marginRight: -1,
    paddingTop: 10,
    maxHeight: 115,
  },
  darkenedImage: {
    tintColor: luxColors.basicBlack.primary,
    opacity: 0.4,
    resizeMode: 'contain',
    width: '100%',
  },
  slideRollFS: {
    backgroundColor: luxColors.contentPanelBackground.primary,
  },
  image: {
    flex: 1,
    resizeMode: 'contain',
  },
  spinner: {
    paddingLeft: 48,
    paddingTop: 40,
  },
  stackLayer: {
    width: '100%',
  },
  thumbnailTouch: {
    flex: 1,
  },
  thumbnail: {
    flex: 1,
    borderColor: luxColors.thumbnailBorder.secondary,
    borderWidth: 2,
  },
  thumbnailNumber: {
    margin: 5,
    marginBottom: 10,
    width: 20,
  },
  outerThumbContainerStyle: {
    borderWidth: 3,
    borderRadius:6,
    borderColor:'#DBDBDB',
    marginBottom: 4,
  },
  innerThumbContainerStyle: {
    borderWidth: 3,
    borderRadius: 3,
    borderColor:'white',
  },
})

interface SlideRollProps {
  documentVersion: DocumentVersionModel;
  isCustomDeck?: boolean;
  activeSlide: number;
  isGridView?: boolean;
  horizontal?: boolean;
  itemHeight?: number;
  itemWidth?: number;
  numberColor?: string;
  contentUnavailable?: boolean;
  isFullWindow?: boolean
  numberPosition?: 'bottom' | 'left' | 'top' | 'right';
  selectedItemBorderColor?: 'success' | 'default';
  selectedNumberColor?: 'success' | 'default';
  setActiveSlide: (index: number) => void;
  scrollToSelected?: boolean;
  darkenUnselected?: boolean;
  visiblePages?: PageExtended[];
  variant?: 'default' | 'customDeck',
  showRightMenu?: boolean,
  showPreview?: boolean,
  keepSpacingLastItem?: boolean,
}
interface NumberPosition {
  [key: string]: FlexStyle['flexDirection'];
}

const SELECTED_BORDER = {
  default: luxColors.thumbnailBorder.primary,
  success: luxColors.success.primary,
};

const SELECTED_NUMBER = {
  default: luxColors.basicBlack.primary,
  success: luxColors.success.primary,
};

const NUMBER_POSITION: NumberPosition = {
  bottom: 'column',
  top: 'column-reverse',
  left: 'row-reverse',
  right: 'row',
};

interface Img64Page extends PageExtended {
  img64: string
  key: string
}

function SlideRoll(props: SlideRollProps) {
  const {
    isCustomDeck,
    contentUnavailable,
    activeSlide,
    setActiveSlide,
    documentVersion,
    itemHeight = 67,
    isFullWindow,
    itemWidth = 120,
    isGridView,
    horizontal = true,
    numberColor,
    selectedItemBorderColor = 'default',
    numberPosition = 'bottom',
    selectedNumberColor,
    scrollToSelected,
    visiblePages,
    variant = 'default',
    showRightMenu,
    showPreview,
    keepSpacingLastItem,
  } = props

  /** Allow vertical wheel scroll to trigger horizontal scrolling */
  const handleMouseEvent = (e:MouseEvent | Event | TouchEvent) => {
    if (e instanceof WheelEvent) {
      const scrollEl = scrollViewRef.current?.getScrollableNode() as HTMLElement
      if (scrollEl) { scrollEl.scrollLeft += e.deltaY }
    }
  }
  useMouseEvent(handleMouseEvent)

  const { deviceMode } = useAppSettings()
  const dispatch = useDispatch()
  const isTablet = deviceMode === 'tablet'
  const [pages, setPages] = useState<Img64Page[]>()
  const scrollViewRef = useRef<ScrollView>(null)
  const visibleDocumentPages = visiblePages || documentVersion.pages;

  // If we're in horizontal mode memoize positions based on width, otherwise memoise based on height
  const itemsPosition =
    useMemo(
      () => {
        // Given the space of the item (width or height), this returns the position of each one.
        const getItemsPosition = (itemSize) => (pages || [])
          .map((page, index) => index === 0
            ? 0
            : index * (itemSize + 8),
          )

        return horizontal
          ? getItemsPosition(itemWidth)
          : getItemsPosition(itemHeight)
      },
      [pages, horizontal],
    ),

    // DynamicStyles
    darkenedImageStyle = [styles.image, styles.darkenedImage, { height: itemHeight }],
    thumbnailWrapperStyle:StyleProp<ViewStyle> = {
      width: itemWidth,
      flexDirection: NUMBER_POSITION[numberPosition],
      paddingTop: 12,
      marginHorizontal: variant === 'customDeck' ? 15 : undefined,
    }

  useEffect(() => {
    let isComponentMounted = true;
    // Get images from cloudfont, using the auth token
    async function init() {
      const populatedPages = await Promise.all(
        visibleDocumentPages.map(async (page: PageExtended) => {
          const archivedKey = detectArchivedFileKeyPath(page.documentVersion?.model, page, 'small' ) ||
            detectArchivedFileKeyPath(documentVersion, page, 'small')
          // TODO: We should switch to using blobs here. Base64, while fun, has lots of overhead
          // both in filesize and dom performance. See slideSelector.tsx
          // const img64 = await getImageFromCloudfront(archivedKey!)
          // return ({ ...page, img64: img64, key: uuidv4() })

          const blob = await getImageObjectURLFromCloudfront(archivedKey!)
          return ({ ...page, img64: blob, key: uuidv4() })
        }) ?? [],
      );
      if (isComponentMounted) {
        setPages(populatedPages);
      }
    }

    init()
    return () => {
      isComponentMounted = false;
    }
  }, [documentVersion?.convertedFolderKey, visibleDocumentPages]);

  // Uses the memoised positions of thumbnails to autoscroll the tray so that the active slide is
  // always in the first position
  function scrollToItem(): void {
    if (scrollToSelected) {
      const activeSlideIndex = isCustomDeck ? activeSlide - 1
        : visiblePages?.findIndex(({ number }) => number === activeSlide) || (activeSlide - 1);

      // Account for horizontal/vertical orientation
      const x = horizontal ? itemsPosition[activeSlideIndex] : 0
      const y = horizontal ? 0 : itemsPosition[activeSlideIndex]

      scrollViewRef?.current?.scrollTo({ x, y, animated: true });
    }
  }
  useEffect(scrollToItem, [activeSlide, visiblePages]);

  const thumbnails = !contentUnavailable && pages?.map((e: Img64Page, index) => {
    const isSelected = activeSlide === (isCustomDeck ? index + 1 : e.number);
    const numberColorStyle = selectedNumberColor && isSelected
      ? { color: SELECTED_NUMBER[selectedNumberColor] }
      : numberColor ? { color: numberColor } : null
    const numberStyle = util.mergeStyles(
      undefined,
      styles.thumbnailNumber,
      numberColorStyle,
      [{ textAlign: 'right' }, numberPosition === 'right'],
    )

    function onSelected(): void {
      // NOTE: FOR CUSTOM DECKS, SINCE SAME PAGE CAN BE USED MULTIPLE TIMES, THE ONLY WAY FOR US TO KNOW
      // IN WHICH PAGE ARE WE (ACTIVE SLIDE), IS BY THE INDEX OF THE PAGE IN THE VISIBLEPAGES ARRAY
      setActiveSlide(isCustomDeck ? index + 1 : e.number);
      /**
       * TODO: Temporary workaround. The call above is duplicative but stripping out the setactiveslide
       * call above causes the presentation not to show in virtual. Will continue to investigate
       */
      // IF WE'RE IN A CUSTOM DECK, THE IFRAME WILL HANDLE THIS SINCE THE NEW PAGE MIGHT BE OF ANOTHER DOCUMENT
      if (!isCustomDeck) {
        dispatch(
          presentationControlActions.triggerPresentationAction(
            { action: PlayerActions.gotoSlide, param: e.number },
          ),
        )
      }
    }

    const onPreview = () => {
      const pageNumber = isCustomDeck ? e.number : index + 1;
      const page = visiblePages?.find(({ number }) => number === pageNumber)
      const archivedKey = page ? (detectArchivedFileKeyPath(documentVersion, page, 'medium') ||
        detectArchivedFileKeyPath(page.documentVersion?.model, page, 'medium')) : ''

      dispatch(
        DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => (<SlidePreviewModal
            presentPage={onSelected}
            archivedKey={archivedKey || ''}
            {...props}
          />),
        }))
    }

    if (variant === 'default') {
      return (
        <DNABox style={thumbnailWrapperStyle} key={e.pageId + e.groupId}>
          <ItemWrapper
            onPresent={onSelected}
            onPreview={onPreview}
            showPreview={showPreview}
            showPresentHover={isGridView}
            itemHeight={itemHeight}
          >
            {/* Use TouchableOpacity here to retain onFocus prop (may be implemented later in Pressable) */}
            <TouchableOpacity
              onPress={onSelected}
              onFocus={onSelected}
              activeOpacity={1}
              style={styles.thumbnailTouch}
            >
              <DNABox
                style={util.mergeStyles(
                  undefined,
                  styles.thumbnail,
                  [{ borderColor: SELECTED_BORDER[selectedItemBorderColor] },
                    isSelected],
                )}
              >
                <Stack>
                  <Stack.Layer>
                    <Image
                      source={{ uri: e.img64 }}
                      style={[styles.image, { height: itemHeight }]}
                    />
                  </Stack.Layer>
                  <Stack.Layer style={[styles.stackLayer, { height: itemHeight }]}>
                    <Iffy is={props.darkenUnselected && !isSelected}>
                      <Image
                        source={{ uri: e.img64 }}
                        style={darkenedImageStyle}
                      />
                    </Iffy>
                  </Stack.Layer>
                </Stack>
              </DNABox>
            </TouchableOpacity>
          </ItemWrapper>
          <Text style={numberStyle}>{index + 1}</Text>
        </DNABox>
      )
    } else if (variant === 'customDeck') {
      return (
        <Pressable onPress={onSelected} key={e.pageId}>
          <DNABox style={[thumbnailWrapperStyle, { width:undefined }]}>
            <Stack>
              <Stack.Layer>
                <DNABox style={styles.outerThumbContainerStyle}>
                  <DNABox style={styles.innerThumbContainerStyle}>
                    <Image
                      source={{ uri: e.img64 }}
                      style={[styles.image, { height:itemHeight, width:itemWidth }]}
                    />
                  </DNABox>
                </DNABox>
              </Stack.Layer>
              <Stack.Layer anchor="topRight">
                <Iffy is={showRightMenu}>
                  <DNABox style={{ margin: 3 }}>
                    <Icon name="plus-box" style={{ width: 32, height: 32, opacity: 0.40 }} />
                  </DNABox>
                </Iffy>
              </Stack.Layer>
            </Stack>
            <DNABox spacing="between">
              <DNAText>{index + 1}</DNAText>
              {e.isRequired &&
                <DNAChip
                  status="danger"
                  appearance="tag"
                >Required</DNAChip>
              }
            </DNABox>
          </DNABox>
        </Pressable>
      )
    }
  });

  // Spinner Thumbnails
  const spinners = visibleDocumentPages.map((_e, index) => (
    <View
      key={`thumbnails_spin_${index}`}
      style={[
        horizontal ? { paddingHorizontal: 20, width: itemWidth, paddingVertical: 20 } : { paddingVertical: 20 },
        contentUnavailable && { backgroundColor: luxColors.basicBlack.primary, height: itemHeight },
      ]}
    >
      { !contentUnavailable && <Spinner /> }
    </View>
  ));

  if (isGridView) {
    return (
      <ScrollView>
        <DNABox
          wrap="start"
          spacing="medium"
          keepSpacingLastItem={keepSpacingLastItem}
          style={[{ marginRight: 4 }, (!thumbnails && isTablet) && { paddingBottom: 30 }]}
        >
          {thumbnails || spinners}
        </DNABox>
      </ScrollView>
    );
  }

  return (
    <DNABox
      fill={isTablet}
      style={isFullWindow
        ? styles.slideRollFS
        : [
          styles.slideRollModal,
          isTablet && styles.slideRollModalTablet,
        ]
      }
    >
      <ScrollView
        ref={scrollViewRef}
        horizontal={horizontal}
        scrollEventThrottle={200}
        decelerationRate="fast"
        testID="preview-modal-slide-roll"
      >
        <DNABox
          appearance={horizontal ? 'row' : 'col'}
          spacing="small"
          alignX={horizontal ? 'end' : 'center'}
          style={[
            !horizontal ? { marginRight: 8 } : { },
            (!thumbnails && isTablet) && { paddingBottom: 30 },
          ]}
        >
          {thumbnails || spinners}
        </DNABox>
      </ScrollView>
    </DNABox>
  )
}

export default SlideRoll;
