import useThumbnailSize from 'src/hooks/useThumbnailSize/useThumbnailSize'

const useSelectorState = () => {
  const { thumbnailMode, setThumbnailMode, toggleThumbnailMode, thumbnailSize } = useThumbnailSize()

  return {
    selectorThumbnailMode : thumbnailMode,
    setSelectorThumbnailMode : setThumbnailMode,
    toggleSelectorThumbnailMode : toggleThumbnailMode,
    selectorThumbnailSize: thumbnailSize,
  }
}

export default useSelectorState
