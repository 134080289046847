import React, { useMemo, useState, createContext, useContext, useEffect } from 'react'
import im, { Draft } from 'immer'
import {
  DNAText,
  DNABox,
  DNAButton,
  DNACheckbox,
  DNAChip,
  DNADivider,
  Iffy,
  luxColors,
  LuxStatus,
} from '@alucio/lux-ui'

import { DocumentORM, FeatureFlags } from 'src/types/types'
import { filters, merge, FilterEntry, modalFilterSystemFieldsQueries } from 'src/state/redux/document/query'

import {
  useCurrentUser,
  useUserTenant,
} from 'src/state/redux/selector/user'
import {
  DocumentStatus,
  FileType,
  LabelValues,
  PurposeType,
  Tenant,
} from '@alucio/aws-beacon-amplify/src/models'
import { FilterAndSortOptions } from 'src/state/redux/selector/document'
import { useTenantFields } from 'src/state/redux/selector/tenant';
import DNADocumentFilterModal from './DNADocumentFilterModal'
import { useLocation } from 'src/router'
import ROUTES from 'src/router/routeDef'
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag'

import capitalize from 'lodash/capitalize'

export type SetFilterStateFn = React.Dispatch<React.SetStateAction<FilterEntry[]>>

export interface DNADocumentFilterContextValue {
  activeFilters: FilterEntry[],
  setActiveFilters: SetFilterStateFn,
  filterEntries: FilterEntry[],
  filterSelectorOpts: FilterAndSortOptions<DocumentORM>,
  toggleFilter: (filterName: string, filterValue: string, c?: SetFilterStateFn) => void,
  resetToDefaultFilters: (c?: SetFilterStateFn) => void,
  clearFilters: (c?: SetFilterStateFn) => void,
  isModalVisible: boolean,
  toggleModal: () => void,
  unpublishedToggle: boolean,
  toggleUnpublished: () => void,
}

interface DocumentFilterChipsProps {
  chipsStatus?: LuxStatus,
  documents: DocumentORM[];
}

export const DNADocumentFilterContext = createContext<DNADocumentFilterContextValue>({
  activeFilters: [],
  filterEntries: [],
  filterSelectorOpts: {},
  setActiveFilters: (p) => p,
  toggleFilter: () => { },
  resetToDefaultFilters: () => { },
  clearFilters: () => { },
  isModalVisible: false,
  toggleModal: () => { },
  unpublishedToggle: false,
  toggleUnpublished: () => { },
})
DNADocumentFilterContext.displayName = 'DNADocumentFilterContext'

const NON_TENANT_SPECIFIC_FILTER_ENTRIES: FilterEntry[] = [
  {
    fieldName: 'Associated Files',
    fieldValue: 'Has associated files',
    default: false,
    active: false,
  },
  {
    fieldName: 'Modifiable',
    fieldValue: 'Non-modifiable',
    default: false,
    active: false,
  },
  {
    fieldName: 'Modifiable',
    fieldValue: 'Modifiable',
    default: false,
    active: false,
  },
  {
    fieldName: 'Downloadable',
    fieldValue: 'Non-downloadable',
    default: false,
    active: false,
  },
  {
    fieldName: 'Downloadable',
    fieldValue: 'Downloadable',
    default: false,
    active: false,
  },
  {
    fieldName: 'Distributable',
    fieldValue: 'Non-distributable',
    default: false,
    active: false,
  },
  {
    fieldName: 'Distributable',
    fieldValue: 'Distributable',
    default: false,
    active: false,
  },
];
const STATUS_FILTER_ENTRIES: FilterEntry[] = [
  {
    fieldName: 'Status',
    fieldValue: 'Revoked',
    default: true,
    active: true,
  },
  {
    fieldName: 'Status',
    fieldValue: 'Published',
    default: true,
    active: true,
  },
  {
    fieldName: 'Status',
    fieldValue: 'Not Published',
    default: false,
    active: false,
  },
  {
    fieldName: 'Status',
    fieldValue: 'Archived',
    default: true,
    active: true,
  },
];
const FORMAT_TYPE_FILTER_ENTRIES: FilterEntry[] = [
  {
    fieldName: 'Type',
    fieldValue: FileType.PPTX,
    default: false,
    active: false,
  },
  {
    fieldName: 'Type',
    fieldValue: FileType.PDF,
    default: false,
    active: false,
  },
];
const PURPOSE_TYPE_FILTER_ENTRIES: FilterEntry[] = [
  {
    fieldName: 'Purpose',
    fieldValue: 'Internal',
    default: false,
    active: false,
  },
  {
    fieldName: 'Purpose',
    fieldValue: 'External - Reactive',
    default: false,
    active: false,
  },
  {
    fieldName: 'Purpose',
    fieldValue: 'External - Proactive',
    default: false,
    active: false,
  },
];

export const useDNADocumentFilters = () => useContext(DNADocumentFilterContext)

// [TODO]: Could be a selector (or meta property)
// But still deciding if needs to be used in more than one place
// This is used to easily get info for the Filter Modal
export function mapFieldFilterEntries(
  defaultFilters: LabelValues[] = [],
  userTenant: Tenant | undefined,
  isPublisher: boolean = false,
) {
  // Map of the default filters
  const defaultFieldsFilter: { [key: string]: { [key: string]: boolean } } = {}
  for (const field of defaultFilters) {
    defaultFieldsFilter[field.key] = {}
    for (const fieldValue of field?.values ?? []) {
      defaultFieldsFilter[field.key][fieldValue] = true
    }
  }

  const filterEntries: FilterEntry[] = []
  // Include non-tenant specifc fields
  Object.values(FORMAT_TYPE_FILTER_ENTRIES).forEach((filterEntry: FilterEntry) => {
    filterEntries.unshift(filterEntry);
  });

  userTenant?.integrations?.forEach((integration: string | null) => {
    filterEntries.unshift({
      fieldName: 'Content Source',
      active: false,
      fieldValue: capitalize(integration!),
      default: false,
    });
  });

  Object.values(PURPOSE_TYPE_FILTER_ENTRIES).forEach((filterEntry: FilterEntry) => {
    filterEntries.unshift(filterEntry);
  });
  if (isPublisher) {
    Object.values(STATUS_FILTER_ENTRIES).forEach((filterEntry: FilterEntry) => {
      filterEntries.unshift(filterEntry);
    });
  }

  NON_TENANT_SPECIFIC_FILTER_ENTRIES.forEach((customFilterEntry: FilterEntry) => {
    filterEntries.unshift(customFilterEntry);
  });

  // Flatten the tenant fields + add additional default/active props
  for (const field of userTenant?.fields ?? []) {
    for (const fieldValue of field?.values ?? []) {
      const { fieldName } = field
      filterEntries.push({
        fieldName,
        fieldValue,
        default: defaultFieldsFilter[fieldName]?.[fieldValue],
        active: defaultFieldsFilter[fieldName]?.[fieldValue],
      })
    }
  }

  return filterEntries
}

// For every tenant field that has an active filter value
//  The document must match at least 1 value for the identified tenant field
export function defaultTenantFieldDocumentFilter(requiredFilterFieldNames, activeFilters) {
  return (tenantFields: DocumentORM['meta']['tenantFields']) => {
    if (!activeFilters.length) return true;
    const satisfyRequiredFieldNames = {}

    for (const activeFilter of activeFilters) {
      const documentFieldValue = tenantFields.valuesMap[activeFilter.fieldName]

      if (documentFieldValue == null) {
        satisfyRequiredFieldNames[activeFilter.fieldName] = false;
      } else if (documentFieldValue.includes(activeFilter.fieldValue)) {
        satisfyRequiredFieldNames[activeFilter.fieldName] = true
      }

      if (Object.keys(satisfyRequiredFieldNames).length === requiredFilterFieldNames.length) { return true }
    }

    return false
  }
}

export const DNADocumentFiltersProvider: React.FC<{ initUserDefaultFilters?: boolean}> = (props) => {
  const {
    children,
    initUserDefaultFilters = true,
  } = props
  const currentUser = useCurrentUser()
  const userTenant = useUserTenant()

  // TODO: we need to figure out a better way to validate if the current view is the publisher view
  const { pathname } = useLocation();
  const lowerCasedPathName = pathname.toLowerCase();
  const isPublisherSearch = lowerCasedPathName === ROUTES.SEARCH_RESULTS_PUBLISHER.PATH
  const isPublisher = [ROUTES.PUBLISHER_DASHBOARD.PATH].includes(lowerCasedPathName) || isPublisherSearch

  const filterEntries = useMemo(() => mapFieldFilterEntries(
    currentUser?.userProfile?.defaultFilterValues,
    userTenant,
    isPublisher,
  ), [currentUser, userTenant])

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [activeFilters, setActiveFilters] = useState<FilterEntry[]>(
    initUserDefaultFilters
      ? filterEntries.filter(entry => entry.default)
      : [],
  )
  const [unpublishedToggle, setUnpublishedToggle] = useState<boolean>(false)

  // Always toggle unpublished off if any active filters are changed
  useEffect(() => { setUnpublishedToggle(false) }, [activeFilters])

  // Curried functions allow us to pass in any setState fn that has a FilterEntry[] type
  //  This allows us to be able to reuse these functions in the filter modal
  //  where actual filters changes are not committed until the Apply button is pressed
  function toggleFilter(
    filterName: string,
    filterValue: string,
    curriedFn: SetFilterStateFn = setActiveFilters,
  ) {
    const curried = im((draft: Draft<FilterEntry[]>) => {
      const activeFilterIdx =
        draft.findIndex(entry => entry.fieldValue === filterValue && entry.fieldName === filterName)
      if (activeFilterIdx < 0) {
        const targetFilter =
          filterEntries.find(entry => entry.fieldValue === filterValue && entry.fieldName === filterName)
        if (targetFilter) { draft.push({ ...targetFilter, active: true }) }
      } else {
        draft.splice(activeFilterIdx, 1)
      }
    })

    curriedFn(curried)
  }

  function resetToDefaultFilters(curriedFn: SetFilterStateFn = setActiveFilters) {
    const curried = (p: FilterEntry[]): typeof p => {
      return filterEntries.filter(entry => entry.default)
    }
    curriedFn(curried)
  }

  function clearFilters(curriedFn: SetFilterStateFn = setActiveFilters) {
    curriedFn(() => [])
  }

  // [TODO-2126] - I believe filters are still calculating at the document level, not the version level
  //             - Also, how do we want to handle counting in offline?
  const docTenantFieldFilter = useMemo(() => {
    const requiredFilterFieldNames = Object.keys(
      activeFilters.reduce(
        (acc, activeFilter) => ({
          ...acc,
          [activeFilter.fieldName]: true,
        }),
        {},
      ))

    return {
      filter: {
        meta: {
          tenantFields: defaultTenantFieldDocumentFilter(requiredFilterFieldNames, activeFilters),
        },
      },
    }
  }, [activeFilters]);

  const docSystemFieldFilter = useMemo(() => {
    const activeFiltersMap = activeFilters.reduce<Record<string, string[]>>(
      (acc, activeFilter) => {
        if (!acc[activeFilter.fieldName])
        { acc[activeFilter.fieldName] = [activeFilter.fieldValue] }
        else
        { acc[activeFilter.fieldName].push(activeFilter.fieldValue) }

        return acc
      },
      { },
    )

    const activeSystemFieldFilters = Object
      .entries(modalFilterSystemFieldsQueries)
      .reduce<FilterAndSortOptions<DocumentORM>[]>(
        (acc, [systemFieldName, systemFieldQuery]) => {
          if (activeFiltersMap[systemFieldName])
          { acc.push(systemFieldQuery(activeFiltersMap[systemFieldName])) }
          return acc
        }
        , [],
      )

    const wrapperFnArr: FilterAndSortOptions<DocumentORM>[] = []
    activeSystemFieldFilters.forEach(filter => {
      if (filter.filter?.relations?.version) {
        wrapperFnArr.push(filter)
      }
    })

    const result = merge(...activeSystemFieldFilters)

    // We consolidate all filters that use version relations.version prop
    // Otherwise we will end up with only one filter for version prop after previous merge of activeSystemFieldFilters
    if (wrapperFnArr.length && result.filter?.relations) {
      result.filter.relations.version = (v) => {
        return wrapperFnArr.every((filter) => {
          if (typeof filter.filter?.relations?.version === 'function') {
            return filter.filter?.relations?.version(v)
          }

          throw Error('Expecting filter to be a function')
        })
      }
    }

    return result
  }, [activeFilters])

  const filterSelectorOpts: FilterAndSortOptions<DocumentORM> = useMemo(() => {
    const activeFilters = merge(
      docTenantFieldFilter,
      docSystemFieldFilter,
    )

    if (isPublisherSearch) {
      return activeFilters
    }

    return !unpublishedToggle
      // merge is also exported from the same query file as filters
      // we want to show active filters, the notPublished and has unpublished versions
      // the publisher is an special case because his 'published version includes the revoke, archive, and delete,
      ? isPublisher
        ? activeFilters
        // [NOTE] - Non publisher modes will ignore any active status filters (i.e. default active ones)
        // since merging will override any previous key/value
        : merge(activeFilters, filters.published)
      : filters.hasUnpublishedVersion
  }, [
    docTenantFieldFilter,
    docSystemFieldFilter,
    activeFilters,
    unpublishedToggle,
  ],
  );

  // [TODO]: Consider memoizing the functions?
  const value = {
    activeFilters,
    setActiveFilters,
    filterEntries,
    filterSelectorOpts,
    isModalVisible,
    toggleFilter,
    resetToDefaultFilters,
    clearFilters,
    toggleModal: () => setIsModalVisible(p => !p),
    unpublishedToggle,
    toggleUnpublished: () => setUnpublishedToggle(p => !p),
  }

  return (
    <DNADocumentFilterContext.Provider value={value}>
      { children}
    </DNADocumentFilterContext.Provider>
  )
}

export const DNADocumentFiltersChips = (props: DocumentFilterChipsProps) => {
  const { documents, chipsStatus = 'brand' } = props
  const {
    activeFilters,
    toggleFilter,
    resetToDefaultFilters,
    clearFilters,
    unpublishedToggle,
  } = useDNADocumentFilters()

  return (
    <DNABox
      alignY="start"
      style={{ minHeight: 36 }}
    >
      <DNABox fill childFill={1} alignY="start" alignX="start">
        <DNABox
          alignY="center"
          spacing="small"
          // Vertical Divider doesn't work super well with Box's wrapping system
          childStyle={{ height: 24, justifyContent: 'center' }}
        >
          <DNAText testID="filter-count-label">
            {documents.length} item(s)
          </DNAText>

          {/* Disable filter count if "In Progress" mode */}
          <Iffy is={!unpublishedToggle}>
            <DNADivider vertical style={{ marginHorizontal: 4 }} />
            <DNAText>Filters:</DNAText>
          </Iffy>
        </DNABox>

        {/* Disable filter chips if "In Progress" mode */}
        <Iffy is={!unpublishedToggle}>
          <DNABox
            shrink
            wrap="start"
            alignY="start"
            spacing="tiny"
            style={{ marginLeft: 8 }}
            childStyle={{ paddingVertical: 2 }}
          >
            {
              activeFilters.map(entry => (
                <DNAChip
                  testID="filter-chip"
                  key={entry.fieldValue}
                  status={chipsStatus}
                  onClose={() => { toggleFilter(entry.fieldName, entry.fieldValue) }}
                >
                  { entry.fieldValue}
                </DNAChip>
              ))
            }
          </DNABox>
          <DNABox
            alignY="center"
            childStyle={{ height: 24, justifyContent: 'center' }}
          >
            <DNABox
              style={{ paddingHorizontal: 16 }}
              spacing="small"
            >
              <DNAButton
                testID="filter-chips-reset-button"
                status="primary"
                appearance="ghost"
                context="minimum"
                onPress={() => resetToDefaultFilters()}
              >
                Reset
              </DNAButton>
              <DNADivider
                vertical
                style={{
                  marginVertical: 4,
                  backgroundColor: luxColors.primary.primary,
                }}
              />
              <DNAButton
                testID="filter-chips-clear-button"
                status="primary"
                appearance="ghost"
                context="minimum"
                onPress={() => clearFilters()}
              >
                Clear
              </DNAButton>
            </DNABox>
          </DNABox>
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

export const DNAUnpublishedCheckbox = () => {
  const { unpublishedToggle, toggleUnpublished } = useDNADocumentFilters()

  return (
    <DNABox spacing="small">
      <DNACheckbox
        checked={unpublishedToggle}
        onChange={toggleUnpublished}
        testID= {`in-progress-checkbox-${unpublishedToggle ? 'checked' : 'unchecked'}`}

      />
      <DNAText>In Progress</DNAText>
    </DNABox>
  )
}

const DNADocumentFiltersModal: React.FC<{ documents: DocumentORM[] }> = (props) => {
  const { documents } = props
  const {
    toggleModal,
    toggleFilter,
    activeFilters,
    setActiveFilters,
    filterEntries,
    resetToDefaultFilters,
    clearFilters,
  } = useDNADocumentFilters()

  const [localActiveFilters, setLocalActiveFilters] = useState<FilterEntry[]>(activeFilters)
  const currentUser = useCurrentUser()
  const userTenant = useUserTenant()
  const tenantFields = useTenantFields({ defaultSearchFilter: false });
  const fieldLabels = tenantFields.map(({ fieldName }) => fieldName);
  const { pathname } = useLocation();
  const lowerCasedPathName = pathname.toLowerCase();
  const isPublisherSearch = lowerCasedPathName === ROUTES.SEARCH_RESULTS_PUBLISHER.PATH
  const isPublisher = [ROUTES.PUBLISHER_DASHBOARD.PATH].includes(lowerCasedPathName) || isPublisherSearch
  const enableEmailTemplates = useFeatureFlag(FeatureFlags.BEAC_2516_Enable_Document_Sharing_Templates)

  useEffect(() => { setLocalActiveFilters(activeFilters) }, [activeFilters])

  const lockedFilterFieldNames = currentUser.meta.formattedLockedFilters
    ? Object.keys(currentUser.meta.formattedLockedFilters!)
    : []

  function closeCancel(): void {
    setLocalActiveFilters(activeFilters)
    toggleModal()
  }

  function closeApply(addDelay?: boolean): void {
    setActiveFilters(localActiveFilters)

    // IF WE'RE ON MOBILE. THE IDEA IS THAT, WHILE THE MODAL IS SLIDING DOWN, THE SEARCH IS BEING DONE.
    // (SO WE CLOSE IT AFTER IT SLID DOWN)
    setTimeout(() => toggleModal(), addDelay ? 700 : 0);

    analytics?.track('SEARCH_FILTER_MODAL', {
      action: 'FILTER_MODAL',
      category: 'SEARCH',
    });
  }

  // [TODO] - Bug, local active filters need to reflect useEffect from activeFilters
  const localActiveMap = localActiveFilters.reduce(
    (acc, activeEntry) => {
      const { fieldName, fieldValue, active } = activeEntry
      if (!acc[fieldName]) { acc[fieldName] = {} }

      acc[fieldName][fieldValue] = { active }

      return acc
    },
    {},
  )

  // [NOTE] - We may want to consider memoizing this
  const filterMap = filterEntries.reduce<
    Record<
      string,
      Record<string, { fieldName: string, fieldValue: string, active: boolean, count: number }>
    >
  >(
    (acc, entry) => {
      const { fieldName, fieldValue } = entry

      if (!acc[fieldName]) { acc[fieldName] = {} }

      acc[fieldName][fieldValue] = {
        fieldName,
        fieldValue,
        active: localActiveMap?.[fieldName]?.[fieldValue]?.active ?? false,
        count: 0,
      }

      return acc
    },
    {},
  )

  // removing the locked fitlers ( before doing the count operation below )
  for (const lockedFilterFieldName of lockedFilterFieldNames) {
    if (filterMap[lockedFilterFieldName]) {
      for (const filterValue of Object.keys(filterMap[lockedFilterFieldName])) {
        if (!currentUser.meta.formattedLockedFilters![lockedFilterFieldName].includes(filterValue)) {
          delete filterMap[lockedFilterFieldName][filterValue]
        }
      }
    }
  }

  // Distributable is non tenant specific for all our tentants except AZ, using the feature flag to remove this field
  if (!enableEmailTemplates) {
    delete filterMap.Distributable
  }

  // [TODO-1793] - Clarify whether or not we should count unpublished docs towards the filter label count
  for (const documentORM of documents) {
    const docVersionORM = documentORM.relations.version.latestUsableDocumentVersion

    // DISTRIBUTABLE
    if (enableEmailTemplates) {
      if (docVersionORM?.model.distributable) {
        filterMap.Distributable.Distributable.count++;
      } else {
        filterMap.Distributable['Non-distributable'].count++;
      }
    }

    // DOWNLOADABLE
    if (docVersionORM?.model.downloadable || (docVersionORM?.model.downloadable === null)) {
      filterMap.Downloadable.Downloadable.count++;
    } else {
      filterMap.Downloadable['Non-downloadable'].count++;
    }

    // MODIFIABLE
    if (docVersionORM?.model.canHideSlides) {
      filterMap.Modifiable.Modifiable.count++;
    } else {
      filterMap.Modifiable['Non-modifiable'].count++;
    }
    // HAS ASSOCIATED FILES
    if (docVersionORM?.relations.associatedFiles.length) {
      filterMap['Associated Files']['Has associated files'].count++;
    }

    // PURPOSE
    switch (docVersionORM?.model.purpose) {
      case PurposeType.EXTERNAL_PROACTIVE:
        filterMap.Purpose['External - Proactive'].count++;
        break;
      case PurposeType.EXTERNAL_REACTIVE:
        filterMap.Purpose['External - Reactive'].count++;
        break;
      case PurposeType.INTERNAL_USE_ONLY:
        filterMap.Purpose.Internal.count++;
        break;
    }

    // STATUS
    if (isPublisher) {
      switch (documentORM.model.status) {
        case DocumentStatus.ARCHIVED:
          filterMap.Status.Archived.count++;
          break;
        case DocumentStatus.NOT_PUBLISHED:
          filterMap.Status['Not Published'].count++;
          break;
        case DocumentStatus.PUBLISHED:
          filterMap.Status.Published.count++;
          break;
        case DocumentStatus.REVOKED:
          filterMap.Status.Revoked.count++;
          break;
      }
    }

    // FILE TYPE
    switch (documentORM.model.type) {
      case FileType.PDF:
        filterMap.Type[FileType.PDF].count++;
        break;
      case FileType.PPTX:
        filterMap.Type[FileType.PPTX].count++;
        break;
    }

    // CUSTOM TENANT FIELDS
    fieldLabels.forEach((label) => {
      const fieldValues = documentORM.meta.tenantFields.valuesMap[label] ?? [];
      fieldValues.forEach((value) => {
        // A document can potentially be assigned a label/value that is no longer in tenant config
        if (filterMap[label] && filterMap[label][value]) {
          filterMap[label][value].count++;
        }
      });
    });

    // CONTENT SOURCE
    if (
      userTenant &&
      userTenant.integrations &&
      userTenant.integrations.length > 0 &&
      documentORM.relations.version.latestUsableDocumentVersion
    ) {
      const docVer = documentORM.relations.version.latestUsableDocumentVersion.model
      docVer.integrationType && filterMap['Content Source'][capitalize(docVer.integrationType)].count++;
    }
  }

  const categories = Object.entries(filterMap);

  const modalProps = {
    categories,
    clearFilters,
    closeApply,
    closeCancel,
    resetToDefaultFilters,
    setLocalActiveFilters,
    toggleFilter,
    activeFilters: localActiveFilters.length,
  };

  // Note: The default export for this will choose between desktop and tablet
  return <DNADocumentFilterModal {...modalProps} />
}

// [TODO] - Correctly extract props from `Component` generic T for use in composition
export const withDNADocumentFilters = <T extends { initUserDefaultFilters?: boolean }>
  (Component) => (props: T) => {
    const { initUserDefaultFilters, ...restProps } = props

    return (
      <DNADocumentFiltersProvider
        initUserDefaultFilters={initUserDefaultFilters}
      >
        <Component {...restProps as T} />
      </DNADocumentFiltersProvider>
    )
  }

export default {
  withDNADocumentFilters,
  Provider: DNADocumentFiltersProvider,
  Chips: DNADocumentFiltersChips,
  Modal: DNADocumentFiltersModal,
  UnpublishToggle: DNAUnpublishedCheckbox,
}
