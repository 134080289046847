/**
 * These type guards are useful for identifying the type of data we are working with
 * which, in turn, is used to help enforce type safety.
 *
 * When structuring a typeguard, the item passed in can be of unknown type and the return
 * value is a boolean representing the EVALUATION of the data as a particular type.
 *
 * Note the use of 'as' to assert the type of the unknown item being passed in ONLY for the evaluation
 * here so that the expected properties can be accessed without the compiler complaining.
 * */

import { SignInFailureResponse } from 'src/screens/Authentication/SignIn/SignIn';
import { CustomDeckORM, FolderItemORM, FolderORM, ORMTypes, DocumentORM, DocumentVersionORM } from './types';

export const isCustomDeckORM = (item: unknown) : item is CustomDeckORM => item
  ? (item as CustomDeckORM).type === ORMTypes.CUSTOM_DECK
  : false

export const isDocumentORM = (item: unknown) : item is DocumentORM => item

  ? (item as DocumentORM).type === ORMTypes.DOCUMENT
  : false

export const isDocumentVersionORM = (item: unknown) : item is DocumentVersionORM => item
  ? (item as DocumentVersionORM).type === ORMTypes.DOCUMENT_VERSION
  : false

export const isFolderORM = (item: unknown): item is FolderORM => item
  ? (item as FolderORM).type === ORMTypes.FOLDER
  : false

export const isFolderItemORM = (item: unknown): item is FolderItemORM => item
  ? (item as FolderItemORM).type === ORMTypes.FOLDER_ITEM
  : false

export const isSignInFailureResponse = (item: unknown): item is SignInFailureResponse => item
  ? (item as SignInFailureResponse).code !== undefined
  : false
