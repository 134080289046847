import React from 'react'
import { StyleSheet } from 'react-native';
import { DNABox, DNAChip, DNAText, Iffy } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { LoadedPresentation, useContent } from 'src/state/context/ContentProvider/ContentProvider';
import PurposeBadge from 'src/components/PurposeBadge/PurposeBadge';
import { isDocumentVersionORM, isFolderItemORM } from 'src/types/typeguards';
import { PurposeType } from '@alucio/aws-beacon-amplify/src/models';

const styles = StyleSheet.create({
  contentDetailWrapper: {
    paddingHorizontal:20,
    paddingVertical:12,
    height: 68,
    backgroundColor: colors['color-flat-1200'],
  },
  footerWrapper: {
    borderTopWidth: 1,
    borderTopColor: colors['color-flat-900'],
  },
});

const NotPresentingContent: React.FC = React.memo(() => {
  return (
    <DNABox
      alignX="center"
      alignY="center"
      appearance="col"
      fill
      style={{ backgroundColor:colors['color-success-500'], height: 108 }}
    >
      <DNAText h2 bold status="basic">Let's start your presentation!</DNAText>
      <DNAText h5 status="basic">Open a presentation and select a slide.</DNAText>
    </DNABox>
  )
});

const PresentingContent:React.FC<{activePresentation: LoadedPresentation}> = (props) => {
  const { activePresentation } = props;
  const isDocumentVersion = isDocumentVersionORM(activePresentation.presentable.orm) ||
    isDocumentVersionORM(activePresentation.presentable.orm.relations.item);
  const isModifiedDocumentVersion = isFolderItemORM(activePresentation.presentable.orm) && isDocumentVersion &&
    activePresentation.presentable.orm.model.visiblePages?.length;
  const showModifiedBadge = isModifiedDocumentVersion || !isDocumentVersion;

  return (
    <DNABox fill appearance="col" spacing="small" alignY="center" style={styles.contentDetailWrapper}>
      <DNAText bold status="success">CURRENTLY PRESENTING</DNAText>
      <DNABox alignY="center" spacing="small" childFill={2}>
        { /* DOCUMENT VERSION BADGE */ }
        <Iffy is={isDocumentVersion}>
          <PurposeBadge
            purpose={activePresentation.currentPresentablePage.documentVersion.model.purpose as PurposeType}
          />
        </Iffy>
        { /* MODIFIED BADGE */ }
        <Iffy is={showModifiedBadge}>
          <DNAChip appearance="tag" status="subtle">
            MODIFIED
          </DNAChip>
        </Iffy>
        <DNAText numberOfLines={1} status="basic">
          {activePresentation.presentable.title}
        </DNAText>
      </DNABox>
    </DNABox>
  )
}

const Footer: React.FC = () => {
  const { activePresentation } = useContent();

  const currentFooter = activePresentation
    ? <PresentingContent activePresentation={activePresentation} />
    : <NotPresentingContent />

  return (
    <DNABox style={styles.footerWrapper}>
      {currentFooter}
    </DNABox>
  );
}

export default Footer
