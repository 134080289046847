import React from 'react'

import { DocumentORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions'
import { DNABox, DNACard, DNAText, DNAButton, useTheme, Iffy, DNAChip, luxColors } from '@alucio/lux-ui'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import DNADocumentContextMenu from 'src/components/DNA/Document/DNADocumentContextMenu/DNADocumentContextMenu'
import { CustomLabelDisplay } from '../default/CustomLabelDisplay'
import { useAppSettings } from 'src/state/context/AppSettings';

export type DNADocumentDefaultRowProps = {
  onPin?: () => void,
  isNetworkConnected?: boolean,
}

const DNADocumentSearchRow: DNARowsComponent<
  DocumentORM,
  DNADocumentDefaultRowProps
> = (props) => {
  const {
    ORM: documentORM,
    isNetworkConnected,
    onPin,
    onPress,
    style,
  } = props

  const theme = useTheme()

  const isBookmarked = documentORM.meta.bookmark.isBookmarked
  const documentActions = useDNADocumentActions();
  const { deviceMode } = useAppSettings();
  const unavailableContent =
    !isNetworkConnected &&
    !documentORM.relations.version.cachedDocumentVersion?.meta.assets.isContentCached;

  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={() => { onPress?.(documentORM) }}
      style={[
        {
          paddingHorizontal: deviceMode === 'desktop' ? theme['row-padding-horizontal'] : 29,
          paddingVertical: theme['row-padding-vertical'],
        },
        style,
      ]}
    >
      <DNABox alignY="center" spacing="medium" childFill={1}>
        {/* Actions */}
        <DNABox spacing={deviceMode === 'desktop' ? 'medium' : 'large'}>
          <DNABox style={{ width: 22 }}>
            <Iffy is={!unavailableContent}>
              <DNAButton
                status="subtle"
                appearance="ghostAlt"
                context={deviceMode === 'desktop' ? 'minimum' : 'roundTablet'}
                onPress={() => {
                  documentActions.bookmark(documentORM)()
                onPin?.()
                }}
                iconLeft={isBookmarked ? 'bookmark' : 'bookmark-outline'}
                size={deviceMode === 'desktop' ? undefined : 'xlarge'}
              />
            </Iffy>
          </DNABox>
          <DNADocumentContextMenu documentORM={documentORM}>
            <DNAButton
              status="subtle"
              appearance="ghostAlt"
              context={deviceMode === 'desktop' ? 'minimum' : 'roundTablet'}
              iconLeft="dots-vertical"
              size={deviceMode === 'desktop' ? undefined : 'xlarge'}
            />
          </DNADocumentContextMenu>
        </DNABox>
        <DNABox alignY="center" spacing="large" childFill={1} style={{ opacity: unavailableContent ? 0.4 : 1 }}>
          <DNADocumentThumbnail
            documentVersionORM={
              (!isNetworkConnected && documentORM.relations.version.cachedDocumentVersion) ||
              documentORM.relations.version.latestDocumentVersionPublished ||
              documentORM.relations.version.latestDocumentVersion
            }
            width={76}
            height={44}
            unavailableContent={unavailableContent}
            // Assessing implementation in #BEAC-2155
            // onPress={() => { console.log('pressed search') }}
          />
          {/* Title */}
          <DNABox
            spacing="small"
            appearance="col"
            childFill
            style={{ paddingRight: 16 }}
          >
            <DNAText b1 numberOfLines={1}>{
              documentORM.relations.version.latestDocumentVersionPublished?.model.title}
            </DNAText>
            <DNABox alignY="center" spacing="small" childFill={unavailableContent ? 2 : 1}>
              <DNADocumentChip
                item={documentORM}
                variant="purpose"
              />
              <Iffy is={unavailableContent}>
                <DNAChip appearance="tag" style={{ backgroundColor: luxColors.basicBlack.primary }}>
                  CONTENT NOT AVAILABLE
                </DNAChip>
              </Iffy>
              <CustomLabelDisplay documentORM={documentORM} />
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default DNADocumentSearchRow
