import { openDB, IDBPObjectStore, IDBPTransaction } from 'idb';

// I get inspiration from here: https://github.com/xg-wang/idb-queue
// But this code was changed to use idb instead of indexDb

type Store = IDBPObjectStore<unknown, [string], string, 'readonly' | 'readwrite' | 'versionchange'>

export function createStore(
  dbName: string,
  storeName: string,
  key = 'key',
  version?: number,
): WithStore {
  return async (txMode, callback) => {
    const db = await openDB(dbName, version, {
      upgrade(db) {
        db.createObjectStore(storeName, { keyPath: key });
      },
    });
    const store = db.transaction(storeName, txMode).objectStore(storeName);
    return callback(store)
  }
}

export type WithStore = <T>(
      txMode: IDBTransactionMode,
      callback: (store: Store) => T | PromiseLike<T>,
    ) => Promise<T>;

let defaultStoreFunc: WithStore | undefined;
function defaultStore() {
  if (!defaultStoreFunc) {
    defaultStoreFunc = createStore('idb-queue', 'default');
  }
  return defaultStoreFunc;
}

export interface RetentionConfig {
      maxNumber: number;
      batchEvictionNumber: number;
    }
function defaultRetention(): RetentionConfig {
  return {
    maxNumber: 1000,
    batchEvictionNumber: 300,
  };
}

async function _batchEvictFromStoreTx(
  store: Store,
  retentionConfig = defaultRetention(),
): Promise<IDBDatabase> {
  let total = 0;
  let lowestKey: number | null = null;

  const result = await store.openKeyCursor()

  const cursor = result;
  if (cursor && total++ < retentionConfig.batchEvictionNumber) {
    lowestKey = cursor.key as number;
    cursor.continue();
  } else if (lowestKey != null) {
        store.delete!(IDBKeyRange.upperBound(lowestKey));
  }

  return promisify(store.transaction);
}

export function batchEvict(
  retentionConfig = defaultRetention(),
  withStore = defaultStore(),
) {
  return withStore('readwrite', (store) =>
    _batchEvictFromStoreTx(store, retentionConfig),
  );
}

let isClearing = false;

export function push<T>(
  value: T,
  retentionConfig = defaultRetention(),
  withStore = defaultStore(),
): Promise<IDBDatabase| undefined> {
  return withStore('readwrite', async (store) => {
    await store.put!(value);
    const count = await store.count();
    if (count <= retentionConfig.maxNumber) {
      return;
    }
    return _batchEvictFromStoreTx(store, retentionConfig);
  }).catch((reason) => {
    if (reason && reason.name === 'QuotaExceededError') {
      return batchEvict(retentionConfig, withStore);
    }
  });
}

export function pushIfNotClearing<T>(
  value: T,
  retentionConfig = defaultRetention(),
  withStore = defaultStore(),
): Promise<void | IDBDatabase | undefined> {
  return isClearing
    ? Promise.resolve()
    : push(value, retentionConfig, withStore);
}

export function clear(withStore = defaultStore()) {
  isClearing = true;
  return withStore('readwrite', (store) => {
      store.clear!();
      return promisify(store.transaction).finally(() => (isClearing = false));
  });
}

export function _shift<T>(
  count: number,
  withStore: WithStore,
  direction: IDBCursorDirection,
) {
  return withStore('readwrite', async (store) => {
    const _store = store as Store
    const shifted: Array<T> = [];

    const result = await _store.openCursor(null, direction)

    const cursor = result;
    if (cursor) {
      shifted.push(cursor.value);
      await cursor.delete!();
      if (count < 0 || shifted.length < count) {
        cursor.continue();
      }
    }

    return shifted;
  });
}

function _peek<T>(
  count: number,
  withStore: WithStore,
  direction: IDBCursorDirection,
) {
  return withStore('readonly', async (store) => {
    const _store = store as Store

    const peeked: Array<T> = [];
    const result = await _store.openCursor(null, direction);

    const cursor = result;
    if (cursor) {
      peeked.push(cursor.value);
      if (count < 0 || peeked.length < count) {
        cursor.continue();
      }
    }
    return peeked;
  });
}

export function promisify<T = undefined>(
  request:
    IDBTransaction |
    IDBPTransaction<unknown, [string], 'readonly' | 'readwrite' | 'versionchange'>,
): Promise<T| IDBDatabase> {
  return new Promise((resolve, reject) => {
    if (request instanceof IDBTransaction) {
      request.oncomplete = () => resolve(request.db);
      request.onabort = request.onerror = () => reject(request.error);
    }
  });
}

export function peek<T>(count = 1, withStore = defaultStore()) {
  return _peek<T>(count, withStore, 'next');
}
export function peekAll<T>(withStore = defaultStore()) {
  return _peek<T>(-1, withStore, 'next');
}

export function shift<T>(count = 1, withStore = defaultStore()) {
  return _shift<T>(count, withStore, 'next');
}

export function shiftAll<T>(withStore = defaultStore()) {
  return shift<T>(-1, withStore);
}

export function peekBack<T>(count = 1, withStore = defaultStore()) {
  return _peek<T>(count, withStore, 'prev');
}

export function pop<T>(count = 1, withStore = defaultStore()) {
  return _shift<T>(count, withStore, 'prev');
}
