import React, { ReactElement, useState, useMemo, useRef, useContext, createContext, useEffect } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Icon, luxColors, DNABox, DNAButton, DNASlider, useDisableSwipe } from '@alucio/lux-ui';
import { OverflowMenu, Input } from '@ui-kitten/components';
import InputComponent from '../../Publishers/InputComponent';
import { useHistory, useLocation } from 'react-router';
import { useCanPerformSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import { useAppSettings } from 'src/state/context/AppSettings';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useIsPublisherView from 'src/hooks/useIsPublisherView/useIsPublisherView';
import debounce from 'lodash/debounce';
import ROUTES from 'src/router/routeDef';
import qs from 'qs';
import DNALibrary from 'src/screens/MyLibrary/DNALibrary'
import SearchInputResultsDesktop from './SearchInputResultsDesktop';

export interface SearchInputProps {
  searchText: string,
}

export const SearchInputContext = createContext<SearchInputProps>({ searchText: '' })

const styles = StyleSheet.create({
  icon: {
    color: luxColors.disabled.primary,
    height: 25,
    width: 25,
  },
  menu: {
    maxHeight: 317,
  },
  searchBarStyle: {
    backgroundColor: luxColors.info.primary,
    borderColor: luxColors.disabled.secondary,
    borderRadius: 4,
    maxWidth: '100%',
    overflow: 'hidden',
  },
  searchBarWrapper: {
    marginLeft: 30,
    marginTop: 3,
    flex: 1,
  },
});

const ICONS = {
  CLOSE: 'close',
  MARGNIFY: 'magnify',
};

const useSearchInput = () => {
  const isPublisherView = useIsPublisherView();
  const { currentSearchString, setCurrentSearchString } = useSearchTextContextProvider();
  const { pathname, search } = useLocation();
  const qsParam = qs.parse(search, { ignoreQueryPrefix: true })
  const isPublisherSearchView = pathname.toLowerCase() === ROUTES.SEARCH_RESULTS_PUBLISHER.PATH
  const queryParamSearchString = typeof qsParam.searchText === 'string' ? qsParam.searchText : ''
  const [searchText, setSearchText] = useState<string>(
    isPublisherView
      ? queryParamSearchString
      : currentSearchString,
  )
  const canPerformSearch = useCanPerformSearch();
  const [displaySearchResults, setDisplaySearchResults] = useState<boolean>(false);
  const inputRef = useRef<Input>()
  const history = useHistory();

  useEffect(() => {
    setSearchText(currentSearchString)
  }, [currentSearchString])

  function onChangeText(text: string): void {
    const canSearch = canPerformSearch(text.trim());
    setSearchText(text);
    setCurrentSearchString(text);

    if (!isPublisherView) {
      if (!displaySearchResults && canSearch) {
        setDisplaySearchResults(true);
      } else if (displaySearchResults && !canSearch) {
        setDisplaySearchResults(false);
      }
    }
    else if (!isPublisherSearchView && canSearch) {
      history.push(`${ROUTES.SEARCH_RESULTS_PUBLISHER.PATH}?searchText=${text}`);
    }
  }

  function clearText(): void {
    onChangeText('');
    inputRef.current?.focus()
  }

  function toggleSearchResultComponent(open: boolean): void {
    setDisplaySearchResults(open);
  }

  function resetSearch(): void {
    setSearchText('')
    setDisplaySearchResults(false)
  }

  return {
    displaySearchResults,
    onChangeText,
    clearText,
    toggleSearchResultComponent,
    searchText,
    resetSearch,
    setDisplaySearchResults,
    canPerformSearch,
    inputRef,
    isPublisherView,
  }
}

const SearchInputDeskTop = () => {
  const history = useHistory();
  const {
    onChangeText,
    displaySearchResults,
    clearText,
    toggleSearchResultComponent,
    searchText,
    setDisplaySearchResults,
    canPerformSearch,
    inputRef,
    isPublisherView,
  } = useSearchInput();

  function moveToResultsPage(): void {
    if (canPerformSearch(searchText.trim()) && !isPublisherView) {
      setDisplaySearchResults(false);
      history.push(`/search/${searchText}`);

      analytics?.track('SEARCH_INPUT', {
        action: 'INPUT',
        category: 'SEARCH',
        searchText,
      });
    }
  }

  function renderIcon(icon: string): ReactElement {
    if (icon === ICONS.CLOSE && !searchText) {
      return <DNABox />;
    }

    return (
      <TouchableOpacity
        onPress={icon === ICONS.CLOSE ? clearText : moveToResultsPage}
        testID={ icon === ICONS.CLOSE ? 'header-search-input-clear' : 'header-search-input-results' }
      >
        <Icon style={styles.icon} name={icon} />
      </TouchableOpacity>
    );
  }

  function renderSearchInput(): ReactElement {
    return (
      <DNABox style={styles.searchBarWrapper}>
        <InputComponent
          ref={inputRef as any}
          testID="header-search-input"
          value={searchText}
          hideLabel={true}
          onChangeText={onChangeText}
          title="Search for documents"
          removeMarginPadding={true}
          inputStyle={styles.searchBarStyle}
          getLeftIconFunction={() => renderIcon(ICONS.MARGNIFY)}
          getRightIconFunction={() => renderIcon(ICONS.CLOSE)}
          onSubmitEditing={moveToResultsPage}
          autoCompleteType="off"
        />
      </DNABox>
    );
  }

  function onBackdropPressHandler() {
    toggleSearchResultComponent(false)
  }

  function onSelectItemHandler() {
    setDisplaySearchResults(false);
    clearText()
  }

  return (
    <OverflowMenu
      anchor={renderSearchInput}
      style={styles.menu}
      visible={displaySearchResults && !isPublisherView}
      placement="bottom start"
      fullWidth={true}
      onBackdropPress={onBackdropPressHandler}
    >
      <SearchInputResultsDesktop
        searchText={searchText}
        moveToResultsPage={moveToResultsPage}
        onSelectItem={onSelectItemHandler}
      />
    </OverflowMenu>
  );
};

const SearchInputTablet = () => {
  const [showSlider, setShowSlider] = useState(false);
  const { onChangeText, searchText, resetSearch, displaySearchResults } = useSearchInput();

  useDisableSwipe()

  const SearchInputContextValue: SearchInputProps = {
    searchText: searchText,
  };
  // Memoize to prevent re-render on exit animation
  // [TODO] Consider memo'ing
  // (pure functioning the List itself since it's performance heavy in multiple places)
  const SearchResults = useMemo(
    () => (
      <SearchInputContext.Provider value={SearchInputContextValue}>
        <DNALibrary
          initUserDefaultFilters={false}
          // @ts-expect-error - Due to composition, it does not successfuly extract props from every layer
          toggleSearchMessage={!displaySearchResults}
        />
      </SearchInputContext.Provider >
    ),
    [searchText, displaySearchResults],
  )

  return (
    <>
      <DNAButton
        context="minimum"
        appearance="ghost"
        status="flat"
        size="xlarge"
        iconLeft="magnify"
        onPress={() => setShowSlider(p => !p)}
      />

      <DNASlider
        visible={showSlider}
        setVisible={setShowSlider}
        onInvisible={resetSearch}
      >
        <DNABox style={{ backgroundColor: 'white' }} appearance="col" fill>
          {/* Search header */}
          <DNABox
            appearance="row"
            alignY="center"
            style={{ backgroundColor: colors['color-brand-800'], height: 72 }}
          >
            <DNABox fill style={{ height: 48 }}>
              <DNABox testID="search-header-bar" alignY="center" fill style={{ marginLeft: 16, marginTop: 4 }}>
                <InputComponent
                  hideLabel={true}
                  removeMarginPadding={true}
                  title="Search all files"
                  onChangeText={debounce(onChangeText, 500)}
                  titleColor={luxColors.contentText.tertiary}
                  inputStyle={{ borderRadius: 8 }}
                  size="medium"
                />
              </DNABox>
              <DNABox style={{ marginHorizontal: 6 }}>
                <DNAButton status="brandAlt" onPress={() => setShowSlider(false)}>
                  Cancel
                </DNAButton>
              </DNABox>
            </DNABox>
          </DNABox>
          {/* Search Results */}
          <DNABox fill appearance="col">
            { SearchResults }
          </DNABox>
        </DNABox>
      </DNASlider>
    </>
  )
}

const SearchTextContext = createContext<{
  currentSearchString: string;
  setCurrentSearchString:(text: string) => void;
    }>(null!)

export const SearchTextContextProvider : React.FC = (props) => {
  const { pathname } = useLocation();
  const defaultMSLSearchString = pathname.toLowerCase() === ROUTES.SEARCH_RESULTS_PUBLISHER.SEARCH
  const [currentSearchString, setCurrentSearch] = useState(defaultMSLSearchString ? pathname.split('/')[2] : '')

  const setCurrentSearchString  = (text: string) => setCurrentSearch(text)

  const context = {
    currentSearchString,
    setCurrentSearchString,
  }

  return (
    <SearchTextContext.Provider value={context}>
      {props.children}
    </SearchTextContext.Provider>
  )
}

export const useSearchTextContextProvider = () => useContext(SearchTextContext)

const SearchInput = () => {
  const { deviceMode } = useAppSettings()

  return (
    deviceMode === 'desktop'
      ? <SearchInputDeskTop />
      : <SearchInputTablet />

  )
}

SearchInput.displayName = 'SearchInput';

export default SearchInput;
