import React, { useEffect, useState } from 'react';

const PASSWORD_VALIDATIONS = {
  hasUppercase: false,
  hasLowercase: false,
  hasNumber: false,
  hasEightCharacters: false,
  matchingPasswords: false,
};

export interface ValidationType {
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasNumber: boolean;
  hasEightCharacters: boolean;
  matchingPasswords: boolean;
}

function withPasswordChecker(WrappedComponent) {
  return function (props) {
    const [passwordValidations, setPasswordValidations] = useState<ValidationType>(PASSWORD_VALIDATIONS);
    const [password, setPassword] = useState<string>('');
    const [repeatedPassword, setRepeatedPassword] = useState<string>('');
    const isPasswordValid = Object.values(passwordValidations).every((value) => value);

    useEffect(() => {
      checkPassword();
    }, [password, repeatedPassword]);

    function onPasswordChange(pswd: string): void {
      if (!pswd.endsWith(' ')) {
        setPassword(pswd);
      }
    }

    function onRepeatedPasswordChange(pswd: string): void {
      if (!pswd.endsWith(' ')) {
        setRepeatedPassword(pswd);
      }
    }

    function checkPassword(): void {
      setPasswordValidations({
        hasUppercase: !!password.match(new RegExp('[A-Z]')),
        hasLowercase: !!password.match(new RegExp('[a-z]')),
        hasNumber: !!password.match(new RegExp('[0-9]')),
        hasEightCharacters: password.length > 7,
        matchingPasswords: password !== '' && password === repeatedPassword,
      });
    }

    return (
      <WrappedComponent
        onRepeatedPasswordChange={onRepeatedPasswordChange}
        onPasswordChange={onPasswordChange}
        password={password}
        repeatedPassword={repeatedPassword}
        isPasswordValid={isPasswordValid}
        passwordValidations={passwordValidations}
        {...props}
      />
    );
  }
}

export default withPasswordChecker;
