import React, { useCallback } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { WebLinking as Linking } from '@alucio/core'
import { useAppSettings, useOnlineStatus } from 'src/state/context/AppSettings'
import { useCurrentUser } from 'src/state/redux/selector/user';
import { useDNARouteStack } from 'src/components/DNA/Navigation/DNARouteStack/DNARouteStack'

import {
  DNABox,
  DNAText,
  DNACard,
  DNADivider,
  luxColors,
  Iffy,
} from '@alucio/lux-ui'
import { UserRole } from '@alucio/aws-beacon-amplify/src/models';
import Auth from '@aws-amplify/auth';

const USER_ROLES = {
  [UserRole.ALUCIO_ADMIN]: 'Alucio Admin',
  [UserRole.TENANT_ADMIN]: 'Admin',
  [UserRole.TENANT_PUBLISHER]: 'Publisher',
  [UserRole.TENANT_VIEWER]: 'Viewer',
};

type TabletMenuProps = {
  onClose?: () => void,
  onChangePassword?: () => void
}

const styles = StyleSheet.create({
  cardWrapper: {
    paddingBottom: 40,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 8,
  },
  mainWrapper: {
    maxWidth: 1440,
    paddingHorizontal: 48,
    paddingVertical: 20,
  },
  tabsWrapper: {
    borderBottomColor: luxColors.border.primary,
    borderBottomWidth: 1,
  },
  header: {
    backgroundColor: luxColors.tag.secondary,
    minHeight: 72,
    maxHeight: 72,
  },
  item: {
    marginVertical: 12,
    paddingHorizontal: 16,
    paddingVertical: 12,
    height: 64,
  },
  menu: {
    marginLeft: 24,
    marginTop: 40,
  },
});

type options = {
  field: string,
  value: string,
  type: 'text' | 'link',
  onPress?: () => void,
  testID?: string,
}

interface ProfileTabletMenuProps {
  title: string,
  options: options[]
}

const MenuItem = (props: options) => {
  return (
    <>
      <DNABox
        appearance="row"
        spacing="between"
        style={styles.item}
        fill
        alignY="center"
      >
        <DNAText>{props.field}</DNAText>
        <DNAText style={props.type === 'link' && { color: luxColors.contentText.quaternary }} >{props.value}</DNAText>
      </DNABox>
      <DNADivider />
    </>
  )
}

const Menu = (props: ProfileTabletMenuProps) => {
  return (<DNABox spacing="medium" appearance="col" alignY="center" style={{ paddingHorizontal: 15 }}>
    <DNAText h5 style={styles.menu}>{props.title}</DNAText>
    <DNACard style={{ marginHorizontal: 30 }}>

      {props.options.map((option, index) => (
        option.type === 'text'
          ? <MenuItem key={`menu_item_${index}`} {...option} />
          : <TouchableOpacity
              onPress={() => option.onPress && option.onPress()}
              testID={option.testID}
          >
            <MenuItem {...option} />
          </TouchableOpacity>
      ))}

    </DNACard>
  </DNABox>)
}

const useProfileMenu = () => {
  const { userProfile: user } = useCurrentUser();
  const { isSSO } = useAppSettings()

  const fullName = user?.givenName + ' ' + user?.familyName
  const rawLabelValues = user?.defaultFilterValues
    ?.reduce<string[]>(
      (acc, label) =>
        (label.values ? [...acc, ...label.values] : acc),
      []
    ) ?? []

  const labelValues = Array
    .from(new Set(rawLabelValues))
    .sort()

  return {
    user: {
      user,
      fullName,
      labelValues,
    },
    isSSO,
  }
}

const SettingsTablet: React.FC<TabletMenuProps> = () => {
  const common = useProfileMenu()
  const stack = useDNARouteStack()
  const { isDeviceSwitchEnabled, dispatch } = useAppSettings()
  const isOnline = useOnlineStatus()

  const menuOptions: options[] = [
    { field: 'Name', value: common.user.fullName, type: 'text' },
    { field: 'Email', value: common.user.user?.email || '', type: 'text' },
    { field: 'Role', value: USER_ROLES[common.user.user!.role], type: 'text' },
    { field: 'My filters', value: common.user.labelValues.map(label => label).join(', '), type: 'text' },
  ]

  const SSOOptions: options[] = [
    {
      field: 'Change password',
      value: '>',
      type: 'link',
      testID: 'account-profile-button',
      onPress: useCallback(() => {
        if (isOnline) {
          stack.push('/profile/password');
        } else {
          stack.push('/profile/offline');
        }
      }, [isOnline]),
    },
  ]

  return (
    <>
      <Menu
        title="ACCOUNT INFORMATION"
        options={[
          ...menuOptions,
          ...(!common.isSSO ? SSOOptions : []),
        ]}
      />
      <Menu
        title="LOGOUT"
        options={
          [
            {
              field: 'Logout',
              value: '>',
              type: 'link',
              // [TODO-PWA] Temporary workaround because calling the signOut method doesn't seem to work
              onPress: async () => { await Auth.signOut(); },
            },
            {
              field: 'Clear all local data and logout',
              value: '>',
              type: 'link',
              onPress: () => { Linking.openURL('/logout', '_self') },
            },
          ]
        }
      />
      <Menu
        title="ABOUT"
        options={
          [
            {
              field: 'Privacy Policy',
              value: '>',
              type: 'link',
              onPress: () => { stack.push('/profile/privacy') },
            },
            {
              field: 'Terms of use',
              value: '>',
              type: 'link',
              onPress: () => { stack.push('/profile/tou') },
            },
          ]
        }
      />
      <Iffy is={isDeviceSwitchEnabled}>
        <Menu
          title="Developer"
          options={
            [
              {
                field: 'Switch to Desktop Mode',
                value: '>',
                type: 'link',
                onPress: () => { dispatch({ type: 'toggleDeviceMode' }) },
              },
            ]
          }
        />
      </Iffy>
    </>
  )
}

const SettingsDesktop: React.FC = () => {
  return null
}

const Settings: React.FC = () => {
  const appSettings = useAppSettings()

  return appSettings.deviceMode === 'desktop'
    ? <SettingsDesktop />
    : <SettingsTablet />
}

export default Settings
