import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import format from 'date-fns/format'
import { luxColors, Popover, DNABox, DNAButton, Iffy, DNAChip } from '@alucio/lux-ui'

import { DocumentAccessLevel, PurposeType } from '@alucio/aws-beacon-amplify/src/models'
import ExtensionBadge from '../../../screens/Documents/ExtensionBadge';
import PurposeBadge from 'src/components/PurposeBadge/PurposeBadge';
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip';

import { DocumentORM, DocumentVersionORM, FolderItemORM } from 'src/types/types';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle/useDocumentTitle'

import { useAppSettings, useOnlineStatus } from 'src/state/context/AppSettings';
import { useContentViewerModalState } from '../state/ContentViewerModalStateProvider';
import { batch, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/state/redux/store';
// eslint-disable-next-line max-len
import { useAllDocumentVersionMap, useAllDocumentsInstance, useAllPersonalDocumentsInstance } from 'src/state/redux/selector/document';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import head from 'lodash/head';

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: luxColors.backgroundButton.primary,
    borderWidth: 0,
    marginBottom: 5,
    marginLeft: 10,
    marginTop: 10,
    padding: 10,
  },
  headerBarIcons: {
    flexDirection: 'row-reverse',
    marginRight: 0,
    marginTop: 5,
  },
  headerBarSubTitle: {
    flexDirection: 'row',
    marginTop: 5,
  },
  headerBarSubTitleText: {
    color: luxColors.subtitle.primary,
  },
  headerBarTitle: {
    marginLeft: 25,
    marginTop: 10,
  },
  icon: {
    height: 24,
    width: 24,
  },
  tablet: {
    flexDirection: 'row-reverse',
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
  },
  tooltipTextColor: {
    color: 'white',
  },
});

interface MenuItem {
  iconName: string,
  tooltip: string,
}
interface HeaderProps {
  onOptionSelected: (option: string) => void;
  isPublisherMode: boolean;
  isContentUnavailable?: boolean;
  document: DocumentORM;
}
interface IconsProps {
  iconsArray: MenuItem[],
  onOptionSelected: (option: string) => void
}

const useHeaderSharedResources = () => {
  const {
    customDeck,
    folderItemORM,
    activeDoc,
    activeDocVersion,
    isModifiedDoc,
    setActiveDocVersionAndSlide,
    setActiveFolderItemAndSlide,
  } = useContentViewerModalState()
  const { isCurrentVersionDoc, isLastVersionDoc } = isVersions(activeDocVersion, folderItemORM);
  const docVersionMap = useAllDocumentVersionMap()
  const onlineStatus = useOnlineStatus()

  // Because the header needs to show the latest version of the document object (to reflect bookmarking)
  // we always fetch from redux
  // TODO: Per Kenny's discussion, this should be moved to the content preview modal context and used to populate the activeDoc
  const document = useAllDocumentsInstance({ filter: { model: { id: activeDoc.model.id } } })
  const personalDocument = useAllPersonalDocumentsInstance({ filter: { model: { id: activeDoc.model.id } } });
  const [currentDocumentORM] = document.length ? document : personalDocument;

  const title = useDocumentTitle(activeDocVersion, folderItemORM)

  const dispatch = useDispatch()

  const modalState = useSelector((state: RootState) => state.contentPreviewModal)

  /**
   * This file name indicates the top parent file that was initially opened by the user. Regardless of how many levels deep the
   * user navigates to, this file name should remain the same. User can also click on this text link to return to this file.
   */
  const prevElement = head(modalState.documentHistoryVersionNavigation);

  const documentVersion = prevElement
    ? docVersionMap[prevElement]
    : undefined

  // Because the header needs to show the latest version of the document object (to reflect bookmarking)
  // we always fetch from redux

  const goBack = () => {
    // This is required to return to the correct folder document version
    if (modalState.folderItemORM) {
      setActiveFolderItemAndSlide(modalState.folderItemORM, 1)
    }
    else {
      setActiveDocVersionAndSlide(documentVersion!, 1)
    }
    prevElement && batch((): void => {
      dispatch(
        contentPreviewModalActions.changeDocument({
          documentVersionId: prevElement,
          addToHistory: false,
        },
        ))

      dispatch(
        contentPreviewModalActions.removeFromHistory(true),
      )
    })
  }

  return {
    isCustomDeck: !!customDeck,
    title,
    activeDoc: currentDocumentORM,
    activeDocVersion,
    isModifiedDoc,
    folderItemORM,
    isCurrentVersionDoc,
    isLastVersionDoc,
    onlineStatus,
    prevElement,
    goBack,
  }
}

type IconOptionProps = {
  isCustomDeck?: boolean,
  isCurrentVersionDoc: boolean,
  isContentUnavailable?: boolean,
  isLastVersionDoc: boolean,
  isModifiedDoc: boolean,
  onlineStatus?: boolean,
}

const isVersions = (activeDocVersion: DocumentVersionORM, folderItemORM: FolderItemORM) => {
  const docVersion = activeDocVersion.model.versionNumber;
  // @ts-expect-error
  const folderVersionNumber = folderItemORM?.relations.item.model.versionNumber;
  return {
    isCurrentVersionDoc: folderItemORM && folderVersionNumber === docVersion,
    isLastVersionDoc: activeDocVersion.meta.version.isLatestPublished,
  };
}

function getIcons(document: DocumentORM, isPublisherMode: boolean, options: IconOptionProps) {
  const { isCurrentVersionDoc, isLastVersionDoc, isModifiedDoc, onlineStatus, isCustomDeck } = options;
  const disableCustomFile = isCurrentVersionDoc && !isLastVersionDoc;

  const canDownload = (isPublisherMode || document.meta.permissions.MSLDownload) && !isModifiedDoc && !isCustomDeck;
  const isNetworkConnected = onlineStatus;
  const canAddToFolder = (document.meta.permissions.addToFolder) && !disableCustomFile && !isCustomDeck;
  const canPresent = options.isCustomDeck ||
    (!options.isContentUnavailable && (isPublisherMode || document.meta.permissions.MSLPresent));

  const canBookmark =
    !options.isContentUnavailable &&
    document.meta.permissions.bookmark &&
    !(disableCustomFile || isModifiedDoc) && !isCustomDeck && !isPublisherMode;

  const downloadIcon = {
    iconName: 'download',
    tooltip: 'Download this file',
  }
  const addToFolderIcon = {
    iconName: 'folder-plus-outline',
    tooltip: 'Add file to folder',
  }
  const presentIcon = {
    iconName: 'presentation-play',
    tooltip: 'Present in full window',
  }
  const bookmarkIcon = {
    iconName: `bookmark${document.meta.bookmark.isBookmarked
      ? ''
      : '-outline'}`,
    tooltip: document.meta.bookmark.isBookmarked
      ? 'Remove from bookmarks'
      : 'Bookmark this file',
  }
  /** Conditionally generate the iconsArray */
  const iconsArray: MenuItem[] = [
    ...canDownload && isNetworkConnected ? [downloadIcon] : [],
    ...canAddToFolder ? [addToFolderIcon] : [],
    ...canBookmark ? [bookmarkIcon] : [],
    ...canPresent ? [presentIcon] : [],
  ]

  return iconsArray;
}

function DesktopIcons(props: IconsProps) {
  const { iconsArray, onOptionSelected } = props;
  return (
    <DNABox style={styles.headerBarIcons} >
      {iconsArray.map(
        ({ iconName, tooltip }, index: number) => (
          <Popover key={iconName}>
            <Popover.Anchor>
              <DNAButton
                status="flatAlt"
                context="round"
                key={`head_bar_icon_${index}`}
                style={styles.button}
                iconLeft={iconName}
                // @ts-ignore
                iconStyle={styles.icon}
                onPress={() => onOptionSelected(iconName)}
              />
            </Popover.Anchor>
            <Popover.Content offset={2}>
              <Text numberOfLines={1} style={styles.tooltipTextColor}>{tooltip}</Text>
            </Popover.Content>
          </Popover>
        ),
      )}
    </DNABox>
  )
}

function TabletIcons(props: IconsProps) {
  const { iconsArray, onOptionSelected } = props
  const reverseIconsArray = [...iconsArray.reverse()]

  return (
    <DNABox spacing="medium">
      {
        reverseIconsArray.map(({ iconName, tooltip }) => (
          <Popover key={iconName}>
            <Popover.Anchor>
              <DNAButton
                status="flatAlt"
                context="roundTablet"
                onPress={() => onOptionSelected(iconName)}
                iconLeft={iconName}
              />
            </Popover.Anchor>
            <Popover.Content offset={2}>
              <Text numberOfLines={1} style={styles.tooltipTextColor}>{tooltip}</Text>
            </Popover.Content>
          </Popover>
        ))
      }
    </DNABox>
  )
}

function HeaderDesktop(props: HeaderProps) {
  const { onOptionSelected, isPublisherMode, isContentUnavailable } = props
  const {
    activeDocVersion,
    isCustomDeck,
    title,
    activeDoc,
    isModifiedDoc,
    isCurrentVersionDoc,
    isLastVersionDoc,
    onlineStatus,
    prevElement,
    goBack,
  } = useHeaderSharedResources()

  const iconsArray: MenuItem[] = getIcons(
    activeDoc,
    isPublisherMode,
    { isCurrentVersionDoc, isLastVersionDoc, isModifiedDoc, onlineStatus, isContentUnavailable, isCustomDeck },
  );

  const isPersonalDocument = activeDoc.model.accessLevel === DocumentAccessLevel.USER;
  const displayIcons = isCustomDeck || ((isCurrentVersionDoc || isLastVersionDoc) && !isPersonalDocument);

  return (
    <>
      <DNABox fill>
        <DNABox fill>
          <Iffy is={prevElement}>
            <DNABox alignX="center" alignY="center">
              <DNAButton
                status="flat"
                appearance="ghostAlt"
                style={[styles.button, { marginRight: 16, height: 40, width: 40 }]}
                iconLeft="chevron-left"
                iconStyle={styles.icon}
                onPress={goBack}
              />
            </DNABox>
          </Iffy>
          <DNABox appearance="col" alignX="start" style={styles.headerBarTitle} fill alignY="center">
            <Text
              testID="preview-modal-title"
              numberOfLines={2}
              style={styles.title}
            >
              {title}
            </Text>
            <Iffy is={!isCustomDeck && !isPersonalDocument}>
              <DNABox appearance="col" style={styles.headerBarSubTitle}>
                <DNABox appearance="row" spacing="small" alignY="center">
                  <PurposeBadge
                    purpose={activeDocVersion.model.purpose as PurposeType}
                    description={activeDocVersion.model.purpose}
                  />
                  <ExtensionBadge extension={activeDocVersion.model.type} />
                  <DNADocumentChip item={activeDocVersion} variant={'status'} />
                  <Iffy is={isModifiedDoc}>
                    <DNAChip
                      appearance="tag"
                      status="subtle"
                    >MODIFIED
                    </DNAChip>
                  </Iffy>
                  <Text testID="last-update" style={styles.headerBarSubTitleText}>
                    Last Updated {format(
                    new Date(activeDocVersion.model.publishedAt || activeDocVersion.model.createdAt), 'MM/dd/yyyy')}
                  </Text>
                </DNABox>
              </DNABox>
            </Iffy>
            <Iffy is={isPersonalDocument}>
              <ExtensionBadge extension={activeDocVersion.model.type} />
            </Iffy>
          </DNABox>
        </DNABox>
        <Iffy is={displayIcons}>
          <DesktopIcons iconsArray={iconsArray} onOptionSelected={onOptionSelected} />
        </Iffy>
      </DNABox>
    </>
  );
}

function HeaderTablet(props: HeaderProps) {
  const { onOptionSelected, isPublisherMode, isContentUnavailable } = props
  const {
    isCustomDeck,
    title,
    activeDoc,
    activeDocVersion,
    isCurrentVersionDoc,
    isLastVersionDoc,
    isModifiedDoc,
    onlineStatus,
    prevElement,
    goBack,
  } = useHeaderSharedResources()
  const iconsArray: MenuItem[] = getIcons(
    activeDoc,
    isPublisherMode,
    { isCurrentVersionDoc, isLastVersionDoc, isModifiedDoc, onlineStatus, isContentUnavailable, isCustomDeck },
  )

  const isPersonalDocument = activeDoc.model.accessLevel === DocumentAccessLevel.USER;
  const displayIcons = isCustomDeck || ((isCurrentVersionDoc || isLastVersionDoc) && !isPersonalDocument);

  return (
    <DNABox fill>
      <Iffy is={prevElement}>
        <DNABox alignX="center" alignY="center">
          <DNAButton
            status="flat"
            appearance="ghostAlt"
            style={[styles.button, { marginRight: 16, height: 40, width: 40 }]}
            iconLeft="chevron-left"
            iconStyle={styles.icon}
            onPress={goBack}
          />
        </DNABox>
      </Iffy>
      {/* Left Side */}
      <DNABox appearance="col" fill>
        <Text
          testID="preview-modal-title"
          numberOfLines={2}
          style={styles.title}
        >
          {title}
        </Text>
        <View style={styles.headerBarSubTitle}>
          <DNABox appearance="row" spacing="small" alignY="center">
            <Iffy is={!isCustomDeck && !isPersonalDocument}>
              <PurposeBadge
                purpose={activeDocVersion.model.purpose as PurposeType}
                description={activeDocVersion.model.purpose}
              />
              <ExtensionBadge extension={activeDocVersion.model.type} />
              <DNADocumentChip item={activeDocVersion} variant={'status'} />
              <Iffy is={isModifiedDoc}>
                <DNAChip
                  appearance="tag"
                  status="subtle"
                >MODIFIED
                </DNAChip>
              </Iffy>
              <Text style={styles.headerBarSubTitleText}>
                Last Updated {format(
                new Date(activeDocVersion.model.publishedAt || activeDocVersion.model.updatedAt), 'MM/dd/yyyy')}
              </Text>
            </Iffy>
            <Iffy is={isPersonalDocument}>
              <ExtensionBadge extension={activeDocVersion.model.type} />
            </Iffy>
          </DNABox>
        </View>
      </DNABox>

      {/* Right Side Icons */}
      <Iffy is={displayIcons}>
        <TabletIcons iconsArray={iconsArray} onOptionSelected={onOptionSelected} />
      </Iffy>
    </DNABox>
  );
}

const Header = (props) => {
  const { deviceMode } = useAppSettings()

  return deviceMode === 'desktop'
    ? <HeaderDesktop {...props} />
    : <HeaderTablet {...props} />
}

export default Header
