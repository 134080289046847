import im from 'immer'
import isValid from 'date-fns/isValid'
import parseISO from 'date-fns/parseISO'
import compareAsc from 'date-fns/compareAsc'

export type FilterOptions<T> = {
  [P in keyof T]?: {
    [K in keyof T[P]]?: T[P][K] | // Single value Check
    ((val: T[P][K]) => boolean) | // Custom function
    T[P][K][]                     // Array of values
  }
}

export type SortOptions<T> = {
  [P in keyof T]?: {
    [K in keyof T[P]]?: 'asc' | 'desc' | ((a: T[P][K], b: T[P][K]) => number)
    } | ('asc' | 'desc' | ((a: T[P], b: T[P]) => number))
}

export type PaginationOptions = {
  currentPage: number,
  pageSize: number,
}

export type FilterAndSortOptions<T> = {
  filter?: FilterOptions<T>,
  sort?: SortOptions<T>[],
  paginate?: PaginationOptions
}

export const selectOptions = <T extends unknown>(_, __, opts: FilterAndSortOptions<T>) => opts

// [TODO] - Use any for now, can we use [T][P][K] ?
// - This definitely needs to be unit tested
export function multiTypeSort(
  a: any,
  b: any,
  opts: {
    dir?: ('asc' | 'desc'),
    fn?: (
      a: any,
      b: any,
      dir?: ('asc' | 'desc')
    ) => number
  },
): number {
  const dirModifier = opts.dir
    ? opts.dir === 'asc' ? 1 : -1
    : 1

  if (a === undefined || b === undefined || a === null || b === null)
  { return ((a && -1) ?? (b && 1) ?? 0) * dirModifier }

  if (opts.fn) {
    return opts.fn(a, b, opts.dir)
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    // Comparing arrays by comparing elements of arrays
    let result = 0;
    let idx = 0;
    do {
      result = multiTypeSort(a[idx], b[idx], opts);
      idx++;
    } while (result === 0 && idx < a.length && idx < b.length)
    return result;
  }

  if (typeof a === 'number' && typeof b === 'number')
  { return (a - b) * dirModifier }

  if (typeof a === 'string' && typeof b === 'string') {
    const isValidDates = isValid(parseISO(a)) && isValid(parseISO(b))
    if (isValidDates)
    { return compareAsc(parseISO(a), parseISO(b)) * dirModifier }

    return a.localeCompare(b) * dirModifier
  }

  if (typeof a === 'boolean' && typeof b === 'boolean')
  { return (+b - +a) * dirModifier }

  throw new Error('Could not determine values for sorting')
}

export function filterCollection<T>(
  collection: T[],
  opts?: FilterAndSortOptions<T>,
): T[] {
  if (!opts?.filter)
  { return collection; }

  return im(collection, draft => {
    return draft.filter(collectionItem => {
      const allCheck = Object
        .entries(opts.filter ?? {})
        // [TODO]: Figure out this TS issue to describe the generic shape of an ORM
        .every(([entityKey, entityValue]: [string, any]) => {
          const entityCheck = Object
            .entries(entityValue ?? { })
            .every(([filterKey, filterValue]) => {
              const entityValue = collectionItem[entityKey][filterKey]

              // [TODO]: Type check this a boolean
              const singleCheck = (typeof filterValue === 'function')
                ? filterValue(entityValue)
                : Array.isArray(filterValue)
                  ? !!filterValue.find(a => a === entityValue)
                  : entityValue === filterValue

              return singleCheck
            })
          return entityCheck
        })
      return allCheck
    })
  }) as T[]
}

export function sortCollection<T>(
  records: T[],
  opts?: FilterAndSortOptions<T>,
): T[] {
  if (!opts?.sort)
  { return records; }

  return im(records, draft => {
    return draft.sort((a, b) => {
      let compareVal = 0

      for (const sortCriteria of opts.sort ?? []) { // [TODO] if (check is not catching this)
        for (const entity in sortCriteria) {
          for (const sortKey in sortCriteria[entity]) {
            const sortVal = sortCriteria[entity][sortKey]
            const sortType = typeof sortVal === 'function'
              ? { fn: sortVal }
              : { dir: sortVal }

            compareVal = multiTypeSort(
              // @ts-ignore
              a[entity][sortKey],
              // @ts-ignore
              b[entity][sortKey],
              sortType as any, // [TODO]: Figure out this TS issue to describe the generic shape of an ORM
            )

            if (compareVal !== 0)
            { return compareVal }
          }
        }
      }

      return 0
    })
  }) as T[]
}

export function paginateCollection<T>(
  records: T[],
  opts: FilterAndSortOptions<T>,
): {
  totalRecords: number,
  pagedRecords: T[]
} {
  if (!opts?.paginate)
  { return { totalRecords: records.length, pagedRecords: records } }

  const { currentPage, pageSize } = opts.paginate
  const startIdx = (currentPage - 1) * pageSize
  const endIdx = startIdx + pageSize

  return {
    totalRecords: records.length,
    pagedRecords: records.slice(startIdx, endIdx),
  }
}
