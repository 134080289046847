// [TODO-1703] - Merge with DNADocumentThumbnail - address in [BEAC-1781]
import React from 'react'
import {
  Stack,
  DNAIcon,
  DNABox,
  Iffy,
} from '@alucio/lux-ui'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import { CustomDeckORM } from 'src/types/types'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { Pressable } from 'react-native'

export interface DNACustomDeckProps {
  customDeckORM: CustomDeckORM,
  showProcessing?: boolean,
  unavailableContent?: boolean,
  width: number,
  height: number,
  onPress?: () => void,
}

export type ThumbnailProps = Exclude<DNACustomDeckProps, 'showProcessing'>

// [TODO-REFACTOR] - We should have one "connected" thumbnail -- consolidate with DNADocumentThumbnail
const DNACustomDeckThumbnail: React.FC<DNACustomDeckProps> = (props) => {
  const {
    unavailableContent,
    customDeckORM,
    width,
    height,
    onPress,
  } = props

  const thumbnailStacks =
    (<React.Fragment>
      <Iffy is={!unavailableContent}>
        <Stack anchor="center">
          <Stack.Layer>
            <DNAThumbnail
              s3URL={customDeckORM.meta.assets.thumbnailKey}
              height={height}
              width={width}
              useLoadingIndicator
            />
          </Stack.Layer>
          {/* Play Icon Layer */}
          <Stack.Layer anchor="bottomRight">
            <Iffy is={!!onPress}>
              <DNAIcon.Styled
                appearance="ghost"
                name="play"
                status="darkAlt"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.64)',
                  borderRadius: 2,
                  paddingBottom:1,
                }}
              />
            </Iffy>
          </Stack.Layer>
        </Stack>
      </Iffy>
      { /* NO CONTENT AVAILABLE THUMBNAIL */}
      <Iffy is={unavailableContent}>
        <Stack anchor="center">
          <Stack.Layer>
            <DNAThumbnail
              s3URL={customDeckORM.meta.assets.thumbnailKey}
              height={height}
              width={width}
              useLoadingIndicator
            />
          </Stack.Layer>
          <Stack.Layer>
            <DNABox
              alignY="center"
              alignX="center"
              style={{ backgroundColor: colors['color-dark-transparent-200'], minHeight: height, width }}
            >
              <DNAIcon.Styled
                appearance="ghost"
                status="basic"
                name="cloud-off-outline"
                width={width > 200 ? 48 : 24}
                height={width > 200 ? 32 : 16}
              />
            </DNABox>
          </Stack.Layer>
        </Stack>
      </Iffy>
    </React.Fragment>)

  /** Allow for optional custom interaction behavior */
  if (onPress) {
    return (
      <Pressable onPress={onPress}>
        {thumbnailStacks}
      </Pressable>
    )
  }

  /** Otherwise, return non-interactive version allowing for any parent container interactions to take effect */
  return (
    <>
      { thumbnailStacks }
    </>
  )
}

export default DNACustomDeckThumbnail
