import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { MeetingORM } from 'src/types/types'

const formSchema = z.object({
  title: z
    .string()
    .nonempty({ message: 'This field is required' }),
  notes: z
    .string()
    .optional(),
})

interface useMeetingsFormStateType {
  meetingORM?: MeetingORM
}

const useMeetingsFormState = (props: useMeetingsFormStateType) => {
  const { meetingORM } = props
  const meetingForm = useForm({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      title: meetingORM?.model.title,
      notes: meetingORM?.model.notes || '',
    },
  })

  return { meetingForm }
}

export default useMeetingsFormState;
