import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import im from 'immer'
import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui'
import { Dispatch } from 'redux'
import { DocumentORM, EmailTemplateORM } from 'src/types/types'
import { CurrentUser, useCurrentUser } from 'src/state/redux/selector/user'
import { useRouteMatch, match } from 'src/router'

import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal'

import { handleBookmark } from 'src/components/Library/Util/util'
import { downloadContentFromCloudfront } from 'src/utils/loadCloudfrontAsset/loadCloudfrontAsset'
import DNADocumentStatusChangeModal from 'src/components/DNA/Modal/DNADocumentStatusChange'

import DNADocumentAddToFolder from 'src/components/DNA/Modal/DNADocumentAddToFolder'
import DNAFileRenameModal from '../Modal/DNAFileRenameModal'
import { copyShareableLinkToClipboard, shareEmail } from 'src/utils/shareLink/shareLink'
import { ShareType } from '@alucio/aws-beacon-amplify/src/models'
import { ToastActions } from '@alucio/lux-ui/lib/components/Toast/useToast'
import DNAFileShareModal, { ShareFileOption } from '../Modal/DNAFileShareModal'
import { useOnlineStatus } from 'src/state/context/AppSettings'
import { useEmailTemplateListForMSL } from 'src/state/redux/selector/emailTemplate'
import { sortCollection } from 'src/state/redux/selector/common'
import emailTemplateQuery from 'src/state/redux/emailTemplate/query'

import API, { graphqlOperation } from '@aws-amplify/api';
import {
  downloadDocumentVersionAsPdfFile,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations'
import { download } from 'src/screens/Documents/Export'

/**
 * [TODO] - Create safer structure/typings with Folder equivalent
 */

export type MatchFolderParams = {
  page: string,
  folderId: string
}

export type MatchNestedFolderParams = MatchFolderParams & {
  nestedFolderId: string
}

// [TODO-2126]  - Currently we use onCallback to allow any action to have allow deferred execution
//                for actions. (i.e. First dismiss a prompt THEN execute an action in here)
//              - ActionCallbacks are non-deferred but accessible to the action
//              - Consider combining onCallback into ActionsCallback, where we may accept both
//                deferred and non-deferred in one argument (instead of two separate ones)
export type ActionCallbacks = Partial<Record<DocumentContextAction, (documentORM: DocumentORM) => void>>

// [TODO]
//  - Can probably refactor this into action specific arguments
//    That is, arguments are starting to get crazy and aren't need for all actions
export type DocumentContextOptions = {
  [key in DocumentContextAction]: {
    icon: string,
    title: string,
    onPress: (
      d: DocumentORM,
      dispatch: Dispatch,
      // [TODO] - Not the cleanest, starting to deviate ...
      user: CurrentUser,
      match: {
        folder: match<MatchFolderParams> | null
        nestedFolder: match<MatchNestedFolderParams> | null
      },
      toast: ToastActions,
      isNetworkConnected?: boolean,
      emailTemplates?: EmailTemplateORM[],
      onCallback?: (cb: () => void) => void,
      actionCallback?: (docORM: DocumentORM) => void
    ) => () => void
  }
}

export type BindDocumentContextActions = {
  [key in DocumentContextAction]: (d: DocumentORM) => () => void
}

export enum DocumentContextActions {
  addToFolder = 'Add to folder',
  rename = 'Rename',
  duplicate = 'Duplicate',
  bookmark = 'Add to bookmarks',
  unbookmark = 'Remove bookmark',
  publish = 'Publish',
  archive = 'Archive',
  unarchive = 'Unarchive',
  delete = 'Delete',
  copyShareLink = 'Copy shareable link',
  downloadAsPDF = 'Download as PDF',
  shareEMail = 'Share',
  revoke = 'Revoke',
  present = 'Present',
  preview = 'Preview',
  publisherPreview = 'Publisher Preview',
  download = 'Download',
  version = 'Version',
}

export type DocumentContextAction = keyof typeof DocumentContextActions

/** [TODO] WE CAN PROBABLY CONSOLIDATE BOTH FOLDER AND DOCUMENT */
/** This is a bit more convenient than using the raw contextActions below */
export const useDNADocumentActions = (
  onCallback?: (cb: () => void) => void,
  actionCallbacks?: ActionCallbacks,
): BindDocumentContextActions => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const folder = useRouteMatch<MatchFolderParams>('/:page/:folderId')
  const nestedFolder = useRouteMatch<MatchNestedFolderParams>('/:page/:folderId/:nestedFolderId')
  const toast = useToast()
  const onlineStatus = useOnlineStatus()
  const isNetworkConnected = onlineStatus
  const emailTemplates = useEmailTemplateListForMSL()
  const orderedTemplates = sortCollection(
    emailTemplates,
    emailTemplateQuery.sorts.titleAsc,
  )

  const bound = useMemo(() => im(documentContextOptions, draft => {
    for (const action in documentContextOptions) {
      draft[action] = (documentORM: DocumentORM) => documentContextOptions[action].onPress(
        documentORM,
        dispatch,
        currentUser,
        { folder, nestedFolder },
        toast,
        isNetworkConnected,
        orderedTemplates,
        onCallback,
        actionCallbacks?.[action],
      )
    }
  }) as unknown as BindDocumentContextActions,
  [dispatch, currentUser, folder, nestedFolder, toast, orderedTemplates, onCallback, actionCallbacks],
  )
  return bound
}

export const documentContextOptions: DocumentContextOptions = {
  addToFolder: {
    icon: 'folder-plus',
    title: DocumentContextActions.addToFolder,
    onPress: (docORM, dispatch, _, __, toast) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (props) => (
          <DNADocumentAddToFolder
            {...props}
            itemORM={docORM}
            toast={toast}
          />
        ),
      }),
      )
    },
  },
  rename: {
    icon: 'pencil',
    title: DocumentContextActions.rename,
    onPress: (documentORM, dispatch) => () => {
      dispatch(DNAModalActions.setModal(
        {
          isVisible: true,
          allowBackdropCancel: false,
          component: (props) => (
            <DNAFileRenameModal
              {...props}
              documentORM={documentORM}
            />
          ),
        },
      ))
    },
  },
  duplicate: {
    icon: 'content-copy',
    title: DocumentContextActions.duplicate,
    onPress: () => () => {
      console.warn('Not implemented yet')
    },
  },
  bookmark: {
    icon: 'bookmark',
    title: DocumentContextActions.bookmark,
    onPress: (documentORM, dispatch, user) => () => {
      if (!user) throw new Error('Could not bookmark')

      handleBookmark(
        documentORM.model,
        user,
        dispatch,
      )
    },
  },
  unbookmark: {
    icon: 'bookmark',
    title: DocumentContextActions.unbookmark,
    onPress: () => () => {
      console.warn('Not implemented yet')
    },
  },
  publish: {
    icon: 'publish',
    title: DocumentContextActions.publish,
    onPress: (documentORM, dispatch, _, __, toast) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (modalProps) => (
          <DNADocumentStatusChangeModal
            {...modalProps}
            documentORM={documentORM}
            action="PUBLISHED"
            toast={toast}
          />
        ),
      }))
    },
  },
  archive: {
    icon: 'archive',
    title: DocumentContextActions.archive,
    onPress: (documentORM, dispatch, _, __, toast, ___, ____, onCallback) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (modalProps) => (
          <DNADocumentStatusChangeModal
            {...modalProps}
            documentORM={documentORM}
            action={documentORM.relations.documentVersions.length > 1 ? 'ARCHIVED_WITH_VERSIONS' : 'ARCHIVED'}
            toast={toast}
            onConfirmCallback={onCallback}
          />
        ),
      }))
    },
  },
  unarchive: {
    icon: 'archive',
    title: DocumentContextActions.unarchive,
    onPress: () => () => {
      console.warn('Not implemented yet')
    },
  },
  shareEMail: {
    icon: 'email-send-outline',
    title: DocumentContextActions.shareEMail,
    onPress: (documentORM, dispatch, currentUser, _, toast, __, emailTemplates) => () => {
      // eslint-disable-next-line max-len
      const hasAssociatedFiles = documentORM.relations.version.latestDocumentVersionPublished?.relations.associatedFiles.length! > 0
      // NOTE: we have a future story coming up planning to display the modal regardless of associatedFile status
      // in that story we can remove the fileToShare const and the else statement below
      const fileToShare: ShareFileOption = {
        isMainDoc: true,
        isDefault: true,
        beingShared: true,
        isDistributable: true,
        contentProps: {
          contentId: documentORM.relations.version.latestDocumentVersionPublished?.model.id!,
          type: ShareType.DOCUMENT_VERSION,
          title: documentORM.relations.version.latestDocumentVersionPublished?.model.title!,
        },
      }
      if (hasAssociatedFiles || emailTemplates?.length) {
        dispatch(DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: (modalProps) => (
            <DNAFileShareModal
              toast={toast}
              documentVersionORM={documentORM.relations.version.latestDocumentVersionPublished!}
              currentUser={currentUser}
              emailTemplates={emailTemplates!}
              {...modalProps}
            />
          ),
        }))
      } else {
        shareEmail(
          [fileToShare],
          currentUser.userProfile!,
        )
      }
    },
  },
  copyShareLink: {
    icon: 'link-variant',
    title: DocumentContextActions.copyShareLink,
    onPress: (documentORM, dispatch, currentUser, _, toast, __, emailTemplates) => () => {
      analytics?.track('DOCUMENT_SHARE', {
        action: 'SHARE',
        channel: 'LINK',
        category: 'DOCUMENT',
        documentId: documentORM.model.id,
        documentVersionId: documentORM.relations.version.latestDocumentVersionPublished?.model.id,
      })
      // NOTE: we have a future story coming up planning to display the modal regardless of associatedFile status
      // in that story we can remove the fileToShare const and the else statement below
      const fileToShare: ShareFileOption = {
        isMainDoc: true,
        isDefault: true,
        beingShared: true,
        isDistributable: true,
        contentProps: {
          contentId: documentORM.relations.version.latestDocumentVersionPublished?.model.id!,
          title: documentORM.relations.version.latestDocumentVersionPublished?.model.title!,
          type: ShareType.DOCUMENT_VERSION,
        },
      }
      if (
        documentORM.relations.version.latestDocumentVersionPublished?.relations.associatedFiles.length!
      ) {
        dispatch(DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: (modalProps) => (
            <DNAFileShareModal
              toast={toast}
              documentVersionORM={documentORM.relations.version.latestDocumentVersionPublished!}
              currentUser={currentUser}
              emailTemplates={emailTemplates!}
              {...modalProps}
            />
          ),
        }))
      } else {
        copyShareableLinkToClipboard([fileToShare], toast)
      }
    },
  },
  delete: {
    icon: 'trash-can-outline',
    title: DocumentContextActions.delete,
    onPress: (documentORM, dispatch, _, __, toast, ___, ____, onCallback) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (modalProps) => (
          <DNADocumentStatusChangeModal
            {...modalProps}
            documentORM={documentORM}
            action={documentORM.relations.documentVersions.length > 1 ? 'DELETED_WITH_VERSIONS' : 'DELETED'}
            toast={toast}
            onConfirmCallback={onCallback}
          />
        ),
      }))
    },
  },
  revoke: {
    icon: 'undo-variant',
    title: 'Revoke',
    onPress: (documentORM, dispatch, _, __, toast, ___, ____, onCallback) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (modalProps) => (
          <DNADocumentStatusChangeModal
            {...modalProps}
            documentORM={documentORM}
            action={documentORM.relations.documentVersions.length > 1 ? 'REVOKED_WITH_VERSIONS' : 'REVOKED'}
            toast={toast}
            onConfirmCallback={onCallback}
          />
        ),
      }))
    },
  },
  present: {
    icon: 'play',
    title: DocumentContextActions.present,
    onPress: (documentORM, dispatch, _, __, ___, ____) => () => {
      const documentVersion = documentORM.relations.version.latestUsableDocumentVersion

      dispatch(
        contentPreviewModalActions.setModalVisibility({
          documentVersionId: documentVersion.model.id,
          isFullWindow: true,
          isOpen: true,
          fromEllipsis: true,
        }),
      )
    },
  },
  preview: {
    icon: 'play',
    title: DocumentContextActions.present,
    onPress: (documentORM, dispatch, _, __, toast, isNetworkConnected) => () => {
      // [TODO-2126] - Use the ORM's latestUsableDocumentVersion instead
      const documentVersion = (!isNetworkConnected && documentORM.relations.version.cachedDocumentVersion) ||
        documentORM.relations.version.latestDocumentVersionPublished ||
        documentORM.relations.version.latestDocumentVersion

      dispatch(
        contentPreviewModalActions.setModalVisibility({
          documentVersionId: documentVersion.model.id,
          isFullWindow: false,
          isOpen: true,
          toast: toast,
        }),
      )
    },
  },
  publisherPreview: {
    icon: 'play',
    title: DocumentContextActions.publisherPreview,
    onPress: (documentORM, dispatch, _, __, toast, isNetworkConnected) => () => {
      const documentVersion = (!isNetworkConnected && documentORM.relations.version.cachedDocumentVersion) ||
        documentORM.relations.version.latestDocumentVersion

      dispatch(
        contentPreviewModalActions.setModalVisibility({
          documentVersionId: documentVersion.model.id,
          isFullWindow: false,
          isOpen: true,
          toast: toast,
        }),
      )
    },
  },
  download: {
    icon: 'download',
    title: DocumentContextActions.download,
    onPress: (documentORM) => () => {
      const currentDocVersion = documentORM.relations.version.latestDocumentVersionPublished ||
        documentORM.relations.version.latestDocumentVersion

      analytics?.track('DOCUMENT_DOWNLOAD', {
        action: 'DOWNLOAD',
        category: 'DOCUMENT',
        documentId: documentORM.model.id,
        documentVersionId: currentDocVersion.model.id,
      })

      downloadContentFromCloudfront(
        currentDocVersion.model.srcFile.key,
        currentDocVersion.model.srcFilename,
        documentORM.model.type,
      )
    },
  },
  version: {
    icon: 'layers-triple',
    title: DocumentContextActions.version,
    onPress: (documentORM, _, __, ___, ____, ______, _______, ________, versionCallback) => () => {
      // [NOTE] - We rely on parent component to do some action to enable the versioning panel
      //          i.e. at a component level
      versionCallback?.(documentORM)
    },
  },
  downloadAsPDF: {
    icon: 'file-pdf-box',
    title: DocumentContextActions.downloadAsPDF,
    onPress: (documentORM, _, __, ___, toast) => async () => {
      const currentDocVersion = documentORM.relations.version.latestDocumentVersionPublished ||
        documentORM.relations.version.latestDocumentVersion
      toast.add(
        <GenericToast
          title="Generating PDF download..."
          status="information"
        />,
        ToastOrientations.TOP_RIGHT,
      )
      try {
        const { data } = await API.graphql(
          graphqlOperation(downloadDocumentVersionAsPdfFile, {
            documentVersionId: currentDocVersion.model.id,
          }),
        ) as { data: { downloadDocumentVersionAsPdfFile: string } }

        download(data.downloadDocumentVersionAsPdfFile, `${currentDocVersion.model.title?.replace('.pptx', '')}.pdf`)
      } catch (e) {
        console.warn(`Error downloading the pdf file for this document id ${currentDocVersion.model.id}`, e)
      }
    },
  },
}
