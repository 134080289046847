import { State, Interpreter } from 'xstate'
import { BroadcastChannel } from 'broadcast-channel';
import { PDFChannelMessage, PlayerMode, PPZTransform } from '@alucio/core'
import { RATIOS } from '@alucio/lux-ui/src/components/layout/DNAAspectRatio/DNAAspectRatio';
import { PresentationBroadcastContent } from '@alucio/core/lib/state/context/PresentationPlayer/types';

/**
 * ---------------------
 *  META
 * ---------------------
 */

export enum Tags {
  IS_IDLE = 'IS_IDLE',
  IS_CONTENT_LOADED = 'IS_CONTENT_LOADED',
}

/**
 * ---------------------
 *  STATE MACHINE
 * ---------------------
 */

export type PlayerWrapperContext = {
  aspectRatio: RATIOS,
  frameId: string,
  meetingId: string,
  presentableState?: PresentationBroadcastContent,
  playerMode: PlayerMode,
  playerState?: PresentationBroadcastContent,
  presentationStateChannel: BroadcastChannel<PresentationChannelMessage>,
  presentationStateObserver?: any // [NOTE] - Should type but doesn't matter too much
  playerStateChannel: BroadcastChannel<PDFChannelMessage>,
  playerStateObserver?: any
}

// [NOTE] - Typed context for each states is a bit much, but doable
export type PlayerWrapperState = {
  value: {
    idle: {},
    presenting: {
      loading,
      loaded
    }
  },
  context: PlayerWrapperContext;
}

export type MachineState = State<
  PlayerWrapperContext,
  PlayerWrapperEvents,
  any,
  PlayerWrapperState
>;

export type MachineSend = Interpreter<
  PlayerWrapperContext,
  any,
  PlayerWrapperEvents,
  PlayerWrapperState
>['send'];

// Presentation Broadcast Channel Message Types
export type PresentationChannelMessage = PresentationStateSyncEvent | NavigatePastEvent |
  PPZTransformEvent | PlayerUIEvent | PresentationProgress | PresentationStateIdleEvent

export type PresentationStateSyncEvent = {
  type: 'PRESENTATION_STATE_SYNC',
  meetingId: string,
  payload: PresentationBroadcastContent
}

export type PresentationStateIdleEvent = {
  type: 'PRESENTATION_STATE_IDLE',
  meetingId: string,
}

export type NavigatePastEvent = {
  type: 'NAVIGATE_PAST_FIRST' | 'NAVIGATE_PAST_LAST'
  meetingId: string,
}

export type PlayerUIEvent = {
  type: 'SHOW_SEARCH' | 'SHOW_MY_CONTENT'
  meetingId: string,
}

export type PPZTransformEvent = {
  type: 'PPZ_TRANSFORM',
  meetingId: string,
  payload: PPZTransform
}

export type PresentationProgress = {
  type: 'PRESENTATION_PROGRESS',
  meetingId: string,
  payload: {
    step: number,
    page: number,
    groupId: string,
  }
}

// State Machine Events
export type EVT_PRESENTATION_STATE_SYNC = {
  type: 'PRESENTATION_STATE_SYNC',
  meetingId: string,
  payload: PresentationBroadcastContent
}

export type EVT_PRESENTATION_STATE_IDLE = {
  type: 'PRESENTATION_STATE_IDLE',
  meetingId: string,
}

export type EVT_PLAYER_ACTION = {
  type: 'PLAYER_ACTION',
  // [TODO] - Use the actual event names emitted from the player
  payload: PDFChannelMessage
}

export type PlayerWrapperEvents =
  | EVT_PRESENTATION_STATE_SYNC
  | EVT_PLAYER_ACTION
  | EVT_PRESENTATION_STATE_IDLE
