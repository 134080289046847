import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { DocumentORM, FolderItemORM, FolderORM } from 'src/types/types'

import { DNAModal, DNABox, DNAButton, DNAText, GenericToast, ToastOrientations, useDisableSwipe } from '@alucio/lux-ui'
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import DNAFolderNestedList, { FolderSelectedMap } from 'src/components/DNA/Folder/NestedList/DNAFolderNestedList'
import DNAFolderUpsertModal from 'src/components/DNA/Modal/DNAFolderUpsert'
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast'
import { folderActions } from 'src/state/redux/slice/folder'
import * as guards from 'src/types/typeguards'

export type DNAFolderArchiveAddItem = {
  itemORM: DocumentORM | FolderItemORM
  toast?: ToastActions
}

export const getDocumentORMFromItem = (itemORM: DocumentORM | FolderItemORM | undefined) : DocumentORM | undefined => (
  guards.isDocumentORM(itemORM)
    ? itemORM
    : guards.isDocumentVersionORM(itemORM?.relations.item)
      ? itemORM?.relations.item.relations.document
      : undefined
)

const trackDocument = (folder: FolderORM, itemORM: DocumentORM | FolderItemORM) => {
  if (guards.isFolderItemORM(itemORM) && guards.isCustomDeckORM(itemORM.relations.item)) {
    const customDeck = itemORM.relations.item;
    analytics?.track('FOLDER_ADD_CUSTOM_DECK', {
      action: 'ADD_CUSTOM_DECK',
      category: 'FOLDER',
      folderId: folder.model.id,
      customDecktId: customDeck.model.id,
    });
  }
  else {
    const document = getDocumentORMFromItem(itemORM)
    analytics?.track('FOLDER_ADD_DOCUMENT_VERSION', {
      action: 'ADD_DOCUMENT_VERSION',
      category: 'FOLDER',
      folderId: folder.model.id,
      documentId: document!.model.id,
      documentVersionId: document?.relations?.version?.latestDocumentVersionPublished?.model.id,
    });
  }
}

export const DNAFolderArchiveModal: DNAModalVariant<DNAFolderArchiveAddItem> = (props) => {
  const { itemORM, toggleModal, pushModal, closeModal, toast } = props
  const [selected, setSelected] = useState<FolderSelectedMap>({})

  const dispatch = useDispatch()
  useDisableSwipe()

  const targetFolders = Object
    .values(selected)
    .filter(selection => selection && !selection.locked)
    .map(selection => selection?.folder) as FolderORM[]

  const onAction = () => {
    if (targetFolders.length) {
      targetFolders.forEach((folder) => {
        trackDocument(folder, itemORM);
        guards.isDocumentORM(itemORM) && dispatch(folderActions.addDocument(itemORM, folder))

        if (guards.isFolderItemORM(itemORM)) {
          if (guards.isCustomDeckORM(itemORM.relations.item)) {
            dispatch(folderActions.createCustomDeck(
              folder, itemORM.relations.item.model.groups, itemORM.model.customTitle || ''));
          } else {
            dispatch(folderActions.addFolderItem(itemORM, folder));
          }
        }
      });

      toast?.add(
        <GenericToast
          title="File added to My Folders."
          status="success"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    }

    toggleModal()
  }

  const onCreateFolder = () => {
    pushModal((props) => (<DNAFolderUpsertModal {...props} toast={toast} />))
  }

  return (
    <DNAModal>
      <DNAModal.Header onClose={closeModal}>
        <DNABox spacing="small">
          <DNAText h5>Add file(s) to my Folders</DNAText>
          <DNAText status="subtle">{targetFolders.length} selected</DNAText>
        </DNABox>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNAFolderNestedList
          itemORM={itemORM}
          onFolderSelect={setSelected}
        />
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <DNAButton onPress={onCreateFolder} appearance="outline">Create new folder</DNAButton>
        <DNAButton onPress={onAction}>Done</DNAButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
}

export default DNAFolderArchiveModal
