import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import EditableText from '../EditableText/EditableText';
import { DNABox, DNAButton, Iffy, luxColors } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';

const styles = StyleSheet.create({
  commonPadding: {
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 4,
    paddingTop: 8,
  },
  inputStyle: {
    backgroundColor: colors['color-text-basic'],
    borderColor: colors['color-flat-400'],
  },
});

interface Props {
  closeDrawer: () => void,
  onChangeTitle: (title: string) => void,
  title: string,
  placeHolder?: string,
  error?: string | undefined,
}

const EmailDrawerHeader = (props: Props) => {
  const [isInputShown, setIsInputShown] = useState<boolean>(false);

  return (
    <View style={[styles.commonPadding, styles.header]}>
      <EditableText
        value={props.title}
        required={true}
        onChangeText={props.onChangeTitle}
        size="medium"
        toggleShowInputIcon={''}
        triggerInputShown={setIsInputShown}
        inputStyle={styles.inputStyle}
        placeHolder={props.placeHolder}
        placeholderTextColor={luxColors.contentText.quaternary}
        error={props.error}
      />
      <Iffy is={!isInputShown}>
        <DNABox style={{ flexShrink: 1 }}>
          <DNAButton
            status="subtle"
            appearance="ghost"
            context="minimum"
            iconLeft="close"
            onPress={props.closeDrawer}
          />
        </DNABox>
      </Iffy>
    </View>
  );
};

EmailDrawerHeader.displayName = 'EmailDrawerHeader';

export default EmailDrawerHeader;
