import React from 'react'
import { DocumentORM, Variant } from 'src/types/types'
import {
  DocumentContextAction,
  ActionCallbacks,
  documentContextOptions,
  useDNADocumentActions,
} from '../DNADocument.actions'
import { DNAContextMenu } from '@alucio/lux-ui'
import { useAppSettings } from 'src/state/context/AppSettings'
import useDNADocumentContextMenuActions from './useDNADocumentContextMenuActions'

interface DNADocumentContextMenuProps {
  documentORM: DocumentORM,
  children: React.ReactElement,
  variant?: Variant,
  actions?: DocumentContextAction[],
  onCallback?: (cb: () => void) => void,
  actionCallbacks?: ActionCallbacks
}

const DNADocumentContextMenu: React.FC<DNADocumentContextMenuProps> = (props) => {
  const {
    documentORM,
    actions,
    children,
    variant = Variant.MSL,
    onCallback,
    actionCallbacks,
  } = props

  const DNADocumentActions = useDNADocumentActions(onCallback, actionCallbacks)
  const { deviceMode } = useAppSettings()
  const isDesktop = deviceMode === 'desktop'
  const actionsToUse = useDNADocumentContextMenuActions(documentORM, variant)

  const MenuItems = actions?.length || actionsToUse.length
    ? (actions ?? actionsToUse)
      .map(action => {
        const { onPress, ...rest } = documentContextOptions[action]
        return (
          <DNAContextMenu.Item
            disabled={actions ? !actions.length : !actionsToUse.length}
            key={action}
            status={isDesktop ? 'neutral' : 'dark'}
            onPress={DNADocumentActions[action](documentORM)}
            {...rest}
          />
        )
      })
    : (<DNAContextMenu.Item
        title="(No actions available)"
        onPress={() => { }}
    />)

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        {children}
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        {MenuItems}
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default DNADocumentContextMenu
