import React, { useCallback, useRef } from 'react';
import {
  DNABox,
  luxColors,
  DNAText,
} from '@alucio/lux-ui';
import { DisplayMode } from 'src/components/DNA/hooks/useDisplayMode'
/** Possible future refactor of Input to lux-ui export per Jose suggestion  */
import { Input } from '@ui-kitten/components';
import { useCanPerformSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import debounce from 'lodash/debounce';
import { DocumentORM } from 'src/types/types';
import { Options } from '../../useMyContentPanelSharedResources';
import DNAActionBar, { DNAActionBarElement } from 'src/components/DNA/ActionBar/DNAActionBar';

enum Tabs {
  MyFolders = 'My Folders',
  SharedWithMe = 'Shared With Me',
  Bookmarks = 'Bookmarks',
  Library = 'Library'
}
interface HeaderPresenterModeProps {
  localSearchText: string,
  onSelectTab: (tab: string) => void,
  selectedTab: string,
  displayMode: DisplayMode,
  setLocalSearchText: (text: string) => void,
  setSearchText: (text: string) => void,
  sessionId?: string,
  selectedDocument?: DocumentORM
}
const HeaderPresenterMode:React.FC<HeaderPresenterModeProps> = (props) => {
  const {
    localSearchText,
    onSelectTab,
    selectedTab,
    setLocalSearchText,
    setSearchText,
    sessionId,
  } = props;

  const isSelected = (tabID) => {
    return selectedTab === tabID ? 'slate' : undefined
  }

  const actionBarItems:DNAActionBarElement[] = [
    {
      onPress: () => { handleTabSelect(Tabs.MyFolders) },
      children: <DNAText status="basic">{Tabs.MyFolders}</DNAText>,
      appearance: 'ghost',
      status: isSelected(Tabs.MyFolders),
      context: 'square',
      size: 'medium',
      key: Tabs.MyFolders,
    },
    {
      onPress: () => { handleTabSelect(Tabs.SharedWithMe) },
      children: <DNAText status="basic">{Tabs.SharedWithMe}</DNAText>,
      appearance: 'ghost',
      status: isSelected(Tabs.SharedWithMe),
      context: 'square',
      size: 'medium',
      key: Tabs.SharedWithMe,
    },
    {
      onPress: () => { handleTabSelect(Tabs.Bookmarks) },
      children: <DNAText status="basic">{Tabs.Bookmarks}</DNAText>,
      appearance: 'ghost',
      status: isSelected(Tabs.Bookmarks),
      context: 'square',
      size: 'medium',
      key: Tabs.Bookmarks,
    },
    {
      onPress: () => { handleTabSelect(Tabs.Library) },
      children: <DNAText status="basic">{Tabs.Library}</DNAText>,
      appearance: 'ghost',
      status: isSelected(Tabs.Library),
      context: 'square',
      size: 'medium',
      key: Tabs.Library,
    },
  ]

  const canPerformSearch = useCanPerformSearch();
  // Used to return to that tab if user clears the search input
  const tabSelectedBeforeSearch = useRef<string>(Tabs.MyFolders)
  const inputRef = useRef<Input>(null)

  const handleTabSelect = (tab:string) => {
    setLocalSearchText('');
    onSelectTab(tab);
  }

  const handleTrackAction = debounce((text: string): void => {
    analytics?.track('VIRTUAL_CONTENT_SEARCH', {
      action: 'CONTENT_SEARCH',
      category: 'VIRTUAL',
      searchText: text,
      sessionId,
    });
  }, 1000);

  // IF WE DON'T MEMOIZE, ANOTHER INSTANCE OF HANDLESEARCH WILL BE CREATED UPON EVERY RENDER
  // THEREFORE, THE DEBOUNCE EFFECT WE NEED WON'T HAVE ANY EFFECT`
  const MemoizedHandleTrackAction = useCallback(handleTrackAction, [sessionId]);

  const onSearchTextChange = (text: string) => {
    const canTextBeSearchable = canPerformSearch(text);
    setLocalSearchText(text);

    if (text) {
      debounce(() => {
        setSearchText(text);
      }, 1000)();
    }

    text && MemoizedHandleTrackAction(text);

    if (canTextBeSearchable && selectedTab && selectedTab !== 'Search results') {
      tabSelectedBeforeSearch.current = selectedTab;
      onSelectTab(Options.SEARCH_RESULTS);
    } else if (!canTextBeSearchable && selectedTab === 'Search results') {
      onSelectTab(tabSelectedBeforeSearch.current);
    }
  }

  return (
    <DNABox alignY="center" style={{ paddingHorizontal: 32, justifyContent: 'space-between' }}>
      <DNABox alignY="center" style={{ paddingTop: 24, paddingBottom:16 }} appearance="col" fill spacing="medium">
        <Input
          ref={inputRef}
          onChangeText={onSearchTextChange}
          placeholderTextColor={luxColors.contentPanelBackground.quinary}
          size="small"
          value={localSearchText}
          placeholder={'Search presentations'}
        />
        <DNABox>
          <DNAActionBar
            fill
            spacing="medium"
            items={actionBarItems}
          />
        </DNABox>
      </DNABox>
    </DNABox>
  );
}

HeaderPresenterMode.displayName = 'ContentPanelHeader';

export default HeaderPresenterMode;
