import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, View, Platform, Pressable } from 'react-native';
import SlideRoll from 'src/components/ContentPreviewModal/SlideRoll/SlideRoll';
import { getImageFromCloudfront } from 'src/utils/loadCloudfrontAsset/loadCloudfrontAsset';
import { DNABox, Icon, luxColors } from '@alucio/lux-ui';
import { useContentViewerModalState } from 'src/components/ContentPreviewModal/state/ContentViewerModalStateProvider';
import { useMeetingControls } from '@alucio/video';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';

if (Platform.OS === 'web') {
  // style the scroll only on web
  require('./SideSlide.css')
}

const styles = StyleSheet.create({
  mainWrapper: {
    marginBottom: 20,
  },
  nextSlideImage: {
    height: 105,
    resizeMode: 'contain',
    width: 184,
  },
  nextSlideText: {
    color: luxColors.info.primary,
    fontSize: 13,
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 4,
    textAlign: 'center',
  },
  nextSlideWrapper: {
    alignItems: 'center',
    borderBottomColor: luxColors.virtual.border,
    borderBottomWidth: 1,
    borderLeftColor: luxColors.virtual.border,
    borderLeftWidth: 1,
    flexDirection: 'column',
    flexShrink: 1,
    paddingBottom: 16,
  },
  slideRollWrapper: {
    flex: 1,
    overflow: 'scroll',
  },
});

export const NextSlide = () => {
  const {
    activeSlide,
    activeDocVersion: documentVersion,
    visiblePages,
    customDeck,
  } = useContentViewerModalState();

  const isLastCustomDeckSlide = customDeck && visiblePages.length === activeSlide;
  const isCurrentLastSlide = isLastCustomDeckSlide || (documentVersion &&
    documentVersion.model.pages &&
    activeSlide === visiblePages[visiblePages.length - 1].number);
  const [nextSlide, setNextSlide] = useState<string>();

  useEffect(() => {
    async function getNextSlide() {
      const selectedPageIndex = customDeck
        ? activeSlide - 1
        : visiblePages.findIndex(({ number }) => number === activeSlide);
      const slideToDisplay = visiblePages[isCurrentLastSlide ? visiblePages.length - 1 : selectedPageIndex + 1];

      if ((documentVersion || customDeck) && slideToDisplay) {
        const key = detectArchivedFileKeyPath(slideToDisplay.documentVersion?.model, slideToDisplay) ||
        detectArchivedFileKeyPath(documentVersion?.model, slideToDisplay)
        const img64 = await getImageFromCloudfront(key!);
        setNextSlide(img64);
      } else {
        setNextSlide(undefined);
      }
    }

    getNextSlide();
  }, [documentVersion, activeSlide, visiblePages, customDeck]);

  return (
    <View style={styles.nextSlideWrapper}>
      <Image source={{ uri: nextSlide }} style={styles.nextSlideImage} />
      <Text style={styles.nextSlideText}>{isCurrentLastSlide ? 'Last Slide' : 'Next Slide'} </Text>
    </View>
  );
};

const SideSlide = () => {
  const {
    activeSlide: selectedPageIndex,
    activeDocVersion: documentVersion,
    setActiveSlide: setSelectedPageIndex,
    visiblePages,
    customDeck,
  } = useContentViewerModalState();

  const { toggleSlidesHandler } = useMeetingControls()

  return (
    <>
      <DNABox appearance="col" style={{ marginLeft: 4 }}>
        <DNABox appearance="col">
          <Pressable onPress={toggleSlidesHandler}>
            <DNABox alignX="end" alignY="center" style={{ marginLeft: 20, width: 235, height: 40, padding: 9 }}>
              <Icon style={{ width: 14, height: 14, color: colors['color-flat-800'] }} name="close" />
            </DNABox>
          </Pressable>
        </DNABox>
        <DNABox
          fill
          testID="slide-roll"
          alignX="center"
          style={styles.mainWrapper}
        >
          <SlideRoll
            darkenUnselected={true}
            documentVersion={documentVersion?.model}
            visiblePages={visiblePages}
            activeSlide={selectedPageIndex}
            setActiveSlide={setSelectedPageIndex}
            horizontal={false}
            itemWidth={240}
            itemHeight={136}
            numberColor={luxColors.info.primary}
            selectedItemBorderColor="success"
            numberPosition="left"
            selectedNumberColor="success"
            scrollToSelected={true}
            isCustomDeck={!!customDeck}
          />
        </DNABox>
      </DNABox>
    </>
  );
};

SideSlide.displayName = 'SideSlide';

export default SideSlide;
