import { useState, useRef, useEffect } from 'react'
import { Animated, Dimensions, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { DocumentVersionORM } from 'src/types/orms';
import useDisplayMode, { DisplayModes } from '../DNA/hooks/useDisplayMode';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

export enum Options {
  FOLDERS = 'My Folders',
  BOOKMARKS = 'Bookmarks',
  LIBRARY = 'Library',
  SEARCH_RESULTS = 'Search results',
  SHARED_WITH_ME = 'Shared With Me',
}

const styles = StyleSheet.create({
  content: {
    backgroundColor: colors['color-dark-400'],
    paddingBottom: 60,
  },
  mainWrapper: {
    flexDirection: 'row',
    height: '62%',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  },
});

export interface MyContentPanelProps {
    onClosePanel?: () => void;
    displayContentPanel: boolean;
    sessionId?: string;
    onSelectPresentableORM?: (PresentableModelORM, slide?: number) => void;
}

const useMyContentPanelSharedResources = (
  props:MyContentPanelProps,
) => {
  const [selectedTab, setSelectedTab] = useState<string>(Options.FOLDERS);
  const [localSearchText, setLocalSearchText] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const windowHeight = (Dimensions.get('window').height)
  const [selectedDocumentVersion, setSelectedDocumentVersion] = useState<DocumentVersionORM | undefined>();
  const bottom = useRef(new Animated.Value(windowHeight)).current;

  const displayMode = useDisplayMode(
    DisplayModes.list,
    { manageURL: false },
  )

  const mainWrapperVisibilityStyle:StyleProp<ViewStyle> = props.displayContentPanel
    ? undefined
    : { display:'none' }

  function trackCurrentTab(tab: string, sessionId?: string): void {
    let action = 'LIBRARY';

    if (tab === Options.FOLDERS) {
      action = 'FOLDER';
    } else if (tab === Options.BOOKMARKS) {
      action = 'BOOKMARK';
    } else if (tab === Options.SEARCH_RESULTS) {
      // IF THE CURRENT TAB IS SEARCH_RESULTS, WE ARE ALREADY TRACKING THE
      // VIRTUAL_CONTENT_SEARCH ACTION (NO NEED TO TRACK A TAB CHANGE ONE)
      return;
    }

        analytics?.track(`VIRTUAL_CONTENT_${action}`, {
          action: `CONTENT_${action}`,
          category: 'VIRTUAL',
          sessionId: sessionId,
        });
  }

  // Force the event in order to reset the current selection,
  // In order to detect the current selected tab even if is selected
  // Peer product requirement
  function setSelectedTabHandler(tab: string): void {
    setSelectedTab('');
    setTimeout(() => setSelectedTab(tab), 0);
  }

  function togglePanelView(show = false): void {
    if (show) {
      trackCurrentTab(selectedTab, props.sessionId);
    }

    Animated.timing(
      bottom,
      {
        toValue: show ? (windowHeight / 2.5) : (windowHeight),
        duration: 1000,
        useNativeDriver: true,
      },
    )
      .start((result) => {
        if (result.finished && !show) {
          props.onClosePanel?.();
          setSelectedDocumentVersion(undefined);
        }
      });
  }

  useEffect(() => {
    togglePanelView(props.displayContentPanel)
  }, [props.displayContentPanel])

  useEffect(() => {
    selectedTab && props.displayContentPanel && trackCurrentTab(selectedTab)
  }, [selectedTab]);

  return {
    styles,
    selectedTab,
    setSelectedTab,
    localSearchText,
    setLocalSearchText,
    searchText,
    setSearchText,
    windowHeight,
    selectedDocumentVersion,
    setSelectedDocumentVersion,
    bottom,
    displayMode,
    mainWrapperVisibilityStyle,
    trackCurrentTab,
    setSelectedTabHandler,
    togglePanelView,
  }
}

export default useMyContentPanelSharedResources
