/* eslint-disable max-len */
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgCalendar(props: SvgProps) {
  return (
    <Svg
      viewBox="0 0 32 32"
      fill="none"
      // @ts-ignore
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6a2 2 0 00-2 2v18a2 2 0 002 2h20a2 2 0 002-2V8a2 2 0 00-2-2H6zM2 8a4 4 0 014-4h20a4 4 0 014 4v18a4 4 0 01-4 4H6a4 4 0 01-4-4V8z"
        fill="#fff"
      />
      <Path
        d="M18.5 16a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM23.5 16a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM18.5 21a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM23.5 21a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM8.5 21a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM13.5 21a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM8.5 26a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM13.5 26a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM18.5 26a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        fill="#fff"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2a1 1 0 011 1v2a1 1 0 01-2 0V3a1 1 0 011-1zm16 0a1 1 0 011 1v2a1 1 0 11-2 0V3a1 1 0 011-1zM3 9h26v2H3V9z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgCalendar;
