import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import {
  Collapsible,
  DNABox,
  DNAButton,
  DNACard,
  DNACheckbox,
  DNAText,
  GridList, Icon,
  Iffy,
  luxColors,
  Popover,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { FieldConfig } from '@alucio/aws-beacon-amplify/src/models';

const styles = StyleSheet.create({
  iconStyle: {
    color: luxColors.contentText.quaternary,
    height: 16,
    paddingLeft: 5,
    width: 16,
  },
  itemTextColor: {
    color: colors['color-flat-900'],
  },
  mainWrapper: {
    marginBottom: 12,
    minHeight: 'fit-content',
    paddingHorizontal: 18,
    paddingVertical: 16,
  },
  row: {
    borderBottomColor: colors['color-flat-400'],
    borderBottomWidth: 1,
    paddingVertical: 12,
  },
  tableHeader: {
    backgroundColor: colors['color-flat-200'],
  },
});

const ALLOW_ACCESS_DESCRIPTION = 'User to have access to content associated \n with the selected value(s)';
const DEFAULT_FILTERS_DESCRIPTION = 'User’s library default view to reflect \n the selected filter(s)';

export const DEFAULT_FILTERS = 'defaultFilterValues';
export const LOCKED_FILTERS = 'lockedFilters';
export type CONFIG_FIELDS = 'defaultFilterValues' | 'lockedFilters';

export interface FilterValue {
  defaultFilterValues: string[],
  lockedFilters: string[],
}

interface Props {
  field: FieldConfig,
  disabled?: boolean,
  values: FilterValue,
  onValueSelected: (fieldName: string, fieldValue: string, column: CONFIG_FIELDS) => void,
  onAllValuesSelected: (fieldName: string, column: CONFIG_FIELDS, values: string[], checked: boolean) => void,
}

const UserFilter = (props: Props) => {
  const { field, values, disabled, onValueSelected, onAllValuesSelected } = props;
  const areAllLockedChecked = values.lockedFilters.length === field.values.length;
  const areAllDefaultChecked = values.defaultFilterValues.length === field.values.length ||
  !!(values.lockedFilters.length && values.lockedFilters.length === values.defaultFilterValues.length)
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function toggleFilter(): void {
    setIsOpen(open => !open);
  }

  return (
    <DNACard style={styles.mainWrapper} appearance="outline">
      <DNABox spacing="small" alignY="center">
        <DNAButton
          status="subtle"
          appearance="ghostAlt"
          context="minimum"
          iconLeft={isOpen ? 'chevron-down' : 'chevron-right'}
          onPress={toggleFilter}
        />
        <DNABox appearance="col" spacing="tiny">
          <DNAText c1 bold style={{ color: luxColors.contentText.tertiary }}>{field.fieldName.toUpperCase()}</DNAText>
          <Iffy is={values.lockedFilters?.length}>
            <DNAText c2 bold>Allow access:  <DNAText c2>{values.lockedFilters?.join(', ')}</DNAText></DNAText>
          </Iffy>
          <Iffy is={values.defaultFilterValues?.length}>
            <DNAText c2 bold>Default filters: <DNAText c2>{values.defaultFilterValues?.join(', ')}</DNAText></DNAText>
          </Iffy>
        </DNABox>
      </DNABox>
      <Collapsible collapsed={!isOpen}>
        <DNACard appearance="flat">
          <GridList cols={'repeat(12, minmax(50px, 1fr))'} gap="12px">
            {/* HEADER */}
            <GridList.Row style={styles.row}>
              <GridList.Col span={6} />
              <GridList.Col span={3} style={{ justifyContent: 'center' }}>
                <DNAText bold>Allow access</DNAText>
                <Popover>
                  <Popover.Anchor>
                    <Icon style={styles.iconStyle} name="help-circle-outline" />
                  </Popover.Anchor>
                  <Popover.Content>
                    <DNAText status="basic">{ALLOW_ACCESS_DESCRIPTION}</DNAText>
                  </Popover.Content>
                </Popover>
              </GridList.Col>
              <GridList.Col span={3} style={{ justifyContent: 'center' }}>
                <DNAText bold>Default filters</DNAText>
                <Popover>
                  <Popover.Anchor>
                    <Icon style={styles.iconStyle} name="help-circle-outline" />
                  </Popover.Anchor>
                  <Popover.Content>
                    <DNAText status="basic">{DEFAULT_FILTERS_DESCRIPTION}</DNAText>
                  </Popover.Content>
                </Popover>
              </GridList.Col>
            </GridList.Row>
            <GridList.Row style={[styles.row, styles.tableHeader]}>
              <GridList.Col span={6}>
                <DNAText bold>Select all</DNAText>
              </GridList.Col>
              <GridList.Col span={3} style={{ justifyContent: 'center' }}>
                <DNACheckbox
                  checked={areAllLockedChecked}
                  disabled={disabled}
                  onChange={() => onAllValuesSelected(
                    field.fieldName,
                    LOCKED_FILTERS,
                    field.values,
                    !areAllLockedChecked,
                  )}
                />
              </GridList.Col>
              <GridList.Col span={3} style={{ justifyContent: 'center' }}>
                <DNACheckbox
                  disabled={disabled}
                  checked={areAllDefaultChecked}
                  onChange={() => onAllValuesSelected(
                    field.fieldName,
                    DEFAULT_FILTERS,
                    field.values,
                    !areAllDefaultChecked,
                  )}
                />
              </GridList.Col>
            </GridList.Row>
            {/* CONTENT */}
            {
              field.values.map((value) => (
                <GridList.Row key={value} style={styles.row}>
                  <GridList.Col span={6}>
                    <DNAText style={styles.itemTextColor}>{value}</DNAText>
                  </GridList.Col>
                  <GridList.Col span={3} style={{ justifyContent: 'center' }}>
                    <DNACheckbox
                      disabled={disabled}
                      checked={values.lockedFilters.includes(value)}
                      onChange={() => onValueSelected(field.fieldName, value, LOCKED_FILTERS)}
                    />
                  </GridList.Col>
                  <GridList.Col span={3} style={{ justifyContent: 'center' }}>
                    <DNACheckbox
                      disabled={(!!values.lockedFilters.length && !values.lockedFilters.includes(value)) || disabled}
                      checked={values.defaultFilterValues.includes(value)}
                      onChange={() => onValueSelected(field.fieldName, value, DEFAULT_FILTERS)}
                    />
                  </GridList.Col>
                </GridList.Row>
              ))
            }
          </GridList>
        </DNACard>
      </Collapsible>
    </DNACard>
  );
};

export default React.memo(UserFilter);
