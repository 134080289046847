import { DNABox, DNAButton, Popover } from '@alucio/lux-ui'
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton'
import { DNABoxProps } from '@alucio/lux-ui/src/components/layout/DNABox/DNABox'
import React from 'react'
import { Pressable } from 'react-native'

export interface DNAActionBarElement extends DNAButtonProps, React.Attributes {
  popoverContent?: React.ReactNode
}

interface DNAActionBarProps extends DNABoxProps, React.Attributes {
  items: DNAActionBarElement[]
}

/**
 * This component builds on the DNAButton & DNABox & Popover components to create a reusable menu generation component.
 * Simply pass in an array of button configurations and a menu component containing all generated elements will be returned.
 * @param props
 * @returns ActionBar
 * @example
 * <ActionBar items={[
 *  {
 *    stuff
 *  }
 * ]}>
 */
const DNAActionBar: React.FC<DNAActionBarProps> = (props) => {
  const { items, ...dnaBoxProps } = props

  const actionBarOptionElements = items.map(option => {
    const { children, popoverContent, ...optionProps } = option

    const anchor = (
      <DNAButton
        {...optionProps}
      >{children}</DNAButton>
    )

    const popover = (
      <Popover placement="left">
        <Popover.Anchor>
          {anchor}
        </Popover.Anchor>

        <Popover.Content >
          {popoverContent}
        </Popover.Content>
      </Popover>
    )

    const derivedElement = popoverContent
      ? popover
      : anchor

    return (
      <Pressable {...optionProps} key={option.key}>
        {derivedElement}
      </Pressable>
    )
  })

  return (
    <DNABox {...dnaBoxProps}>
      {actionBarOptionElements}
    </DNABox>
  )
}

export default DNAActionBar
