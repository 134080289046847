/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoSubId
        tenantId
        email
        givenName
        phoneNumber
        familyName
        meetingId
        shareBCC
        shareCC
        createdAt
        createdBy
        updatedAt
        status
        role
        isExcludedFromReporting
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cognitoSubId
        tenantId
        email
        givenName
        phoneNumber
        familyName
        meetingId
        shareBCC
        shareCC
        createdAt
        createdBy
        updatedAt
        status
        role
        isExcludedFromReporting
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
      }
      folderUpdateGracePeriodDays
      medInfoURL
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        licensedUsers
        status
        folderUpdateGracePeriodDays
        medInfoURL
        createdAt
        createdBy
        updatedAt
        statusChangedAt
        statusChangedBy
        ssoDomains
        integrations
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTenants = /* GraphQL */ `
  query SyncTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTenants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        licensedUsers
        status
        folderUpdateGracePeriodDays
        medInfoURL
        createdAt
        createdBy
        updatedAt
        statusChangedAt
        statusChangedBy
        ssoDomains
        integrations
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDocumentVersion = /* GraphQL */ `
  query GetDocumentVersion($id: ID!) {
    getDocumentVersion(id: $id) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      pages {
        pageId
        number
        srcId
        srcHash
        isRequired
        speakerNotes
      }
      pageGroups {
        id
        pageIds
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDocumentVersions = /* GraphQL */ `
  query ListDocumentVersions(
    $filter: ModelDocumentVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        documentId
        versionNumber
        srcFilename
        conversionStatus
        status
        srcHash
        srcSize
        type
        releaseNotes
        changeType
        title
        shortDescription
        longDescription
        owner
        expiresAt
        hasCopyright
        purpose
        canHideSlides
        distributable
        downloadable
        isInternalGenerated
        notificationScope
        selectedThumbnail
        publishedAt
        uploadedAt
        uploadedBy
        convertedArchiveKey
        convertedArchiveSize
        convertedFolderKey
        editPermissions
        converterVersion
        createdAt
        createdBy
        updatedAt
        updatedBy
        integrationType
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDocumentVersions = /* GraphQL */ `
  query SyncDocumentVersions(
    $filter: ModelDocumentVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDocumentVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        documentId
        versionNumber
        srcFilename
        conversionStatus
        status
        srcHash
        srcSize
        type
        releaseNotes
        changeType
        title
        shortDescription
        longDescription
        owner
        expiresAt
        hasCopyright
        purpose
        canHideSlides
        distributable
        downloadable
        isInternalGenerated
        notificationScope
        selectedThumbnail
        publishedAt
        uploadedAt
        uploadedBy
        convertedArchiveKey
        convertedArchiveSize
        convertedFolderKey
        editPermissions
        converterVersion
        createdAt
        createdBy
        updatedAt
        updatedBy
        integrationType
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const documentVersionsByTenantIdStatus = /* GraphQL */ `
  query DocumentVersionsByTenantIdStatus(
    $tenantId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentVersionsByTenantIdStatus(
      tenantId: $tenantId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        documentId
        versionNumber
        srcFilename
        conversionStatus
        status
        srcHash
        srcSize
        type
        releaseNotes
        changeType
        title
        shortDescription
        longDescription
        owner
        expiresAt
        hasCopyright
        purpose
        canHideSlides
        distributable
        downloadable
        isInternalGenerated
        notificationScope
        selectedThumbnail
        publishedAt
        uploadedAt
        uploadedBy
        convertedArchiveKey
        convertedArchiveSize
        convertedFolderKey
        editPermissions
        converterVersion
        createdAt
        createdBy
        updatedAt
        updatedBy
        integrationType
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAttachedFile = /* GraphQL */ `
  query GetAttachedFile($id: ID!) {
    getAttachedFile(id: $id) {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAttachedFiles = /* GraphQL */ `
  query ListAttachedFiles(
    $filter: ModelAttachedFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachedFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        tenantId
        documentId
        srcFileName
        srcHash
        srcSize
        type
        editPermissions
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttachedFiles = /* GraphQL */ `
  query SyncAttachedFiles(
    $filter: ModelAttachedFileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttachedFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        tenantId
        documentId
        srcFileName
        srcHash
        srcSize
        type
        editPermissions
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      tenantId
      accessLevel
      status
      owner
      expiresAt
      integration {
        integrationId
        externalId
        firstSync
        lastSync
      }
      integrationType
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        accessLevel
        status
        owner
        expiresAt
        integrationType
        type
        editPermissions
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDocuments = /* GraphQL */ `
  query SyncDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        accessLevel
        status
        owner
        expiresAt
        integrationType
        type
        editPermissions
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmailTemplate = /* GraphQL */ `
  query GetEmailTemplate($id: ID!) {
    getEmailTemplate(id: $id) {
      id
      tenantId
      labelValues {
        key
        value
      }
      subject
      title
      cc
      bcc
      body
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      status
      editPermissions
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEmailTemplates = /* GraphQL */ `
  query ListEmailTemplates(
    $filter: ModelEmailTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        subject
        title
        cc
        bcc
        body
        status
        editPermissions
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEmailTemplates = /* GraphQL */ `
  query SyncEmailTemplates(
    $filter: ModelEmailTemplateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmailTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        subject
        title
        cc
        bcc
        body
        status
        editPermissions
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomDeck = /* GraphQL */ `
  query GetCustomDeck($id: ID!) {
    getCustomDeck(id: $id) {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        visible
        srcId
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCustomDecks = /* GraphQL */ `
  query ListCustomDecks(
    $filter: ModelCustomDeckFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomDecks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        createdBy
        autoUpdateAcknowledgedAt
        updatedAt
        updatedBy
        tenantId
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomDecks = /* GraphQL */ `
  query SyncCustomDecks(
    $filter: ModelCustomDeckFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomDecks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        createdBy
        autoUpdateAcknowledgedAt
        updatedAt
        updatedBy
        tenantId
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFolder = /* GraphQL */ `
  query GetFolder($id: ID!) {
    getFolder(id: $id) {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFolders = /* GraphQL */ `
  query ListFolders(
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        name
        isPinned
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFolders = /* GraphQL */ `
  query SyncFolders(
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFolders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        name
        isPinned
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const foldersByTenantIdCreatedBy = /* GraphQL */ `
  query FoldersByTenantIdCreatedBy(
    $tenantId: ID!
    $createdBy: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    foldersByTenantIdCreatedBy(
      tenantId: $tenantId
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        name
        isPinned
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getContentShare = /* GraphQL */ `
  query GetContentShare($id: ID!) {
    getContentShare(id: $id) {
      id
      token
      tenantId
      type
      contentId
      expiresAt
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listContentShares = /* GraphQL */ `
  query ListContentShares(
    $filter: ModelContentShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentShares(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        token
        tenantId
        type
        contentId
        expiresAt
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContentShares = /* GraphQL */ `
  query SyncContentShares(
    $filter: ModelContentShareFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContentShares(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        token
        tenantId
        type
        contentId
        expiresAt
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const contentShareByToken = /* GraphQL */ `
  query ContentShareByToken(
    $token: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContentShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentShareByToken(
      token: $token
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        token
        tenantId
        type
        contentId
        expiresAt
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVirtualSession = /* GraphQL */ `
  query GetVirtualSession($id: ID!) {
    getVirtualSession(id: $id) {
      id
      tenantId
      meetingId
      roomName
      status
      recordingStatus
      recordingTaskId
      hostName
      hostPhonePin
      createdAt
      createdBy
      terminatedAt
      meetingLog {
        type
        msg
        timestamp
        action
        param
        user
        isHost
      }
      documentHistory {
        contentId
        contentType
        totalDurationSec
      }
      attendees {
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listVirtualSessions = /* GraphQL */ `
  query ListVirtualSessions(
    $filter: ModelVirtualSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVirtualSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        meetingId
        roomName
        status
        recordingStatus
        recordingTaskId
        hostName
        hostPhonePin
        createdAt
        createdBy
        terminatedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVirtualSessions = /* GraphQL */ `
  query SyncVirtualSessions(
    $filter: ModelVirtualSessionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVirtualSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        meetingId
        roomName
        status
        recordingStatus
        recordingTaskId
        hostName
        hostPhonePin
        createdAt
        createdBy
        terminatedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const sessionsByMeetingIdStatus = /* GraphQL */ `
  query SessionsByMeetingIdStatus(
    $meetingId: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVirtualSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionsByMeetingIdStatus(
      meetingId: $meetingId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        meetingId
        roomName
        status
        recordingStatus
        recordingTaskId
        hostName
        hostPhonePin
        createdAt
        createdBy
        terminatedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const sessionByHostPhonePinStatus = /* GraphQL */ `
  query SessionByHostPhonePinStatus(
    $hostPhonePin: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVirtualSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionByHostPhonePinStatus(
      hostPhonePin: $hostPhonePin
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        meetingId
        roomName
        status
        recordingStatus
        recordingTaskId
        hostName
        hostPhonePin
        createdAt
        createdBy
        terminatedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const sessionsByCreatedByCreatedAt = /* GraphQL */ `
  query SessionsByCreatedByCreatedAt(
    $createdBy: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVirtualSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionsByCreatedByCreatedAt(
      createdBy: $createdBy
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        meetingId
        roomName
        status
        recordingStatus
        recordingTaskId
        hostName
        hostPhonePin
        createdAt
        createdBy
        terminatedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMeeting = /* GraphQL */ `
  query GetMeeting($id: ID!) {
    getMeeting(id: $id) {
      id
      tenantId
      title
      startTime
      endTime
      contentPresented {
        contentId
        folderItemId
        status
        contentType
        openedAt
        closedAt
      }
      type
      notes
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMeetings = /* GraphQL */ `
  query ListMeetings(
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        title
        startTime
        endTime
        type
        notes
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMeetings = /* GraphQL */ `
  query SyncMeetings(
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMeetings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        title
        startTime
        endTime
        type
        notes
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const attendemeetingsByCreatedByStartTimeesByHostIdStatus = /* GraphQL */ `
  query AttendemeetingsByCreatedByStartTimeesByHostIdStatus(
    $createdBy: ID!
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attendemeetingsByCreatedByStartTimeesByHostIdStatus(
      createdBy: $createdBy
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        title
        startTime
        endTime
        type
        notes
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVirtualAttendee = /* GraphQL */ `
  query GetVirtualAttendee($id: ID!) {
    getVirtualAttendee(id: $id) {
      id
      attendeeKey
      tenantId
      hostId
      meetingId
      sessionId
      status
      statusDetail
      name
      phonePin
      clientIP
      joinToken
      createdAt
      terminatedAt
      acceptedAt
      lastSeenAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listVirtualAttendees = /* GraphQL */ `
  query ListVirtualAttendees(
    $filter: ModelVirtualAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVirtualAttendees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attendeeKey
        tenantId
        hostId
        meetingId
        sessionId
        status
        statusDetail
        name
        phonePin
        clientIP
        joinToken
        createdAt
        terminatedAt
        acceptedAt
        lastSeenAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVirtualAttendees = /* GraphQL */ `
  query SyncVirtualAttendees(
    $filter: ModelVirtualAttendeeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVirtualAttendees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        attendeeKey
        tenantId
        hostId
        meetingId
        sessionId
        status
        statusDetail
        name
        phonePin
        clientIP
        joinToken
        createdAt
        terminatedAt
        acceptedAt
        lastSeenAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const attendeesByHostIdStatus = /* GraphQL */ `
  query AttendeesByHostIdStatus(
    $hostId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVirtualAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attendeesByHostIdStatus(
      hostId: $hostId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attendeeKey
        tenantId
        hostId
        meetingId
        sessionId
        status
        statusDetail
        name
        phonePin
        clientIP
        joinToken
        createdAt
        terminatedAt
        acceptedAt
        lastSeenAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const attendeesBySessionId = /* GraphQL */ `
  query AttendeesBySessionId(
    $sessionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVirtualAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attendeesBySessionId(
      sessionId: $sessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attendeeKey
        tenantId
        hostId
        meetingId
        sessionId
        status
        statusDetail
        name
        phonePin
        clientIP
        joinToken
        createdAt
        terminatedAt
        acceptedAt
        lastSeenAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const attendeeByPhonePinStatus = /* GraphQL */ `
  query AttendeeByPhonePinStatus(
    $phonePin: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVirtualAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attendeeByPhonePinStatus(
      phonePin: $phonePin
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attendeeKey
        tenantId
        hostId
        meetingId
        sessionId
        status
        statusDetail
        name
        phonePin
        clientIP
        joinToken
        createdAt
        terminatedAt
        acceptedAt
        lastSeenAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIntegrationLog = /* GraphQL */ `
  query GetIntegrationLog($id: ID!) {
    getIntegrationLog(id: $id) {
      integrationId
      id
      log {
        level
        timestamp
        message
        srcDocId
        srcDocVersion
      }
      status
      tenantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listIntegrationLogs = /* GraphQL */ `
  query ListIntegrationLogs(
    $filter: ModelIntegrationLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        integrationId
        id
        status
        tenantId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIntegrationLogs = /* GraphQL */ `
  query SyncIntegrationLogs(
    $filter: ModelIntegrationLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIntegrationLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        integrationId
        id
        status
        tenantId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIntegrationSettings = /* GraphQL */ `
  query GetIntegrationSettings($id: ID!) {
    getIntegrationSettings(id: $id) {
      id
      tenantId
      syncContentEvery
      name
      mapping {
        key
        fieldType
        dataType
        targetFieldName
        srcFieldname
      }
      clientConfigurationFields {
        key
        value
      }
      integrationType
      enabled
      notificationEmail
      errorSyncEmail
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listIntegrationSettings = /* GraphQL */ `
  query ListIntegrationSettings(
    $filter: ModelIntegrationSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        syncContentEvery
        name
        integrationType
        enabled
        notificationEmail
        errorSyncEmail
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIntegrationSettings = /* GraphQL */ `
  query SyncIntegrationSettings(
    $filter: ModelIntegrationSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIntegrationSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        syncContentEvery
        name
        integrationType
        enabled
        notificationEmail
        errorSyncEmail
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const meetingInfoByMeetingId = /* GraphQL */ `
  query MeetingInfoByMeetingId($meetingId: String!) {
    meetingInfoByMeetingId(meetingId: $meetingId) {
      meetingId
      hostGivenName
      hostFamilyName
      tenantId
      hostId
      hostEmail
    }
  }
`;
export const generateTokenForContentAccess = /* GraphQL */ `
  query GenerateTokenForContentAccess(
    $documentId: String!
    $documentVersionId: String!
    $authorizedPath: String!
    $durationSeconds: Int!
  ) {
    generateTokenForContentAccess(
      documentId: $documentId
      documentVersionId: $documentVersionId
      authorizedPath: $authorizedPath
      durationSeconds: $durationSeconds
    ) {
      token
      expiresAt
    }
  }
`;
export const exchangeContentShareTokenForAccessToken = /* GraphQL */ `
  query ExchangeContentShareTokenForAccessToken($token: ID!) {
    exchangeContentShareTokenForAccessToken(token: $token) {
      token
      key
      expiresAt
      fileName
    }
  }
`;
export const getProviderByUserEmail = /* GraphQL */ `
  query GetProviderByUserEmail($idpIdentifier: String!) {
    getProviderByUserEmail(idpIdentifier: $idpIdentifier)
  }
`;
export const getReportList = /* GraphQL */ `
  query GetReportList($folderName: String!) {
    getReportList(folderName: $folderName) {
      dashboardId
      name
    }
  }
`;
export const getEmbeddedUrl = /* GraphQL */ `
  query GetEmbeddedUrl($dashboardId: String!) {
    getEmbeddedUrl(dashboardId: $dashboardId)
  }
`;
export const getSharedFolders = /* GraphQL */ `
  query GetSharedFolders {
    getSharedFolders {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
