import React from 'react'
import { Clipboard } from 'react-native'
import { EmailTemplate, User, ShareType } from '@alucio/aws-beacon-amplify/src/models'
import { GenericToast, ToastOrientations } from '@alucio/lux-ui';
import { ToastActions } from '@alucio/lux-ui/lib/components/Toast/useToast';
import { getShareableLink, ShareableLinkResult } from './common'
import { ShareFileOption } from 'src/components/DNA/Modal/DNAFileShareModal';
import { isWindows } from 'react-device-detect'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'

const JUMP_LINE = '%0D%0A'
const BODY_ARG_LENGTH = 6 // => '&body='
const MAX_LENGTH_ALLOWED_ON_WINDOWS = 2000

export interface ContentShareProps {
  contentId: string,
  sharedContentId?: string,
  title?: string,
  type: ShareType,
}

async function copyShareableLinkToClipboard(
  shares: ShareFileOption[],
  toast: ToastActions,
): Promise<ShareableLinkResult[]> {
  const toastWidth = 275
  const generatingToastId = toast.add(<GenericToast
    title="Generating Link"
    status="loading"
    width={toastWidth}
  />, ToastOrientations.TOP_RIGHT)

  const shareableLinksList: Promise<ShareableLinkResult>[] = shares.map((share: ShareFileOption) => {
    const { contentId, type, title } = share.contentProps
    return getShareableLink(contentId, type, title)
  })
  const result: ShareableLinkResult[] = await Promise.all(shareableLinksList)

  const linkCopiedToast = (<GenericToast
    title="Link copied, expires in 30 days."
    status="success"
    width={toastWidth}
  />)

  const clipboardText = result.reduce((acc, val) => {
    return `${acc}${val.title}${val.title ? '\n' : ''}${val.link}?expiresAt=${val.expiresAt} \n\n`
  }, '')

  // We try copying to clipboard first
  // [NOTE] - RNWeb uses document.execCommand('copy') under the hood with a similar implementation as clipboard.js
  const isSuccessful = Clipboard.setString(clipboardText) as any as boolean

  if (isSuccessful) {
    toast.remove(generatingToastId);
    toast.add(linkCopiedToast, ToastOrientations.TOP_RIGHT);
  } else {
    toast.remove(generatingToastId);
    let copyToastId = '';
    const handleCopyLink = () => {
      navigator.clipboard.writeText(clipboardText).then(() => {
        toast.remove(copyToastId);
        toast.add(linkCopiedToast, ToastOrientations.TOP_RIGHT);
      }, () => {
        toast.remove(copyToastId);
        toast.add(
          <GenericToast title="Unable to access clipboard." width={toastWidth} status="error" />,
          ToastOrientations.TOP_RIGHT,
        )
      })
    }
    copyToastId = toast.add(
      <GenericToast
        title="Link generated."
        width={toastWidth}
        status="information"
        actionConfig={{ actionTitle: 'Copy link', callback: handleCopyLink }}
      />,
      ToastOrientations.TOP_RIGHT, false, 0);
  }

  return result;
}

async function shareEmail(shareFileOptions: ShareFileOption[], user: User): Promise<void> {
  const shares: ContentShareProps[] =
    shareFileOptions.map((shareFileOption: ShareFileOption): ContentShareProps => shareFileOption.contentProps);
  const shareableLinksList = shares.map(share => {
    const { title, contentId, type } = share
    return getShareableLink(contentId, type, title)
  })
  const result = await Promise.all(shareableLinksList)

  const bodyText = result.reduce((acc, val) => {
    /* eslint-disable max-len */
    return `${acc}${encodeURIComponent(val.title)}${val.title ? JUMP_LINE : ''}${encodeURIComponent(val.link)}%20(link expires ${val.expiresAt})${JUMP_LINE + JUMP_LINE}`
  }, '')

  const cc = user.shareCC && user.shareCC.length > 0 ? encodeURIComponent(user.shareCC.join(',')) : '';
  const bcc = user.shareBCC && user.shareBCC.length > 0 ? encodeURIComponent(user.shareBCC.join(',')) : '';
  var mail = document.createElement('a');

  await buildHref(cc, bcc, JUMP_LINE + bodyText).then((href) => {
    mail.href = href
    mail.target = '_blank'
    mail.click();
  });
}

async function copyEmailTemplateToClipboard(
  shareFileOptions: ShareFileOption[], attachmentsInfo: ContentShareProps[], toast: ToastActions, emailTemplate: EmailTemplate): Promise<undefined | (() => void)> {
  const shares: ContentShareProps[] = shareFileOptions.map((shareFileOption: ShareFileOption): ContentShareProps => shareFileOption.contentProps);
  const toastWidth = 275

  const processor = (links: ShareableLinkResult[]) => links.reduce((acc, val, idx) => {
    return `${acc}${val.title}${val.title ? '\n' : ''}${val.link}?expiresAt=${val.expiresAt}` +
      `${idx !== links.length - 1 ? '\n\n' : ''}`
  }, '')
  const clipboardText = await getEmailBodyWithTemplate(shares, attachmentsInfo, emailTemplate, processor)

  // We try copying to clipboard first
  const isCopySuccessful = Clipboard.setString(clipboardText) as any as boolean

  if (isCopySuccessful) {
    return undefined
  }
  else {
    const handleCopyLink = () => {
      navigator.clipboard.writeText(clipboardText).then(() => {
        return true;
      }, () => {
        toast.add(
          <GenericToast title="Unable to access clipboard." width={toastWidth} status="error" />,
          ToastOrientations.TOP_RIGHT,
        )
        return false;
      })
    }
    return handleCopyLink
  }
}

async function shareEmailTemplate(
  shareFileOptions: ShareFileOption[], attachmentsInfo: ContentShareProps[], emailTemplate: EmailTemplate): Promise<void> {
  const splitSeparator = isWindows ? ';' : ',';
  const shares: ContentShareProps[] = shareFileOptions.map((shareFileOption: ShareFileOption): ContentShareProps => shareFileOption.contentProps);
  const processor = (links: ShareableLinkResult[]) => links.reduce((acc, val, idx) => {
    return `${acc}${encodeURIComponent(val.title)}${val.title ? JUMP_LINE : ''}` +
      `${encodeURIComponent(val.link)}%20(link expires ${val.expiresAt})` +
      `${idx !== links.length - 1 ? JUMP_LINE + JUMP_LINE : ''}`
  }, '')

  let bodyText = await getEmailBodyWithTemplate(shares, attachmentsInfo, emailTemplate, processor)
  bodyText = bodyText.replace(/\n/g, JUMP_LINE);
  const cc = emailTemplate.cc && emailTemplate.cc.length > 0 ? encodeURIComponent(emailTemplate.cc.join(splitSeparator)) : '';
  const bcc = emailTemplate.bcc && emailTemplate.bcc.length > 0 ? encodeURIComponent(emailTemplate.bcc.join(splitSeparator)) : '';

  // [TODO] - We need to remove this when we're done
  //        - And we should make it invisible so it does not show up on screen
  var mail = document.createElement('a');

  await buildHref(cc, bcc, bodyText, emailTemplate.subject).then((href) => {
    mail.href = href
    mail.target = '_blank'
    mail.setAttribute('data-testid', 'emulated-email-client')
    mail.click();
  })
}

async function getEmailBodyWithTemplate(
  shares: ContentShareProps[],
  attachments: ContentShareProps[],
  emailTemplate: EmailTemplate,
  processor: (links: ShareableLinkResult[]) => string): Promise<string> {
  const shareableLinksList = shares.map(share => {
    const { title, contentId, type } = share
    return getShareableLink(contentId, type, title)
  })
  const attachmentsLinksList = attachments.map(share => {
    const { title, contentId, type } = share
    return getShareableLink(contentId, type, title)
  })
  const filesResults = await Promise.all(shareableLinksList)
  const attachmentsResults = await Promise.all(attachmentsLinksList)
  const fileLinks = processor(filesResults)
  const attachmentLinks = processor(attachmentsResults)

  let bodyText = emailTemplate.body!.replace('[Files]', checkIfShouldAddNewlines(emailTemplate.body!, '[Files]') ? `\n${fileLinks}\n` : fileLinks);
  bodyText = bodyText.replace('[Attachments]', checkIfShouldAddNewlines(emailTemplate.body!, '[Attachments]') ? `\n${attachmentLinks}\n` : attachmentLinks);
  return bodyText;
}

const checkIfShouldAddNewlines = (body: string, placeholder: string) => {
  const bodyLines = body.split('\n');
  const lineWithPlaceholder = bodyLines.find(l => l.indexOf(placeholder) > -1);
  let shouldAddNewLines = false;
  if (lineWithPlaceholder && !lineWithPlaceholder.trim().startsWith(placeholder)) {
    shouldAddNewLines = true;
  }

  return shouldAddNewLines;
}

async function emailTemplatePreview(subject?: string, body?: string, cc?: string[], bcc?: string[], attachments?: ContentShareProps[]): Promise<void> {
  const splitSeparator = isWindows ? ';' : ',';
  const formattedBCC = (bcc?.length && encodeURIComponent(bcc.join(splitSeparator))) || '';
  const formattedCC = (cc?.length && encodeURIComponent(cc.join(splitSeparator))) || '';
  const mail = document.createElement('a');

  if (body && attachments) {
    const expiresAt = format(addDays(new Date(), 30), 'yyyy-MM-dd')
    const links = attachments.reduce((acc, val) => {
      /* eslint-disable max-len */
      return `${acc}${encodeURIComponent(val.title ?? '')}${val.title ? JUMP_LINE : ''}[Document link]%20(link expires ${expiresAt})${JUMP_LINE + JUMP_LINE}`
    }, '')

    body = body.replace('[Attachments]', checkIfShouldAddNewlines(body, '[Attachments]') ? `\n${links}\n` : links);
  }
  body = body ? body.replace(/\n/g, JUMP_LINE) : '';

  mail.href = await buildHref(formattedCC, formattedBCC, body, subject);
  mail.target = '_blank';
  mail.click();
}

const buildHref = async (cc: string, bcc: string, body: string, subject?: string) => {
  let hasMultipleArg = false
  let mailto = 'mailto:?'

  const buildArg = (field: string, value?: string) => {
    let arg = ''

    if (value) {
      if (hasMultipleArg) {
        arg += '&'
      } else {
        hasMultipleArg = !hasMultipleArg
      }
      arg += `${field}=${value}`
    }

    return arg
  }

  mailto += buildArg('cc', cc)
  mailto += buildArg('bcc', bcc)
  mailto += buildArg('subject', subject)

  if (isWindows && excedesWindowsLenghtLimit(mailto, body)) {
    await navigator.clipboard.writeText(decodeURIComponent(body))
    body = 'Paste the message from your clipboard here. (Ctrl-V)'
  }

  mailto += buildArg('body', body)
  return mailto;
}

const excedesWindowsLenghtLimit = (mailto: string, body: string) => mailto.length + body.length + BODY_ARG_LENGTH > MAX_LENGTH_ALLOWED_ON_WINDOWS;

export {
  copyShareableLinkToClipboard,
  copyEmailTemplateToClipboard,
  shareEmail,
  shareEmailTemplate,
  emailTemplatePreview,
}
