import React, { useEffect, useState } from 'react';
import { TouchableOpacity, ViewStyle } from 'react-native';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/state/redux/store'

import {
  DNABox,
  DNAButton,
  DNACard,
  DNAChip,
  DNAContextMenu,
  DNADivider,
  DNAIcon,
  DNAText,
  GridList,
  Iffy,
  luxColors,
  Popover,
  useToast,
  util,
} from '@alucio/lux-ui'

import { downloadRecordingFromCloudfront } from 'src/utils/loadCloudfrontAsset/loadCloudfrontAsset';

import virtualSessionSlice, { virtualSessionSliceActions } from 'src/state/redux/slice/virtualSession'
import { useVirtualSessionList } from 'src/state/redux/selector/virtualSession'
import { DocumentVersionORM, VirtualSessionORM } from 'src/types/types';
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import { useCurrentUser } from 'src/state/redux/selector/user';
import {
  DocumentHistoryEntry,
  MeetingContentType,
  ShareType,
  VirtualAttendee,
} from '@alucio/aws-beacon-amplify/src/models';
import { useAllDocumentVersionMap } from 'src/state/redux/selector/document'
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip';
import { copyShareableLinkToClipboard } from 'src/utils/shareLink/shareLink';
import { useOnlineStatus } from 'src/state/context/AppSettings'
import format from 'date-fns/format';
import { useDNADocumentVersionActions } from '../../components/DNA/Document/DNADocumentVersion.actions';
import { useCustomDeckORMMap } from '../../state/redux/selector/folder';

const MAX_TEXT_LINES = 5
const MAX_CONTENT_TEXT_LINES = 2

const S = {
  fileLink: {
    color: luxColors.primary.primary,
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 'bold' as 'bold',
    marginRight: 8,
  },
  HeaderCol: {
    paddingVertical: 12,
    alignItems: 'flex-start',
  },
  smallMarginRight: {
    marginRight: 8,
  },
  NameCol: {
    maxWidth: 320,
  },
  PaddedContentRow: {
    paddingLeft: 30,
  },
  Row: {
    flexBasis: 'auto',
  },
  recordingDisabledIconButton: {
    color: '#DBDBDB',
    width: 21,
    height: 21,
    marginTop: 1,
  } as ViewStyle,
  recordingEnabledIconButton: {
    color: '#44A2DA',
  } as ViewStyle,
}

export interface MeetingsHistoryListRowProps {
  sessionORM: VirtualSessionORM,
}

export interface RenderMeetingAttendeesProps {
  attendees: VirtualAttendee[]
}

export const RenderMeetingAttendees: React.FC<RenderMeetingAttendeesProps> = (props) => {
  const { attendees } = props;
  const [showAll, setShowAll] = useState(attendees.length < 6)

  const toggleViewAll = () => {
    setShowAll(!showAll)
  }

  const JSXAttendees = attendees.map((attendee, idx) => {
    const acceptedAtTime = attendee.acceptedAt ? format(new Date(attendee.acceptedAt), 'h:mm a') : '';
    const terminatedAtTime = attendee.terminatedAt ? format(new Date(attendee.terminatedAt), 'h:mm a') : '';
    // if join/leave time exists we show it along with the attendee name otherwise just show the attendee name
    const attendeeInfo = acceptedAtTime && terminatedAtTime
      ? `${attendee.name} | ${acceptedAtTime} - ${terminatedAtTime}` : attendee.name
    return (
      <DNAText numberOfLines={MAX_TEXT_LINES} key={`${attendee.id}-${idx}`}>
        {attendeeInfo}
      </DNAText>
    )
  })

  function attendeesToRender() {
    if (showAll) {
      return JSXAttendees
    } else {
      return JSXAttendees.slice(0, 5)
    }
  }

  return (
    <>
      <Iffy is={!attendees.length}>
        <DNABox>
          <DNAText>No Attendees</DNAText>
        </DNABox>
      </Iffy>
      <>
        <DNABox appearance="col" spacing="small" fill>
          {attendeesToRender()}
          {attendees.length > 5 &&
            <DNAButton
              status="primary"
              appearance="ghost"
              context="minimum"
              onPress={() => toggleViewAll()}
            >
              {showAll ? 'Show less' : 'Show all'}
            </DNAButton>}
        </DNABox>
      </>
    </>
  )
}

export interface RenderMeetingContentProps {
  presentedContent: DocumentHistoryEntry[]
}

export const RenderMeetingContent: React.FC<RenderMeetingContentProps> = (props) => {
  const presentedContent = props.presentedContent ? props.presentedContent : [];
  const [showAll, setShowAll] = useState(presentedContent.length < 6)
  const docVersionMap = useAllDocumentVersionMap();
  const customDecksMap = useCustomDeckORMMap();
  const { shareEMail } = useDNADocumentVersionActions();
  const dispatch = useDispatch();

  const toggleViewAll = () => {
    setShowAll(!showAll)
  }

  const formatDocTitle = (docVersion: DocumentVersionORM) => {
    // eslint-disable-next-line max-len
    return `v ${docVersion.model.versionNumber} ${docVersion.relations.document.relations.version.latestDocumentVersionPublished?.model.title}`;
  }

  const handlePress = (docVersion) => {
    dispatch(
      contentPreviewModalActions.setModalVisibility({
        documentVersionId: docVersion.model.id,
        isOpen: true,
      },
      ))
  }

  const JSXContent = presentedContent.reduce((acc, entry, idx) => {
    const docVersionORM = entry.contentType === MeetingContentType.DOCUMENT_VERSION
      ? docVersionMap[entry.contentId]
      : undefined;
    const docORM = docVersionORM?.relations.document;
    const latestPublishedDocumentVersion = docORM?.relations.version.latestDocumentVersionPublished;
    const customDeck = entry.contentType === MeetingContentType.CUSTOM_DECK
      ? customDecksMap[entry.contentId]
      : undefined;
    const fileTitle = docVersionORM ? formatDocTitle(docVersionORM) : customDeck?.meta.title;

    if (!docVersionORM && !customDeck) {
      return acc;
    }

    acc.push((
      <DNABox
        fill
        style={util.mergeStyles(
          { paddingBottom: 3 },
          [S.PaddedContentRow, !latestPublishedDocumentVersion?.meta.permissions.MSLShare],
        )}
        key={`${entry.contentId}-${idx}`}
        appearance="row"
        alignY="center"
      >
        {/* SHARE LINK */}
        <Iffy is={docVersionORM?.meta.permissions.MSLShare}>
          <Popover placement="top">
            <Popover.Anchor>
              <DNAButton
                status="subtle"
                appearance="ghost"
                context="minimum"
                style={S.smallMarginRight}
                iconLeft="share"
                onPress={shareEMail(latestPublishedDocumentVersion!)}
              />
            </Popover.Anchor>
            <Popover.Content offset={2}>
              <DNAText status="basic">
                Copy shareable link
              </DNAText>
            </Popover.Content>
          </Popover>
        </Iffy>
        {/* DOCUMENT CHIP */}
        <DNABox style={S.smallMarginRight}>
          <Iffy is={docVersionORM}>
            <DNADocumentChip
              item={docVersionORM?.relations.document!}
              variant="purpose"
            />
          </Iffy>
          <Iffy is={customDeck}>
            <DNAChip appearance="tag" status="subtle">MODIFIED</DNAChip>
          </Iffy>
        </DNABox>
        {/* CONTENT LABEL + DURATION */}
        <DNABox spacing="between" fill childFill={0}>
          {/* We have to use touchable opacity here since textwrapping in buttons is borked */}
          <TouchableOpacity
            disabled={!docVersionORM}
            onPress={() => handlePress(docVersionORM)}
            style={{ flex: 1, marginRight: 8 }}
            activeOpacity={1}
          >
            <DNAText style={{ width: '100%' }} numberOfLines={MAX_CONTENT_TEXT_LINES}>
              {fileTitle}
            </DNAText>
          </TouchableOpacity>
          <DNABox fill alignY="center">
            <DNAText>
              ({entry.totalDurationSec < 60 ? '<1' : Math.ceil(entry.totalDurationSec / 60)} min)
            </DNAText>
          </DNABox>
        </DNABox>
      </DNABox>
    ));

    return acc;
  }, [] as React.ReactElement[]);

  function contentRowsToRender() {
    if (showAll) {
      return JSXContent
    } else {
      return JSXContent.slice(0, 5)
    }
  }

  return (
    <>
      <Iffy is={!presentedContent?.length}>
        <DNABox>
          <DNAText style={S.PaddedContentRow}>No content presented</DNAText>
        </DNABox>
      </Iffy>
      <Iffy is={presentedContent?.length}>
        <DNABox appearance="col" shrink spacing="small">
          {contentRowsToRender()}
          {presentedContent.length > 5 &&
            <DNAButton
              status="primary"
              appearance="ghost"
              context="minimum"
              onPress={() => toggleViewAll()}
            >
              {showAll ? 'Show less' : 'Show all'}
            </DNAButton>}
        </DNABox>
      </Iffy>
    </>
  )
}

export const RenderNoNetwork: React.FC = () => {
  return (
    <DNABox alignX="center">
      <DNABox
        fill
        style={{
          maxWidth: 1440,
          paddingHorizontal: 48,
          paddingVertical: 20,
        }}
      >
        <DNABox fill spacing="medium" appearance="col">
          {/* Header */}
          <DNABox alignY="center" spacing="between">
            <DNAText h5 status="secondary">Meeting History</DNAText>
          </DNABox>
          <DNADivider />
          <DNAEmpty
            useContainer={true}
            emptyVariant={EmptyVariant.NoNetworkMeetingsHistory}
          />
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export const MeetingsHistoryListRow: React.FC<MeetingsHistoryListRowProps> = (props) => {
  const user = useCurrentUser()
  const toast = useToast()
  function handleDownloadRecording(sessionORM: VirtualSessionORM) {
    downloadRecordingFromCloudfront(
      user.userProfile?.tenantId!,
      sessionORM.model.id,
      `Beacon_Recording_${format(new Date(sessionORM.model.createdAt), 'yyyyMMdd_HHmm')}.mp4`,
    )

    analytics?.track('VIRTUAL_RECORD_DOWNLOAD', {
      action: 'RECORD_DOWNLOAD',
      category: 'VIRTUAL',
      sessionId: sessionORM.model.id,
    });
  }
  function handleGetRecordingLink(sessionORM: VirtualSessionORM) {
    const fileToShare = {
      isMainDoc: true,
      isDefault: true,
      beingShared: true,
      isDistributable: true,
      contentProps: {
        contentId: sessionORM.model.id,
        type: ShareType.RECORDING,
      },
    }
    copyShareableLinkToClipboard([fileToShare], toast);

    analytics?.track('VIRTUAL_RECORD_SHARE', {
      action: 'RECORD_SHARE',
      channel: 'LINK',
      category: 'VIRTUAL',
      sessionId: sessionORM.model.id,
    });
  }
  const { sessionORM } = props

  return (
    // @ts-ignore
    <GridList.Row as={DNACard} appearance="flat" style={S.Row}>
      <GridList.Col style={S.HeaderCol} span={1}>
        {sessionORM.meta.sessionDate}
      </GridList.Col>
      <GridList.Col style={S.HeaderCol} span={1}>
        <DNABox spacing="tiny" appearance="col" >
          <DNAText numberOfLines={1}>
            {sessionORM.meta.sessionTime}
          </DNAText>
          <DNAText numberOfLines={1} status="secondary">
            {sessionORM.meta.sessionDuration}
          </DNAText>
        </DNABox>
      </GridList.Col>
      <GridList.Col style={S.HeaderCol} span={1}>
        <RenderMeetingAttendees attendees={sessionORM.model.attendees} />
      </GridList.Col>
      <GridList.Col style={S.HeaderCol} span={1}>
        <RenderMeetingContent presentedContent={sessionORM.model.documentHistory!} />
      </GridList.Col>
      <GridList.Col style={S.HeaderCol} span={1}>
        <Iffy is={sessionORM.model.recordingStatus === 'PROCESSING'}>
          <Popover placement="top">
            <Popover.Anchor>
              <DNAIcon style={S.recordingDisabledIconButton} name="video-box" />
            </Popover.Anchor>
            <Popover.Content offset={2}>
              <DNAText status="basic">
                Recording is processing. Please check back later.
              </DNAText>
            </Popover.Content>
          </Popover>
        </Iffy>
        <Iffy is={sessionORM.model.recordingStatus === 'RECORDED'}>
          <DNAContextMenu>
            <DNAContextMenu.Anchor>
              <DNAButton
                status="neutral"
                appearance="ghost"
                context="minimum"
                iconLeft="video-box"
                iconRight="chevron-down"
                iconStyle={S.recordingEnabledIconButton}
              />
            </DNAContextMenu.Anchor>
            <DNAContextMenu.Items>
              <DNAContextMenu.Item
                onPress={() => handleDownloadRecording(sessionORM)}
                icon="download"
                title="Download recording"
              />
              <DNAContextMenu.Item
                onPress={() => handleGetRecordingLink(sessionORM)}
                icon="link-variant"
                title="Copy shareable link"
              />
            </DNAContextMenu.Items>
          </DNAContextMenu>
        </Iffy>
      </GridList.Col>
    </GridList.Row>
  )
}

export const DNAMeetingsHistory: React.FC = () => {
  const dispatch = useDispatch()
  const user = useCurrentUser()
  const allSessions = useVirtualSessionList()
  const sliceState = useSelector((state: RootState) => state.virtualSession.status)
  const onlineStatus = useOnlineStatus()
  const isNetworkConnected = onlineStatus

  useEffect(() => {
    // call the api
    dispatch(virtualSessionSliceActions.getRecentMeetingsForUser({ userId: user?.userProfile?.id! }))

    // this is cleaning up the redux when unmounting
    return () => {
      dispatch(virtualSessionSlice.actions.clearState())
    }
  }, [])

  return (
    <>
      <Iffy is={sliceState !== 'OK'}>
        <Iffy is={!isNetworkConnected}>
          <RenderNoNetwork />
        </Iffy>
        <DNABox alignX="center" />
      </Iffy>
      <Iffy is={sliceState === 'OK'}>
        <DNABox alignX="center">
          <DNABox
            fill
            style={{
              maxWidth: 1440,
              paddingHorizontal: 48,
              paddingVertical: 20,
            }}
          >
            <DNABox fill spacing="medium" appearance="col">
              {/* Header */}
              <DNABox alignY="center" spacing="between">
                <DNAText h5 status="secondary">Meeting History</DNAText>
              </DNABox>
              <DNADivider />

              <Iffy is={!isNetworkConnected}>
                <DNAEmpty
                  useContainer={true}
                  emptyVariant={EmptyVariant.NoNetworkMeetingsHistory}
                />
              </Iffy>

              <Iffy is={isNetworkConnected}>
                <Iffy is={!allSessions.length}>
                  <DNAEmpty
                    useContainer={false}
                    emptyVariant={EmptyVariant.MeetingsHistoryEmpty}
                  />
                </Iffy>

                <Iffy is={allSessions.length}>
                  <DNACard style={{ paddingHorizontal: 16, paddingVertical: 8, overflow: 'hidden' }}>
                    <GridList
                      cols={'130px 200px minmax(100px,300px) minmax(100px,700px) 50px'}
                      gap="12px"
                      style={{ width: '100%' }}
                    >
                      <GridList.Header>
                        <GridList.Col style={S.HeaderCol} span={1}>
                          <DNAText b2 bold status="secondary">Date</DNAText>
                        </GridList.Col>
                        <GridList.Col style={S.HeaderCol} span={1}>
                          <DNAText b2 bold status="secondary">Time</DNAText>
                        </GridList.Col>
                        <GridList.Col style={S.HeaderCol} span={1}>
                          <DNAText b2 bold status="secondary">Attendees</DNAText>
                        </GridList.Col>
                        <GridList.Col style={S.HeaderCol} span={1}>
                          <DNAText style={S.PaddedContentRow} b2 bold status="secondary">Content Presented</DNAText>
                        </GridList.Col>
                      </GridList.Header>
                      {
                        allSessions.map((session, idx) => (
                          <React.Fragment key={`${session.model.id}-${idx}`}>
                            <DNADivider />
                            <MeetingsHistoryListRow sessionORM={session} />
                          </React.Fragment>
                        ))
                      }
                    </GridList>
                  </DNACard>
                </Iffy>
              </Iffy>
            </DNABox>
          </DNABox>
        </DNABox>
      </Iffy>
    </>
  )
}

export default DNAMeetingsHistory;
