import React from 'react';
import { useParams } from 'react-router';
import useScreenNav from 'src/components/DNA/hooks/useScreenNav';
import ContentProvider from 'src/state/context/ContentProvider/ContentProvider';
import MeetingsContent from './MeetingsContent';
import { useMeeting } from 'src/state/redux/selector/meeting';
import { MEETING_STATUS } from '@alucio/aws-beacon-amplify/src/API';
import MeetingsStateProvider from 'src/state/context/Meetings/MeetingsStateProvider';

interface Props {
  meetingId: string
}

const Meetings = (props: Props) => {
  return (
    <ContentProvider meetingId={props.meetingId}>
      <MeetingsStateProvider meetingId={props.meetingId}>
        <MeetingsContent />
      </MeetingsStateProvider>
    </ContentProvider>
  );
};

const MeetingsWrapper = () => {
  const { meetingId } = useParams<{ meetingId: string }>();
  const meeting = useMeeting(meetingId);
  const { goTo } = useScreenNav();

  if (!meeting || meeting.model.status !== MEETING_STATUS.IN_PROGRESS) {
    goTo.HOME();
    // [TODO]: MEETING NOT FOUND MSG ?
  }

  return <Meetings meetingId={meetingId} />;
};

MeetingsWrapper.displayName = 'Meetings';

export default MeetingsWrapper;
