import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNACard, DNADivider, DNAText, GridList } from '@alucio/lux-ui';
import { User } from '@alucio/aws-beacon-amplify/src/models';
import DNAUserDefaultRow from '../FlatList/Rows/default/DNAUserDefaultRow';
import DNAEmpty, { EmptyVariant } from '../Empty/DNAEmpty';
import sortBy from 'lodash/sortBy'
import { FieldHeader, ListFieldConfig } from '../GridList/common';

const styles = StyleSheet.create({
  header: {
    paddingVertical: 12,
  },
  wrapper: {
    minWidth: 860,
  },
});

interface Props {
  users: User[];
}

enum SortOrders {
  'ascending',
  'descending'
}
type SortOrder = keyof typeof SortOrders

type userFields = keyof User
interface SelectedSort {
  fieldName: userFields
  sortOrder: SortOrder
}

interface UserListFieldConfig extends ListFieldConfig {
  unsortable?: boolean
}

const fields: UserListFieldConfig[] = [
  {
    fieldName: 'givenName',
    fieldLabel: 'Name',
    fieldType: 'LABEL',
    width: 2,
  },
  {
    fieldName: 'status',
    fieldLabel: 'Status',
    fieldType: 'LABEL',
    width: 1,
  },
  {
    fieldName: 'email',
    fieldLabel: 'Email/Phone',
    fieldType: 'LABEL',
    width: 3,
  },
  {
    fieldName: 'role',
    fieldLabel: 'Role',
    fieldType: 'LABEL',
    width: 1,
  },
  {
    fieldName: 'filters',
    fieldLabel: 'Filters',
    fieldType: 'LABEL',
    width: 3,
    unsortable: true,
  },
]

const DNAUsersList = (props : Props) => {
  const { users } = props;
  const [selectedSort, setSelectedSort] = useState<SelectedSort>(
    {
      fieldName:'givenName',
      sortOrder:'ascending',
    },
  )

  const sortedUsers = selectedSort.sortOrder === 'ascending'
    ? sortBy(users, selectedSort.fieldName)
    : sortBy(users, selectedSort.fieldName).reverse()

  if (!props.users.length) {
    return (
      <DNACard appearance="float" style={{ overflow: 'hidden', borderRadius: 8 }}>
        <DNAEmpty emptyVariant={EmptyVariant.UsersListEmpty} />
      </DNACard>
    );
  }

  const toggleSort = (fieldName) => {
    const fieldIsActive = selectedSort.fieldName = fieldName
    if (fieldIsActive) {
      setSelectedSort(p => ({ ...p, sortOrder:  selectedSort.sortOrder === 'ascending' ? 'descending' : 'ascending' }))
    } else {
      setSelectedSort({
        fieldName: fieldName,
        sortOrder: 'ascending',
      })
    }
  }

  const headerElements = fields.map(field => {
    const sortIcon = selectedSort.fieldName === field.fieldName
      ? selectedSort.sortOrder === 'ascending'
        ? 'menu-down'
        : 'menu-up'
      : undefined;
    return (
      <GridList.Col span={field.width}>
        {field.unsortable
          ? <DNAText status="flat" label>{field.fieldLabel}</DNAText>
          : <FieldHeader
              key={field.fieldLabel}
              field={field}
              style={styles.header}
              toggleSort={toggleSort}
              sortIcon={sortIcon}
          />
      }

      </GridList.Col>)
  })

  return (
    <DNABox>
      <DNACard style={styles.wrapper}>
        <GridList cols={'50px repeat(13, minmax(50px, 1fr))'} gap="12px">
          <GridList.Header>
            <GridList.Col style={[styles.header, { marginRight: 16 }]} span={1}>
              {/* ACTION ELLIPSIS BUTTON */}
            </GridList.Col>
            {headerElements}
          </GridList.Header>
          {/* CONTENT */}
          {
            sortedUsers.map((user) => (
              <React.Fragment key={user.id}>
                <DNADivider />
                <DNAUserDefaultRow user={user} />
              </React.Fragment>
            ))
          }
        </GridList>
      </DNACard>
    </DNABox>
  );
};

DNAUsersList.displayName = 'UsersList';

export default DNAUsersList;
