import React from 'react';
import { styles } from '../ContentPreviewModal.styles';
import {
  DNABox,
  DNAText,
  Iffy,
} from '@alucio/lux-ui'
import { Pressable, ScrollView } from 'react-native';
import { useContentViewerModalState, SidebarOptions } from '../state/ContentViewerModalStateProvider';
import { DocumentVersionORM } from 'src/types/types';
import LabelsSideBar from './LabelsSideBar/LabelsSideBar';
import NotesSidebar from './NotesSidebar/NotesSidebar';
import ReleaseNotesSidebar from './ReleaseNotesSidebar/ReleaseNotesSidebar';
import Controls from './Controls/Controls';
import mergeStyles from '@alucio/lux-ui/src/components/util/mergeStyles';
import AssociatedFilesSideBar from './AssociatedFilesSideBar/AssociatedFilesSideBar';
import { DocumentAccessLevel } from '@alucio/aws-beacon-amplify/src/models';
interface SideBarProps {
  documentVersionORM: DocumentVersionORM
}
interface TabConfig {
  key: string,
  id: SidebarOptions,
  label: string,
  icon: string,
  tid: string,
}

export const tabConfigs: TabConfig[] = [
  {
    key: 'notes-button',
    id: SidebarOptions.notesSidebar,
    label: 'Slide Notes',
    icon: 'text-box',
    tid: 'notes-tab',
  },
  {
    key: 'release-notes-button',
    id: SidebarOptions.releaseNotesSidebar,
    label: 'Release Notes',
    icon: 'bullhorn',
    tid: 'release-tab',
  },
  {
    key: 'metadata-button',
    id: SidebarOptions.metadataSidebar,
    label: 'Labels',
    icon: 'information',
    tid: 'labels-tab',
  },
  {
    key: 'associatedFiles-button',
    id: SidebarOptions.associatedFilesSidebar,
    label: 'Associated Files',
    icon: 'file-multiple',
    tid: 'associated-tab',
  },
]

const SideBar = (props: SideBarProps) => {
  const {
    documentVersionORM,
  } = props;

  const {
    visibleSidebar,
    setVisibleSidebar,
    getSidebarOptions,
  } = useContentViewerModalState();

  const isUserDocument = documentVersionORM.relations.document.model.accessLevel === DocumentAccessLevel.USER;

  const getTextStyle = (tabName) => {
    return (
      [
        styles.buttonText,
        tabName === visibleSidebar
          ? styles.buttonTextActive
          : null,
      ]
    )
  }

  const tabs = getSidebarOptions().map(optionId => {
    const config = tabConfigs.find((tab) => tab.id === optionId)
    if (isUserDocument && optionId !== SidebarOptions.notesSidebar) {
      return null;
    }
    if (!config) { throw Error(`Unknown tab ${optionId}`) }
    return (
      <Pressable
        testID={config.tid}
        key={config.key}
        onPress={() => setVisibleSidebar(config.id)}
      >
        <DNABox
          fill
          alignY="center"
          alignX="center"
          style={
            mergeStyles(
              undefined,
              styles.sidebarButton,
              [styles.sidebarButtonActive, config.id === visibleSidebar],
              [styles.sidebarButtonInActive, config.id !== visibleSidebar],
            )
          }
        >

          <DNAText
            style={getTextStyle(config.id)}
          >{config.label}
          </DNAText>
        </DNABox>
      </Pressable >
    )
  })

  const getContent = () => {
    let tabContent
    switch (visibleSidebar) {
      case SidebarOptions.metadataSidebar:
        tabContent = <LabelsSideBar documentVersion={documentVersionORM} />
        break
      case SidebarOptions.releaseNotesSidebar:
        tabContent = <ReleaseNotesSidebar />
        break
      case SidebarOptions.notesSidebar:
        tabContent = <NotesSidebar />
        break
      case SidebarOptions.associatedFilesSidebar:
        tabContent = <AssociatedFilesSideBar />
        break
      default:
        tabContent = null
        break
    }

    return (
      tabContent
    )
  }

  return (
    <DNABox
      appearance="col"
    >
      <Controls isUserDocument={isUserDocument} />
      <Iffy is={visibleSidebar}>
        <DNABox appearance="col" fill style={styles.sidebarContainer} >
          <DNABox>
            <DNABox appearance="row" fill style={styles.sidebarButtons}>
              {tabs}
            </DNABox>
          </DNABox>
          <DNABox
            appearance="col"
            as={ScrollView}
            style={{
              marginTop: 20,
              marginRight: 24,
            }}
          >
            <DNABox appearance="col" style={{ maxHeight: 10, paddingLeft: 20 }}>
              {getContent()}
            </DNABox>
          </DNABox>
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

export default SideBar
