import React, { useRef, useState, useEffect } from 'react';
import { Image, ScrollView } from 'react-native'
import im from 'immer'
import {
  Stack,
  DNABox,
  DNAText,
  Iffy,
  luxColors,
} from '@alucio/lux-ui'
import ReactMarkdown from 'react-markdown'

import { FAQStateContext, CollapseMap } from './FAQ.context'
import { Header, List, ListItem, Link, Text, Strong, SectionNav } from './FAQ.components'
import { useAppSettings } from 'src/state/context/AppSettings';

const useFAQSharedResources = () => {
  const sourceFileURL = require('../../../assets/html/FAQ.md')
  const renderers = {
    heading: Header,
    h1: Header,
    h2: Header,
    h3: Header,
    ol: List,
    li: ListItem,
    a: Link,
    p: Text,
    strong: Strong,
  }

  const [rawFile, setRawFile] = useState<string>()
  const [collapseMap, setCollapseMap] = useState<CollapseMap>({})
  const [sections, setSections] = useState<any[]>([])
  const [title, setTitle] = useState<string>()
  const scrollRef = useRef<any>()

  async function fetchRawInput(ignore:boolean, delayRender?) {
    const res = await fetch(sourceFileURL)
    const rawInput = await res.text()
    if (ignore) return;
    delayRender
      ? setTimeout(() => {
        setRawFile(rawInput)
      }, delayRender)
      : setRawFile(rawInput)
  }

  const value = {
    title,
    setTitle,
    collapseMap,
    addCollapse: (idx: number) => setCollapseMap(im((draft: CollapseMap) => { draft[idx] = true })),
    toggleCollapse: (idx: number) => setCollapseMap(im((draft: CollapseMap) => { draft[idx] = !draft[idx] })),
    sections,
    pushSection: (ref: any) => setSections(p => [...p, ref]),
  }

  // After the initial markdown renders, we run some post-processing (via useEffect callbacks)
  // to generate the Nav section/collapisble map.
  // We hide the UI until everything is done from our side to avoid content jumping around
  const hideStyle = !title
    ? { style: { maxHeight: 0, overflowY: 'hidden' } }
    : { }

  return {
    sourceFileURL,
    renderers,
    rawFile,
    setRawFile,
    collapseMap,
    setCollapseMap,
    sections,
    setSections,
    title,
    setTitle,
    scrollRef,
    fetchRawInput,
    value,
    hideStyle,
  }
}

// Desktop Variant
const FAQDesktop: React.FC = () => {
  const headerImage = require('../../../assets/images/FAQ_Header.jpg')
  const {
    renderers,
    rawFile,
    sections,
    title,
    scrollRef,
    fetchRawInput,
    value,
    hideStyle,
  } = useFAQSharedResources()

  useEffect(() => {
    let ignore = false

    // We have to pass in the raw string for the input
    fetchRawInput(ignore)

    return () => { ignore = true }
  }, [])

  return (
    <DNABox
      appearance="col"
      alignX="center"
      as={ScrollView}
      ref={scrollRef}
      fill
    >
      <FAQStateContext.Provider value={value}>
        {/* Optional Loading Indicator */}
        <Iffy is={!title}>
          <DNABox fill alignX="center" alignY="center">
            <Image
              style={{ width: 200, height: 200 }}
              source={require('../../../assets/images/LoaderDNA.gif')}
            />
          </DNABox>
        </Iffy>
        <DNABox appearance="col" {...hideStyle}>
          {/* Header Image */}
          <Stack anchor="center">
            <Stack.Layer>
              <Image
                style={{
                  width: '100%',
                  height: 400,
                }}
                source={headerImage}
              />
            </Stack.Layer>
            <Stack.Layer>
              <DNAText
                h1
                status="basic"
                testID="page-title"
              >
                { title?.trim() }
              </DNAText>
            </Stack.Layer>
          </Stack>
          {/* Content */}
          <DNABox style={{ maxWidth: 1440, padding: 48, marginTop: 80 }}>
            <SectionNav scrollRef={scrollRef} sections={sections} />
            <DNABox appearance="col" fill>
              <ReactMarkdown
                sourcePos
                includeElementIndex
                components={renderers}
              >
                { rawFile! }
              </ReactMarkdown>
            </DNABox>
          </DNABox>
        </DNABox>
      </FAQStateContext.Provider>
    </DNABox>

  )
}

// Tablet Variant
const FAQTablet: React.FC = () => {
  const {
    renderers,
    rawFile,
    sections,
    title,
    scrollRef,
    fetchRawInput,
    value,
    hideStyle,
  } = useFAQSharedResources()

  useEffect(() => {
    let ignore = false

    // We have to pass in the raw string for the input
    fetchRawInput(ignore, 600)
    return () => { ignore = true }
  }, [])

  return (
    <DNABox
      appearance="col"
      alignX="center"
      as={ScrollView}
      ref={scrollRef}
      fill
      style={{ backgroundColor:luxColors.hoveredBackground.primary }}
    >
      <FAQStateContext.Provider value={value}>
        {/* Optional Loading Indicator */}
        <Iffy is={!title}>
          <DNABox fill alignX="center" alignY="center">
            <Image
              style={{ width: 200, height: 200 }}
              source={require('../../../assets/images/LoaderDNA.gif')}
            />
          </DNABox>
        </Iffy>
        <DNABox appearance="col" {...hideStyle}>
          {/* Content */}
          <DNABox style={{ padding: 16 }}>
            <SectionNav scrollRef={scrollRef} sections={sections} />
          </DNABox>
          <DNABox style={{ paddingHorizontal: 16, paddingBottom: 120 }}>
            <DNABox appearance="col" fill>
              <ReactMarkdown
                sourcePos
                includeElementIndex
                components={renderers}
              >
                { rawFile! }
              </ReactMarkdown>
            </DNABox>
          </DNABox>
        </DNABox>
      </FAQStateContext.Provider>
    </DNABox>
  )
}

const FAQSwitcher: React.FC = () => {
  const { deviceMode } = useAppSettings()

  return deviceMode === 'desktop'
    ? <FAQDesktop />
    : <FAQTablet />
}

export default FAQSwitcher
