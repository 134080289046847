/**
 * [TODO-928] - We should extract these components out and making them the standard way in any slide editor to render slides/groups
 */
import React from 'react'
import { StyleSheet, ViewStyle } from 'react-native'
import DNAThumbnail, { DNAThumbnailProps } from 'src/components/DNA/Thumbnail/DNAThumbnail'

import { DocumentAccessLevel, Page } from '@alucio/aws-beacon-amplify/src/models'
import {
  PayloadGroup,
  usePresentationBuilderState,
} from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider'
import { thumbnailSizes } from 'src/hooks/useThumbnailSize/useThumbnailSize';

import {
  DNABox,
  DNAText,
  DNAChip,
  DNAIcon,
  Iffy,
  Stack,
} from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { DNABoxProps } from '@alucio/lux-ui/lib/components/layout/DNABox/DNABox'
import { useOnlineStatus } from 'src/state/context/AppSettings'

import { GroupItemOverlay, notPublishedStatus } from './Overlays'
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector'

const S = StyleSheet.create({
  outlineBorder: {
    borderRadius: 4,
    borderWidth: 3,
    borderColor: colors['color-flat-400'],
    padding: 4,
  },
  thumbnailContainer: {
    padding: 4,
  },
  groupedContainer: {
    paddingTop: 64,
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  // Offset the container so groups aligned with non-grouped items, overlay is also offset though
  groupedOffset: {
    marginHorizontal: -16,
  },
  thumbnailBackdrop: {
    backgroundColor: colors['color-flat-400'],
  },
  footerOffset: {
    marginHorizontal: 2,
  },
})

export const GroupedSlides: React.FC<{
  group: PayloadGroup,
  startingIdx: number,
  orientation: DNABoxProps['appearance'],
  style?: ViewStyle,
}> = (props) => {
  const { group, orientation, startingIdx, style } = props

  const finalStyle = StyleSheet.flatten([
    S.outlineBorder,
    S.groupedContainer,
    // S.groupedOffset,
    style,
  ])

  return (
    <GroupItemOverlay
      group={group}
      enabled
      style={style}
    >
      <DNABox
        // [NOTE] - This might be achievable without a prop by normal flexing
        //  but flexing certain containers might mess with DND
        appearance={orientation}
        spacing="large"
        style={finalStyle}
        wrap="stretch"
      >
        {
          group.pages.map((page, idx) => (
            <DNABox appearance="col" key={`${group.id}-${page.pageId}`}>
              <Slide
                key={`${group.id}-${page.pageId}`}
                group={group}
                page={page}
                displayIdx={startingIdx ? (startingIdx + idx) : -1}
                enableOverlay={false}
              />
            </DNABox>
          ))
        }
      </DNABox>
    </GroupItemOverlay>
  )
}

type SlideProps = {
  group: PayloadGroup,
  page: Page,
  displayIdx: number,
  enableOverlay?: boolean,
  style?: ViewStyle,
} & Pick<DNAThumbnailProps, 'height' | 'width'>

export const Slide: React.FC<SlideProps> = (props) => {
  const { group, page, displayIdx, enableOverlay, style } = props
  const { editorThumbnailMode, displayPageSourceFile } = usePresentationBuilderState();
  const isNetworkConnected = useOnlineStatus()
  const isFromPersonalDocument =
    group.documentVersion?.relations.document.model.accessLevel === DocumentAccessLevel.USER;

  const thumbnailSize = {
    height: thumbnailSizes[editorThumbnailMode].height,
    width: thumbnailSizes[editorThumbnailMode].width,
  };

  const s3URL = detectArchivedFileKeyPath(group.documentVersion?.model, page)!

  const { isContentCached } = group.documentVersion?.meta?.assets ?? { }

  const finalStyle = StyleSheet.flatten([
    S.outlineBorder,
    S.thumbnailContainer,
    style,
  ])

  const disableThumbnail = notPublishedStatus[group.groupStatus]

  return (
    <DNABox appearance="col" spacing="small">
      {/* [TODO-928] - Consider making this composable instead of prop driven */}
      <GroupItemOverlay group={group} enabled={enableOverlay}>
        {/* Thumbnail Container */}
        <DNABox style={finalStyle}>
          {
            !disableThumbnail
              ? (
                <Stack anchor="center">
                  <Stack.Layer>
                    <DNAThumbnail
                      overlayText={displayPageSourceFile ? group.documentVersion?.model.title : undefined}
                      style={S.thumbnailBackdrop}
                      s3URL={s3URL}
                      {...thumbnailSize}
                    />
                  </Stack.Layer>
                  <Stack.Layer>
                    {
                      (!isNetworkConnected && !isContentCached)
                        ? (<DNAIcon.Styled
                            appearance="ghost"
                            status="basic"
                            name="cloud-off-outline"
                            size="xlarge"
                        />)
                        : null
                    }
                  </Stack.Layer>
                </Stack>
              )
              //  - If a slide in a group is no longer "valid"
              //    show a fallback holder (with additional details/indicators)
              : <DNABox style={[S.thumbnailBackdrop, thumbnailSize]} />
          }
        </DNABox>
      </GroupItemOverlay>
      <DNABox style={S.footerOffset} fill>
        <SlideFooter isFromPersonalDocument={isFromPersonalDocument} page={page} displayIdx={displayIdx} />
      </DNABox>
    </DNABox>
  )
}

export const SlideFooter: React.FC<{
  page: Page,
  isFromPersonalDocument?: boolean,
  displayIdx: number,
}> = (props) => {
  const { isFromPersonalDocument, page, displayIdx } = props

  return (
    <DNABox fill spacing="between">
      <DNAText bold>{displayIdx === -1 ? ' ' : displayIdx}</DNAText>
      <Iffy is={page.isRequired}>
        <DNAChip status="danger" appearance="tag">
          Required
        </DNAChip>
      </Iffy>
      <Iffy is={isFromPersonalDocument}>
        <DNAChip status="dark" appearance="tag">
          MY UPLOADS
        </DNAChip>
      </Iffy>
    </DNABox>
  )
}

export default Slide
