import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNADivider, luxColors } from '@alucio/lux-ui';
import { TenantORM } from 'src/state/redux/selector/tenant';
import DNAUsersList from 'src/components/DNA/Users/DNAUsersList';
import { exportData } from './Export';
import { useCurrentUser } from 'src/state/redux/selector/user';
import { DRAWER_ENTITIES, drawerActions } from '../../state/redux/slice/drawer';
import { useDispatch } from 'react-redux';

const styles = StyleSheet.create({
  crumbsColor: {
    color: luxColors.flat.primary,
  },
  header: {
    marginBottom: 16,
  },
  itemTextColor: {
    color: luxColors.contentText.tertiary,
  },
  mainWrapper: {
    backgroundColor: luxColors.emptyStateButtonText.primary,
    overflowX: 'auto',
  },
  tab: {
    paddingHorizontal: 12,
    paddingVertical: 20,
  },
  tenantRowWrapper: {
    borderBottomColor: luxColors.divider.primary,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 20,
  },
  tenantsWrapper: {
    borderRadius: 8,
    overflow: 'hidden',
  },
  actionsContainer: {
    padding: 16,
  },
});

const UsersList = (props: { selectedTenant: TenantORM | undefined }) => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const { selectedTenant } = props;

  function openCreateUserModal(): void {
    dispatch(drawerActions.toggle({
      entity: DRAWER_ENTITIES.USER,
      tenantId: selectedTenant?.tenant.id,
    }));
  }
  return (
    <DNABox style={styles.mainWrapper} fill appearance="col">
      <DNABox spacing="small" alignX="end" style={styles.actionsContainer}>
        <DNAButton
          size="small"
          iconLeft="download"
          status="neutral"
          appearance="outline"
          onPress={() => exportData(selectedTenant?.meta.users, currentUser, selectedTenant)}
        >
          Export users list
        </DNAButton>
        <DNAButton
          size="small"
          iconLeft="plus"
          onPress={openCreateUserModal}
        >
          Add user
        </DNAButton>
      </DNABox>
      <DNADivider />
      <DNAUsersList users={selectedTenant?.meta.users || []} />
    </DNABox>
  );
};

UsersList.displayName = 'UsersList';

export default UsersList;
