import React from 'react';
import { DNABox, DNAButton, DNAText, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import { StyleSheet } from 'react-native';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { Tab, tabs } from '../PresentationControls';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import ConfirmationModal from '../TabContent/MeetingDetails/ConfirmationModal';
import { useDispatch } from 'react-redux';

const styles = StyleSheet.create({
  closeIcon: {
    color: colors['color-text-basic'],
  },
  container: {
    maxHeight: 56,
    backgroundColor: colors['color-dark-500'],
    paddingHorizontal: 28,
  },
  presentationNumber: {
    backgroundColor: colors['color-text-basic'],
    borderRadius: 3,
    paddingHorizontal: 6,
    paddingVertical: 3,
  },
})

const Header = () => {
  const { presentations } = useContent();
  const {
    setCurrentTab,
    currentTab,
    togglePanelVisibility,
    meetingORM,
    endMeeting,
    meetingForm,
  } = useMeetingsState();
  const isInPersonMeeting = meetingORM?.model.type === MeetingType.IN_PERSON;
  const dispatch = useDispatch()

  const { isDirty } = meetingForm.formState

  const checkFormDiscard = (cb: () => void) => {
    const isMeetingTab = currentTab === 'MEETING_DETAILS'

    function onConfirm(): void {
      cb()
      meetingForm.reset({ title: meetingORM?.model.title, notes: meetingORM?.model.notes || '' }, { isDirty: false })
    }

    isMeetingTab && isDirty
      ? dispatch(
        DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => (<ConfirmationModal
            {...props}
            onConfirmAction={onConfirm}
          />),
        }))
      : cb()
  }

  function onSelectTab(newTab: Tab): void {
    newTab !== currentTab && checkFormDiscard(() => { setCurrentTab(newTab) })
  }

  const handleTogglePanelVisibility = () => {
    checkFormDiscard(togglePanelVisibility)
  }

  return (
    <DNABox style={styles.container} alignY="center" fill spacing="between">
      {/* TABS */}
      <DNABox fill>
        {
          (Object.keys(tabs) as Tab[]).map((tabKey, idx) => {
            if (isInPersonMeeting && tabKey === 'PRESENTING') {
              return undefined;
            }
            const isSelected = currentTab === tabKey;
            const isPresenting = presentations.length > 0;
            return (
              <DNABox key={tabs[tabKey].testID + idx}>
                <DNAButton
                  onPress={() => onSelectTab(tabKey as Tab)}
                  appearance="ghost"
                  status={isSelected ? 'slate' : undefined}
                  context="square"
                  size="medium"
                  testID={`${tabs[tabKey].testID}-button-tab`}
                >
                  <DNABox spacing="small" alignY="center">
                    <DNAText status="basic">
                      {tabs[tabKey].title}
                    </DNAText>
                    <Iffy is={tabKey === 'OPENED_PRESENTATIONS'}>
                      <DNABox
                        style={[styles.presentationNumber,
                          isPresenting && { backgroundColor: colors['color-success-500'] }]}
                        alignX="center"
                        alignY="center"
                      >
                        <DNAText status={isPresenting ? 'basic' : 'dark'}>
                          {presentations.length}
                        </DNAText>
                      </DNABox>
                    </Iffy>
                  </DNABox>
                </DNAButton>
              </DNABox>
            );
          })
        }
      </DNABox>
      {/* CLOSE PANEL BUTTON */}
      <Iffy is={isInPersonMeeting}>
        <DNAButton
          style={{ paddingHorizontal: 0 }}
          // @ts-ignore
          iconStyle={styles.closeIcon}
          onPress={handleTogglePanelVisibility}
          iconLeft="close"
          appearance="ghost"
          size="medium"
        />
      </Iffy>
      {/* END MEETING BUTTON */}
      <Iffy is={!isInPersonMeeting}>
        <DNAButton
          context="square"
          size="tiny"
          status="danger"
          onPress={endMeeting}
        >
          <DNAText status="basic">End</DNAText>
        </DNAButton>
      </Iffy>
    </DNABox>
  );
};

Header.displayName = 'Header';

export default Header;
