import React from 'react'
import { DNABox, DNACard, DNAText, Iffy, DNAChip, DNAButton } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import { Pressable, StyleSheet } from 'react-native';
import { LoadedPresentation } from 'src/state/context/ContentProvider/ContentProvider';
import { isDocumentVersionORM, isFolderItemORM } from 'src/types/typeguards';
import { Icon } from '@ui-kitten/components';

const styles = StyleSheet.create({
  presentButton: {
    borderRadious: 4,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.24)',
  },
  containerStyle: {
    paddingLeft: 8,
    paddingRight: 12,
    paddingVertical: 8,
    paddingBottom: 8,
    backgroundColor: colors['color-dark-300'],
    marginVertical: 4,
    borderRadius: 4,
  },
  inPersonControlContainerStyle: {
    paddingLeft: 8,
    paddingRight: 12,
    paddingVertical: 8,
    paddingBottom: 8,
    backgroundColor: 'transparent',
    marginVertical: 4,
    borderBottomColor: 'rgba(255, 255, 255, 0.16)',
    borderBottomWidth: 1,
  },
  activeItem: {
    backgroundColor: colors['color-success-transparent-200'],
    borderWidth: 1,
    borderColor: colors['color-success-500'],
    bordeRadius: 4,
    paddingLeft: 8,
    paddingRight: 12,
    paddingVertical: 8,
    paddingBottom: 8,
    marginVertical: 4,
  },
  icon: {
    color: colors['color-success-500'],
    width: 32,
    height: 32,
  },
});

export interface OpenedPresentationRowProps {
  item: LoadedPresentation,
  removePresentation: (id: string) => void,
  changeActivePresentation: (id: string) => void,
  activePresentation?: LoadedPresentation,
  inPersonControls?: boolean,

}

enum Actions {
  change,
  close
}
type Action = keyof typeof Actions
type ActionEffects = Record<Action, (id: string) => void>

const DocumentOpenedPresentationRow: React.FC<OpenedPresentationRowProps> = (props) => {
  const { item, removePresentation, changeActivePresentation, activePresentation } = props
  const s3URL = detectArchivedFileKeyPath(item.currentPresentablePage.documentVersion?.model,
    item.currentPresentablePage.page)!
  const isActive = activePresentation?.presentable.id === item.presentable.id;
  const isDocumentVersion = isDocumentVersionORM(item.presentable.orm) ||
    isDocumentVersionORM(item.presentable.orm.relations.item);
  const isModifiedDocumentVersion = isFolderItemORM(item.presentable.orm) && isDocumentVersion &&
    item.presentable.orm.model.visiblePages?.length;

  const rowStyle = [
    !props.inPersonControls && isActive && styles.activeItem,
    !props.inPersonControls && !isActive && styles.containerStyle,
    props.inPersonControls && styles.inPersonControlContainerStyle,
  ]

  const actionEffects:ActionEffects = {
    change: changeActivePresentation,
    close: removePresentation,
  }

  const triggerAction = (action:Action) => {
    actionEffects[action](item.presentable.id)
  }

  return (
    <DNACard
      appearance="flat"
      style={rowStyle}
    >
      <DNABox alignY="center" spacing="between" childFill={0}>
        <DNABox spacing="between" alignY="center" childFill={0}>
          <Pressable onPress={ () => triggerAction('change') }>
            <DNABox spacing="medium" alignY="center" childFill={1}>

              <DNABox
                style={{
                  backgroundColor: colors['color-dark-300'],
                  borderWidth: 1,
                  borderColor: 'rgba(255, 255, 255, 0.32)',
                }}
              >
                <DNAThumbnail
                  s3URL={s3URL}
                  useLoadingIndicator={true}
                  width={80}
                  height={46}
                />
              </DNABox>
              <DNABox spacing="small" fill childFill={0}>

                {/* Content Info */}
                <DNABox spacing="small" appearance="col" fill>
                  <DNAText numberOfLines={2} status="basic">
                    {item.presentable.title}
                  </DNAText>
                  <DNABox spacing="small">
                    <Iffy is={isDocumentVersion}>
                      <DNABox spacing="small">
                        <DNADocumentChip
                          item={item.currentPresentablePage.documentVersion}
                          variant="purpose"
                        />
                        <DNAChip appearance="tag" status="flat">
                          {item.currentPresentablePage.documentVersion.model?.type}
                        </DNAChip>
                      </DNABox>
                    </Iffy>
                    <Iffy is={isModifiedDocumentVersion || !isDocumentVersion}>
                      <DNAChip
                        appearance="tag"
                        style={{ backgroundColor: colors['color-basic-transparent-200'] }}
                        status="dark"
                      >
                        MODIFIED
                      </DNAChip>
                    </Iffy>
                  </DNABox>
                </DNABox>

                {/* Action buttons */}
                <Iffy is={!props.inPersonControls}>
                  <DNABox spacing="small" fill alignY="center">
                    <Iffy is={isActive}>
                      <DNAText status="success">Presenting...</DNAText>
                    </Iffy>
                    <DNAButton
                      onPress={() => triggerAction('close')}
                      appearance="ghost"
                      status="neutral"
                      context="square"
                      size="medium"
                      style={styles.presentButton}
                    >
                      <DNAText status="basic">Close</DNAText>
                    </DNAButton>
                  </DNABox>
                </Iffy>
                <Iffy is={props.inPersonControls && isActive}>
                  <DNABox fill alignY="center">
                    <Icon name="check" style={styles.icon} />
                  </DNABox>
                </Iffy>
              </DNABox>

            </DNABox>

          </Pressable>

        </DNABox>
      </DNABox>
    </DNACard >
  )
}

export default DocumentOpenedPresentationRow
