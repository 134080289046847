import { createSlice } from '@reduxjs/toolkit'
import { commonReducers, initialState } from './common'
import { Document, DocumentStatus } from '@alucio/aws-beacon-amplify/src/models'
import { DocumentORM } from 'src/types/types'

const sliceName = 'document'
const { reducers, extraReducers, asyncActions } = commonReducers<Document>(sliceName)

const documentSlice = createSlice({
  name: sliceName,
  initialState: initialState<Document>(),
  reducers: {
    ...reducers,
    archive: {
      prepare: (documentORM: DocumentORM) => {
        return {
          payload: {
            model: Document,
            entity: documentORM.model,
            updates: { status: DocumentStatus.ARCHIVED },
          },
        }
      },
      reducer: reducers.save,
    },
    publish: {
      prepare: (documentORM: DocumentORM) => {
        return {
          payload: {
            model: Document,
            entity: documentORM.model,
            updates: { status: DocumentStatus.PUBLISHED },
          },
        }
      },
      reducer: reducers.save,
    },
    revoke: {
      prepare: (documentORM: DocumentORM) => {
        return {
          payload: {
            model: Document,
            entity: documentORM.model,
            updates: { status: DocumentStatus.REVOKED },
          },
        }
      },
      reducer: reducers.save,
    },
    delete: {
      prepare: (documentORM: DocumentORM) => {
        return {
          payload: {
            model: Document,
            entity: documentORM.model,
            updates: { status: DocumentStatus.DELETED },
          },
        }
      },
      reducer: reducers.save,
    },
  },
  extraReducers,
})

export default documentSlice
export const documentActions = {
  ...documentSlice.actions,
  ...asyncActions,
}
