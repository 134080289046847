import React from 'react';
import { Tab, TabBar } from '@alucio/lux-ui';

export enum FileEditTabBarSelected {
    LABELS,
    PREVIEW,
    UPDATE_VERSION
}

interface FileEditTabBarProps {
    onTabSelected: (selectedTab: number) => void;
    tabSelected: number;
}

export const FileEditTabBar = (props: FileEditTabBarProps) => {
  function selectedOption(index:number) {
    props.onTabSelected(index);
  }

  return (
    <TabBar
      selectedIndex={props.tabSelected}
      style={{ height: 56 }}
      onSelect={selectedOption}
    >
      <Tab title="Labels"/>
      <Tab title="Preview"/>
      <Tab title="Version" testID="version-tab"/>
    </TabBar>
  );
};
