import React from 'react'
import { DocumentORM } from 'src/types/types'
import { DNACardComponent } from 'src/components/DNA/Grid/Cards/types'

import {
  DNABox,
  DNAText,
  DNACard,
  useTheme,
} from '@alucio/lux-ui'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'

export const DNADocumentVirtualCard: DNACardComponent<DocumentORM> = (props) => {
  const { ORM: documentORM, onPress } = props
  const theme = useTheme()

  const canPresent = documentORM.meta.permissions.MSLPresent;

  return (
    <DNABox
      alignX="start"
      style={[{ minWidth: 232, minHeight: 188 }, !canPresent && { opacity: 0.4 }]}
    >
      <DNACard
        interactive="pressable"
        onPress={() => { onPress?.(documentORM) }}
        style={{
          padding: 8,
          backgroundColor: theme['color-dark-300'],
        }}
      >
        <DNABox appearance="col" spacing="small">
          <DNABox style={{ backgroundColor: 'rgb(0, 0, 0)' }}>
            <DNADocumentThumbnail
              documentVersionORM={documentORM.relations.version.latestUsableDocumentVersion}
              width={216}
              height={124}
            />
          </DNABox>
          <DNABox appearance="row" spacing="small">
            <DNADocumentChip item={documentORM} variant="purpose" />
            <DNAText
              status="basic"
              style={{ width: 183, overflow: 'hidden' }}
              numberOfLines={2}
            >
              {documentORM.relations.version.latestUsableDocumentVersion.model.title}
            </DNAText>
          </DNABox>
        </DNABox>
      </DNACard>
    </DNABox>
  );
}

export default DNADocumentVirtualCard
