import { PlayerActions, PlayerControlData } from '@alucio/video';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PresentationControlSliceType {
  action: PlayerActions
  param?: any
  controlMsg?: PlayerControlData
}

const initialState: PresentationControlSliceType = {
  action: PlayerActions.next,
}

const presentationControlSlice = createSlice({
  name: 'presentationControlState',
  initialState: initialState,
  reducers: {
    triggerPresentationAction : (state, action: PayloadAction<PresentationControlSliceType>) => {
      const { payload } = action
      state.controlMsg = new PlayerControlData(
        payload.action,
        payload?.param,
      )
      return state
    },
    resetState: () => {
      return initialState;
    },
  },
});

export default presentationControlSlice;
export const presentationControlActions = {
  ...presentationControlSlice.actions,
}
