import React from 'react'
import { StyleSheet, View } from 'react-native'
import {
  DNAText,
  DNABox,
  DNAButton,
  Iffy,
  luxColors,
  DNADivider,
} from '@alucio/lux-ui'

import { useDisplayMode } from 'src/components/DNA/hooks/useDisplayMode'
import {
  useDNADocumentFilters,
} from 'src/components/DNA/Document/DNADocumentFilters/DNADocumentFilters'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import useCurrentPage from '../hooks/useCurrentPage'
import { ROUTES } from 'src/router/routes'

export interface GridListTabletHeaderProps {
  totalRecordCount: number,
  searchText?: string,
}

const S = StyleSheet.create({
  hoveredRow: {
    backgroundColor: luxColors.mainContainer.primary,
  },
  itemsCountText: {
    color: colors['color-brand-compliment-500'],
  },
  HeaderCol: {
    paddingVertical: 12,
  },
  DesktopContainer: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    overflow: 'hidden',
  },
  MobileContainer: {
    backgroundColor: luxColors.info.primary,
  },
  MobileHeader: {
    backgroundColor: colors['color-flat-200'],
    paddingHorizontal: 16,
    minHeight: 49,
  },
  MobileRow: {
    paddingHorizontal: 16,
  },
  SelectedFilters: {
    backgroundColor: colors['color-brand-compliment-500'],
    borderRadius: 3,
    left: 20,
    paddingHorizontal: 4,
    paddingVertical: 2,
    position: 'absolute',
    top: 0,
  },
  DataCell: {
    paddingTop: 8,
    paddingBottom: 8,
  },
})

export const GridListTabletHeader: React.FC<GridListTabletHeaderProps> = (props) => {
  const { searchText, totalRecordCount } = props;
  const { activeFilters, toggleModal } = useDNADocumentFilters()
  const { displayModeIconName, toggleDisplayMode } = useDisplayMode()

  const currentPage = useCurrentPage();

  return (
    <DNABox alignY="center" spacing="small" style={S.MobileHeader}>
      <DNABox fill alignY="center" spacing="large">
        <Iffy is={currentPage !== ROUTES.BOOKMARKS}>
          <DNAButton
            appearance="ghost"
            status="subtle"
            context="moderate"
            iconLeft="filter-variant"
            onPress={toggleModal}
            testID="header-filters-button"
          >
            <Iffy is={activeFilters.length}>
              <View style={S.SelectedFilters}>
                <DNAText testID="filter-count-label">
                  {activeFilters.length}
                </DNAText>
              </View>
            </Iffy>
          </DNAButton>
        </Iffy>
        <DNAButton
          appearance="ghost"
          status={'subtle'}
          context="moderate"
          iconLeft={displayModeIconName}
          onPress={toggleDisplayMode}
        />
      </DNABox>
      <DNADivider vertical={true} height={20} />
      <DNAText status="flatAlt">
        {
          searchText
            ? `Search results for "${searchText}" | ${totalRecordCount} result${totalRecordCount === 1 ? '' : 's'}`
            : `${totalRecordCount} item(s)`
        }
      </DNAText>
    </DNABox>
  )
}

export default GridListTabletHeader
