export const schema = {
    "models": {
        "Folder": {
            "name": "Folder",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "isPinned": {
                    "name": "isPinned",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "FolderStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "FolderItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "sharePermissions": {
                    "name": "sharePermissions",
                    "isArray": true,
                    "type": {
                        "nonModel": "SharePermission"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            },
            "syncable": true,
            "pluralName": "Folders",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByTenantIdCreatedBy",
                        "queryField": "foldersByTenantIdCreatedBy",
                        "fields": [
                            "tenantId",
                            "createdBy"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "createdBy",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "update",
                                    "read"
                                ],
                                "identityClaim": "custom:user_id"
                            }
                        ]
                    }
                }
            ]
        },
        "Tenant": {
            "name": "Tenant",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "licensedUsers": {
                    "name": "licensedUsers",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "TenantStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "fields": {
                    "name": "fields",
                    "isArray": true,
                    "type": {
                        "nonModel": "FieldConfig"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "folderUpdateGracePeriodDays": {
                    "name": "folderUpdateGracePeriodDays",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "medInfoURL": {
                    "name": "medInfoURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "publisherListConfig": {
                    "name": "publisherListConfig",
                    "isArray": false,
                    "type": {
                        "nonModel": "ListConfiguration"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "mslListConfig": {
                    "name": "mslListConfig",
                    "isArray": false,
                    "type": {
                        "nonModel": "ListConfiguration"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "statusChangedAt": {
                    "name": "statusChangedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "statusChangedBy": {
                    "name": "statusChangedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "ssoDomains": {
                    "name": "ssoDomains",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "integrations": {
                    "name": "integrations",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "config": {
                    "name": "config",
                    "isArray": false,
                    "type": {
                        "nonModel": "TenantConfig"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Tenants",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "AlucioAdmin"
                                ],
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "id",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:org_id"
                            }
                        ]
                    }
                }
            ]
        },
        "DocumentVersion": {
            "name": "DocumentVersion",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "documentId": {
                    "name": "documentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "versionNumber": {
                    "name": "versionNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "srcFilename": {
                    "name": "srcFilename",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "conversionStatus": {
                    "name": "conversionStatus",
                    "isArray": false,
                    "type": {
                        "enum": "ConversionStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "DocumentStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "srcFile": {
                    "name": "srcFile",
                    "isArray": false,
                    "type": {
                        "nonModel": "S3Object"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "srcHash": {
                    "name": "srcHash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "srcSize": {
                    "name": "srcSize",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "pages": {
                    "name": "pages",
                    "isArray": true,
                    "type": {
                        "nonModel": "Page"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "pageGroups": {
                    "name": "pageGroups",
                    "isArray": true,
                    "type": {
                        "nonModel": "PageGroup"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "FileType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "releaseNotes": {
                    "name": "releaseNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "changeType": {
                    "name": "changeType",
                    "isArray": false,
                    "type": {
                        "enum": "DocumentVersionChangeType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "labelValues": {
                    "name": "labelValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "LabelValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "shortDescription": {
                    "name": "shortDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "longDescription": {
                    "name": "longDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "expiresAt": {
                    "name": "expiresAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "hasCopyright": {
                    "name": "hasCopyright",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "purpose": {
                    "name": "purpose",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "canHideSlides": {
                    "name": "canHideSlides",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "distributable": {
                    "name": "distributable",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "downloadable": {
                    "name": "downloadable",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isInternalGenerated": {
                    "name": "isInternalGenerated",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "semVer": {
                    "name": "semVer",
                    "isArray": false,
                    "type": {
                        "nonModel": "SemVer"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "notificationScope": {
                    "name": "notificationScope",
                    "isArray": false,
                    "type": {
                        "enum": "NotificationScope"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "selectedThumbnail": {
                    "name": "selectedThumbnail",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "publishedAt": {
                    "name": "publishedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "uploadedAt": {
                    "name": "uploadedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "uploadedBy": {
                    "name": "uploadedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "convertedArchiveKey": {
                    "name": "convertedArchiveKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "convertedArchiveSize": {
                    "name": "convertedArchiveSize",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "convertedFolderKey": {
                    "name": "convertedFolderKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "associatedFiles": {
                    "name": "associatedFiles",
                    "isArray": true,
                    "type": {
                        "nonModel": "AssociatedFile"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "editPermissions": {
                    "name": "editPermissions",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "converterVersion": {
                    "name": "converterVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integration": {
                    "name": "integration",
                    "isArray": false,
                    "type": {
                        "nonModel": "DocumentVersionIntegration"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "integrationType": {
                    "name": "integrationType",
                    "isArray": false,
                    "type": {
                        "enum": "IntegrationType"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "DocumentVersions",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByTenantIdStatus",
                        "queryField": "documentVersionsByTenantIdStatus",
                        "fields": [
                            "tenantId",
                            "status"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "editPermissions",
                                "operations": [
                                    "read",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "tenantId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:org_id"
                            }
                        ]
                    }
                }
            ]
        },
        "AttachedFile": {
            "name": "AttachedFile",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "documentId": {
                    "name": "documentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "srcFile": {
                    "name": "srcFile",
                    "isArray": false,
                    "type": {
                        "nonModel": "S3Object"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "srcFileName": {
                    "name": "srcFileName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "srcHash": {
                    "name": "srcHash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "srcSize": {
                    "name": "srcSize",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "FileType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "editPermissions": {
                    "name": "editPermissions",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AttachedFiles",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByTenantId",
                        "fields": [
                            "tenantId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "editPermissions",
                                "operations": [
                                    "read",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "tenantId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:org_id"
                            }
                        ]
                    }
                }
            ]
        },
        "VirtualSession": {
            "name": "VirtualSession",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "meetingId": {
                    "name": "meetingId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "roomName": {
                    "name": "roomName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "SessionStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "recordingStatus": {
                    "name": "recordingStatus",
                    "isArray": false,
                    "type": {
                        "enum": "RecordingStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "recordingTaskId": {
                    "name": "recordingTaskId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "hostName": {
                    "name": "hostName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "hostPhonePin": {
                    "name": "hostPhonePin",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "terminatedAt": {
                    "name": "terminatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "meetingLog": {
                    "name": "meetingLog",
                    "isArray": true,
                    "type": {
                        "nonModel": "MeetingLogEntry"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "documentHistory": {
                    "name": "documentHistory",
                    "isArray": true,
                    "type": {
                        "nonModel": "DocumentHistoryEntry"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "attendees": {
                    "name": "attendees",
                    "isArray": true,
                    "type": {
                        "model": "VirtualAttendee"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "sessionId"
                    }
                }
            },
            "syncable": false,
            "pluralName": "VirtualSessions",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByMeetingIdStatus",
                        "queryField": "sessionsByMeetingIdStatus",
                        "fields": [
                            "meetingId",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByHostPhonePinStatus",
                        "queryField": "sessionByHostPhonePinStatus",
                        "fields": [
                            "hostPhonePin",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByCreatedByCreatedAt",
                        "queryField": "sessionsByCreatedByCreatedAt",
                        "fields": [
                            "createdBy",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "createdBy",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:user_id"
                            }
                        ]
                    }
                }
            ]
        },
        "VirtualAttendee": {
            "name": "VirtualAttendee",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "attendeeKey": {
                    "name": "attendeeKey",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "hostId": {
                    "name": "hostId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "meetingId": {
                    "name": "meetingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "sessionId": {
                    "name": "sessionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "AttendeeStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "statusDetail": {
                    "name": "statusDetail",
                    "isArray": false,
                    "type": {
                        "enum": "AttendeeStatusDetail"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "phonePin": {
                    "name": "phonePin",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "clientIP": {
                    "name": "clientIP",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "joinToken": {
                    "name": "joinToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "terminatedAt": {
                    "name": "terminatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "acceptedAt": {
                    "name": "acceptedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "lastSeenAt": {
                    "name": "lastSeenAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": false,
            "pluralName": "VirtualAttendees",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByHostIdStatus",
                        "queryField": "attendeesByHostIdStatus",
                        "fields": [
                            "hostId",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "BySessionId",
                        "queryField": "attendeesBySessionId",
                        "fields": [
                            "sessionId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByPhonePinStatus",
                        "queryField": "attendeeByPhonePinStatus",
                        "fields": [
                            "phonePin",
                            "status"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "hostId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:user_id"
                            }
                        ]
                    }
                }
            ]
        },
        "ContentShare": {
            "name": "ContentShare",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "token": {
                    "name": "token",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "ShareType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "contentId": {
                    "name": "contentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "expiresAt": {
                    "name": "expiresAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": false,
            "pluralName": "ContentShares",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByToken",
                        "queryField": "contentShareByToken",
                        "fields": [
                            "token"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "createdBy",
                                "allow": "owner",
                                "operations": [
                                    "create"
                                ],
                                "identityClaim": "custom:user_id"
                            }
                        ]
                    }
                }
            ]
        },
        "User": {
            "name": "User",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cognitoSubId": {
                    "name": "cognitoSubId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "givenName": {
                    "name": "givenName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "phoneNumber": {
                    "name": "phoneNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "familyName": {
                    "name": "familyName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "meetingId": {
                    "name": "meetingId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "defaultFilterValues": {
                    "name": "defaultFilterValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "LabelValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "lockedFilters": {
                    "name": "lockedFilters",
                    "isArray": true,
                    "type": {
                        "nonModel": "LabelValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "shareBCC": {
                    "name": "shareBCC",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "shareCC": {
                    "name": "shareCC",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "UserStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "role": {
                    "name": "role",
                    "isArray": false,
                    "type": {
                        "enum": "UserRole"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "bookmarkedDocs": {
                    "name": "bookmarkedDocs",
                    "isArray": true,
                    "type": {
                        "nonModel": "BookmarkedDoc"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "isExcludedFromReporting": {
                    "name": "isExcludedFromReporting",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Users",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByTenantId",
                        "fields": [
                            "tenantId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByMeetingId",
                        "fields": [
                            "meetingId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "AlucioAdmin"
                                ],
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "tenantId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:org_id"
                            }
                        ]
                    }
                }
            ]
        },
        "Document": {
            "name": "Document",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "accessLevel": {
                    "name": "accessLevel",
                    "isArray": false,
                    "type": {
                        "enum": "DocumentAccessLevel"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "DocumentStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "expiresAt": {
                    "name": "expiresAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "integration": {
                    "name": "integration",
                    "isArray": false,
                    "type": {
                        "nonModel": "DocumentIntegration"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "integrationType": {
                    "name": "integrationType",
                    "isArray": false,
                    "type": {
                        "enum": "IntegrationType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "FileType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "editPermissions": {
                    "name": "editPermissions",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Documents",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByTenantIdStatus",
                        "fields": [
                            "tenantId",
                            "status"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "editPermissions",
                                "operations": [
                                    "read",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "tenantId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:org_id"
                            }
                        ]
                    }
                }
            ]
        },
        "EmailTemplate": {
            "name": "EmailTemplate",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "labelValues": {
                    "name": "labelValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "LabelValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "subject": {
                    "name": "subject",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "cc": {
                    "name": "cc",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "bcc": {
                    "name": "bcc",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "associatedFiles": {
                    "name": "associatedFiles",
                    "isArray": true,
                    "type": {
                        "nonModel": "AssociatedFile"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "EmailTemplateStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "editPermissions": {
                    "name": "editPermissions",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "EmailTemplates",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "editPermissions",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "tenantId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:org_id"
                            }
                        ]
                    }
                }
            ]
        },
        "CustomDeck": {
            "name": "CustomDeck",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "autoUpdateAcknowledgedAt": {
                    "name": "autoUpdateAcknowledgedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "groups": {
                    "name": "groups",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomDeckGroup"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            },
            "syncable": true,
            "pluralName": "CustomDecks",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByTenantIdCreatedBy",
                        "fields": [
                            "tenantId",
                            "createdBy"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "createdBy",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:user_id"
                            }
                        ]
                    }
                }
            ]
        },
        "Meeting": {
            "name": "Meeting",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "startTime": {
                    "name": "startTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "endTime": {
                    "name": "endTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "contentPresented": {
                    "name": "contentPresented",
                    "isArray": true,
                    "type": {
                        "nonModel": "ContentPresented"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "MeetingType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "notes": {
                    "name": "notes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "MeetingStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Meetings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByCreatedByStartTime",
                        "queryField": "attendemeetingsByCreatedByStartTimeesByHostIdStatus",
                        "fields": [
                            "createdBy",
                            "startTime"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "createdBy",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "update",
                                    "read"
                                ],
                                "identityClaim": "custom:user_id"
                            }
                        ]
                    }
                }
            ]
        },
        "IntegrationLog": {
            "name": "IntegrationLog",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integrationId": {
                    "name": "integrationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "log": {
                    "name": "log",
                    "isArray": true,
                    "type": {
                        "nonModel": "IntegrationLogEntry"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "IntegrationRunStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": false,
            "pluralName": "IntegrationLogs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByIntegrationIdLog",
                        "fields": [
                            "tenantId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "AlucioAdmin"
                                ],
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IntegrationSettings": {
            "name": "IntegrationSettings",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "syncContentEvery": {
                    "name": "syncContentEvery",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "mapping": {
                    "name": "mapping",
                    "isArray": true,
                    "type": {
                        "nonModel": "IntegrationFieldMapping"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "clientConfigurationFields": {
                    "name": "clientConfigurationFields",
                    "isArray": true,
                    "type": {
                        "nonModel": "IntegrationClientConfigurationTuple"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "integrationType": {
                    "name": "integrationType",
                    "isArray": false,
                    "type": {
                        "enum": "IntegrationType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "enabled": {
                    "name": "enabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "notificationEmail": {
                    "name": "notificationEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "errorSyncEmail": {
                    "name": "errorSyncEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": false,
            "pluralName": "IntegrationSettings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByTenantIdSetting",
                        "fields": [
                            "tenantId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "AlucioAdmin"
                                ],
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "FolderStatus": {
            "name": "FolderStatus",
            "values": [
                "ACTIVE",
                "ARCHIVED",
                "REMOVED"
            ]
        },
        "FolderItemType": {
            "name": "FolderItemType",
            "values": [
                "FOLDER",
                "DOCUMENT_VERSION",
                "CUSTOM_DECK"
            ]
        },
        "FolderItemStatus": {
            "name": "FolderItemStatus",
            "values": [
                "ACTIVE",
                "REMOVED"
            ]
        },
        "PermissionType": {
            "name": "PermissionType",
            "values": [
                "VIEW"
            ]
        },
        "ShareTargetType": {
            "name": "ShareTargetType",
            "values": [
                "USER",
                "FILTER",
                "ALL"
            ]
        },
        "FieldDataType": {
            "name": "FieldDataType",
            "values": [
                "CATEGORICAL",
                "MULTICATEGORICAL",
                "STRING",
                "DATE"
            ]
        },
        "TenantStatus": {
            "name": "TenantStatus",
            "values": [
                "ACTIVE",
                "INACTIVE"
            ]
        },
        "ConversionStatus": {
            "name": "ConversionStatus",
            "values": [
                "PENDING",
                "PROCESSING",
                "PROCESSED",
                "ERROR"
            ]
        },
        "DocumentStatus": {
            "name": "DocumentStatus",
            "values": [
                "ARCHIVED",
                "REVOKED",
                "NOT_PUBLISHED",
                "PUBLISHED",
                "DELETED",
                "ARCHIVED_WITH_VERSIONS",
                "REVOKED_WITH_VERSIONS",
                "DELETED_WITH_VERSIONS"
            ]
        },
        "FileType": {
            "name": "FileType",
            "values": [
                "JPG",
                "JPEG",
                "XLS",
                "XLSX",
                "XLSM",
                "DOC",
                "DOCX",
                "PDF",
                "PPTX",
                "PNG",
                "MP4"
            ]
        },
        "DocumentVersionChangeType": {
            "name": "DocumentVersionChangeType",
            "values": [
                "MAJOR",
                "MINOR"
            ]
        },
        "NotificationScope": {
            "name": "NotificationScope",
            "values": [
                "NONE",
                "EMAIL"
            ]
        },
        "AssociatedFileType": {
            "name": "AssociatedFileType",
            "values": [
                "DOCUMENT",
                "ATTACHED_FILE"
            ]
        },
        "AssociatedFileStatus": {
            "name": "AssociatedFileStatus",
            "values": [
                "ACTIVE",
                "DELETED"
            ]
        },
        "IntegrationType": {
            "name": "IntegrationType",
            "values": [
                "VEEVA",
                "BEACON",
                "NONE"
            ]
        },
        "AttachedFileEntityType": {
            "name": "AttachedFileEntityType",
            "values": [
                "DOCUMENT_VERSION",
                "EMAIL_TEMPLATE"
            ]
        },
        "AttendeeStatus": {
            "name": "AttendeeStatus",
            "values": [
                "ACCEPTED",
                "CONNECTED",
                "DECLINED",
                "DISCONNECTED",
                "PENDING",
                "ENDED",
                "REMOVED",
                "LEFT"
            ]
        },
        "AttendeeStatusDetail": {
            "name": "AttendeeStatusDetail",
            "values": [
                "HOST_ENDED",
                "HOST_DISCONNECTED"
            ]
        },
        "SessionStatus": {
            "name": "SessionStatus",
            "values": [
                "ACTIVE",
                "TERMINATED"
            ]
        },
        "RecordingStatus": {
            "name": "RecordingStatus",
            "values": [
                "RECORDING",
                "PROCESSING",
                "RECORDED",
                "ERROR"
            ]
        },
        "MeetingContentType": {
            "name": "MeetingContentType",
            "values": [
                "CUSTOM_DECK",
                "DOCUMENT_VERSION"
            ]
        },
        "ShareType": {
            "name": "ShareType",
            "values": [
                "DOCUMENT_VERSION",
                "RECORDING",
                "ATTACHED_FILE"
            ]
        },
        "UserStatus": {
            "name": "UserStatus",
            "values": [
                "ACTIVE",
                "DEACTIVATED"
            ]
        },
        "UserRole": {
            "name": "UserRole",
            "values": [
                "AlucioAdmin",
                "TenantAdmin",
                "TenantPublisher",
                "TenantViewer"
            ]
        },
        "InputEngineType": {
            "name": "InputEngineType",
            "values": [
                "MULTICATEGORICAL",
                "SINGLECATEGORICAL"
            ]
        },
        "DocumentAccessLevel": {
            "name": "DocumentAccessLevel",
            "values": [
                "TENANT",
                "USER"
            ]
        },
        "EmailTemplateStatus": {
            "name": "EmailTemplateStatus",
            "values": [
                "DRAFT",
                "ACTIVE",
                "DEACTIVATED",
                "REMOVED"
            ]
        },
        "PurposeType": {
            "name": "PurposeType",
            "values": [
                "INTERNAL_USE_ONLY",
                "EXTERNAL_PROACTIVE",
                "EXTERNAL_REACTIVE",
                "NOT_DEFINED"
            ]
        },
        "MeetingLogEntryType": {
            "name": "MeetingLogEntryType",
            "values": [
                "BeaconControl",
                "PlayerControl",
                "Chat"
            ]
        },
        "MeetingLogActions": {
            "name": "MeetingLogActions",
            "values": [
                "startPresentation",
                "stopPresentation",
                "next",
                "prev",
                "gotoSlide",
                "play",
                "pause",
                "getNotes",
                "getFormattedNotes",
                "nxtSlide",
                "prvSlide",
                "clickCount",
                "jumpToAnim",
                "currentStatus",
                "getOutline",
                "preloadSlide",
                "resetSlide",
                "isSlideMediaLoaded",
                "playSlideAnims",
                "isTransitionPresent",
                "getContentDetails",
                "getCurrentAnimationCount",
                "playAutomaticAnimations",
                "forceStartSlidePlay",
                "reportProgress",
                "closePlayButton",
                "setRPath",
                "loadPres",
                "initPres",
                "authCloudFront",
                "inProgressParticipantJoin"
            ]
        },
        "ContentPresentedStatus": {
            "name": "ContentPresentedStatus",
            "values": [
                "ACTIVE",
                "DELETED"
            ]
        },
        "MeetingType": {
            "name": "MeetingType",
            "values": [
                "IN_PERSON",
                "VIRTUAL",
                "MANUAL"
            ]
        },
        "MeetingStatus": {
            "name": "MeetingStatus",
            "values": [
                "IN_PROGRESS",
                "ENDED_EXIT",
                "ENDED_TIMEOUT",
                "DELETED"
            ]
        },
        "LogLevel": {
            "name": "LogLevel",
            "values": [
                "WARNING",
                "ERROR",
                "SUCCESS",
                "INFO"
            ]
        },
        "IntegrationRunStatus": {
            "name": "IntegrationRunStatus",
            "values": [
                "RUNNING",
                "COMPLETED",
                "WARNING",
                "ERROR"
            ]
        }
    },
    "nonModels": {
        "MeetingInfo": {
            "name": "MeetingInfo",
            "fields": {
                "meetingId": {
                    "name": "meetingId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "hostGivenName": {
                    "name": "hostGivenName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "hostFamilyName": {
                    "name": "hostFamilyName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "hostId": {
                    "name": "hostId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "hostEmail": {
                    "name": "hostEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ContentTokenOutput": {
            "name": "ContentTokenOutput",
            "fields": {
                "token": {
                    "name": "token",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "expiresAt": {
                    "name": "expiresAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ExchangeShareTokenOutput": {
            "name": "ExchangeShareTokenOutput",
            "fields": {
                "token": {
                    "name": "token",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "expiresAt": {
                    "name": "expiresAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "fileName": {
                    "name": "fileName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ReportList": {
            "name": "ReportList",
            "fields": {
                "dashboardId": {
                    "name": "dashboardId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "FolderItem": {
            "name": "FolderItem",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "FolderItemType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "itemId": {
                    "name": "itemId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "FolderItemStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "addedAt": {
                    "name": "addedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updateAcknowledgedAt": {
                    "name": "updateAcknowledgedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "itemLastUpdatedAt": {
                    "name": "itemLastUpdatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "visiblePages": {
                    "name": "visiblePages",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "customTitle": {
                    "name": "customTitle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "SharePermission": {
            "name": "SharePermission",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "isDeleted": {
                    "name": "isDeleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "PermissionType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "targetType": {
                    "name": "targetType",
                    "isArray": false,
                    "type": {
                        "enum": "ShareTargetType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "targetUsername": {
                    "name": "targetUsername",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "targetLabels": {
                    "name": "targetLabels",
                    "isArray": true,
                    "type": {
                        "nonModel": "LabelValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "deletedBy": {
                    "name": "deletedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "deletedAt": {
                    "name": "deletedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "LabelValues": {
            "name": "LabelValues",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "values": {
                    "name": "values",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "FieldConfig": {
            "name": "FieldConfig",
            "fields": {
                "fieldName": {
                    "name": "fieldName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "required": {
                    "name": "required",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "isEmailTemplateFilter": {
                    "name": "isEmailTemplateFilter",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "dataType": {
                    "name": "dataType",
                    "isArray": false,
                    "type": {
                        "enum": "FieldDataType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "userFilter": {
                    "name": "userFilter",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "defaultSearchFilter": {
                    "name": "defaultSearchFilter",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "values": {
                    "name": "values",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "ListConfiguration": {
            "name": "ListConfiguration",
            "fields": {
                "fields": {
                    "name": "fields",
                    "isArray": true,
                    "type": {
                        "nonModel": "ListConfigurationField"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "sort": {
                    "name": "sort",
                    "isArray": false,
                    "type": {
                        "nonModel": "ListConfigurationSort"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ListConfigurationField": {
            "name": "ListConfigurationField",
            "fields": {
                "fieldName": {
                    "name": "fieldName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "header": {
                    "name": "header",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "width": {
                    "name": "width",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ListConfigurationSort": {
            "name": "ListConfigurationSort",
            "fields": {
                "field": {
                    "name": "field",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "isAsc": {
                    "name": "isAsc",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "TenantConfig": {
            "name": "TenantConfig",
            "fields": {
                "requiredSlidesHiddenMessage": {
                    "name": "requiredSlidesHiddenMessage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "S3Object": {
            "name": "S3Object",
            "fields": {
                "bucket": {
                    "name": "bucket",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "region": {
                    "name": "region",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "url": {
                    "name": "url",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Page": {
            "name": "Page",
            "fields": {
                "pageId": {
                    "name": "pageId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "number": {
                    "name": "number",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "srcId": {
                    "name": "srcId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "srcHash": {
                    "name": "srcHash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "isRequired": {
                    "name": "isRequired",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "speakerNotes": {
                    "name": "speakerNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "PageGroup": {
            "name": "PageGroup",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "pageIds": {
                    "name": "pageIds",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "LabelValue": {
            "name": "LabelValue",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "SemVer": {
            "name": "SemVer",
            "fields": {
                "minor": {
                    "name": "minor",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "major": {
                    "name": "major",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "AssociatedFile": {
            "name": "AssociatedFile",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "isDistributable": {
                    "name": "isDistributable",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isDefault": {
                    "name": "isDefault",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "AssociatedFileType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "attachmentId": {
                    "name": "attachmentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "AssociatedFileStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "DocumentVersionIntegration": {
            "name": "DocumentVersionIntegration",
            "fields": {
                "externalVersionId": {
                    "name": "externalVersionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "timestamp": {
                    "name": "timestamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "VirtualAttendeeLimited": {
            "name": "VirtualAttendeeLimited",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "attendeeKey": {
                    "name": "attendeeKey",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "meetingId": {
                    "name": "meetingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "AttendeeStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "statusDetail": {
                    "name": "statusDetail",
                    "isArray": false,
                    "type": {
                        "enum": "AttendeeStatusDetail"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "phonePin": {
                    "name": "phonePin",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "joinToken": {
                    "name": "joinToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "VirtualCreateSessionOutput": {
            "name": "VirtualCreateSessionOutput",
            "fields": {
                "joinToken": {
                    "name": "joinToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "session": {
                    "name": "session",
                    "isArray": false,
                    "type": {
                        "model": "VirtualSession"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "existingAttendees": {
                    "name": "existingAttendees",
                    "isArray": true,
                    "type": {
                        "model": "VirtualAttendee"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "MeetingLogEntry": {
            "name": "MeetingLogEntry",
            "fields": {
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "msg": {
                    "name": "msg",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "timestamp": {
                    "name": "timestamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "action": {
                    "name": "action",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "param": {
                    "name": "param",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "user": {
                    "name": "user",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "isHost": {
                    "name": "isHost",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DocumentHistoryEntry": {
            "name": "DocumentHistoryEntry",
            "fields": {
                "contentId": {
                    "name": "contentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "contentType": {
                    "name": "contentType",
                    "isArray": false,
                    "type": {
                        "enum": "MeetingContentType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "totalDurationSec": {
                    "name": "totalDurationSec",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "StartRecordingResponse": {
            "name": "StartRecordingResponse",
            "fields": {
                "connectURL": {
                    "name": "connectURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "session": {
                    "name": "session",
                    "isArray": false,
                    "type": {
                        "model": "VirtualSession"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "VirtualAttendeeLeftResponse": {
            "name": "VirtualAttendeeLeftResponse",
            "fields": {
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "AttendeeStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "BookmarkedDoc": {
            "name": "BookmarkedDoc",
            "fields": {
                "docID": {
                    "name": "docID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "SubmitIntegrationResult": {
            "name": "SubmitIntegrationResult",
            "fields": {
                "success": {
                    "name": "success",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "message": {
                    "name": "message",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DocumentIntegration": {
            "name": "DocumentIntegration",
            "fields": {
                "integrationId": {
                    "name": "integrationId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "externalId": {
                    "name": "externalId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "firstSync": {
                    "name": "firstSync",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "lastSync": {
                    "name": "lastSync",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "CustomDeckGroup": {
            "name": "CustomDeckGroup",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "pages": {
                    "name": "pages",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomDeckPage"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "visible": {
                    "name": "visible",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "srcId": {
                    "name": "srcId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "CustomDeckPage": {
            "name": "CustomDeckPage",
            "fields": {
                "pageId": {
                    "name": "pageId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "pageNumber": {
                    "name": "pageNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "documentVersionId": {
                    "name": "documentVersionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ContentPresented": {
            "name": "ContentPresented",
            "fields": {
                "contentId": {
                    "name": "contentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "folderItemId": {
                    "name": "folderItemId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "ContentPresentedStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "contentType": {
                    "name": "contentType",
                    "isArray": false,
                    "type": {
                        "enum": "MeetingContentType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "events": {
                    "name": "events",
                    "isArray": true,
                    "type": {
                        "nonModel": "PagePresentedEvent"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "openedAt": {
                    "name": "openedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "closedAt": {
                    "name": "closedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "PagePresentedEvent": {
            "name": "PagePresentedEvent",
            "fields": {
                "pageNumber": {
                    "name": "pageNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "pageId": {
                    "name": "pageId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "timestamp": {
                    "name": "timestamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "IntegrationClientConfigurationTuple": {
            "name": "IntegrationClientConfigurationTuple",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "IntegrationValueMapping": {
            "name": "IntegrationValueMapping",
            "fields": {
                "srcValue": {
                    "name": "srcValue",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "targetValue": {
                    "name": "targetValue",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "IntegrationFieldMapping": {
            "name": "IntegrationFieldMapping",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "fieldType": {
                    "name": "fieldType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dataType": {
                    "name": "dataType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "targetFieldName": {
                    "name": "targetFieldName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "srcFieldname": {
                    "name": "srcFieldname",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "valueMappings": {
                    "name": "valueMappings",
                    "isArray": true,
                    "type": {
                        "nonModel": "IntegrationValueMapping"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "IntegrationLogEntry": {
            "name": "IntegrationLogEntry",
            "fields": {
                "level": {
                    "name": "level",
                    "isArray": false,
                    "type": {
                        "enum": "LogLevel"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "timestamp": {
                    "name": "timestamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "message": {
                    "name": "message",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "srcDocId": {
                    "name": "srcDocId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "srcDocVersion": {
                    "name": "srcDocVersion",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        }
    },
    "version": "2c2bbcb8eaa53f8ea946b2bbafb35e5e"
};
