import React, { useEffect, useRef, useState } from 'react';
import { DNABox, Select, IndexPath, AlucioChannel } from '@alucio/lux-ui'
import { useAppSettings } from 'src/state/context/AppSettings';
import { Logger } from '@aws-amplify/core';
import { useAllDocumentsInstance } from 'src/state/redux/selector/document';
import ContentProvider, { useContent } from 'src/state/context/ContentProvider/ContentProvider'
import PlayerWrapper from 'src/components/Presentation/PlayerWrapper'
import docQuery from 'src/state/redux/document/query'
import qs from 'qs'
const logger = new Logger('Playground', 'INFO');

const PlaygroundTablet = () => {
  return null
}

const ContentPlayerTester = () => {
  const { initPresentation, activePresentation } = useContent();
  const allDocuments = useAllDocumentsInstance(docQuery.filters.published)
  const [documentId, setDocumentId] = useState<string>();
  const selectedDocRow = allDocuments.findIndex((doc) => doc.model.id === documentId)
  logger.debug('Selected Document ID' + activePresentation?.presentable.id)
  const onDocumentChange = (e) => {
    const doc = allDocuments[e.row]
    setDocumentId(doc.model.id)
    initPresentation(doc.relations.version.latestUsableDocumentVersion, 1)
  }
  const presentationChannel = useRef(AlucioChannel.get(AlucioChannel.commonChannels.PRESENTATION_CHANNEL))
  const playerChannel = useRef(
    AlucioChannel.get(AlucioChannel.commonChannels.PDF_CHANNEL),
  )
  useEffect(() => {
    playerChannel.current.onmessage = (msg) => {
      logger.debug('Got message in Playground for Player', msg)
    }
    presentationChannel.current.onmessage = (msg) => {
      logger.debug('Got message in Playground for Presentation', msg)
    }
  }, [])
  return (
    <DNABox appearance="col" fill>
      <DNABox appearance="col">
        <Select
          onSelect={onDocumentChange}
          value={allDocuments[selectedDocRow]?.relations?.version?.latestUsableDocumentVersion?.model?.title}
          selectedIndex={new IndexPath(selectedDocRow)}
        >
          {
            allDocuments.map((document) => {
              return (
                <Select.Item
                  key={document.model.id}
                  title={document.relations.version.latestUsableDocumentVersion.model.title}
                />
              )
            })
          }
        </Select>
      </DNABox>
      <PlayerWrapper isFullWindow={true} meetingId="aaaaaa" mode="INTERACTIVE" />
    </DNABox>
  )
}

const PlaygroundDesktop = () => {
  const qsParam = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  if (qsParam.child) {
    return (
      <DNABox fill>
        <PlayerWrapper
          isFullWindow={true}
          meetingId={qsParam.meetingId?.toString() ?? 'aaaaaa'}
          mode="NON_INTERACTIVE"
        />
      </DNABox>
    )
  } else {
    return (
      <DNABox fill>
        <ContentProvider>
          <DNABox
            appearance="col"
            fill
            style={{ backgroundColor: 'aqua' }}
          >

            {/* IFRAME WRAPPER */}
            <ContentPlayerTester />
          </DNABox>
        </ContentProvider>
      </DNABox>
    )
  }
}

export default () => {
  const appSettings = useAppSettings()

  return (appSettings.deviceMode === 'tablet')
    ? <PlaygroundTablet />
    : <PlaygroundDesktop />
}
