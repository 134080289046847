import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { ContentVariant } from 'src/components/DNA/types'
import { DNABox, DNAButton, DNAIcon, LuxStatus, util } from '@alucio/lux-ui'
import { useAppSettings } from 'src/state/context/AppSettings'

export interface PinButtonProps {
  onPin?: () => void,
  isHidden?: boolean,
  variant?: ContentVariant,
  interactive?: boolean
}

/**
 * Pinned Button
 */
const S = {
  rotatePin: { transform: [{ rotate: '-20deg' }] },
  hidden: { display: 'none' },
}

type VariantPinStyles = Record<ContentVariant, LuxStatus>
const variantPinStyles: VariantPinStyles = {
  default: 'brand',
  search: 'brand',
  virtual: 'subtle',
  customDeck: 'brand',
  publisher: 'brand',
  meetings: 'neutral',
}

export const PinButton: React.FC<PinButtonProps> = (props) => {
  const { onPin, isHidden, variant = 'default', interactive = true } = props
  const { deviceMode } = useAppSettings();
  const isDesktop: boolean = deviceMode === 'desktop';
  const style = util.mergeStyles(props, [S.hidden, isHidden]) as StyleProp<ViewStyle>

  return (
    <DNABox style={{ minWidth: 30 }}>

      {interactive
        ? <DNAButton
            appearance="ghostAlt"
            size="large"
            context={isDesktop ? 'minimum' : 'roundTablet'}
            iconLeft="pin"
            style={{ width:32, height:32 }}
            status={variantPinStyles[variant]}
            onPress={onPin}
            iconStyle={style}
        />
        : <DNAIcon.Styled
            appearance="ghostAlt"
            status={variantPinStyles[variant]}
            size="large"
            context="minimum"
            name="pin"
            style={style}
        />}
    </DNABox>
  )
}

export default PinButton
