import React, { useState, useEffect } from 'react'
import { Image, ImageProps } from 'react-native'
import {
  DNABox,
  Iffy,
  responsive,
  util,
  useSafePromise, Stack, DNAText,
} from '@alucio/lux-ui'
import { getImageFromCloudfront } from 'src/utils/loadCloudfrontAsset/common';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

export interface DNAThumbnailProps extends Omit<ImageProps, 'source'> {
  useLoadingIndicator?: boolean,
  s3URL?: string,
  children?: never,
  overlayText?: string,
}

export type imageURI = {
  blob: string,
  width: number,
  height: number,
  ratio: number,
}

export enum FetchStatus {
  PENDING,
  RESOLVED,
  REJECTED,
}

export const DNAThumbnail: React.FC<DNAThumbnailProps> = (props) => {
  const { s3URL, useLoadingIndicator = true, height, width, overlayText, ...rest } = props
  const [imageURI, setImageURI] = useState<imageURI>()
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>()

  const safePromise = useSafePromise()

  useEffect(() => {
    async function fetchImage() {
      if (!s3URL) {
        setFetchStatus(FetchStatus.REJECTED)
        return;
      }

      try {
        setFetchStatus(FetchStatus.PENDING)
        const blob = await safePromise.makeSafe(getImageFromCloudfront(s3URL))
        const fetchDimensions = () =>
          new Promise<{ width: number, height: number}>(
            (resolve, reject) => (
              Image.getSize(
                blob,
                (width, height) => resolve({ width, height }),
                reject,
              )
            ),
          )

        const dimensions = await safePromise.makeSafe(fetchDimensions())
        setImageURI({
          blob,
          ratio: responsive.dimensions.screenWidth / dimensions.width,
          ...dimensions,
        })
        setFetchStatus(FetchStatus.RESOLVED)
        /** TODO: Probably a better way to structure this */
      } catch (err) {
        if (err?.isCanceled) return;

        setImageURI(undefined)
        setFetchStatus(FetchStatus.REJECTED)
      }
    }

    fetchImage()
  }, [s3URL])

  const showLoadingIndicator = (
    (fetchStatus === FetchStatus.PENDING) &&
    useLoadingIndicator
  )

  return (
    <DNABox>
      <Stack anchor="bottom">
        <Stack.Layer>
          <DNABox>
            <Iffy is={showLoadingIndicator}>
              <DNABox style={{ minHeight: height, minWidth: width }} />
            </Iffy>
            <Iffy is={fetchStatus === FetchStatus.RESOLVED}>
              <Image
                { ...rest }
                style={util.mergeStyles(props, { height, width })}
                source={{ uri: imageURI?.blob }}
                resizeMode="center"
              />
            </Iffy>
            <Iffy is={fetchStatus === FetchStatus.REJECTED}>
              <DNABox style={{ minHeight: height, minWidth: width }} />
            </Iffy>
          </DNABox>
        </Stack.Layer>
        <Stack.Layer style={{ width: '100%' }}>
          <Iffy is={overlayText && overlayText !== ''}>
            <DNABox
              style={{
                paddingVertical: 2,
                paddingHorizontal: 4,
                backgroundColor: colors['color-dark-transparent-1000'],
              }}
              fill
            >
              <DNAText status="basic" numberOfLines={2} c1>
                {overlayText}
              </DNAText>
            </DNABox>
          </Iffy>
        </Stack.Layer>
      </Stack>
    </DNABox>
  );
}

export default DNAThumbnail
