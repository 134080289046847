/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type VirtualNotifyUpdateVirtualAttendeeLimitedInput = {
  id: string,
  attendeeKey?: string | null,
  tenantId?: string | null,
  meetingId?: string | null,
  status?: ATTENDEE_STATUS | null,
  statusDetail?: ATTENDEE_STATUS_DETAIL | null,
  name?: string | null,
  phonePin?: string | null,
  joinToken?: string | null,
};

export enum ATTENDEE_STATUS {
  ACCEPTED = "ACCEPTED",
  CONNECTED = "CONNECTED",
  DECLINED = "DECLINED",
  DISCONNECTED = "DISCONNECTED",
  PENDING = "PENDING",
  ENDED = "ENDED",
  REMOVED = "REMOVED",
  LEFT = "LEFT",
}


export enum ATTENDEE_STATUS_DETAIL {
  HOST_ENDED = "HOST_ENDED",
  HOST_DISCONNECTED = "HOST_DISCONNECTED",
}


export type VirtualAttendeeLimited = {
  __typename: "VirtualAttendeeLimited",
  id?: string,
  attendeeKey?: string,
  tenantId?: string,
  meetingId?: string,
  status?: ATTENDEE_STATUS,
  statusDetail?: ATTENDEE_STATUS_DETAIL | null,
  name?: string,
  phonePin?: string | null,
  joinToken?: string | null,
};

export type CreateUserInput = {
  id?: string | null,
  cognitoSubId?: string | null,
  tenantId: string,
  email: string,
  givenName: string,
  phoneNumber?: string | null,
  familyName: string,
  meetingId?: string | null,
  defaultFilterValues: Array< LabelValuesInput >,
  lockedFilters?: Array< LabelValuesInput > | null,
  shareBCC?: Array< string > | null,
  shareCC?: Array< string > | null,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  status?: USER_STATUS | null,
  role: USER_ROLE,
  bookmarkedDocs?: Array< BookmarkedDocInput > | null,
  isExcludedFromReporting?: boolean | null,
  _version?: number | null,
};

export type LabelValuesInput = {
  key: string,
  values: Array< string >,
};

export enum USER_STATUS {
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
}


export enum USER_ROLE {
  AlucioAdmin = "AlucioAdmin",
  TenantAdmin = "TenantAdmin",
  TenantPublisher = "TenantPublisher",
  TenantViewer = "TenantViewer",
}


export type BookmarkedDocInput = {
  docID: string,
  createdAt: string,
};

export type ModelUserConditionInput = {
  cognitoSubId?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  meetingId?: ModelStringInput | null,
  shareBCC?: ModelStringInput | null,
  shareCC?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  status?: ModelUSER_STATUSInput | null,
  role?: ModelUSER_ROLEInput | null,
  isExcludedFromReporting?: ModelBooleanInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelUSER_STATUSInput = {
  eq?: USER_STATUS | null,
  ne?: USER_STATUS | null,
};

export type ModelUSER_ROLEInput = {
  eq?: USER_ROLE | null,
  ne?: USER_ROLE | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type User = {
  __typename: "User",
  id?: string,
  cognitoSubId?: string | null,
  tenantId?: string,
  email?: string,
  givenName?: string,
  phoneNumber?: string | null,
  familyName?: string,
  meetingId?: string | null,
  defaultFilterValues?:  Array<LabelValues >,
  lockedFilters?:  Array<LabelValues > | null,
  shareBCC?: Array< string > | null,
  shareCC?: Array< string > | null,
  createdAt?: string,
  createdBy?: string,
  updatedAt?: string,
  status?: USER_STATUS | null,
  role?: USER_ROLE,
  bookmarkedDocs?:  Array<BookmarkedDoc > | null,
  isExcludedFromReporting?: boolean | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type LabelValues = {
  __typename: "LabelValues",
  key?: string,
  values?: Array< string >,
};

export type BookmarkedDoc = {
  __typename: "BookmarkedDoc",
  docID?: string,
  createdAt?: string,
};

export type UpdateUserInput = {
  id: string,
  cognitoSubId?: string | null,
  tenantId?: string | null,
  email?: string | null,
  givenName?: string | null,
  phoneNumber?: string | null,
  familyName?: string | null,
  meetingId?: string | null,
  defaultFilterValues?: Array< LabelValuesInput > | null,
  lockedFilters?: Array< LabelValuesInput > | null,
  shareBCC?: Array< string > | null,
  shareCC?: Array< string > | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  status?: USER_STATUS | null,
  role?: USER_ROLE | null,
  bookmarkedDocs?: Array< BookmarkedDocInput > | null,
  isExcludedFromReporting?: boolean | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateTenantInput = {
  id?: string | null,
  name: string,
  licensedUsers: number,
  status: TENANT_STATUS,
  fields: Array< FieldConfigInput >,
  folderUpdateGracePeriodDays?: number | null,
  medInfoURL?: string | null,
  publisherListConfig?: ListConfigurationInput | null,
  mslListConfig?: ListConfigurationInput | null,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  statusChangedAt: string,
  statusChangedBy: string,
  ssoDomains?: Array< string | null > | null,
  integrations?: Array< string | null > | null,
  config?: TenantConfigInput | null,
  _version?: number | null,
};

export type FieldConfigInput = {
  fieldName: string,
  description?: string | null,
  required: boolean,
  isEmailTemplateFilter?: boolean | null,
  dataType: FIELD_DATA_TYPE,
  userFilter: boolean,
  defaultSearchFilter: boolean,
  values: Array< string >,
};

export enum FIELD_DATA_TYPE {
  CATEGORICAL = "CATEGORICAL",
  MULTICATEGORICAL = "MULTICATEGORICAL",
  STRING = "STRING",
  DATE = "DATE",
}


export type ModelTenantConditionInput = {
  name?: ModelStringInput | null,
  licensedUsers?: ModelIntInput | null,
  status?: ModelTENANT_STATUSInput | null,
  folderUpdateGracePeriodDays?: ModelIntInput | null,
  medInfoURL?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  statusChangedAt?: ModelStringInput | null,
  statusChangedBy?: ModelStringInput | null,
  ssoDomains?: ModelStringInput | null,
  integrations?: ModelStringInput | null,
  and?: Array< ModelTenantConditionInput | null > | null,
  or?: Array< ModelTenantConditionInput | null > | null,
  not?: ModelTenantConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelTENANT_STATUSInput = {
  eq?: TENANT_STATUS | null,
  ne?: TENANT_STATUS | null,
};

export enum TENANT_STATUS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type Tenant = {
  __typename: "Tenant",
  id?: string,
  name?: string,
  licensedUsers?: number,
  status?: TENANT_STATUS,
  fields?:  Array<FieldConfig >,
  folderUpdateGracePeriodDays?: number | null,
  medInfoURL?: string | null,
  publisherListConfig?: ListConfiguration,
  mslListConfig?: ListConfiguration,
  createdAt?: string,
  createdBy?: string,
  updatedAt?: string,
  statusChangedAt?: string,
  statusChangedBy?: string,
  ssoDomains?: Array< string | null > | null,
  integrations?: Array< string | null > | null,
  config?: TenantConfig,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type FieldConfig = {
  __typename: "FieldConfig",
  fieldName?: string,
  description?: string | null,
  required?: boolean,
  isEmailTemplateFilter?: boolean | null,
  dataType?: FIELD_DATA_TYPE,
  userFilter?: boolean,
  defaultSearchFilter?: boolean,
  values?: Array< string >,
};

export type ListConfiguration = {
  __typename: "ListConfiguration",
  fields?:  Array<ListConfigurationField >,
  sort?: ListConfigurationSort,
};

export type ListConfigurationField = {
  __typename: "ListConfigurationField",
  fieldName?: string,
  header?: string | null,
  width?: number | null,
};

export type ListConfigurationSort = {
  __typename: "ListConfigurationSort",
  field?: string,
  isAsc?: boolean,
};

export type TenantConfig = {
  __typename: "TenantConfig",
  requiredSlidesHiddenMessage?: string | null,
};

export type UpdateTenantInput = {
  id: string,
  name?: string | null,
  licensedUsers?: number | null,
  status?: TENANT_STATUS | null,
  fields?: Array< FieldConfigInput > | null,
  folderUpdateGracePeriodDays?: number | null,
  medInfoURL?: string | null,
  publisherListConfig?: ListConfigurationInput | null,
  mslListConfig?: ListConfigurationInput | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  statusChangedAt?: string | null,
  statusChangedBy?: string | null,
  ssoDomains?: Array< string | null > | null,
  integrations?: Array< string | null > | null,
  config?: TenantConfigInput | null,
  _version?: number | null,
};

export type ListConfigurationInput = {
  fields: Array< ListConfigurationFieldInput >,
  sort?: ListConfigurationSortInput | null,
};

export type ListConfigurationFieldInput = {
  fieldName: string,
  header?: string | null,
  width?: number | null,
};

export type ListConfigurationSortInput = {
  field: string,
  isAsc: boolean,
};

export type TenantConfigInput = {
  requiredSlidesHiddenMessage?: string | null,
};

export type DeleteTenantInput = {
  id: string,
  _version?: number | null,
};

export type CreateDocumentVersionInput = {
  id?: string | null,
  tenantId: string,
  documentId: string,
  versionNumber: number,
  srcFilename: string,
  conversionStatus: CONVERSION_STATUS,
  status: DOCUMENT_STATUS,
  srcFile: S3ObjectInput,
  srcHash?: string | null,
  srcSize: number,
  pages: Array< PageInput >,
  pageGroups?: Array< PageGroupInput > | null,
  type: FILE_TYPE,
  releaseNotes?: string | null,
  changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
  labelValues?: Array< LabelValueInput > | null,
  title?: string | null,
  shortDescription?: string | null,
  longDescription?: string | null,
  owner?: string | null,
  expiresAt?: string | null,
  hasCopyright?: boolean | null,
  purpose?: string | null,
  canHideSlides?: boolean | null,
  distributable?: boolean | null,
  downloadable?: boolean | null,
  isInternalGenerated?: boolean | null,
  semVer?: SemVerInput | null,
  notificationScope?: NOTIFICATION_SCOPE | null,
  selectedThumbnail?: number | null,
  publishedAt?: string | null,
  uploadedAt?: string | null,
  uploadedBy?: string | null,
  convertedArchiveKey?: string | null,
  convertedArchiveSize?: number | null,
  convertedFolderKey?: string | null,
  associatedFiles?: Array< AssociatedFileInput > | null,
  editPermissions: Array< string >,
  converterVersion?: number | null,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  integration?: DocumentVersionIntegrationInput | null,
  integrationType?: INTEGRATION_TYPE | null,
  _version?: number | null,
};

export enum CONVERSION_STATUS {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  PROCESSED = "PROCESSED",
  ERROR = "ERROR",
}


export enum DOCUMENT_STATUS {
  ARCHIVED = "ARCHIVED",
  REVOKED = "REVOKED",
  NOT_PUBLISHED = "NOT_PUBLISHED",
  PUBLISHED = "PUBLISHED",
  DELETED = "DELETED",
  ARCHIVED_WITH_VERSIONS = "ARCHIVED_WITH_VERSIONS",
  REVOKED_WITH_VERSIONS = "REVOKED_WITH_VERSIONS",
  DELETED_WITH_VERSIONS = "DELETED_WITH_VERSIONS",
}


export type S3ObjectInput = {
  bucket: string,
  region: string,
  key: string,
  url: string,
};

export type PageInput = {
  pageId: string,
  number: number,
  srcId?: string | null,
  srcHash: string,
  isRequired?: boolean | null,
  speakerNotes?: string | null,
};

export type PageGroupInput = {
  id?: string | null,
  pageIds?: Array< string > | null,
};

export enum FILE_TYPE {
  JPG = "JPG",
  JPEG = "JPEG",
  XLS = "XLS",
  XLSX = "XLSX",
  XLSM = "XLSM",
  DOC = "DOC",
  DOCX = "DOCX",
  PDF = "PDF",
  PPTX = "PPTX",
  PNG = "PNG",
  MP4 = "MP4",
}


export enum DOCUMENT_VERSION_CHANGE_TYPE {
  MAJOR = "MAJOR",
  MINOR = "MINOR",
}


export type LabelValueInput = {
  key: string,
  value: string,
};

export type SemVerInput = {
  minor: number,
  major: number,
};

export enum NOTIFICATION_SCOPE {
  NONE = "NONE",
  EMAIL = "EMAIL",
}


export type AssociatedFileInput = {
  id?: string | null,
  isDistributable?: boolean | null,
  isDefault?: boolean | null,
  type: ASSOCIATED_FILE_TYPE,
  attachmentId: string,
  status: ASSOCIATED_FILE_STATUS,
  createdAt?: string | null,
  createdBy: string,
};

export enum ASSOCIATED_FILE_TYPE {
  DOCUMENT = "DOCUMENT",
  ATTACHED_FILE = "ATTACHED_FILE",
}


export enum ASSOCIATED_FILE_STATUS {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}


export type DocumentVersionIntegrationInput = {
  externalVersionId?: string | null,
  version?: string | null,
  timestamp?: string | null,
  srcFileHash?: string | null,
  srcDocumentHash?: string | null,
};

export enum INTEGRATION_TYPE {
  VEEVA = "VEEVA",
  BEACON = "BEACON",
  NONE = "NONE",
}


export type ModelDocumentVersionConditionInput = {
  tenantId?: ModelIDInput | null,
  documentId?: ModelIDInput | null,
  versionNumber?: ModelIntInput | null,
  srcFilename?: ModelStringInput | null,
  conversionStatus?: ModelCONVERSION_STATUSInput | null,
  status?: ModelDOCUMENT_STATUSInput | null,
  srcHash?: ModelStringInput | null,
  srcSize?: ModelFloatInput | null,
  type?: ModelFILE_TYPEInput | null,
  releaseNotes?: ModelStringInput | null,
  changeType?: ModelDOCUMENT_VERSION_CHANGE_TYPEInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  longDescription?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  hasCopyright?: ModelBooleanInput | null,
  purpose?: ModelStringInput | null,
  canHideSlides?: ModelBooleanInput | null,
  distributable?: ModelBooleanInput | null,
  downloadable?: ModelBooleanInput | null,
  isInternalGenerated?: ModelBooleanInput | null,
  notificationScope?: ModelNOTIFICATION_SCOPEInput | null,
  selectedThumbnail?: ModelIntInput | null,
  publishedAt?: ModelStringInput | null,
  uploadedAt?: ModelStringInput | null,
  uploadedBy?: ModelStringInput | null,
  convertedArchiveKey?: ModelStringInput | null,
  convertedArchiveSize?: ModelFloatInput | null,
  convertedFolderKey?: ModelStringInput | null,
  editPermissions?: ModelStringInput | null,
  converterVersion?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  integrationType?: ModelINTEGRATION_TYPEInput | null,
  and?: Array< ModelDocumentVersionConditionInput | null > | null,
  or?: Array< ModelDocumentVersionConditionInput | null > | null,
  not?: ModelDocumentVersionConditionInput | null,
};

export type ModelCONVERSION_STATUSInput = {
  eq?: CONVERSION_STATUS | null,
  ne?: CONVERSION_STATUS | null,
};

export type ModelDOCUMENT_STATUSInput = {
  eq?: DOCUMENT_STATUS | null,
  ne?: DOCUMENT_STATUS | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFILE_TYPEInput = {
  eq?: FILE_TYPE | null,
  ne?: FILE_TYPE | null,
};

export type ModelDOCUMENT_VERSION_CHANGE_TYPEInput = {
  eq?: DOCUMENT_VERSION_CHANGE_TYPE | null,
  ne?: DOCUMENT_VERSION_CHANGE_TYPE | null,
};

export type ModelNOTIFICATION_SCOPEInput = {
  eq?: NOTIFICATION_SCOPE | null,
  ne?: NOTIFICATION_SCOPE | null,
};

export type ModelINTEGRATION_TYPEInput = {
  eq?: INTEGRATION_TYPE | null,
  ne?: INTEGRATION_TYPE | null,
};

export type DocumentVersion = {
  __typename: "DocumentVersion",
  id?: string,
  tenantId?: string,
  documentId?: string,
  versionNumber?: number,
  srcFilename?: string,
  conversionStatus?: CONVERSION_STATUS,
  status?: DOCUMENT_STATUS,
  srcFile?: S3Object,
  srcHash?: string | null,
  srcSize?: number,
  pages?:  Array<Page >,
  pageGroups?:  Array<PageGroup > | null,
  type?: FILE_TYPE,
  releaseNotes?: string | null,
  changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
  labelValues?:  Array<LabelValue > | null,
  title?: string | null,
  shortDescription?: string | null,
  longDescription?: string | null,
  owner?: string | null,
  expiresAt?: string | null,
  hasCopyright?: boolean | null,
  purpose?: string | null,
  canHideSlides?: boolean | null,
  distributable?: boolean | null,
  downloadable?: boolean | null,
  isInternalGenerated?: boolean | null,
  semVer?: SemVer,
  notificationScope?: NOTIFICATION_SCOPE | null,
  selectedThumbnail?: number | null,
  publishedAt?: string | null,
  uploadedAt?: string | null,
  uploadedBy?: string | null,
  convertedArchiveKey?: string | null,
  convertedArchiveSize?: number | null,
  convertedFolderKey?: string | null,
  associatedFiles?:  Array<AssociatedFile > | null,
  editPermissions?: Array< string >,
  converterVersion?: number | null,
  createdAt?: string,
  createdBy?: string,
  updatedAt?: string,
  updatedBy?: string,
  integration?: DocumentVersionIntegration,
  integrationType?: INTEGRATION_TYPE | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type S3Object = {
  __typename: "S3Object",
  bucket?: string,
  region?: string,
  key?: string,
  url?: string,
};

export type Page = {
  __typename: "Page",
  pageId?: string,
  number?: number,
  srcId?: string | null,
  srcHash?: string,
  isRequired?: boolean | null,
  speakerNotes?: string | null,
};

export type PageGroup = {
  __typename: "PageGroup",
  id?: string,
  pageIds?: Array< string > | null,
};

export type LabelValue = {
  __typename: "LabelValue",
  key?: string,
  value?: string,
};

export type SemVer = {
  __typename: "SemVer",
  minor?: number,
  major?: number,
};

export type AssociatedFile = {
  __typename: "AssociatedFile",
  id?: string,
  isDistributable?: boolean | null,
  isDefault?: boolean | null,
  type?: ASSOCIATED_FILE_TYPE,
  attachmentId?: string,
  status?: ASSOCIATED_FILE_STATUS,
  createdAt?: string,
  createdBy?: string,
};

export type DocumentVersionIntegration = {
  __typename: "DocumentVersionIntegration",
  externalVersionId?: string | null,
  version?: string | null,
  timestamp?: string | null,
  srcFileHash?: string | null,
  srcDocumentHash?: string | null,
};

export type UpdateDocumentVersionInput = {
  id: string,
  tenantId?: string | null,
  documentId?: string | null,
  versionNumber?: number | null,
  srcFilename?: string | null,
  conversionStatus?: CONVERSION_STATUS | null,
  status?: DOCUMENT_STATUS | null,
  srcFile?: S3ObjectInput | null,
  srcHash?: string | null,
  srcSize?: number | null,
  pages?: Array< PageInput > | null,
  pageGroups?: Array< PageGroupInput > | null,
  type?: FILE_TYPE | null,
  releaseNotes?: string | null,
  changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
  labelValues?: Array< LabelValueInput > | null,
  title?: string | null,
  shortDescription?: string | null,
  longDescription?: string | null,
  owner?: string | null,
  expiresAt?: string | null,
  hasCopyright?: boolean | null,
  purpose?: string | null,
  canHideSlides?: boolean | null,
  distributable?: boolean | null,
  downloadable?: boolean | null,
  isInternalGenerated?: boolean | null,
  semVer?: SemVerInput | null,
  notificationScope?: NOTIFICATION_SCOPE | null,
  selectedThumbnail?: number | null,
  publishedAt?: string | null,
  uploadedAt?: string | null,
  uploadedBy?: string | null,
  convertedArchiveKey?: string | null,
  convertedArchiveSize?: number | null,
  convertedFolderKey?: string | null,
  associatedFiles?: Array< AssociatedFileInput > | null,
  editPermissions?: Array< string > | null,
  converterVersion?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  integration?: DocumentVersionIntegrationInput | null,
  integrationType?: INTEGRATION_TYPE | null,
  _version?: number | null,
};

export type DeleteDocumentVersionInput = {
  id: string,
  _version?: number | null,
};

export type CreateAttachedFileInput = {
  id?: string | null,
  title: string,
  tenantId: string,
  documentId?: string | null,
  srcFile: S3ObjectInput,
  srcFileName: string,
  srcHash?: string | null,
  srcSize: number,
  type: FILE_TYPE,
  editPermissions: Array< string >,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelAttachedFileConditionInput = {
  title?: ModelStringInput | null,
  tenantId?: ModelStringInput | null,
  documentId?: ModelIDInput | null,
  srcFileName?: ModelStringInput | null,
  srcHash?: ModelStringInput | null,
  srcSize?: ModelFloatInput | null,
  type?: ModelFILE_TYPEInput | null,
  editPermissions?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAttachedFileConditionInput | null > | null,
  or?: Array< ModelAttachedFileConditionInput | null > | null,
  not?: ModelAttachedFileConditionInput | null,
};

export type AttachedFile = {
  __typename: "AttachedFile",
  id?: string,
  title?: string,
  tenantId?: string,
  documentId?: string | null,
  srcFile?: S3Object,
  srcFileName?: string,
  srcHash?: string | null,
  srcSize?: number,
  type?: FILE_TYPE,
  editPermissions?: Array< string >,
  createdAt?: string,
  createdBy?: string,
  updatedAt?: string,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type UpdateAttachedFileInput = {
  id: string,
  title?: string | null,
  tenantId?: string | null,
  documentId?: string | null,
  srcFile?: S3ObjectInput | null,
  srcFileName?: string | null,
  srcHash?: string | null,
  srcSize?: number | null,
  type?: FILE_TYPE | null,
  editPermissions?: Array< string > | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteAttachedFileInput = {
  id: string,
  _version?: number | null,
};

export type CreateDocumentInput = {
  id?: string | null,
  tenantId: string,
  accessLevel: DOCUMENT_ACCESS_LEVEL,
  status: DOCUMENT_STATUS,
  owner: string,
  expiresAt?: string | null,
  integration?: DocumentIntegrationInput | null,
  integrationType?: INTEGRATION_TYPE | null,
  type: FILE_TYPE,
  editPermissions?: Array< string > | null,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  _version?: number | null,
};

export enum DOCUMENT_ACCESS_LEVEL {
  TENANT = "TENANT",
  USER = "USER",
}


export type DocumentIntegrationInput = {
  integrationId?: string | null,
  externalId?: string | null,
  firstSync?: string | null,
  lastSync?: string | null,
};

export type ModelDocumentConditionInput = {
  tenantId?: ModelIDInput | null,
  accessLevel?: ModelDOCUMENT_ACCESS_LEVELInput | null,
  status?: ModelDOCUMENT_STATUSInput | null,
  owner?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  integrationType?: ModelINTEGRATION_TYPEInput | null,
  type?: ModelFILE_TYPEInput | null,
  editPermissions?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDocumentConditionInput | null > | null,
  or?: Array< ModelDocumentConditionInput | null > | null,
  not?: ModelDocumentConditionInput | null,
};

export type ModelDOCUMENT_ACCESS_LEVELInput = {
  eq?: DOCUMENT_ACCESS_LEVEL | null,
  ne?: DOCUMENT_ACCESS_LEVEL | null,
};

export type Document = {
  __typename: "Document",
  id?: string,
  tenantId?: string,
  accessLevel?: DOCUMENT_ACCESS_LEVEL,
  status?: DOCUMENT_STATUS,
  owner?: string,
  expiresAt?: string | null,
  integration?: DocumentIntegration,
  integrationType?: INTEGRATION_TYPE | null,
  type?: FILE_TYPE,
  editPermissions?: Array< string > | null,
  createdAt?: string,
  createdBy?: string,
  updatedAt?: string,
  updatedBy?: string,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type DocumentIntegration = {
  __typename: "DocumentIntegration",
  integrationId?: string | null,
  externalId?: string | null,
  firstSync?: string | null,
  lastSync?: string | null,
};

export type UpdateDocumentInput = {
  id: string,
  tenantId?: string | null,
  accessLevel?: DOCUMENT_ACCESS_LEVEL | null,
  status?: DOCUMENT_STATUS | null,
  owner?: string | null,
  expiresAt?: string | null,
  integration?: DocumentIntegrationInput | null,
  integrationType?: INTEGRATION_TYPE | null,
  type?: FILE_TYPE | null,
  editPermissions?: Array< string > | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  _version?: number | null,
};

export type DeleteDocumentInput = {
  id: string,
  _version?: number | null,
};

export type CreateEmailTemplateInput = {
  id?: string | null,
  tenantId: string,
  labelValues?: Array< LabelValueInput > | null,
  subject?: string | null,
  title?: string | null,
  cc?: Array< string > | null,
  bcc?: Array< string > | null,
  body?: string | null,
  associatedFiles?: Array< AssociatedFileInput > | null,
  status: EMAIL_TEMPLATE_STATUS,
  editPermissions?: Array< string > | null,
  createdBy: string,
  updatedBy: string,
  _version?: number | null,
};

export enum EMAIL_TEMPLATE_STATUS {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
  REMOVED = "REMOVED",
}


export type ModelEmailTemplateConditionInput = {
  tenantId?: ModelIDInput | null,
  subject?: ModelStringInput | null,
  title?: ModelStringInput | null,
  cc?: ModelStringInput | null,
  bcc?: ModelStringInput | null,
  body?: ModelStringInput | null,
  status?: ModelEMAIL_TEMPLATE_STATUSInput | null,
  editPermissions?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEmailTemplateConditionInput | null > | null,
  or?: Array< ModelEmailTemplateConditionInput | null > | null,
  not?: ModelEmailTemplateConditionInput | null,
};

export type ModelEMAIL_TEMPLATE_STATUSInput = {
  eq?: EMAIL_TEMPLATE_STATUS | null,
  ne?: EMAIL_TEMPLATE_STATUS | null,
};

export type EmailTemplate = {
  __typename: "EmailTemplate",
  id?: string,
  tenantId?: string,
  labelValues?:  Array<LabelValue > | null,
  subject?: string | null,
  title?: string | null,
  cc?: Array< string > | null,
  bcc?: Array< string > | null,
  body?: string | null,
  associatedFiles?:  Array<AssociatedFile > | null,
  status?: EMAIL_TEMPLATE_STATUS,
  editPermissions?: Array< string > | null,
  createdBy?: string,
  updatedBy?: string,
  createdAt?: string,
  updatedAt?: string,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type UpdateEmailTemplateInput = {
  id: string,
  tenantId?: string | null,
  labelValues?: Array< LabelValueInput > | null,
  subject?: string | null,
  title?: string | null,
  cc?: Array< string > | null,
  bcc?: Array< string > | null,
  body?: string | null,
  associatedFiles?: Array< AssociatedFileInput > | null,
  status?: EMAIL_TEMPLATE_STATUS | null,
  editPermissions?: Array< string > | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  _version?: number | null,
};

export type DeleteEmailTemplateInput = {
  id: string,
  _version?: number | null,
};

export type CreateCustomDeckInput = {
  id?: string | null,
  createdAt?: string | null,
  createdBy: string,
  autoUpdateAcknowledgedAt?: string | null,
  updatedAt?: string | null,
  updatedBy: string,
  tenantId: string,
  groups: Array< CustomDeckGroupInput >,
  _version?: number | null,
};

export type CustomDeckGroupInput = {
  id?: string | null,
  pages: Array< CustomDeckPageInput >,
  visible: boolean,
  srcId?: string | null,
};

export type CustomDeckPageInput = {
  pageId: string,
  pageNumber: number,
  documentVersionId: string,
};

export type ModelCustomDeckConditionInput = {
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  autoUpdateAcknowledgedAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelCustomDeckConditionInput | null > | null,
  or?: Array< ModelCustomDeckConditionInput | null > | null,
  not?: ModelCustomDeckConditionInput | null,
};

export type CustomDeck = {
  __typename: "CustomDeck",
  id?: string,
  createdAt?: string,
  createdBy?: string,
  autoUpdateAcknowledgedAt?: string | null,
  updatedAt?: string,
  updatedBy?: string,
  tenantId?: string,
  groups?:  Array<CustomDeckGroup >,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type CustomDeckGroup = {
  __typename: "CustomDeckGroup",
  id?: string,
  pages?:  Array<CustomDeckPage >,
  visible?: boolean,
  srcId?: string | null,
};

export type CustomDeckPage = {
  __typename: "CustomDeckPage",
  pageId?: string,
  pageNumber?: number,
  documentVersionId?: string,
};

export type UpdateCustomDeckInput = {
  id: string,
  createdAt?: string | null,
  createdBy?: string | null,
  autoUpdateAcknowledgedAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  tenantId?: string | null,
  groups?: Array< CustomDeckGroupInput > | null,
  _version?: number | null,
};

export type DeleteCustomDeckInput = {
  id: string,
  _version?: number | null,
};

export type CreateFolderInput = {
  id?: string | null,
  tenantId: string,
  name: string,
  isPinned?: boolean | null,
  status: FOLDER_STATUS,
  items: Array< FolderItemInput >,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  sharePermissions?: Array< SharePermissionInput > | null,
  _version?: number | null,
};

export enum FOLDER_STATUS {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  REMOVED = "REMOVED",
}


export type FolderItemInput = {
  id?: string | null,
  type: FOLDER_ITEM_TYPE,
  itemId: string,
  status?: FOLDER_ITEM_STATUS | null,
  addedAt: string,
  updateAcknowledgedAt?: string | null,
  itemLastUpdatedAt: string,
  visiblePages?: Array< number > | null,
  customTitle?: string | null,
};

export enum FOLDER_ITEM_TYPE {
  FOLDER = "FOLDER",
  DOCUMENT_VERSION = "DOCUMENT_VERSION",
  CUSTOM_DECK = "CUSTOM_DECK",
}


export enum FOLDER_ITEM_STATUS {
  ACTIVE = "ACTIVE",
  REMOVED = "REMOVED",
}


export type SharePermissionInput = {
  id?: string | null,
  isDeleted?: boolean | null,
  type: PERMISSION_TYPE,
  targetType: SHARE_TARGET_TYPE,
  targetUsername?: string | null,
  targetLabels?: Array< LabelValuesInput > | null,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  deletedBy?: string | null,
  deletedAt?: string | null,
};

export enum PERMISSION_TYPE {
  VIEW = "VIEW",
}


export enum SHARE_TARGET_TYPE {
  USER = "USER",
  FILTER = "FILTER",
  ALL = "ALL",
}


export type ModelFolderConditionInput = {
  tenantId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  isPinned?: ModelBooleanInput | null,
  status?: ModelFOLDER_STATUSInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFolderConditionInput | null > | null,
  or?: Array< ModelFolderConditionInput | null > | null,
  not?: ModelFolderConditionInput | null,
};

export type ModelFOLDER_STATUSInput = {
  eq?: FOLDER_STATUS | null,
  ne?: FOLDER_STATUS | null,
};

export type Folder = {
  __typename: "Folder",
  id?: string,
  tenantId?: string,
  name?: string,
  isPinned?: boolean | null,
  status?: FOLDER_STATUS,
  items?:  Array<FolderItem >,
  createdAt?: string,
  createdBy?: string,
  updatedAt?: string,
  updatedBy?: string,
  sharePermissions?:  Array<SharePermission > | null,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type FolderItem = {
  __typename: "FolderItem",
  id?: string,
  type?: FOLDER_ITEM_TYPE,
  itemId?: string,
  status?: FOLDER_ITEM_STATUS | null,
  addedAt?: string,
  updateAcknowledgedAt?: string | null,
  itemLastUpdatedAt?: string,
  visiblePages?: Array< number > | null,
  customTitle?: string | null,
};

export type SharePermission = {
  __typename: "SharePermission",
  id?: string,
  isDeleted?: boolean | null,
  type?: PERMISSION_TYPE,
  targetType?: SHARE_TARGET_TYPE,
  targetUsername?: string | null,
  targetLabels?:  Array<LabelValues > | null,
  createdAt?: string,
  createdBy?: string,
  updatedAt?: string,
  updatedBy?: string,
  deletedBy?: string | null,
  deletedAt?: string | null,
};

export type UpdateFolderInput = {
  id: string,
  tenantId?: string | null,
  name?: string | null,
  isPinned?: boolean | null,
  status?: FOLDER_STATUS | null,
  items?: Array< FolderItemInput > | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  sharePermissions?: Array< SharePermissionInput > | null,
  _version?: number | null,
};

export type DeleteFolderInput = {
  id: string,
  _version?: number | null,
};

export type CreateContentShareInput = {
  id?: string | null,
  token: string,
  tenantId: string,
  type: SHARE_TYPE,
  contentId: string,
  expiresAt: string,
  createdAt?: string | null,
  createdBy: string,
  _version?: number | null,
};

export enum SHARE_TYPE {
  DOCUMENT_VERSION = "DOCUMENT_VERSION",
  RECORDING = "RECORDING",
  ATTACHED_FILE = "ATTACHED_FILE",
}


export type ModelContentShareConditionInput = {
  token?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  type?: ModelSHARE_TYPEInput | null,
  contentId?: ModelIDInput | null,
  expiresAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  and?: Array< ModelContentShareConditionInput | null > | null,
  or?: Array< ModelContentShareConditionInput | null > | null,
  not?: ModelContentShareConditionInput | null,
};

export type ModelSHARE_TYPEInput = {
  eq?: SHARE_TYPE | null,
  ne?: SHARE_TYPE | null,
};

export type ContentShare = {
  __typename: "ContentShare",
  id?: string,
  token?: string,
  tenantId?: string,
  type?: SHARE_TYPE,
  contentId?: string,
  expiresAt?: string,
  createdAt?: string,
  createdBy?: string,
  updatedAt?: string,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type UpdateContentShareInput = {
  id: string,
  token?: string | null,
  tenantId?: string | null,
  type?: SHARE_TYPE | null,
  contentId?: string | null,
  expiresAt?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  _version?: number | null,
};

export type DeleteContentShareInput = {
  id: string,
  _version?: number | null,
};

export type CreateVirtualSessionInput = {
  id?: string | null,
  tenantId: string,
  meetingId: string,
  roomName: string,
  status: SESSION_STATUS,
  recordingStatus?: RECORDING_STATUS | null,
  recordingTaskId?: string | null,
  hostName: string,
  hostPhonePin: string,
  createdAt?: string | null,
  createdBy: string,
  terminatedAt?: string | null,
  meetingLog?: Array< MeetingLogEntryInput > | null,
  documentHistory?: Array< DocumentHistoryEntryInput > | null,
  _version?: number | null,
};

export enum SESSION_STATUS {
  ACTIVE = "ACTIVE",
  TERMINATED = "TERMINATED",
}


export enum RECORDING_STATUS {
  RECORDING = "RECORDING",
  PROCESSING = "PROCESSING",
  RECORDED = "RECORDED",
  ERROR = "ERROR",
}


export type MeetingLogEntryInput = {
  type?: number | null,
  msg?: string | null,
  timestamp?: string | null,
  action?: number | null,
  param?: string | null,
  user?: string | null,
  isHost?: boolean | null,
};

export type DocumentHistoryEntryInput = {
  contentId: string,
  contentType: MEETING_CONTENT_TYPE,
  totalDurationSec: number,
};

export enum MEETING_CONTENT_TYPE {
  CUSTOM_DECK = "CUSTOM_DECK",
  DOCUMENT_VERSION = "DOCUMENT_VERSION",
}


export type ModelVirtualSessionConditionInput = {
  tenantId?: ModelIDInput | null,
  meetingId?: ModelStringInput | null,
  roomName?: ModelStringInput | null,
  status?: ModelSESSION_STATUSInput | null,
  recordingStatus?: ModelRECORDING_STATUSInput | null,
  recordingTaskId?: ModelStringInput | null,
  hostName?: ModelStringInput | null,
  hostPhonePin?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  terminatedAt?: ModelStringInput | null,
  and?: Array< ModelVirtualSessionConditionInput | null > | null,
  or?: Array< ModelVirtualSessionConditionInput | null > | null,
  not?: ModelVirtualSessionConditionInput | null,
};

export type ModelSESSION_STATUSInput = {
  eq?: SESSION_STATUS | null,
  ne?: SESSION_STATUS | null,
};

export type ModelRECORDING_STATUSInput = {
  eq?: RECORDING_STATUS | null,
  ne?: RECORDING_STATUS | null,
};

export type VirtualSession = {
  __typename: "VirtualSession",
  id?: string,
  tenantId?: string,
  meetingId?: string,
  roomName?: string,
  status?: SESSION_STATUS,
  recordingStatus?: RECORDING_STATUS | null,
  recordingTaskId?: string | null,
  hostName?: string,
  hostPhonePin?: string,
  createdAt?: string,
  createdBy?: string,
  terminatedAt?: string | null,
  meetingLog?:  Array<MeetingLogEntry > | null,
  documentHistory?:  Array<DocumentHistoryEntry > | null,
  attendees?: ModelVirtualAttendeeConnection,
  updatedAt?: string,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type MeetingLogEntry = {
  __typename: "MeetingLogEntry",
  type?: number | null,
  msg?: string | null,
  timestamp?: string | null,
  action?: number | null,
  param?: string | null,
  user?: string | null,
  isHost?: boolean | null,
};

export type DocumentHistoryEntry = {
  __typename: "DocumentHistoryEntry",
  contentId?: string,
  contentType?: MEETING_CONTENT_TYPE,
  totalDurationSec?: number,
};

export type ModelVirtualAttendeeConnection = {
  __typename: "ModelVirtualAttendeeConnection",
  items?:  Array<VirtualAttendee | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type VirtualAttendee = {
  __typename: "VirtualAttendee",
  id?: string,
  attendeeKey?: string,
  tenantId?: string,
  hostId?: string,
  meetingId?: string,
  sessionId?: string | null,
  status?: ATTENDEE_STATUS,
  statusDetail?: ATTENDEE_STATUS_DETAIL | null,
  name?: string,
  phonePin?: string | null,
  clientIP?: string,
  joinToken?: string | null,
  createdAt?: string,
  terminatedAt?: string | null,
  acceptedAt?: string | null,
  lastSeenAt?: string,
  updatedAt?: string,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type UpdateVirtualSessionInput = {
  id: string,
  tenantId?: string | null,
  meetingId?: string | null,
  roomName?: string | null,
  status?: SESSION_STATUS | null,
  recordingStatus?: RECORDING_STATUS | null,
  recordingTaskId?: string | null,
  hostName?: string | null,
  hostPhonePin?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  terminatedAt?: string | null,
  meetingLog?: Array< MeetingLogEntryInput > | null,
  documentHistory?: Array< DocumentHistoryEntryInput > | null,
  _version?: number | null,
};

export type DeleteVirtualSessionInput = {
  id: string,
  _version?: number | null,
};

export type CreateMeetingInput = {
  id?: string | null,
  tenantId: string,
  title?: string | null,
  startTime: string,
  endTime?: string | null,
  contentPresented: Array< ContentPresentedInput >,
  type: MEETING_TYPE,
  notes?: string | null,
  status: MEETING_STATUS,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  _version?: number | null,
};

export type ContentPresentedInput = {
  contentId: string,
  folderItemId?: string | null,
  status: CONTENT_PRESENTED_STATUS,
  contentType: MEETING_CONTENT_TYPE,
  events?: Array< PagePresentedEventInput | null > | null,
  openedAt: string,
  closedAt?: string | null,
};

export enum CONTENT_PRESENTED_STATUS {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}


export type PagePresentedEventInput = {
  pageNumber: number,
  pageId: string,
  timestamp: string,
};

export enum MEETING_TYPE {
  IN_PERSON = "IN_PERSON",
  VIRTUAL = "VIRTUAL",
  MANUAL = "MANUAL",
}


export enum MEETING_STATUS {
  IN_PROGRESS = "IN_PROGRESS",
  ENDED_EXIT = "ENDED_EXIT",
  ENDED_TIMEOUT = "ENDED_TIMEOUT",
  DELETED = "DELETED",
}


export type ModelMeetingConditionInput = {
  tenantId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  type?: ModelMEETING_TYPEInput | null,
  notes?: ModelStringInput | null,
  status?: ModelMEETING_STATUSInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelMeetingConditionInput | null > | null,
  or?: Array< ModelMeetingConditionInput | null > | null,
  not?: ModelMeetingConditionInput | null,
};

export type ModelMEETING_TYPEInput = {
  eq?: MEETING_TYPE | null,
  ne?: MEETING_TYPE | null,
};

export type ModelMEETING_STATUSInput = {
  eq?: MEETING_STATUS | null,
  ne?: MEETING_STATUS | null,
};

export type Meeting = {
  __typename: "Meeting",
  id?: string,
  tenantId?: string,
  title?: string | null,
  startTime?: string,
  endTime?: string | null,
  contentPresented?:  Array<ContentPresented >,
  type?: MEETING_TYPE,
  notes?: string | null,
  status?: MEETING_STATUS,
  createdAt?: string,
  createdBy?: string,
  updatedAt?: string,
  updatedBy?: string,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type ContentPresented = {
  __typename: "ContentPresented",
  contentId?: string,
  folderItemId?: string | null,
  status?: CONTENT_PRESENTED_STATUS,
  contentType?: MEETING_CONTENT_TYPE,
  events?:  Array<PagePresentedEvent | null > | null,
  openedAt?: string,
  closedAt?: string | null,
};

export type PagePresentedEvent = {
  __typename: "PagePresentedEvent",
  pageNumber?: number,
  pageId?: string,
  timestamp?: string,
};

export type UpdateMeetingInput = {
  id: string,
  tenantId?: string | null,
  title?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  contentPresented?: Array< ContentPresentedInput > | null,
  type?: MEETING_TYPE | null,
  notes?: string | null,
  status?: MEETING_STATUS | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  _version?: number | null,
};

export type DeleteMeetingInput = {
  id: string,
  _version?: number | null,
};

export type CreateVirtualAttendeeInput = {
  id?: string | null,
  attendeeKey: string,
  tenantId: string,
  hostId: string,
  meetingId: string,
  sessionId?: string | null,
  status: ATTENDEE_STATUS,
  statusDetail?: ATTENDEE_STATUS_DETAIL | null,
  name: string,
  phonePin?: string | null,
  clientIP: string,
  joinToken?: string | null,
  createdAt?: string | null,
  terminatedAt?: string | null,
  acceptedAt?: string | null,
  lastSeenAt: string,
  _version?: number | null,
};

export type ModelVirtualAttendeeConditionInput = {
  attendeeKey?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  hostId?: ModelIDInput | null,
  meetingId?: ModelIDInput | null,
  sessionId?: ModelIDInput | null,
  status?: ModelATTENDEE_STATUSInput | null,
  statusDetail?: ModelATTENDEE_STATUS_DETAILInput | null,
  name?: ModelStringInput | null,
  phonePin?: ModelStringInput | null,
  clientIP?: ModelStringInput | null,
  joinToken?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  terminatedAt?: ModelStringInput | null,
  acceptedAt?: ModelStringInput | null,
  lastSeenAt?: ModelStringInput | null,
  and?: Array< ModelVirtualAttendeeConditionInput | null > | null,
  or?: Array< ModelVirtualAttendeeConditionInput | null > | null,
  not?: ModelVirtualAttendeeConditionInput | null,
};

export type ModelATTENDEE_STATUSInput = {
  eq?: ATTENDEE_STATUS | null,
  ne?: ATTENDEE_STATUS | null,
};

export type ModelATTENDEE_STATUS_DETAILInput = {
  eq?: ATTENDEE_STATUS_DETAIL | null,
  ne?: ATTENDEE_STATUS_DETAIL | null,
};

export type UpdateVirtualAttendeeInput = {
  id: string,
  attendeeKey?: string | null,
  tenantId?: string | null,
  hostId?: string | null,
  meetingId?: string | null,
  sessionId?: string | null,
  status?: ATTENDEE_STATUS | null,
  statusDetail?: ATTENDEE_STATUS_DETAIL | null,
  name?: string | null,
  phonePin?: string | null,
  clientIP?: string | null,
  joinToken?: string | null,
  createdAt?: string | null,
  terminatedAt?: string | null,
  acceptedAt?: string | null,
  lastSeenAt?: string | null,
  _version?: number | null,
};

export type DeleteVirtualAttendeeInput = {
  id: string,
  _version?: number | null,
};

export type CreateIntegrationLogInput = {
  integrationId: string,
  id?: string | null,
  log: Array< IntegrationLogEntryInput | null >,
  status: INTEGRATION_RUN_STATUS,
  tenantId: string,
  _version?: number | null,
};

export type IntegrationLogEntryInput = {
  level: LOG_LEVEL,
  timestamp: string,
  message: string,
  srcDocId: string,
  srcDocVersion: string,
};

export enum LOG_LEVEL {
  WARNING = "WARNING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  INFO = "INFO",
}


export enum INTEGRATION_RUN_STATUS {
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
  WARNING = "WARNING",
  ERROR = "ERROR",
}


export type ModelIntegrationLogConditionInput = {
  integrationId?: ModelIDInput | null,
  status?: ModelINTEGRATION_RUN_STATUSInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelIntegrationLogConditionInput | null > | null,
  or?: Array< ModelIntegrationLogConditionInput | null > | null,
  not?: ModelIntegrationLogConditionInput | null,
};

export type ModelINTEGRATION_RUN_STATUSInput = {
  eq?: INTEGRATION_RUN_STATUS | null,
  ne?: INTEGRATION_RUN_STATUS | null,
};

export type IntegrationLog = {
  __typename: "IntegrationLog",
  integrationId?: string,
  id?: string,
  log?:  Array<IntegrationLogEntry | null >,
  status?: INTEGRATION_RUN_STATUS,
  tenantId?: string,
  createdAt?: string,
  updatedAt?: string,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type IntegrationLogEntry = {
  __typename: "IntegrationLogEntry",
  level?: LOG_LEVEL,
  timestamp?: string,
  message?: string,
  srcDocId?: string,
  srcDocVersion?: string,
};

export type UpdateIntegrationLogInput = {
  integrationId?: string | null,
  id: string,
  log?: Array< IntegrationLogEntryInput | null > | null,
  status?: INTEGRATION_RUN_STATUS | null,
  tenantId?: string | null,
  _version?: number | null,
};

export type DeleteIntegrationLogInput = {
  id: string,
  _version?: number | null,
};

export type CreateIntegrationSettingsInput = {
  id?: string | null,
  tenantId: string,
  syncContentEvery: number,
  name?: string | null,
  mapping: Array< IntegrationFieldMappingInput | null >,
  clientConfigurationFields: Array< IntegrationClientConfigurationTupleInput | null >,
  integrationType?: INTEGRATION_TYPE | null,
  enabled?: boolean | null,
  notificationEmail?: string | null,
  errorSyncEmail?: string | null,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  _version?: number | null,
};

export type IntegrationFieldMappingInput = {
  key: string,
  fieldType: string,
  dataType: string,
  targetFieldName: string,
  srcFieldname: string,
  valueMappings?: Array< IntegrationValueMappingInput | null > | null,
};

export type IntegrationValueMappingInput = {
  srcValue: string,
  targetValue: string,
};

export type IntegrationClientConfigurationTupleInput = {
  key: string,
  value: string,
};

export type ModelIntegrationSettingsConditionInput = {
  tenantId?: ModelIDInput | null,
  syncContentEvery?: ModelIntInput | null,
  name?: ModelStringInput | null,
  integrationType?: ModelINTEGRATION_TYPEInput | null,
  enabled?: ModelBooleanInput | null,
  notificationEmail?: ModelStringInput | null,
  errorSyncEmail?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelIntegrationSettingsConditionInput | null > | null,
  or?: Array< ModelIntegrationSettingsConditionInput | null > | null,
  not?: ModelIntegrationSettingsConditionInput | null,
};

export type IntegrationSettings = {
  __typename: "IntegrationSettings",
  id?: string,
  tenantId?: string,
  syncContentEvery?: number,
  name?: string | null,
  mapping?:  Array<IntegrationFieldMapping | null >,
  clientConfigurationFields?:  Array<IntegrationClientConfigurationTuple | null >,
  integrationType?: INTEGRATION_TYPE | null,
  enabled?: boolean | null,
  notificationEmail?: string | null,
  errorSyncEmail?: string | null,
  createdAt?: string,
  createdBy?: string,
  updatedAt?: string,
  updatedBy?: string,
  _version?: number,
  _deleted?: boolean | null,
  _lastChangedAt?: number,
};

export type IntegrationFieldMapping = {
  __typename: "IntegrationFieldMapping",
  key?: string,
  fieldType?: string,
  dataType?: string,
  targetFieldName?: string,
  srcFieldname?: string,
  valueMappings?:  Array<IntegrationValueMapping | null > | null,
};

export type IntegrationValueMapping = {
  __typename: "IntegrationValueMapping",
  srcValue?: string,
  targetValue?: string,
};

export type IntegrationClientConfigurationTuple = {
  __typename: "IntegrationClientConfigurationTuple",
  key?: string,
  value?: string,
};

export type UpdateIntegrationSettingsInput = {
  id: string,
  tenantId?: string | null,
  syncContentEvery?: number | null,
  name?: string | null,
  mapping?: Array< IntegrationFieldMappingInput | null > | null,
  clientConfigurationFields?: Array< IntegrationClientConfigurationTupleInput | null > | null,
  integrationType?: INTEGRATION_TYPE | null,
  enabled?: boolean | null,
  notificationEmail?: string | null,
  errorSyncEmail?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  _version?: number | null,
};

export type DeleteIntegrationSettingsInput = {
  id: string,
  _version?: number | null,
};

export type CreateTenantInputLambda = {
  name: string,
  fieldConfig?: Array< FieldConfigInput > | null,
};

export type CreateDocumentFromS3UploadInput = {
  srcFilename: string,
  fileS3Key: string,
};

export type CreateDocumentFromS3UploadEngineInput = {
  srcFilename: string,
  fileS3Key: string,
  tenantId: string,
};

export type CreateDocumentVersionFromS3UploadInput = {
  srcFilename: string,
  fileS3Key: string,
  version: number,
  documentId: string,
  existingVersionId: string,
};

export type CreateDocumentVersionFromS3UploadEngineInput = {
  srcFilename: string,
  documentId: string,
  fileS3Key: string,
  existingVersionId: string,
  tenantId: string,
  updateDocVersion: boolean,
};

export type CreateAttachedFileFromS3UploadInput = {
  entityId: string,
  entityType: ATTACHED_FILE_ENTITY_TYPE,
  srcFilename: string,
  fileS3Key: string,
};

export enum ATTACHED_FILE_ENTITY_TYPE {
  DOCUMENT_VERSION = "DOCUMENT_VERSION",
  EMAIL_TEMPLATE = "EMAIL_TEMPLATE",
}


export type VirtualAttendeeJoinInput = {
  name: string,
  meetingId: string,
};

export type VirtualCreateSessionOutput = {
  __typename: "VirtualCreateSessionOutput",
  joinToken?: string,
  session?: VirtualSession,
  existingAttendees?:  Array<VirtualAttendee > | null,
};

export type StartRecordingResponse = {
  __typename: "StartRecordingResponse",
  connectURL?: string | null,
  session?: VirtualSession,
};

export type VirtualAttendeeLeftResponse = {
  __typename: "VirtualAttendeeLeftResponse",
  status?: ATTENDEE_STATUS,
};

export type VirtualCallStatistics = {
  audio?: VirtualAudioVideoStatistics | null,
  video?: VirtualAudioVideoStatistics | null,
};

export type VirtualAudioVideoStatistics = {
  send?: VirtualMediaStatistics | null,
  recv?: VirtualMediaStatistics | null,
};

export type VirtualMediaStatistics = {
  bandwidthActual?: number | null,
  bandwidthAvailable?: number | null,
  fractionLost?: number | null,
  rtt?: number | null,
  jitter?: number | null,
  overallQuality?: number | null,
};

export type VirtualUpdateSessionMeetingLogInput = {
  logEntries: Array< MeetingLogEntryInput >,
  documentHistory: Array< DocumentHistoryEntryInput >,
  sessionId: string,
};

export type UpdateUserLambdaInput = {
  id: string,
  tenantId?: string | null,
  email?: string | null,
  givenName?: string | null,
  phoneNumber?: string | null,
  familyName?: string | null,
  meetingId?: string | null,
  defaultFilterValues?: Array< LabelValuesInput > | null,
  lockedFilters?: Array< LabelValuesInput > | null,
  shareBCC?: Array< string > | null,
  shareCC?: Array< string > | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  status?: USER_STATUS | null,
  role?: USER_ROLE | null,
  bookmarkedDocs?: Array< BookmarkedDocInput > | null,
  isExcludedFromReporting?: boolean | null,
};

export type DisableUserLambdaInput = {
  id: string,
  status: USER_STATUS,
};

export type CreateUserLambdaInput = {
  tenantId: string,
  email: string,
  givenName: string,
  phoneNumber?: string | null,
  familyName: string,
  defaultFilterValues?: Array< LabelValuesInput > | null,
  lockedFilters?: Array< LabelValuesInput > | null,
  shareBCC?: Array< string > | null,
  shareCC?: Array< string > | null,
  role: USER_ROLE,
  bookmarkedDocs?: Array< BookmarkedDocInput > | null,
  isExcludedFromReporting?: boolean | null,
};

export type RunIntegrationEngineInput = {
  integrationSettingId: string,
};

export type SubmitIntegrationResult = {
  __typename: "SubmitIntegrationResult",
  success?: boolean,
  message?: string | null,
};

export type UpdateTenantLogoInput = {
  tenantId: string,
  key: string,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  cognitoSubId?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  meetingId?: ModelStringInput | null,
  shareBCC?: ModelStringInput | null,
  shareCC?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  status?: ModelUSER_STATUSInput | null,
  role?: ModelUSER_ROLEInput | null,
  isExcludedFromReporting?: ModelBooleanInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items?:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTenantFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  licensedUsers?: ModelIntInput | null,
  status?: ModelTENANT_STATUSInput | null,
  folderUpdateGracePeriodDays?: ModelIntInput | null,
  medInfoURL?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  statusChangedAt?: ModelStringInput | null,
  statusChangedBy?: ModelStringInput | null,
  ssoDomains?: ModelStringInput | null,
  integrations?: ModelStringInput | null,
  and?: Array< ModelTenantFilterInput | null > | null,
  or?: Array< ModelTenantFilterInput | null > | null,
  not?: ModelTenantFilterInput | null,
};

export type ModelTenantConnection = {
  __typename: "ModelTenantConnection",
  items?:  Array<Tenant | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDocumentVersionFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  documentId?: ModelIDInput | null,
  versionNumber?: ModelIntInput | null,
  srcFilename?: ModelStringInput | null,
  conversionStatus?: ModelCONVERSION_STATUSInput | null,
  status?: ModelDOCUMENT_STATUSInput | null,
  srcHash?: ModelStringInput | null,
  srcSize?: ModelFloatInput | null,
  type?: ModelFILE_TYPEInput | null,
  releaseNotes?: ModelStringInput | null,
  changeType?: ModelDOCUMENT_VERSION_CHANGE_TYPEInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  longDescription?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  hasCopyright?: ModelBooleanInput | null,
  purpose?: ModelStringInput | null,
  canHideSlides?: ModelBooleanInput | null,
  distributable?: ModelBooleanInput | null,
  downloadable?: ModelBooleanInput | null,
  isInternalGenerated?: ModelBooleanInput | null,
  notificationScope?: ModelNOTIFICATION_SCOPEInput | null,
  selectedThumbnail?: ModelIntInput | null,
  publishedAt?: ModelStringInput | null,
  uploadedAt?: ModelStringInput | null,
  uploadedBy?: ModelStringInput | null,
  convertedArchiveKey?: ModelStringInput | null,
  convertedArchiveSize?: ModelFloatInput | null,
  convertedFolderKey?: ModelStringInput | null,
  editPermissions?: ModelStringInput | null,
  converterVersion?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  integrationType?: ModelINTEGRATION_TYPEInput | null,
  and?: Array< ModelDocumentVersionFilterInput | null > | null,
  or?: Array< ModelDocumentVersionFilterInput | null > | null,
  not?: ModelDocumentVersionFilterInput | null,
};

export type ModelDocumentVersionConnection = {
  __typename: "ModelDocumentVersionConnection",
  items?:  Array<DocumentVersion | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAttachedFileFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  tenantId?: ModelStringInput | null,
  documentId?: ModelIDInput | null,
  srcFileName?: ModelStringInput | null,
  srcHash?: ModelStringInput | null,
  srcSize?: ModelFloatInput | null,
  type?: ModelFILE_TYPEInput | null,
  editPermissions?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAttachedFileFilterInput | null > | null,
  or?: Array< ModelAttachedFileFilterInput | null > | null,
  not?: ModelAttachedFileFilterInput | null,
};

export type ModelAttachedFileConnection = {
  __typename: "ModelAttachedFileConnection",
  items?:  Array<AttachedFile | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDocumentFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  accessLevel?: ModelDOCUMENT_ACCESS_LEVELInput | null,
  status?: ModelDOCUMENT_STATUSInput | null,
  owner?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  integrationType?: ModelINTEGRATION_TYPEInput | null,
  type?: ModelFILE_TYPEInput | null,
  editPermissions?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDocumentFilterInput | null > | null,
  or?: Array< ModelDocumentFilterInput | null > | null,
  not?: ModelDocumentFilterInput | null,
};

export type ModelDocumentConnection = {
  __typename: "ModelDocumentConnection",
  items?:  Array<Document | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelEmailTemplateFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  subject?: ModelStringInput | null,
  title?: ModelStringInput | null,
  cc?: ModelStringInput | null,
  bcc?: ModelStringInput | null,
  body?: ModelStringInput | null,
  status?: ModelEMAIL_TEMPLATE_STATUSInput | null,
  editPermissions?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEmailTemplateFilterInput | null > | null,
  or?: Array< ModelEmailTemplateFilterInput | null > | null,
  not?: ModelEmailTemplateFilterInput | null,
};

export type ModelEmailTemplateConnection = {
  __typename: "ModelEmailTemplateConnection",
  items?:  Array<EmailTemplate | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCustomDeckFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  autoUpdateAcknowledgedAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelCustomDeckFilterInput | null > | null,
  or?: Array< ModelCustomDeckFilterInput | null > | null,
  not?: ModelCustomDeckFilterInput | null,
};

export type ModelCustomDeckConnection = {
  __typename: "ModelCustomDeckConnection",
  items?:  Array<CustomDeck | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFolderFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  isPinned?: ModelBooleanInput | null,
  status?: ModelFOLDER_STATUSInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFolderFilterInput | null > | null,
  or?: Array< ModelFolderFilterInput | null > | null,
  not?: ModelFolderFilterInput | null,
};

export type ModelFolderConnection = {
  __typename: "ModelFolderConnection",
  items?:  Array<Folder | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelContentShareFilterInput = {
  id?: ModelIDInput | null,
  token?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  type?: ModelSHARE_TYPEInput | null,
  contentId?: ModelIDInput | null,
  expiresAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  and?: Array< ModelContentShareFilterInput | null > | null,
  or?: Array< ModelContentShareFilterInput | null > | null,
  not?: ModelContentShareFilterInput | null,
};

export type ModelContentShareConnection = {
  __typename: "ModelContentShareConnection",
  items?:  Array<ContentShare | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelVirtualSessionFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  meetingId?: ModelStringInput | null,
  roomName?: ModelStringInput | null,
  status?: ModelSESSION_STATUSInput | null,
  recordingStatus?: ModelRECORDING_STATUSInput | null,
  recordingTaskId?: ModelStringInput | null,
  hostName?: ModelStringInput | null,
  hostPhonePin?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  terminatedAt?: ModelStringInput | null,
  and?: Array< ModelVirtualSessionFilterInput | null > | null,
  or?: Array< ModelVirtualSessionFilterInput | null > | null,
  not?: ModelVirtualSessionFilterInput | null,
};

export type ModelVirtualSessionConnection = {
  __typename: "ModelVirtualSessionConnection",
  items?:  Array<VirtualSession | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMeetingFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  type?: ModelMEETING_TYPEInput | null,
  notes?: ModelStringInput | null,
  status?: ModelMEETING_STATUSInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelMeetingFilterInput | null > | null,
  or?: Array< ModelMeetingFilterInput | null > | null,
  not?: ModelMeetingFilterInput | null,
};

export type ModelMeetingConnection = {
  __typename: "ModelMeetingConnection",
  items?:  Array<Meeting | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelVirtualAttendeeFilterInput = {
  id?: ModelIDInput | null,
  attendeeKey?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  hostId?: ModelIDInput | null,
  meetingId?: ModelIDInput | null,
  sessionId?: ModelIDInput | null,
  status?: ModelATTENDEE_STATUSInput | null,
  statusDetail?: ModelATTENDEE_STATUS_DETAILInput | null,
  name?: ModelStringInput | null,
  phonePin?: ModelStringInput | null,
  clientIP?: ModelStringInput | null,
  joinToken?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  terminatedAt?: ModelStringInput | null,
  acceptedAt?: ModelStringInput | null,
  lastSeenAt?: ModelStringInput | null,
  and?: Array< ModelVirtualAttendeeFilterInput | null > | null,
  or?: Array< ModelVirtualAttendeeFilterInput | null > | null,
  not?: ModelVirtualAttendeeFilterInput | null,
};

export type ModelIntegrationLogFilterInput = {
  integrationId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  status?: ModelINTEGRATION_RUN_STATUSInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelIntegrationLogFilterInput | null > | null,
  or?: Array< ModelIntegrationLogFilterInput | null > | null,
  not?: ModelIntegrationLogFilterInput | null,
};

export type ModelIntegrationLogConnection = {
  __typename: "ModelIntegrationLogConnection",
  items?:  Array<IntegrationLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIntegrationSettingsFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  syncContentEvery?: ModelIntInput | null,
  name?: ModelStringInput | null,
  integrationType?: ModelINTEGRATION_TYPEInput | null,
  enabled?: ModelBooleanInput | null,
  notificationEmail?: ModelStringInput | null,
  errorSyncEmail?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelIntegrationSettingsFilterInput | null > | null,
  or?: Array< ModelIntegrationSettingsFilterInput | null > | null,
  not?: ModelIntegrationSettingsFilterInput | null,
};

export type ModelIntegrationSettingsConnection = {
  __typename: "ModelIntegrationSettingsConnection",
  items?:  Array<IntegrationSettings | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type MeetingInfo = {
  __typename: "MeetingInfo",
  meetingId?: string,
  hostGivenName?: string,
  hostFamilyName?: string,
  tenantId?: string,
  hostId?: string | null,
  hostEmail?: string | null,
};

export type ContentTokenOutput = {
  __typename: "ContentTokenOutput",
  token?: string,
  expiresAt?: string,
};

export type ExchangeShareTokenOutput = {
  __typename: "ExchangeShareTokenOutput",
  token?: string,
  key?: string,
  expiresAt?: string,
  fileName?: string,
};

export type ReportList = {
  __typename: "ReportList",
  dashboardId?: string,
  name?: string,
};

export type VirtualNotifyUpdateVirtualAttendeeLimitedMutationVariables = {
  attendee?: VirtualNotifyUpdateVirtualAttendeeLimitedInput,
};

export type VirtualNotifyUpdateVirtualAttendeeLimitedMutation = {
  virtualNotifyUpdateVirtualAttendeeLimited?:  {
    __typename: "VirtualAttendeeLimited",
    id: string,
    attendeeKey: string,
    tenantId: string,
    meetingId: string,
    status: ATTENDEE_STATUS,
    statusDetail?: ATTENDEE_STATUS_DETAIL | null,
    name: string,
    phonePin?: string | null,
    joinToken?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input?: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  input?: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  input?: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTenantMutationVariables = {
  input?: CreateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type CreateTenantMutation = {
  createTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config?:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTenantMutationVariables = {
  input?: UpdateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type UpdateTenantMutation = {
  updateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config?:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTenantMutationVariables = {
  input?: DeleteTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type DeleteTenantMutation = {
  deleteTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config?:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentVersionMutationVariables = {
  input?: CreateDocumentVersionInput,
  condition?: ModelDocumentVersionConditionInput | null,
};

export type CreateDocumentVersionMutation = {
  createDocumentVersion?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename: string,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcHash?: string | null,
    srcSize: number,
    pages:  Array< {
      __typename: "Page",
      pageId: string,
      number: number,
      srcId?: string | null,
      srcHash: string,
      isRequired?: boolean | null,
      speakerNotes?: string | null,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDocumentVersionMutationVariables = {
  input?: UpdateDocumentVersionInput,
  condition?: ModelDocumentVersionConditionInput | null,
};

export type UpdateDocumentVersionMutation = {
  updateDocumentVersion?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename: string,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcHash?: string | null,
    srcSize: number,
    pages:  Array< {
      __typename: "Page",
      pageId: string,
      number: number,
      srcId?: string | null,
      srcHash: string,
      isRequired?: boolean | null,
      speakerNotes?: string | null,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDocumentVersionMutationVariables = {
  input?: DeleteDocumentVersionInput,
  condition?: ModelDocumentVersionConditionInput | null,
};

export type DeleteDocumentVersionMutation = {
  deleteDocumentVersion?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename: string,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcHash?: string | null,
    srcSize: number,
    pages:  Array< {
      __typename: "Page",
      pageId: string,
      number: number,
      srcId?: string | null,
      srcHash: string,
      isRequired?: boolean | null,
      speakerNotes?: string | null,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAttachedFileMutationVariables = {
  input?: CreateAttachedFileInput,
  condition?: ModelAttachedFileConditionInput | null,
};

export type CreateAttachedFileMutation = {
  createAttachedFile?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAttachedFileMutationVariables = {
  input?: UpdateAttachedFileInput,
  condition?: ModelAttachedFileConditionInput | null,
};

export type UpdateAttachedFileMutation = {
  updateAttachedFile?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAttachedFileMutationVariables = {
  input?: DeleteAttachedFileInput,
  condition?: ModelAttachedFileConditionInput | null,
};

export type DeleteAttachedFileMutation = {
  deleteAttachedFile?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentMutationVariables = {
  input?: CreateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type CreateDocumentMutation = {
  createDocument?:  {
    __typename: "Document",
    id: string,
    tenantId: string,
    accessLevel: DOCUMENT_ACCESS_LEVEL,
    status: DOCUMENT_STATUS,
    owner: string,
    expiresAt?: string | null,
    integration?:  {
      __typename: "DocumentIntegration",
      integrationId?: string | null,
      externalId?: string | null,
      firstSync?: string | null,
      lastSync?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    type: FILE_TYPE,
    editPermissions?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDocumentMutationVariables = {
  input?: UpdateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type UpdateDocumentMutation = {
  updateDocument?:  {
    __typename: "Document",
    id: string,
    tenantId: string,
    accessLevel: DOCUMENT_ACCESS_LEVEL,
    status: DOCUMENT_STATUS,
    owner: string,
    expiresAt?: string | null,
    integration?:  {
      __typename: "DocumentIntegration",
      integrationId?: string | null,
      externalId?: string | null,
      firstSync?: string | null,
      lastSync?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    type: FILE_TYPE,
    editPermissions?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDocumentMutationVariables = {
  input?: DeleteDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type DeleteDocumentMutation = {
  deleteDocument?:  {
    __typename: "Document",
    id: string,
    tenantId: string,
    accessLevel: DOCUMENT_ACCESS_LEVEL,
    status: DOCUMENT_STATUS,
    owner: string,
    expiresAt?: string | null,
    integration?:  {
      __typename: "DocumentIntegration",
      integrationId?: string | null,
      externalId?: string | null,
      firstSync?: string | null,
      lastSync?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    type: FILE_TYPE,
    editPermissions?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateEmailTemplateMutationVariables = {
  input?: CreateEmailTemplateInput,
  condition?: ModelEmailTemplateConditionInput | null,
};

export type CreateEmailTemplateMutation = {
  createEmailTemplate?:  {
    __typename: "EmailTemplate",
    id: string,
    tenantId: string,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    subject?: string | null,
    title?: string | null,
    cc?: Array< string > | null,
    bcc?: Array< string > | null,
    body?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    status: EMAIL_TEMPLATE_STATUS,
    editPermissions?: Array< string > | null,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateEmailTemplateMutationVariables = {
  input?: UpdateEmailTemplateInput,
  condition?: ModelEmailTemplateConditionInput | null,
};

export type UpdateEmailTemplateMutation = {
  updateEmailTemplate?:  {
    __typename: "EmailTemplate",
    id: string,
    tenantId: string,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    subject?: string | null,
    title?: string | null,
    cc?: Array< string > | null,
    bcc?: Array< string > | null,
    body?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    status: EMAIL_TEMPLATE_STATUS,
    editPermissions?: Array< string > | null,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteEmailTemplateMutationVariables = {
  input?: DeleteEmailTemplateInput,
  condition?: ModelEmailTemplateConditionInput | null,
};

export type DeleteEmailTemplateMutation = {
  deleteEmailTemplate?:  {
    __typename: "EmailTemplate",
    id: string,
    tenantId: string,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    subject?: string | null,
    title?: string | null,
    cc?: Array< string > | null,
    bcc?: Array< string > | null,
    body?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    status: EMAIL_TEMPLATE_STATUS,
    editPermissions?: Array< string > | null,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCustomDeckMutationVariables = {
  input?: CreateCustomDeckInput,
  condition?: ModelCustomDeckConditionInput | null,
};

export type CreateCustomDeckMutation = {
  createCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      visible: boolean,
      srcId?: string | null,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCustomDeckMutationVariables = {
  input?: UpdateCustomDeckInput,
  condition?: ModelCustomDeckConditionInput | null,
};

export type UpdateCustomDeckMutation = {
  updateCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      visible: boolean,
      srcId?: string | null,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCustomDeckMutationVariables = {
  input?: DeleteCustomDeckInput,
  condition?: ModelCustomDeckConditionInput | null,
};

export type DeleteCustomDeckMutation = {
  deleteCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      visible: boolean,
      srcId?: string | null,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFolderMutationVariables = {
  input?: CreateFolderInput,
  condition?: ModelFolderConditionInput | null,
};

export type CreateFolderMutation = {
  createFolder?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFolderMutationVariables = {
  input?: UpdateFolderInput,
  condition?: ModelFolderConditionInput | null,
};

export type UpdateFolderMutation = {
  updateFolder?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFolderMutationVariables = {
  input?: DeleteFolderInput,
  condition?: ModelFolderConditionInput | null,
};

export type DeleteFolderMutation = {
  deleteFolder?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateContentShareMutationVariables = {
  input?: CreateContentShareInput,
  condition?: ModelContentShareConditionInput | null,
};

export type CreateContentShareMutation = {
  createContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    expiresAt: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateContentShareMutationVariables = {
  input?: UpdateContentShareInput,
  condition?: ModelContentShareConditionInput | null,
};

export type UpdateContentShareMutation = {
  updateContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    expiresAt: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteContentShareMutationVariables = {
  input?: DeleteContentShareInput,
  condition?: ModelContentShareConditionInput | null,
};

export type DeleteContentShareMutation = {
  deleteContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    expiresAt: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateVirtualSessionMutationVariables = {
  input?: CreateVirtualSessionInput,
  condition?: ModelVirtualSessionConditionInput | null,
};

export type CreateVirtualSessionMutation = {
  createVirtualSession?:  {
    __typename: "VirtualSession",
    id: string,
    tenantId: string,
    meetingId: string,
    roomName: string,
    status: SESSION_STATUS,
    recordingStatus?: RECORDING_STATUS | null,
    recordingTaskId?: string | null,
    hostName: string,
    hostPhonePin: string,
    createdAt: string,
    createdBy: string,
    terminatedAt?: string | null,
    meetingLog?:  Array< {
      __typename: "MeetingLogEntry",
      type?: number | null,
      msg?: string | null,
      timestamp?: string | null,
      action?: number | null,
      param?: string | null,
      user?: string | null,
      isHost?: boolean | null,
    } > | null,
    documentHistory?:  Array< {
      __typename: "DocumentHistoryEntry",
      contentId: string,
      contentType: MEETING_CONTENT_TYPE,
      totalDurationSec: number,
    } > | null,
    attendees?:  {
      __typename: "ModelVirtualAttendeeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateVirtualSessionMutationVariables = {
  input?: UpdateVirtualSessionInput,
  condition?: ModelVirtualSessionConditionInput | null,
};

export type UpdateVirtualSessionMutation = {
  updateVirtualSession?:  {
    __typename: "VirtualSession",
    id: string,
    tenantId: string,
    meetingId: string,
    roomName: string,
    status: SESSION_STATUS,
    recordingStatus?: RECORDING_STATUS | null,
    recordingTaskId?: string | null,
    hostName: string,
    hostPhonePin: string,
    createdAt: string,
    createdBy: string,
    terminatedAt?: string | null,
    meetingLog?:  Array< {
      __typename: "MeetingLogEntry",
      type?: number | null,
      msg?: string | null,
      timestamp?: string | null,
      action?: number | null,
      param?: string | null,
      user?: string | null,
      isHost?: boolean | null,
    } > | null,
    documentHistory?:  Array< {
      __typename: "DocumentHistoryEntry",
      contentId: string,
      contentType: MEETING_CONTENT_TYPE,
      totalDurationSec: number,
    } > | null,
    attendees?:  {
      __typename: "ModelVirtualAttendeeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteVirtualSessionMutationVariables = {
  input?: DeleteVirtualSessionInput,
  condition?: ModelVirtualSessionConditionInput | null,
};

export type DeleteVirtualSessionMutation = {
  deleteVirtualSession?:  {
    __typename: "VirtualSession",
    id: string,
    tenantId: string,
    meetingId: string,
    roomName: string,
    status: SESSION_STATUS,
    recordingStatus?: RECORDING_STATUS | null,
    recordingTaskId?: string | null,
    hostName: string,
    hostPhonePin: string,
    createdAt: string,
    createdBy: string,
    terminatedAt?: string | null,
    meetingLog?:  Array< {
      __typename: "MeetingLogEntry",
      type?: number | null,
      msg?: string | null,
      timestamp?: string | null,
      action?: number | null,
      param?: string | null,
      user?: string | null,
      isHost?: boolean | null,
    } > | null,
    documentHistory?:  Array< {
      __typename: "DocumentHistoryEntry",
      contentId: string,
      contentType: MEETING_CONTENT_TYPE,
      totalDurationSec: number,
    } > | null,
    attendees?:  {
      __typename: "ModelVirtualAttendeeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMeetingMutationVariables = {
  input?: CreateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type CreateMeetingMutation = {
  createMeeting?:  {
    __typename: "Meeting",
    id: string,
    tenantId: string,
    title?: string | null,
    startTime: string,
    endTime?: string | null,
    contentPresented:  Array< {
      __typename: "ContentPresented",
      contentId: string,
      folderItemId?: string | null,
      status: CONTENT_PRESENTED_STATUS,
      contentType: MEETING_CONTENT_TYPE,
      openedAt: string,
      closedAt?: string | null,
    } >,
    type: MEETING_TYPE,
    notes?: string | null,
    status: MEETING_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMeetingMutationVariables = {
  input?: UpdateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type UpdateMeetingMutation = {
  updateMeeting?:  {
    __typename: "Meeting",
    id: string,
    tenantId: string,
    title?: string | null,
    startTime: string,
    endTime?: string | null,
    contentPresented:  Array< {
      __typename: "ContentPresented",
      contentId: string,
      folderItemId?: string | null,
      status: CONTENT_PRESENTED_STATUS,
      contentType: MEETING_CONTENT_TYPE,
      openedAt: string,
      closedAt?: string | null,
    } >,
    type: MEETING_TYPE,
    notes?: string | null,
    status: MEETING_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMeetingMutationVariables = {
  input?: DeleteMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type DeleteMeetingMutation = {
  deleteMeeting?:  {
    __typename: "Meeting",
    id: string,
    tenantId: string,
    title?: string | null,
    startTime: string,
    endTime?: string | null,
    contentPresented:  Array< {
      __typename: "ContentPresented",
      contentId: string,
      folderItemId?: string | null,
      status: CONTENT_PRESENTED_STATUS,
      contentType: MEETING_CONTENT_TYPE,
      openedAt: string,
      closedAt?: string | null,
    } >,
    type: MEETING_TYPE,
    notes?: string | null,
    status: MEETING_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateVirtualAttendeeMutationVariables = {
  input?: CreateVirtualAttendeeInput,
  condition?: ModelVirtualAttendeeConditionInput | null,
};

export type CreateVirtualAttendeeMutation = {
  createVirtualAttendee?:  {
    __typename: "VirtualAttendee",
    id: string,
    attendeeKey: string,
    tenantId: string,
    hostId: string,
    meetingId: string,
    sessionId?: string | null,
    status: ATTENDEE_STATUS,
    statusDetail?: ATTENDEE_STATUS_DETAIL | null,
    name: string,
    phonePin?: string | null,
    clientIP: string,
    joinToken?: string | null,
    createdAt: string,
    terminatedAt?: string | null,
    acceptedAt?: string | null,
    lastSeenAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateVirtualAttendeeMutationVariables = {
  input?: UpdateVirtualAttendeeInput,
  condition?: ModelVirtualAttendeeConditionInput | null,
};

export type UpdateVirtualAttendeeMutation = {
  updateVirtualAttendee?:  {
    __typename: "VirtualAttendee",
    id: string,
    attendeeKey: string,
    tenantId: string,
    hostId: string,
    meetingId: string,
    sessionId?: string | null,
    status: ATTENDEE_STATUS,
    statusDetail?: ATTENDEE_STATUS_DETAIL | null,
    name: string,
    phonePin?: string | null,
    clientIP: string,
    joinToken?: string | null,
    createdAt: string,
    terminatedAt?: string | null,
    acceptedAt?: string | null,
    lastSeenAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteVirtualAttendeeMutationVariables = {
  input?: DeleteVirtualAttendeeInput,
  condition?: ModelVirtualAttendeeConditionInput | null,
};

export type DeleteVirtualAttendeeMutation = {
  deleteVirtualAttendee?:  {
    __typename: "VirtualAttendee",
    id: string,
    attendeeKey: string,
    tenantId: string,
    hostId: string,
    meetingId: string,
    sessionId?: string | null,
    status: ATTENDEE_STATUS,
    statusDetail?: ATTENDEE_STATUS_DETAIL | null,
    name: string,
    phonePin?: string | null,
    clientIP: string,
    joinToken?: string | null,
    createdAt: string,
    terminatedAt?: string | null,
    acceptedAt?: string | null,
    lastSeenAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateIntegrationLogMutationVariables = {
  input?: CreateIntegrationLogInput,
  condition?: ModelIntegrationLogConditionInput | null,
};

export type CreateIntegrationLogMutation = {
  createIntegrationLog?:  {
    __typename: "IntegrationLog",
    integrationId: string,
    id: string,
    log:  Array< {
      __typename: "IntegrationLogEntry",
      level: LOG_LEVEL,
      timestamp: string,
      message: string,
      srcDocId: string,
      srcDocVersion: string,
    } | null >,
    status: INTEGRATION_RUN_STATUS,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateIntegrationLogMutationVariables = {
  input?: UpdateIntegrationLogInput,
  condition?: ModelIntegrationLogConditionInput | null,
};

export type UpdateIntegrationLogMutation = {
  updateIntegrationLog?:  {
    __typename: "IntegrationLog",
    integrationId: string,
    id: string,
    log:  Array< {
      __typename: "IntegrationLogEntry",
      level: LOG_LEVEL,
      timestamp: string,
      message: string,
      srcDocId: string,
      srcDocVersion: string,
    } | null >,
    status: INTEGRATION_RUN_STATUS,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteIntegrationLogMutationVariables = {
  input?: DeleteIntegrationLogInput,
  condition?: ModelIntegrationLogConditionInput | null,
};

export type DeleteIntegrationLogMutation = {
  deleteIntegrationLog?:  {
    __typename: "IntegrationLog",
    integrationId: string,
    id: string,
    log:  Array< {
      __typename: "IntegrationLogEntry",
      level: LOG_LEVEL,
      timestamp: string,
      message: string,
      srcDocId: string,
      srcDocVersion: string,
    } | null >,
    status: INTEGRATION_RUN_STATUS,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateIntegrationSettingsMutationVariables = {
  input?: CreateIntegrationSettingsInput,
  condition?: ModelIntegrationSettingsConditionInput | null,
};

export type CreateIntegrationSettingsMutation = {
  createIntegrationSettings?:  {
    __typename: "IntegrationSettings",
    id: string,
    tenantId: string,
    syncContentEvery: number,
    name?: string | null,
    mapping:  Array< {
      __typename: "IntegrationFieldMapping",
      key: string,
      fieldType: string,
      dataType: string,
      targetFieldName: string,
      srcFieldname: string,
    } | null >,
    clientConfigurationFields:  Array< {
      __typename: "IntegrationClientConfigurationTuple",
      key: string,
      value: string,
    } | null >,
    integrationType?: INTEGRATION_TYPE | null,
    enabled?: boolean | null,
    notificationEmail?: string | null,
    errorSyncEmail?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateIntegrationSettingsMutationVariables = {
  input?: UpdateIntegrationSettingsInput,
  condition?: ModelIntegrationSettingsConditionInput | null,
};

export type UpdateIntegrationSettingsMutation = {
  updateIntegrationSettings?:  {
    __typename: "IntegrationSettings",
    id: string,
    tenantId: string,
    syncContentEvery: number,
    name?: string | null,
    mapping:  Array< {
      __typename: "IntegrationFieldMapping",
      key: string,
      fieldType: string,
      dataType: string,
      targetFieldName: string,
      srcFieldname: string,
    } | null >,
    clientConfigurationFields:  Array< {
      __typename: "IntegrationClientConfigurationTuple",
      key: string,
      value: string,
    } | null >,
    integrationType?: INTEGRATION_TYPE | null,
    enabled?: boolean | null,
    notificationEmail?: string | null,
    errorSyncEmail?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteIntegrationSettingsMutationVariables = {
  input?: DeleteIntegrationSettingsInput,
  condition?: ModelIntegrationSettingsConditionInput | null,
};

export type DeleteIntegrationSettingsMutation = {
  deleteIntegrationSettings?:  {
    __typename: "IntegrationSettings",
    id: string,
    tenantId: string,
    syncContentEvery: number,
    name?: string | null,
    mapping:  Array< {
      __typename: "IntegrationFieldMapping",
      key: string,
      fieldType: string,
      dataType: string,
      targetFieldName: string,
      srcFieldname: string,
    } | null >,
    clientConfigurationFields:  Array< {
      __typename: "IntegrationClientConfigurationTuple",
      key: string,
      value: string,
    } | null >,
    integrationType?: INTEGRATION_TYPE | null,
    enabled?: boolean | null,
    notificationEmail?: string | null,
    errorSyncEmail?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTenantLambdaMutationVariables = {
  tenant?: CreateTenantInputLambda | null,
};

export type CreateTenantLambdaMutation = {
  createTenantLambda?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config?:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentFromS3UploadMutationVariables = {
  inputDoc?: CreateDocumentFromS3UploadInput,
};

export type CreateDocumentFromS3UploadMutation = {
  createDocumentFromS3Upload?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename: string,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcHash?: string | null,
    srcSize: number,
    pages:  Array< {
      __typename: "Page",
      pageId: string,
      number: number,
      srcId?: string | null,
      srcHash: string,
      isRequired?: boolean | null,
      speakerNotes?: string | null,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserDocumentFromS3UploadMutationVariables = {
  inputDoc?: CreateDocumentFromS3UploadInput,
};

export type CreateUserDocumentFromS3UploadMutation = {
  createUserDocumentFromS3Upload?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename: string,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcHash?: string | null,
    srcSize: number,
    pages:  Array< {
      __typename: "Page",
      pageId: string,
      number: number,
      srcId?: string | null,
      srcHash: string,
      isRequired?: boolean | null,
      speakerNotes?: string | null,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentFromS3UploadIntegrationEngineMutationVariables = {
  inputDoc?: CreateDocumentFromS3UploadEngineInput,
};

export type CreateDocumentFromS3UploadIntegrationEngineMutation = {
  createDocumentFromS3UploadIntegrationEngine?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename: string,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcHash?: string | null,
    srcSize: number,
    pages:  Array< {
      __typename: "Page",
      pageId: string,
      number: number,
      srcId?: string | null,
      srcHash: string,
      isRequired?: boolean | null,
      speakerNotes?: string | null,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentVersionFromS3UploadMutationVariables = {
  inputVersion?: CreateDocumentVersionFromS3UploadInput,
};

export type CreateDocumentVersionFromS3UploadMutation = {
  createDocumentVersionFromS3Upload?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename: string,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcHash?: string | null,
    srcSize: number,
    pages:  Array< {
      __typename: "Page",
      pageId: string,
      number: number,
      srcId?: string | null,
      srcHash: string,
      isRequired?: boolean | null,
      speakerNotes?: string | null,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentVersionFromS3UploadIntegrationEngineMutationVariables = {
  inputVersion?: CreateDocumentVersionFromS3UploadEngineInput,
};

export type CreateDocumentVersionFromS3UploadIntegrationEngineMutation = {
  createDocumentVersionFromS3UploadIntegrationEngine?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename: string,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcHash?: string | null,
    srcSize: number,
    pages:  Array< {
      __typename: "Page",
      pageId: string,
      number: number,
      srcId?: string | null,
      srcHash: string,
      isRequired?: boolean | null,
      speakerNotes?: string | null,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentVersionFromExistingMutationVariables = {
  documentId?: string,
  newVersionNumber?: number,
  existingVersionId?: string,
};

export type CreateDocumentVersionFromExistingMutation = {
  createDocumentVersionFromExisting?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename: string,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcHash?: string | null,
    srcSize: number,
    pages:  Array< {
      __typename: "Page",
      pageId: string,
      number: number,
      srcId?: string | null,
      srcHash: string,
      isRequired?: boolean | null,
      speakerNotes?: string | null,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentVersionFromExistingIntegrationEngineMutationVariables = {
  documentId?: string,
  newVersionNumber?: number,
  existingVersionId?: string,
  tenantId?: string,
  email?: string,
};

export type CreateDocumentVersionFromExistingIntegrationEngineMutation = {
  createDocumentVersionFromExistingIntegrationEngine?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename: string,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcHash?: string | null,
    srcSize: number,
    pages:  Array< {
      __typename: "Page",
      pageId: string,
      number: number,
      srcId?: string | null,
      srcHash: string,
      isRequired?: boolean | null,
      speakerNotes?: string | null,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAttachedFileFromS3UploadMutationVariables = {
  inputAttached?: CreateAttachedFileFromS3UploadInput,
};

export type CreateAttachedFileFromS3UploadMutation = {
  createAttachedFileFromS3Upload?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DownloadDocumentVersionAsPdfFileMutationVariables = {
  documentVersionId?: string,
};

export type DownloadDocumentVersionAsPdfFileMutation = {
  downloadDocumentVersionAsPdfFile?: string | null,
};

export type DownloadCustomDeckAsPdfFileMutationVariables = {
  customDeckId?: string,
};

export type DownloadCustomDeckAsPdfFileMutation = {
  downloadCustomDeckAsPdfFile?: string | null,
};

export type VirtualAttendeeJoinMutationVariables = {
  params?: VirtualAttendeeJoinInput | null,
};

export type VirtualAttendeeJoinMutation = {
  virtualAttendeeJoin?:  {
    __typename: "VirtualAttendeeLimited",
    id: string,
    attendeeKey: string,
    tenantId: string,
    meetingId: string,
    status: ATTENDEE_STATUS,
    statusDetail?: ATTENDEE_STATUS_DETAIL | null,
    name: string,
    phonePin?: string | null,
    joinToken?: string | null,
  } | null,
};

export type VirtualCreateSessionMutation = {
  virtualCreateSession?:  {
    __typename: "VirtualCreateSessionOutput",
    joinToken: string,
    session:  {
      __typename: "VirtualSession",
      id: string,
      tenantId: string,
      meetingId: string,
      roomName: string,
      status: SESSION_STATUS,
      recordingStatus?: RECORDING_STATUS | null,
      recordingTaskId?: string | null,
      hostName: string,
      hostPhonePin: string,
      createdAt: string,
      createdBy: string,
      terminatedAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    existingAttendees?:  Array< {
      __typename: "VirtualAttendee",
      id: string,
      attendeeKey: string,
      tenantId: string,
      hostId: string,
      meetingId: string,
      sessionId?: string | null,
      status: ATTENDEE_STATUS,
      statusDetail?: ATTENDEE_STATUS_DETAIL | null,
      name: string,
      phonePin?: string | null,
      clientIP: string,
      joinToken?: string | null,
      createdAt: string,
      terminatedAt?: string | null,
      acceptedAt?: string | null,
      lastSeenAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } > | null,
  } | null,
};

export type VirtualTerminateSessionMutationVariables = {
  sessionId?: string,
};

export type VirtualTerminateSessionMutation = {
  virtualTerminateSession?:  {
    __typename: "VirtualSession",
    id: string,
    tenantId: string,
    meetingId: string,
    roomName: string,
    status: SESSION_STATUS,
    recordingStatus?: RECORDING_STATUS | null,
    recordingTaskId?: string | null,
    hostName: string,
    hostPhonePin: string,
    createdAt: string,
    createdBy: string,
    terminatedAt?: string | null,
    meetingLog?:  Array< {
      __typename: "MeetingLogEntry",
      type?: number | null,
      msg?: string | null,
      timestamp?: string | null,
      action?: number | null,
      param?: string | null,
      user?: string | null,
      isHost?: boolean | null,
    } > | null,
    documentHistory?:  Array< {
      __typename: "DocumentHistoryEntry",
      contentId: string,
      contentType: MEETING_CONTENT_TYPE,
      totalDurationSec: number,
    } > | null,
    attendees?:  {
      __typename: "ModelVirtualAttendeeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type VirtualUpdateAttendeeStatusMutationVariables = {
  sessionId?: string,
  attendeeId?: string,
  status?: ATTENDEE_STATUS,
};

export type VirtualUpdateAttendeeStatusMutation = {
  virtualUpdateAttendeeStatus?:  {
    __typename: "VirtualAttendee",
    id: string,
    attendeeKey: string,
    tenantId: string,
    hostId: string,
    meetingId: string,
    sessionId?: string | null,
    status: ATTENDEE_STATUS,
    statusDetail?: ATTENDEE_STATUS_DETAIL | null,
    name: string,
    phonePin?: string | null,
    clientIP: string,
    joinToken?: string | null,
    createdAt: string,
    terminatedAt?: string | null,
    acceptedAt?: string | null,
    lastSeenAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type VirtualStartRecordingMutationVariables = {
  sessionId?: string,
  meetingHostname?: string,
};

export type VirtualStartRecordingMutation = {
  virtualStartRecording?:  {
    __typename: "StartRecordingResponse",
    connectURL?: string | null,
    session?:  {
      __typename: "VirtualSession",
      id: string,
      tenantId: string,
      meetingId: string,
      roomName: string,
      status: SESSION_STATUS,
      recordingStatus?: RECORDING_STATUS | null,
      recordingTaskId?: string | null,
      hostName: string,
      hostPhonePin: string,
      createdAt: string,
      createdBy: string,
      terminatedAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
  } | null,
};

export type VirtualTerminateSessionInternalMutationVariables = {
  sessionId?: string,
  terminationReason?: ATTENDEE_STATUS_DETAIL,
};

export type VirtualTerminateSessionInternalMutation = {
  virtualTerminateSessionInternal?:  {
    __typename: "VirtualSession",
    id: string,
    tenantId: string,
    meetingId: string,
    roomName: string,
    status: SESSION_STATUS,
    recordingStatus?: RECORDING_STATUS | null,
    recordingTaskId?: string | null,
    hostName: string,
    hostPhonePin: string,
    createdAt: string,
    createdBy: string,
    terminatedAt?: string | null,
    meetingLog?:  Array< {
      __typename: "MeetingLogEntry",
      type?: number | null,
      msg?: string | null,
      timestamp?: string | null,
      action?: number | null,
      param?: string | null,
      user?: string | null,
      isHost?: boolean | null,
    } > | null,
    documentHistory?:  Array< {
      __typename: "DocumentHistoryEntry",
      contentId: string,
      contentType: MEETING_CONTENT_TYPE,
      totalDurationSec: number,
    } > | null,
    attendees?:  {
      __typename: "ModelVirtualAttendeeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type VirtualAttendeeNotifyPresenceMutationVariables = {
  attendeeId?: string,
  attendeeKey?: string,
};

export type VirtualAttendeeNotifyPresenceMutation = {
  virtualAttendeeNotifyPresence?:  {
    __typename: "VirtualAttendeeLimited",
    id: string,
    attendeeKey: string,
    tenantId: string,
    meetingId: string,
    status: ATTENDEE_STATUS,
    statusDetail?: ATTENDEE_STATUS_DETAIL | null,
    name: string,
    phonePin?: string | null,
    joinToken?: string | null,
  } | null,
};

export type VirtualAttendeeLeftMutationVariables = {
  attendeeId?: string,
  attendeeKey?: string,
};

export type VirtualAttendeeLeftMutation = {
  virtualAttendeeLeft?:  {
    __typename: "VirtualAttendeeLeftResponse",
    status: ATTENDEE_STATUS,
  } | null,
};

export type VirtualUpdateVideoSubscriptionsMutationVariables = {
  joinToken?: string,
  activeParticipantIds?: Array< string | null > | null,
};

export type VirtualUpdateVideoSubscriptionsMutation = {
  virtualUpdateVideoSubscriptions: number,
};

export type VirtualUpdateCallStatsMutationVariables = {
  joinToken?: string,
  stats?: VirtualCallStatistics | null,
};

export type VirtualUpdateCallStatsMutation = {
  virtualUpdateCallStats: number,
};

export type VirtualUpdateSessionMeetingLogMutationVariables = {
  input?: VirtualUpdateSessionMeetingLogInput,
};

export type VirtualUpdateSessionMeetingLogMutation = {
  virtualUpdateSessionMeetingLog?:  {
    __typename: "VirtualSession",
    id: string,
    tenantId: string,
    meetingId: string,
    roomName: string,
    status: SESSION_STATUS,
    recordingStatus?: RECORDING_STATUS | null,
    recordingTaskId?: string | null,
    hostName: string,
    hostPhonePin: string,
    createdAt: string,
    createdBy: string,
    terminatedAt?: string | null,
    meetingLog?:  Array< {
      __typename: "MeetingLogEntry",
      type?: number | null,
      msg?: string | null,
      timestamp?: string | null,
      action?: number | null,
      param?: string | null,
      user?: string | null,
      isHost?: boolean | null,
    } > | null,
    documentHistory?:  Array< {
      __typename: "DocumentHistoryEntry",
      contentId: string,
      contentType: MEETING_CONTENT_TYPE,
      totalDurationSec: number,
    } > | null,
    attendees?:  {
      __typename: "ModelVirtualAttendeeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GenerateContentShareMutationVariables = {
  type?: SHARE_TYPE,
  contentId?: string,
  expiresInMins?: number,
};

export type GenerateContentShareMutation = {
  generateContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    expiresAt: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserLambdaMutationVariables = {
  user?: UpdateUserLambdaInput | null,
};

export type UpdateUserLambdaMutation = {
  updateUserLambda?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserStatusLambdaMutationVariables = {
  user?: DisableUserLambdaInput | null,
};

export type UpdateUserStatusLambdaMutation = {
  updateUserStatusLambda?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTenantUserMutationVariables = {
  user?: CreateUserLambdaInput | null,
  sendInvite?: boolean | null,
};

export type CreateTenantUserMutation = {
  createTenantUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type SubmitIntegrationRunMutationVariables = {
  input?: RunIntegrationEngineInput | null,
};

export type SubmitIntegrationRunMutation = {
  submitIntegrationRun?:  {
    __typename: "SubmitIntegrationResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type UpdateTenantLogoMutationVariables = {
  input?: UpdateTenantLogoInput | null,
};

export type UpdateTenantLogoMutation = {
  updateTenantLogo?: boolean | null,
};

export type GetUserQueryVariables = {
  id?: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoSubId?: string | null,
      tenantId: string,
      email: string,
      givenName: string,
      phoneNumber?: string | null,
      familyName: string,
      meetingId?: string | null,
      shareBCC?: Array< string > | null,
      shareCC?: Array< string > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      status?: USER_STATUS | null,
      role: USER_ROLE,
      isExcludedFromReporting?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoSubId?: string | null,
      tenantId: string,
      email: string,
      givenName: string,
      phoneNumber?: string | null,
      familyName: string,
      meetingId?: string | null,
      shareBCC?: Array< string > | null,
      shareCC?: Array< string > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      status?: USER_STATUS | null,
      role: USER_ROLE,
      isExcludedFromReporting?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTenantQueryVariables = {
  id?: string,
};

export type GetTenantQuery = {
  getTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config?:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTenantsQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantsQuery = {
  listTenants?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      licensedUsers: number,
      status: TENANT_STATUS,
      folderUpdateGracePeriodDays?: number | null,
      medInfoURL?: string | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      statusChangedAt: string,
      statusChangedBy: string,
      ssoDomains?: Array< string | null > | null,
      integrations?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTenantsQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTenantsQuery = {
  syncTenants?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      licensedUsers: number,
      status: TENANT_STATUS,
      folderUpdateGracePeriodDays?: number | null,
      medInfoURL?: string | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      statusChangedAt: string,
      statusChangedBy: string,
      ssoDomains?: Array< string | null > | null,
      integrations?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDocumentVersionQueryVariables = {
  id?: string,
};

export type GetDocumentVersionQuery = {
  getDocumentVersion?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename: string,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcHash?: string | null,
    srcSize: number,
    pages:  Array< {
      __typename: "Page",
      pageId: string,
      number: number,
      srcId?: string | null,
      srcHash: string,
      isRequired?: boolean | null,
      speakerNotes?: string | null,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDocumentVersionsQueryVariables = {
  filter?: ModelDocumentVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentVersionsQuery = {
  listDocumentVersions?:  {
    __typename: "ModelDocumentVersionConnection",
    items:  Array< {
      __typename: "DocumentVersion",
      id: string,
      tenantId: string,
      documentId: string,
      versionNumber: number,
      srcFilename: string,
      conversionStatus: CONVERSION_STATUS,
      status: DOCUMENT_STATUS,
      srcHash?: string | null,
      srcSize: number,
      type: FILE_TYPE,
      releaseNotes?: string | null,
      changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
      title?: string | null,
      shortDescription?: string | null,
      longDescription?: string | null,
      owner?: string | null,
      expiresAt?: string | null,
      hasCopyright?: boolean | null,
      purpose?: string | null,
      canHideSlides?: boolean | null,
      distributable?: boolean | null,
      downloadable?: boolean | null,
      isInternalGenerated?: boolean | null,
      notificationScope?: NOTIFICATION_SCOPE | null,
      selectedThumbnail?: number | null,
      publishedAt?: string | null,
      uploadedAt?: string | null,
      uploadedBy?: string | null,
      convertedArchiveKey?: string | null,
      convertedArchiveSize?: number | null,
      convertedFolderKey?: string | null,
      editPermissions: Array< string >,
      converterVersion?: number | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      integrationType?: INTEGRATION_TYPE | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDocumentVersionsQueryVariables = {
  filter?: ModelDocumentVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDocumentVersionsQuery = {
  syncDocumentVersions?:  {
    __typename: "ModelDocumentVersionConnection",
    items:  Array< {
      __typename: "DocumentVersion",
      id: string,
      tenantId: string,
      documentId: string,
      versionNumber: number,
      srcFilename: string,
      conversionStatus: CONVERSION_STATUS,
      status: DOCUMENT_STATUS,
      srcHash?: string | null,
      srcSize: number,
      type: FILE_TYPE,
      releaseNotes?: string | null,
      changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
      title?: string | null,
      shortDescription?: string | null,
      longDescription?: string | null,
      owner?: string | null,
      expiresAt?: string | null,
      hasCopyright?: boolean | null,
      purpose?: string | null,
      canHideSlides?: boolean | null,
      distributable?: boolean | null,
      downloadable?: boolean | null,
      isInternalGenerated?: boolean | null,
      notificationScope?: NOTIFICATION_SCOPE | null,
      selectedThumbnail?: number | null,
      publishedAt?: string | null,
      uploadedAt?: string | null,
      uploadedBy?: string | null,
      convertedArchiveKey?: string | null,
      convertedArchiveSize?: number | null,
      convertedFolderKey?: string | null,
      editPermissions: Array< string >,
      converterVersion?: number | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      integrationType?: INTEGRATION_TYPE | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DocumentVersionsByTenantIdStatusQueryVariables = {
  tenantId?: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DocumentVersionsByTenantIdStatusQuery = {
  documentVersionsByTenantIdStatus?:  {
    __typename: "ModelDocumentVersionConnection",
    items:  Array< {
      __typename: "DocumentVersion",
      id: string,
      tenantId: string,
      documentId: string,
      versionNumber: number,
      srcFilename: string,
      conversionStatus: CONVERSION_STATUS,
      status: DOCUMENT_STATUS,
      srcHash?: string | null,
      srcSize: number,
      type: FILE_TYPE,
      releaseNotes?: string | null,
      changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
      title?: string | null,
      shortDescription?: string | null,
      longDescription?: string | null,
      owner?: string | null,
      expiresAt?: string | null,
      hasCopyright?: boolean | null,
      purpose?: string | null,
      canHideSlides?: boolean | null,
      distributable?: boolean | null,
      downloadable?: boolean | null,
      isInternalGenerated?: boolean | null,
      notificationScope?: NOTIFICATION_SCOPE | null,
      selectedThumbnail?: number | null,
      publishedAt?: string | null,
      uploadedAt?: string | null,
      uploadedBy?: string | null,
      convertedArchiveKey?: string | null,
      convertedArchiveSize?: number | null,
      convertedFolderKey?: string | null,
      editPermissions: Array< string >,
      converterVersion?: number | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      integrationType?: INTEGRATION_TYPE | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAttachedFileQueryVariables = {
  id?: string,
};

export type GetAttachedFileQuery = {
  getAttachedFile?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAttachedFilesQueryVariables = {
  filter?: ModelAttachedFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAttachedFilesQuery = {
  listAttachedFiles?:  {
    __typename: "ModelAttachedFileConnection",
    items:  Array< {
      __typename: "AttachedFile",
      id: string,
      title: string,
      tenantId: string,
      documentId?: string | null,
      srcFileName: string,
      srcHash?: string | null,
      srcSize: number,
      type: FILE_TYPE,
      editPermissions: Array< string >,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAttachedFilesQueryVariables = {
  filter?: ModelAttachedFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAttachedFilesQuery = {
  syncAttachedFiles?:  {
    __typename: "ModelAttachedFileConnection",
    items:  Array< {
      __typename: "AttachedFile",
      id: string,
      title: string,
      tenantId: string,
      documentId?: string | null,
      srcFileName: string,
      srcHash?: string | null,
      srcSize: number,
      type: FILE_TYPE,
      editPermissions: Array< string >,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDocumentQueryVariables = {
  id?: string,
};

export type GetDocumentQuery = {
  getDocument?:  {
    __typename: "Document",
    id: string,
    tenantId: string,
    accessLevel: DOCUMENT_ACCESS_LEVEL,
    status: DOCUMENT_STATUS,
    owner: string,
    expiresAt?: string | null,
    integration?:  {
      __typename: "DocumentIntegration",
      integrationId?: string | null,
      externalId?: string | null,
      firstSync?: string | null,
      lastSync?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    type: FILE_TYPE,
    editPermissions?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDocumentsQueryVariables = {
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentsQuery = {
  listDocuments?:  {
    __typename: "ModelDocumentConnection",
    items:  Array< {
      __typename: "Document",
      id: string,
      tenantId: string,
      accessLevel: DOCUMENT_ACCESS_LEVEL,
      status: DOCUMENT_STATUS,
      owner: string,
      expiresAt?: string | null,
      integrationType?: INTEGRATION_TYPE | null,
      type: FILE_TYPE,
      editPermissions?: Array< string > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDocumentsQueryVariables = {
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDocumentsQuery = {
  syncDocuments?:  {
    __typename: "ModelDocumentConnection",
    items:  Array< {
      __typename: "Document",
      id: string,
      tenantId: string,
      accessLevel: DOCUMENT_ACCESS_LEVEL,
      status: DOCUMENT_STATUS,
      owner: string,
      expiresAt?: string | null,
      integrationType?: INTEGRATION_TYPE | null,
      type: FILE_TYPE,
      editPermissions?: Array< string > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetEmailTemplateQueryVariables = {
  id?: string,
};

export type GetEmailTemplateQuery = {
  getEmailTemplate?:  {
    __typename: "EmailTemplate",
    id: string,
    tenantId: string,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    subject?: string | null,
    title?: string | null,
    cc?: Array< string > | null,
    bcc?: Array< string > | null,
    body?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    status: EMAIL_TEMPLATE_STATUS,
    editPermissions?: Array< string > | null,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListEmailTemplatesQueryVariables = {
  filter?: ModelEmailTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmailTemplatesQuery = {
  listEmailTemplates?:  {
    __typename: "ModelEmailTemplateConnection",
    items:  Array< {
      __typename: "EmailTemplate",
      id: string,
      tenantId: string,
      subject?: string | null,
      title?: string | null,
      cc?: Array< string > | null,
      bcc?: Array< string > | null,
      body?: string | null,
      status: EMAIL_TEMPLATE_STATUS,
      editPermissions?: Array< string > | null,
      createdBy: string,
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEmailTemplatesQueryVariables = {
  filter?: ModelEmailTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEmailTemplatesQuery = {
  syncEmailTemplates?:  {
    __typename: "ModelEmailTemplateConnection",
    items:  Array< {
      __typename: "EmailTemplate",
      id: string,
      tenantId: string,
      subject?: string | null,
      title?: string | null,
      cc?: Array< string > | null,
      bcc?: Array< string > | null,
      body?: string | null,
      status: EMAIL_TEMPLATE_STATUS,
      editPermissions?: Array< string > | null,
      createdBy: string,
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCustomDeckQueryVariables = {
  id?: string,
};

export type GetCustomDeckQuery = {
  getCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      visible: boolean,
      srcId?: string | null,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCustomDecksQueryVariables = {
  filter?: ModelCustomDeckFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomDecksQuery = {
  listCustomDecks?:  {
    __typename: "ModelCustomDeckConnection",
    items:  Array< {
      __typename: "CustomDeck",
      id: string,
      createdAt: string,
      createdBy: string,
      autoUpdateAcknowledgedAt?: string | null,
      updatedAt: string,
      updatedBy: string,
      tenantId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCustomDecksQueryVariables = {
  filter?: ModelCustomDeckFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCustomDecksQuery = {
  syncCustomDecks?:  {
    __typename: "ModelCustomDeckConnection",
    items:  Array< {
      __typename: "CustomDeck",
      id: string,
      createdAt: string,
      createdBy: string,
      autoUpdateAcknowledgedAt?: string | null,
      updatedAt: string,
      updatedBy: string,
      tenantId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFolderQueryVariables = {
  id?: string,
};

export type GetFolderQuery = {
  getFolder?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFoldersQueryVariables = {
  filter?: ModelFolderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFoldersQuery = {
  listFolders?:  {
    __typename: "ModelFolderConnection",
    items:  Array< {
      __typename: "Folder",
      id: string,
      tenantId: string,
      name: string,
      isPinned?: boolean | null,
      status: FOLDER_STATUS,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFoldersQueryVariables = {
  filter?: ModelFolderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFoldersQuery = {
  syncFolders?:  {
    __typename: "ModelFolderConnection",
    items:  Array< {
      __typename: "Folder",
      id: string,
      tenantId: string,
      name: string,
      isPinned?: boolean | null,
      status: FOLDER_STATUS,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FoldersByTenantIdCreatedByQueryVariables = {
  tenantId?: string,
  createdBy?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFolderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FoldersByTenantIdCreatedByQuery = {
  foldersByTenantIdCreatedBy?:  {
    __typename: "ModelFolderConnection",
    items:  Array< {
      __typename: "Folder",
      id: string,
      tenantId: string,
      name: string,
      isPinned?: boolean | null,
      status: FOLDER_STATUS,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetContentShareQueryVariables = {
  id?: string,
};

export type GetContentShareQuery = {
  getContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    expiresAt: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListContentSharesQueryVariables = {
  filter?: ModelContentShareFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentSharesQuery = {
  listContentShares?:  {
    __typename: "ModelContentShareConnection",
    items:  Array< {
      __typename: "ContentShare",
      id: string,
      token: string,
      tenantId: string,
      type: SHARE_TYPE,
      contentId: string,
      expiresAt: string,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncContentSharesQueryVariables = {
  filter?: ModelContentShareFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncContentSharesQuery = {
  syncContentShares?:  {
    __typename: "ModelContentShareConnection",
    items:  Array< {
      __typename: "ContentShare",
      id: string,
      token: string,
      tenantId: string,
      type: SHARE_TYPE,
      contentId: string,
      expiresAt: string,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ContentShareByTokenQueryVariables = {
  token?: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentShareFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentShareByTokenQuery = {
  contentShareByToken?:  {
    __typename: "ModelContentShareConnection",
    items:  Array< {
      __typename: "ContentShare",
      id: string,
      token: string,
      tenantId: string,
      type: SHARE_TYPE,
      contentId: string,
      expiresAt: string,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVirtualSessionQueryVariables = {
  id?: string,
};

export type GetVirtualSessionQuery = {
  getVirtualSession?:  {
    __typename: "VirtualSession",
    id: string,
    tenantId: string,
    meetingId: string,
    roomName: string,
    status: SESSION_STATUS,
    recordingStatus?: RECORDING_STATUS | null,
    recordingTaskId?: string | null,
    hostName: string,
    hostPhonePin: string,
    createdAt: string,
    createdBy: string,
    terminatedAt?: string | null,
    meetingLog?:  Array< {
      __typename: "MeetingLogEntry",
      type?: number | null,
      msg?: string | null,
      timestamp?: string | null,
      action?: number | null,
      param?: string | null,
      user?: string | null,
      isHost?: boolean | null,
    } > | null,
    documentHistory?:  Array< {
      __typename: "DocumentHistoryEntry",
      contentId: string,
      contentType: MEETING_CONTENT_TYPE,
      totalDurationSec: number,
    } > | null,
    attendees?:  {
      __typename: "ModelVirtualAttendeeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListVirtualSessionsQueryVariables = {
  filter?: ModelVirtualSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVirtualSessionsQuery = {
  listVirtualSessions?:  {
    __typename: "ModelVirtualSessionConnection",
    items:  Array< {
      __typename: "VirtualSession",
      id: string,
      tenantId: string,
      meetingId: string,
      roomName: string,
      status: SESSION_STATUS,
      recordingStatus?: RECORDING_STATUS | null,
      recordingTaskId?: string | null,
      hostName: string,
      hostPhonePin: string,
      createdAt: string,
      createdBy: string,
      terminatedAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVirtualSessionsQueryVariables = {
  filter?: ModelVirtualSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVirtualSessionsQuery = {
  syncVirtualSessions?:  {
    __typename: "ModelVirtualSessionConnection",
    items:  Array< {
      __typename: "VirtualSession",
      id: string,
      tenantId: string,
      meetingId: string,
      roomName: string,
      status: SESSION_STATUS,
      recordingStatus?: RECORDING_STATUS | null,
      recordingTaskId?: string | null,
      hostName: string,
      hostPhonePin: string,
      createdAt: string,
      createdBy: string,
      terminatedAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SessionsByMeetingIdStatusQueryVariables = {
  meetingId?: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVirtualSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SessionsByMeetingIdStatusQuery = {
  sessionsByMeetingIdStatus?:  {
    __typename: "ModelVirtualSessionConnection",
    items:  Array< {
      __typename: "VirtualSession",
      id: string,
      tenantId: string,
      meetingId: string,
      roomName: string,
      status: SESSION_STATUS,
      recordingStatus?: RECORDING_STATUS | null,
      recordingTaskId?: string | null,
      hostName: string,
      hostPhonePin: string,
      createdAt: string,
      createdBy: string,
      terminatedAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SessionByHostPhonePinStatusQueryVariables = {
  hostPhonePin?: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVirtualSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SessionByHostPhonePinStatusQuery = {
  sessionByHostPhonePinStatus?:  {
    __typename: "ModelVirtualSessionConnection",
    items:  Array< {
      __typename: "VirtualSession",
      id: string,
      tenantId: string,
      meetingId: string,
      roomName: string,
      status: SESSION_STATUS,
      recordingStatus?: RECORDING_STATUS | null,
      recordingTaskId?: string | null,
      hostName: string,
      hostPhonePin: string,
      createdAt: string,
      createdBy: string,
      terminatedAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SessionsByCreatedByCreatedAtQueryVariables = {
  createdBy?: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVirtualSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SessionsByCreatedByCreatedAtQuery = {
  sessionsByCreatedByCreatedAt?:  {
    __typename: "ModelVirtualSessionConnection",
    items:  Array< {
      __typename: "VirtualSession",
      id: string,
      tenantId: string,
      meetingId: string,
      roomName: string,
      status: SESSION_STATUS,
      recordingStatus?: RECORDING_STATUS | null,
      recordingTaskId?: string | null,
      hostName: string,
      hostPhonePin: string,
      createdAt: string,
      createdBy: string,
      terminatedAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMeetingQueryVariables = {
  id?: string,
};

export type GetMeetingQuery = {
  getMeeting?:  {
    __typename: "Meeting",
    id: string,
    tenantId: string,
    title?: string | null,
    startTime: string,
    endTime?: string | null,
    contentPresented:  Array< {
      __typename: "ContentPresented",
      contentId: string,
      folderItemId?: string | null,
      status: CONTENT_PRESENTED_STATUS,
      contentType: MEETING_CONTENT_TYPE,
      openedAt: string,
      closedAt?: string | null,
    } >,
    type: MEETING_TYPE,
    notes?: string | null,
    status: MEETING_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMeetingsQueryVariables = {
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsQuery = {
  listMeetings?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      tenantId: string,
      title?: string | null,
      startTime: string,
      endTime?: string | null,
      type: MEETING_TYPE,
      notes?: string | null,
      status: MEETING_STATUS,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMeetingsQueryVariables = {
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMeetingsQuery = {
  syncMeetings?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      tenantId: string,
      title?: string | null,
      startTime: string,
      endTime?: string | null,
      type: MEETING_TYPE,
      notes?: string | null,
      status: MEETING_STATUS,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AttendemeetingsByCreatedByStartTimeesByHostIdStatusQueryVariables = {
  createdBy?: string,
  startTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AttendemeetingsByCreatedByStartTimeesByHostIdStatusQuery = {
  attendemeetingsByCreatedByStartTimeesByHostIdStatus?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      tenantId: string,
      title?: string | null,
      startTime: string,
      endTime?: string | null,
      type: MEETING_TYPE,
      notes?: string | null,
      status: MEETING_STATUS,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVirtualAttendeeQueryVariables = {
  id?: string,
};

export type GetVirtualAttendeeQuery = {
  getVirtualAttendee?:  {
    __typename: "VirtualAttendee",
    id: string,
    attendeeKey: string,
    tenantId: string,
    hostId: string,
    meetingId: string,
    sessionId?: string | null,
    status: ATTENDEE_STATUS,
    statusDetail?: ATTENDEE_STATUS_DETAIL | null,
    name: string,
    phonePin?: string | null,
    clientIP: string,
    joinToken?: string | null,
    createdAt: string,
    terminatedAt?: string | null,
    acceptedAt?: string | null,
    lastSeenAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListVirtualAttendeesQueryVariables = {
  filter?: ModelVirtualAttendeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVirtualAttendeesQuery = {
  listVirtualAttendees?:  {
    __typename: "ModelVirtualAttendeeConnection",
    items:  Array< {
      __typename: "VirtualAttendee",
      id: string,
      attendeeKey: string,
      tenantId: string,
      hostId: string,
      meetingId: string,
      sessionId?: string | null,
      status: ATTENDEE_STATUS,
      statusDetail?: ATTENDEE_STATUS_DETAIL | null,
      name: string,
      phonePin?: string | null,
      clientIP: string,
      joinToken?: string | null,
      createdAt: string,
      terminatedAt?: string | null,
      acceptedAt?: string | null,
      lastSeenAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVirtualAttendeesQueryVariables = {
  filter?: ModelVirtualAttendeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVirtualAttendeesQuery = {
  syncVirtualAttendees?:  {
    __typename: "ModelVirtualAttendeeConnection",
    items:  Array< {
      __typename: "VirtualAttendee",
      id: string,
      attendeeKey: string,
      tenantId: string,
      hostId: string,
      meetingId: string,
      sessionId?: string | null,
      status: ATTENDEE_STATUS,
      statusDetail?: ATTENDEE_STATUS_DETAIL | null,
      name: string,
      phonePin?: string | null,
      clientIP: string,
      joinToken?: string | null,
      createdAt: string,
      terminatedAt?: string | null,
      acceptedAt?: string | null,
      lastSeenAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AttendeesByHostIdStatusQueryVariables = {
  hostId?: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVirtualAttendeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AttendeesByHostIdStatusQuery = {
  attendeesByHostIdStatus?:  {
    __typename: "ModelVirtualAttendeeConnection",
    items:  Array< {
      __typename: "VirtualAttendee",
      id: string,
      attendeeKey: string,
      tenantId: string,
      hostId: string,
      meetingId: string,
      sessionId?: string | null,
      status: ATTENDEE_STATUS,
      statusDetail?: ATTENDEE_STATUS_DETAIL | null,
      name: string,
      phonePin?: string | null,
      clientIP: string,
      joinToken?: string | null,
      createdAt: string,
      terminatedAt?: string | null,
      acceptedAt?: string | null,
      lastSeenAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AttendeesBySessionIdQueryVariables = {
  sessionId?: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVirtualAttendeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AttendeesBySessionIdQuery = {
  attendeesBySessionId?:  {
    __typename: "ModelVirtualAttendeeConnection",
    items:  Array< {
      __typename: "VirtualAttendee",
      id: string,
      attendeeKey: string,
      tenantId: string,
      hostId: string,
      meetingId: string,
      sessionId?: string | null,
      status: ATTENDEE_STATUS,
      statusDetail?: ATTENDEE_STATUS_DETAIL | null,
      name: string,
      phonePin?: string | null,
      clientIP: string,
      joinToken?: string | null,
      createdAt: string,
      terminatedAt?: string | null,
      acceptedAt?: string | null,
      lastSeenAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AttendeeByPhonePinStatusQueryVariables = {
  phonePin?: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVirtualAttendeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AttendeeByPhonePinStatusQuery = {
  attendeeByPhonePinStatus?:  {
    __typename: "ModelVirtualAttendeeConnection",
    items:  Array< {
      __typename: "VirtualAttendee",
      id: string,
      attendeeKey: string,
      tenantId: string,
      hostId: string,
      meetingId: string,
      sessionId?: string | null,
      status: ATTENDEE_STATUS,
      statusDetail?: ATTENDEE_STATUS_DETAIL | null,
      name: string,
      phonePin?: string | null,
      clientIP: string,
      joinToken?: string | null,
      createdAt: string,
      terminatedAt?: string | null,
      acceptedAt?: string | null,
      lastSeenAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetIntegrationLogQueryVariables = {
  id?: string,
};

export type GetIntegrationLogQuery = {
  getIntegrationLog?:  {
    __typename: "IntegrationLog",
    integrationId: string,
    id: string,
    log:  Array< {
      __typename: "IntegrationLogEntry",
      level: LOG_LEVEL,
      timestamp: string,
      message: string,
      srcDocId: string,
      srcDocVersion: string,
    } | null >,
    status: INTEGRATION_RUN_STATUS,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListIntegrationLogsQueryVariables = {
  filter?: ModelIntegrationLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIntegrationLogsQuery = {
  listIntegrationLogs?:  {
    __typename: "ModelIntegrationLogConnection",
    items:  Array< {
      __typename: "IntegrationLog",
      integrationId: string,
      id: string,
      status: INTEGRATION_RUN_STATUS,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncIntegrationLogsQueryVariables = {
  filter?: ModelIntegrationLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncIntegrationLogsQuery = {
  syncIntegrationLogs?:  {
    __typename: "ModelIntegrationLogConnection",
    items:  Array< {
      __typename: "IntegrationLog",
      integrationId: string,
      id: string,
      status: INTEGRATION_RUN_STATUS,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetIntegrationSettingsQueryVariables = {
  id?: string,
};

export type GetIntegrationSettingsQuery = {
  getIntegrationSettings?:  {
    __typename: "IntegrationSettings",
    id: string,
    tenantId: string,
    syncContentEvery: number,
    name?: string | null,
    mapping:  Array< {
      __typename: "IntegrationFieldMapping",
      key: string,
      fieldType: string,
      dataType: string,
      targetFieldName: string,
      srcFieldname: string,
    } | null >,
    clientConfigurationFields:  Array< {
      __typename: "IntegrationClientConfigurationTuple",
      key: string,
      value: string,
    } | null >,
    integrationType?: INTEGRATION_TYPE | null,
    enabled?: boolean | null,
    notificationEmail?: string | null,
    errorSyncEmail?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListIntegrationSettingsQueryVariables = {
  filter?: ModelIntegrationSettingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIntegrationSettingsQuery = {
  listIntegrationSettings?:  {
    __typename: "ModelIntegrationSettingsConnection",
    items:  Array< {
      __typename: "IntegrationSettings",
      id: string,
      tenantId: string,
      syncContentEvery: number,
      name?: string | null,
      integrationType?: INTEGRATION_TYPE | null,
      enabled?: boolean | null,
      notificationEmail?: string | null,
      errorSyncEmail?: string | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncIntegrationSettingsQueryVariables = {
  filter?: ModelIntegrationSettingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncIntegrationSettingsQuery = {
  syncIntegrationSettings?:  {
    __typename: "ModelIntegrationSettingsConnection",
    items:  Array< {
      __typename: "IntegrationSettings",
      id: string,
      tenantId: string,
      syncContentEvery: number,
      name?: string | null,
      integrationType?: INTEGRATION_TYPE | null,
      enabled?: boolean | null,
      notificationEmail?: string | null,
      errorSyncEmail?: string | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MeetingInfoByMeetingIdQueryVariables = {
  meetingId?: string,
};

export type MeetingInfoByMeetingIdQuery = {
  meetingInfoByMeetingId?:  {
    __typename: "MeetingInfo",
    meetingId: string,
    hostGivenName: string,
    hostFamilyName: string,
    tenantId: string,
    hostId?: string | null,
    hostEmail?: string | null,
  } | null,
};

export type GenerateTokenForContentAccessQueryVariables = {
  documentId?: string,
  documentVersionId?: string,
  authorizedPath?: string,
  durationSeconds?: number,
};

export type GenerateTokenForContentAccessQuery = {
  generateTokenForContentAccess?:  {
    __typename: "ContentTokenOutput",
    token: string,
    expiresAt: string,
  } | null,
};

export type ExchangeContentShareTokenForAccessTokenQueryVariables = {
  token?: string,
};

export type ExchangeContentShareTokenForAccessTokenQuery = {
  exchangeContentShareTokenForAccessToken?:  {
    __typename: "ExchangeShareTokenOutput",
    token: string,
    key: string,
    expiresAt: string,
    fileName: string,
  } | null,
};

export type GetProviderByUserEmailQueryVariables = {
  idpIdentifier?: string,
};

export type GetProviderByUserEmailQuery = {
  getProviderByUserEmail?: boolean | null,
};

export type GetReportListQueryVariables = {
  folderName?: string,
};

export type GetReportListQuery = {
  getReportList?:  Array< {
    __typename: "ReportList",
    dashboardId: string,
    name: string,
  } | null > | null,
};

export type GetEmbeddedUrlQueryVariables = {
  dashboardId?: string,
};

export type GetEmbeddedUrlQuery = {
  getEmbeddedUrl?: string | null,
};

export type GetSharedFoldersQuery = {
  getSharedFolders:  Array< {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null >,
};

export type OnCreateVirtualAttendeeSubscriptionVariables = {
  hostId?: string,
};

export type OnCreateVirtualAttendeeSubscription = {
  onCreateVirtualAttendee?:  {
    __typename: "VirtualAttendee",
    id: string,
    attendeeKey: string,
    tenantId: string,
    hostId: string,
    meetingId: string,
    sessionId?: string | null,
    status: ATTENDEE_STATUS,
    statusDetail?: ATTENDEE_STATUS_DETAIL | null,
    name: string,
    phonePin?: string | null,
    clientIP: string,
    joinToken?: string | null,
    createdAt: string,
    terminatedAt?: string | null,
    acceptedAt?: string | null,
    lastSeenAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateVirtualAttendeeSubscriptionVariables = {
  hostId?: string,
};

export type OnUpdateVirtualAttendeeSubscription = {
  onUpdateVirtualAttendee?:  {
    __typename: "VirtualAttendee",
    id: string,
    attendeeKey: string,
    tenantId: string,
    hostId: string,
    meetingId: string,
    sessionId?: string | null,
    status: ATTENDEE_STATUS,
    statusDetail?: ATTENDEE_STATUS_DETAIL | null,
    name: string,
    phonePin?: string | null,
    clientIP: string,
    joinToken?: string | null,
    createdAt: string,
    terminatedAt?: string | null,
    acceptedAt?: string | null,
    lastSeenAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  tenantId?: string | null,
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  tenantId?: string | null,
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  tenantId?: string | null,
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTenantSubscriptionVariables = {
  id?: string | null,
};

export type OnCreateTenantSubscription = {
  onCreateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config?:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTenantSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateTenantSubscription = {
  onUpdateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config?:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTenantSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteTenantSubscription = {
  onDeleteTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config?:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDocumentVersionSubscriptionVariables = {
  tenantId?: string | null,
};

export type OnCreateDocumentVersionSubscription = {
  onCreateDocumentVersion?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename: string,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcHash?: string | null,
    srcSize: number,
    pages:  Array< {
      __typename: "Page",
      pageId: string,
      number: number,
      srcId?: string | null,
      srcHash: string,
      isRequired?: boolean | null,
      speakerNotes?: string | null,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDocumentVersionSubscriptionVariables = {
  tenantId?: string | null,
};

export type OnUpdateDocumentVersionSubscription = {
  onUpdateDocumentVersion?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename: string,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcHash?: string | null,
    srcSize: number,
    pages:  Array< {
      __typename: "Page",
      pageId: string,
      number: number,
      srcId?: string | null,
      srcHash: string,
      isRequired?: boolean | null,
      speakerNotes?: string | null,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDocumentVersionSubscriptionVariables = {
  tenantId?: string | null,
};

export type OnDeleteDocumentVersionSubscription = {
  onDeleteDocumentVersion?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename: string,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcHash?: string | null,
    srcSize: number,
    pages:  Array< {
      __typename: "Page",
      pageId: string,
      number: number,
      srcId?: string | null,
      srcHash: string,
      isRequired?: boolean | null,
      speakerNotes?: string | null,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAttachedFileSubscriptionVariables = {
  tenantId?: string | null,
};

export type OnCreateAttachedFileSubscription = {
  onCreateAttachedFile?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAttachedFileSubscriptionVariables = {
  tenantId?: string | null,
};

export type OnUpdateAttachedFileSubscription = {
  onUpdateAttachedFile?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAttachedFileSubscriptionVariables = {
  tenantId?: string | null,
};

export type OnDeleteAttachedFileSubscription = {
  onDeleteAttachedFile?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDocumentSubscriptionVariables = {
  tenantId?: string | null,
};

export type OnCreateDocumentSubscription = {
  onCreateDocument?:  {
    __typename: "Document",
    id: string,
    tenantId: string,
    accessLevel: DOCUMENT_ACCESS_LEVEL,
    status: DOCUMENT_STATUS,
    owner: string,
    expiresAt?: string | null,
    integration?:  {
      __typename: "DocumentIntegration",
      integrationId?: string | null,
      externalId?: string | null,
      firstSync?: string | null,
      lastSync?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    type: FILE_TYPE,
    editPermissions?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDocumentSubscriptionVariables = {
  tenantId?: string | null,
};

export type OnUpdateDocumentSubscription = {
  onUpdateDocument?:  {
    __typename: "Document",
    id: string,
    tenantId: string,
    accessLevel: DOCUMENT_ACCESS_LEVEL,
    status: DOCUMENT_STATUS,
    owner: string,
    expiresAt?: string | null,
    integration?:  {
      __typename: "DocumentIntegration",
      integrationId?: string | null,
      externalId?: string | null,
      firstSync?: string | null,
      lastSync?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    type: FILE_TYPE,
    editPermissions?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDocumentSubscriptionVariables = {
  tenantId?: string | null,
};

export type OnDeleteDocumentSubscription = {
  onDeleteDocument?:  {
    __typename: "Document",
    id: string,
    tenantId: string,
    accessLevel: DOCUMENT_ACCESS_LEVEL,
    status: DOCUMENT_STATUS,
    owner: string,
    expiresAt?: string | null,
    integration?:  {
      __typename: "DocumentIntegration",
      integrationId?: string | null,
      externalId?: string | null,
      firstSync?: string | null,
      lastSync?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    type: FILE_TYPE,
    editPermissions?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateEmailTemplateSubscriptionVariables = {
  tenantId?: string | null,
};

export type OnCreateEmailTemplateSubscription = {
  onCreateEmailTemplate?:  {
    __typename: "EmailTemplate",
    id: string,
    tenantId: string,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    subject?: string | null,
    title?: string | null,
    cc?: Array< string > | null,
    bcc?: Array< string > | null,
    body?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    status: EMAIL_TEMPLATE_STATUS,
    editPermissions?: Array< string > | null,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateEmailTemplateSubscriptionVariables = {
  tenantId?: string | null,
};

export type OnUpdateEmailTemplateSubscription = {
  onUpdateEmailTemplate?:  {
    __typename: "EmailTemplate",
    id: string,
    tenantId: string,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    subject?: string | null,
    title?: string | null,
    cc?: Array< string > | null,
    bcc?: Array< string > | null,
    body?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    status: EMAIL_TEMPLATE_STATUS,
    editPermissions?: Array< string > | null,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteEmailTemplateSubscriptionVariables = {
  tenantId?: string | null,
};

export type OnDeleteEmailTemplateSubscription = {
  onDeleteEmailTemplate?:  {
    __typename: "EmailTemplate",
    id: string,
    tenantId: string,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    subject?: string | null,
    title?: string | null,
    cc?: Array< string > | null,
    bcc?: Array< string > | null,
    body?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    status: EMAIL_TEMPLATE_STATUS,
    editPermissions?: Array< string > | null,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCustomDeckSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnCreateCustomDeckSubscription = {
  onCreateCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      visible: boolean,
      srcId?: string | null,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCustomDeckSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnUpdateCustomDeckSubscription = {
  onUpdateCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      visible: boolean,
      srcId?: string | null,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCustomDeckSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnDeleteCustomDeckSubscription = {
  onDeleteCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      visible: boolean,
      srcId?: string | null,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFolderSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnCreateFolderSubscription = {
  onCreateFolder?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFolderSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnUpdateFolderSubscription = {
  onUpdateFolder?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFolderSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnDeleteFolderSubscription = {
  onDeleteFolder?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateContentShareSubscription = {
  onCreateContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    expiresAt: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateContentShareSubscription = {
  onUpdateContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    expiresAt: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteContentShareSubscription = {
  onDeleteContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    expiresAt: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateVirtualSessionSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnCreateVirtualSessionSubscription = {
  onCreateVirtualSession?:  {
    __typename: "VirtualSession",
    id: string,
    tenantId: string,
    meetingId: string,
    roomName: string,
    status: SESSION_STATUS,
    recordingStatus?: RECORDING_STATUS | null,
    recordingTaskId?: string | null,
    hostName: string,
    hostPhonePin: string,
    createdAt: string,
    createdBy: string,
    terminatedAt?: string | null,
    meetingLog?:  Array< {
      __typename: "MeetingLogEntry",
      type?: number | null,
      msg?: string | null,
      timestamp?: string | null,
      action?: number | null,
      param?: string | null,
      user?: string | null,
      isHost?: boolean | null,
    } > | null,
    documentHistory?:  Array< {
      __typename: "DocumentHistoryEntry",
      contentId: string,
      contentType: MEETING_CONTENT_TYPE,
      totalDurationSec: number,
    } > | null,
    attendees?:  {
      __typename: "ModelVirtualAttendeeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateVirtualSessionSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnUpdateVirtualSessionSubscription = {
  onUpdateVirtualSession?:  {
    __typename: "VirtualSession",
    id: string,
    tenantId: string,
    meetingId: string,
    roomName: string,
    status: SESSION_STATUS,
    recordingStatus?: RECORDING_STATUS | null,
    recordingTaskId?: string | null,
    hostName: string,
    hostPhonePin: string,
    createdAt: string,
    createdBy: string,
    terminatedAt?: string | null,
    meetingLog?:  Array< {
      __typename: "MeetingLogEntry",
      type?: number | null,
      msg?: string | null,
      timestamp?: string | null,
      action?: number | null,
      param?: string | null,
      user?: string | null,
      isHost?: boolean | null,
    } > | null,
    documentHistory?:  Array< {
      __typename: "DocumentHistoryEntry",
      contentId: string,
      contentType: MEETING_CONTENT_TYPE,
      totalDurationSec: number,
    } > | null,
    attendees?:  {
      __typename: "ModelVirtualAttendeeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteVirtualSessionSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnDeleteVirtualSessionSubscription = {
  onDeleteVirtualSession?:  {
    __typename: "VirtualSession",
    id: string,
    tenantId: string,
    meetingId: string,
    roomName: string,
    status: SESSION_STATUS,
    recordingStatus?: RECORDING_STATUS | null,
    recordingTaskId?: string | null,
    hostName: string,
    hostPhonePin: string,
    createdAt: string,
    createdBy: string,
    terminatedAt?: string | null,
    meetingLog?:  Array< {
      __typename: "MeetingLogEntry",
      type?: number | null,
      msg?: string | null,
      timestamp?: string | null,
      action?: number | null,
      param?: string | null,
      user?: string | null,
      isHost?: boolean | null,
    } > | null,
    documentHistory?:  Array< {
      __typename: "DocumentHistoryEntry",
      contentId: string,
      contentType: MEETING_CONTENT_TYPE,
      totalDurationSec: number,
    } > | null,
    attendees?:  {
      __typename: "ModelVirtualAttendeeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMeetingSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnCreateMeetingSubscription = {
  onCreateMeeting?:  {
    __typename: "Meeting",
    id: string,
    tenantId: string,
    title?: string | null,
    startTime: string,
    endTime?: string | null,
    contentPresented:  Array< {
      __typename: "ContentPresented",
      contentId: string,
      folderItemId?: string | null,
      status: CONTENT_PRESENTED_STATUS,
      contentType: MEETING_CONTENT_TYPE,
      openedAt: string,
      closedAt?: string | null,
    } >,
    type: MEETING_TYPE,
    notes?: string | null,
    status: MEETING_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMeetingSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnUpdateMeetingSubscription = {
  onUpdateMeeting?:  {
    __typename: "Meeting",
    id: string,
    tenantId: string,
    title?: string | null,
    startTime: string,
    endTime?: string | null,
    contentPresented:  Array< {
      __typename: "ContentPresented",
      contentId: string,
      folderItemId?: string | null,
      status: CONTENT_PRESENTED_STATUS,
      contentType: MEETING_CONTENT_TYPE,
      openedAt: string,
      closedAt?: string | null,
    } >,
    type: MEETING_TYPE,
    notes?: string | null,
    status: MEETING_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMeetingSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnDeleteMeetingSubscription = {
  onDeleteMeeting?:  {
    __typename: "Meeting",
    id: string,
    tenantId: string,
    title?: string | null,
    startTime: string,
    endTime?: string | null,
    contentPresented:  Array< {
      __typename: "ContentPresented",
      contentId: string,
      folderItemId?: string | null,
      status: CONTENT_PRESENTED_STATUS,
      contentType: MEETING_CONTENT_TYPE,
      openedAt: string,
      closedAt?: string | null,
    } >,
    type: MEETING_TYPE,
    notes?: string | null,
    status: MEETING_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateIntegrationLogSubscription = {
  onCreateIntegrationLog?:  {
    __typename: "IntegrationLog",
    integrationId: string,
    id: string,
    log:  Array< {
      __typename: "IntegrationLogEntry",
      level: LOG_LEVEL,
      timestamp: string,
      message: string,
      srcDocId: string,
      srcDocVersion: string,
    } | null >,
    status: INTEGRATION_RUN_STATUS,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateIntegrationLogSubscription = {
  onUpdateIntegrationLog?:  {
    __typename: "IntegrationLog",
    integrationId: string,
    id: string,
    log:  Array< {
      __typename: "IntegrationLogEntry",
      level: LOG_LEVEL,
      timestamp: string,
      message: string,
      srcDocId: string,
      srcDocVersion: string,
    } | null >,
    status: INTEGRATION_RUN_STATUS,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteIntegrationLogSubscription = {
  onDeleteIntegrationLog?:  {
    __typename: "IntegrationLog",
    integrationId: string,
    id: string,
    log:  Array< {
      __typename: "IntegrationLogEntry",
      level: LOG_LEVEL,
      timestamp: string,
      message: string,
      srcDocId: string,
      srcDocVersion: string,
    } | null >,
    status: INTEGRATION_RUN_STATUS,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateIntegrationSettingsSubscription = {
  onCreateIntegrationSettings?:  {
    __typename: "IntegrationSettings",
    id: string,
    tenantId: string,
    syncContentEvery: number,
    name?: string | null,
    mapping:  Array< {
      __typename: "IntegrationFieldMapping",
      key: string,
      fieldType: string,
      dataType: string,
      targetFieldName: string,
      srcFieldname: string,
    } | null >,
    clientConfigurationFields:  Array< {
      __typename: "IntegrationClientConfigurationTuple",
      key: string,
      value: string,
    } | null >,
    integrationType?: INTEGRATION_TYPE | null,
    enabled?: boolean | null,
    notificationEmail?: string | null,
    errorSyncEmail?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateIntegrationSettingsSubscription = {
  onUpdateIntegrationSettings?:  {
    __typename: "IntegrationSettings",
    id: string,
    tenantId: string,
    syncContentEvery: number,
    name?: string | null,
    mapping:  Array< {
      __typename: "IntegrationFieldMapping",
      key: string,
      fieldType: string,
      dataType: string,
      targetFieldName: string,
      srcFieldname: string,
    } | null >,
    clientConfigurationFields:  Array< {
      __typename: "IntegrationClientConfigurationTuple",
      key: string,
      value: string,
    } | null >,
    integrationType?: INTEGRATION_TYPE | null,
    enabled?: boolean | null,
    notificationEmail?: string | null,
    errorSyncEmail?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteIntegrationSettingsSubscription = {
  onDeleteIntegrationSettings?:  {
    __typename: "IntegrationSettings",
    id: string,
    tenantId: string,
    syncContentEvery: number,
    name?: string | null,
    mapping:  Array< {
      __typename: "IntegrationFieldMapping",
      key: string,
      fieldType: string,
      dataType: string,
      targetFieldName: string,
      srcFieldname: string,
    } | null >,
    clientConfigurationFields:  Array< {
      __typename: "IntegrationClientConfigurationTuple",
      key: string,
      value: string,
    } | null >,
    integrationType?: INTEGRATION_TYPE | null,
    enabled?: boolean | null,
    notificationEmail?: string | null,
    errorSyncEmail?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateVirtualAttendeePublicSubscriptionVariables = {
  attendeeKey?: string,
};

export type OnUpdateVirtualAttendeePublicSubscription = {
  onUpdateVirtualAttendeePublic?:  {
    __typename: "VirtualAttendeeLimited",
    id: string,
    attendeeKey: string,
    tenantId: string,
    meetingId: string,
    status: ATTENDEE_STATUS,
    statusDetail?: ATTENDEE_STATUS_DETAIL | null,
    name: string,
    phonePin?: string | null,
    joinToken?: string | null,
  } | null,
};
