import React from 'react'
import { useDispatch } from 'react-redux'

import { FolderItemORM, FolderORM, isFolderORM, isFolderItemORM, isDocumentVersionORM } from 'src/types/types'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'

import {
  DNABox,
  DNAButton,
  DNAIcon,
  DNAText,
  Iffy,
  Popover,
} from '@alucio/lux-ui'
import DNADocumentVersionUpdateModal from 'src/components/DNA/Modal/DNADocumentVersionUpdate'
import { DocumentStatus } from '@alucio/aws-beacon-amplify/src/models'

const DNADocumentVersionUpdateButton: React.FC<{
  orm: FolderORM | FolderItemORM
}> = (props) => {
  const { orm } = props
  const dispatch = useDispatch()

  const containsPendingReview = isFolderORM(orm)
    ? orm.meta.version.containsPendingReviewItem
    : false
  const hasAutoUpdate = isFolderORM(orm)
    ? orm.meta.version.containsAutoUpdatedItem
    : orm.meta.hasAutoUpdatedItem;
  const hasOutdated = isFolderORM(orm)
    ? orm.meta.version.containsOutdatedDocVer
    : orm.meta.hasOutdatedItem
  const isUnpublishedDocument = isFolderItemORM(orm) && isDocumentVersionORM(orm.relations.item)
    ? orm.relations.item.relations.document.model.status === DocumentStatus.NOT_PUBLISHED
    : false

  const hideUpdateWarning = isFolderORM(orm)
    ? orm.meta.isSharedWithTheUser : orm.relations?.parent?.meta.isSharedWithTheUser;

  const onPress = () => {
    !isFolderORM(orm) && dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <DNADocumentVersionUpdateModal
          {...props}
          folderItemORM={orm}
        />
      ),
    }))
  }

  if (!(hasAutoUpdate || hasOutdated || containsPendingReview) || isUnpublishedDocument) {
    return null;
  }

  return (
    <DNABox appearance="row" alignY="center">
      <Iffy is={hasOutdated}>
        <Popover type="tooltip" placement="top">
          <Popover.Anchor>
            <Iffy is={!isFolderORM(orm)}>
              <DNAButton
                status="danger"
                appearance="ghost"
                context="minimum"
                onPress={onPress}
                style={{ width: 32, height: 32 }}
                iconLeft="update"
              />
            </Iffy>
            <Iffy is={isFolderORM(orm)}>
              <DNABox
                alignY="center"
                alignX="center"
                style={{ width: 32, height: 32 }}
              >
                <DNAIcon.Styled
                  status="danger"
                  appearance="ghost"
                  context="minimum"
                  name="update"
                />
              </DNABox>
            </Iffy>
          </Popover.Anchor>
          <Popover.Content>
            <DNAText
              status="basic"
            >
              {
                !isFolderORM(orm)
                  ? 'Update file to the latest version'
                  : 'File(s) in this folder need to be updated'
              }
            </DNAText>
          </Popover.Content>
        </Popover>
      </Iffy>
      <Iffy is={(hasAutoUpdate || containsPendingReview) && !hideUpdateWarning}>
        <Popover type="tooltip" placement="top">
          <Popover.Anchor>
            <DNABox
              alignY="center"
              alignX="center"
              style={{ width: 32, height: 32 }}
            >
              <DNAIcon.Styled
                status="warning"
                appearance="ghost"
                context="minimum"
                name="alert-circle"
              />
            </DNABox>
          </Popover.Anchor>
          <Popover.Content>
            <DNAText status="basic">
              {
                !isFolderORM(orm)
                  ? 'This file was auto-updated to a new version'
                  : containsPendingReview
                    ? 'File(s) in this folder need to be reviewed'
                    : 'File(s) in this folder have been auto-updated to a new version'
              }
            </DNAText>
          </Popover.Content>
        </Popover>
      </Iffy>
    </DNABox>
  )
}

export default DNADocumentVersionUpdateButton
