import React from 'react'
import { DNABox } from '@alucio/lux-ui'
import { SlideThumb, ThumbnailPage } from './SlideSelector'
import {
  ScrollView,
  StyleSheet,
  Pressable,
} from 'react-native'
import { ThumbnailSize } from 'src/hooks/useThumbnailSize/useThumbnailSize'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import mergeStyles from '@alucio/lux-ui/lib/components/util/mergeStyles';
import { DocumentVersionORM } from 'src/types/types'
import { useOnlineStatus } from 'src/state/context/AppSettings'

interface PageGroupListProps {
  handleGroupSelect: (group: GroupDraft) => void
  thumbnailSize: ThumbnailSize
  groupDrafts: GroupDraft[]
  disableCheckboxes?: boolean
  documentVersionORM?: DocumentVersionORM
}

const styles = StyleSheet.create({
  groupContainer: {
    flexBasis: '100%',
    borderWidth: 3,
    borderColor: colors['color-flat-400'],
    borderRadius: 8,
    paddingHorizontal: 35,
    paddingTop: 35,
    marginVertical: 30,
  },
  groupSelectedBorderColor: {
    borderColor: colors['color-primary-500'],
  },
});

export interface GroupDraft {
  id: string;
  pages: ThumbnailPage[];
}

const PageGroupList:React.FC<PageGroupListProps> = (props) => {
  const {
    handleGroupSelect,
    groupDrafts,
    thumbnailSize,
    disableCheckboxes,
    documentVersionORM,
  } = props

  const isNetworkConnected = useOnlineStatus()

  const unavailableContent = (
    !isNetworkConnected &&
    !documentVersionORM?.meta.assets.isContentCached
  )

  const pageGroupElements = groupDrafts.map((pageGroup, idx) => {
    if (!pageGroup.pages?.length) return
    const isMultiPage = pageGroup.pages.length > 1;
    const isGroupSelected = isMultiPage && pageGroup.pages.some(({ checked }) => checked);

    return (
      <Pressable
        key={pageGroup.id ?? pageGroup.pages[0].pageId}
        style={
        mergeStyles(undefined,
          [styles.groupContainer, isMultiPage],
          [{ marginTop: 0 }, idx === 0],
          [styles.groupSelectedBorderColor, isGroupSelected])}
        onPress={() => { handleGroupSelect(pageGroup) }}
      >
        <DNABox
          wrap={'start'}
          alignY="center"
        >
          {pageGroup.pages.map(page => {
            if (!page) return
            return (
              <SlideThumb
                key={page.pageId}
                page={page}
                onChange={!page.isPlaceholder
                  ? () => { handleGroupSelect(pageGroup) }
                  : undefined
                }
                disabled={page.disabled}
                checked={page.checked}
                size={thumbnailSize}
                disableCheckbox={disableCheckboxes}
                unavailableContent={unavailableContent}
              />
            )
          })}
        </DNABox>
      </Pressable>
    )
  })

  return (
    <DNABox as={ScrollView} wrap="start">
      {pageGroupElements}
    </DNABox>
  )
}

export default PageGroupList
