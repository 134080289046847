import React from 'react'
import { StyleSheet, Text } from 'react-native'

import { DNABox, luxColors, Stack, util } from '@alucio/lux-ui'

import Controls from './Controls'
import IFrame from './IFrame/IFrame'
import Iffy from '@alucio/lux-ui/lib/components/util/Iffy/Iffy';
import DNAText from '@alucio/lux-ui/lib/components/type/DNAText/DNAText';

const styles = StyleSheet.create({
  contentPreview: {
    backgroundColor: 'black',
  },
  iconContainer: {
    position: 'absolute',
    flexDirection: 'row',
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    margin: 0,
    marginBottom: 8,
    padding: 0,
    width: 181,
    height: 40,
    borderRadius: 4,
  },
  stackWrapper: {
    flex: 1,
    width: '100%',
    height: '100%',
    userSelect: 'none',
  },
  iframeWrapper: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  watermark: {
    top: 8,
    left: 8,
    pointerEvents: 'none',
    transform: [{ translateX: 0 }], // to overrider the parent transform
  },
  watermarkText: {
    alignSelf: 'flex-end',
    backgroundColor: luxColors.watermarkBackdrop.primary,
    borderRadius: 4,
    color: luxColors.watermark.primary,
    paddingBottom: 4,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 4,
  },
  watermarkFullwindow: {
    fontSize: 24,
  },
})

interface ContentPreviewProps {
  fullwindow?: boolean;
  hideControls?: boolean;
  watermark?: string;
  showContentUnavailable?: boolean;
}

const ContentPreview = (props: ContentPreviewProps) => {
  const { fullwindow, hideControls, showContentUnavailable, watermark } = props;

  return (
    <DNABox fill style={styles.contentPreview}>
      <Iffy is={!showContentUnavailable}>
        <Stack anchor="bottom" style={[styles.stackWrapper, { flex:1 }]}>
          <Stack.Layer style={[styles.iframeWrapper, { flex:1 }]} >
            <IFrame/>
          </Stack.Layer>
          {watermark &&
            <Stack.Layer style={styles.watermark}>
              <Text
                style={util.mergeStyles(
                  undefined,
                  styles.watermarkText,
                  fullwindow ? styles.watermarkFullwindow : null,
                )}
              >{watermark}</Text>
            </Stack.Layer>
          }
          {!hideControls &&
            <Stack.Layer style={styles.iconContainer}>
              <Controls />
            </Stack.Layer>
          }
        </Stack >
      </Iffy>
      <Iffy is={showContentUnavailable}>
        <DNABox fill alignY="center" alignX="center">
          <DNAText status="basic">
            Content is not available
          </DNAText>
        </DNABox>
      </Iffy>
    </DNABox>
  );
};

export default ContentPreview;
