import React, { useRef } from 'react'
import { Image, StyleSheet, Text } from 'react-native'
import { useMachine } from '@xstate/react'

import {
  DNABox,
  DNAText,
  DNAAspectRatio,
  Iffy,
} from '@alucio/lux-ui'
import {
  PlayerMode,
} from '@alucio/core'
import playerWrapperMachine, { PW } from 'src/state/machines/presentation/playerWrapper'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'
import { PresentationBroadcastContent } from '@alucio/core/lib/state/context/PresentationPlayer/types'

const styles = StyleSheet.create({
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
  },
  loaderImage: {
    height: 200,
    width: 200,
  },
});

const useFormatted = (state: PW.MachineState) => {
  const currentState = JSON.stringify({ state: state.value, frameId: state.context.frameId })

  return {
    currentState,
  }
}

const useCond = (state: PW.MachineState) => {
  const isDocumentLoaded = state.hasTag(PW.Tags.IS_CONTENT_LOADED)
  const isIdle = state.hasTag(PW.Tags.IS_IDLE)

  return {
    isDocumentLoaded,
    isIdle,
  }
}

interface IFrameWrapperProps {
  isFullWindow: boolean,
  meetingId: string,
  showDebug?: boolean,
  mode?: PlayerMode,
}

const IFrameWrapper: React.FC<IFrameWrapperProps> = (props: IFrameWrapperProps) => {
  const { isFullWindow, meetingId, showDebug = false, mode = 'INTERACTIVE' } = props
  const { deviceMode } = useAppSettings();
  const iFrameRef = useRef<HTMLIFrameElement>(null)
  const machine = useRef(playerWrapperMachine(meetingId, mode)).current
  const [state] = useMachine(machine)

  const formatted = useFormatted(state)
  const cond = useCond(state)
  const formatContentInfo = (data: PresentationBroadcastContent) => {
    const { ppzCoords, state, groupId, visiblePages, documentVersionId: docVerId } = data
    return JSON.stringify({
      docVersionId: `${docVerId.substring(0, 5)}...${docVerId.substring(docVerId.length - 5)}`,
      ppzCoords,
      state,
      groupId,
      visiblePages: visiblePages.join(','),
    }, null, '  ')
  }
  return (
    <DNABox fill appearance="col">
      <Iffy is={showDebug}>
        <DNAText h1>Current State</DNAText>
        <DNAText>{formatted.currentState}</DNAText>
        <DNABox appearance="row" fill>
          <DNABox appearance="col">
            <Text>
              Presentable State
              {state.context.presentableState ? formatContentInfo(state.context.presentableState) : ''}
            </Text>
          </DNABox>
          <DNABox appearance="col">
            <Text>
              Player State
              {state.context.playerState ? formatContentInfo(state.context.playerState) : ''}
            </Text>
          </DNABox>
        </DNABox>
      </Iffy>
      <DNABox
        fill
        appearance="col"
      >
        <DNAAspectRatio ratio={state.context.aspectRatio}>
          <Iffy is={!cond.isIdle}>
            {/* IFRAME */}
            <iframe
              id="player-iframe"
              frameBorder={0}
              ref={iFrameRef}
              // eslint-disable-next-line max-len
              src={`/pdf/index.html?frameId=${state.context.frameId}&isFullWindow=${isFullWindow}&isTabletMode=${deviceMode === DeviceMode.tablet}`}
              style={{
                flex: 1,
              }}
            />

            {/* DOCUMENT LOADING SPINNER */}
            <Iffy is={!cond.isDocumentLoaded}>
              <DNABox
                alignX="center"
                alignY="center"
                style={styles.loaderContainer}
              >
                <Image
                  testID="dna-loader"
                  style={styles.loaderImage}
                  source={require('../../../assets/images/LoaderDNA_dark.gif')}
                />

              </DNABox>
            </Iffy>
          </Iffy>
        </DNAAspectRatio>
      </DNABox>
    </DNABox>
  )
}

export default IFrameWrapper
