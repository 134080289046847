// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const FolderStatus = {
  "ACTIVE": "ACTIVE",
  "ARCHIVED": "ARCHIVED",
  "REMOVED": "REMOVED"
};

const FolderItemType = {
  "FOLDER": "FOLDER",
  "DOCUMENT_VERSION": "DOCUMENT_VERSION",
  "CUSTOM_DECK": "CUSTOM_DECK"
};

const FolderItemStatus = {
  "ACTIVE": "ACTIVE",
  "REMOVED": "REMOVED"
};

const PermissionType = {
  "VIEW": "VIEW"
};

const ShareTargetType = {
  "USER": "USER",
  "FILTER": "FILTER",
  "ALL": "ALL"
};

const FieldDataType = {
  "CATEGORICAL": "CATEGORICAL",
  "MULTICATEGORICAL": "MULTICATEGORICAL",
  "STRING": "STRING",
  "DATE": "DATE"
};

const TenantStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const ConversionStatus = {
  "PENDING": "PENDING",
  "PROCESSING": "PROCESSING",
  "PROCESSED": "PROCESSED",
  "ERROR": "ERROR"
};

const DocumentStatus = {
  "ARCHIVED": "ARCHIVED",
  "REVOKED": "REVOKED",
  "NOT_PUBLISHED": "NOT_PUBLISHED",
  "PUBLISHED": "PUBLISHED",
  "DELETED": "DELETED",
  "ARCHIVED_WITH_VERSIONS": "ARCHIVED_WITH_VERSIONS",
  "REVOKED_WITH_VERSIONS": "REVOKED_WITH_VERSIONS",
  "DELETED_WITH_VERSIONS": "DELETED_WITH_VERSIONS"
};

const FileType = {
  "JPG": "JPG",
  "JPEG": "JPEG",
  "XLS": "XLS",
  "XLSX": "XLSX",
  "XLSM": "XLSM",
  "DOC": "DOC",
  "DOCX": "DOCX",
  "PDF": "PDF",
  "PPTX": "PPTX",
  "PNG": "PNG",
  "MP4": "MP4"
};

const DocumentVersionChangeType = {
  "MAJOR": "MAJOR",
  "MINOR": "MINOR"
};

const NotificationScope = {
  "NONE": "NONE",
  "EMAIL": "EMAIL"
};

const AssociatedFileType = {
  "DOCUMENT": "DOCUMENT",
  "ATTACHED_FILE": "ATTACHED_FILE"
};

const AssociatedFileStatus = {
  "ACTIVE": "ACTIVE",
  "DELETED": "DELETED"
};

const IntegrationType = {
  "VEEVA": "VEEVA",
  "BEACON": "BEACON",
  "NONE": "NONE"
};

const AttachedFileEntityType = {
  "DOCUMENT_VERSION": "DOCUMENT_VERSION",
  "EMAIL_TEMPLATE": "EMAIL_TEMPLATE"
};

const AttendeeStatus = {
  "ACCEPTED": "ACCEPTED",
  "CONNECTED": "CONNECTED",
  "DECLINED": "DECLINED",
  "DISCONNECTED": "DISCONNECTED",
  "PENDING": "PENDING",
  "ENDED": "ENDED",
  "REMOVED": "REMOVED",
  "LEFT": "LEFT"
};

const AttendeeStatusDetail = {
  "HOST_ENDED": "HOST_ENDED",
  "HOST_DISCONNECTED": "HOST_DISCONNECTED"
};

const SessionStatus = {
  "ACTIVE": "ACTIVE",
  "TERMINATED": "TERMINATED"
};

const RecordingStatus = {
  "RECORDING": "RECORDING",
  "PROCESSING": "PROCESSING",
  "RECORDED": "RECORDED",
  "ERROR": "ERROR"
};

const MeetingContentType = {
  "CUSTOM_DECK": "CUSTOM_DECK",
  "DOCUMENT_VERSION": "DOCUMENT_VERSION"
};

const ShareType = {
  "DOCUMENT_VERSION": "DOCUMENT_VERSION",
  "RECORDING": "RECORDING",
  "ATTACHED_FILE": "ATTACHED_FILE"
};

const UserStatus = {
  "ACTIVE": "ACTIVE",
  "DEACTIVATED": "DEACTIVATED"
};

const UserRole = {
  "ALUCIO_ADMIN": "AlucioAdmin",
  "TENANT_ADMIN": "TenantAdmin",
  "TENANT_PUBLISHER": "TenantPublisher",
  "TENANT_VIEWER": "TenantViewer"
};

const InputEngineType = {
  "MULTICATEGORICAL": "MULTICATEGORICAL",
  "SINGLECATEGORICAL": "SINGLECATEGORICAL"
};

const DocumentAccessLevel = {
  "TENANT": "TENANT",
  "USER": "USER"
};

const EmailTemplateStatus = {
  "DRAFT": "DRAFT",
  "ACTIVE": "ACTIVE",
  "DEACTIVATED": "DEACTIVATED",
  "REMOVED": "REMOVED"
};

const PurposeType = {
  "INTERNAL_USE_ONLY": "Internal Use Only",
  "EXTERNAL_PROACTIVE": "External - Proactive",
  "EXTERNAL_REACTIVE": "External - Reactive",
  "NOT_DEFINED": "Not Defined"
};

const MeetingLogEntryType = {
  "BEACON_CONTROL": "BeaconControl",
  "PLAYER_CONTROL": "PlayerControl",
  "CHAT": "Chat"
};

const MeetingLogActions = {
  "START_PRESENTATION": "startPresentation",
  "STOP_PRESENTATION": "stopPresentation",
  "NEXT": "next",
  "PREV": "prev",
  "GOTO_SLIDE": "gotoSlide",
  "PLAY": "play",
  "PAUSE": "pause",
  "GET_NOTES": "getNotes",
  "GET_FORMATTED_NOTES": "getFormattedNotes",
  "NXT_SLIDE": "nxtSlide",
  "PRV_SLIDE": "prvSlide",
  "CLICK_COUNT": "clickCount",
  "JUMP_TO_ANIM": "jumpToAnim",
  "CURRENT_STATUS": "currentStatus",
  "GET_OUTLINE": "getOutline",
  "PRELOAD_SLIDE": "preloadSlide",
  "RESET_SLIDE": "resetSlide",
  "IS_SLIDE_MEDIA_LOADED": "isSlideMediaLoaded",
  "PLAY_SLIDE_ANIMS": "playSlideAnims",
  "IS_TRANSITION_PRESENT": "isTransitionPresent",
  "GET_CONTENT_DETAILS": "getContentDetails",
  "GET_CURRENT_ANIMATION_COUNT": "getCurrentAnimationCount",
  "PLAY_AUTOMATIC_ANIMATIONS": "playAutomaticAnimations",
  "FORCE_START_SLIDE_PLAY": "forceStartSlidePlay",
  "REPORT_PROGRESS": "reportProgress",
  "CLOSE_PLAY_BUTTON": "closePlayButton",
  "SET_R_PATH": "setRPath",
  "LOAD_PRES": "loadPres",
  "INIT_PRES": "initPres",
  "AUTH_CLOUD_FRONT": "authCloudFront",
  "IN_PROGRESS_PARTICIPANT_JOIN": "inProgressParticipantJoin"
};

const ContentPresentedStatus = {
  "ACTIVE": "ACTIVE",
  "DELETED": "DELETED"
};

const MeetingType = {
  "IN_PERSON": "IN_PERSON",
  "VIRTUAL": "VIRTUAL",
  "MANUAL": "MANUAL"
};

const MeetingStatus = {
  "IN_PROGRESS": "IN_PROGRESS",
  "ENDED_EXIT": "ENDED_EXIT",
  "ENDED_TIMEOUT": "ENDED_TIMEOUT",
  "DELETED": "DELETED"
};

const LogLevel = {
  "WARNING": "WARNING",
  "ERROR": "ERROR",
  "SUCCESS": "SUCCESS",
  "INFO": "INFO"
};

const IntegrationRunStatus = {
  "RUNNING": "RUNNING",
  "COMPLETED": "COMPLETED",
  "WARNING": "WARNING",
  "ERROR": "ERROR"
};

const { Folder, Tenant, DocumentVersion, AttachedFile, VirtualSession, VirtualAttendee, ContentShare, User, Document, EmailTemplate, CustomDeck, Meeting, IntegrationLog, IntegrationSettings, MeetingInfo, ContentTokenOutput, ExchangeShareTokenOutput, ReportList, FolderItem, SharePermission, LabelValues, FieldConfig, ListConfiguration, ListConfigurationField, ListConfigurationSort, TenantConfig, S3Object, Page, PageGroup, LabelValue, SemVer, AssociatedFile, DocumentVersionIntegration, VirtualAttendeeLimited, VirtualCreateSessionOutput, MeetingLogEntry, DocumentHistoryEntry, StartRecordingResponse, VirtualAttendeeLeftResponse, BookmarkedDoc, SubmitIntegrationResult, DocumentIntegration, CustomDeckGroup, CustomDeckPage, ContentPresented, PagePresentedEvent, IntegrationClientConfigurationTuple, IntegrationValueMapping, IntegrationFieldMapping, IntegrationLogEntry } = initSchema(schema);

export {
  Folder,
  Tenant,
  DocumentVersion,
  AttachedFile,
  VirtualSession,
  VirtualAttendee,
  ContentShare,
  User,
  Document,
  EmailTemplate,
  CustomDeck,
  Meeting,
  IntegrationLog,
  IntegrationSettings,
  FolderStatus,
  FolderItemType,
  FolderItemStatus,
  PermissionType,
  ShareTargetType,
  FieldDataType,
  TenantStatus,
  ConversionStatus,
  DocumentStatus,
  FileType,
  DocumentVersionChangeType,
  NotificationScope,
  AssociatedFileType,
  AssociatedFileStatus,
  IntegrationType,
  AttachedFileEntityType,
  AttendeeStatus,
  AttendeeStatusDetail,
  SessionStatus,
  RecordingStatus,
  MeetingContentType,
  ShareType,
  UserStatus,
  UserRole,
  InputEngineType,
  DocumentAccessLevel,
  EmailTemplateStatus,
  PurposeType,
  MeetingLogEntryType,
  MeetingLogActions,
  ContentPresentedStatus,
  MeetingType,
  MeetingStatus,
  LogLevel,
  IntegrationRunStatus,
  MeetingInfo,
  ContentTokenOutput,
  ExchangeShareTokenOutput,
  ReportList,
  FolderItem,
  SharePermission,
  LabelValues,
  FieldConfig,
  ListConfiguration,
  ListConfigurationField,
  ListConfigurationSort,
  TenantConfig,
  S3Object,
  Page,
  PageGroup,
  LabelValue,
  SemVer,
  AssociatedFile,
  DocumentVersionIntegration,
  VirtualAttendeeLimited,
  VirtualCreateSessionOutput,
  MeetingLogEntry,
  DocumentHistoryEntry,
  StartRecordingResponse,
  VirtualAttendeeLeftResponse,
  BookmarkedDoc,
  SubmitIntegrationResult,
  DocumentIntegration,
  CustomDeckGroup,
  CustomDeckPage,
  ContentPresented,
  PagePresentedEvent,
  IntegrationClientConfigurationTuple,
  IntegrationValueMapping,
  IntegrationFieldMapping,
  IntegrationLogEntry
};