import React from 'react'
import { useHistory } from 'src/router';
import { useDNAFolderActions } from 'src/components/DNA/Folder/DNAFolder.actions'
import { DNAButton, DNABox, DNAText, DNADivider, useTheme } from '@alucio/lux-ui'

export const DNAFolderPreviewHeader: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const folderActions = useDNAFolderActions()

  return (
    <DNABox appearance="col">
      <DNABox
        spacing="between"
        alignY="center"
        style={{
          borderTopLeftRadius: theme['border-card-radius'] as any,
          borderTopRightRadius: theme['border-card-radius'] as any,
          backgroundColor: theme['color-text-basic'],
          paddingHorizontal: theme['row-padding-horizontal'],
          paddingVertical: 24,
        }}
      >
        <DNABox alignY="center" spacing="medium">
          <DNAText testID="dashboard-section" h3>My Folders</DNAText>
          <DNAButton
            appearance="ghost"
            context="minimum"
            iconLeft="plus"
            // [TODO] Special case- This one is breaking the type rules
            onPress={folderActions.createFolder(undefined as any)}
          />
        </DNABox>
        <DNAButton
          testID="my-folders-section-view-all"
          appearance="ghost"
          context="minimum"
          onPress={() => history.push('folders')}
        >
          View All
        </DNAButton>
      </DNABox>
      <DNADivider />
    </DNABox>
  )
}

export default DNAFolderPreviewHeader
