import React from 'react'
import { FolderORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'

import {
  DNACard,
  DNABox,
  DNAText,
  useTheme,
  util,
} from '@alucio/lux-ui'
import Folder from 'src/components/DNA/Folder/DNAFolder'
import PinButton from 'src/components/DNA/Folder/DNAFolderPinButton'

export const DNAFolderRow: DNARowsComponent<FolderORM> = (props) => {
  const { ORM: folderORM, onPress } = props
  const theme = useTheme()

  // [TODO] - These should be standardized design tokens
  const containerStyle = {
    paddingHorizontal: 20,
    paddingVertical: 12,
    marginVertical: 1,
    borderRadius: 4,
    backgroundColor: theme['color-dark-300'],
  }

  return (
    <DNACard
      appearance="flat"
      style={util.mergeStyles(props, containerStyle)}
      interactive="pressable"
      onPress={() => { onPress?.(folderORM) } }
    >
      <DNABox alignY="center" spacing="medium">
        <PinButton
          isHidden={!folderORM.model.isPinned}
          variant="virtual"
        />
        <DNABox spacing="large" alignY="center" childFill={1}>
          <Folder folder={folderORM} />
          <DNAText b1 numberOfLines={1} status="basic">{folderORM.model.name}</DNAText>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default DNAFolderRow
