import React from 'react'
import { DocumentVersionORM, FolderItemORM } from 'src/types/types'
import { useDocumentTitle } from 'src/hooks/useDocumentTitle/useDocumentTitle'
import { DNACardComponent } from 'src/components/DNA/Grid/Cards/types'
import { DocumentStatus } from '@alucio/aws-beacon-amplify/src/models';

import { useDNADocumentVersionActions } from 'src/components/DNA/Document/DNADocumentVersion.actions'
import { useAppSettings } from 'src/state/context/AppSettings';
import {
  DNAButton,
  DNABox,
  DNAText,
  DNACard,
  DNADivider,
  Iffy,
  DNAChip,
  luxColors,
} from '@alucio/lux-ui'
import DNADocumentVersionContextMenu from 'src/components/DNA/Document/DNADocumentVersionContextMenu'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import DNADocumentVersionUpdateButton from 'src/components/DNA/Document/DNADocumentVersionUpdateButton'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import { CUSTOM_DECK_CARD_MAX_HEIGHT } from './constants';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
export const DNADocumentFolderItemCard: DNACardComponent<FolderItemORM> = (props) => {
  const { ORM: folderItemORM, isNetworkConnected, onPress } = props
  const documentVerActions = useDNADocumentVersionActions()
  const documentVersionORM = folderItemORM.relations.item as DocumentVersionORM
  const parentDocumentORM = documentVersionORM.relations.document
  const isBookmarked = parentDocumentORM.meta.bookmark.isBookmarked
  const { visiblePages } = folderItemORM.model

  const isModified =
    visiblePages?.length &&
    documentVersionORM.model.canHideSlides &&
    documentVersionORM.relations.document.model.status === 'PUBLISHED'
  const title = useDocumentTitle(documentVersionORM, folderItemORM)
  const isLatestPublished = documentVersionORM.meta.version.isLatestPublished
  const { status } = parentDocumentORM.model
  // [TODO-2126] - We should have a property that combines isOnline and isContentCached
  //               on the DocumentVersionORM rather than checking both at the component level
  const unavailableContent = !isNetworkConnected && !documentVersionORM.meta.assets.isContentCached;
  const { deviceMode } = useAppSettings();
  const route = useCurrentPage({ exact: false });
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const canPresent = documentVersionORM.relations.document.meta.permissions.MSLPresent
  const defaultOnPress = canPresent
    ? documentVerActions.present(documentVersionORM)
    : documentVerActions.preview(documentVersionORM)
  const handleOnPress = () => {
    onPress?.(folderItemORM)
  }

  // [TODO-2126] - This thumbnail key should be calculated at the ORM level
  let customThumbnailKey: string | undefined
  const selectedThumbnail = documentVersionORM.model.selectedThumbnail ?? 1
  if (folderItemORM.model.visiblePages && !folderItemORM.model.visiblePages.find(vp => vp === selectedThumbnail)) {
    const customPage = documentVersionORM.model.pages[folderItemORM.model.visiblePages[0] - 1]
    customThumbnailKey = detectArchivedFileKeyPath(documentVersionORM.model, customPage) ?? undefined
  }
  return (
    <DNABox
      alignX="start"
      style={{ minWidth: 272, height: CUSTOM_DECK_CARD_MAX_HEIGHT }}
    >
      <DNACard
        interactive="pressable"
        onPress={handleOnPress}
        style={{ padding: 12 }}
      >
        <DNABox appearance="col" spacing="between" style={{ minHeight:'100%', opacity: unavailableContent ? 0.4 : 1 }}>
          <DNADocumentThumbnail
            documentVersionORM={documentVersionORM}
            width={248}
            height={142}
            unavailableContent={unavailableContent}
            customThumbnailKey={customThumbnailKey}
            onPress={
              isPublisherRoute ? handleOnPress : defaultOnPress
            }
          />
          <DNABox appearance="col" spacing="medium">
            <DNAText
              style={{ width: 208, overflow: 'hidden' }}
              numberOfLines={2}
              testID="folder-item-title"
            >
              {title}
            </DNAText>
            <DNABox appearance="col" spacing="small">
              <DNADivider />
              <DNABox alignY="center" spacing="between">
                <DNABox alignY="center" spacing="small">
                  <DNABox appearance="row">
                    <DNADocumentChip item={parentDocumentORM} variant="purpose" />
                    <Iffy is={isPublisherRoute}>
                      <DNABox style={{ marginHorizontal: 8 }}>
                        <DNADocumentChip
                          item={documentVersionORM.relations.document}
                          variant="status"
                        />
                      </DNABox>
                    </Iffy>
                  </DNABox>
                  <Iffy is={status === DocumentStatus.ARCHIVED}>
                    <DNAChip appearance="tag" status="subtle">{status}</DNAChip>
                  </Iffy>
                  <Iffy is={isModified && status === DocumentStatus.PUBLISHED}>
                    <DNAChip appearance="tag" status="subtle">MODIFIED</DNAChip>
                  </Iffy>
                  <Iffy is={unavailableContent}>
                    <DNAChip appearance="tag" style={{ backgroundColor: luxColors.basicBlack.primary }}>
                      NOT DOWNLOADED
                    </DNAChip>
                  </Iffy>
                </DNABox>
                <DNABox>
                  {/* Update Version Notification */}
                  <DNADocumentVersionUpdateButton orm={folderItemORM}/>
                  {/* Bookmark Button */}
                  <Iffy is={isLatestPublished && !unavailableContent && !isPublisherRoute}>
                    <DNAButton
                      status="subtle"
                      appearance="ghostAlt"
                      context={deviceMode === 'tablet' ? 'roundTablet' : 'minimum'}
                      onPress={documentVerActions.bookmark(documentVersionORM)}
                      iconLeft={isBookmarked ? 'bookmark' : 'bookmark-outline'}
                      size={deviceMode === 'tablet' ? 'xlarge' : 'large'}
                      style={{ width:32, height:32 }}
                    />
                  </Iffy>
                  <DNADocumentVersionContextMenu folderItemORM={folderItemORM}>
                    <DNAButton
                      status="subtle"
                      appearance="ghostAlt"
                      context={deviceMode === 'tablet' ? 'roundTablet' : 'minimum'}
                      iconLeft="dots-vertical"
                      size={deviceMode === 'tablet' ? 'xlarge' : 'large'}
                      style={{ width:32, height:32 }}
                    />
                  </DNADocumentVersionContextMenu>
                </DNABox>
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNACard>
    </DNABox>
  );
}

export default DNADocumentFolderItemCard
