import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Icon } from '@ui-kitten/components';
import { Iffy } from '@alucio/lux-ui';
import { useDropzone } from 'react-dropzone';
import UploadDocumentsModal from '../Modals/UploadDocumentsModal';

const styles = StyleSheet.create({
  dropZone: {
    height: '100%',
    width: '100%',
  },
  contentContainer: {
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  dragActiveBorder: {
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'white',
    borderStyle: 'dashed',
    borderWidth: 2,
    flex: 1,
  },
  overlayDrag: {
    padding: 15,
    position: 'absolute',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
    zIndex: 1,
  },
  uploadIcon: {
    color: 'white',
    height: 100,
    width: 100,
  },
  uploadText: {
    color: 'white',
    fontSize: 28,
    fontWeight: 'bold',
  },
});

interface Props {
  children: ReactElement<any>;
  files: File[];
  onCloseModal: () => void;
  toggleMyUnpublishedDocument: (val: boolean) => void;
  isUserDocument?: boolean;
  disableDrag?: boolean;
}

// If we're on web, this wrapper will hold the Dropzone UI's elements and logic
function DocumentDropzone(props: Props) {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const onDrop = useCallback((files: File[]) => {
    !props.disableDrag && onFileSelected(files);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true });

  useEffect(() => {
    if (props.files.length) {
      onFileSelected(props.files);
    }
  }, [props.files]);

  function closeModal(): void {
    props.toggleMyUnpublishedDocument(true);
    setIsModalVisible(false);
    // Clear files if modal is closed
    props.onCloseModal()
  }

  function onFileSelected(files: File[]): void {
    if (files.length > 0) {
      setSelectedFiles(files);
      setIsModalVisible(true);
    }
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Iffy is={isDragActive && !props.disableDrag}>
        <DragOverlay />
      </Iffy>
      <View style={styles.dropZone}>
        <Iffy is={isModalVisible}>
          <UploadDocumentsModal
            files={selectedFiles}
            closeModal={closeModal}
            isUserDocument={props.isUserDocument}
          />
        </Iffy>
        {props.children}
      </View>
    </div>
  );
}

function DragOverlay(): ReactElement<View> {
  return (
    <View style={styles.overlayDrag}>
      <View style={styles.dragActiveBorder}>
        <Icon style={styles.uploadIcon} name="cloud-upload" />
        <Text style={styles.uploadText}>Drop files here to upload</Text>
      </View>
    </View>
  );
}

export default DocumentDropzone;
