import React, { useState } from 'react';
import { StyleSheet, View, Text, Pressable } from 'react-native';
import { Iffy, Icon } from '@alucio/lux-ui';

import { PurposeType } from '@alucio/aws-beacon-amplify/src/models'
import { DocumentVersionORM, FeatureFlags } from 'src/types/types';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag'

const styles = StyleSheet.create({
  emptyView: {
    marginTop: 12,
  },
  button: {
    alignSelf: 'flex-start',
    margin: 2,
  },
  iconContainer: {
    flexDirection: 'row',
  },
  icon: {
    backgroundColor: 'black',
    color: 'white',
  },
  iconText: {
    backgroundColor: 'white',
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: 13,
  },
})

interface RightBarProps {
  documentVersion: DocumentVersionORM;
}

function ExpandIcon({ toggleLongDescription = true, onPress }) {
  return (
    <Pressable onPress={onPress}>
      <View style={styles.iconContainer}>
        <Icon name={toggleLongDescription ? 'plus' : 'minus'} style={styles.icon} size={32} />
        <Text style={[styles.iconText, styles.subtitle]}>
          {'  Long Description'}
        </Text>
      </View>
    </Pressable>
  )
}

function LabelsSideBar(props: RightBarProps) {
  const { documentVersion } = props;
  const [toggleLongDescription, setToggleLongDescription] = useState<boolean>(!!documentVersion.model.longDescription);
  const enableEmailTemplates = useFeatureFlag(FeatureFlags.BEAC_2516_Enable_Document_Sharing_Templates)

  const customFields = Object.keys(documentVersion.meta.tenantFields.valuesMap).map((e, index: number) => {
    const customField = documentVersion.meta.tenantFields.valuesMap[e];
    return (customField.length > 0
      ? <React.Fragment key={`modal_custom_fields_${index}`}><View style={styles.emptyView} />
        <Iffy is={index > 0}>
          <View style={styles.emptyView} />
        </Iffy>
        <View>
          <Text style={styles.subtitle}>{e}</Text>
          {customField.map((value, index: number) => (<Text key={`custom_field_${index}`}>{value}</Text>))}
        </View>
      </React.Fragment>
      : null
    )
  });

  return (
    <View>
      <View>
        <Text style={styles.subtitle}>Purpose</Text>
        <Text>
          {documentVersion.model.purpose || PurposeType.INTERNAL_USE_ONLY}
        </Text>

        <View style={styles.emptyView} />
        <Iffy is={enableEmailTemplates}>
          <Text style={styles.subtitle}>Distribution Status</Text>
          <Text>
            {documentVersion.model.distributable ? 'Distributable' : 'Non-distributable'}
          </Text>
        </Iffy>

        <View style={styles.emptyView} />

        <Text style={styles.subtitle}>Short Description</Text>
        <Text>{documentVersion.model.shortDescription}</Text>

        <Iffy is={documentVersion.model.longDescription}>
          <>
            <View style={styles.emptyView} />
            <ExpandIcon
              onPress={() => setToggleLongDescription(p => !p)}
              toggleLongDescription={toggleLongDescription}
            />
          </>
        </Iffy>

        <Iffy is={documentVersion.meta.integration.source}>
          <View style={styles.emptyView} />
          <Text style={styles.subtitle}>Content Source</Text>
          <Text>{documentVersion.meta.integration.source}</Text>
        </Iffy>

        <Iffy
          is={
              documentVersion.model?.longDescription &&
              documentVersion.model?.longDescription !== '' &&
              !toggleLongDescription
            }
        >
          <>
            <Text>
              {documentVersion.model.longDescription}
            </Text>
          </>
        </Iffy>
      </View>
      <View style={styles.emptyView} />
      {customFields}
      <View style={styles.emptyView} />
    </View>
  )
}

export default LabelsSideBar;
