import { BroadcastChannel } from 'broadcast-channel';
import { WorkerChannelMessage } from '@alucio/core'
import Observable from 'zen-observable'

// [TODO-PWA] - Make this generic so allow other channels to use a similar functionality
class ObservableChannel extends BroadcastChannel<WorkerChannelMessage> {
  public observable: Observable<WorkerChannelMessage>
  private observerCB?: (msg: WorkerChannelMessage) => void
  private beforeHooks: (() => Promise<void>)[] = []

  constructor(...args: ConstructorParameters<typeof BroadcastChannel>) {
    super(...args)

    this.observable = new Observable<WorkerChannelMessage>(observer => {
      const obsHandler = (msg: WorkerChannelMessage) => {
        observer.next(msg)
        this.observerCB?.(msg)
      }

      this.addEventListener('message', obsHandler)

      return () => {
        this.removeEventListener('message', obsHandler)
      }
    })
  }

  public setOnCloseCallback(cb: () => void): void {
    if (this.options.idb) {
      this.options.idb.onclose = () => {
        this.options.idb?.onclose?.()
        cb()
      }
    }
  }

  public setObserverCB(cb: (msg: WorkerChannelMessage) => void) {
    this.observerCB = cb
    const CBListener = (msg: WorkerChannelMessage) => {
      this.observerCB?.(msg)
    }

    this.addEventListener('message', CBListener)
  }

  public registerBeforeHook(hook: () => Promise<void>) {
    this.beforeHooks.push(hook)
  }

  // [TODO-1904] - Probably a way to do this with Decorators? Maybe proxies to preserve the original postMessage name
  public async postMessageExtended(msg: WorkerChannelMessage): Promise<void> {
    for (const beforeHook of this.beforeHooks) {
      await beforeHook()
    }

    return this.postMessage(msg)
  }
}

// [TODO-PWA]
//  - Consider not using BroadcastChannel due if mobile safari has issues :(
//  - Can probably do it through normal worker/client communication
//  - https://developer.mozilla.org/en-US/docs/Web/API/Client/postMessage

/**
 * IOS workaround
 * https://github.com/pubkey/broadcast-channel#handling-indexeddb-onclose-events
 */
let workerChannel: ObservableChannel

export const createChannel = () => {
  workerChannel = new ObservableChannel(
    'WORKER_CHANNEL',
    {
      idb: {
        onclose: () => {
          workerChannel.close()
          createChannel()
        },
      },
    },
  )

  // [NOTE] - Pulse check if need to test broadcast-channel reliability in iOS
  // setInterval(() => {
  //   workerChannel.postMessage({
  //     type: 'LOG_MESSAGE',
  //     level: 'DEBUG',
  //     message: 'Channel Pulse'
  //   })
  // }, 10000)
}

createChannel()

// @ts-expect-error
export default workerChannel
