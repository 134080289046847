import { User } from '@alucio/aws-beacon-amplify/src/models';
import { DNABox, DNAButton, DNAIcon, DNAModal, DNAText, Iffy, InformationMessage } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Input } from '@ui-kitten/components';
import React, { useState, useEffect } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import ActiveUser from 'src/state/global/ActiveUser';
import { useTenant } from 'src/state/redux/selector/tenant';
import { DNAModalVariant } from '../../DNAConnectedModal';
import { isUserType, ShareFilter, ShareFolderMode } from '../DNAShareFolderModal';
import UserList from './UserList';

const styles = StyleSheet.create(({
  searchBar: {
    backgroundColor: colors['color-text-basic'],
    borderColor: colors['color-flat-400'],
  },
}))
interface AddPeopleProps {
  display: boolean
  setMode: (mode: ShareFolderMode) => void
  currentItems: ShareFilter[]
  onAddItem: (item: User) => void
}

const SHARE_LIMIT = 25;
const AddPeople : DNAModalVariant<AddPeopleProps> = (props) => {
  const { display, toggleModal, setMode, currentItems, onAddItem } = props;
  // note: we have to use non-null assertion here because we cannot mess with the order of hooks
  const tenant = useTenant(ActiveUser.user!.tenantId);
  const sortedTenantUsers = tenant?.meta.users.filter((user) => user.id !== ActiveUser.user?.id!).sort((a, b) => {
    const aName  = a.givenName + ' ' + a.familyName;
    const bName  = b.givenName + ' ' + b.familyName;
    return aName.toLocaleLowerCase().localeCompare(bName.toLocaleLowerCase());
  }) || [];
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const { deviceMode } = useAppSettings();
  const isDesktop = deviceMode === DeviceMode.desktop;
  const alreadySelectedUsers = currentItems.filter((item) => isUserType(item.filter) && !item.isDeleted);
  const totalSelected = alreadySelectedUsers.length + selectedUsers.length;

  const toggleCheckedItem = (user: User) => {
    setSelectedUsers((prev) => {
      if (prev.find((item) => item.id === user.id)) {
        return prev.filter((item) => item.id !== user.id);
      }
      if (totalSelected >= SHARE_LIMIT) {
        return prev;
      }
      return [...prev, user];
    });
  }

  const handleAddUsers = () => {
    selectedUsers.forEach((id) => onAddItem(id));
    setMode(ShareFolderMode.SHARE_FOLDER);
  }

  const renderCloseIcon = () => (
    <Iffy is={searchText}>
      <Pressable onPress={() => setSearchText('')}>
        <DNAIcon.Styled
          name="close-circle"
          appearance="ghost"
          status="flat"
          context="round"
        />
      </Pressable>
    </Iffy>
  )

  useEffect(() => {
    setSelectedUsers([]);
  }, [display])

  return (
    <DNABox testID="add-people-modal" style={{ display : display ? undefined : 'none' }}>
      <DNAModal>
        <DNAModal.Header>
          {/* DESKTOP HEADER */}
          <Iffy is={isDesktop}>
            <DNABox appearance="rowReverse" spacing="between" alignY="center" style={{ minWidth: 600 }}>
              <DNAButton iconLeft="close" onPress={toggleModal} appearance="ghost" status="neutral" size="medium" />
            </DNABox>
          </Iffy>
          {/* TABLET HEADER */}
          <Iffy is={!isDesktop}>
            <DNABox appearance="row" spacing="between" alignY="center" style={{ minWidth: 600 }}>
              <DNAButton iconLeft="close" onPress={toggleModal} appearance="ghost" status="neutral" size="medium" />
              <DNAButton
                size="large"
                appearance="ghost"
                status="primary"
                onPress={handleAddUsers}
                testID="add-people-done-button"
              >
                Done
              </DNAButton>
            </DNABox>
          </Iffy>
        </DNAModal.Header>
        <DNAModal.Body>
          <DNABox appearance="col" spacing="medium" fill style={{ margin : 24 }}>
            <Iffy is={totalSelected >= SHARE_LIMIT}>
              <InformationMessage
                text={`${SHARE_LIMIT} people limit reached`}
                variance="primary"
              />
            </Iffy>
            <DNAText bold>Select up to {SHARE_LIMIT} | {totalSelected} selected</DNAText>
            <Input
              placeholder="Search names or emails"
              value={searchText}
              onChangeText={setSearchText}
              accessoryRight={renderCloseIcon}
              style={styles.searchBar}
              testID="search-team-members"
            />
            <DNABox appearance="col" style={{ height: 308 }}>
              <UserList
                users={sortedTenantUsers}
                onPress={toggleCheckedItem}
                currentItems={currentItems}
                selectedUsers={selectedUsers}
                searchText={searchText}
              />
            </DNABox>
          </DNABox>
        </DNAModal.Body>
        <DNAModal.Footer>
          {/* DESKTOP FOOTER ACTIONS */}
          <DNABox appearance="rowReverse" style={{ width: '100%' }}>
            <Iffy is={isDesktop}>
              <DNAButton
                size="small"
                status="primary"
                onPress={handleAddUsers}
                testID="add-people-done-button"
              >
                Done
              </DNAButton>
            </Iffy>
          </DNABox>
        </DNAModal.Footer>
      </DNAModal>
    </DNABox>
  )
}

export default AddPeople;
