import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNADivider, DNASpinner } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { PERFORMING_ACTION } from './AddMeeting';

const styles = StyleSheet.create({
  content: {
    backgroundColor: colors['color-text-basic'],
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
});

interface Props {
  closeDrawer: () => void,
  isFormEdited?: boolean,
  isSubmitting?: boolean,
  isSaved?: boolean,
  onDelete: () => void,
  onSave: () => void,
  action: PERFORMING_ACTION,
}

const AddMeetingFooter = ({ onSave, onDelete, closeDrawer, action, isSubmitting, isSaved, isFormEdited }: Props) => {
  return (
    <DNABox fill appearance="col" alignY="end">
      <DNADivider />
      <DNABox spacing="between" alignY="center" style={styles.content}>
        <DNABox spacing="tiny">
          {action !== PERFORMING_ACTION.ADD && <DNAButton
            appearance="filled"
            size="xlarge"
            status="flat"
            context="moderate"
            style={{ paddingVertical: 5 }}
            iconRight="trash-can-outline"
            onPress={onDelete}
          />}
        </DNABox>
        <DNABox spacing="small">
          <DNAButton appearance="filled" status="flat" onPress={closeDrawer}>Cancel</DNAButton>
          <DNAButton
            style={{ maxHeight: 43 }}
            appearance="filled"
            status={isSaved ? 'success' : undefined}
            disabled={!isFormEdited && !isSaved}
            iconLeft={isSaved ? 'check' : undefined}
            onPress={onSave}
          >
            {
              isSaved ? 'Saved' : isSubmitting ? <DNASpinner size="tiny" status="info" /> : 'Save'
            }
          </DNAButton>
        </DNABox>
      </DNABox>
    </DNABox>
  );
};

AddMeetingFooter.displayName = 'AddMeetingFooter';

export default AddMeetingFooter;
