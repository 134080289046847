import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import store from '../../redux/store';
import { DataStore } from '@aws-amplify/datastore';
import { v4 as uuid } from 'uuid';
import { commonReducers, datastoreSave, initialState, SliceState } from './common';
import {
  CustomDeck,
  CustomDeckGroup,
  DocumentStatus,
  DocumentVersion,
  DocumentVersionChangeType,
  Folder,
  FolderItem,
  FolderItemStatus,
  FolderItemType,
  FolderStatus,
  SharePermission,
} from '@alucio/aws-beacon-amplify/src/models';

import { DocumentORM, DocumentVersionORM, FolderItemORM, FolderORM } from 'src/types/types'
import {
  allFoldersFilteredAndSortedFactory,
  allSharedFoldersFilteredAndSortedFactory,
} from 'src/state/redux/selector/folder'
import addDays from 'date-fns/addDays'
import isPast from 'date-fns/isPast';
import ActiveUser from 'src/state/global/ActiveUser';
import cloneDeep from 'lodash/cloneDeep';

export const sliceName = 'folder'
const { reducers, extraReducers } = commonReducers<Folder>(sliceName)

// [TODO] There's no need for this to be async ...
// - Revisit this when making common async reducers more configurable
const cloneFolder = createAsyncThunk(
  'folder/cloneFolder',
  async ({ folder, isCopyingShared }: { folder: Folder, isCopyingShared?: boolean }) => {
    const now = new Date().toISOString();
    const newFolders: Folder[] = [];

    const copyFolder = async (folder: Folder, newName: string) => {
      const items = await Promise.all(folder.items.map(async (item: FolderItem) => {
        if (item.type === FolderItemType.FOLDER && item.status !== FolderItemStatus.REMOVED) {
          // Need to do a deep copy
          const subFolderORM = getFolderORMById(item.itemId, isCopyingShared);
          const subFolderCopy = await copyFolder(subFolderORM.model, subFolderORM.model.name);
          return {
            type: FolderItemType.FOLDER,
            status: FolderItemStatus.ACTIVE,
            id: uuid(),
            itemId: subFolderCopy.id,
            addedAt: now,
            itemLastUpdatedAt: now,
          }
        }
        else if (item.type === FolderItemType.CUSTOM_DECK && item.status !== FolderItemStatus.REMOVED) {
          const basedCustomDeck = await DataStore.query(CustomDeck, item.itemId)

          if (!basedCustomDeck) {
            throw new Error(`Could not find custom deck with id ${item.itemId}`)
          }

          const dupCustmDeck = await DataStore.save(
            new CustomDeck({
              ...cloneDeep(basedCustomDeck),
            }),
          )

          return Promise.resolve({
            ...item,
            id: uuid(),
            itemId: dupCustmDeck.id,
            addedAt: now,
            updateAcknowledgedAt: isCopyingShared ? now : item.updateAcknowledgedAt,
            itemLastUpdatedAt: now,
          });
        }
        else {
          return Promise.resolve({
            ...item,
            id: uuid(),
            addedAt: now,
            updateAcknowledgedAt: isCopyingShared ? now : item.updateAcknowledgedAt,
            itemLastUpdatedAt: now,
          });
        }
      }));
      // THE REASON OF CREATING THEM AS REMOVED IS TO AVOID NESTED ONES
      // TO BE SHOWN IN ROOT BEFORE BEING ASSIGNED TO THEIR PARENTS
      const newFolderWithItems = await DataStore.save<Folder>(
        new Folder({
          ...folder,
          status: FolderStatus.REMOVED,
          updatedBy: ActiveUser?.user?.id!,
          createdBy: ActiveUser?.user?.id!,
          // @ts-ignore
          createdAt: now,
          // @ts-ignore
          updatedAt: now,
          items: items,
          name: newName,
        }),
      );
      newFolders.push(newFolderWithItems);
      return newFolderWithItems;
    };
    try {
      const newFolder = await copyFolder(folder, `${!isCopyingShared ? 'Copy_' : ''}${folder.name}`);

      // ACTIVATE THE SUBFOLDERS
      await Promise.all(newFolders.reverse().map((subfolder) =>
        DataStore.save(
          Folder.copyOf(subfolder, draft => {
            // @ts-ignore
            draft.status = FolderStatus.ACTIVE;
          }),
        ),
      ));
      return newFolder;
    } catch (ex) {
      // To log in the logger tool
      console.error(`update status ${JSON.stringify(ex)}`);
      throw new Error(`error creating updating the status of ${JSON.stringify(ex)}`);
    }
  },
);

/** ORM HELPERS
 *  These ORM helpers help us grab the latest version of an ORM
 *    Components can sometimes have a stale ref to an ORM
 *    and saving to DataStore with a model that has an older version number will cause conflict
 *    resolution to happen on the backend. This is problematic for arrays at the moment
 *
 *  While most components strive to have the latest ORM, it isn't 100% guaranteed
 *    So we can do it at dispatch time instead
 *
 *  This however doesn't completely guarantee conflict-free versions
 *    If a dispatch still happens before the update callback subscription comes back
 *    We'll may still send a model with the same version twice (causing resolution)
*/
const getLatestFolderORM = (folderORM: FolderORM) => {
  return getFolderORMById(folderORM.model.id);
}

const getFolderORMById = (id: string, sharedFolders?: boolean) => {
  const selector = sharedFolders
    ? allSharedFoldersFilteredAndSortedFactory()
    : allFoldersFilteredAndSortedFactory();

  const [folder] = selector(
    store.getState(),
    undefined,
    { filter: { model: { id } } },
  ) as FolderORM[]

  if (!folder) {
    throw new Error(`Could not get folder ORM with id ${id}`)
  }

  return folder
}

// [TODO] - These shouldn't be optional (even though it's a failsafe)
// From using DocumentORM.meta
interface removeFilesFromFolderProps {
  folderId?: string,
  documentId?: string,
  index?: number,
}

interface documentVersionInfoMapEntry {
  latestPublishedVersionNumber: number,
  latestPublishedVersionId: string,
  latestVersionPublishedAt: string,
  latestVersionChangeType: DocumentVersionChangeType,
  isPastExpiration: boolean,
  isMajorUpdate: (documentVersionId: string) => boolean,
}

export const createDocumentVersionInfoLookup = (
  updatedDocVersions: DocumentVersion[],
  gracePeriodDays: number): Map<string, documentVersionInfoMapEntry> => {
  const docLookup: Map<string, documentVersionInfoMapEntry> = new Map()
  const convertToRecord = (docVer: DocumentVersion) => {
    return {
      latestPublishedVersionNumber: docVer.versionNumber,
      latestPublishedVersionId: docVer.id,
      latestVersionPublishedAt: docVer.updatedAt,
      latestVersionChangeType: docVer.changeType ?? 'MAJOR',
      isPastExpiration: isPast(addDays(new Date(docVer.updatedAt), gracePeriodDays)),
    }
  };
  updatedDocVersions.forEach((docVer) => {
    if (docVer.status === DocumentStatus.PUBLISHED) {
      if (docLookup[docVer.documentId]) {
        if (docVer.versionNumber > docLookup[docVer.documentId].latestPublishedVersionNumber) {
          docLookup[docVer.documentId] = convertToRecord(docVer)
        }
      } else {
        docLookup[docVer.documentId] = convertToRecord(docVer)
      }
    }
  });
  return docLookup;
}

const folderSlice = createSlice({
  name: sliceName,
  initialState: initialState<Folder>(),
  reducers: {
    ...reducers,
    // [TODO] - A bit WET, can just have one that takes the status
    //  - However, this leaves a better structure if it individual reducers need to do anything else
    archive: {
      prepare: (folderORM: FolderORM) => {
        return {
          payload: {
            model: Folder,
            entity: folderORM.model,
            updates: {
              status: FolderStatus.ARCHIVED,
              updatedAt: (new Date()).toISOString(),
              updatedBy: ActiveUser?.user?.id,

            },
          },
        }
      },
      reducer: reducers.save,
    },
    unarchive: {
      prepare: (folderORM: FolderORM) => {
        return {
          payload: {
            model: Folder,
            entity: folderORM.model,
            updates: {
              updatedAt: (new Date()).toISOString(),
              updatedBy: ActiveUser?.user?.id,
              status: FolderStatus.ACTIVE,
            },
          },
        }
      },
      reducer: reducers.save,
    },
    delete: {
      prepare: (folderORM: FolderORM) => {
        return {
          payload: {
            model: Folder,
            entity: folderORM.model,
            updates: {
              updatedAt: (new Date()).toISOString(),
              updatedBy: ActiveUser?.user?.id,
              status: FolderStatus.REMOVED,
            },
          },
        }
      },
      reducer: reducers.save,
    },
    // This is only used when copying a sub-folder otherwise cloneFolder is used
    duplicate: {
      prepare: (folderORM: FolderORM) => {
        if (!folderORM.relations.parentFolder) {
          throw new Error('This is not a nested folder')
        }
        const now = new Date().toISOString();
        const items = folderORM.model.items?.map(item => {
          return {
            ...item,
            addedAt: now,
            itemLastUpdatedAt: now,
          }
        })
        return {
          payload: {
            parentFolder: folderORM.relations.parentFolder.model,
            folderName: `Copy of ${folderORM.model.name}`,
            items,
          },
        }
      },
      reducer: (
        state: SliceState<Folder>,
        action: PayloadAction<{
          parentFolder: Folder
          folderName: string,
          items: FolderItem[],
        }>,
      ): void => {
        const now = new Date().toISOString();
        const { parentFolder, folderName, items } = action.payload;

        if (!ActiveUser.user) { return; }

        const newDuplicateFolder = new Folder({
          name: folderName,
          tenantId: ActiveUser.user.tenantId,
          status: FolderStatus.ACTIVE,
          items,
          isPinned: false,
          createdBy: ActiveUser.user.id,
          updatedBy: ActiveUser.user.id,
        })

        DataStore
          .save(newDuplicateFolder)
          .then(newSubFolderRes => {
            const newSubFolderItem = new FolderItem({
              // @ts-ignore
              id: uuid(),
              addedAt: now,
              itemId: newSubFolderRes.id,
              itemLastUpdatedAt: now,
              status: FolderItemStatus.ACTIVE,
              type: FolderItemType.FOLDER,
            })

            const items = parentFolder.items
              ? [...parentFolder.items, newSubFolderItem]
              : [newSubFolderItem]

            datastoreSave(Folder, parentFolder, { items })
          })
      },
    },
    rename: {
      prepare: (name: string, folder: Folder) => {
        const updatedAt = new Date().toISOString();
        return {
          payload: {
            model: Folder,
            entity: folder,
            updates: {
              name,
              updatedAt,
              updatedBy: ActiveUser?.user?.id,
            },
          },
        }
      },
      reducer: reducers.save,
    },
    create: (
      _state: SliceState<Folder>,
      action: PayloadAction<{ name: string }>,
    ): void => {
      const { name } = action.payload

      if (!ActiveUser.user) {
        throw new Error('Could not get current user')
      }

      DataStore.save(
        new Folder({
          name: name,
          tenantId: ActiveUser.user.tenantId,
          status: FolderStatus.ACTIVE,
          items: [],
          isPinned: false,
          updatedBy: ActiveUser.user.id,
          createdBy: ActiveUser.user.id,
        }),
      ).then((newFolder) => {
        analytics?.track('FOLDER_CREATE', {
          action: 'CREATE',
          category: 'FOLDER',
          folderId: newFolder.id,
        });
      });
    },
    updateSharePermission: {
      prepare: (sharePermissions: SharePermission[], folder: Folder) => {
        const updatedAt = new Date().toISOString();
        return {
          payload: {
            model: Folder,
            entity: folder,
            updates: {
              sharePermissions,
              updatedAt,
              updatedBy: ActiveUser?.user?.id,
            },
          },
        }
      },
      reducer: reducers.save,
    },
    createSubFolder: {
      prepare: (parentFolder: FolderORM, folderName: string) => {
        const latestFolderORM = getLatestFolderORM(parentFolder)

        return {
          payload: {
            parentFolder: latestFolderORM.model,
            folderName,
          },
        }
      },
      reducer: (
        state: SliceState<Folder>,
        action: PayloadAction<{
          parentFolder: Folder
          folderName: string,
        }>,
      ): void => {
        const now = new Date().toISOString();
        const { parentFolder, folderName } = action.payload

        if (!ActiveUser.user) {
          return;
        }

        const newSubFolder = new Folder({
          name: folderName,
          tenantId: ActiveUser.user.tenantId,
          status: FolderStatus.ACTIVE,
          items: [],
          isPinned: false,
          createdBy: ActiveUser.user.id,
          updatedBy: ActiveUser.user.id,
        })

        DataStore
          .save(newSubFolder)
          .then(newSubFolderRes => {
            const newSubFolderItem = new FolderItem({
              // @ts-ignore
              id: uuid(),
              type: FolderItemType.FOLDER,
              itemId: newSubFolderRes.id,
              status: FolderItemStatus.ACTIVE,
              addedAt: now,
              itemLastUpdatedAt: now,
            })

            const items = parentFolder.items
              ? [...parentFolder.items, newSubFolderItem]
              : [newSubFolderItem]

            datastoreSave(Folder, parentFolder, { items })

            analytics?.track('FOLDER_CREATE', {
              action: 'CREATE',
              category: 'FOLDER',
              folderId: newSubFolderRes.id,
            });
          })
      },
    },
    createCustomDeck: {
      prepare: (targetFolder: FolderORM, selectedSlides: CustomDeckGroup[], title: string) => {
        const latestFolderORM = getLatestFolderORM(targetFolder)
        return {
          payload: {
            targetFolder: latestFolderORM.model,
            selectedSlides,
            title,
          },
        }
      },
      reducer: (
        _,
        action: PayloadAction<{
          targetFolder: Folder,
          selectedSlides: CustomDeckGroup[],
          title: string,
        }>,
      ): void => {
        const now = new Date().toISOString();
        const { targetFolder, selectedSlides, title } = action.payload;

        if (!ActiveUser.user) {
          return;
        }

        const newCustomDeck = new CustomDeck({
          createdBy: ActiveUser.user.id,
          autoUpdateAcknowledgedAt: now,
          updatedBy: ActiveUser.user.id,
          tenantId: ActiveUser.user.tenantId,
          groups: selectedSlides,
        })

        DataStore
          .save(newCustomDeck)
          .then(newCustomDeckRes => {
            const newFolderItem = new FolderItem({
              // @ts-ignore
              id: uuid(),
              type: FolderItemType.CUSTOM_DECK,
              itemId: newCustomDeckRes.id,
              status: FolderItemStatus.ACTIVE,
              addedAt: now,
              itemLastUpdatedAt: now,
              customTitle: title,
            })

            const items = targetFolder.items
              ? [...targetFolder.items, newFolderItem]
              : [newFolderItem]

            datastoreSave(Folder, targetFolder, { items })

            analytics?.track('CUSTOM_SAVE', {
              action: 'SAVE',
              category: 'CUSTOM',
              customDeckId: newCustomDeck.id,
            });
          })
      },
    },
    updateCustomDeck: {
      prepare: (
        targetFolder: FolderORM,
        folderItemORM: FolderItemORM,
        existingCustomDeck: CustomDeck,
        updatedSelectedSlides: CustomDeckGroup[],
        title: string,
      ) => {
        const latestFolderORM = getLatestFolderORM(targetFolder)

        analytics?.track('CUSTOM_SAVE', {
          action: 'SAVE',
          category: 'CUSTOM',
          customDeckId: existingCustomDeck.id,
        })
        return {
          payload: {
            targetFolder: latestFolderORM.model,
            folderItemORM,
            existingCustomDeck,
            updatedSelectedSlides,
            title,
          },
        }
      },
      reducer: (
        _,
        action: PayloadAction<{
          targetFolder: Folder,
          folderItemORM: FolderItemORM,
          existingCustomDeck: CustomDeck,
          updatedSelectedSlides: CustomDeckGroup[],
          title: string,
        }>,
      ): void => {
        const now = new Date().toISOString();
        const {
          targetFolder,
          folderItemORM,
          existingCustomDeck,
          updatedSelectedSlides,
          title,
        } = action.payload
        const folderItem = folderItemORM.model

        if (!ActiveUser.user) {
          return;
        }

        DataStore
          .save(
            CustomDeck.copyOf(existingCustomDeck, updatedCustomDeck => {
              updatedCustomDeck.groups = updatedSelectedSlides
              updatedCustomDeck.autoUpdateAcknowledgedAt = now
              /** This is a hacky workaround for the conditional check above for ActiveUser.user. Apparently, within the
               * scope of the CustomDeck.copyOf, the TS compiler has no awareness of the check that was previously preformed.
               * There may be a better implementation for this that allows for us not to make this conditional statement here. */
              if (ActiveUser.user) {
                updatedCustomDeck.updatedBy = ActiveUser.user.id
              }
            }),
          )
          .then(() => {
            // ONLY UPDATE THE FOLDER, IF THE TITLE OF THE FOLDERITEM CHANGED.
            if (folderItem.customTitle === title) {
              return;
            }

            const items =
              targetFolder.items
                .map(item => item.id === folderItem.id
                  ? {
                    ...item,
                    customTitle: title,
                  }
                  : item,
                )

            datastoreSave(Folder, targetFolder, { items })
          })
      },
    },
    addDocument: {
      prepare: (targetDocORM: DocumentORM, targetFolderORM: FolderORM) => {
        const now = new Date().toISOString();
        const { latestUsableDocumentVersion } = targetDocORM.relations.version

        const documentId = latestUsableDocumentVersion.model.id;

        if (!documentId) {
          throw new Error('Could not find latest published version')
        }

        return {
          payload: {
            model: Folder,
            entity: targetFolderORM.model,
            updates: {
              items: [
                ...targetFolderORM.model.items ?? [],
                {
                  id: uuid(),
                  type: FolderItemType.DOCUMENT_VERSION,
                  itemId: documentId,
                  addedAt: now,
                  status: FolderItemStatus.ACTIVE,
                  // FOR TESTING we set this to null so we can see the update warning
                  // updateAcknowledgedAt: now,
                  updateAcknowledgedAt: now,
                  itemLastUpdatedAt: now,
                } as FolderItem,
              ],
            },
          },
        }
      },
      reducer: reducers.save,
    },
    addFolderItem: {
      prepare: (folderItem: FolderItemORM, targetFolderORM: FolderORM) => {
        const now = new Date().toISOString();
        return {
          payload: {
            model: Folder,
            entity: targetFolderORM.model,
            updates: {
              items: [
                ...targetFolderORM.model.items ?? [],
                {
                  ...folderItem.model,
                  addedAt: now,
                  status: FolderItemStatus.ACTIVE,
                  updateAcknowledgedAt: now,
                  itemLastUpdatedAt: now,
                },
              ],
            },
          },
        };
      },
      reducer: reducers.save,
    },
    acknowledgeAutoUpdate: {
      prepare: (folderItems: FolderItemORM[]) => {
        // Get folders ORMs that contains items to be updated
        const foldersWithUpdates = new Set<FolderORM>()
        folderItems.forEach(folderItemORM => {
          // Making sure to have the latest folder orm version
          foldersWithUpdates.add(getLatestFolderORM(folderItemORM.relations.parent!))
        })

        return {
          payload: {
            foldersWithUpdates,
            folderItems,
          },
        }
      },
      reducer: (
        _state: SliceState<Folder>,
        action: PayloadAction<{
          foldersWithUpdates: Set<FolderORM>,
          folderItems: FolderItemORM[]
        }>,
      ) => {
        const { foldersWithUpdates, folderItems } = action.payload;
        const currTS = new Date().toISOString()

        foldersWithUpdates.forEach(folderORM => {
          let hasChanges = false
          const updatedItems = folderORM.model.items
            ?.map(item => {
              const updatedFolderItemORM = folderItems.find(obj => obj.model.id === item.id)
              if (updatedFolderItemORM) {
                hasChanges = true

                analytics?.track('FOLDER_ITEM_ACKNOWLEDGE_UPDATE', {
                  action: 'ITEM_ACKNOWLEDGE_UPDATE',
                  category: 'FOLDER',
                  folderItemId: updatedFolderItemORM.model.id,
                });

                return {
                  ...item,
                  updateAcknowledgedAt: currTS,
                  itemLastUpdatedAt: currTS,
                }
              }

              return item
            })

          if (hasChanges) {
            datastoreSave<Folder>(Folder, folderORM.model, {
              items: updatedItems,
            })
          }
        })
      },
    },
    updateItemPagesAndTitle: {
      prepare: (
        folderItemORM: FolderItemORM,
        updates: { visiblePages: number[] | undefined, customTitle: string | undefined },
      ) => {
        const folderORM = folderItemORM.relations.parent!
        const updatedItems = folderORM
          .model
          .items
          ?.map(item => item.id === folderItemORM.model.id
            ? {
              ...item,
              visiblePages: updates.visiblePages,
              customTitle: updates.customTitle,
              itemLastUpdatedAt: new Date().toISOString(),
            }
            : item
          )
        return {
          payload: {
            model: Folder,
            entity: folderORM.model,
            updates: {
              items: updatedItems,
            },
          },
        }
      },
      reducer: reducers.save,
    },
    updateDocumentVersion: {
      prepare: (folderItems: FolderItemORM[], isAutoUpdate: boolean) => {
        // Get folders ORMs that contains items to be updated
        const foldersWithUpdates = new Set<FolderORM>()
        folderItems.forEach(folderItemORM => {
          if (folderItemORM.model.type !== FolderItemType.DOCUMENT_VERSION) {
            throw new Error('Version Update can only be done on DocumentVersion Folder Items');
          }

          if (!folderItemORM.relations.parent) {
            throw new Error('Could not identify folder to update items in')
          }

          // Making sure to have the latest folder orm version
          foldersWithUpdates.add(getLatestFolderORM(folderItemORM.relations.parent!))
        })

        return {
          payload: {
            foldersWithUpdates,
            folderItems,
            isAutoUpdate,
          },
        }
      },
      reducer: (
        _state: SliceState<Folder>,
        action: PayloadAction<{
          foldersWithUpdates: Set<FolderORM>,
          folderItems: FolderItemORM[],
          isAutoUpdate: boolean,
        }>,
      ) => {
        const { foldersWithUpdates, folderItems, isAutoUpdate } = action.payload;

        // Validate items and build Folders Map
        foldersWithUpdates.forEach(folderORM => {
          let hasChanges = false

          const updatedItems = folderORM.model.items
            ?.map(item => {
              const updatedFolderItemORM = folderItems.find(obj => obj.model.id === item.id)
              if (updatedFolderItemORM) {
                hasChanges = true

                const currDocVersion = updatedFolderItemORM.relations.item as DocumentVersionORM
                const targetParentDoc = currDocVersion.relations.document
                const targetNewDocVerId = targetParentDoc.relations.version.latestDocumentVersionPublished?.model.id

                if (!targetNewDocVerId) {
                  throw new Error('Could not determine document version to update to')
                }

                analytics?.track('FOLDER_ITEM_UPDATE_VERSION', {
                  action: isAutoUpdate ? 'ITEM_AUTO_UPDATE' : 'ITEM_UPDATE_VERSION',
                  category: 'FOLDER',
                  folderItemId: updatedFolderItemORM.model.id,
                });

                return {
                  ...item,
                  itemId: targetNewDocVerId,
                  itemLastUpdatedAt: new Date().toISOString(),
                  updateAcknowledgedAt: !isAutoUpdate ? new Date().toISOString() : undefined,
                  customTitle: undefined,
                  visiblePages: undefined,
                }
              }

              return item
            })

          if (hasChanges) {
            datastoreSave<Folder>(Folder, folderORM.model, {
              items: updatedItems,
            })
          }
        })
      },
    },
    applyAutoUpdate: {
      prepare: (updatedDocVersions: DocumentVersion[],
        gracePeriodDays: number) => {
        return {
          payload: {
            updatedDocVersions,
            gracePeriodDays,
          },
        }
      },
      reducer: (
        _state: SliceState<Folder>,
        action: PayloadAction<{
          updatedDocVersions: DocumentVersion[],
          gracePeriodDays: number
        }>,
      ) => {
        const { updatedDocVersions, gracePeriodDays } = action.payload;
        const docLookup = createDocumentVersionInfoLookup(updatedDocVersions, gracePeriodDays);
        const allFolders = _state.records.filter((rec) => rec.status !== FolderStatus.REMOVED);
        allFolders.forEach((folder: Folder) => {
          let hasUpdates = false
          const updatedItems = folder.items.map((item) => {
            if (item.type === FolderItemType.DOCUMENT_VERSION) {
              const [docId, versionNumber] = item.itemId.split('_')
              if (docLookup[docId] &&
                (docLookup[docId].latestPublishedVersionNumber > parseInt(versionNumber, 10)) &&
                docLookup[docId].isPastExpiration) {
                // Tracking event here where we have the folderItem
                analytics?.track('FOLDER_ITEM_AUTO_UPDATE', {
                  action: 'ITEM_AUTO_UPDATE',
                  category: 'FOLDER',
                  folderItemId: item.id,
                });

                // We need to auto-update the folder item
                hasUpdates = true;
                return {
                  ...item,
                  itemId: docLookup[docId].latestPublishedVersionId,
                  itemLastUpdatedAt: new Date().toISOString(),
                  updateAcknowledgedAt: undefined,
                  customTitle: undefined,
                  visiblePages: undefined,
                }
              }
            }
            return item;
          });
          if (hasUpdates) {
            datastoreSave<Folder>(Folder, folder, {
              items: updatedItems,
            })
          }
        })
      },
    },
    /** Per discussion with product/design, the expected behavior here is to replicate the MacOS finder pattern.
     * This assumes the following assertions:
     *  - Duplicates will intelligently find the next available increment number, i.e. if original, original copy 1,
     *    and original copy 3 exist, then duping will generate original copy 2.
     *  - Only title names are considered when determining dupe increment number, i.e. if DocA and DocB exist and
     *    DocB is renamed to DocA Copy 1, upon duping either file, DocA Copy 2 will be generated which contains the
     *    content from the selected doc.
     */
    duplicateItem: {
      prepare: (
        defaultTitle: string,
        targetFolderORM: FolderORM,
        itemToDupe: FolderItem,
      ) => {
        /** Utility function to return a unique set of array items */
        const uniqBy = (
          arr: FolderItem[],
          fn,
        ) =>
          [...new Map(
            arr
              .reverse()
              .map(
                (x) =>
                  [typeof fn === 'function'
                    ? fn(x)
                    : x[fn], x],
              ),
          ).values()].reverse()

        const targetFolderItems = targetFolderORM.model.items
        const targetFolderModel = targetFolderORM.model
        const itemToDupeCurrentTitle = itemToDupe?.customTitle || defaultTitle

        /** Check for 'Copy' & number at the end of the title */
        const titleSuffix = itemToDupeCurrentTitle?.split(' ').slice(-2)
        const titleSuffixStr = titleSuffix?.join(' ') || ''
        const titleIsCopy = titleSuffix.length > 1
          ? (titleSuffix?.[0] === 'Copy') && (titleSuffix?.[1].match(/^-?\d+$/))
          : false

        const titleRoot = titleIsCopy
          ? itemToDupeCurrentTitle?.substring(0, itemToDupeCurrentTitle.length - titleSuffixStr?.length - 1)
          : itemToDupeCurrentTitle

        /** Identify the existing folderitems with duplicate titles */
        const existingDupes = targetFolderItems
          .filter(({ customTitle, status }) => {
            return (
              itemToDupeCurrentTitle &&
              customTitle?.includes(titleRoot) &&
              customTitle !== titleRoot &&
              status !== FolderItemStatus.REMOVED
            )
          })
          .sort((a, b) => {
            const aCopyNumber = parseInt(a.customTitle?.split(' ').pop() || '0', 10)
            const bCopyNumber = parseInt(b.customTitle?.split(' ').pop() || '0', 10)
            return aCopyNumber - bCopyNumber
          })

        const uniqueDupes: FolderItem[] = uniqBy(existingDupes, 'customTitle')

        /** Prune out duplicates past a gap in the sequence number */
        const prunedDupes = uniqueDupes.filter((dupe, idx) => {
          const dupeNumber = parseInt(dupe.customTitle?.split(' ').pop() || '0', 10)
          return dupeNumber === idx + 1
        })

        /** Next number in the sequence shold be right after the gap we identified above */
        const sequenceNumber = prunedDupes.length + 1

        /** Generate new FolderItem with unique random id  */
        const currTS = new Date().toISOString()
        const duplicateItem: FolderItem = {
          ...itemToDupe,
          id: uuid(),
          customTitle: `${titleRoot} Copy ${sequenceNumber}`,
          addedAt: currTS,
          updateAcknowledgedAt: currTS,
          itemLastUpdatedAt: currTS,

        }

        const updatedItems = [...targetFolderItems, duplicateItem]

        return {
          payload: {
            model: Folder,
            entity: targetFolderModel,
            updates: {
              items: updatedItems,
            },
          },
        }
      },
      reducer: reducers.save,
    },
    removeItem: {
      prepare: (folderItem: FolderItemORM) => {
        const targetFolderORM = folderItem.relations.parent

        if (!targetFolderORM) {
          throw new Error('Could not identify folder to remove items from')
        }

        const updatedItems = targetFolderORM
          .model
          .items?.map(item => item.id === folderItem.model.id
            ? {
              ...item,
              status: FolderItemStatus.REMOVED,
              itemLastUpdatedAt: new Date().toISOString(),
            } : item)

        if (!updatedItems) {
          console.error('Folder has no items to remove')
        }

        return {
          payload: {
            model: Folder,
            entity: targetFolderORM.model,
            updates: {
              items: updatedItems,
            },
          },
        }
      },
      reducer: reducers.save,
    },
    removeFolder: (state: SliceState<Folder>, action: PayloadAction<FolderORM>) => {
      const folderORM = action.payload
      const parentFolderORM = folderORM.relations.parentFolder
      const currTS = new Date().toISOString()

      if (parentFolderORM) {
        datastoreSave(
          Folder,
          parentFolderORM.model,
          {
            items: parentFolderORM.model
              ?.items?.map(item => item.itemId === folderORM.model.id
                ? {
                  ...item,
                  status: FolderItemStatus.REMOVED,
                  itemLastUpdatedAt: currTS,
                } : item),
          },
        )
      }

      const recursiveRemove = (folder: FolderORM) => {
        const childrenFolders = folder
          .relations
          .items
          .filter(fol => fol.relations.item.type === 'FOLDER').map((item) => item.relations.item) as FolderORM[]

        childrenFolders.forEach(fol => recursiveRemove(fol))
        datastoreSave(Folder, folder.model, {
          status: FolderStatus.REMOVED,
          updatedAt: currTS,
          updatedBy: ActiveUser?.user?.id,
        })
      }

      recursiveRemove(folderORM)
    },
  },
  extraReducers,
});

export default folderSlice;
export const folderActions = {
  cloneFolder,
  ...folderSlice.actions,
};
