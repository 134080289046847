import React, { useState } from 'react';
import { StyleSheet, Text, ScrollView } from 'react-native';
import {
  DNAButton,
  DNABox,
  DNAText,
  useVisualViewportSize,
} from '@alucio/lux-ui';
import { useContentViewerModalState } from 'src/components/ContentPreviewModal/state/ContentViewerModalStateProvider';
import { PageExtended } from 'src/types/types';
import { FileType } from '@alucio/aws-beacon-amplify/src/models';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

const styles = StyleSheet.create({
  fontSelectorContainer: {
    opacity: 0.4,
  },
  fontSizeSelector: {
    color: colors['color-flat-800'],
    fontSize: 12,
  },
  presenterNotes: {
    paddingBottom: 20,
    paddingLeft: 12,
    paddingRight: 12,
    width: 280,
  },
  presenterNotesIcon: {
    height: 18,
    width: 18,
  },
  presenterNotesText: {
    fontSize: 12,
    lineHeight: 24,
  },
  presenterTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
});

const PresenterNotes = () => {
  const {
    activeSlide,
    activeDocVersion: documentVersion,
    visiblePages,
    customDeck,
  } = useContentViewerModalState();

  // When switching versions the doc is updated first then the slide #
  const page = customDeck
    ? visiblePages[activeSlide - 1]
    : documentVersion?.model.pages?.find(({ number }) => number === activeSlide);
  const [fontSize, setFontSize] = useState(14);
  const vvpSize = useVisualViewportSize()

  function toggleFontSize() {
    fontSize === 14 ? setFontSize(18) : setFontSize(14);
  }

  if (documentVersion?.model.type !== FileType.PPTX ||
    (customDeck && (page as PageExtended)?.documentVersion?.model.type !== FileType.PPTX)) {
    return null;
  }

  return (
    <DNABox
      fill
      appearance="col"
      style={[styles.presenterNotes]}
    >
      <DNABox
        wrap="center"
        alignY="center"
        alignX="end"
        spacing="small"
        style={{ paddingVertical: 12 }}
      >
        <DNAButton
          size="tiny"
          appearance="ghost"
          context="minimum"
          onPress={toggleFontSize}
        >
          <DNABox alignY="center" style={styles.fontSelectorContainer}>
            <Text style={styles.fontSizeSelector}>A</Text>
            <Text style={[styles.fontSizeSelector, { fontSize: 16, marginLeft: 2, marginBottom: 2 }]}>A</Text>
          </DNABox>
        </DNAButton>
      </DNABox>

      <DNABox
        as={ScrollView}
        // @ts-ignore `as` prop doesn't have proper type safety
        horizontal={false}
        fill
        style={{ height: vvpSize.height && vvpSize.height - 230 }}

      >
        <DNAText style={[styles.presenterNotesText, { fontSize: fontSize }]}>
          {page?.speakerNotes || 'No notes on this slide.'}
        </DNAText>
      </DNABox>
    </DNABox>
  );
};

PresenterNotes.displayName = 'PresenterNotes';

export default PresenterNotes;
