import { DNABox, DNAText } from '@alucio/lux-ui'
import React from 'react'

interface LabelRowProps {
    label: string
    values: Array<string>
}

const LabelRow: React.FC<LabelRowProps> = (props) => {
  const { label, values } = props
  const joinedValues = values.join(', ')

  return (
    <DNABox>
      <DNAText bold>{label}: <DNAText status="flatAlt">{joinedValues}</DNAText></DNAText>
    </DNABox>
  )
}

export default LabelRow
