import React from 'react'

import { DocumentORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions'
import { CustomLabelDisplay } from './CustomLabelDisplay'
import { DNABox, DNACard, DNAText, DNAButton, useTheme, Iffy } from '@alucio/lux-ui'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import DNADocumentContextMenu from 'src/components/DNA/Document/DNADocumentContextMenu/DNADocumentContextMenu'
import { DocumentAccessLevel } from '@alucio/aws-beacon-amplify/src/models'
import { format } from 'date-fns';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

export type DNADocumentDefaultRowProps = {
  onPin?: () => void,
  isDesktop?: boolean;
}

const DNADocumentDefaultRow: DNARowsComponent<
  DocumentORM,
  DNADocumentDefaultRowProps
> = (props) => {
  const {
    ORM: documentORM,
    onPin,
    onPress,
    style,
    isDesktop,
  } = props

  const theme = useTheme()

  const isBookmarked = documentORM.meta.bookmark.isBookmarked
  const documentActions = useDNADocumentActions()
  const isPersonalDocument = documentORM.model.accessLevel === DocumentAccessLevel.USER;

  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={() => { onPress?.(documentORM) }}
      style={[
        {
          paddingHorizontal: theme['row-padding-horizontal'],
          paddingVertical: theme['row-padding-vertical'],
        },
        style,
      ]}
    >
      <DNABox testID="folder-item-row" alignY="center" spacing="between" childFill={0}>
        <DNABox alignY="center" spacing="large" childFill={1}>
          <DNABox appearance="row" style={{ borderWidth: 1, borderColor: theme['color-flat-300'] }}>
            <Iffy is={!isDesktop}>
              <DNADocumentContextMenu documentORM={documentORM}>
                <DNAButton
                  status="subtle"
                  appearance="ghostAlt"
                  context={'roundTablet'}
                  iconLeft="dots-vertical"
                  size={'xlarge'}
                />
              </DNADocumentContextMenu>
            </Iffy>
            <DNADocumentThumbnail
              documentVersionORM={
                documentORM.relations.version.latestDocumentVersionPublished ||
                documentORM.relations.version.latestDocumentVersion
              }
              width={76}
              height={44}
              showProcessing
              // Assessing implementation in #BEAC-2155
              // onPress={() => { console.log('pressed default row') }}
            />
          </DNABox>
          {/* Title */}
          <DNABox
            spacing="small"
            appearance="col"
            childFill
            style={{ paddingRight: 16 }}
          >
            <DNAText testID="folder-item-row-name" b1 numberOfLines={1}>
              {documentORM.relations.version.latestDocumentVersionPublished?.model.title}
            </DNAText>
            {!isPersonalDocument &&
              <DNABox alignY="center" spacing="small" fill childFill={1}>
                <DNADocumentChip
                  item={documentORM}
                  variant="purpose"
                />
                <CustomLabelDisplay documentORM={documentORM} />
              </DNABox>}
          </DNABox>
        </DNABox>
        {/* Actions */}
        <DNABox spacing="small" alignY="center">
          <Iffy is={isPersonalDocument}>
            <DNAText testID="folder-item-row-date" style={{ color: colors['color-text-secondary'], marginRight: 57 }}>
              {format(new Date(documentORM.model.createdAt), 'MMM dd, yyyy')}
            </DNAText>
          </Iffy>
          <Iffy is={!isPersonalDocument}>
            <DNAButton
              status="subtle"
              appearance="ghostAlt"
              context="minimum"
              onPress={() => {
                documentActions.bookmark(documentORM)()
                onPin?.()
              }}
              iconLeft={isBookmarked ? 'bookmark' : 'bookmark-outline'}
            />
          </Iffy>
          <Iffy is={isDesktop}>
            <DNADocumentContextMenu documentORM={documentORM}>
              <DNAButton
                status="subtle"
                appearance="ghostAlt"
                context="minimum"
                iconLeft="dots-vertical"
              />
            </DNADocumentContextMenu>
          </Iffy>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default DNADocumentDefaultRow
