import { createSlice } from '@reduxjs/toolkit'
import { commonReducers, initialState } from './common'
import { Tenant } from '@alucio/aws-beacon-amplify/src/models'

const sliceName = 'tenant'
const { reducers, extraReducers } = commonReducers<Tenant>(sliceName)

const tenantSlice = createSlice({
  name: sliceName,
  initialState: initialState<Tenant>(),
  reducers,
  extraReducers,
})

export default tenantSlice
export const tenantActions = tenantSlice.actions
