import { EmailTemplateORM } from 'src/types/types'
import { FilterAndSortOptions } from 'src/state/redux/selector/common'

type FilterSortMap<T extends string> = {
  [K in T]: FilterAndSortOptions<EmailTemplateORM>
}

export const sorts: FilterSortMap<'titleAsc'> = {
  titleAsc: {
    sort: [
      { model: { title: 'asc' } },
    ],
  },
}

export default {
  sorts,
}
