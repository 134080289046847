import React, { useEffect, useState } from 'react';
import { Pressable, ScrollView } from 'react-native';
import {
  DocumentORM,
  DocumentVersionORM,
  FolderItemORM,
  FolderORM,
} from 'src/types/types'
import { useAllDocumentsInstance } from 'src/state/redux/selector/document'
import documentQuery from 'src/state/redux/document/query';

import { ContentORMs } from 'src/components/DNA/types'
import { DNABox, DNAIcon, Iffy, luxColors, DNAText } from '@alucio/lux-ui';
import SlideRoll from 'src/components/ContentPreviewModal/SlideRoll/SlideRoll';

import {
  useDNAFolderNav,
  withDNAFolderNav,
} from 'src/components/DNA/Folder/Nav/DNAFolderNav'

import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList'
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import { FilterAndSortOptions } from 'src/state/redux/selector/common'
import { DisplayMode, DisplayModes } from 'src/components/DNA/hooks/useDisplayMode'
import DNAGrid from 'src/components/DNA/Grid/DNAGrid'
import { useMSLDocumentSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import { getVisiblePages } from 'src/utils/documentHelpers';
import { Options } from '../../useMyContentPanelSharedResources';
import {
  isCustomDeckORM,
  isDocumentORM,
  isDocumentVersionORM,
  isFolderItemORM,
  isFolderORM,
} from 'src/types/typeguards'
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';

interface TabConfigProps {
  items: FolderItemORM[] | DocumentORM[]
  label: string
}
type TabConfigs = Record<Options, TabConfigProps>
type EmptyVariants = Record<Options, EmptyVariant>
type TabFilters = Record<Options, FilterAndSortOptions<FolderORM>>

/** TODO: This seems like something we can make shared */
const emptyVariants:EmptyVariants = {
  [Options.FOLDERS]: EmptyVariant.FolderListPresentEmpty,
  [Options.SHARED_WITH_ME]: EmptyVariant.FolderSharedPresentEmpty,
  [Options.BOOKMARKS]: EmptyVariant.FolderBookmarkPresentEmpty,
  [Options.LIBRARY]: EmptyVariant.DocumentListEmpty,
  [Options.SEARCH_RESULTS]: EmptyVariant.MeetingsDocumentResultsEmpty,
}

interface ContentProps {
  localSearchText: string,
  selectedTab: Options,
  setSelectedDocumentVersion: (doc: DocumentVersionORM | undefined, folderItemORM?: FolderItemORM) => void,
  setSelectedPage: (index: number | undefined, folderItemORM?: FolderItemORM) => void,
  displayMode: DisplayMode,
  searchText?: string,
  selectedDocumentVersion?: DocumentVersionORM,
  sessionId?: string,
  showPreview?: boolean,
}

const BOOKMARKED_DOCS_QUERY = documentQuery.merge(
  documentQuery.filters.published,
  documentQuery.filters.bookmarked,
  documentQuery.sorts.bookmarkedAsc,
)

const ALL_DOCS_QUERY = documentQuery.merge(
  documentQuery.sorts.updatedAtDesc,
  documentQuery.sorts.titleAsc,
  documentQuery.filters.published,
  documentQuery.sorts.bookmarkedAsc,
)
function ContentPresenterMode(props: ContentProps) {
  const {
    localSearchText,
    selectedTab,
    displayMode,
    searchText = '',
    selectedDocumentVersion,
    setSelectedDocumentVersion,
  } = props
  const {
    currentItems,
    pushFolder,
    popToFolder,
    folderStack,
    setDisplaySharedFolders,
    isCurrentlyNested,
  } = useDNAFolderNav()

  const [folderItemORM, setFolderItemORM] = useState<FolderItemORM | undefined>();

  const customDeck = (isCustomDeckORM(folderItemORM?.relations?.item) && folderItemORM?.relations?.item) || undefined

  const bookmarkedDocs = useAllDocumentsInstance(BOOKMARKED_DOCS_QUERY)
  const documents = useAllDocumentsInstance(ALL_DOCS_QUERY)
  const resultsFromSearch: DocumentORM[] = useMSLDocumentSearch({ text: searchText })
  const title = selectedDocumentVersion?.model.title

  const tabConfigs: TabConfigs = {
    [Options.FOLDERS]: {
      items: currentItems,
      label: 'My Folders',
    },
    [Options.SHARED_WITH_ME]: {
      items: currentItems,
      label: 'Shared With Me',
    },
    [Options.BOOKMARKS]: {
      items: bookmarkedDocs,
      label: 'Bookmarks',
    },
    [Options.LIBRARY]: {
      items: documents,
      label: 'Library',
    },
    // "Ghost tab". It works as a tab but is not shown as an option to be selected.
    // Will be applied upon a valid search text input value.
    [Options.SEARCH_RESULTS]: {
      items: resultsFromSearch,
      label: 'Search results',
    },
  }

  const { items, label } = tabConfigs[selectedTab]

  const { meetingORM } = useMeetingsState();
  const showPreview = meetingORM?.model.type === 'VIRTUAL'

  const onItemPress = (itemORM: ContentORMs) => {
    const isDocumentVersionInFolder =
      isFolderItemORM(itemORM) &&
      isDocumentVersionORM(itemORM.relations.item) &&
      itemORM.relations.item.relations.document.meta.permissions.MSLPresent

    // IF IT'S NOT A DOCUMENT VERSION FROM A FOLDER, WE WANT TO CLEAN THE STATE
    if (!isDocumentVersionInFolder) {
      setFolderItemORM(undefined);
    }

    if (isFolderORM(itemORM)) {
      pushFolder(itemORM)
    } else if (isFolderItemORM(itemORM)) {
      if (
        isDocumentVersionORM(itemORM.relations.item) &&
        itemORM.relations.item.relations.document.meta.permissions.MSLPresent
      ) {
        setFolderItemORM(itemORM);
        setSelectedDocumentVersion(itemORM.relations.item);
      } else if (isFolderORM(itemORM.relations.item)) {
        pushFolder(itemORM.relations.item)
      } else if (isCustomDeckORM(itemORM.relations.item)) {
        setFolderItemORM(itemORM);
        setSelectedDocumentVersion(undefined);
      }
    } else if (isDocumentORM(itemORM) && itemORM.meta.permissions.MSLPresent) {
      setSelectedDocumentVersion(itemORM.relations.version.latestDocumentVersionPublished)
    }
  }

  const emptyVariant = selectedTab !== Options.FOLDERS
    ? emptyVariants[selectedTab]
    : (isCurrentlyNested ? EmptyVariant.FileListPresentEmpty : EmptyVariant.FolderListPresentEmpty)

  const clearNavStack = () => {
    popToFolder();
    setFolderItemORM(undefined);
    setSelectedDocumentVersion(undefined);
  }

  const presentPage = (index: number) => {
    if (!selectedDocumentVersion && !isCustomDeckORM(folderItemORM?.relations.item)) {
      console.error('Could not find selected document to present')
      return;
    }
    props.setSelectedPage(index, folderItemORM);
  }

  const searchResultsText = selectedTab !== Options.SEARCH_RESULTS
    ? `${items.length} item(s)`
    : `Search results for "${localSearchText}" | ${items.length} result(s)`

  // On tab switch, clear the Nav Stack
  useEffect(() => { clearNavStack() }, [selectedTab]);

  // Disable display toggle if document is selected (slide roll view)
  useEffect(() => {
    displayMode.setEnableDisplayMode(!selectedDocumentVersion)
  }, [selectedDocumentVersion])

  useEffect(() => {
    /**
     * Suggest refactoring this to rely solely on the query param in DNAFolderNav.
     * This would require a minor rework of the shared folder tab in the My Folders
     * section as well.
     * */
    setDisplaySharedFolders(selectedTab === Options.SHARED_WITH_ME)
  }, [selectedTab])

  return (
    <DNABox fill>
      {
        <DNABox appearance="col" fill style={{ marginHorizontal: 32, marginTop: 16 }}>
          {/* Bread crumbs */}
          <DNABox alignY="center" style={{ marginBottom: 16 }}>
            <Pressable onPress={clearNavStack}>
              <DNAText numberOfLines={1} status="subtle">
                {label}
              </DNAText>
            </Pressable>

            {/* Render the Folder Stack as Breadcrumbs */}
            {folderStack.map(
              folderORM => (
                <DNABox shrink alignY="center">
                  <DNAIcon.Styled
                    name="chevron-right"
                    appearance="ghost"
                    status="subtle"
                    context="minimum"
                    style={{ marginTop: 2 }}
                  />
                  <Pressable
                    style={{ flexShrink: 1 }}
                    onPress={() => {
                      popToFolder(folderORM);
                      setFolderItemORM(undefined);
                      setSelectedDocumentVersion(undefined)
                    }}
                  >
                    <DNAText numberOfLines={1} status="subtle">
                      {folderORM.model.name}
                    </DNAText>
                  </Pressable>
                </DNABox>
              ),
            )}

            <Iffy is={selectedDocumentVersion || customDeck}>
              <DNABox shrink alignY="center">
                <DNAIcon.Styled
                  name="chevron-right"
                  appearance="ghost"
                  status="subtle"
                  context="minimum"
                  style={{ marginTop: 2 }}
                />
                <Pressable style={{ flexShrink: 1 }}>
                  <DNAText numberOfLines={1} status="subtle">
                    {title}
                  </DNAText>
                </Pressable>
              </DNABox>
            </Iffy>
            <Iffy is={selectedTab !== Options.FOLDERS && !selectedDocumentVersion}>
              <DNAText b2 status="subtle" style={{ marginLeft: 4 }}>
                {searchResultsText}
              </DNAText>
            </Iffy>
          </DNABox>

          {/* TODO: Offline states for the shared with me tab need to be defined and impelemented. BEAC-2823
            See: https://www.figma.com/file/2gMCgBkvFKk9rlqXvYxk6w/Viewer?node-id=13353%3A219223
          */}
          {/* FlatList */}
          <Iffy is={(!selectedDocumentVersion && displayMode.displayMode === DisplayModes.list) && !customDeck}>
            <DNAFlatList<(DocumentORM | DocumentVersionORM | FolderORM)>
              items={items}
              variant="meetings"
              appearance
              emptyVariant={emptyVariant}
              onPress={onItemPress}
              useEmptyContainer={false}
            />
          </Iffy>

          {/* Grid */}
          <Iffy is={(!selectedDocumentVersion && displayMode.displayMode === DisplayModes.grid) && !customDeck}>
            <DNABox fill appearance="col" as={ScrollView}>
              <DNAGrid<(DocumentORM | DocumentVersionORM | FolderORM) >
                items={items}
                /** TODO: update in BEAC-2822 */
                variant="virtual"
                emptyVariant={emptyVariant}
                onPress={onItemPress}
                useEmptyContainer={false}
              />
            </DNABox>
          </Iffy>

          <Iffy is={selectedDocumentVersion || customDeck}>
            <SlideRoll
              activeSlide={0}
              documentVersion={selectedDocumentVersion?.model!}
              isCustomDeck={!!customDeck}
              isGridView={true}
              itemHeight={126}
              itemWidth={216}
              numberColor={luxColors.subtitle.quaternary}
              setActiveSlide={presentPage}
              showPreview={showPreview}
              keepSpacingLastItem
              visiblePages={getVisiblePages(selectedDocumentVersion, folderItemORM, customDeck)}
            />
          </Iffy>
        </DNABox>

      }
    </DNABox>

  );
}

ContentPresenterMode.displayName = 'PanelContent';

export default withDNAFolderNav(ContentPresenterMode)
