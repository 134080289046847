import React from 'react'

import { DocumentORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import { CustomLabelDisplay } from '../default/CustomLabelDisplay'
import { DNABox, DNACard, DNAText, useTheme } from '@alucio/lux-ui'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import { DocumentAccessLevel } from '@alucio/aws-beacon-amplify/src/models';

const DNADocumentCustomDeckRow: DNARowsComponent<
  DocumentORM
> = (props) => {
  const {
    ORM: documentORM,
    onPress,
    style,
    isNetworkConnected,
  } = props

  const theme = useTheme()
  const isPersonalFile = documentORM.model.accessLevel === DocumentAccessLevel.USER;

  const unavailableContent = (
    !isNetworkConnected &&
    !documentORM.relations.version
      .latestDocumentVersionPublished
      ?.meta.assets.isContentCached
  )

  const processed = documentORM
    .relations.version.latestDocumentVersionPublished?.model.conversionStatus === 'PROCESSED';

  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={() => { processed && onPress?.(documentORM) }}
      style={[
        {
          paddingHorizontal: theme['row-padding-horizontal'],
          paddingVertical: theme['row-padding-vertical'],
        },
        style,
      ]}
    >
      <DNABox alignY="center" spacing="between" childFill={0}>
        <DNABox alignY="center" spacing="large" childFill={1}>
          <DNADocumentThumbnail
            unavailableContent={unavailableContent}
            documentVersionORM={
              documentORM.relations.version.latestDocumentVersionPublished ||
              documentORM.relations.version.latestDocumentVersion
            }
            width={76}
            height={44}
            showProcessing
            // Assessing implementation in #BEAC-2155
            // onPress={() => { console.log('pressed custom deck row') }}
          />
          {/* Title */}
          <DNABox
            spacing="small"
            appearance="col"
            childFill
            style={{ paddingRight: 16 }}
          >
            <DNAText b1 numberOfLines={1}>{
              documentORM.relations.version.latestDocumentVersionPublished?.model.title}
            </DNAText>
            {
              !isPersonalFile &&
                <DNABox alignY="center" spacing="small" fill childFill={1}>
                  <DNADocumentChip
                    item={documentORM}
                    variant="purpose"
                  />
                  <CustomLabelDisplay documentORM={documentORM} />
                </DNABox>
            }
          </DNABox>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default DNADocumentCustomDeckRow
