import React, { ReactElement } from 'react';
import { StyleSheet } from 'react-native';
import { Drawer } from '@alucio/lux-ui';
import { useDispatch, useSelector } from 'react-redux';
import { DRAWER_ENTITIES, drawerActions } from 'src/state/redux/slice/drawer';
import { RootState } from 'src/state/redux/store';
import UserEdit from '../UserEdit/UserEdit';
import EmailTemplateDrawer from '../EmailTemplateDrawer/EmailTemplateDrawer';
import AddMeeting from '../Meeting/AddMeeting';

const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: 'transparent',
  },
});

function RightSideBar(props) {
  const drawer = useSelector((state: RootState) => state.drawer)
  const dispatch = useDispatch();

  function toggleDrawer(): void {
    dispatch(drawerActions.toggle({}));
  }

  function getDrawerContent(): ReactElement | null {
    switch (drawer.entity) {
      case DRAWER_ENTITIES.EMAIL_TEMPLATE:
        return <EmailTemplateDrawer toggleDrawer={toggleDrawer} templateId={drawer.entityId} />;
      case DRAWER_ENTITIES.MEETING:
        return <AddMeeting meetingId={drawer.entityId} toggleDrawer={toggleDrawer} />;
      case DRAWER_ENTITIES.USER:
        if (drawer.entityId || drawer.tenantId) {
          return (
            <UserEdit
              key={drawer.entityId || drawer.tenantId}
              toggleDrawer={toggleDrawer}
              userId={drawer.entityId}
              tenantId={drawer.tenantId}
            />
          );
        }
    }
    return null;
  }

  return (
    <Drawer
      open={drawer.isOpen}
      onClose={toggleDrawer}
      width={drawer.width}
      preventCloseOnBackdropPress={drawer.entity === DRAWER_ENTITIES.USER}
      animationTime={250}
      scrimStyles={{ position: 'absolute' }}
      style={styles.mainWrapper}
      orientation={drawer.orientation}
      drawerContent={getDrawerContent()}
    >
      {props.children}
    </Drawer>
  );
}

RightSideBar.displayName = 'RightSideBar';

export default RightSideBar;
