import React, { useState, useCallback } from 'react'
import { Pressable, ScrollView, StyleSheet } from 'react-native'
import { useDispatch } from 'react-redux'
import format from 'date-fns/format';
import { DNABox, DNAButton, DNADivider, DNAText, Iffy, Popover, util } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Variant, DocumentORM, DocumentVersionORM } from 'src/types/types';
import { DocumentStatus } from '@alucio/aws-beacon-amplify/src/models';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip';
import DNADocumentContextMenu from 'src/components/DNA/Document/DNADocumentContextMenu/DNADocumentContextMenu'
import { useUserByEmail } from 'src/state/redux/selector/user';
import { useVersioningPanel } from './VersioningPanelContext';
import * as VersioningModals from 'src/components/DNA/Modal/DNAVersioningModals'

const styles = StyleSheet.create({
  arrowIcon: {
    height: 32,
    width: 32,
  },
  header: {
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  mainWrapper: {
    backgroundColor: colors['color-text-basic'],
    borderRightColor: colors['color-flat-300'],
    borderRightWidth: 1,
  },
  versionRow: {
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
});

interface VersionRowProps {
  addSeparator: boolean;
  isAnyFormDirty: boolean;
  documentVersion: DocumentVersionORM;
  isSelected: boolean;
  isDraft: boolean;
  showStatusBadge: boolean;
  onSelect: (documentVersionId: string) => void;
}

export const SidebarWrapper: React.FC = (props) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { documentORM, toggleSlider, cond } = useVersioningPanel();

  const toggleCollapse = (): void => {
    setCollapsed(p => !p);
  };

  return (
    <DNABox
      appearance="row"
      style={util.mergeStyles(
        undefined,
        styles.mainWrapper,
        [{ width: 360 }, !collapsed],
      )}
    >
      <DNABox fill appearance="col">
        {/* SIDEBAR HEADER */}
        <DNABox
          spacing="between"
          alignY="center"
          style={util.mergeStyles(
            undefined,
            styles.header,
            [{ maxHeight: 64 }, !collapsed],
          )}
        >
          <Iffy is={!collapsed}>
            <DNAText testID="version-history-text" bold>Version History</DNAText>
          </Iffy>
          <DNABox spacing="small" appearance={collapsed ? 'colReverse' : 'row'}>
            <DNADocumentContextMenu
              documentORM={documentORM}
              variant={Variant.versioning}
              onCallback={toggleSlider}
            >
              <DNAButton
                testID="version-label-ellipsis"
                status="subtle"
                appearance="outline"
                context="round"
                style={styles.arrowIcon}
                iconLeft="dots-vertical"
                disabled={cond.isModifying}
              />
            </DNADocumentContextMenu>
            <Popover placement="right">
              <Popover.Anchor>
                <DNAButton
                  testID="version-history-chevron"
                  status="subtle"
                  iconLeft={`chevron-${collapsed ? 'right' : 'left'}`}
                  appearance="outline"
                  context="round"
                  style={styles.arrowIcon}
                  onPress={toggleCollapse}
                />
              </Popover.Anchor>
              <Popover.Content>
                <DNAText style={{ color: colors['color-text-basic'] }}>
                  {collapsed ? 'Version History' : 'Collapse'}
                </DNAText>
              </Popover.Content>
            </Popover>
          </DNABox>
        </DNABox>
        <Iffy is={!collapsed}>
          <DNADivider />
        </Iffy>
        {/* SIDEBAR CONTENT */}
        <Iffy is={!collapsed}>
          {props.children}
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

const VersionHistoryList: React.FC<{
  onSelect: (docVerId: string) => void,
  isAnyFormDirty?: boolean,
  selectedDocVerId?: string,
  documentORM: DocumentORM
}> = (props) => {
  const { documentORM, onSelect, selectedDocVerId, isAnyFormDirty = false } = props

  const latestDocumentVersion = documentORM.relations.version.latestDocumentVersion;
  const isLatestDraft = latestDocumentVersion.model.status === DocumentStatus.NOT_PUBLISHED;
  const onVersionSelect = useCallback(
    (docVerId: string) => () => onSelect(docVerId),
    [onSelect],
  )

  return (
    <ScrollView>
      {
        documentORM.relations.documentVersions.map((documentVersion, idx) =>
          (<VersionRow
            isAnyFormDirty={isAnyFormDirty}
            key={documentVersion.model.id}
            documentVersion={documentVersion}
            isSelected={documentVersion.model.id === selectedDocVerId}
            isDraft={isLatestDraft && latestDocumentVersion.model.id === documentVersion.model.id}
            onSelect={onVersionSelect(documentVersion.model.id)}
            addSeparator={documentVersion.model.semVer?.major !==
              documentORM.relations.documentVersions[idx + 1]?.model.semVer?.major}
            showStatusBadge={documentVersion.model.id ===
            documentORM.relations.version.latestDocumentVersionPublished?.model.id}
          />),
        )
      }
    </ScrollView>
  )
}

const VersionRow = (props: VersionRowProps) => {
  const { documentVersion, isDraft, isSelected, onSelect, isAnyFormDirty } = props;
  const updatedBy = useUserByEmail(documentVersion.model.updatedBy)?.meta.formattedName;
  const dispatch = useDispatch()

  const docORM = documentVersion.relations.document
  const status = ['ARCHIVED', 'REVOKED'].includes(docORM.model.status)
    ? docORM.model.status
    : documentVersion.model.status

  const handleOnSelect = () => {
    if (!isAnyFormDirty) {
      onSelect(documentVersion.model.id)
      return;
    }

    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: () => <VersioningModals.DiscardChangesModal onConfirm={() => onSelect(documentVersion.model.id)} />,
    }))
  }

  return (
    <Pressable onPress={handleOnSelect}>
      <DNABox
        appearance="col"
        style={util.mergeStyles(
          undefined,
          styles.versionRow,
          [{ backgroundColor: colors['color-flat-300'] },
            isSelected],
        )}
      >
        <DNABox appearance="col" spacing="tiny">
          <DNABox spacing="small">
            <Iffy is={!isDraft}>
              <DNAText bold>
                {`v ${documentVersion.model.semVer?.major}.${documentVersion.model.semVer?.minor}`}
              </DNAText>
            </Iffy>
            <Iffy is={isDraft}>
              <DNAText bold>Draft In Progress</DNAText>
            </Iffy>
            <Iffy is={props.showStatusBadge || isDraft}>
              <DNADocumentChip item={documentVersion} variant={'status'} status={status} />
            </Iffy>
          </DNABox>
          <DNAText b2 status="subtle" numberOfLines={1}>
            {
              isDraft
                ? `Created ${format(new Date(documentVersion.model.createdAt), 'MMM d, yyyy')}`
                : `Published ${format(new Date(documentVersion.model.publishedAt || 0), 'MMM d, yyyy')}`
            }&nbsp; by {updatedBy || documentVersion.model.updatedBy}
          </DNAText>
        </DNABox>
      </DNABox>
      <DNADivider
        style={{
          backgroundColor: props.addSeparator && !isDraft
            ? colors['color-flat-600'] : colors['color-flat-300'],
        }}
      />
    </Pressable>
  );
};

export default VersionHistoryList;
