import React, { Fragment } from 'react'
import { StyleSheet } from 'react-native'

import { DocumentORM } from 'src/types/types'
import { FieldConfig } from '@alucio/aws-beacon-amplify/src/models';
import { useTenantFields } from 'src/state/redux/selector/tenant'
import { DNAText, Iffy } from '@alucio/lux-ui'

export type CustomLabelDisplayProps = {
  documentORM: DocumentORM;
}

const S = StyleSheet.create({
  bulletText: {
    marginHorizontal: 8,
    textAlignVertical: 'center',
  },
})

export function CustomLabelDisplay(props: CustomLabelDisplayProps) {
  const { documentORM } = props;
  const tenantFields = useTenantFields({ defaultSearchFilter: true });
  const { valuesMap } = documentORM.meta.tenantFields;

  const fieldsWithData = tenantFields.filter((field: FieldConfig) => (valuesMap[field.fieldName].length > 0));

  return (
    <DNAText numberOfLines={1} status="secondary">
      <>
        {
          fieldsWithData
            .map((field: FieldConfig, idx) => (
              <Fragment key={`custom_label_${idx}`}>
                <Iffy is={idx !== 0}>
                  <DNAText c3 status="secondary" style={S.bulletText}>
                    •
                  </DNAText>
                </Iffy>
                {valuesMap[field.fieldName].join(', ')}
              </Fragment>
            ))
        }
      </>
    </DNAText>
  );
}
