import React, { useEffect, useMemo, useRef, useState, createRef } from 'react'
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAIcon, DNAText } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { PayloadGroup, usePresentationBuilderState } from '../../state/PresentationBuilderStateProvider';
import { Page } from '@alucio/aws-beacon-amplify/src/models';
import SlideSelector from './SlideSelector';
import GroupsReplacements from './GroupsReplacements';
import { DocumentVersionORM } from 'src/types/types';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import CloseConfirmation from 'src/components/DNA/Modal/DNAPresentationBuilder/CloseConfirmation';
import { useDispatch } from 'react-redux';
import { useAppSettings } from 'src/state/context/AppSettings'

const styles = StyleSheet.create({
  headerContainer: {
    height: 56,
    backgroundColor: colors['color-warning-500'],
  },
  leftHeader: {
    marginLeft: 18,
  },
  rightHeader: {
    marginRight: 16,
  },
});

export enum GroupDraftStatus {
  DELETED = 'DELETED',
  REVOKED = 'REVOKED',
  ARCHIVED = 'ARCHIVED',
  GROUP_REMOVED = 'GROUP_REMOVED',
  ACTIVE = 'ACTIVE',
  MAJOR_UPDATE = 'MAJOR_UPDATE',
  REPLACED = 'REPLACED'
}

export interface ReplaceGroupDraft {
  groupId: string,
  visible: boolean,
  documentVersion?: DocumentVersionORM,
  status: GroupDraftStatus,
  isGroup?: boolean,
  pages: Page[],
  groupReplacement?: PayloadGroup,
}

const FindAndReplace: React.FC = () => {
  const {
    activeReplacementGroup,
    selectedGroups,
    handleModeChange,
    title,
    onApplyFindAndReplace,
    setActiveReplacementGroup,
    customDeck,
  } = usePresentationBuilderState();
  const { isOnline } = useAppSettings();

  const [activeGroup, setActiveGroup] = useState<string | undefined>(activeReplacementGroup?.id!);
  const [customTitle, setCustomTitle] = useState<string>(title || '');
  const isFirstLoad = useRef<boolean>(true);
  const [
    replaceGroups,
    setReplaceGroups,
  ] = useState<ReplaceGroupDraft[]>(assignReplacementGroups(selectedGroups, true));

  const selectedDocumentVersion = replaceGroups.find(({ groupId }) => groupId === activeGroup)?.documentVersion
  const documentVersionId =
    selectedDocumentVersion?.relations.document.relations.version.latestDocumentVersionPublished?.model.id
  const allContentCached = selectedGroups.every(group => group.documentVersion?.meta.assets.isContentCached)

  const latestDocumentVersionId = useRef<string>(documentVersionId!);
  const hasUserChangedAGroup = useRef<boolean>(false);
  const dispatch = useDispatch()

  // SO THE MEMOIZED SELECTOR CAN USE AN UPDATED ACTIVEGROUP
  const activeGroupRef = useRef<string | undefined>(activeGroup);

  const groupRefs = useMemo(() => {
    return replaceGroups.reduce((acc, value) => {
      acc[value.groupId] = createRef();
      return acc;
    }, {})
  }, []);

  const MemoizedSlideSelector = useMemo(
    () => {
      if (documentVersionId) {
        latestDocumentVersionId.current = documentVersionId;
      }

      return (
        <SlideSelector
          documentVersionId={documentVersionId || latestDocumentVersionId.current}
          onReplacementSelected={onReplacementSelected}
        />
      )
    },
    [documentVersionId],
  );

  function assignReplacementGroups(groups: PayloadGroup[], firstLoad?: boolean): ReplaceGroupDraft[] {
    let newReplacementGroups: ReplaceGroupDraft[] = [];

    if (firstLoad) {
      newReplacementGroups = groups.map((group): ReplaceGroupDraft => ({
        groupId: group.id,
        pages: group.pages,
        visible: group.visible,
        isGroup: group.pages.length > 1,
        // NOTE: IF A NEW STATUS IS ADDED TO THE GROUPSTATUSENUM, WE'LL NEED TO HANDLE IT IN GROUPDRAFTSTATUS
        status: GroupDraftStatus[group.groupStatus],
        documentVersion: group.documentVersion,
      }));
    } else {
      // [TODO]: COMPARE WITH CURRENT STATE OF DRAFT GROUPS TO CHECK WHETHER A DOC RECEIVED AN MAJOR UPDATE, TO
      // UPDATE THE CURRENT DRAFT GROUPS STATE
    }
    return newReplacementGroups;
  }

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
    }

    // UPON THE UPDATE OF THE SELECTED GROUPS (MAIN VIEW), UPDATE THE CURRENT DRAFT GROUPS
    // IN CASE SOME SOURCE DOCS HAVE BEEN AFFECTED (REMOVED/ARCHIVED/DELETED/MAJOR VERSIONED)
  }, [selectedGroups]);

  useEffect(() => {
    activeGroupRef.current = activeGroup;
  }, [activeGroup]);

  const onCloseHandler =  () => {
    setActiveReplacementGroup(undefined);
    handleModeChange('customization');
  }

  const openCloseConfirmation = () => {
    dispatch(
      DNAModalActions.setModal(
        {
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => <CloseConfirmation {...props} onClose={onCloseHandler} />,
        },
      ),
    )
  }

  const closeModal = (): void =>
    hasUserChangedAGroup.current ? openCloseConfirmation() : onCloseHandler()

  // WE MAP THE REPLACEMENT'S DRAFT INTO PAYLOADGROUP
  const onApply = (): void => {
    const updatedGroups: PayloadGroup[] = [];

    replaceGroups.forEach((replaceGroup) => {
      if (replaceGroup.status !== GroupDraftStatus.GROUP_REMOVED) {
        const currentGroup = selectedGroups.find(({ id }) => replaceGroup.groupId === id)!;

        if (replaceGroup.groupReplacement) {
          updatedGroups.push({ ...replaceGroup.groupReplacement, visible: currentGroup.visible });
        } else {
          updatedGroups.push(currentGroup);
        }
      }
    });

    onApplyFindAndReplace(updatedGroups, customTitle);
  };

  // REMOVES A GROUP INSTEAD OF REPLACING IT (CHANGE THE GROUP STATUS TO REMOVED)
  const onRemoveGroup = (id: string): void => {
    updateGroupStatus(id, GroupDraftStatus.GROUP_REMOVED);
    if (id === activeGroup) {
      setActiveGroup(undefined);
    }
    hasUserChangedAGroup.current = true;
  };

  // UNDO A REMOVE GROUP ACTION (CHANGE THE GROUP STATUS TO MAJOR_UPDATE)
  const onUndoRemoval = (id: string): void => {
    updateGroupStatus(id, GroupDraftStatus.MAJOR_UPDATE);
  };

  const updateGroupStatus = (
    id: string,
    newStatus: GroupDraftStatus.MAJOR_UPDATE | GroupDraftStatus.GROUP_REMOVED,
  ): void => {
    hasUserChangedAGroup.current = true;
    setReplaceGroups((groups) => groups.map((group) => {
      if (group.groupId === id) {
        // IN CASE WE'RE "RESTORING" THE REMOVAL OF A GROUP THAT ALREADY SELECTED A REPLACEMENT
        if (newStatus === GroupDraftStatus.MAJOR_UPDATE && group.groupReplacement) {
          group.status = GroupDraftStatus.REPLACED;
        } else {
          group.status = newStatus;
        }
      }

      return group;
    }));
  };

  // ACCEPTS WHEN A GROUP WAS DELETED AND REMOVES IT
  const onAcceptDeletedGroup = (id: string): void => {
    setReplaceGroups((groups) => groups.filter(({ groupId }) => groupId !== id));
    hasUserChangedAGroup.current = true;
  };

  // SELECTED GROUP TO BE REPLACED
  const onSelectGroup = (groupId: string): void => {
    setActiveGroup(groupId);
  };

  // REPLACE THE ACTIVE GROUP WITH THE SELECTED ON THE LEFT SIDE
  function onReplacementSelected(payloadGroup: PayloadGroup): void {
    if (!activeGroupRef.current) {
      return;
    }

    hasUserChangedAGroup.current = true;

    // get the index of activeGroup and then set the activeGroup to the next available slide to be replaced
    const currentIndex = replaceGroups.findIndex((group) => activeGroupRef.current === group.groupId);
    const nextGroup = replaceGroups.find((group, index) => index > currentIndex &&
    group.status === GroupDraftStatus.MAJOR_UPDATE);
    setActiveGroup(nextGroup?.groupId);

    setReplaceGroups((groups) => groups.map((group) => {
      if (group.groupId === activeGroupRef.current) {
        group.groupReplacement = payloadGroup;
        group.status = GroupDraftStatus.REPLACED;
        analytics?.track('CUSTOM_REPLACE', {
          action: 'REPLACE',
          category: 'CUSTOM',
          customDeckId: customDeck?.model.id,
        })
      }

      return group;
    }));

    if (nextGroup) {
      groupRefs[nextGroup.groupId]?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  return (
    <DNABox fill appearance="col">
      <DNABox
        alignY="center"
        style={styles.headerContainer}
        spacing="between"
      >
        <DNABox fill spacing="medium" style={styles.leftHeader}>
          {/* LeftIcon */}
          <DNAIcon.Styled
            appearance="ghost"
            status="warningAlt"
            size="xlarge"
            name="swap-horizontal-bold"
          />
          <DNAText style={{ color: colors['color-text-basic'] }}>Find &amp; Replace</DNAText>
        </DNABox>
        {/* Buttons on the right */}
        <DNABox fill spacing="medium" style={styles.rightHeader}>
          <DNAButton
            onPress={closeModal}
            status="subtle"
            appearance="filled"
            context="moderate"
          >
            Cancel
          </DNAButton>
          <DNAButton
            status="subtle"
            appearance="filled"
            context="moderate"
            onPress={onApply}
            disabled={(!isOnline && !allContentCached)}
          >
            Apply
          </DNAButton>
        </DNABox>
      </DNABox>
      <DNABox fill>
        { /* LEFT SIDE */ }
        {MemoizedSlideSelector}
        { /* RIGHT SIDE */ }
        <GroupsReplacements
          activeGroup={activeGroup}
          replaceGroups={replaceGroups}
          customTitle={customTitle}
          setCustomTitle={setCustomTitle}
          onRemoveGroup={onRemoveGroup}
          onSelectGroup={onSelectGroup}
          onUndoRemoval={onUndoRemoval}
          onAcceptDeletedGroup={onAcceptDeletedGroup}
          groupRefs={groupRefs}
        />
      </DNABox>
    </DNABox>
  )
};

FindAndReplace.displayName = 'FindAndReplace';

export default FindAndReplace;
