import React, { useState, useEffect, useRef } from 'react';
import config from 'src/config/app.json'
import { StyleSheet, Text } from 'react-native'
import { useLogout } from '../Authenticator';
import { Divider } from '@ui-kitten/components';
import {
  useTimeout,
  Modal,
  Box,
  luxColors,
  Button,
  AlucioChannel,
} from '@alucio/lux-ui';
// [TODO]: TEMP, should re-export actual type from Lux-UI
import { BroadcastChannel } from 'broadcast-channel'
import { useAppSettings } from 'src/state/context/AppSettings';

export enum SessionStatus {
  end = 'end',
  timeExpired = 'timeExpired',
  reset = 'reset',
  extend = 'extend',
  promptModal = 'promptMopdal',
}

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: luxColors.modalBackdrop.primary,
  },
  button: {
    borderRadius: 16,
    height: 32,
    minHeight: 32,
  },
  buttonExtend: {
    backgroundColor: luxColors.thumbnailBorder.primary,
    marginLeft: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    marginTop: 12,
    marginLeft: 24,
    marginRight: 12,
    marginBottom: 12,
    alignSelf: 'flex-end',
  },
  container: {
    flex: 1,
    width: 480,
    minHeight: 184,
    backgroundColor: luxColors.confirmationBackground.primary,
    borderRadius: 6,
    borderWidth: 3,
    borderColor: luxColors.menuBorder.primary,
    shadowColor: luxColors.menuShadown.primary,
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  descriptionContainer: {
    marginTop: 30,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 24,
  },
  divider: {
    backgroundColor: luxColors.mainContainer.primary,
  },
  header: {
    marginLeft: 24,
    marginBottom: 10,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 24,
    color: luxColors.confirmationHeader.primary,
    flexDirection: 'column',
  },
  headerCloseButton: {
    flexDirection: 'column',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: 20,
    marginTop: 10,
  },
  icon: {
    color: luxColors.closeIcon.primary,
    height: 24,
    width: 24,
  },
  textContainer: {
    color: luxColors.confirmationText.primary,
  },
})

type IdleConfig = {
  idleTime: number,
  interval: number,
  promptDuration: number,
  debounce: number,
}

const idleChannelName = 'beacon-idle'
const overrideKey = 'Sd5jxf4CGM'

const idleParams: IdleConfig = localStorage.getItem(overrideKey)
  ? JSON.parse(localStorage?.getItem(overrideKey) as string)
  : config.idleTimeout;

function clearTimers(...args: React.MutableRefObject<number | undefined>[]) {
  for (const timer of args) {
    if (timer.current) {
      // Works for both setTimeout and setInterval
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setInterval
      clearTimeout(timer.current)
    }
    timer.current = undefined;
  }
}

/**
 * [TODO]: Need to handle the usecase of pausing during presentation
 *  Perhaps the hook is global and we can use it in another component and call pause()
 */
const Idle = () => {
  const { isIdleDisabled } = useAppSettings();
  const ignoreLogoutRef = useRef<boolean>(!!isIdleDisabled);
  const { signOut } = useLogout();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isMounted = useRef<boolean>(false)
  const sessionWarningInterval = useRef<number>();
  const sessionWarningTimeout = useRef<number>();
  const channel = useRef<BroadcastChannel<string>>(AlucioChannel.get(idleChannelName));

  useEffect(() => {
    ignoreLogoutRef.current = !!isIdleDisabled;
  }, [isIdleDisabled]);

  async function logout(reason: SessionStatus, fromChannel: boolean): Promise<void> {
    // We only respect the ignoreLogoutRef if it wasn't an explicit logout
    if (reason !== SessionStatus.end && ignoreLogoutRef.current) {
      extendSession();
      return;
    }

    clearTimers(sessionWarningInterval, sessionWarningTimeout)
    if (!fromChannel) {
      await channel.current.postMessage(reason);
    }
    await signOut(reason, fromChannel);
  }

  const { reset, getRemainingTime, pause } = useTimeout({
    timeout: idleParams.idleTime * 1000,
    // [TODO]: This probably never gets called
    //  We can remove sessionWarningInterval, and have onIdle show the modal instead
    onIdle: () => logout(SessionStatus.timeExpired, false),
    onAction: () => {
      // Possible issues with this
      // 1. useTimeout doesn't properly unmount
      //    (these are still called even after logged out/component unmounted)
      // 2. Due to throttling, it may be possible this is called after paused?
      // [TODO]: Wrap in try/catch for now
      if (isMounted.current && !isModalVisible) {
        try {
          reset()
          channel.current.postMessage(SessionStatus.reset)
        } catch (e) {
          console.error(e)
        }
      }
    },
    debounce: idleParams.debounce * 1000,
  })

  function showModal() {
    if (!isModalVisible && !ignoreLogoutRef.current) {
      pause();
      const modalDuration = idleParams.promptDuration * 1000
      setIsModalVisible(true);
      // Having inconsistencies with setTimeout type inferrence, sometimes it's NodeJS Timeout, sometimes it's number
      // In the browser, generally it's just a number (id) so should be fine to assume it's a number
      sessionWarningTimeout.current = setTimeout(
        () => logout(SessionStatus.timeExpired, false)
        , modalDuration) as unknown as number
    }
  }

  function extendSession(broadcast?: boolean) {
    reset();
    clearTimers(sessionWarningTimeout)
    setIsModalVisible(false);
    broadcast && channel.current.postMessage(SessionStatus.extend);
  }

  /** Channel subscription setup */
  useEffect(() => {
    channel.current.onmessage = async (msg: string) => {
      switch (msg) {
        case SessionStatus.end: {
          await logout(SessionStatus.end, true)
          break;
        }
        case SessionStatus.timeExpired: {
          await logout(SessionStatus.timeExpired, true)
          break;
        }
        case SessionStatus.reset: {
          reset();
          break;
        }
        case SessionStatus.promptModal: {
          showModal();
          break;
        }
        case SessionStatus.extend: {
          extendSession()
          break;
        }
        default: break
      }
    }
  }, [isModalVisible])

  /** Setup checker for extend session prompt */
  useEffect(() => {
    // [TODO]: This means the interval timer will reset on a new offset
    // But we have to clear it to avoid a stale 'showModal'
    if (sessionWarningInterval.current) {
      clearTimers(sessionWarningInterval)
    }

    sessionWarningInterval.current = setInterval(
      () => {
        const currentIdleTime = getRemainingTime() / 1000;
        if (currentIdleTime > idleParams.promptDuration) return;

        channel.current.postMessage(SessionStatus.promptModal);
        showModal();
      },
      idleParams.interval * 1000,
    ) as unknown as number;
  }, [isModalVisible])

  useEffect(() => {
    isMounted.current = true

    // Channel might have been closed if we are in the middle of an SSO logout
    if (!channel.current.isClosed) {
      extendSession(true)
    }

    return () => {
      isMounted.current = false
      clearTimers(sessionWarningInterval, sessionWarningTimeout)
    }
  }, [])

  if (!isModalVisible) return null;

  return (
    <Modal
      visible={true}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => { }}
    >
      <Box>
        <Box style={styles.container}>
          <Box style={styles.headerContainer}>
            <Box>
              <Text style={styles.header}>Your session is about to expire</Text>
            </Box>
          </Box>
          <Divider style={styles.divider} />
          <Box style={styles.descriptionContainer}>
            <Text style={styles.textContainer}>
              For security reason, you will be logged out soon.
            </Text>
          </Box>
          <Divider style={styles.divider} />
          <Box style={styles.buttonContainer}>
            <Button.Kitten
              status="basic"
              style={styles.button}
              onPress={() => logout(SessionStatus.end, false)}
            >
              Log out
            </Button.Kitten>
            <Button.Kitten
              style={[styles.button, styles.buttonExtend]}
              onPress={() => extendSession(true)}
            >
              Stay logged in
            </Button.Kitten>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default Idle;
