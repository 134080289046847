import React, { useCallback, useEffect, useRef } from 'react';
import { FlatList, ScrollView } from 'react-native';
import { DNABox, useMouseEvent } from '@alucio/lux-ui';
import { useAppSettings } from 'src/state/context/AppSettings';
import { Presentable, PresentablePage } from 'src/types/types';
import DNASlideRollThumbnail from './DNASlideRollThumbnail';
import { SLIDE_ROLL_VARIANT } from './StyleSetting';

interface SlideRollProps {
  activeSlide: PresentablePage,
  itemHeight?: number,
  itemWidth?: number,
  horizontal?: boolean,
  isContentUnavailable?: boolean,
  scrollToSelected?: boolean,
  isGridView?: boolean,
  presentable: Presentable,
  onSelect?: (presentationNumber: number) => void,
  variant?: SLIDE_ROLL_VARIANT,
}

function DNASlideRoll(props: SlideRollProps) {
  const {
    activeSlide,
    isContentUnavailable,
    scrollToSelected = true,
    isGridView,
    itemHeight,
    itemWidth,
    onSelect,
    presentable,
    horizontal = true,
    variant = SLIDE_ROLL_VARIANT.DEFAULT,
  } = props;

  const { deviceMode } = useAppSettings();
  const isTablet = deviceMode === 'tablet';
  const flatListRef = useRef<FlatList>(null);

  const scrollToItem = (): void => {
    if (scrollToSelected) {
      flatListRef?.current?.scrollToIndex({
        index: activeSlide.presentationPageNumber - 1,
        animated: true,
      });
    }
  }
  useEffect(scrollToItem, [activeSlide, presentable.presentablePages]);

  const handleMouseEvent = useCallback(
    (e: MouseEvent | Event | TouchEvent) => {
      if (e instanceof WheelEvent) {
        const scrollEl = flatListRef.current?.getScrollableNode() as HTMLElement
        if (scrollEl) { scrollEl.scrollLeft += e.deltaY }
      }
    },
    [],
  )
  useMouseEvent(handleMouseEvent);

  const renderThumbnail = ({ item: page }) => {
    const isSelected = activeSlide.presentationPageNumber === page.presentationPageNumber;
    function onSelectPage(): void {
      onSelect?.(page.presentationPageNumber);
    }

    return (
      <DNASlideRollThumbnail
        isContentUnavailable={isContentUnavailable}
        page={page}
        variant={variant}
        isSelected={isSelected}
        height={itemHeight}
        width={itemWidth}
        onSelect={onSelectPage}
      />
    );
  };

  if (isGridView) {
    return (
      <ScrollView>
        <DNABox wrap="start" spacing="medium">
          {presentable.presentablePages.map((page) => renderThumbnail({ item: page }))}
        </DNABox>
      </ScrollView>
    );
  }

  return (
    <DNABox fill={isTablet}>
      <FlatList
        scrollEnabled={true}
        scrollEventThrottle={200}
        keyExtractor={(item) => item.id}
        ref={flatListRef}
        horizontal={horizontal}
        data={presentable.presentablePages}
        renderItem={renderThumbnail}
        onScrollToIndexFailed={info => {
          setTimeout(() => {
            flatListRef.current?.scrollToIndex({ index: info.index, animated: true });
          }, 500);
        }}
      />
    </DNABox>
  );
}

export default DNASlideRoll;
