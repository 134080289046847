import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast';
import { FolderItemORM } from 'src/types/types';

export interface ContentPreviewModalSliceType {
  isOpen: Boolean,
  documentVersionId?: string,
  folderItemORM?: FolderItemORM,
  isPublisherMode?: boolean,
  isFullWindow?: boolean,
  toast?: ToastActions,
  fromEllipsis?: boolean,
  documentHistoryVersionNavigation?: string[]
}

export interface ChangeDocumentType {
  documentVersionId?: string,
  addToHistory: boolean
}

const initialState: ContentPreviewModalSliceType = {
  isFullWindow: false,
  isOpen: false,
  documentHistoryVersionNavigation: [],
};

const contentPreviewModalSlice = createSlice({
  name: 'contentPreviewModalState',
  initialState: initialState,
  reducers: {
    changeDocument: (state, action: PayloadAction<ChangeDocumentType>) => {
      const { payload } = action;
      if (payload.addToHistory) state.documentHistoryVersionNavigation?.push(state.documentVersionId!)
      state.documentVersionId = payload.documentVersionId
      return state;
    },
    setModalVisibility: (state, action: PayloadAction<ContentPreviewModalSliceType>) => {
      const { payload } = action;
      if (!payload.documentVersionId) {
        state.isOpen = false;
      } else {
        state.documentVersionId = payload.documentVersionId;
        state.isOpen = payload.isOpen;
        state.folderItemORM = payload.folderItemORM;
        state.isFullWindow = !!payload.isFullWindow;
        state.toast = payload.toast
        state.fromEllipsis = !!payload.fromEllipsis
      }

      if (!payload.isOpen) {
        state.documentHistoryVersionNavigation = [];
      }

      return state;
    },
    setIsFullWindow: (state, action: PayloadAction<boolean>) => {
      state.isFullWindow = action.payload
      return state;
    },
    removeFromHistory : (state, action : PayloadAction<boolean>) => {
      // clean history
      if (action.payload) state.documentHistoryVersionNavigation = [];
      else state.documentHistoryVersionNavigation?.pop()
      return state;
    },
  },
});

export default contentPreviewModalSlice;
export const contentPreviewModalActions = {
  ...contentPreviewModalSlice.actions,
}
