import React from 'react'

import { DNAHeader, DNABox, Iffy } from '@alucio/lux-ui'
import { useAppSettings, useOnlineStatus } from 'src/state/context/AppSettings'
import { useLDClient } from 'launchdarkly-react-client-sdk'

import TenantLogo from './TenantLogo'
import SearchInput from 'src/components/Header/SearchInput/SearchInput'
import SplitButton from 'src/components/Header/SplitButton'

import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import useScreenNav from 'src/components/DNA/hooks/useScreenNav'

import ProfileMenu from 'src/components/DNA/Header/ProfileMenu'
import FAQButton from 'src/components/DNA/Header/FAQButton'
import Notification from 'src/components/Header/Notifications';
import NetworkOfflineButton from 'src/components/DNA/Header/NetworkOfflineButton'
import SyncStatusButton from 'src/components/DNA/Header/SyncStatusButton'
import MeetingButton from 'src/components/Header/MeetingButton';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag';
import { FeatureFlags } from 'src/types/featureFlags';

const useDNAAppHeader = () => {
  const { goTo } = useScreenNav()
  const currentPage = useCurrentPage()
  return {
    currentPage,
    goToVirtual: () => goTo.VIRTUAL(),
    goToHome: () => goTo.HOME(),
  }
}

const DNAAppHeaderDesktop = () => {
  const onlineStatus = useOnlineStatus()
  const isNetworkConnected = onlineStatus
  const { currentPage, goToVirtual } = useDNAAppHeader()
  const { isOfflineEnabled } = useAppSettings()
  const client = useLDClient()
  const disableGlobalHeader = currentPage?.configOptions?.desktop?.disableGlobalHeader
  const isThirdPartyEnabled  = useFeatureFlag(FeatureFlags.BEAC_1498_third_party_conferencing_integration);
  const isMeetingsDisabled = useCurrentPage()?.configOptions?.desktop?.disableMeetings

  /** No need to continue instantiating if this component is disabled on current route */
  if (disableGlobalHeader) return null

  const disabledViaFeatureFlag = client?.variation('disable-virtual', false)
  const isCurrentRouteSearchDisabled = currentPage?.configOptions?.desktop?.disableSearch
  const isCurrentRouteNotificationsDisabled = currentPage?.configOptions?.desktop?.disableNotificaions
  const isCurrentRouteVirtualDisabled = currentPage?.configOptions?.desktop?.disableVirtual || disabledViaFeatureFlag
  const showMeetingsButton = isThirdPartyEnabled && !isMeetingsDisabled

  return (
    <DNAHeader testID="desktop-header">
      {/*
        NOTE: This is a workaround to be able to use flex that's aware
          of both sides. If using Header.Left and Header.Right, they are
          independent of each other and cannot flex properly when shrinking.
          In this case, it applies to the reflexive nature of the SearchInput
          that needs to shrink when the screen is small enough and the right side
          begins to overlap the search
      */}
      <DNAHeader.Center style={{ position: 'relative' }}>
        <DNABox
          alignY="center"
          spacing="between"
          childFill={0}
        >
          {/* LEFT SIDE */}
          <DNABox fill style={{ minHeight: 50, marginRight: 24 }}>
            <TenantLogo />
            <Iffy is={!isCurrentRouteSearchDisabled}>
              <DNABox fill style={{ maxWidth: 720 }}>
                <SearchInput />
              </DNABox>
            </Iffy>
          </DNABox>

          {/* RIGHT SIDE */}
          <DNABox alignY="center" spacing="large">
            <Iffy is={!isCurrentRouteVirtualDisabled}>
              <SplitButton onPressLeft={() => goToVirtual()} />
            </Iffy>
            <Iffy is={showMeetingsButton}>
              <MeetingButton />
            </Iffy>
            <DNABox spacing="medium" alignY="center">
              <DNABox alignX="center">
                <Iffy is={!isNetworkConnected}>
                  <NetworkOfflineButton />
                </Iffy>
              </DNABox>
              <Iffy is={isOfflineEnabled}>
                <SyncStatusButton />
              </Iffy>
              <DNABox>
                <FAQButton />
              </DNABox>
              <DNABox>
                <Iffy is={!isCurrentRouteNotificationsDisabled}>
                  <Notification />
                </Iffy>
              </DNABox>
            </DNABox>
            <ProfileMenu />
          </DNABox>
        </DNABox>
      </DNAHeader.Center>
    </DNAHeader>
  )
}

const DNAAppHeaderTablet: React.FC = () => {
  const onlineStatus = useOnlineStatus()
  const isNetworkConnected = onlineStatus
  const { currentPage } = useDNAAppHeader()
  const { isOfflineEnabled } = useAppSettings()

  return (
    <DNAHeader testID="tablet-header" status="brand" title={currentPage?.LABEL}>
      <DNAHeader.Right>
        <DNABox alignY="center" spacing="medium">
          <SearchInput />
          <Iffy is={!isNetworkConnected}>
            <NetworkOfflineButton />
          </Iffy>
          <Iffy is={isOfflineEnabled}>
            <SyncStatusButton />
          </Iffy>
          <FAQButton />
          <Notification />
          <ProfileMenu />
        </DNABox>
      </DNAHeader.Right>
    </DNAHeader>
  )
}

const DNAAppHeader = (props) => {
  const { deviceMode } = useAppSettings()

  return deviceMode === 'desktop'
    ? <DNAAppHeaderDesktop {...props} />
    : <DNAAppHeaderTablet {...props} />
}

export default DNAAppHeader
