import {
  CustomDeckORM,
  DocumentVersionORM,
  isCustomDeckORM,
  isDocumentVersionORM,
  isFolderItemORM,
  ORMTypes,
  Presentable,
  PresentableGroup,
  PresentablePage,
} from 'src/types/types';
import { v4 as uuid } from 'uuid';
import { PresentableModelORM } from './ContentProvider';

function getDocumentVersionVisiblePages(
  documentVersion: DocumentVersionORM,
  visiblePages: number[],
  group: PresentableGroup,
): PresentablePage[] {
  let presentationPageNumber = 1;
  return documentVersion.model.pages.reduce<PresentablePage[]>((acc, page): PresentablePage[] => {
    if (visiblePages.length && !visiblePages.includes(page.number)) {
      return acc;
    }
    acc.push({
      id: `${group.id}_${presentationPageNumber}`,
      page: page,
      documentVersion,
      presentationPageNumber: presentationPageNumber++,
      presentableGroup: group,
    });
    return acc;
  }, []);
}

interface PresentableFromCustomDeckResponse {
  presentableGroups: PresentableGroup[],
  presentablePages: PresentablePage[],
  numberOfPages: number,
}

function getCustomDeckPresentableGroups(customDeck: CustomDeckORM): PresentableFromCustomDeckResponse {
  let currentGroupNumber = 0;
  let presentationPageNumber = 1;
  let numberOfPages = 0;
  const presentablePages: PresentablePage[] = [];
  const presentableGroups = customDeck.meta.groupsORM.reduce<PresentableGroup[]>((acc, groupORM) => {
    if (!groupORM.model.visible || !groupORM.pages.length) {
      return acc;
    }

    // WE NEED TO CHECK IF WE NEED TO CREATE A NEW PRESENTABLE GROUP BASED ON THE CURRENT GROUPORM ITERATION,
    // OR THOSE PAGES CAN BE ADDED TO THE GROUP OF THE PREVIOUS ITERATION
    const newGroup = {
      id: uuid(),
      pages: [],
    };
    const groupFirstPage = groupORM.pages[0];
    const currentGroup = acc[currentGroupNumber - 1];
    const areGroupPagesAlreadyIncluded = groupORM.pages.some(({ page: { number } }) =>
      currentGroup?.pages.some(({ page }) => number === page?.number));

    const canBeAddedToCurrentGroup =
      acc[currentGroupNumber - 1]?.documentVersion.model.id === groupFirstPage.model.documentVersionId &&
      !areGroupPagesAlreadyIncluded;

    const newSetOfPages = groupORM.pages.map((page): PresentablePage => ({
      id: canBeAddedToCurrentGroup
        ? `${currentGroup.id}_${presentationPageNumber}` : `${newGroup.id}_${presentationPageNumber}`,
      page: page.page,
      documentVersion: page.documentVersionORM,
      presentationPageNumber: presentationPageNumber++,
      presentableGroup: currentGroup || { ...newGroup, documentVersion: page.documentVersionORM },
    }));

    presentablePages.push(...newSetOfPages);
    numberOfPages += newSetOfPages.length;

    if (canBeAddedToCurrentGroup) {
      currentGroup.pages = [...acc[currentGroupNumber - 1].pages, ...newSetOfPages];
    } else {
      currentGroupNumber++;
      acc.push({
        id: newGroup.id,
        documentVersion: groupFirstPage.documentVersionORM,
        pages: newSetOfPages,
      });
    }

    return acc;
  }, []);

  return {
    presentableGroups,
    presentablePages,
    numberOfPages,
  }
}

export function getPresentable(item: PresentableModelORM): Presentable {
  let presentable: Presentable | undefined;

  function getPresentableFromDocVer(
    documentVersion: DocumentVersionORM,
    title: string,
    visiblePages: number[]): Presentable {
    const groupId = uuid();
    // FIRST, WE CREATE THE GROUP, WITH AN EMPTY ARRAY OF PAGES,
    // SINCE THE PAGES NEED TO HAVE THE GROUP'S REFERENCE
    const presentableGroups: PresentableGroup[] = [{
      id: groupId,
      documentVersion: documentVersion,
      pages: [],
    }];
    // THEN, WE ADD THE PAGES WITH THE REFERENCE
    presentableGroups[0].pages =
      getDocumentVersionVisiblePages(documentVersion, visiblePages || [], presentableGroups[0]);
    return {
      id: uuid(),
      orm: documentVersion,
      type: ORMTypes.DOCUMENT_VERSION,
      title,
      numberOfPages: presentableGroups[0].pages.length,
      presentablePages: presentableGroups[0].pages,
      presentableGroups,
    };
  }

  switch (item.type) {
    case ORMTypes.DOCUMENT_VERSION:
      presentable = getPresentableFromDocVer(item, item.model.title || '', []);
      break;

    case ORMTypes.FOLDER_ITEM:
      if (isFolderItemORM(item.relations.item)) {
        throw new Error('FolderItem cannot be a folder');
      } else if (isDocumentVersionORM(item.relations.item)) {
        const title = item.model.customTitle || item.relations.item.model.title || '';
        presentable = getPresentableFromDocVer(item.relations.item,
          title, item.model.visiblePages || []);
        presentable.orm = item;
        presentable.type = ORMTypes.FOLDER_ITEM;
      } else if (isCustomDeckORM(item.relations.item)) {
        const customDeck = item.relations.item;
        const { presentableGroups, presentablePages, numberOfPages } = getCustomDeckPresentableGroups(customDeck);

        presentable = {
          id: uuid(),
          orm: item,
          type: ORMTypes.FOLDER_ITEM,
          title: item.model.customTitle || '',
          presentableGroups,
          presentablePages,
          numberOfPages,
        };
      }
  }

  if (!presentable) {
    throw new Error('Presentable is undefined');
  }
  return {
    ...presentable,
    presentableGroups: presentable.presentableGroups.map((group) => ({
      ...group,
      pages: group.pages.map((page) => ({
        ...page,
        presentableGroup: group,
      })),
    })),
  };
}
