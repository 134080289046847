import React from 'react'
import { isCustomDeckORM, FolderItemORM } from 'src/types/types'
import { DNACardComponent } from 'src/components/DNA/Grid/Cards/types'
import { DNABox, DNACard, DNAChip, DNAText, useTheme } from '@alucio/lux-ui';
import DNACustomDeckThumbnail from 'src/components/DNA/Document/DNACustomDeckThumbnail';

const DNACustomDeckVirtualCard: DNACardComponent<FolderItemORM> = (props) => {
  const { ORM: folderItemORM, onPress } = props
  const theme = useTheme()
  if (!isCustomDeckORM(folderItemORM.relations.item)) {
    throw Error('ORM received in props must be a custom deck');
  }
  const customDeckORM = folderItemORM.relations.item

  return (
    <DNABox
      alignX="start"
      style={{ minWidth: 232, minHeight: 212 }}
    >
      <DNACard
        interactive="pressable"
        onPress={() => { onPress?.(folderItemORM) }}
        style={{
          padding: 8,
          backgroundColor: theme['color-dark-300'],
        }}
      >
        <DNABox appearance="col" spacing="small">
          <DNABox style={{ backgroundColor: theme['color-dark-transparent-700'] }}>
            <DNACustomDeckThumbnail
              customDeckORM={customDeckORM}
              width={216}
              height={124}
              unavailableContent={false}
            />
          </DNABox>
          <DNABox appearance="col" spacing="small">
            <DNAText
              status="basic"
              b2
              style={{ width: 216, overflow: 'hidden' }}
              numberOfLines={2}
            >
              {folderItemORM.model.customTitle ? folderItemORM.model.customTitle : 'Untitled Presentation'}
            </DNAText>
            <DNABox spacing="small" appearance="row">
              <DNAChip
                appearance="tag"
                style={{ backgroundColor: theme['color-basic-transparent-200'] }}
                status="dark"
              >
                MODIFIED
              </DNAChip>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNACard>
    </DNABox>
  )
}

export default DNACustomDeckVirtualCard
