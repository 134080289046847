import React from 'react';
import {
  DNABox,
  DNAButton,
  DNACard,
  DNACheckbox,
  Modal,
  DNADivider,
  DNAText,
  DNASlider,
  luxColors,
} from '@alucio/lux-ui';
import { ScrollView, StyleSheet } from 'react-native';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { FilterEntry } from 'src/state/redux/document/query';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useDNADocumentFilters } from 'src/components/DNA/Document/DNADocumentFilters/DNADocumentFilters'
import { FeatureFlags } from 'src/types/featureFlags'
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag'

const styles = StyleSheet.create({
  card: {
    width: '100vw',
    padding: 32,
    height: '70vh',
    borderRadius: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  cardTablet: {
    padding: 0,
  },
  container: {
    top: 72,
    borderTopColor: luxColors.selectedRow.primary,
    shadowColor: luxColors.menuShadown.primary,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 6,
    borderTopWidth: 0.8,
  },
  headerMobile: {
    backgroundColor: colors['color-brand-800'],
    marginBottom: 16,
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
  submitButtonWrapper: {
    marginBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
});

interface ModalProps {
  activeFilters: number;
  categories,
  clearFilters: (fn) => void;
  closeApply: (addDelay?: boolean) => void;
  closeCancel: () => void;
  resetToDefaultFilters,
  setLocalActiveFilters: (filters: FilterEntry[]) => void;
  toggleFilter: (fieldName: string, fieldValue: string, fn) => void,
}

// [TODO]: VERTICAL/HORIZONTAL SCROLL BARS TO BE FIXED
const DNADocumentFiltersModalTablet = (props: ModalProps) => {
  const {
    activeFilters,
    categories,
    clearFilters,
    closeApply,
    closeCancel,
    resetToDefaultFilters,
    setLocalActiveFilters,
    toggleFilter,
  } = props;

  const { isModalVisible, toggleModal } = useDNADocumentFilters()

  return (
    <DNASlider
      visible={isModalVisible}
      setVisible={toggleModal}
    >
      {/* CONTAINER */}
      <DNABox
        fill
        style={{ backgroundColor: 'white' }}
        appearance="col"
      >
        {/* HEADER */}
        <DNABox alignY="center" style={styles.headerMobile}>
          {/* LEFT CONTROLS */}
          <DNABox alignX="start" fill>
            <DNABox>
              <DNAButton status="brandAlt" onPress={closeCancel}>
                Cancel
              </DNAButton>
            </DNABox>
          </DNABox>
          <DNABox alignX="center" fill style={{ alignContent: 'center' }}>
            <DNABox>
              <DNAText h3>Filters ({activeFilters || 0})</DNAText>
            </DNABox>
          </DNABox>
          {/* RIGHT CONTROLS */}
          <DNABox fill>
            <DNABox alignX="end" spacing="medium" fill>
              <DNABox>
                <DNAButton
                  status="brandAlt"
                  testID="filter-modal-reset-button"
                  onPress={() => {
                    resetToDefaultFilters(setLocalActiveFilters)
                  }}
                >
                  Reset
                </DNAButton>
              </DNABox>
              <DNABox>
                <DNAButton
                  status="brandAlt"
                  testID="filter-modal-clear-button"
                  onPress={() => {
                    clearFilters(setLocalActiveFilters)
                  }}
                >
                  Clear
                </DNAButton>
              </DNABox>
              <DNABox>
                <DNAButton
                  onPress={() => closeApply()}
                  testID="filter-modal-apply-button"
                >
                  Apply
                </DNAButton>
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>

        {/* CONTENT */}
        <DNABox
          as={ScrollView}
          spacing="large"
          appearance="col"
          childStyle={{ paddingLeft: 16, paddingRight: 16 }}
        >
          {
            categories.map(([categoryLabel, categoryValues]) => (
              <DNABox key={categoryLabel} appearance="col" spacing="medium">
                <DNAText h4 style={{ color: colors['color-flat-900'] }}>{categoryLabel}</DNAText>
                <DNABox spacing="small" wrap="start">
                  {
                    Object.entries(categoryValues).map(([categoryValue, fieldEntry]: any) => (
                      <DNAButton
                        key={`${categoryLabel}-${categoryValue}`}
                        status={fieldEntry.active ? 'primary' : 'info'}
                        size="medium"
                        context="square"
                        style={{ marginBottom: 8 }}
                        appearance={fieldEntry.active ? 'filled' : 'outline'}
                        onPress={() => toggleFilter(
                          fieldEntry.fieldName,
                          fieldEntry.fieldValue,
                          setLocalActiveFilters,
                        )}
                        testID={`filter-modal-button-${fieldEntry.active ? 'active' : 'inactive'}`}
                      >
                        {fieldEntry.fieldValue} ({fieldEntry.count})
                      </DNAButton>
                    ))
                  }
                </DNABox>
              </DNABox>
            ))
          }
        </DNABox>
      </DNABox>
    </DNASlider>
  );
};

const DNADocumentFiltersModalDesktop = (props: ModalProps) => {
  const {
    categories,
    clearFilters,
    closeApply,
    closeCancel,
    resetToDefaultFilters,
    setLocalActiveFilters,
    toggleFilter,
  } = props;

  const { isModalVisible } = useDNADocumentFilters()
  const enableEmailTemplates = useFeatureFlag(FeatureFlags.BEAC_2516_Enable_Document_Sharing_Templates)

  return (
    <Modal
      visible={isModalVisible}
      onBackdropPress={closeCancel}
      style={styles.container}
    >
      <DNABox alignY="start" alignX="center" fill>
        <DNACard style={styles.card}>
          <DNABox appearance="col" spacing="small">
            {/* Header */}
            <DNABox spacing="between" alignY="center">
              <DNAText h2>All filters</DNAText>
              {/* Right-Side Controls */}
              <DNABox
                alignY="center"
                spacing="medium"
                // Needed for the Vertical Divider
                childStyle={{ height: 48, justifyContent: 'center' }}
                childFill={2}
              >
                <DNAButton
                  testID="filter-modal-reset-button"
                  appearance="ghost"
                  status="neutral"
                  context="minimum"
                  onPress={() => {
                    resetToDefaultFilters(setLocalActiveFilters)
                  }}
                >
                  Reset
                </DNAButton>
                <DNAButton
                  testID="filter-modal-clear-button"
                  appearance="ghost"
                  status="neutral"
                  context="minimum"
                  onPress={() => {
                    clearFilters(setLocalActiveFilters)
                  }}
                >
                  Clear
                </DNAButton>
                <DNADivider vertical />
                <DNAButton
                  testID="filter-modal-cancel-button"
                  appearance="ghost"
                  status="neutral"
                  context="minimum"
                  onPress={closeCancel}
                >
                  Cancel
                </DNAButton>
                <DNAButton
                  testID="filter-modal-apply-button"
                  appearance="outline"
                  onPress={() => closeApply()}
                >
                  Apply
                </DNAButton>
              </DNABox>
            </DNABox>
            <DNADivider />
            {/* Content */}
            <DNABox
              as={ScrollView}
              fill
              wrap="start"
              style={{ maxHeight: '55vh' }}
            >
              {
                categories.map(([categoryLabel, categoryValues], idx) => (
                  <DNABox
                    key={categoryLabel}
                    appearance="col"
                    spacing="small"
                    style={[
                      // [NOTE]: This takes the place of the parent DNABox's '{spacing: "medium"}'
                      { marginRight: 16, padding: 24, width: '23%' },

                      // We explicitly lengthen the 3rd category to take up two slots
                      // so that this set of system labels has their own row
                      (!enableEmailTemplates && idx === 2) ? { width: '46%' } : undefined,
                    ]}
                  >
                    <DNAText h4 style={{ marginBottom: 14 }}>
                      {categoryLabel}
                    </DNAText>
                    {
                      // @ts-ignore - Fix typings here later
                      Object.entries(categoryValues).map(([_, fieldEntry]: any) => (
                        <DNABox
                          testID="filter-checkbox"
                          key={fieldEntry.fieldName + fieldEntry.fieldValue}
                          childFill={1}
                          spacing="small"
                        >
                          <DNACheckbox
                            // [TODO] - Move testID to DNACheckbox level to make selection easier
                            testID={fieldEntry.active ? 'checkbox-checked' : 'checkbox-unchecked'}
                            checked={fieldEntry.active}
                            onChange={() => toggleFilter(
                              fieldEntry.fieldName,
                              fieldEntry.fieldValue,
                              setLocalActiveFilters,
                            )}
                          />
                          <DNAText
                            numberOfLines={3}
                            ellipsizeMode="tail"
                          >{fieldEntry.fieldValue} ({fieldEntry.count})
                          </DNAText>
                        </DNABox>
                      ))
                    }
                  </DNABox>
                ))
              }
            </DNABox>
          </DNABox>
        </DNACard>
      </DNABox>
    </Modal >
  );
};

// [TODO]: PROPS LOGIC TO BE MOVED INTO A HOOK
const DNADocumentFilterModal = (props) => {
  const { deviceMode } = useAppSettings();

  return deviceMode === 'desktop'
    ? <DNADocumentFiltersModalDesktop {...props} />
    : <DNADocumentFiltersModalTablet {...props} />
};

export default DNADocumentFilterModal;
