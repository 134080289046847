import React from 'react';
import useMyContentPanelSharedResources, { MyContentPanelProps, Options } from '../../useMyContentPanelSharedResources';
import { DNABox, DNADivider, Iffy } from '@alucio/lux-ui';
import Header from './Header';
import Content from './Content';
import { FolderItemORM } from 'src/types/orms';
import { StyleSheet } from 'react-native';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

const styles = StyleSheet.create({
  content: {
    backgroundColor: colors['primary-900'],
    paddingBottom: 60,
  },
})

const MeetingsVariant: React.FC<MyContentPanelProps> = (props) => {
  const {
    selectedDocumentVersion,
    selectedTab,
    localSearchText,
    setSelectedTabHandler,
    displayMode,
    setLocalSearchText,
    setSearchText,
    searchText,
    setSelectedDocumentVersion,
  } = useMyContentPanelSharedResources(props)

  function onPresent(
    index: number | undefined, folderItemORM?: FolderItemORM,
  ): void {
    props.onSelectPresentableORM?.(folderItemORM || selectedDocumentVersion, index);
  }

  return (
    <DNABox appearance="col" fill style={styles.content}>
      <Header
        selectedTab={selectedTab}
        localSearchText={localSearchText}
        selectedDocument={selectedDocumentVersion?.relations.document}
        onSelectTab={setSelectedTabHandler}
        displayMode={displayMode}
        setLocalSearchText={setLocalSearchText}
        setSearchText={setSearchText}
        sessionId={props.sessionId}
      />
      <DNADivider variant="virtual" />
      <Iffy is={selectedTab} >
        <Content
          localSearchText={localSearchText}
          searchText={searchText}
          selectedTab={selectedTab as Options}
          selectedDocumentVersion={selectedDocumentVersion}
          setSelectedDocumentVersion={setSelectedDocumentVersion}
          setSelectedPage={onPresent}
          displayMode={displayMode}
          sessionId={props.sessionId}
        />
      </Iffy>
    </DNABox>
  )
}

export default MeetingsVariant
