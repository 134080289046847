import { DNABox, DNAText, Iffy } from '@alucio/lux-ui'
import React, { useRef } from 'react'
import { ImageBackground } from 'react-native'
import PlayerWrapper from 'src/components/Presentation/PlayerWrapper'
import { useLocation } from 'react-router'
import { PlayerMode } from '@alucio/core'
import { useMachine } from '@xstate/react'
import playerWrapperMachine from 'src/state/machines/presentation/playerWrapper'

interface ContentProps {
  meetingId: string
}

const Content:React.FC<ContentProps> = (props) => {
  const { meetingId } = props

  const mode:PlayerMode = 'NON_INTERACTIVE'
  const machine = useRef(playerWrapperMachine(meetingId, mode)).current
  const [{ value }] = useMachine(machine)

  const isIdle = value === 'idle'

  return (
    <DNABox fill style={{ backgroundColor: 'black' }}>
      <Iffy is={isIdle}>
        <ImageBackground
          source={require('../../../../assets/images/beacon-presentation-bg.jpg')}
          style={{ flex: 1 }}
        >
          <DNABox appearance="col" alignX="center" alignY="center" fill>
            <DNAText h1 status="basic">This is your presentation window.</DNAText>
            <DNAText h3 bold status="basic">Share this window with your attendees.</DNAText>
          </DNABox>
        </ImageBackground>
      </Iffy>

      <Iffy is={!isIdle}>
        <PlayerWrapper isFullWindow={true} meetingId={meetingId} mode={mode} />
      </Iffy>
    </DNABox>
  )
}

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PopoutContent:React.FC = () => {
  const query = useQuery()
  const meetingId = query.get('meetingId')

  if (!meetingId) return null

  return (
    <Content meetingId={meetingId}/>
  )
}

export default PopoutContent
