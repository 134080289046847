import { GetReportListQuery, GetEmbeddedUrlQuery } from '@alucio/aws-beacon-amplify/src/API'
import { getEmbeddedUrl, getReportList } from '@alucio/aws-beacon-amplify/src/graphql/queries'
import { StyleSheet, View, Image } from 'react-native';
import { DNABox, DNACard, DNASelect, DNAText, Iffy, IndexPath, useSafePromise } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import API, { graphqlOperation } from '@aws-amplify/api'
import React, { useState, createContext, useEffect, useContext, useRef } from 'react'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';

import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';

const styles = StyleSheet.create({
  header: {
    marginBottom: 0,
  },
  mainWrapper: {
    paddingHorizontal: 32,
    paddingVertical: 24,
    backgroundColor: colors['color-text-basic'],
    overflowY: 'auto',
  },
});

const options = {
  parameters: {
    country: 'United States',
  },
  scrolling: 'no',
  height: 'AutoFit',
  loadingHeight: '700px',
  width: '100%',
  footerPaddingEnabled: true,
  undoRedoDisabled: true,
  printEnable: true,
  resetDisabled: true,
};

type DashBoardType = {
  dasboardId: string;
  name: string;
}

type DashBoardListType = DashBoardType[] | undefined

type ReportProvider = {
  dashdoardList: DashBoardListType,
  onSelectedDashboard: (dashBoardId: string) => void,
  reportUrl: string | undefined
}

const ReportContext = createContext<ReportProvider>(null!)
const isLoading = (reportUrl) => reportUrl === 'loading'

const getReportListAsync = async (folderName: string) => {
  const gqlParams = {
    ...graphqlOperation(getReportList, { folderName: folderName }),
  }

  const { data } = await API.graphql(gqlParams) as {
    data: GetReportListQuery;
  };

  if (!data.getReportList) {
    throw new Error('No report list get from the service')
  }

  return data.getReportList.map(item => ({
    dasboardId: item?.dashboardId,
    name: item?.name,
  })) as DashBoardListType
}

const getEmbeddedUrlAsync = async (dashboardId: string) => {
  const gqlParams = {
    ...graphqlOperation(getEmbeddedUrl, { dashboardId: dashboardId }),
  }
  const { data } = await API.graphql(gqlParams) as {
    data: GetEmbeddedUrlQuery;
  };

  if (!data.getEmbeddedUrl) {
    throw new Error('An error occurred getting the embedded URL report')
  }

  return data;
}

const ReportProvider: React.FC = (props) => {
  const [dashdoardList, setDashboardList] = useState<DashBoardListType>()
  const [reportUrl, setReportUrl] = useState<string>();
  const safePromise = useSafePromise()

  useEffect(() => {
    const listReports = async (folderName: string) => {
      try {
        const dashBoardList = await safePromise.makeSafe(getReportListAsync(folderName))
        // [TODO-1918] - Could come back null, handle non-happy path
        // Consider inserting "Failed to Load" as the drop down selection
        setDashboardList(dashBoardList)
      }
      catch (e) {
        console.warn(e)
      }
    }

    listReports('Beacon_Embedded_Publisher')
  }, [])

  const onSelectedDashboard = async (dashboardId: string) => {
    setReportUrl('loading')

    const data = await getEmbeddedUrlAsync(dashboardId)

    // [TODO-1918] - Could come back null, handle non-happy path
    // Consider showing `DNAEmpty` message type of error image/text
    setReportUrl(data.getEmbeddedUrl || undefined)
  }

  const contextValues = {
    dashdoardList,
    onSelectedDashboard,
    reportUrl,
  }

  return (
    <ReportContext.Provider value={contextValues}>
      {props.children}
    </ReportContext.Provider>
  )
}

const useReport = () => useContext(ReportContext)

const ReportSelector = () => {
  const { dashdoardList, onSelectedDashboard } = useReport();
  const [selectedReport, setSelectedReport] =
    useState<{
      index: IndexPath | IndexPath[],
      selectedDashBoard: DashBoardType | undefined
    }
    >()

  return (
    <DNABox appearance="col" spacing="small">
      <DNASelect
        size="small"
        placeholder="SELECT A REPORT"
        selectedIndex={selectedReport?.index}
        value={selectedReport?.selectedDashBoard?.name}
        onSelect={(v) => {
          if (Array.isArray(v)) return;
          const selectedDashBoard = dashdoardList && dashdoardList[v.row];
          selectedDashBoard && onSelectedDashboard(selectedDashBoard.dasboardId)
          setSelectedReport({ index: v, selectedDashBoard: selectedDashBoard })
        }}
      >
        {
          dashdoardList?.map((x) => <DNASelect.Item key={x.dasboardId} title={x.name} />)
        }
      </DNASelect>
    </DNABox>
  )
}

const Loading = () => {
  const { reportUrl } = useReport();

  if (!isLoading(reportUrl)) return null

  return (
    <Iffy is={isLoading(reportUrl)}>
      <DNABox fill alignX="center" alignY="center">
        <Image
          testID="dna-loader-report"
          style={{ width: 200, height: 200 }}
          source={require('../../../assets/images/LoaderDNA.gif')}
        />
      </DNABox>
    </Iffy>
  )
}

const EmbeddedReport = () => {
  const { reportUrl } = useReport();
  const dasboard = useRef()
  const isHttpsReport = reportUrl?.startsWith('https:/')

  useEffect(() => {
    if (reportUrl && !isLoading(reportUrl)) {
      const containerDiv = document.getElementById('embedded-report-id')
      dasboard.current = QuickSightEmbedding.embedDashboard({ ...options, url: reportUrl, container: containerDiv });
    }
  }, [reportUrl])

  return (
    <>
      <Iffy is={!reportUrl}>
        <DNACard appearance="flat">
          <DNAEmpty emptyVariant={EmptyVariant.AnalyticsEmpty} />
        </DNACard>
      </Iffy>
      <Iffy is={isHttpsReport}>
        <View style={{ flex: 1 }} nativeID="embedded-report-id" />
      </Iffy>
    </>
  )
}

const Report = () => {
  const currentPage = useCurrentPage()
  return (
    <ReportProvider>
      <DNABox
        style={styles.mainWrapper}
        spacing="medium"
        childFill={2}
        appearance="col"
        fill
      >
        { /* HEADER */}
        <DNABox alignY="center" style={styles.header}>
          <DNAText h5 status="subtle" testID="page-title">
            {currentPage?.LABEL}
          </DNAText>
        </DNABox>
        <ReportSelector />
        <DNABox fill>
          <EmbeddedReport />
          <Loading />
        </DNABox>
      </DNABox>
    </ReportProvider>
  )
}

Report.displayName = 'Reports';

export default Report
