import React, { ReactElement, useCallback, useMemo, useRef } from 'react';
import {
  DNABox,
  DNAButton,
  Tabs,
  Iffy, luxColors, DNAIcon,
} from '@alucio/lux-ui';
import { DisplayMode } from 'src/components/DNA/hooks/useDisplayMode'
import InputComponent from '../Publishers/InputComponent';
/** Possible future refactor of Input to lux-ui export per Jose suggestion  */
import { Input } from '@ui-kitten/components';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCanPerformSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import debounce from 'lodash/debounce';
import { DocumentORM } from 'src/types/types';

const styles = StyleSheet.create({
  searchBarStyle: {
    alignSelf: 'center',
    backgroundColor: luxColors.contentPanelBackground.tertiary,
    borderColor: luxColors.contentPanelBackground.tertiary,
    borderRadius: 4,
    color: luxColors.info.primary,
    flexDirection: 'row',
    width: '100%',
  },
  tabsWrapper: {
    flexDirection: 'row',
    flexGrow: 0.6,
  },
});

const tabs: TabProps['option'][] = [
  {
    title: 'My Folders',
    iconImage: require('../../../assets/images/Icons/Folder.svg'),
  },
  {
    title: 'Bookmarks',
    iconImage: require('../../../assets/images/Icons/Bookmarks.svg'),
  },
  {
    title: 'Library',
    iconImage: require('../../../assets/images/Icons/Molecule.svg'),
  },
];

interface Props {
  localSearchText: string,
  onClosePanel: () => void,
  onSelectTab: (tab: string) => void,
  selectedTab: string,
  displayMode: DisplayMode,
  setLocalSearchText: (text: string) => void,
  setSearchText: (text: string) => void,
  sessionId?: string,
  selectedDocument?: DocumentORM
}

interface TabProps {
  isSelected: boolean;
  option: {
    title: string,
    iconImage: any,
  }
  onTabSelected: (tab: string) => void,
}

function Header(props: Props) {
  const {
    localSearchText,
    onClosePanel,
    onSelectTab,
    selectedTab,
    displayMode,
    setLocalSearchText,
    setSearchText,
    selectedDocument,
    sessionId,
  } = props;

  const canPerformSearch = useCanPerformSearch();
  // Used to return to that tab if user clears the search input
  const tabSelectedBeforeSearch = useRef(tabs[0].title);

  // Memoized Components
  const MemoizedClosePanelButton = useMemo(() => getClosePanelButton(), []);
  const MemoizedToggleViewButton = useMemo(() => getToggleViewButton(), [displayMode.displayModeIconName]);
  const MemoizedClearTextButton = useMemo(() => getClearButtonIcon(), [selectedTab]);
  const MemoizedMagnifying = useMemo(() => renderMagnifyingIcon(), []);
  const MemoizedMagnifyingFunction = useCallback(() => MemoizedMagnifying, []);
  const MemoizedTabs = useMemo(() => getTabs(), [selectedTab]);
  const inputRef = useRef<Input>(null)

  function renderCloseIcon(): ReactElement {
    if (!localSearchText) {
      return <DNABox />;
    }

    return MemoizedClearTextButton;
  }

  function getTabs(): ReactElement {
    return <Tabs selectedTab={selectedTab} onSelectTab={handleTabSelect} tabs={tabs} />;
  }

  function getClearButtonIcon(): ReactElement {
    const handleClear = () => {
      onSearchTextChange('')
      inputRef.current?.focus()
    }
    return (
      <TouchableOpacity onPress={handleClear}>
        <DNAIcon.Styled name="close" appearance="ghost" status="subtle" />
      </TouchableOpacity>
    );
  }

  function renderMagnifyingIcon(): ReactElement {
    return (
      <TouchableOpacity disabled>
        <DNAIcon.Styled name="magnify" appearance="ghost" status="subtle" />
      </TouchableOpacity>
    );
  }
  function handleTabSelect(tab:string) {
    setLocalSearchText('');
    onSelectTab(tab);
  }

  const handleTrackAction = debounce((text: string): void => {
    analytics?.track('VIRTUAL_CONTENT_SEARCH', {
      action: 'CONTENT_SEARCH',
      category: 'VIRTUAL',
      searchText: text,
      sessionId,
    });
  }, 1000);

  // IF WE DON'T MEMOIZE, ANOTHER INSTANCE OF HANDLESEARCH WILL BE CREATED UPON EVERY RENDER
  // THEREFORE, THE DEBOUNCE EFFECT WE NEED WON'T HAVE ANY EFFECT
  const MemoizedHandleTrackAction = useCallback(handleTrackAction, [sessionId]);

  function onSearchTextChange(text: string): void {
    const canTextBeSearchable = canPerformSearch(text);
    setLocalSearchText(text);

    if (text) {
      debounce(() => {
        setSearchText(text);
      }, 1000)();
    }

    text && MemoizedHandleTrackAction(text);

    if (canTextBeSearchable && selectedTab && selectedTab !== 'Search results') {
      tabSelectedBeforeSearch.current = selectedTab;
      onSelectTab('Search results');
    } else if (!canTextBeSearchable && selectedTab === 'Search results') {
      onSelectTab(tabSelectedBeforeSearch.current);
    }
  }

  function getClosePanelButton(): ReactElement {
    return (
      <DNAButton appearance="ghost" status="subtle" context="minimum" onPress={onClosePanel}>
        Close
      </DNAButton>
    );
  }

  function getToggleViewButton(): ReactElement {
    return (
      <Iffy is={displayMode.enableDisplayMode}>
        <DNAButton
          appearance="ghost"
          status="subtle"
          context="minimum"
          size="large"
          iconLeft={displayMode.displayModeIconName}
          onPress={displayMode.toggleDisplayMode}
        />
      </Iffy>
    );
  }

  return (
    <DNABox alignY="center" style={{ paddingHorizontal: 32, justifyContent: 'space-between' }}>
      <DNABox alignY="center" style={styles.tabsWrapper}>
        {MemoizedTabs}
        <View style={{ flex: 1, marginLeft: 36 }}>
          <InputComponent
            ref={inputRef}
            getRightIconFunction={renderCloseIcon}
            getLeftIconFunction={MemoizedMagnifyingFunction}
            hideLabel={true}
            inputStyle={styles.searchBarStyle}
            onChangeText={onSearchTextChange}
            placeholderTextColor={luxColors.contentPanelBackground.quinary}
            removeMarginPadding={true}
            size="small"
            title="Search documents..."
            value={localSearchText}
          />
        </View>
      </DNABox>
      <DNABox alignY="center" spacing="medium">
        <Iffy is={!selectedDocument}>
          {MemoizedToggleViewButton}
        </Iffy>
        {MemoizedClosePanelButton}
      </DNABox>
    </DNABox>
  );
}

Header.displayName = 'ContentPanelHeader';

export default Header;
