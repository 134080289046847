import React, { useEffect, useState } from 'react';
import { DNABox, DNAButton, DNADivider, DNAText, Iffy, Text } from '@alucio/lux-ui';
import { useForm, Controller } from 'react-hook-form'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { zodResolver } from '@hookform/resolvers/zod'
import { ScrollView, StyleSheet } from 'react-native';
import * as z from 'zod'
import { useDispatch } from 'react-redux';
import InputComponent from 'src/components/Publishers/InputComponent';
import AddMeetingFooter from './AddMeetingFooter';
import { meetingActions } from 'src/state/redux/slice/meeting';
import { MeetingStatus, MeetingType, Meeting } from '@alucio/aws-beacon-amplify/src/models';
import { format, add, parseISO } from 'date-fns';
import DateComponent from 'src/components/Publishers/DateComponent';
import DNAMeetingDeleteModal from '../DNA/Modal/DNAMeetingDeleteModal';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { drawerActions, DRAWER_ENTITIES } from 'src/state/redux/slice/drawer';
import { useMeeting } from 'src/state/redux/selector/meeting';
import ContentPresentedList from './ContentPresentedList';
import DNAMeetingContentDeleteModal from '../DNA/Modal/DNAMeetingContentDeleteModal';

const styles = StyleSheet.create({
  inputStyle: {
    borderColor: colors['color-flat-400'],
  },
  titleContainer: {
    justifyContent: 'space-between',
    height: 56,
    paddingLeft: 24,
    paddingRight: 24,
  },
  title: {
    color: colors['flat-900'],
    fontSize: 16,
  },
  contentContainer: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  inputLabel: {
    color: colors['color-dark-500'],
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
})

const formSchema = z.object({
  title: z
    .string()
    .nonempty({ message: 'This field is required' }),
  endTime: z
    .date()
    .optional(),
  startTime: z
    .date()
    .optional(),
  type: z
    .string()
    .nonempty({ message: 'This field is required' }),
  status: z
    .string()
    .nonempty({ message: 'This field is required' }),
  notes: z
    .string()
    .optional(),
  contentPresented: z
    .array(z.any())
    .optional(),
}).partial().refine((partialInput) => {
  if (!partialInput.startTime || !partialInput.endTime) {
    return true;
  }
  return partialInput.startTime < partialInput.endTime
}, { message: 'End time must be after the start time', path: ['endTime'] });

export enum PERFORMING_ACTION {
  ADD,
  EDIT
}

interface Props {
  meetingId?: string,
  toggleDrawer: () => void,
}

const AddMeeting = (props: Props) => {
  const action = props.meetingId ? PERFORMING_ACTION.EDIT : PERFORMING_ACTION.ADD
  const meetingORM = useMeeting(props.meetingId!)
  const dispatch = useDispatch();
  const meetForm = useForm({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      title: meetingORM?.model.title ||
        `Meeting Started at ${format(new Date(), 'MM/dd/yyyy h:mm aa')}`,
      startTime: meetingORM
        ? parseISO(meetingORM.model.startTime)
        : new Date(),
      endTime: !meetingORM
        ? add(new Date(), { hours: 1 })
        : meetingORM?.model.endTime
          ? parseISO(meetingORM.model.endTime!)
          : undefined,
      type: MeetingType.MANUAL,
      contentPresented: [],
      status: MeetingStatus.ENDED_EXIT,
      notes: meetingORM ? meetingORM.model.notes : '',
    },
  })

  const {
    control, handleSubmit, reset,
    formState: { errors, isDirty, isValid, isSubmitSuccessful, isSubmitting },
  } = meetForm
  const [isSaved, setIsSaved] = useState(isSubmitSuccessful)

  useEffect(() => {
    meetingORM?.model.endTime && meetForm.setValue(
      'endTime',
      parseISO(meetingORM?.model.endTime,
      ),
    )
  }, [meetingORM])

  useEffect(() => {
    isSubmitSuccessful && setIsSaved(true);
    setTimeout(() => {
      setIsSaved(false);
    }, 3000)
  }, [isSubmitSuccessful])

  function onCancel(): void {
    props.toggleDrawer()
  }

  function onDeleteContent(contentPresentedId: string, folderItemId?: string): void {
    if (meetingORM) {
      const payload = {
        isVisible: true,
        allowBackdropCancel: false,
        component: () =>
          (<DNAMeetingContentDeleteModal
            meeting={meetingORM?.model}
            contentPresentedId={contentPresentedId}
            folderItemId={folderItemId}
          />),
      };
      dispatch(DNAModalActions.setModal(payload));
    }
  }

  function onDelete(): void {
    if (meetingORM) {
      const payload = {
        isVisible: true,
        allowBackdropCancel: false,
        component: () =>
          (<DNAMeetingDeleteModal
            meeting={meetingORM?.model}
            onDelete={props.toggleDrawer}
          />),
      };
      dispatch(DNAModalActions.setModal(payload));
    }
  }
  const onSubmit = async (data: Meeting) => {
    if (!meetingORM) {
      dispatch(meetingActions.createMeeting({
        ...data,
        startTime: new Date(data.startTime).toISOString(),
        endTime: new Date(data.endTime || new Date()).toISOString(),
        status: MeetingStatus.ENDED_EXIT,
        type: MeetingType.MANUAL,
        contentPresented: [],
        callback: onComplete,
      }))
    } else {
      const payload = {
        ...meetingORM?.model,
        startTime: new Date(data.startTime).toISOString(),
        endTime: new Date(data.endTime || new Date()).toISOString(),
        title: data.title,
        notes: data.notes,
      };
      dispatch(meetingActions.updateMeeting(
        meetingORM?.model, payload,
      ))

      // Event when meeting is updated through the form
      analytics?.track('MEETING_UPDATED', {
        action: 'UPDATED',
        category: 'MEETING',
        meetingId: meetingORM.model.id,
      });
      onComplete(payload)
    }
  }

  function onComplete(meeting: Meeting) {
    dispatch(drawerActions.toggle({
      entity: DRAWER_ENTITIES.MEETING,
      entityId: meeting.id,
      keepOpen: true,
    }));
    reset(
      { ...meeting, startTime: parseISO(meeting.startTime), endTime: parseISO(meeting.endTime!) }, {
        isDirty: false,
      });
  }

  return (
    <DNABox fill appearance="col">
      <ScrollView>
        <DNABox fill appearance="col" spacing="medium" >
          <DNABox appearance="col">
            <DNABox alignY="center" appearance="row" spacing="small" style={styles.titleContainer}>
              <DNAText bold style={styles.title}>Meeting Details</DNAText>
              <DNABox>
                <DNAButton
                  status="subtle"
                  appearance="ghost"
                  context="minimum"
                  iconLeft="close"
                  onPress={onCancel}
                />
              </DNABox>
            </DNABox>
            <DNADivider />
          </DNABox>
          <DNABox spacing="medium" appearance="col" style={styles.contentContainer}>
            <DNABox appearance="col">
              <Controller
                name="title"
                title="Meeting Title"
                control={control}
                as={InputComponent}
                required
                hideLabel={false}
                multiline={true}
                numOfLines={2}
                characterLimit={250}
                disabled={false}
                showCharacterCounter
                inputStyle={errors.title && { borderColor: colors['color-danger-500'] }}
                removeMarginPadding={true}
              />
              {
                errors.title &&
                  <DNAText status="danger">
                    {errors.title.message}
                  </DNAText>
              }
            </DNABox>
            <DNABox appearance="col" spacing="tiny">
              <DNABox fill appearance="row">
                <Controller
                  control={control}
                  name="startTime"
                  title="start Date"
                  render={props => (
                    <DNABox appearance="col" style={{ marginRight: 10 }} fill>
                      <DateComponent
                        title="Start Time"
                        disabled={false}
                        includeTimePicker={true}
                        dateFormat="MM/dd/yyyy h:mm aa"
                        selectedDate={props.value}
                        onDateSelected={props.onChange}
                        removeMarginPadding
                      />
                    </DNABox>
                  )}
                />
                <Controller
                  control={control}
                  name="endTime"
                  title="start Date"
                  render={props => (
                    <DNABox appearance="col" style={{ marginLeft: 10 }} fill>
                      <DateComponent
                        title="End Time"
                        disabled={false}
                        includeTimePicker={true}
                        dateFormat="MM/dd/yyyy h:mm aa"
                        selectedDate={props.value}
                        onDateSelected={props.onChange}
                        removeMarginPadding
                        required={false}
                      />
                    </DNABox>
                  )}
                />
              </DNABox>
            </DNABox>
            {
              errors.endTime &&
                <DNAText status="danger">
                  {errors.endTime.message}
                </DNAText>
            }
            <DNABox spacing="medium" appearance="col">
              <Text style={styles.inputLabel}>Content Presented</Text>
              <Iffy is={action === PERFORMING_ACTION.ADD}>
                <DNAText status="flat">
                  No content was presented
                </DNAText>
              </Iffy>
              <Iffy is={action === PERFORMING_ACTION.EDIT}>
                <ContentPresentedList
                  onDelete={onDeleteContent}
                  isEditForm={true}
                  contentPresented={meetingORM?.model.contentPresented || []}
                />

              </Iffy>
            </DNABox>
            <DNABox appearance="col" spacing="medium">
              <Controller
                name="notes"
                title="Meeting Notes"
                placeHolder="Notes..."
                control={control}
                as={InputComponent}
                hideLabel={false}
                required={false}
                multiline={true}
                characterLimit={1000}
                disabled={false}
                showCharacterCounter
                removeMarginPadding={true}
                numOfLines={10}
                inputStyle={[styles.inputStyle]}
              />
            </DNABox>
            <DNABox />
          </DNABox>
        </DNABox>
      </ScrollView>
      <AddMeetingFooter
        closeDrawer={onCancel}
        onDelete={onDelete}
        isFormEdited={isDirty && isValid}
        isSubmitting={isSubmitting}
        isSaved={isSaved}
        action={action}
        onSave={handleSubmit(onSubmit)}
      />
    </DNABox>

  )
}
AddMeeting.displayName = 'AddMeeting';
export default AddMeeting;
