import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { Meeting } from '@alucio/aws-beacon-amplify/src/models'
import { RootState } from '../store'
import { ORMTypes, MeetingORM } from 'src/types/types';

export type MeetingORMMap = { [meetingId: string]: MeetingORM }
export const selMeetings = (state: RootState): Meeting[] => state.meeting.records;
export const selMeetingId = (_, __, meetingId: string) => meetingId;

const toMeetingORM = (
  meeting: Meeting,
): MeetingORM => {
  const meetingORM: MeetingORM = {
    model: meeting,
    type: ORMTypes.MEETING,
  }

  return meetingORM
}

const meetingList = createSelector(
  selMeetings,
  (meetings): MeetingORM[] => meetings.map(meeting => toMeetingORM(meeting)),
);

const meeting = createSelector(
  meetingList,
  selMeetingId,
  (meetings, meetingId): MeetingORM | undefined =>
    meetings.find(({ model }) => model.id === meetingId),
)

export const useMeetingList = (): ReturnType<typeof meetingList> =>
  useSelector((state: RootState) => meetingList(state));

export const useMeeting = (meetingId: string): ReturnType<typeof meeting> =>
  useSelector((state: RootState) => meeting(state, undefined, meetingId));
