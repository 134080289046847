import { User } from '@alucio/aws-beacon-amplify/src/models'

/**
 * Simple singleton that keeps track of the currently logged in user
 *  Although this is also stored/accessed in Redux/Reselect, it's difficult to
 *  work with at times. This does not provide all the options currently that the
 *  selector alternative does
*/
class ActiveUser {
  // [TODO] - Consider removing undefined
  // and just erroring if the actual value is undefined
  // Generally we can assume that we'll have an ActiveUser when logged in/being used
  user: User | undefined

  set(user?: User) {
    if (!User) {
      throw new Error('No user identified')
    }

    this.user = user
  }

  clear() {
    this.user = undefined
  }

  isSet(): boolean {
    return !!this.user
  }
}

export default new ActiveUser()
