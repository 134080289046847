import _merge from 'lodash/merge'
import { FolderItemORM } from 'src/types/types'
import { FilterAndSortOptions } from 'src/state/redux/selector/common'
import { isCustomDeckORM, isDocumentVersionORM, isFolderORM } from 'src/types/typeguards'

type FilterSortMap<T extends string> = {
  [K in T]: FilterAndSortOptions<FolderItemORM>
}

export const merge: (
  ...args: FilterAndSortOptions<FolderItemORM>[]
) => FilterAndSortOptions<FolderItemORM> = (...args) => _merge({}, ...args)

export const sorts: FilterSortMap<'folderDisplayAsc'> = {
  folderDisplayAsc: {
    sort: [
      {
        relations: {
          item: (a, b) => {
            if (a.type !== b.type) {
              return b.type.localeCompare(a.type)
            } else {
              if (isFolderORM(a) && isFolderORM(b)) {
                const aFolder = a.model
                const bFolder = b.model

                if (a.meta.isSharedWithTheUser && b.meta.isSharedWithTheUser) {
                  return aFolder.name.localeCompare(bFolder.name);
                }
                return aFolder.isPinned === bFolder.isPinned ? aFolder.name.localeCompare(bFolder.name)
                  : aFolder.isPinned ? -1 : 1
              } else if (isCustomDeckORM(a) && isCustomDeckORM(b)) {
                // If the custom deck was just created the value of createdAt field generated in DynamoDB will be undefined and the autogenerated
                // value will come in another update msg. For those cases we just return 1
                return (
                  a
                ).model.createdAt?.localeCompare(
                  b.model.createdAt,
                ) ?? 1
              } else {
                if (isDocumentVersionORM(a) && isDocumentVersionORM(b)) {
                  const docVersionA = a.relations.document.relations.version.latestUsableDocumentVersion
                  const docVersionB = b.relations.document.relations.version.latestUsableDocumentVersion
                  return docVersionA.model.title!
                    .localeCompare(
                      docVersionB.model.title!,
                    )
                } else {
                  return 1
                }
              }
            }
          },
        },
      },
    ],
  },
}

export default {
  sorts,
  merge,
}
