import React, { useState } from 'react';
import { DNABox, DNAButton, DNAChip, DNADivider, DNAText, Iffy } from '@alucio/lux-ui';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import PurposeBadge from 'src/components/PurposeBadge/PurposeBadge';
import { isDocumentVersionORM, isFolderItemORM } from 'src/types/types';
import { PurposeType } from '@alucio/aws-beacon-amplify/src/models';
import DNAActionBar, { DNAActionBarElement } from 'src/components/DNA/ActionBar/DNAActionBar';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { ImageBackground, StyleSheet } from 'react-native';
import SlideRollWrapper from '../../SharedComponents/SlideRollWrapper';
import PresentationNav from '../../SharedComponents/PresentationNav';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import PlayerWrapper from 'src/components/Presentation/PlayerWrapper'

const styles = StyleSheet.create({
  iframeContainer: {
    borderColor: colors['color-success-500'],
    borderWidth: 4,
  },
  speakerNotesContainer: {
    width: 320,
    borderLeftWidth: 1,
    borderColor: '#383838',
  },
  speakerNotesHeader: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  speakerNotesContent: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  slideRollContainer: {
    borderRightWidth: 1,
    borderColor: '#383838',
  },
});

const Presenting = () => {
  const { activePresentation } = useContent();
  const [speakerNotesVisible, setSpeakerNotesVisible] = useState<boolean>(false);
  const [displaySlideRoll, setDisplaySlideRoll] = useState<boolean>(true);
  const { setCurrentTab, meetingORM } = useMeetingsState();

  if (!meetingORM) return null
  const speakerNotes = activePresentation?.currentPresentablePage.page.speakerNotes ?? 'No slide notes';

  const toggleSpeakerNotes = () => {
    setSpeakerNotesVisible((prev) => !prev);
  }

  const toggleSlideRoll = () => {
    setDisplaySlideRoll((prev) => !prev);
  }

  const handleCollapse = () => {
    setCurrentTab('OPENED_PRESENTATIONS')
  }

  const renderActivePresentationInfo = () => {
    if (!activePresentation) {
      return <></>;
    }

    const { orm } = activePresentation.presentable
    const { purpose } = activePresentation.currentPresentablePage.documentVersion.model
    const isDocumentVersion =
      isDocumentVersionORM(orm) ||
      isDocumentVersionORM(orm.relations.item);
    const isModifiedDocumentVersion =
      isFolderItemORM(orm) &&
      isDocumentVersion &&
      orm.model.visiblePages?.length;
    const showModifiedBadge =
      isModifiedDocumentVersion ||
      !isDocumentVersion;

    return (
      <DNABox fill>
        <DNABox style={{ marginRight:12 }}>
          { /* DOCUMENT VERSION BADGE */ }
          { purpose && isDocumentVersion &&
            <PurposeBadge
            /** TODO: Properly type the purpose prop on the documentVersion.model so
            * we don't have to use the as assertion here. See https://alucioinc.atlassian.net/browse/BEAC-2873 */
              purpose={purpose as PurposeType}
            />
            }

          { /* MODIFIED BADGE */ }
          { showModifiedBadge &&
            <DNAChip appearance="tag" status="subtle">
              MODIFIED
            </DNAChip>
            }

        </DNABox>
        <DNABox fill style={{ marginRight:12 }}>
          <DNAText numberOfLines={1} status="basic">
            {activePresentation.presentable.title}
          </DNAText>
        </DNABox>
      </DNABox>
    )
  }

  const actionBarItems:DNAActionBarElement[] = [
    {
      onPress: toggleSlideRoll,
      children: <DNAText status="basic">Slides</DNAText>,
      appearance: 'ghost',
      status: 'slate',
      context: 'square',
      size: 'medium',
      key: 'Slides',
      testID: 'toggle-slides-button',
    },
    {
      onPress: toggleSpeakerNotes,
      children: <DNAText status="basic">Speaker Notes</DNAText>,
      appearance: 'ghost',
      status: 'slate',
      context: 'square',
      size: 'medium',
      key: 'Speaker Notes',
      testID: 'toggle-speaker-notes-button',
    },
  ]
  return (
    <DNABox appearance="col" fill>
      {/* HEADER */}
      <DNABox fill alignY="center" alignX="end" style={{ padding: 16, maxHeight:56 }}>
        {renderActivePresentationInfo()}
        {/* <DNABox fill alignX="end"> */}
        <DNAButton
          status="slate"
          appearance="filled"
          context="square"
          iconLeft="arrow-collapse"
          testID="collapse-button"
          onPress={handleCollapse}
        >
          Collapse
        </DNAButton>
        {/* </DNABox> */}
      </DNABox>
      <DNADivider variant="virtual"/>

      {/* CONTENT */}
      <DNABox fill>

        {/* SLIDE ROLL */}
        <Iffy is={activePresentation}>
          <DNABox
            style={[styles.slideRollContainer,
              !displaySlideRoll && { width: 0, height: 0 }]}
          >
            <SlideRollWrapper />
          </DNABox>
        </Iffy>
        <DNABox appearance="col" fill>
          {/* TODO: replace with eric's iframe component here */}
          {/* PRESENTATION */}
          <DNABox style={styles.iframeContainer} fill>
            {/* IFRAME */}
            {activePresentation
              ? <PlayerWrapper isFullWindow={true} meetingId={meetingORM.model.id} mode="INTERACTIVE" />
              : <ImageBackground
                  source={require('../../../../../assets/images/beacon-presentation-bg.jpg')}
                  style={{ flex: 1 }}
              />
            }

          </DNABox>
          <Iffy is={activePresentation}>
            {/* ACTION BAR */}
            <DNABox alignY="center" alignX="center" spacing="small" style={{ padding : 12 }}>
              {/* SLIDE NAVIGATION */}
              <PresentationNav />
              <DNAActionBar spacing="small" items={actionBarItems}/>
            </DNABox>
          </Iffy>
        </DNABox>
        {/* SPEAKER NOTES */}
        <Iffy is={speakerNotesVisible}>
          <DNABox appearance="col" style={styles.speakerNotesContainer}>
            <DNABox spacing="between" alignY="center" style={styles.speakerNotesHeader}>
              <DNAText status="basic">Speaker Notes</DNAText>
              <DNAButton
                status="slate"
                appearance="filled"
                iconLeft="close"
                context="square"
                onPress={toggleSpeakerNotes}
                testID="close-speaker-notes-button"
              />
            </DNABox>
            <DNADivider variant="virtual" />
            <DNAText status="basic" style={styles.speakerNotesContent}>{speakerNotes}</DNAText>
          </DNABox>
        </Iffy>
      </DNABox>
    </DNABox>
  );
};

Presenting.displayName = 'Presenting';

export default Presenting;
