import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import im from 'immer'

import { Dispatch } from 'redux'
import { DocumentVersionORM, EmailTemplateORM, FolderItemORM } from 'src/types/types'
import { CurrentUser, useCurrentUser } from 'src/state/redux/selector/user'

import { folderActions } from 'src/state/redux/slice/folder'
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal'
import { downloadContentFromCloudfront } from 'src/utils/loadCloudfrontAsset/loadCloudfrontAsset'
import { handleBookmark } from 'src/components/Library/Util/util'
import { GenericToast, useToast, ToastOrientations } from '@alucio/lux-ui'
import { copyShareableLinkToClipboard, shareEmail } from 'src/utils/shareLink/shareLink'
import { ShareType } from '@alucio/aws-beacon-amplify/src/models'
import { ToastActions } from '@alucio/lux-ui/lib/components/Toast/useToast'
import { slideSelectorModalActions } from 'src/state/redux/slice/SlideSelectorModal/SlideSelectorModal'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import DNAFileShareModal, { ShareFileOption } from 'src/components/DNA/Modal/DNAFileShareModal'
import { useEmailTemplateListForMSL } from 'src/state/redux/selector/emailTemplate'
import DNADocumentAddToFolder from '../Modal/DNADocumentAddToFolder'
import emailTemplateQuery from 'src/state/redux/emailTemplate/query'
import { sortCollection } from 'src/state/redux/selector/common'
import API, { graphqlOperation } from '@aws-amplify/api';
import {
  downloadDocumentVersionAsPdfFile,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations'
import { download } from 'src/screens/Documents/Export'

/**
 * [TODO] - Create safer structure/typings with Folder equivalent
 */

export type MatchFolderParams = {
  page: string,
  folderId: string
}

export type MatchNestedFolderParams = MatchFolderParams & {
  nestedFolderId: string
}

export type DocumentVersionContextOptions = {
  [key in keyof typeof DocumentVersionContextActions]: {
    icon: string,
    title: DocumentVersionContextActions,
    onPress: (
      d: DocumentVersionORM,
      dispatch: Dispatch,
      // [TODO] - Not the cleanest, starting to deviate ...
      user: CurrentUser,
      toast: ToastActions,
      folderItemORM?: FolderItemORM,
      emailTemplates?: EmailTemplateORM[]
    ) => () => void
  }
}

export type BindDocumentVersionContextActions = {
  [key in keyof typeof DocumentVersionContextActions]: (d: DocumentVersionORM, f?: FolderItemORM) => () => void
}

export enum DocumentVersionContextActions {
  addToFolder = 'Add to folder',
  download = 'Download',
  downloadAsPDF = 'Download as PDF',
  duplicate = 'Duplicate',
  copyShareLink = 'Copy shareable link',
  shareEMail = 'Share',
  present = 'Present',
  preview = 'Preview',
  removeFromFolder = 'Remove from folder',
  bookmark = 'Bookmark',
  selectSlides = 'Select slides',
  rename = 'Rename'
}

export type DocumentVersionActions = (keyof typeof DocumentVersionContextActions)

/** [TODO] WE CAN PROBABLY CONSLIDATE BOTH FOLDER AND DOCUMENT */
/** This is a bit more convenient than using the raw contextActions below */
export const useDNADocumentVersionActions = (): BindDocumentVersionContextActions => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const toast = useToast()
  const emailTemplates = useEmailTemplateListForMSL()
  const orderedTemplates = sortCollection(
    emailTemplates,
    emailTemplateQuery.sorts.titleAsc,
  )

  const bound = useMemo(() => im(documentVersionContextOptions, draft => {
    for (const action in documentVersionContextOptions) {
      const actionTyped = action as keyof typeof DocumentVersionContextActions
      draft[action] = (
        documentVersionORM: DocumentVersionORM,
        folderItemORM?: FolderItemORM,
      ) => documentVersionContextOptions[actionTyped].onPress(
        documentVersionORM,
        dispatch,
        currentUser,
        toast,
        folderItemORM,
        orderedTemplates,
      )
    }
  }) as unknown as BindDocumentVersionContextActions,
  [dispatch, currentUser, toast, orderedTemplates],
  )

  return bound
}

export const documentVersionContextOptions: DocumentVersionContextOptions = {
  download: {
    icon: 'download',
    title: DocumentVersionContextActions.download,
    onPress: (currentDocVersionORM) => () => {
      analytics?.track('DOCUMENT_DOWNLOAD', {
        action: 'DOWNLOAD',
        category: 'DOCUMENT',
        documentId: currentDocVersionORM.model.documentId,
        documentVersionId: currentDocVersionORM.model.id,
      });

      downloadContentFromCloudfront(
        currentDocVersionORM.model.srcFile.key,
        currentDocVersionORM.model.srcFilename,
        currentDocVersionORM.model.type,
      )
    },
  },
  duplicate: {
    icon: 'content-copy',
    title: DocumentVersionContextActions.duplicate,
    onPress: (d, dispatch, u, t, fiORM) => () => {
      const defaultTitle = d.model.title
      const targetFolderORM = fiORM?.relations.parent
      const itemToDupe = fiORM?.model

      defaultTitle &&
      targetFolderORM &&
      itemToDupe &&
      dispatch(folderActions.duplicateItem(defaultTitle, targetFolderORM, itemToDupe))
    },
  },
  present: {
    icon: 'play',
    title: DocumentVersionContextActions.present,
    onPress: (docVersionORM, dispatch, _, __, folderItemORM) => () => {
      dispatch(
        contentPreviewModalActions.setModalVisibility({
          documentVersionId: docVersionORM.model.id,
          folderItemORM: folderItemORM,
          isFullWindow: true,
          fromEllipsis: true,
          isOpen: true,
        },
        ))
    },
  },
  preview: {
    icon: 'play',
    title: DocumentVersionContextActions.present,
    onPress: (docVersionORM, dispatch, _, __, folderItemORM) => () => {
      dispatch(
        contentPreviewModalActions.setModalVisibility({
          documentVersionId: docVersionORM.model.id,
          folderItemORM: folderItemORM,
          isFullWindow: false,
          isOpen: true,
        },
        ))
    },
  },
  removeFromFolder: {
    icon: 'folder-remove',
    title: DocumentVersionContextActions.removeFromFolder,
    onPress: (docVersionORM, dispatch, _, toast, folderItemORM) => () => {
      // [TODO-279] This is a pretty hacky workaround
      //  Context menus don't allow a good path to pass in arbitrary values in right now
      //  So determine the target folder by checking what folder we're currently in
      const targetFolderId = folderItemORM?.relations.parent?.model.id

      if (!targetFolderId) {
        console.error('Could not determine the current folder path')
        return;
      }
      analytics?.track('FOLDER_REMOVE_DOCUMENT_VERSION', {
        action: 'REMOVE_DOCUMENT_VERSION',
        category: 'FOLDER',
        folderId: targetFolderId,
        documentId: docVersionORM.model.documentId,
        documentVersionId: docVersionORM.model.id,
      });
      dispatch(folderActions.removeItem(folderItemORM!))
      toast.add(
        <GenericToast
          title="File removed from My Folders."
          status="success"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
  },
  shareEMail: {
    icon: 'email-send-outline',
    title: DocumentVersionContextActions.shareEMail,
    onPress: (docVersionORM, dispatch, user, toast, _, emailTemplates) => () => {
      const hasAssociatedFiles = docVersionORM.relations.associatedFiles.length > 0;
      // NOTE: we have a future story coming up planning to display the modal regardless of associatedFile status
      // in that story we can remove the fileToShare const and the else statement below
      const fileToShare: ShareFileOption = {
        isMainDoc: true,
        isDefault: true,
        beingShared: true,
        isDistributable: true,
        contentProps: {
          title: docVersionORM.model.title!,
          contentId: docVersionORM.model.id!,
          type: ShareType.DOCUMENT_VERSION,
        },
      }
      if (hasAssociatedFiles || emailTemplates!.length > 0) {
        dispatch(DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: (modalProps) => (
            <DNAFileShareModal
              toast={toast}
              documentVersionORM={docVersionORM}
              currentUser={user}
              emailTemplates={emailTemplates!}
              {...modalProps}
            />
          ),
        }))
      } else {
        shareEmail(
          [fileToShare],
          user.userProfile!,
        )
      }
    },
  },
  copyShareLink: {
    icon: 'link-variant',
    title: DocumentVersionContextActions.copyShareLink,
    onPress: (docVersionORM, dispatch, user, toast, _, emailTemplates) => () => {
      // NOTE: we have a future story coming up planning to display the modal regardless of associatedFile status
      // in that story we can remove the fileToShare const and the else statement below
      const fileToShare: ShareFileOption = {
        isMainDoc: true,
        isDefault: true,
        beingShared: true,
        isDistributable: true,
        contentProps: {
          title: docVersionORM.model.title,
          contentId: docVersionORM.model.id!,
          type: ShareType.DOCUMENT_VERSION,
        },
      }
      if (docVersionORM.relations.associatedFiles.length > 0) {
        dispatch(DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: (modalProps) => (
            <DNAFileShareModal
              toast={toast}
              documentVersionORM={docVersionORM}
              currentUser={user}
              emailTemplates={emailTemplates!}
              {...modalProps}
            />
          ),
        }))
      } else {
        copyShareableLinkToClipboard([fileToShare], toast);
      }
    },
  },
  // Bookmark the parent document
  bookmark: {
    icon: 'bookmark',
    title: DocumentVersionContextActions.bookmark,
    onPress: (docVersionORM, dispatch, user) => () => {
      if (!user) throw new Error('Could not bookmark')

      handleBookmark(
        docVersionORM.relations.document.model,
        user,
        dispatch,
      )
    },
  },
  /**
   * This property adds the 'Select slides' option to a document context menu. A
   * redux dispatch is called and the folderitemORM is passed as the payload
   */
  selectSlides: {
    icon: 'pencil',
    title: DocumentVersionContextActions.selectSlides,
    onPress: (d, dispatch, user, toast, folderItemORM) => () => {
      dispatch(
        slideSelectorModalActions.setActiveFolderItem({
          activeFolderItemId: folderItemORM?.model.id,
          folderId: folderItemORM?.relations.parent?.model.id,
          isOpen: true,
        },
        ),
      )
    },
  },
  addToFolder: {
    icon: 'folder-plus',
    title: DocumentVersionContextActions.addToFolder,
    onPress: (_, dispatch, __, toast, folderItemORM) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (props) => (
          <DNADocumentAddToFolder
            {...props}
            itemORM={folderItemORM!}
            toast={toast}
          />
        ),
      }),
      )
    },
  },
  downloadAsPDF: {
    icon: 'file-pdf-box',
    title: DocumentVersionContextActions.downloadAsPDF,
    onPress: (currentDocVersionORM, _, __, toast) => async () => {
      toast.add(
        <GenericToast
          title="Generating PDF download..."
          status="information"
        />,
        ToastOrientations.TOP_RIGHT,
      )
      try {
        const { data } = await API.graphql(
          graphqlOperation(downloadDocumentVersionAsPdfFile, {
            documentVersionId: currentDocVersionORM.model.id,
          }),
        ) as { data: { downloadDocumentVersionAsPdfFile: string } };

        download(data.downloadDocumentVersionAsPdfFile, `${currentDocVersionORM.model.title?.replace('.pptx', '')}.pdf`)
      } catch (e) {
        console.warn(`Error downloading the pdf file for this document id ${currentDocVersionORM.model.id}`, e)
      }
    },
  },
  rename: {
    icon: 'pencil',
    title: DocumentVersionContextActions.rename,
    onPress: () => () => {
      console.warn('not yet implemented')
    },
  },
}
