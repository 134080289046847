import React from 'react';
import { DNABox, DNADivider, DNAText, DNAButton, Popover } from '@alucio/lux-ui'
import DNAGrid from 'src/components/DNA/Grid/DNAGrid'
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import { FolderORM, DocumentORM } from 'src/types/types'

import { useHistory } from 'src/router';

import { useAllFolders } from 'src/state/redux/selector/folder'
import { useAllDocuments, useAllDocumentsInstance } from 'src/state/redux/selector/document'
import folderFilters from 'src/state/redux/folder/query'
import docFilters from 'src/state/redux/document/query'

import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList'

import DNAFolderPreviewHeader from 'src/components/DNA/FlatList/Headers/DNAFolderPreviewHeader'
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions'
import { useCurrentUser } from 'src/state/redux/selector/user';
import { useAppSettings } from 'src/state/context/AppSettings';
import useScreenNav from 'src/components/DNA/hooks/useScreenNav';

const gridWidth = {
  // (Card * No. Cols) + Space Between
  width: (272 * 2) + 16,
}

const bookmarksFilter = docFilters.merge(
  docFilters.filters.published,
  docFilters.filters.nonInternal,
  docFilters.filters.bookmarked,
  docFilters.sorts.bookmarkedDesc,
)

const folderFilter = folderFilters.merge(
  folderFilters.filters.rootFolder,
  folderFilters.filters.active,
  folderFilters.sorts.pinnedAsc,
)

const MSLHome: React.FC = () => {
  const history = useHistory();
  const documentActions = useDNADocumentActions()
  const currentUser = useCurrentUser()
  const { deviceMode } = useAppSettings()
  const { goTo } = useScreenNav()

  if (deviceMode === 'tablet') {
    goTo.FOLDERS();
  }

  const requiredFilterFieldNames = Object.keys(
    // @ts-ignore
    currentUser.meta.formattedFilters?.reduce(
      (acc, activeFilter) => ({
        ...acc,
        [activeFilter.fieldName]: true,
      }),
      {},
    ))

  const recentlyUpdatedFilter = docFilters.merge(
    docFilters.filters.published,
    docFilters.filters.nonInternal,
    docFilters.sorts.updatedAtDesc,
    docFilters.filters.defaultFilters(requiredFilterFieldNames, currentUser.meta.formattedFilters),
  )

  const folders = useAllFolders(folderFilter);
  const recentlyUpdatedDocuments = useAllDocuments(recentlyUpdatedFilter)
  const bookmarkedDocuments = useAllDocumentsInstance(bookmarksFilter)

  const onDocPress = (documentORM: DocumentORM) => {
    documentActions.preview(documentORM)()
  }

  return (
    <DNABox alignX="center">
      <DNABox
        fill
        style={{
          maxWidth: 1280,
          paddingHorizontal: 48,
          paddingVertical: 20,
        }}
      >
        <DNABox
          fill
          spacing="medium"
          appearance="col"
          testID="page-container"
        >
          <DNABox appearance="row" spacing="small" alignY="center">
            <DNAText
              testID="page-title"
              status="secondary"
              h5
            >
              Dashboard
            </DNAText>
            <Popover placement="top">
              <Popover.Anchor>
                <DNAButton
                  context="minimum"
                  appearance="ghost"
                  status="subtle"
                  size="medium"
                  iconLeft="help-circle-outline"
                />
              </Popover.Anchor>
              <Popover.Content offset={2}>
                <DNAText status="basic" style={{ width: 400 }}>
                  Internal files are hidden from this dashboard view to prevent visibility during external engagements.
                </DNAText>
              </Popover.Content>
            </Popover>
          </DNABox>
          <DNADivider />
          {/* CONTENT */}
          {/* At a certain breakpoint, this should render into a column */}
          <DNABox
            fill
            childStyle={gridWidth}
            wrap="start"
            spacing="between"
          >
            {/* RECENTLY UPDATED */}
            <DNABox
              childFill={1}
              fill
              spacing="medium"
              appearance="col"
              testID="dashboard-section"
            >
              <DNABox spacing="between" alignY="center">
                <DNAText h3>Recently Updated</DNAText>
                <DNAButton
                  testID="recently-updated-section-view-all"
                  appearance="ghost"
                  context="minimum"
                  onPress={() => history.push('library')}
                >
                  View All
                </DNAButton>
              </DNABox>
              <DNAGrid
                items={recentlyUpdatedDocuments}
                onPress={onDocPress}
                limit={4}
                emptyVariant={EmptyVariant.DocumentGridRecentlyUpdated}
                spacing="between"
              />
            </DNABox>

            {/* BOOKMARKS UPDATED */}
            <DNABox
              childFill={1}
              fill
              spacing="medium"
              appearance="col"
              testID="dashboard-section"
            >
              <DNABox spacing="between" alignY="center">
                <DNAText h3>Bookmarks</DNAText>
                <DNAButton
                  testID="bookmarks-section-view-all"
                  appearance="ghost"
                  context="minimum"
                  onPress={() => history.push('bookmarks')}
                >
                  View All
                </DNAButton>
              </DNABox>
              <DNAGrid
                items={bookmarkedDocuments}
                onPress={onDocPress}
                limit={4}
                emptyVariant={EmptyVariant.DocumentGridBookmark}
                spacing="between"
              />
            </DNABox>
          </DNABox>

          {/* FOLDERS LIST */}
          <DNABox testID="dashboard-section" style={{ borderRadius: 8, overflow: 'hidden' }}>
            <DNAFlatList<FolderORM>
              items={folders}
              limit={5}
              onPress={(folderORM) => history.push(`folders/${folderORM.model.id}`)}
              emptyVariant={EmptyVariant.FolderListCreate}
              emptyWithHeader
            >
              <DNAFolderPreviewHeader />
            </DNAFlatList>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export default MSLHome
