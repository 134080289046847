import {
  configureStore,
  getDefaultMiddleware,
  AnyAction,
  Reducer,
  OutputParametricSelector,
} from '@reduxjs/toolkit'
import Observable from 'zen-observable'

import { multiSliceReducer } from 'src/state/redux/slice/multiSlice'
import { rootReducer, RootState } from 'src/state/redux/rootReducer'
import cacheSyncMiddleware from './middleware/cacheSync'

export type { RootState } from 'src/state/redux/rootReducer'

/**
 * Root level reducer/action to be able to reset the store back to its initial state
 *  - i.e. To use during logout
 */
const RESET_TYPE = 'store/reset'
export const storeReset = () => ({ type: RESET_TYPE });

const customRootReducer: Reducer<RootState, AnyAction> = (state, action) => {
  const isMultiSliceAction = action.type.includes('multiSlice/')

  if (isMultiSliceAction) {
    return multiSliceReducer(state, action)
  }

  return action.type === RESET_TYPE
    ? rootReducer(undefined, action)
    : rootReducer(state, action)
}

const store = configureStore({
  reducer: customRootReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
    cacheSyncMiddleware,
  ],
  devTools: true,
})

export function observeSlice(
  selector: OutputParametricSelector<RootState, any, any, any>,
  ...args
) {
  return new Observable(
    observer => {
      let previousVal

      const unsubscribeRedux = store.subscribe(() => {
        // @ts-ignore - [TODO]: Fix these types based on the selector passed in
        const currentVal = selector(store.getState(), ...args)

        if (previousVal !== currentVal) {
          observer.next(currentVal)
          previousVal = currentVal
        }
      })

      return () => {
        previousVal = undefined
        unsubscribeRedux()
      }
    },
  )
}

export default store
