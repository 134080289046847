// Many parts copied from @dnd-kit/core/src/utilities/algorithms/rectIntersection.ts
import { useCallback } from 'react'
import type { UniqueIdentifier, LayoutRect, ViewRect } from '@dnd-kit/core'
import type { CollisionDetection } from '@dnd-kit/core/dist/utilities/algorithms/types'
import type { Coordinates } from '@dnd-kit/core/dist/types'

export function distanceBetween(p1: Coordinates, p2: Coordinates) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

/**
 * Returns the intersecting rectangle of a customized area between two rectangles
 */
const getIntersectionRatioCustomOffset = (
  entry: LayoutRect,
  target: ViewRect,
  intersectionWidth: number,
  intersectionHeight: number,
): number => {
  const cTargetTop = target.top + (target.height * 0.5) - (intersectionHeight * 0.5)
  const cEntryTop = entry.offsetTop + (entry.height * 0.5) - (intersectionHeight * 0.5)
  const top = Math.max(cTargetTop, cEntryTop);

  const cTargetLeft = target.left + (target.width * 0.5) - (intersectionWidth * 0.5)
  const cEntryLeft = entry.offsetLeft + (entry.width * 0.5) - (intersectionWidth * 0.5)
  const left = Math.max(cTargetLeft, cEntryLeft);

  const cTargetRight = target.left + (target.width * 0.5) + (intersectionWidth * 0.5)
  const cEntryRight = entry.offsetLeft + (entry.width * 0.5) + (intersectionWidth * 0.5)
  const right = Math.min(cTargetRight, cEntryRight);

  const cTargetBottom = target.top + (target.height * 0.5) + (intersectionHeight * 0.5)
  const cEntryBottom = entry.offsetTop + (entry.height * 0.5) + (intersectionHeight * 0.5)
  const bottom = Math.min(cTargetBottom, cEntryBottom);

  const width = right - left;
  const height = bottom - top;

  if (left < right && top < bottom) {
    const targetArea = intersectionHeight * intersectionWidth
    const entryArea = intersectionHeight * intersectionWidth

    const intersectionArea = width * height;
    const intersectionRatio =
        intersectionArea / (targetArea + entryArea - intersectionArea);

    const rv = Number(intersectionRatio.toFixed(4));
    return rv
  }

  // Rectangles do not overlap, or overlap has an area of zero (edge/corner overlap)
  return 0;
}

/**
 * Returns the rectangle that has the greatest intersection area with a given
 * rectangle in an array of rectangles.
 */
export const customCenteredRectIntersection: (width: number, height: number) => CollisionDetection =
  (intersectionWidth: number, intersectionHeight: number) => ({
    collisionRect,
    droppableContainers,
  }) => {
    let maxIntersectionRatio = 0;
    let maxIntersectingDroppableContainer: UniqueIdentifier | null = null;

    for (const droppableContainer of droppableContainers) {
      const { rect: { current: rect } } = droppableContainer;

      if (rect) {
        const intersectionRatio = getIntersectionRatioCustomOffset(
          rect,
          collisionRect,
          intersectionWidth,
          intersectionHeight,
        )

        if (intersectionRatio > maxIntersectionRatio) {
          maxIntersectionRatio = intersectionRatio;
          maxIntersectingDroppableContainer = droppableContainer.id;
        }
      }
    }

    return maxIntersectingDroppableContainer;
  };

// Simple callback hook to memoize the collision function (which may require regeneration due to responsive design/dimensions)
export const useCustomCenteredRectIntersection = (intersectionWidth: number, intersectionHeight: number) => {
  const collisionFn = useCallback(
    customCenteredRectIntersection(intersectionWidth, intersectionHeight),
    [intersectionWidth, intersectionHeight],
  )

  return collisionFn
}
