import React from 'react'
import { StyleSheet } from 'react-native'
import { DNABox } from '@alucio/lux-ui'

import { Action, usePresentationBuilderSharedResources } from '../PresentationEditor'
import { usePresentationBuilderState } from '../../state/PresentationBuilderStateProvider'
import { useAppSettings } from 'src/state/context/AppSettings'

const S = StyleSheet.create({
  buttonStyle: {
    paddingHorizontal:12,
    paddingVertical:4,
  },
  containerStyle: {
    borderBottomColor: '#F1F1F1',
    borderBottomWidth: 1,
    height: 56,
  },
})

export const PrimaryActionButtons:React.FC = () => {
  const { getActionEls } = usePresentationBuilderSharedResources()
  const {
    selectedGroups,
    savePresentation,
    cancelPresentation,
    handleModeChange,
    builderMode,
  } = usePresentationBuilderState()

  const { isOnline } = useAppSettings()
  const allContentCached = selectedGroups.every(group => group.documentVersion?.meta.assets.isContentCached)

  const actions:Action[] = [
    {
      iconLeft: builderMode === 'customization' ? 'arrow-collapse' : 'arrow-expand',
      fill:true,
      onPress: () => handleModeChange(),
    },
    {
      label:'Cancel',
      appearance:'filled',
      status:'flat',
      style: [S.buttonStyle, { marginRight: 8 }],
      onPress: () => cancelPresentation(),
    },
    {
      label:'Save',
      appearance:'filled',
      status: 'primary',
      disabled: (
        !selectedGroups.length ||
        !selectedGroups.some(({ visible }) => visible) ||
        (!isOnline && !allContentCached) // or there is at least one visible group
      ),
      style:[S.buttonStyle, { marginRight: 12 }],
      onPress: () => savePresentation(),
    },
  ]
  const actionEls = getActionEls(actions)

  return (
    <DNABox
      style={S.containerStyle}
      alignY="center"
    >
      {actionEls}
    </DNABox>
  )
}
