/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const virtualNotifyUpdateVirtualAttendeeLimited = /* GraphQL */ `
  mutation VirtualNotifyUpdateVirtualAttendeeLimited(
    $attendee: VirtualNotifyUpdateVirtualAttendeeLimitedInput!
  ) {
    virtualNotifyUpdateVirtualAttendeeLimited(attendee: $attendee) {
      id
      attendeeKey
      tenantId
      meetingId
      status
      statusDetail
      name
      phonePin
      joinToken
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
      }
      folderUpdateGracePeriodDays
      medInfoURL
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
      }
      folderUpdateGracePeriodDays
      medInfoURL
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant(
    $input: DeleteTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    deleteTenant(input: $input, condition: $condition) {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
      }
      folderUpdateGracePeriodDays
      medInfoURL
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentVersion = /* GraphQL */ `
  mutation CreateDocumentVersion(
    $input: CreateDocumentVersionInput!
    $condition: ModelDocumentVersionConditionInput
  ) {
    createDocumentVersion(input: $input, condition: $condition) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      pages {
        pageId
        number
        srcId
        srcHash
        isRequired
        speakerNotes
      }
      pageGroups {
        id
        pageIds
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDocumentVersion = /* GraphQL */ `
  mutation UpdateDocumentVersion(
    $input: UpdateDocumentVersionInput!
    $condition: ModelDocumentVersionConditionInput
  ) {
    updateDocumentVersion(input: $input, condition: $condition) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      pages {
        pageId
        number
        srcId
        srcHash
        isRequired
        speakerNotes
      }
      pageGroups {
        id
        pageIds
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDocumentVersion = /* GraphQL */ `
  mutation DeleteDocumentVersion(
    $input: DeleteDocumentVersionInput!
    $condition: ModelDocumentVersionConditionInput
  ) {
    deleteDocumentVersion(input: $input, condition: $condition) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      pages {
        pageId
        number
        srcId
        srcHash
        isRequired
        speakerNotes
      }
      pageGroups {
        id
        pageIds
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAttachedFile = /* GraphQL */ `
  mutation CreateAttachedFile(
    $input: CreateAttachedFileInput!
    $condition: ModelAttachedFileConditionInput
  ) {
    createAttachedFile(input: $input, condition: $condition) {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAttachedFile = /* GraphQL */ `
  mutation UpdateAttachedFile(
    $input: UpdateAttachedFileInput!
    $condition: ModelAttachedFileConditionInput
  ) {
    updateAttachedFile(input: $input, condition: $condition) {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAttachedFile = /* GraphQL */ `
  mutation DeleteAttachedFile(
    $input: DeleteAttachedFileInput!
    $condition: ModelAttachedFileConditionInput
  ) {
    deleteAttachedFile(input: $input, condition: $condition) {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      tenantId
      accessLevel
      status
      owner
      expiresAt
      integration {
        integrationId
        externalId
        firstSync
        lastSync
      }
      integrationType
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      tenantId
      accessLevel
      status
      owner
      expiresAt
      integration {
        integrationId
        externalId
        firstSync
        lastSync
      }
      integrationType
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      tenantId
      accessLevel
      status
      owner
      expiresAt
      integration {
        integrationId
        externalId
        firstSync
        lastSync
      }
      integrationType
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEmailTemplate = /* GraphQL */ `
  mutation CreateEmailTemplate(
    $input: CreateEmailTemplateInput!
    $condition: ModelEmailTemplateConditionInput
  ) {
    createEmailTemplate(input: $input, condition: $condition) {
      id
      tenantId
      labelValues {
        key
        value
      }
      subject
      title
      cc
      bcc
      body
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      status
      editPermissions
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEmailTemplate = /* GraphQL */ `
  mutation UpdateEmailTemplate(
    $input: UpdateEmailTemplateInput!
    $condition: ModelEmailTemplateConditionInput
  ) {
    updateEmailTemplate(input: $input, condition: $condition) {
      id
      tenantId
      labelValues {
        key
        value
      }
      subject
      title
      cc
      bcc
      body
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      status
      editPermissions
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEmailTemplate = /* GraphQL */ `
  mutation DeleteEmailTemplate(
    $input: DeleteEmailTemplateInput!
    $condition: ModelEmailTemplateConditionInput
  ) {
    deleteEmailTemplate(input: $input, condition: $condition) {
      id
      tenantId
      labelValues {
        key
        value
      }
      subject
      title
      cc
      bcc
      body
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      status
      editPermissions
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCustomDeck = /* GraphQL */ `
  mutation CreateCustomDeck(
    $input: CreateCustomDeckInput!
    $condition: ModelCustomDeckConditionInput
  ) {
    createCustomDeck(input: $input, condition: $condition) {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        visible
        srcId
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCustomDeck = /* GraphQL */ `
  mutation UpdateCustomDeck(
    $input: UpdateCustomDeckInput!
    $condition: ModelCustomDeckConditionInput
  ) {
    updateCustomDeck(input: $input, condition: $condition) {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        visible
        srcId
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCustomDeck = /* GraphQL */ `
  mutation DeleteCustomDeck(
    $input: DeleteCustomDeckInput!
    $condition: ModelCustomDeckConditionInput
  ) {
    deleteCustomDeck(input: $input, condition: $condition) {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        visible
        srcId
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFolder = /* GraphQL */ `
  mutation CreateFolder(
    $input: CreateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    createFolder(input: $input, condition: $condition) {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFolder = /* GraphQL */ `
  mutation UpdateFolder(
    $input: UpdateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    updateFolder(input: $input, condition: $condition) {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFolder = /* GraphQL */ `
  mutation DeleteFolder(
    $input: DeleteFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    deleteFolder(input: $input, condition: $condition) {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createContentShare = /* GraphQL */ `
  mutation CreateContentShare(
    $input: CreateContentShareInput!
    $condition: ModelContentShareConditionInput
  ) {
    createContentShare(input: $input, condition: $condition) {
      id
      token
      tenantId
      type
      contentId
      expiresAt
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateContentShare = /* GraphQL */ `
  mutation UpdateContentShare(
    $input: UpdateContentShareInput!
    $condition: ModelContentShareConditionInput
  ) {
    updateContentShare(input: $input, condition: $condition) {
      id
      token
      tenantId
      type
      contentId
      expiresAt
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteContentShare = /* GraphQL */ `
  mutation DeleteContentShare(
    $input: DeleteContentShareInput!
    $condition: ModelContentShareConditionInput
  ) {
    deleteContentShare(input: $input, condition: $condition) {
      id
      token
      tenantId
      type
      contentId
      expiresAt
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createVirtualSession = /* GraphQL */ `
  mutation CreateVirtualSession(
    $input: CreateVirtualSessionInput!
    $condition: ModelVirtualSessionConditionInput
  ) {
    createVirtualSession(input: $input, condition: $condition) {
      id
      tenantId
      meetingId
      roomName
      status
      recordingStatus
      recordingTaskId
      hostName
      hostPhonePin
      createdAt
      createdBy
      terminatedAt
      meetingLog {
        type
        msg
        timestamp
        action
        param
        user
        isHost
      }
      documentHistory {
        contentId
        contentType
        totalDurationSec
      }
      attendees {
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateVirtualSession = /* GraphQL */ `
  mutation UpdateVirtualSession(
    $input: UpdateVirtualSessionInput!
    $condition: ModelVirtualSessionConditionInput
  ) {
    updateVirtualSession(input: $input, condition: $condition) {
      id
      tenantId
      meetingId
      roomName
      status
      recordingStatus
      recordingTaskId
      hostName
      hostPhonePin
      createdAt
      createdBy
      terminatedAt
      meetingLog {
        type
        msg
        timestamp
        action
        param
        user
        isHost
      }
      documentHistory {
        contentId
        contentType
        totalDurationSec
      }
      attendees {
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteVirtualSession = /* GraphQL */ `
  mutation DeleteVirtualSession(
    $input: DeleteVirtualSessionInput!
    $condition: ModelVirtualSessionConditionInput
  ) {
    deleteVirtualSession(input: $input, condition: $condition) {
      id
      tenantId
      meetingId
      roomName
      status
      recordingStatus
      recordingTaskId
      hostName
      hostPhonePin
      createdAt
      createdBy
      terminatedAt
      meetingLog {
        type
        msg
        timestamp
        action
        param
        user
        isHost
      }
      documentHistory {
        contentId
        contentType
        totalDurationSec
      }
      attendees {
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMeeting = /* GraphQL */ `
  mutation CreateMeeting(
    $input: CreateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    createMeeting(input: $input, condition: $condition) {
      id
      tenantId
      title
      startTime
      endTime
      contentPresented {
        contentId
        folderItemId
        status
        contentType
        openedAt
        closedAt
      }
      type
      notes
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMeeting = /* GraphQL */ `
  mutation UpdateMeeting(
    $input: UpdateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    updateMeeting(input: $input, condition: $condition) {
      id
      tenantId
      title
      startTime
      endTime
      contentPresented {
        contentId
        folderItemId
        status
        contentType
        openedAt
        closedAt
      }
      type
      notes
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMeeting = /* GraphQL */ `
  mutation DeleteMeeting(
    $input: DeleteMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    deleteMeeting(input: $input, condition: $condition) {
      id
      tenantId
      title
      startTime
      endTime
      contentPresented {
        contentId
        folderItemId
        status
        contentType
        openedAt
        closedAt
      }
      type
      notes
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createVirtualAttendee = /* GraphQL */ `
  mutation CreateVirtualAttendee(
    $input: CreateVirtualAttendeeInput!
    $condition: ModelVirtualAttendeeConditionInput
  ) {
    createVirtualAttendee(input: $input, condition: $condition) {
      id
      attendeeKey
      tenantId
      hostId
      meetingId
      sessionId
      status
      statusDetail
      name
      phonePin
      clientIP
      joinToken
      createdAt
      terminatedAt
      acceptedAt
      lastSeenAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateVirtualAttendee = /* GraphQL */ `
  mutation UpdateVirtualAttendee(
    $input: UpdateVirtualAttendeeInput!
    $condition: ModelVirtualAttendeeConditionInput
  ) {
    updateVirtualAttendee(input: $input, condition: $condition) {
      id
      attendeeKey
      tenantId
      hostId
      meetingId
      sessionId
      status
      statusDetail
      name
      phonePin
      clientIP
      joinToken
      createdAt
      terminatedAt
      acceptedAt
      lastSeenAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteVirtualAttendee = /* GraphQL */ `
  mutation DeleteVirtualAttendee(
    $input: DeleteVirtualAttendeeInput!
    $condition: ModelVirtualAttendeeConditionInput
  ) {
    deleteVirtualAttendee(input: $input, condition: $condition) {
      id
      attendeeKey
      tenantId
      hostId
      meetingId
      sessionId
      status
      statusDetail
      name
      phonePin
      clientIP
      joinToken
      createdAt
      terminatedAt
      acceptedAt
      lastSeenAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createIntegrationLog = /* GraphQL */ `
  mutation CreateIntegrationLog(
    $input: CreateIntegrationLogInput!
    $condition: ModelIntegrationLogConditionInput
  ) {
    createIntegrationLog(input: $input, condition: $condition) {
      integrationId
      id
      log {
        level
        timestamp
        message
        srcDocId
        srcDocVersion
      }
      status
      tenantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateIntegrationLog = /* GraphQL */ `
  mutation UpdateIntegrationLog(
    $input: UpdateIntegrationLogInput!
    $condition: ModelIntegrationLogConditionInput
  ) {
    updateIntegrationLog(input: $input, condition: $condition) {
      integrationId
      id
      log {
        level
        timestamp
        message
        srcDocId
        srcDocVersion
      }
      status
      tenantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteIntegrationLog = /* GraphQL */ `
  mutation DeleteIntegrationLog(
    $input: DeleteIntegrationLogInput!
    $condition: ModelIntegrationLogConditionInput
  ) {
    deleteIntegrationLog(input: $input, condition: $condition) {
      integrationId
      id
      log {
        level
        timestamp
        message
        srcDocId
        srcDocVersion
      }
      status
      tenantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createIntegrationSettings = /* GraphQL */ `
  mutation CreateIntegrationSettings(
    $input: CreateIntegrationSettingsInput!
    $condition: ModelIntegrationSettingsConditionInput
  ) {
    createIntegrationSettings(input: $input, condition: $condition) {
      id
      tenantId
      syncContentEvery
      name
      mapping {
        key
        fieldType
        dataType
        targetFieldName
        srcFieldname
      }
      clientConfigurationFields {
        key
        value
      }
      integrationType
      enabled
      notificationEmail
      errorSyncEmail
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateIntegrationSettings = /* GraphQL */ `
  mutation UpdateIntegrationSettings(
    $input: UpdateIntegrationSettingsInput!
    $condition: ModelIntegrationSettingsConditionInput
  ) {
    updateIntegrationSettings(input: $input, condition: $condition) {
      id
      tenantId
      syncContentEvery
      name
      mapping {
        key
        fieldType
        dataType
        targetFieldName
        srcFieldname
      }
      clientConfigurationFields {
        key
        value
      }
      integrationType
      enabled
      notificationEmail
      errorSyncEmail
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteIntegrationSettings = /* GraphQL */ `
  mutation DeleteIntegrationSettings(
    $input: DeleteIntegrationSettingsInput!
    $condition: ModelIntegrationSettingsConditionInput
  ) {
    deleteIntegrationSettings(input: $input, condition: $condition) {
      id
      tenantId
      syncContentEvery
      name
      mapping {
        key
        fieldType
        dataType
        targetFieldName
        srcFieldname
      }
      clientConfigurationFields {
        key
        value
      }
      integrationType
      enabled
      notificationEmail
      errorSyncEmail
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTenantLambda = /* GraphQL */ `
  mutation CreateTenantLambda($tenant: CreateTenantInputLambda) {
    createTenantLambda(tenant: $tenant) {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
      }
      folderUpdateGracePeriodDays
      medInfoURL
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentFromS3Upload = /* GraphQL */ `
  mutation CreateDocumentFromS3Upload(
    $inputDoc: CreateDocumentFromS3UploadInput!
  ) {
    createDocumentFromS3Upload(inputDoc: $inputDoc) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      pages {
        pageId
        number
        srcId
        srcHash
        isRequired
        speakerNotes
      }
      pageGroups {
        id
        pageIds
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserDocumentFromS3Upload = /* GraphQL */ `
  mutation CreateUserDocumentFromS3Upload(
    $inputDoc: CreateDocumentFromS3UploadInput!
  ) {
    createUserDocumentFromS3Upload(inputDoc: $inputDoc) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      pages {
        pageId
        number
        srcId
        srcHash
        isRequired
        speakerNotes
      }
      pageGroups {
        id
        pageIds
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentFromS3UploadIntegrationEngine = /* GraphQL */ `
  mutation CreateDocumentFromS3UploadIntegrationEngine(
    $inputDoc: CreateDocumentFromS3UploadEngineInput!
  ) {
    createDocumentFromS3UploadIntegrationEngine(inputDoc: $inputDoc) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      pages {
        pageId
        number
        srcId
        srcHash
        isRequired
        speakerNotes
      }
      pageGroups {
        id
        pageIds
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentVersionFromS3Upload = /* GraphQL */ `
  mutation CreateDocumentVersionFromS3Upload(
    $inputVersion: CreateDocumentVersionFromS3UploadInput!
  ) {
    createDocumentVersionFromS3Upload(inputVersion: $inputVersion) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      pages {
        pageId
        number
        srcId
        srcHash
        isRequired
        speakerNotes
      }
      pageGroups {
        id
        pageIds
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentVersionFromS3UploadIntegrationEngine = /* GraphQL */ `
  mutation CreateDocumentVersionFromS3UploadIntegrationEngine(
    $inputVersion: CreateDocumentVersionFromS3UploadEngineInput!
  ) {
    createDocumentVersionFromS3UploadIntegrationEngine(
      inputVersion: $inputVersion
    ) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      pages {
        pageId
        number
        srcId
        srcHash
        isRequired
        speakerNotes
      }
      pageGroups {
        id
        pageIds
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentVersionFromExisting = /* GraphQL */ `
  mutation CreateDocumentVersionFromExisting(
    $documentId: ID!
    $newVersionNumber: Int!
    $existingVersionId: ID!
  ) {
    createDocumentVersionFromExisting(
      documentId: $documentId
      newVersionNumber: $newVersionNumber
      existingVersionId: $existingVersionId
    ) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      pages {
        pageId
        number
        srcId
        srcHash
        isRequired
        speakerNotes
      }
      pageGroups {
        id
        pageIds
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentVersionFromExistingIntegrationEngine = /* GraphQL */ `
  mutation CreateDocumentVersionFromExistingIntegrationEngine(
    $documentId: ID!
    $newVersionNumber: Int!
    $existingVersionId: ID!
    $tenantId: String!
    $email: String!
  ) {
    createDocumentVersionFromExistingIntegrationEngine(
      documentId: $documentId
      newVersionNumber: $newVersionNumber
      existingVersionId: $existingVersionId
      tenantId: $tenantId
      email: $email
    ) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      pages {
        pageId
        number
        srcId
        srcHash
        isRequired
        speakerNotes
      }
      pageGroups {
        id
        pageIds
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAttachedFileFromS3Upload = /* GraphQL */ `
  mutation CreateAttachedFileFromS3Upload(
    $inputAttached: CreateAttachedFileFromS3UploadInput!
  ) {
    createAttachedFileFromS3Upload(inputAttached: $inputAttached) {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const downloadDocumentVersionAsPdfFile = /* GraphQL */ `
  mutation DownloadDocumentVersionAsPdfFile($documentVersionId: ID!) {
    downloadDocumentVersionAsPdfFile(documentVersionId: $documentVersionId)
  }
`;
export const downloadCustomDeckAsPdfFile = /* GraphQL */ `
  mutation DownloadCustomDeckAsPdfFile($customDeckId: ID!) {
    downloadCustomDeckAsPdfFile(customDeckId: $customDeckId)
  }
`;
export const virtualAttendeeJoin = /* GraphQL */ `
  mutation VirtualAttendeeJoin($params: VirtualAttendeeJoinInput) {
    virtualAttendeeJoin(params: $params) {
      id
      attendeeKey
      tenantId
      meetingId
      status
      statusDetail
      name
      phonePin
      joinToken
    }
  }
`;
export const virtualCreateSession = /* GraphQL */ `
  mutation VirtualCreateSession {
    virtualCreateSession {
      joinToken
      session {
        id
        tenantId
        meetingId
        roomName
        status
        recordingStatus
        recordingTaskId
        hostName
        hostPhonePin
        createdAt
        createdBy
        terminatedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      existingAttendees {
        id
        attendeeKey
        tenantId
        hostId
        meetingId
        sessionId
        status
        statusDetail
        name
        phonePin
        clientIP
        joinToken
        createdAt
        terminatedAt
        acceptedAt
        lastSeenAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const virtualTerminateSession = /* GraphQL */ `
  mutation VirtualTerminateSession($sessionId: ID!) {
    virtualTerminateSession(sessionId: $sessionId) {
      id
      tenantId
      meetingId
      roomName
      status
      recordingStatus
      recordingTaskId
      hostName
      hostPhonePin
      createdAt
      createdBy
      terminatedAt
      meetingLog {
        type
        msg
        timestamp
        action
        param
        user
        isHost
      }
      documentHistory {
        contentId
        contentType
        totalDurationSec
      }
      attendees {
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const virtualUpdateAttendeeStatus = /* GraphQL */ `
  mutation VirtualUpdateAttendeeStatus(
    $sessionId: ID!
    $attendeeId: ID!
    $status: ATTENDEE_STATUS!
  ) {
    virtualUpdateAttendeeStatus(
      sessionId: $sessionId
      attendeeId: $attendeeId
      status: $status
    ) {
      id
      attendeeKey
      tenantId
      hostId
      meetingId
      sessionId
      status
      statusDetail
      name
      phonePin
      clientIP
      joinToken
      createdAt
      terminatedAt
      acceptedAt
      lastSeenAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const virtualStartRecording = /* GraphQL */ `
  mutation VirtualStartRecording($sessionId: ID!, $meetingHostname: String!) {
    virtualStartRecording(
      sessionId: $sessionId
      meetingHostname: $meetingHostname
    ) {
      connectURL
      session {
        id
        tenantId
        meetingId
        roomName
        status
        recordingStatus
        recordingTaskId
        hostName
        hostPhonePin
        createdAt
        createdBy
        terminatedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const virtualTerminateSessionInternal = /* GraphQL */ `
  mutation VirtualTerminateSessionInternal(
    $sessionId: ID!
    $terminationReason: ATTENDEE_STATUS_DETAIL!
  ) {
    virtualTerminateSessionInternal(
      sessionId: $sessionId
      terminationReason: $terminationReason
    ) {
      id
      tenantId
      meetingId
      roomName
      status
      recordingStatus
      recordingTaskId
      hostName
      hostPhonePin
      createdAt
      createdBy
      terminatedAt
      meetingLog {
        type
        msg
        timestamp
        action
        param
        user
        isHost
      }
      documentHistory {
        contentId
        contentType
        totalDurationSec
      }
      attendees {
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const virtualAttendeeNotifyPresence = /* GraphQL */ `
  mutation VirtualAttendeeNotifyPresence($attendeeId: ID!, $attendeeKey: ID!) {
    virtualAttendeeNotifyPresence(
      attendeeId: $attendeeId
      attendeeKey: $attendeeKey
    ) {
      id
      attendeeKey
      tenantId
      meetingId
      status
      statusDetail
      name
      phonePin
      joinToken
    }
  }
`;
export const virtualAttendeeLeft = /* GraphQL */ `
  mutation VirtualAttendeeLeft($attendeeId: ID!, $attendeeKey: ID!) {
    virtualAttendeeLeft(attendeeId: $attendeeId, attendeeKey: $attendeeKey) {
      status
    }
  }
`;
export const virtualUpdateVideoSubscriptions = /* GraphQL */ `
  mutation VirtualUpdateVideoSubscriptions(
    $joinToken: String!
    $activeParticipantIds: [String]
  ) {
    virtualUpdateVideoSubscriptions(
      joinToken: $joinToken
      activeParticipantIds: $activeParticipantIds
    )
  }
`;
export const virtualUpdateCallStats = /* GraphQL */ `
  mutation VirtualUpdateCallStats(
    $joinToken: String!
    $stats: VirtualCallStatistics
  ) {
    virtualUpdateCallStats(joinToken: $joinToken, stats: $stats)
  }
`;
export const virtualUpdateSessionMeetingLog = /* GraphQL */ `
  mutation VirtualUpdateSessionMeetingLog(
    $input: VirtualUpdateSessionMeetingLogInput!
  ) {
    virtualUpdateSessionMeetingLog(input: $input) {
      id
      tenantId
      meetingId
      roomName
      status
      recordingStatus
      recordingTaskId
      hostName
      hostPhonePin
      createdAt
      createdBy
      terminatedAt
      meetingLog {
        type
        msg
        timestamp
        action
        param
        user
        isHost
      }
      documentHistory {
        contentId
        contentType
        totalDurationSec
      }
      attendees {
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const generateContentShare = /* GraphQL */ `
  mutation GenerateContentShare(
    $type: SHARE_TYPE!
    $contentId: ID!
    $expiresInMins: Int!
  ) {
    generateContentShare(
      type: $type
      contentId: $contentId
      expiresInMins: $expiresInMins
    ) {
      id
      token
      tenantId
      type
      contentId
      expiresAt
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserLambda = /* GraphQL */ `
  mutation UpdateUserLambda($user: UpdateUserLambdaInput) {
    updateUserLambda(user: $user) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserStatusLambda = /* GraphQL */ `
  mutation UpdateUserStatusLambda($user: DisableUserLambdaInput) {
    updateUserStatusLambda(user: $user) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTenantUser = /* GraphQL */ `
  mutation CreateTenantUser(
    $user: CreateUserLambdaInput
    $sendInvite: Boolean
  ) {
    createTenantUser(user: $user, sendInvite: $sendInvite) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const submitIntegrationRun = /* GraphQL */ `
  mutation SubmitIntegrationRun($input: RunIntegrationEngineInput) {
    submitIntegrationRun(input: $input) {
      success
      message
    }
  }
`;
export const updateTenantLogo = /* GraphQL */ `
  mutation UpdateTenantLogo($input: UpdateTenantLogoInput) {
    updateTenantLogo(input: $input)
  }
`;
