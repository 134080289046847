import React, { useState } from 'react'
import { Pressable, StyleSheet, ScrollView } from 'react-native'
import {
  Stack,
  DNABox,
  DNAIcon,
  Iffy,
  Popover,
  DNAText,
  DNAButton,
  DNADivider,
  LuxStatus,
  DNACard,
  Rotate,
} from '@alucio/lux-ui'
import { WebLinking as Linking } from '@alucio/core'

import useScreenNav from 'src/components/DNA/hooks/useScreenNav'
import { useSyncState, useSyncStatus, SyncStatusORM } from 'src/state/redux/selector/cache'
import { useAllDocumentVersionMap } from 'src/state/redux/selector/document'
import { bytesToSize } from 'src/utils/documentHelpers'
import workerChannel from 'src/worker/channels/workerChannel'

const S = StyleSheet.create({
  centerIcon: {
    // @ts-expect-error - Works fine in web
    transform: [{ translateX: '-50%' }, { translateY: '25%' }],
  },
})

type SyncStatusDefinition = {
  statuses: string[],
  buttonIcon: string,
  labelIcon: string,
  status: LuxStatus,
  label: string,
}

const syncStatusDefinition: SyncStatusDefinition[] = [
  {
    statuses: ['offline'],
    buttonIcon: '',
    labelIcon: 'wifi-off',
    status: 'primary',
    label: "Looks like you're offline.",
  },
  {
    statuses: ['online.idle.init'],
    buttonIcon: 'sync-circle',
    labelIcon: 'sync-circle',
    status: 'primary',
    label: 'Waiting for sync...',
  },
  {
    statuses: ['online.sync.prepare'],
    buttonIcon: 'sync-circle',
    labelIcon: 'sync-circle',
    status: 'primary',
    label: 'Preparing download...',
  },
  {
    statuses: ['online.sync.thumbnails', 'online.sync.content', 'online.sync.determine'],
    buttonIcon: 'sync-circle',
    labelIcon: 'sync-circle',
    status: 'primary',
    label: 'Downloading in progress...',
  },
  {
    statuses: ['online.paused'],
    buttonIcon: 'pause-circle',
    labelIcon: 'pause-circle',
    status: 'primary',
    label: 'Downloading paused...',
  },
  {
    statuses: ['online.idle.pending'],
    buttonIcon: 'check-circle',
    labelIcon: 'check-circle',
    status: 'success',
    label: "You're all synced for offline use.",
  },
  {
    statuses: ['online.idle.error'],
    buttonIcon: 'alert-circle',
    labelIcon: 'alert-circle',
    status: 'warning',
    label: 'Downloading complete',
  },
]

const getSyncStatusDefinition = (syncStatus: SyncStatusORM): SyncStatusDefinition | undefined => (
  syncStatusDefinition.find(definition => (
    definition
      .statuses
      .some(defStatus => syncStatus.matches(defStatus))
  )) ??
  {
    statuses: ['online.idle.error'],
    buttonIcon: 'alert-circle',
    labelIcon: 'alert-circle',
    status: 'danger',
    label: 'Something went wrong',
  }
)

const SyncStatusButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const syncStatus = useSyncStatus()
  const screenNav = useScreenNav()

  const syncStatusDef = getSyncStatusDefinition(syncStatus)
  const handlePress = () => {
    setIsOpen(p => !p)
  }

  return (
    <Popover
      lazyMount
      placement="bottom"
      interactive={true}
      visible={isOpen}
      onBackdropPress={handlePress}
      type="menu"
    >
      <Popover.Anchor>
        <Pressable
          delayLongPress={1000}
          onPress={
            () => {
              handlePress()
            }}
          onLongPress={() => {
            screenNav.goTo.SYNC_STATUS_DEBUG()
          }}
        >
          {/* Cloud Layer */}
          <Stack anchor="bottom">
            <Stack.Layer>
              <DNAIcon.Styled
                name="cloud-outline"
                appearance="ghost"
                status="flat"
                context="minimum"
                size="xlarge"
              />
            </Stack.Layer>

            {/* Solid Fill Layer */}
            <Stack.Layer style={S.centerIcon}>
              <Iffy is={syncStatusDef?.buttonIcon !== ''}>
                <DNAIcon.Styled
                  name={'circle'}
                  status="primary"
                  appearance="filled"
                  context="minimum"
                />
              </Iffy>
            </Stack.Layer>

            {/* Icon Layer */}
            {/* [TODO-PWA]
                  - Needs to show warning icon if there are failed files
                  - This button minimized render by not taking the entire sync object
                  - Maybe need to break out another variable for errorLength
            */}
            <Stack.Layer style={S.centerIcon}>
              <Iffy is={syncStatusDef}>
                <Rotate disabled={syncStatusDef?.buttonIcon !== 'sync-circle'}>
                  <DNAIcon.Styled
                    name={syncStatusDef?.buttonIcon}
                    status={syncStatusDef?.status}
                    appearance="ghost"
                    context="minimum"
                  />
                </Rotate>
              </Iffy>
            </Stack.Layer>
          </Stack>
        </Pressable>
      </Popover.Anchor>

      <Popover.Content>
        <DNACard style={{ width: 400 }}>
          <SyncStatusInfo />
        </DNACard>
      </Popover.Content>
    </Popover>
  )
}

export const SyncStatusInfo: React.FC = () => {
  const syncState = useSyncState()
  const syncStatusDef = getSyncStatusDefinition(syncState)
  const allDocumentVersionMap = useAllDocumentVersionMap()

  const contentSyncManifest = Object.values(syncState.context.syncManifest)

  const contentEntriesRemaining = contentSyncManifest
    ? `${contentSyncManifest.length} file(s) remaining`
    : ''

  const totalSizeLeft = contentSyncManifest.reduce<number>(
    (acc, entry) => acc + entry.fileSize,
    0,
  )
  const contentSizeLeft =
    contentSyncManifest.length
      ? bytesToSize(totalSizeLeft)
      : '0 MB'

  const isPaused = syncState.matches('online.paused')
  const isSync = syncState.matches('online.sync')
  const isOffline = syncState.matches('offline.idle')
  const hasRemainingSync = contentSyncManifest.length
  const failedSyncEntries = syncState.context.error.syncEntries
  const hasFailedSyncEntires = Object.keys(syncState.context.error.syncEntries).length
  const isIdleWithWarning = syncState.matches('online.idle.error')

  const contactSupportMailTo = () => {
    Linking.openURL('mailto:support@alucio.io', '_self')
  }

  const handlePausedSync = async () => {
    if (syncState?.matches('online.paused')) { workerChannel.postMessageExtended({ type: 'RESUME_SYNC' }) }

    if (syncState?.matches('online.sync')) { workerChannel.postMessageExtended({ type: 'PAUSE_SYNC' }) }
  }

  return (
    <DNABox
      fill
      appearance="col"
      style={{ padding: 24 }}
      spacing="large"
      childFill={2}
    >
      {/* 0. Icons and Status Label */}
      <DNABox spacing="small" alignY="center">
        <Rotate disabled={syncStatusDef?.labelIcon !== 'sync-circle'}>
          <DNAIcon.Styled
            name={syncStatusDef?.labelIcon}
            status={syncStatusDef?.status}
            appearance="ghost"
            context="minimum"
            size="xlarge"
          />
        </Rotate>
        <Iffy is={hasFailedSyncEntires && syncStatusDef?.labelIcon !== 'alert-circle'}>
          <DNAIcon.Styled
            name="alert-circle"
            status="warning"
            appearance="ghost"
            context="minimum"
            size="xlarge"
          />
        </Iffy>
        <DNAText h4>
          { syncStatusDef?.label }
        </DNAText>
      </DNABox>

      {/* 1. Offline label */}
      {/* Use ternaries instead of Iffy due to DNABox bug */}
      {
        (isOffline && hasRemainingSync)
          ? <DNAText status="flat">Please connect to the internet to resume download</DNAText>
          : null
      }

      {/* 2.Failed Sync Entries */}
      {
        hasFailedSyncEntires
          ? <DNABox
              fill
              appearance="col"
              spacing="medium"
              childFill={2}
          >
            <DNAText status="flat">
              The following file(s) have failed to download:
            </DNAText>
            <DNADivider />
            <ScrollView style={{ flex: 1, maxHeight: 200 }}>
              <DNABox
                fill
                childFill
                appearance="col"
                spacing="medium"
                wrap="start"
              >
                {/* [TODO-PWA] - We should put a cap on how many rows to render */}
                {
                        Object
                          .values(failedSyncEntries)
                          .map(value => (
                            <DNAText numberOfLines={3} bold>
                              {
                              allDocumentVersionMap[value.documentVersionId]
                                ?.relations
                                ?.document
                                ?.relations
                                .version
                                .latestDocumentVersionPublished
                                ?.model
                                .title
                            }
                            </DNAText>
                          ))
                      }
              </DNABox>
            </ScrollView>
            <DNADivider />
          </DNABox>
          : null
        }

      {/* 3. Download Progress */}
      {
        hasRemainingSync
          ? <DNABox fill appearance="col">
            {/* Download remaining */}
            <DNAText b1 status="flat">{contentEntriesRemaining}</DNAText>
            <DNAText b1 status="flat">{contentSizeLeft} remaining</DNAText>
          </DNABox>
          : null
      }

      {/* 4. Pause Button */}
      {
        (isPaused || isSync)
          ? <DNAButton
              style={{ flex: 1 }}
              onPress={handlePausedSync}
          >
            {isPaused ? 'Resume download' : 'Pause Download'}
          </DNAButton>
          : null
      }

      {/* 5. Contact Support Button */}
      {
        isIdleWithWarning
          ? <DNAButton
              style={{ flex: 1 }}
              onPress={contactSupportMailTo}
          >
            Contact support
          </DNAButton>
          : null
      }
    </DNABox>
  )
}

export default SyncStatusButton
