import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StyleSheet } from 'react-native';
import API, { graphqlOperation } from '@aws-amplify/api';
import { createTenantUser, updateUserLambda } from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import {
  DNABox,
  DNAButton, DNACheckbox,
  DNASelect,
  DNAText,
  GenericToast,
  Iffy,
  luxColors,
  ToastOrientations,
  useToast,
} from '@alucio/lux-ui';
import { FieldConfig, LabelValues, User, UserRole } from '@alucio/aws-beacon-amplify/src/models';
import { CreateUserInput, UpdateUserInput, USER_ROLE } from '@alucio/aws-beacon-amplify/src/API';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import InputComponent from '../Publishers/InputComponent';
import UserFilter, { CONFIG_FIELDS, LOCKED_FILTERS } from './UserFilter';

import { useFormik } from 'formik';
import DNACloseUserDrawerConfirmation from '../DNA/Modal/DNACloseUserDrawerConfirmation';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { useTenant } from 'src/state/redux/selector/tenant';
import { Spinner } from '@ui-kitten/components';
import { useUserById } from 'src/state/redux/selector/user';
import { validEmail } from 'src/utils/emailValidation';

const styles = StyleSheet.create({
  commonPadding: {
    paddingVertical: 16,
    paddingHorizontal: 32,
  },
  content: {
    backgroundColor: colors['color-flat-200'],
    flex: 1,
    paddingHorizontal: 32,
    paddingVertical: 34,
    overflow: 'scroll',
  },
  emailInput: {
    backgroundColor: colors['color-flat-200'],
  },
  footer: {
    borderTopColor: colors['color-flat-400'],
    borderTopWidth: 1,
  },
  header: {
    borderBottomColor: colors['color-flat-400'],
    borderBottomWidth: 1,
  },
  inputColor: {
    backgroundColor: luxColors.info.primary,
  },
  inputStyle: {
    backgroundColor: luxColors.info.primary,
    borderColor: colors['color-flat-400'],
  },
  inputStyleError: {
    backgroundColor: luxColors.info.primary,
    borderColor: colors['color-danger-500'],
  },
  roleInputError: {
    backgroundColor: luxColors.info.primary,
    borderColor: colors['color-danger-500'],
    borderRadius: 5,
    borderWidth: 1,
  },
  titleColor: {
    color: luxColors.contentText.quaternary,
  },
});

const MAPPED_ROLES = {
  [UserRole.TENANT_ADMIN]: 'Admin',
  [UserRole.TENANT_VIEWER]: 'Viewer',
  [UserRole.TENANT_PUBLISHER]: 'Publisher',
};

const EXISTING_EMAIL_ERROR = 'This email already exists.';
const INVALID_PHONE_NUMBER_ERROR = 'Invalid phone number format.';

interface Props {
  tenantId?: string,
  userId?: string,
  toggleDrawer: () => void,
}

interface FooterProps {
  isCreate: boolean,
  closeDrawer: () => void,
  isSubmitting: boolean,
  formikStatus: string,
  sendInvite: boolean,
  setSendInvite: (sendInvite: boolean) => void,
  onSave: () => void,
}

interface HeaderProps {
  closeDrawer: () => void,
  userEmail?: string,
}

function initFormikValues(fields: FieldConfig[], user?: User) {
  const filterValues = {};
  const userDefaultFilterValues = user?.defaultFilterValues || [];
  const userLockedFilters = user?.lockedFilters || [];

  // WE FORMAT THE FIELDVALUES TO BE HANLDED INDIVIDUALLY, USING THE FOLLOWING STRUCTURE:
  // { [key as string]: { defaultFilterValues: string[], lockedFilters: string[] } }
  fields.forEach(({ fieldName }) => {
    filterValues[fieldName] = {
      defaultFilterValues: [],
      lockedFilters: [],
    };

    filterValues[fieldName].defaultFilterValues = userDefaultFilterValues
      .find(({ key }) => key === fieldName)?.values || []
    filterValues[fieldName].lockedFilters = userLockedFilters
      .find(({ key }) => key === fieldName)?.values || []
  });

  return {
    email: user?.email,
    familyName: user?.familyName,
    givenName: user?.givenName,
    phoneNumber: user?.phoneNumber,
    role: user?.role || '',
    shareBCC: user?.shareBCC?.join(', ') || '',
    shareCC: user?.shareCC?.join(', ') || '',
    configValues: {
      ...filterValues,
    },
    isExcludedFromReporting: !!user?.isExcludedFromReporting,
  }
}

const DNAUserEdit = (props: Props) => {
  const userORM = useUserById(props.userId || '');
  const toast = useToast();
  const user = userORM?.user;
  const { toggleDrawer } = props;
  const tenant = useTenant(user?.tenantId || props.tenantId!);
  const dispatch = useDispatch();
  const [sendInvite, setSendInvite] = useState<boolean>(true);
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({});
  const [existingEmails, setExistingEmails] = useState(new Set());
  const formik = useFormik({
    initialValues: initFormikValues(tenant?.tenant.fields || [], user),
    enableReinitialize: false,
    onSubmit: async (values) => {
      const { defaultFilterValues, lockedFilters } = getFormattedFilterValuesFromFormik(values.configValues);
      const ccEmails = values.shareCC.trim() ? values.shareCC.split(',').map((email) => email.trim()) : [];
      const bccEmails = values.shareBCC.trim() ? values.shareBCC.split(',').map((email) => email.trim()) : [];
      const updsertPayload: CreateUserInput | UpdateUserInput = {
        id: user?.id || '',
        email: values.email,
        givenName: values.givenName,
        familyName: values.familyName,
        role: values.role as USER_ROLE,
        tenantId: user?.tenantId || tenant?.tenant.id,
        phoneNumber: values.phoneNumber,
        shareBCC: bccEmails,
        shareCC: ccEmails,
        defaultFilterValues,
        lockedFilters,
        isExcludedFromReporting: values.isExcludedFromReporting,
      };

      try {
        if (user) {
          await API.graphql(graphqlOperation(updateUserLambda, { user: updsertPayload }));
        } else {
          delete updsertPayload.id;
          await API.graphql(graphqlOperation(createTenantUser, { user: updsertPayload, sendInvite }));
          toast?.add(
            <GenericToast title={`New user created. ${sendInvite ? 'Email sent.' : ''}`} status="success" />,
            ToastOrientations.TOP_RIGHT,
          );
          toggleDrawer();
        }
      } catch (e) {
        if (e.errors[0]?.message === e) {
          setExistingEmails((existing) => existing.add(values.email));
          setErrors({ email: EXISTING_EMAIL_ERROR });
        } else if (e.errors[0]?.message === INVALID_PHONE_NUMBER_ERROR) {
          setErrors({ phoneNumber: INVALID_PHONE_NUMBER_ERROR });
        } else {
          toast?.add(
            <GenericToast title="Something went wrong" status="error" />,
            ToastOrientations.TOP_RIGHT,
          );
        }

        formik.setStatus('error');
        formik.setSubmitting(false);
        return;
      }

      formik.setStatus('success');
      formik.setSubmitting(false);
      setTimeout(() => {
        formik.resetForm({ values });
      }, 2000);
    },
  });
  const formikValuesRef = useRef(formik.values);
  const errorsRef = useRef(errors);

  // MEMOIZED COMPONENTS/FUNCTIONS
  const HeaderMemoized = useMemo(() => <Header closeDrawer={onCancel} userEmail={user?.email} />, [formik.dirty]),
    FooterMemoized = useMemo(() =>
      (<Footer
        setSendInvite={setSendInvite}
        sendInvite={sendInvite}
        closeDrawer={onCancel}
        onSave={areFieldsValid}
        isCreate={!user}
        isSubmitting={formik.isSubmitting}
        formikStatus={formik.status}
      />), [formik.dirty, formik.isSubmitting, formik.status, sendInvite]);
  const onChangeFilterOption = useCallback(
    (
      fieldName,
      fieldValueName,
      column,
    ) => onFilterOptionSelected(fieldName, fieldValueName, column),
    [],
  );
  const onAllValuesSelectedMemoized = useCallback(
    (
      fieldName,
      column,
      values,
      checked) => onAllValuesSelected(fieldName, column, values, checked),
    [],
  );
  const onUpdateFamilyName = useCallback((value) => onUpdateField('familyName', value), []);
  const onUpdateGivenName = useCallback((value) => onUpdateField('givenName', value), []);
  const onUpdatePhoneNumber = useCallback((value) => onChangePhoneNumber(value), []);
  const onUpdateEmail = useCallback((value) => onChangeEmail(value), []);
  const onUpdateExcludedFromReporting = useCallback(
    (value) => formik.setFieldValue('isExcludedFromReporting', value),
    [],
  );

  // SINCE WE MEMOIZE THE CONFIG VALUES COMPONENTS TO AVOID THEM TO RE RENDER,
  // WE STILL NEED THEM TO BE AWARE OF FORMIK CURRENT STATE, HENCE WE USE A REF OF IT
  useEffect(() => {
    formikValuesRef.current = { ...formik.values };
  }, [formik.values]);

  useEffect(() => {
    errorsRef.current = errors;
  }, [errors]);

  // SELECTS OR DESELECTS EVERY VALUE OF A SPECIFIC FIELD
  function onAllValuesSelected(fieldName: string, column: CONFIG_FIELDS, values: string[], checked: boolean): void {
    const current = { ...formikValuesRef.current.configValues[fieldName] };
    let newSelectedValues: string[] = [];

    if (column === LOCKED_FILTERS) {
      newSelectedValues = checked ? values : [];
    } else {
      if (!checked) {
        newSelectedValues = [];
      } else {
        // IF THE CHECKED IS TRUE, WE CAN ONLY SELECT (FOR THE DEFAULTS) THE ONES ALLOWED BY THE LOCKED ONES
        newSelectedValues = current.lockedFilters.length ? [...current.lockedFilters] : [...values];
      }
    }

    formik.setFieldValue(
      'configValues',
      {
        ...formikValuesRef.current.configValues,
        [fieldName]: {
          ...formikValuesRef.current.configValues[fieldName],
          [column]: newSelectedValues,
        },
      });
  }

  function onFilterOptionSelected(fieldName: string, fieldValueName: string, column: CONFIG_FIELDS): void {
    const current = { ...formikValuesRef.current.configValues[fieldName] };

    // IF THE VALUE IS ALREADY IN THE ARRAY, WE REMOVE IT. OTHERWISE WE ADD IT
    if (current[column].includes(fieldValueName)) {
      // const index = current[column].indexOf(fieldValueName);
      current[column] = current[column].filter((name) => name !== fieldValueName);
    } else {
      current[column] = [...current[column], fieldValueName];
    }

    // IF THE SELECTED ELEMENT IS IN THE LOCKED FILTERS COLUMN, AND WE'RE UNCHECKING,
    // WE NEED TO REMOVE IT FROM THE DEFAULT FILTER VALUES
    if (column === LOCKED_FILTERS && current.lockedFilters.length) {
      current.defaultFilterValues = current.defaultFilterValues.filter((name) => current.lockedFilters.includes(name));
    }

    formik.setFieldValue('configValues', { ...formikValuesRef.current.configValues, [fieldName]: current });
  }

  function onCancel(): void {
    // ONLY SHOW THE CONFIRMATION MODAL IF THERE IS AN UNSAVED MODIFICATION
    if (formik.dirty) {
      const payload = {
        isVisible: true,
        allowBackdropCancel: false,
        component: () => <DNACloseUserDrawerConfirmation closeUserDrawer={toggleDrawer} />,
      };

      dispatch(DNAModalActions.setModal(payload));
    } else {
      toggleDrawer();
    }
  }

  // WE NEED TO PREFIX A + SO COGNITO WON'T COMPLAIN
  function onChangePhoneNumber(phoneNumber: string): void {
    const isValid = !phoneNumber || (phoneNumber.length > 4 && phoneNumber.length < 20);

    if (isValid) {
      setErrors((errors) => {
        delete errors.phoneNumber;
        return errors;
      });
    }

    formik.setFieldValue('phoneNumber', phoneNumber ? `+${phoneNumber}` : '');
  }

  // HERE WE CHECK THE ERRORS PRIOR TO SUBMIT
  function areFieldsValid(): void {
    const values = formikValuesRef.current;
    const formikErrors: { [key: string]: string } = {};

    // CHECK IF THE PHONE NUMBER FORMAT IS VALID
    const isValidPhone = !values.phoneNumber || (values.phoneNumber.length > 4 && values.phoneNumber.length < 20);
    if (!isValidPhone) {
      formikErrors.phoneNumber = INVALID_PHONE_NUMBER_ERROR;
    }
    // CHECK IF THE EMAIL IS VALID
    const isValidEmail = validEmail.test(values.email || '')

    if (!isValidEmail) {
      formikErrors.email = 'Invalid email format.';
    } else if (existingEmails.has(values.email || '')) {
      formikErrors.email = 'This email already exists.';
    }

    // CHECK IF THERE'S AN EMPTY REQUIRED VALUE
    ['email', 'givenName', 'familyName', 'role'].forEach((field) => {
      if (!values[field]) {
        formikErrors[field] = 'This field is required.';
      }
    });

    if (Object.keys(formikErrors).length) {
      setErrors(formikErrors);
    } else {
      setErrors({});
      formik.submitForm();
    }
  }

  // ACCORDING TO THE REQUIREMENTS, UPON CHANGING THE EMAIL, WE ONLY WANT TO CHECK IF IT HAS NO ERRORS (CLEAN THE ERROR MSG, NOT ADDING)
  function onChangeEmail(email: string): void {
    const trimmed = email.trim();
    let isValidEmail = true;

    if (isValidEmail && trimmed !== '') {
      // eslint-disable-next-line max-len,no-useless-escape
      isValidEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(trimmed);
    }

    if (isValidEmail) {
      setErrors((errors) => {
        delete errors.email;
        return errors;
      });
    } else if (errorsRef.current.email) {
      setErrors({ ...errorsRef.current, email: 'Invalid email format.' });
    }

    formik.setFieldValue('email', trimmed);
  }

  function onUpdateField(field: string, value: string): void {
    if (value) {
      const updatedErrors = errorsRef.current;
      delete updatedErrors[field];
      setErrors(updatedErrors);
    }

    formik.setFieldValue(field, value);
  }

  return (
    <DNABox appearance="col" style={{ flex:1 }}>
      {/* HEADER */}
      { HeaderMemoized }
      {/* CONTENT */}
      <DNABox style={styles.content} appearance="col" spacing="xlarge">
        <DNABox appearance="col">
          <DNAText style={[{ marginBottom: 26 }, styles.titleColor]}>USER INFORMATION</DNAText>
          <DNABox spacing="medium" childStyle={{ flex: 1 }}>
            <DNABox appearance="col" spacing="medium">
              <DNABox appearance="col" spacing="small">
                <InputComponent
                  hidePlaceholder
                  inputStyle={errors.givenName ? styles.inputStyleError : styles.inputStyle}
                  titleColor={luxColors.contentText.tertiary}
                  removeMarginPadding
                  value={formik.values.givenName}
                  onChangeText={onUpdateGivenName}
                  title="FIRST NAME"
                  required={true}
                  multiline={true}
                  numOfLines={1}
                  disabled={formik.isSubmitting}
                />
                <Iffy is={errors.givenName}>
                  <DNAText c2 status="danger">{errors.givenName}</DNAText>
                </Iffy>
              </DNABox>
              <DNABox appearance="col" spacing="small">
                <InputComponent
                  hidePlaceholder
                  inputStyle={errors.familyName ? styles.inputStyleError : styles.inputStyle}
                  titleColor={luxColors.contentText.tertiary}
                  removeMarginPadding
                  value={formik.values.familyName}
                  onChangeText={onUpdateFamilyName}
                  title="LAST NAME"
                  required={true}
                  multiline={true}
                  numOfLines={1}
                  disabled={formik.isSubmitting}
                />
                <Iffy is={errors.familyName}>
                  <DNAText c2 status="danger">{errors.familyName}</DNAText>
                </Iffy>
              </DNABox>
              <DNABox appearance="col" spacing="small">
                <DNABox appearance="col" spacing="tiny">
                  <DNABox spacing="small">
                    <DNAText status="danger">*</DNAText>
                    <DNAText bold style={{ color: luxColors.contentText.tertiary }}>ROLE</DNAText>
                  </DNABox>
                  <DNASelect
                    onSelect={(value) => {
                      if (Array.isArray(value)) return;
                      formik.setFieldValue('role', Object.keys(MAPPED_ROLES)[value.row])
                    }}
                    size="large"
                    style={errors.role && !formik.values?.role ? styles.roleInputError : undefined}
                    status="flat"
                    disabled={formik.isSubmitting}
                    value={MAPPED_ROLES[formik.values?.role || '']}
                  >
                    {
                      Object.values(MAPPED_ROLES).map((value) => (
                        <DNASelect.Item key={value} title={value} />
                      ))
                    }
                  </DNASelect>
                </DNABox>
                <Iffy is={errors.role && !formik.values?.role}>
                  <DNAText c2 status="danger">{errors.role || ''}</DNAText>
                </Iffy>
              </DNABox>
              <DNABox appearance="col" spacing="small">
                <InputComponent
                  titleColor={user ? luxColors.contentText.quaternary : luxColors.contentText.tertiary}
                  removeMarginPadding
                  inputStyle={user ? styles.emailInput : errors.email ? styles.inputStyleError : styles.inputStyle}
                  hidePlaceholder
                  value={formik.values.email}
                  title="EMAIL"
                  required={true}
                  multiline={true}
                  numOfLines={1}
                  onChangeText={onUpdateEmail}
                  disabled={!!user || formik.isSubmitting}
                />
                <Iffy is={errors.email}>
                  <DNAText c2 status="danger">{errors.email || ''}</DNAText>
                </Iffy>
              </DNABox>
              <DNABox appearance="col">
                <InputComponent
                  titleColor={luxColors.contentText.tertiary}
                  hidePlaceholder
                  inputStyle={errors.phoneNumber ? styles.inputStyleError : styles.inputStyle}
                  removeMarginPadding
                  isNumeric={true}
                  value={formik.values.phoneNumber}
                  onChangeText={onUpdatePhoneNumber}
                  title="PHONE"
                  multiline={true}
                  required={false}
                  numOfLines={1}
                  disabled={formik.isSubmitting}
                />
                <Iffy is={errors.phoneNumber}>
                  <DNAText c2 status="danger">{errors.phoneNumber || ''}</DNAText>
                </Iffy>
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>
        <DNABox appearance="col">
          <DNAText style={[{ marginBottom: 26 }, styles.titleColor]}>USER FILTERS</DNAText>
          <DNABox appearance="col">
            { tenant?.tenant.fields.map((field) => (
              <Iffy is={field.userFilter}>
                <UserFilter
                  key={field.fieldName}
                  field={field}
                  disabled={formik.isSubmitting}
                  values={formik.values.configValues[field.fieldName]}
                  onValueSelected={onChangeFilterOption}
                  onAllValuesSelected={onAllValuesSelectedMemoized}
                />
              </Iffy>
            )
              )}
          </DNABox>
        </DNABox>
        <DNABox>
          <DNACheckbox
            checked={formik.values.isExcludedFromReporting}
            onChange={onUpdateExcludedFromReporting}
          />
          <DNAText style={{ marginLeft: 7 }}>Exclude from reporting</DNAText>
        </DNABox>
      </DNABox>
      {/* FOOTER */}
      { FooterMemoized }
    </DNABox>
  );
};

function Footer(props: FooterProps): ReactElement {
  return (
    <DNABox style={[styles.commonPadding, styles.footer]} spacing="between" alignX="end" alignY="center">
      <DNABox spacing="small">
        <Iffy is={props.isCreate}>
          <DNACheckbox
            disabled={props.isSubmitting}
            onChange={() => props.setSendInvite(!props.sendInvite)}
            checked={props.sendInvite}
          />
          <DNAText>Send email invite</DNAText>
        </Iffy>
      </DNABox>
      <DNABox spacing="small">
        <DNAButton
          disabled={props.isSubmitting}
          appearance="outline"
          onPress={props.closeDrawer}
          size="small"
        >
          Cancel
        </DNAButton>
        <DNAButton
          size="small"
          status={props.formikStatus === 'success' ? 'success' : undefined}
          iconLeft={props.formikStatus === 'success' ? 'check' : undefined}
          onPress={props.onSave}
        >
          {props.isSubmitting
            ? <Spinner size="tiny" status="info" />
            : props.formikStatus === 'success'
              ? 'Saved'
              : props.isCreate ? 'Create' : 'Save'}
        </DNAButton>
      </DNABox>
    </DNABox>
  );
}

function Header(props: HeaderProps): ReactElement {
  return (
    <DNABox style={[styles.commonPadding, styles.header]} spacing="between" alignY="center">
      <DNAText bold>{props.userEmail ? `Edit ${props.userEmail}` : 'Add user'}</DNAText>
      <DNABox alignY="center" spacing="small">
        <DNAText status="danger">* Required Fields</DNAText>
        <DNAButton
          status="subtle"
          appearance="ghostAlt"
          context="minimum"
          iconLeft="close"
          onPress={props.closeDrawer}
        />
      </DNABox>
    </DNABox>
  );
}

// FORMAT THE FILTER VAlUES STRUCTURE USED FOR FORMIK INTO USER'S LABELS/VALUES SO THEY CAN BE SAVED
function getFormattedFilterValuesFromFormik(configValues) {
  return Object.keys(configValues).reduce((acc, configValue) => {
    if (configValues[configValue].lockedFilters.length) {
      acc.lockedFilters.push({
        key: configValue,
        values: [...configValues[configValue].lockedFilters],
      });
    }

    if (configValues[configValue].defaultFilterValues.length) {
      acc.defaultFilterValues.push({
        key: configValue,
        values: [...configValues[configValue].defaultFilterValues],
      });
    }

    return acc;
  }, { lockedFilters: [] as LabelValues[], defaultFilterValues: [] as LabelValues[] });
}

DNAUserEdit.displayName = 'UserEdit';

export default DNAUserEdit;
