import React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { WebLinking as Linking } from '@alucio/core'

const S = StyleSheet.create({
  footer: {
    flexShrink: 0,
    paddingBottom: 24,
  },
  headerText: {
    fontSize: 24,
    fontWeight: '700',
    marginBottom: 40,
  },
  linkText: {
    color: '#44A2DA',
  },
  messageWrapper: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
  },
  subtitleText: {
    fontSize: 16,
    fontWeight: '500',

  },
  wrapper: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
  },
});

export default (props: { message: string, promptLogout?: boolean, children?: any }) => {
  const {
    message = 'We could not find what you were looking for',
    promptLogout = true,
    children,
  } = props
  const handleLogout = async () => {
    Linking.openURL('/logout', '_self')
  }

  const handleEmail = () => {
    Linking.openURL('mailto:support@alucio.io', '_self')
  }

  return (
    <View style={S.wrapper}>
      <View style={S.messageWrapper}>
        <Text style={S.headerText}>{message}</Text>
        { children }
        {
          promptLogout && (
            <Text style={S.subtitleText}>If error persists, please
              <Pressable onPress={handleLogout}>
                <Text style={S.linkText}> click here </Text>
              </Pressable>
              <Text style={S.subtitleText}>to log out and log back in.</Text>
            </Text>
          )
        }
      </View>
      <View style={S.footer}>
        <Text style={S.subtitleText}>For further assistance, contact Alucio Support at
          <Pressable>
            <Text style={S.linkText} onPress={handleEmail}> support@alucio.io</Text>
          </Pressable>
        </Text>
      </View>
    </View>
  )
}
