import { StyleSheet } from 'react-native';
import { luxColors } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';

export const styles = StyleSheet.create({
  CharacterCounter: {
    color: '#8F9BB3',
    fontSize: 14,
    fontWeight: 'normal',
    justifyContent: 'flex-end',
  },
  ColumnSize: {
    flex: 1,
  },
  ColumnDivider: {
    marginHorizontal: 24,
    maxWidth: 2,
  },
  Container: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 14,
    marginRight: 14,
    padding: 10,
  },
  CounterContainer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row-reverse',
  },
  EditFooter: {
    paddingBottom: 18,
  },
  EditFooterButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  EditFooterContainer: {
    backgroundColor: '#F7F9FC',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginTop: 66,
    paddingBottom: 32,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24,
  },
  EditFooterInfoContainer: {
    borderBottomColor: luxColors.formFieldBorder.primary,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: 32,
  },
  EditFooterInfoText: {
    color: '#8F9BB3',
    fontSize: 13,
    fontWeight: 'normal',
  },
  EditFooterInfoTitle: {
    color: '#8F9BB3',
    fontSize: 13,
    fontWeight: 'bold',
    justifyContent: 'flex-start',
    paddingBottom: 4,
    textTransform: 'uppercase',
  },
  EditHeader: {
    color: '#2C3651',
    fontSize: 22,
    fontWeight: 'bold',
  },
  EditHeaderButton: {},
  EditHeaderButtonContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  EditHeaderContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    paddingBottom: 0,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 21,
  },
  EditHeaderDefaultButton: {
    backgroundColor: '#EDF1F7',
  },
  EditHeaderSaveButton: {
    backgroundColor: '#0095FF',
  },
  errorLabel: {
    color: '#CB3831',
    fontSize: 13,
    fontWeight: 'bold',
  },
  fileEditBar: {
    width: 300,
  },
  HelpToolTipIconStyle: {
    color: luxColors.contentText.quaternary,
    height: 16,
    paddingLeft: 5,
    width: 16,
  },
  IconTextBlack: {
    color: '#B6B6B6',
    fontSize: 18,
  },
  IconTextRed: {
    color: '#FF3D71',
    fontSize: 18,
  },
  IconTextWhite: {
    color: '#FFF',
    fontSize: 18,
  },
  DefaultInputComponent: {
    backgroundColor: colors['color-text-basic'],
  },
  titleInput: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 14,
    marginRight: 14,
    padding: 10,
    paddingBottom: 0,
    paddingTop: 0,
    alignSelf: 'flex-end',
  },
  labelTab: {
    alignItems: 'flex-start',
    backgroundColor: luxColors.backgroundColor.primary,
    marginLeft: 24,
    paddingTop: 15,
  },
  navTabContainer: {
    backgroundColor: luxColors.backgroundColor.primary,
    flex: 1,
    overflow: 'scroll',
  },
  NoMarginPadding: {
    marginLeft: 0,
    marginRight: 0,
    padding: 0,
  },
  OptionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  PillContainer: {
    backgroundColor: '#AABBCC',
    borderRadius: 5,
    padding: 5,
  },
  radioItem: {
    color: '#222B45',
  },
  required: {
    color: '#FF0000',
    paddingLeft: 5,
    paddingRight: 5,
  },
  RadioContainer: {
    flexDirection: 'column',
    marginLeft: 14,
    marginRight: 14,
    padding: 10,
    backgroundColor: 'transparent',
  },
  ScrollViewContainer: {
    paddingVertical: 40,
    backgroundColor: colors['color-flat-200'],
  },
  tabContentWrapper: {
    paddingVertical: 40,
    paddingHorizontal: 40,
  },
  status: {
    paddingLeft: 10,
  },
  StatusDescriptionContainer: {
    marginLeft: 20,
    padding: 5,
  },
  tabBorder: {
    backgroundColor: luxColors.menuBackground.primary,
    borderBottomColor: luxColors.border.primary,
    borderBottomWidth: 1,
  },
  tabContainer: {
    flex: 1,
    justifyContent: 'space-between',
    marginLeft: 24,
    marginRight: 24,
  },
  tabPadding: {
    paddingLeft: 96,
    paddingRight: 96,
  },
  ThumbnailContainer: {
    borderColor: luxColors.formFieldBorder.primary,
    borderWidth: 1,
    marginLeft: 24,
  },
  Title: {
    color: colors['color-dark-500'],
    fontSize: 13,
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 4,
    textTransform: 'uppercase',
  },
  TitleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  SelectPrompt: {
    color: '#8F9BB3',
  },
  DisabledPrompt: {
    color: '#C6CDC9',
  },
});
