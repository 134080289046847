import { luxColors } from '@alucio/lux-ui';
import { colors } from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {},
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  mainContainer: {
    flex: 1,
    flexWrap: 'wrap',
    width: 1240,
    backgroundColor: luxColors.menuBorder.primary,
    borderWidth: 5,
    borderColor: luxColors.menuBorder.primary,
    borderRadius: 8,
  },
  mainContainerFS: {
    flex: 1,
    flexWrap: 'wrap',
    backgroundColor: luxColors.menuBorder.primary,
    borderWidth: 0,
    borderRadius: 8,
  },
  tabletMainContainerFS: {
    flex: 1,
    flexWrap: 'wrap',
    backgroundColor: luxColors.basicBlack.primary,
    borderWidth: 1,
    borderColor: luxColors.basicBlack.primary,
  },
  headerBarDesktop: {
    width: '100%',
    minHeight: 80,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: luxColors.buttonBorder.primary,
    paddingBottom: 10,
  },
  headerBarTablet: {
    borderBottomWidth: 1,
    borderBottomColor: luxColors.buttonBorder.primary,
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  autoUpdateBannerContainer: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 16,
    borderBottomWidth: 1,
    borderBottomColor: luxColors.buttonBorder.primary,
  },
  mainContent: {
    flexGrow: 1,
    flexDirection: 'row',
  },
  contentPreview: {
    // height: 429,
    marginBottom: 16,
    backgroundColor: 'black',
  },
  contentPreviewTablet: {
    backgroundColor: luxColors.basicBlack.primary,
  },
  contentPreviewFS: {
    width: '100%',
    height: '100%',
  },
  icon: {
    width: 32,
    height: 32,
    marginTop: 10,
    color: luxColors.closeIcon.primary,
  },
  exitIcon: {
    padding: 12,
    marginTop: 10,
    marginLeft: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    color: luxColors.menuBorder.primary,
    borderRadius: 30,
  },
  slideContentContainer: {
    width: 695,
    margin: 24,
    marginBottom: 0,
  },
  slideContentContainerFS: {
    width: '100vw',
    height: '100%',
    margin: 0,
    marginBottom: 14,
  },
  headerCloseButton: {
    marginTop: 12,
    flex: 1,
    marginLeft: 20,
    marginRight: 20,
  },
  fullwindowControls: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: 'red',
  },
  tabletNavAreaLayer: {
    position: 'absolute',
    top: 0,
    width: 125,
    height: '100vh',
  },
  tabletNavButtonBar: {
    width: 125,
    overflow: 'hidden',
    height: '100vh',
  },
  tabletTopBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 95,
    backgroundColor: colors['brand-500'],
    width: '100%',
    zIndex: 1000,
  },
  sidebarContainer: {
    borderLeftWidth: 1,
    borderStyle: 'solid',
    borderColor: '#EBECF0',
    width: 390,
  },
  sidebarButtons: {
    maxHeight: 40,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#EBECF0',
  },
  sidebarButton: {
    backgroundColor: 'unset',
    color: '#6B778C',
  },
  sidebarButtonInActive: {
    paddingBottom: 3,
  },
  sidebarButtonActive: {
    borderWidth: 0,
    borderRadius: 0,
    borderBottomWidth: 3,
    borderColor: '#44A2DA',
    borderStyle: 'solid',
  },
  buttonText: {
    color: '#6B778C',
    maxWidth: 75,
    textAlign: 'center',
    marginLeft: 15,
    marginRight: 15,
  },
  buttonTextActive: {
    color: '#44A2DA',
  },
  exitButton: {
    flex: 1,
    paddingLeft: 16,
    paddingTop: 16,
  },
  hideFullScreenBars: {
    flex: 1,
    paddingRight: 16,
    paddingTop: 16,
  },
  slideCounter: {
    color: 'white',
    fontSize: 18,
    marginRight: 62,
  },
})
