import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'src/router'
import { FolderORM } from 'src/types/types'
import { useDNAFolderNav } from './DNAFolderNav'

// [TODO] Separating this into two separate hooks also isn't ideal
// Only intended for cold boot init
// - Only apply ONCE in a single context
export const useFolderNavURLInit = () => {
  const [init, setInit] = useState<boolean>(false)
  const location = useLocation()
  const folderNav = useDNAFolderNav()

  const pathTokens = location.pathname.split('/').slice(2)

  /**
   * Intended for a page that's been cold boot
   * - Take the URL path and init the nav stack based on the URL
   */
  useEffect(() => {
    pathTokens.forEach(pathToken => {
      const folderORMFromPath = folderNav
        .allFolders
        .find(folderORM => folderORM.model.id === pathToken)

      if (!folderORMFromPath) {
        // history.replace('/404')
        return;
      }

      folderNav.pushFolder(folderORMFromPath)
    })

    setInit(true)
  }, [])

  // This currently does not update the location URL based on
  //  FolderStack changing programmatically (i.e. if a folder is deleted from a different session)
  useEffect(() => {
    const pathDelta = pathTokens.length - folderNav.folderStack.length

    if (pathDelta === 0 || !init) {
      return;
    }

    if (pathTokens.length === 0) {
      folderNav.popToFolder()
    } else if (pathDelta > 1) {
      const targetFolders = pathTokens.slice(
        folderNav.folderStack.length ? folderNav.folderStack.length - 1 : 0,
        pathDelta,
      )

      targetFolders.forEach(targetFolderId => {
        const targetFolderORM = folderNav.allFolders.find(f => f.model.id === targetFolderId)

        if (!targetFolderORM) {
          console.error('Could not find folder to push')
          return;
        }

        folderNav.pushFolder(targetFolderORM)
      })
    } else {
      const [targetFolderId] = pathTokens.slice(-1)
      const targetFolderORM = folderNav
        .allFolders
        .find(f => f.model.id === targetFolderId)

      if (!targetFolderORM) {
      } else {
        pathTokens.length > folderNav.folderStack.length
          ? folderNav.pushFolder(targetFolderORM)
          : folderNav.popToFolder(targetFolderORM)
      }
    }
  }, [init, location, folderNav.folderStack])
}

// Only passes functions to update URLs
export const useFolderNavURL = (pageURL: string) => {
  const history = useHistory()
  const location = useLocation()
  const folderNav = useDNAFolderNav()
  const pathTokens = location.pathname.split('/').slice(2)

  const pushFolder = (folderORM: FolderORM) => {
    history.push({
      pathname: location.pathname + `/${folderORM.model.id}`,
      search: location.search,
    })
  }

  const popFolder = () => {
    history.push({
      pathname: pageURL + pathTokens.slice(0, -1),
      search: location.search,
    })
  }

  const popToFolder = (folderORM?: FolderORM) => {
    if (!folderORM) {
      history.push({ pathname: pageURL, search: location.search })
      return;
    }

    const targetIdx = folderNav.folderStack.findIndex(f => f.model.id === folderORM.model.id)

    if (targetIdx === -1) {
      console.error('Could not pop to folder')
      return;
    }

    history.push({
      pathname: pageURL + `/${pathTokens.slice(0, targetIdx + 1).join('/')}`,
      search: location.search,
    })
  }

  return {
    pushFolder,
    popFolder,
    popToFolder,
  }
}

export default useFolderNavURL
