import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'src/router'
import qs from 'qs'

export enum DisplayModes {
  grid = 'grid',
  list = 'list'
}

export type DisplayMode = ReturnType<typeof useDisplayMode>

const QSKey = 'display'

/**
 * Hook for managing display toggles for grids and lists
 * - uses querystring as state mechanism
 */
export const useDisplayMode = (
  defaultMode: DisplayModes = DisplayModes.list,
  opts: { manageURL: boolean } = { manageURL: true },
) => {
  const history = useHistory()
  const location = useLocation()

  // Determine the state from querystring
  const qsParam = qs.parse(location.search, { ignoreQueryPrefix: true })
  const qsValue = qsParam[QSKey]
    ? qsParam[QSKey] === DisplayModes.grid
      ? DisplayModes.grid
      : DisplayModes.list
    : undefined

  const [displayMode, setDisplayMode] = useState<DisplayModes>(qsValue || defaultMode)
  const [enableDisplayMode, setEnableDisplayMode] = useState<boolean>(true)

  // Assert the querystring to match the current state
  // [NOTE]: This causes 2 renders! Probably due to React Router and history.replace
  useEffect(() => {
    if (opts.manageURL && qsParam[QSKey] !== displayMode) {
      history.replace({
        pathname: location.pathname,
        search: qs.stringify({ ...qsParam, [QSKey]: displayMode }),
      })
    }
  }, [displayMode])

  // If URL search query changes, reflect to our local state
  useEffect(() => {
    if (opts.manageURL && qsValue !== displayMode) {
      if (qsValue === DisplayModes.grid || qsValue === DisplayModes.list) {
        setDisplayMode(qsValue)
      }
    }
  }, [location.search])

  function toggleDisplayMode() {
    setDisplayMode(p => p === DisplayModes.grid
      ? DisplayModes.list
      : DisplayModes.grid,
    )
  }

  function toggleEnableDisplayMode() {
    setEnableDisplayMode(p => !p)
  }

  // [TODO] - Consider adding a partial nav that automatically includes the search string
  return {
    displayMode,
    displayModeIconName: displayMode === DisplayModes.grid
      ? 'view-list'
      : 'view-grid',
    setDisplayMode,
    toggleDisplayMode,
    enableDisplayMode,
    toggleEnableDisplayMode,
    setEnableDisplayMode,
  }
}

export default useDisplayMode
