import { DocumentVersionORM, FolderItemORM } from 'src/types/types'
/** This hook allows us to centralize the logic which determines the correct title to be used for various components.
 * E.g. the content preview modal header, gridview element label, list view element label, etc. */

// [TODO-ORM] - This can probably be calculated at the ORM level
export const useDocumentTitle = (
  docVersionORM: DocumentVersionORM,
  folderItemORM?: FolderItemORM,
) => {
  return folderItemORM?.model.customTitle ||
    docVersionORM.model.title
}
