import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import im from 'immer'
import { createSelector } from '@reduxjs/toolkit'
import {
  AssociatedFile,
  AssociatedFileType,
  AttachedFile,
  Document,
  DocumentAccessLevel,
  DocumentStatus,
  DocumentVersion,
  DocumentVersionChangeType,
  FieldConfig,
  LabelValue,
  PurposeType,
  S3Object,
  Tenant,
  UserRole,
} from '@alucio/aws-beacon-amplify/src/models'
import { CacheManifestEntry, CONTENT_CACHE_TYPE } from '@alucio/core'
import { RootState } from 'src/state/redux/store'
import {
  AssociatedFileORM,
  DocumentORM,
  DocumentVersionORM,
  ORMTypes,
  PageGroupORM,
  PageORM,
  VERSION_UPDATE_STATUS,
} from 'src/types/types'
import { indexArray } from 'src/utils/arrayHelpers';
import { getAuthHeaders } from 'src/utils/loadCloudfrontAsset/common'

import {
  FilterAndSortOptions,
  filterCollection,
  paginateCollection,
  sortCollection,
} from 'src/state/redux/selector/common'
import { loggedUser } from 'src/state/redux/selector/user'
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector'
import capitalize from 'lodash/capitalize'

export type { FilterAndSortOptions, SortOptions } from 'src/state/redux/selector/common'

export type AttachedFilesMap = { [attachedFileId: string]: AttachedFile }
export type DocumentVersionsMap = { [docId: string]: DocumentVersion[] }
export type DocumentsMap = { [docId: string]: Document }
export type TenantsMap = { [tenantId: string]: Tenant }
export type BookmarkMap = { [docId: string]: { isBookmarked: boolean, createdAt: string } }
export type documentVersionORMMap = { [docVerId: string]: DocumentVersionORM }
export type TenantFieldMap = {
  values: {
    [key: string]: LabelValue['value'][]
  },
  configs: {
    [key: string]: FieldConfig
  }
}
export type CacheMap = Record<
  string,
  { [key in CONTENT_CACHE_TYPE]?: CacheManifestEntry }
>

// [TODO] Keep naming consistent map vs indexed
export interface IndexedDocumentORM {
  [key: string]: DocumentORM
}

export interface IndexedDocumentVersion {
  [key: string]: DocumentVersion
}

export const selAttachedFiles = (state: RootState): AttachedFile[] => state.attachedFile.records
export const selDocuments = (state: RootState): Document[] => state.document.records
export const selDocumentVersions = (state: RootState): DocumentVersion[] => state.documentVersion.records
export const selTenants = (state: RootState): Tenant[] => state.tenant.records
export const selCache = (state: RootState): CacheManifestEntry[] => state.cache.manifestEntries
const selIsOnline = (state: RootState): boolean => state.cache.isOnline
export const selOpts = (_, __, opts: FilterAndSortOptions<DocumentORM>) => opts

export const selAttachedFilesMap = createSelector(
  selAttachedFiles,
  (attachedFiles: AttachedFile[]): AttachedFilesMap => {
    return attachedFiles.reduce(
      (acc, attachedFile) => {
        acc[attachedFile.id] = attachedFile;
        return acc;
      },
      {} as AttachedFilesMap,
    )
  },
);

export const selDocumentsMap = createSelector(
  selDocuments,
  (documents: Document[]): DocumentsMap => {
    return documents.reduce(
      (acc, document) => {
        acc[document.id] = document;
        return acc;
      },
      {} as DocumentsMap,
    )
  },
)

export const selDocumentVersionsMap = createSelector(
  selDocumentVersions,
  (docVers): DocumentVersionsMap => {
    return docVers.reduce(
      (acc, docVer) => {
        if (!acc[docVer.documentId]) acc[docVer.documentId] = []
        acc[docVer.documentId].push(docVer)
        return acc
      },
      {} as DocumentVersionsMap,
    )
  },
)

export const selTenantMap = createSelector(
  selTenants,
  (tenants): TenantsMap => {
    return tenants.reduce(
      (acc, tenant) => {
        acc[tenant.id] = tenant
        return acc
      },
      {} as TenantsMap,
    )
  },
)

export const selCacheMap = createSelector(
  selCache,
  (cache): CacheMap => {
    return cache.reduce<CacheMap>(
      (acc, cache) => {
        if (!acc[cache.documentVersionId]) {
          acc[cache.documentVersionId] = {
            CONTENT: undefined,
            THUMBNAIL: undefined,
          }
        }

        acc[cache.documentVersionId][cache.cacheType] = cache

        return acc
      },
      {},
    )
  },
)

export function getMissingRequiredFieldNames(
  docVersion: DocumentVersion,
  tenantFieldConfig?: FieldConfig[],
): string[] {
  const missingFields: string[] = [];
  // Check Document's mandatory fields
  const mandatoryDocumentFields = ['title', 'expiresAt', 'shortDescription', 'purpose']
  mandatoryDocumentFields.forEach(field => {
    if ((docVersion[field] === undefined) ||
      (docVersion[field] === null) ||
      (docVersion[field] === '')) {
      missingFields.push(field);
    }
  });

  const requiredTenantFields = tenantFieldConfig?.filter(field => field.required)
  if (requiredTenantFields?.length) {
    // Map document label into object for easier comparison
    const docMappedLabelValues = docVersion?.labelValues?.reduce((acc, { key, value }) =>
      ({ ...acc, [key]: value }), {}) ?? {}

    // Check if all required fields are met
    requiredTenantFields.forEach((field) => {
      if (!docMappedLabelValues[field.fieldName]) {
        missingFields.push(field.fieldName);
      }
    });
  }
  return missingFields;
}

export function getMappedTenantFields(docVersion: DocumentVersion, tenant: Tenant): TenantFieldMap {
  const reduced = im({ values: {}, configs: {} }, (draft: TenantFieldMap) => {
    const docValues = docVersion?.labelValues?.reduce((acc, { key, value }) => {
      if (key in acc) {
        const obj = { ...acc };
        obj[key] = [value, ...obj[key]];
        return obj;
      } else {
        return { ...acc, [key]: [value] };
      }
    }, {}) ?? {}

    // [NOTE]: This is omitting any labelValues not present in Tenant.fields
    tenant?.fields?.forEach(field => {
      draft.values[field.fieldName] = docValues[field.fieldName]
        ? [...new Set<string>(docValues[field.fieldName].sort())]
        : [];
      draft.configs[field.fieldName] = field
    })
  })

  return reduced
}

export function getPermissions(document: Document, docVersion: DocumentVersion): DocumentORM['meta']['permissions'] {
  const { status, accessLevel } = document
  const { purpose, canHideSlides:docCanHideSlides } = docVersion
  const { REVOKED, ARCHIVED, PUBLISHED } = DocumentStatus

  const isInternal = purpose === PurposeType.INTERNAL_USE_ONLY
  const isRevoked = status === REVOKED
  const isArchived = status === ARCHIVED
  const isPublished = status === PUBLISHED
  const isUserDoc = accessLevel === 'USER'

  const canBookmark = !(isRevoked || isArchived)
  const canAddToFolder = !(isRevoked || isArchived || isUserDoc)
  const canPresent = !(isInternal || isArchived || isUserDoc)
  const canDownload = ((docVersion.downloadable ?? true) || isArchived) && !isUserDoc
  const canHideSlides = !!docCanHideSlides && isPublished && !isInternal

  return {
    bookmark:canBookmark,
    addToFolder:canAddToFolder,
    MSLPresent:canPresent,
    MSLDownload:canDownload,
    MSLSelectSlides:canHideSlides,
  }
}

function shareableAssociatedFiles(associatedFiles: AssociatedFileORM[]) {
  if (associatedFiles.length! < 1) return false
  return associatedFiles.some(associatedDoc =>
    associatedDoc.model.type === 'ATTACHED_FILE'
      ? !!associatedDoc.model.isDistributable
      : !!associatedDoc.relations.latestUsableDocumentVersion?.distributable,
  );
}

function getDocVersionPermissions(
  documentVersion: DocumentVersion,
  associatedFiles: AssociatedFileORM[],
): DocumentVersionORM['meta']['permissions'] {
  const isPublished = documentVersion.status === DocumentStatus.PUBLISHED
  const canShare = isPublished && (documentVersion.distributable || shareableAssociatedFiles(associatedFiles))
  return { MSLShare: canShare }
}

// [NOTE]: We expect this to be pre-sorted
// [TODO-PWA] - Consider not always adding cache meta to `useAllDocuments`
//  - It can cause lots of rerender during content sync
//  - Consider having a separate selector that components would fall back to?
function getCachedDocumentVersion(docVerDesc: DocumentVersionORM[]): (DocumentVersionORM | undefined) {
  const [latestVersion] = docVerDesc

  if (!latestVersion) { return; }

  // 1. Return either the most recent cached doc version
  //  -or-
  // 2. next latest version
  const latestContentCachedDocVer = docVerDesc.find(docVer => docVer.meta.assets.isContentCached)
  if (latestContentCachedDocVer) return latestContentCachedDocVer

  // 3. Return the most recent (partial, thumbnail only )doc version
  //  -or-
  // 4. next latest partial version
  // 5. or no version if nothing's cached
  const latestThumbnailCachedDocVer = docVerDesc.find(docVer => docVer.meta.assets.isThumbnailCached)
  return latestThumbnailCachedDocVer
}

export const selBookmarkMap = createSelector(
  loggedUser,
  (currentUser): BookmarkMap => {
    // [TODO] Should not make user profile optional
    // - If they cannot find a cognito/user profile, they should not be able to continue
    if (!currentUser.userProfile) {
      throw new Error('Could not find current user')
    }

    return currentUser.userProfile?.bookmarkedDocs?.reduce(
      (acc, bookmark) => {
        acc[bookmark.docID] = { isBookmarked: true, createdAt: bookmark.createdAt }
        return acc
      },
      {} as BookmarkMap
    ) ?? {}
  },
)

const toDocumentORM = (
  document: Document,
  docVersMap: DocumentVersionsMap,
  tenantsMap: TenantsMap,
  bookmarkMap: BookmarkMap,
  userTenant: Tenant,
  attachedFilesMap: AttachedFilesMap,
  documentsMap: DocumentsMap,
  cacheMap: CacheMap,
  isOnline: boolean,
): DocumentORM => {
  // NOTE: A docVer may not be loaded at the same as a Document, ensure that it can handle an undefined value
  const targetDocVersions = docVersMap[document.id] ?? [] as DocumentVersion[] | undefined
  const [docVer] = Object.values(targetDocVersions)

  const documentORM: DocumentORM = {
    model: document,
    type: ORMTypes.DOCUMENT,
    relations: {
      tenant: tenantsMap[document.tenantId],
      documentVersions: [],
      version: {
        // @ts-ignore (we will set this value next)
        latestDocumentVersion: undefined,
        latestDocumentVersionPublished: undefined,
        cachedDocumentVersion: undefined,
      },
    },
    meta: {
      assets: {
        thumbnailKey: undefined,
        getAuthHeaders,
      },
      bookmark: {
        isBookmarked: !!bookmarkMap?.[document.id]?.isBookmarked,
        createdAt: bookmarkMap?.[document.id]?.createdAt,
      },
      tenantFields: {
        labelCompletion: false,
        valuesMap: {},
        configsMap: {},
      },
      integration: {
        integrationType: document.integrationType,
        integration: document.integration,
        source: capitalize(document.integrationType),
      },
      permissions: {
        bookmark: false,
        addToFolder: false,
        MSLDownload: false,
        MSLPresent: false,
        MSLSelectSlides: false,
      },
      hasUnpublishedVersion: false,
      sealedStatus: undefined,
    },
  }

  // Determine latestDoc and or published version
  if (docVer) {
    const docVerDesc: DocumentVersion[] = targetDocVersions.sort((a, b) => b.versionNumber - a.versionNumber)
    const docVerORMDesc = docVerDesc.map(docVer => toDocumentVersionORM(
      docVer,
      documentORM,
      docVerDesc,
      attachedFilesMap,
      documentsMap,
      docVersMap,
      cacheMap,
      userTenant,
    ))

    documentORM.relations.documentVersions = docVerORMDesc;

    const [latestDocVer] = docVerORMDesc
    const latestPublishedDocVer = docVerORMDesc.find(({ model }) => model.status === DocumentStatus.PUBLISHED);
    const cachedDocVersion = getCachedDocumentVersion(docVerORMDesc)

    documentORM.relations.version.cachedDocumentVersion = cachedDocVersion
    documentORM.relations.version.latestDocumentVersion = latestDocVer
    documentORM.relations.version.latestDocumentVersionPublished = latestPublishedDocVer
    // Latest usable document version is Online Published -> Offline Published Cached -> Latest Version (published or unpublished)
    // In a lot of places we do network checks, but maybe that should just be done from the ORM level instead of the component level?
    const usableDocVer = (isOnline ? latestPublishedDocVer : cachedDocVersion) || latestDocVer
    documentORM.relations.version.latestUsableDocumentVersion = usableDocVer

    // integration
    documentORM.meta.integration.integrationType = usableDocVer.model.integrationType
    documentORM.meta.integration.integration = documentORM.model.integration

    documentORM.meta.permissions = getPermissions(document, usableDocVer.model)
    documentORM.meta.tenantFields = usableDocVer.meta.tenantFields
    documentORM.meta.hasUnpublishedVersion = (
      latestDocVer?.model.status === DocumentStatus.NOT_PUBLISHED &&
      !(
        [
          `${DocumentStatus.REVOKED}`,
          `${DocumentStatus.ARCHIVED}`,
          `${DocumentStatus.DELETED}`,
        ].includes(document.status)
      )
    );
    const selectedThumbnailPage = usableDocVer.model.selectedThumbnail ?? 1

    documentORM.meta.assets.thumbnailKey = detectArchivedFileKeyPath(
      usableDocVer.model,
      { number: selectedThumbnailPage, pageId: `${usableDocVer.model.id}_${selectedThumbnailPage}` },
      'small',
    ) || undefined

    // @ts-expect-error - TS doesn't the know types are narrowed down
    documentORM.meta.sealedStatus = [
      DocumentStatus.ARCHIVED,
      DocumentStatus.REVOKED,
      DocumentStatus.DELETED,
    ].find(status => status === document.status)
  }

  // [NOTE] This is a dummy DocumentVersion, this can happen when we upload a new document
  //  and the DocumentVersion has not yet been added to redux
  //  Rather than make documentVersion optional (undefined, requiring lots of fallback logic in many components)
  //  We just have a dummy version instead, this would only be available for a second or two at most
  if (!docVer) {
    const dummyVer = new DocumentVersion({
      associatedFiles: [],
      tenantId: '',
      documentId: '',
      versionNumber: 0,
      srcFilename: '',
      conversionStatus: 'PROCESSING',
      status: 'NOT_PUBLISHED',
      pages: [],
      srcFile: new S3Object({
        key: '',
        bucket: '',
        region: '',
        url: '',
      }),
      type: 'PPTX',
      editPermissions: [],
      srcSize: 0,
      createdBy: '',
      updatedBy: '',
    })

    documentORM.relations.version.latestDocumentVersion = toDocumentVersionORM(
      dummyVer,
      documentORM,
      [],
      {},
      {},
      {},
      {},
      {} as Tenant,
    )
  }

  return documentORM
}

const toDocumentVersionORM = (
  docVer: DocumentVersion,
  docORM: DocumentORM,
  siblingDocVer: DocumentVersion[],
  attachedFilesMap: AttachedFilesMap,
  documentsMap: DocumentsMap,
  documentVersionsMap: DocumentVersionsMap,
  cacheMap: CacheMap,
  userTenant: Tenant,
): DocumentVersionORM => {
  const associatedFiles = getAssociatedFilesORM(
    docVer.associatedFiles ?? [],
    attachedFilesMap,
    documentsMap,
    documentVersionsMap,
  );

  // Assumes the incoming siblingDocVer array is sorted
  const isLatestPublished = siblingDocVer
    .find(docVer => docVer.status === 'PUBLISHED')
    ?.id === docVer.id;
  const { values:labelValues, configs:labelConfigs } = getMappedTenantFields(docVer, userTenant)

  const docVerORM: DocumentVersionORM = {
    model: docVer,
    type: ORMTypes.DOCUMENT_VERSION,
    relations: {
      document: docORM,
      associatedFiles: associatedFiles,
      pages: [] as PageORM[],
      pageGroups: [] as PageGroupORM[],
    },
    meta: {
      assets: {
        thumbnailKey: docVer.convertedFolderKey
          ? detectArchivedFileKeyPath(
            docVer,
            {
              number: docVer.selectedThumbnail ?? 1,
              pageId: `${docVer.id}_${docVer.selectedThumbnail ?? 1}`,
            },
            'small',
          )!
          : undefined,
        contentKey: docVer
          ? `/content/${docVer.srcFile.key}`
          : undefined,
        getAuthHeaders,
        isContentCached: cacheMap[docVer.id]?.CONTENT?.status === 'LOADED',
        isThumbnailCached: cacheMap[docVer.id]?.THUMBNAIL?.status === 'LOADED',
      },
      version: {
        semVerLabel: `${docVer.semVer?.major}.${docVer.semVer?.minor}`,
        isLatestPublished,
        // From this version to the latest version would it be a MAJOR or MINOR update
        // If the document is not published we always consider it a major change (requires review)
        updateStatus:
          docVer.status !== DocumentStatus.PUBLISHED ||
            docORM.model.status !== DocumentStatus.PUBLISHED
            ? VERSION_UPDATE_STATUS.NOT_PUBLISHED
            : isLatestPublished
              ? VERSION_UPDATE_STATUS.CURRENT
              : siblingDocVer
                .filter((ver) => ver.versionNumber > docVer.versionNumber && ver.status === DocumentStatus.PUBLISHED)
                .every((ver) => ver.changeType === DocumentVersionChangeType.MINOR)
                ? VERSION_UPDATE_STATUS.PENDING_MINOR
                : VERSION_UPDATE_STATUS.PENDING_MAJOR,
      },
      integration: {
        integrationType: docVer.integrationType,
        integration: docVer.integration,
        source: docVer.integrationType && capitalize(docVer.integrationType),
      },
      permissions: getDocVersionPermissions(docVer, associatedFiles),
      tenantFields: {
        labelCompletion: getMissingRequiredFieldNames(docVer, userTenant.fields).length === 0,
        valuesMap: labelValues,
        configsMap: labelConfigs,
      },
      // @ts-expect-error - TS doesn't the know types are narrowed down
      sealedStatus: [
        DocumentStatus.ARCHIVED,
        DocumentStatus.REVOKED,
        DocumentStatus.DELETED,
      ].find(status => status === docORM.model.status),
    },
  }

  const pageORMs = docVer.pages.map<PageORM>((page) => {
    return {
      model: page,
      type: ORMTypes.PAGE,
      relations: {
        documentVersion: docVerORM,
        pageGroup: undefined,
      },
    }
  })
  docVerORM.relations.pages = pageORMs
  if (docVer.pageGroups && docVer.pageGroups.length > 0) {
    // Need to populate Page Groups
    const pageLookup = pageORMs.reduce((acc, page) => {
      acc.set(page.model.pageId, page)
      return acc
    }, new Map<string, PageORM>())
    docVerORM.relations.pageGroups = docVer.pageGroups?.map<PageGroupORM>((group) => {
      const groupORM: PageGroupORM = {
        model: group,
        type: ORMTypes.PAGE_GROUP,
        meta: {
          isRequired: false,
        },
        relations: {
          documentVersion: docVerORM,
          pages: [] as PageORM[],
        },
      }
      const groupPages = group.pageIds ? group.pageIds.map<PageORM>((pageId) => {
        const pageORM = pageLookup.get(pageId)
        if (pageORM) {
          pageORM.relations.pageGroup = groupORM
          return pageORM
        } else {
          throw Error(`Page Group References non-existant page: ${pageId}`)
        }
      }) : [] as PageORM[]
      groupORM.relations.pages = groupPages
      groupORM.meta.isRequired = groupPages.some((page) => !!page.model.isRequired)
      return groupORM
    })
  }

  return docVerORM
}

export function getAssociatedFileORMFromFile(
  file: (AttachedFile | Document),
  model: AssociatedFile,
  latestUsableDocumentVersion?: DocumentVersion) {
  return {
    model: model,
    meta: {
      canBeSharedByMSL: model.status === 'ACTIVE' &&
        (model.type !== 'DOCUMENT' ||
          (latestUsableDocumentVersion?.status === 'PUBLISHED' && !!latestUsableDocumentVersion?.distributable)),
    },
    relations: {
      latestUsableDocumentVersion,
    },
    type: ORMTypes.ASSOCIATED_FILE,
    file,
  } as AssociatedFileORM
}
export function getAssociatedFilesORM(
  associatedFiles: AssociatedFile[],
  attachedFilesMap: AttachedFilesMap,
  documentsMap: DocumentsMap,
  documentVersionsMap: DocumentVersionsMap,
): AssociatedFileORM[] {
  if (!associatedFiles.length) return []

  return associatedFiles.reduce((acc: AssociatedFileORM[], associatedFile: AssociatedFile) => {
    const isAttachedFile = associatedFile.type === AssociatedFileType.ATTACHED_FILE;

    // the associated file id has a '_1' appended to it, removing it for linked documents
    const attachmentIdMod = associatedFile.attachmentId.replace('_1', '')

    // Prepare latestPublishedDocVer object if this file is a Document
    let latestPublishedDocVer: DocumentVersion | undefined;
    let latestDocVer: DocumentVersion | undefined;
    if (!isAttachedFile) {
      const targetDocVersions = documentVersionsMap[attachmentIdMod]
      // [TODO-2126] - This may need to be handled more gracefully
      //               This would only happen though if records are bad i.e. in local dev environments
      if (targetDocVersions) {
        const docVerDesc: DocumentVersion[] = targetDocVersions.sort((a, b) => b.versionNumber - a.versionNumber)
        latestDocVer = docVerDesc[0]
        latestPublishedDocVer = docVerDesc.find((docVer) => docVer.status === DocumentStatus.PUBLISHED)
      }
    }

    const file = isAttachedFile
      ? attachedFilesMap[associatedFile.attachmentId]
      : documentsMap[attachmentIdMod]

    // Remove any associations which are to missing / deleted docs
    if (file) {
      acc.push(getAssociatedFileORMFromFile(file, associatedFile, latestPublishedDocVer || latestDocVer))
      return acc
    } else {
      return acc
    }
  }, [] as AssociatedFileORM[]);
}

const allDocuments = createSelector(
  selDocuments,
  selDocumentVersionsMap,
  selTenantMap,
  selBookmarkMap,
  loggedUser,
  selAttachedFilesMap,
  selDocumentsMap,
  selCacheMap,
  selIsOnline,
  (docs,
    docVersMap,
    tenantsMap,
    bookmarkMap,
    currentUser,
    attachedFilesMap,
    documentsMap,
    cacheMap,
    isOnline): DocumentORM[] => {
    const userTenant = tenantsMap[currentUser?.userProfile?.tenantId ?? '-1']
    if (!userTenant && currentUser?.userProfile?.role !== UserRole.ALUCIO_ADMIN) {
      throw new Error('Could not identify user\'s tenant')
    }

    // const lockedFilterFieldNames = Object.keys(currentUser.meta.formattedLockedFilters!)
    const documents: DocumentORM[] = docs.map(doc => {
      return toDocumentORM(
        doc,
        docVersMap,
        tenantsMap,
        bookmarkMap,
        userTenant,
        attachedFilesMap,
        documentsMap,
        cacheMap,
        isOnline,
      )
    });

    return documents
  },
)

const allTenantDocuments = createSelector(
  allDocuments,
  (documents): DocumentORM[] =>
    documents.filter(({ model }) => model.accessLevel === DocumentAccessLevel.TENANT),
);

const allPersonalDocuments = createSelector(
  allDocuments,
  loggedUser,
  (documents, user): DocumentORM[] =>
    documents.filter(({ model }) => model.accessLevel === DocumentAccessLevel.USER &&
      model.createdBy === user.authProfile?.attributes.email)
      .sort((a, b) => b.model.createdAt.localeCompare(a.model.createdAt)),
);

// Default instance (1 use at a time)
const allDocumentsFiltered = createSelector(
  allTenantDocuments,
  selOpts,
  filterCollection,
)

const allDocumentsSortedAndFiltered = createSelector(
  allDocumentsFiltered,
  selOpts,
  sortCollection,
)

const allPersonalDocumentsSortedAndFiltered = createSelector(
  allPersonalDocuments,
  selOpts,
  sortCollection,
)

// Instance factory (can use multiple at a time)
const allDocumentsFilteredFactory = () => createSelector(
  allTenantDocuments,
  selOpts,
  filterCollection,
)

const allPersonalDocumentsFilteredFactory = () => createSelector(
  allPersonalDocuments,
  selOpts,
  filterCollection,
)

export const allDocumentsSortedAndFilteredFactory = () => createSelector(
  allDocumentsFilteredFactory(),
  selOpts,
  sortCollection,
)

export const allPersonalDocumentsSortedAndFilteredFactory = () => createSelector(
  allPersonalDocumentsFilteredFactory(),
  selOpts,
  sortCollection,
)

export const allDocumentsSortedFilteredPaginated = createSelector(
  allDocumentsSortedAndFiltered,
  selOpts,
  paginateCollection,
)

export const allDocumentVersionMap = createSelector(
  allDocuments,
  (docs): documentVersionORMMap => {
    return docs.reduce((acc, doc) => {
      doc.relations.documentVersions.forEach(docVerORM => {
        acc[docVerORM.model.id] = docVerORM
      })
      return acc
    },
      {} as documentVersionORMMap,
    )
  },
)

const allDocumentsLength = createSelector(
  allTenantDocuments,
  (documents) => documents.length,
)

export const indexedDocumentList = createSelector(
  allDocumentsFiltered,
  (documents: DocumentORM[]): IndexedDocumentORM => {
    const indexedDocumentORM = {};
    documents.forEach((documentORM) => {
      indexedDocumentORM[documentORM.model.id] = documentORM;
    });
    return indexedDocumentORM;
  },
);

export const indexedDocumentVersionList = createSelector(
  selDocumentVersions,
  (documentVersions: DocumentVersion[]): IndexedDocumentVersion => {
    return indexArray<DocumentVersion>(documentVersions, 'id');
  },
);

const documentVersionORMById = createSelector(
  allDocumentVersionMap,
  (_, id: string) => id,
  (indexedDocumentVersions, id): DocumentVersionORM | undefined => indexedDocumentVersions[id],
)

export const useAllDocuments = (opts?: FilterAndSortOptions<DocumentORM>):
  ReturnType<typeof allTenantDocuments> =>
  useSelector((state: RootState) =>
    allDocumentsSortedAndFiltered(state, undefined, opts))

export const useAllPersonalDocuments = ():
  ReturnType<typeof allPersonalDocuments> =>
  useSelector((state: RootState) =>
    allPersonalDocuments(state));

export const useAllDocumentVersionMap = ():
  ReturnType<typeof allDocumentVersionMap> =>
  useSelector((state: RootState) =>
    allDocumentVersionMap(state))

export const useAllDocumentsInstance = (opts?: FilterAndSortOptions<DocumentORM>):
  ReturnType<typeof allDocumentsSortedAndFiltered> => {
  const selectorInstance = useMemo(
    () => allDocumentsSortedAndFilteredFactory(),
    [],
  )

  return useSelector((state: RootState) => selectorInstance(state, undefined, opts))
}

export const useAllPersonalDocumentsInstance = (opts?: FilterAndSortOptions<DocumentORM>):
  ReturnType<typeof allPersonalDocumentsSortedAndFiltered> => {
  const selectorInstance = useMemo(
    () => allPersonalDocumentsSortedAndFilteredFactory(),
    [],
  )

  return useSelector((state: RootState) => selectorInstance(state, undefined, opts))
}

export const useAllDocumentsMap = ():
  ReturnType<typeof selDocumentsMap> =>
  useSelector((state: RootState) =>
    selDocumentsMap(state),
  )

// [TODO] - This naming is confusing as it's too similar to useAllDocumentVersionMap
//        - This selector is DocumentId -> Array<DocumentVersions>
export const useAllDocumentVersionsMap = ():
  ReturnType<typeof selDocumentVersionsMap> =>
  useSelector((state: RootState) =>
    selDocumentVersionsMap(state),
  )

export const useAllDocumentsLength = ():
  ReturnType<typeof allDocumentsLength> =>
  useSelector((state: RootState) =>
    allDocumentsLength(state))

// [TODO] - We have different ways of accessing a specific DocumentORM
//          We should consider consolidating (or making these more well known)
export const useDocumentVersionORM = (id: string): ReturnType<typeof documentVersionORMById> =>
  useSelector((state: RootState) => documentVersionORMById(state, id))
