import { createSelector } from '@reduxjs/toolkit';
import lunr from 'lunr';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import {
  allDocumentsSortedFilteredPaginated,
  indexedDocumentList,
  IndexedDocumentORM,
  selOpts,
} from '../document';
import {
  FilterAndSortOptions,
  paginateCollection,
  sortCollection,
} from 'src/state/redux/selector/common'
import { DocumentORM } from 'src/types/types'
import { userTenant, userTenantDefaultFilterKeys } from '../user';
import { DocumentStatus, Tenant } from '@alucio/aws-beacon-amplify/src/models';
import stopWordsList from './stopWordsList.json';
import { merge, filters } from 'src/state/redux/document/query'
import { useMemo } from 'react';

export interface DocumentSearchOptions {
  text: string;
  isPublisher?: boolean;
  status?: (DocumentStatus | keyof typeof DocumentStatus)[];
}

export const searchText = (_: RootState, documentSearchOptions: DocumentSearchOptions) => documentSearchOptions;

const LUNR_RESERVED_CHARACTERS = ['*', '-', '+', '~', ':', '^', '/'];
const SPECIAL_CHARACTERS_REGEX = /-|\/|\+|\*|~|\^|:/;

const documentPurpose = {
  EP: 'Proactive',
  ER: 'Reactive',
  INT: 'Internal',
};

// RETURNS AN INDEXED LUNR'S OBJECT BASED ON PUBLISHED
const indexedSearchList = createSelector(
  indexedDocumentList,
  userTenantDefaultFilterKeys,
  (_, opts) => opts.isPublisher,
  indexSearchList,
);

// RETURNS AN INDEXED LUNR'S OBJECT BASED ON THE RECEIVED DOCUMENTS
function indexSearchList(documents: IndexedDocumentORM, defaultFilterKeys: string[], isPublisher?: boolean) {
  // WE SUPPLY TO LUNR OUR OWN STOP WORDS LIST
  lunr.stopWordFilter = lunr.generateStopWordFilter(stopWordsList);
  // TO SEPARATE STRINGS INTO TOKEN ONLY BY SPACES
  lunr.tokenizer.separator = /[\s]+/;
  lunr.trimmer = function (token) {
    return token.update(function (s) {
      return s.trim();
    })
  };
  const index = lunr(function (this: lunr.index) {
    this.field('title', { boost: 5 });
    this.field('shortDescription', { boost: 2 });
    this.field('longDescription');
    this.field('releaseNotes');
    this.field('purpose', { boost: 4 });
    this.ref('id');
    this.pipeline.remove(lunr.stemmer);

    const ids = Object.keys(documents);

    const customLabels = documents[ids[0]] ? Object.keys(documents[ids[0]].meta.tenantFields.valuesMap) : [];
    const formattedLabels = {};

    customLabels.forEach((customLabel) => {
      formattedLabels[customLabel] = customLabel.replace(/\//g, '');
      this.field(formattedLabels[customLabel], { boost: defaultFilterKeys.includes(customLabel) ? 3 : 1 });
    });

    ids.forEach((id) => {
      const documentModel = documents[id].relations.version.latestUsableDocumentVersion?.model;

      if (!documentModel) {
        return;
      }

      const { longDescription, purpose, shortDescription, title } = documentModel

      // Note: when lunr receives a field with an array of strings, it handles it as if it is already tokenized
      // This combines the array of strings into just one so lunr can tokenize it
      const tenantFieldValues = customLabels.reduce((acc, label) => {
        acc[formattedLabels[label]] = documents[id].meta.tenantFields.valuesMap[label]?.join(' ') ?? '';
        return acc;
      }, {});

      const documentVersion = isPublisher ? documents[id].relations.version.latestUsableDocumentVersion
        : documents[id].relations.version.latestDocumentVersionPublished;

      this.add({
        id,
        title,
        shortDescription,
        longDescription,
        purpose,
        releaseNotes: documentVersion?.model.releaseNotes,
        ...tenantFieldValues,
      });
    });
  });

  return { index, documents };
}

const twoLettersSearchableValues = createSelector(
  userTenant,
  (tenant: Tenant | undefined): string[] => {
    // Purposes
    const values = ['ER', 'EP'];

    tenant?.fields?.forEach((field) => {
      field?.values?.forEach((value) => {
        if (value.length === 2) {
          values.push(value);
        }
      });
    });

    return values;
  },
);

const handleCanPerformSearch = createSelector(
  twoLettersSearchableValues,
  (values: string[]): (searchText: string) => boolean => {
    return (searchText: string) => (searchText && searchText.length > 2) || values.includes(searchText.toUpperCase());
  },
);

const handleDocumentSearch = createSelector(
  handleCanPerformSearch,
  indexedSearchList,
  searchText,
  performDocumentSearch,
);

const allDocumentsSortedFilteredSearched = createSelector(
  handleDocumentSearch,
  selOpts,
  sortCollection,
);

const allDocumentsSortedFilteredSearchedPaginated = createSelector(
  allDocumentsSortedFilteredSearched,
  selOpts,
  paginateCollection,
)

// IT PERFORMS A SEARCH OVER THE RECEIVED INDEXED LIST
function performDocumentSearch(canPerformSearch, searchIndex, searchOptions: DocumentSearchOptions): DocumentORM[] {
  const { text, status } = searchOptions;

  // THE SEARCH WILL BE PERFORMED IF THE TEXT IS LONGER THAN 2 CHARACTERS OR IF THE TEXT IS PURPOSE
  if (!canPerformSearch(text)) {
    return [];
  }

  const { index, documents } = searchIndex;
  const query = queryBuilder(searchOptions);

  if (!query) {
    return [];
  }

  // PERFORM THE SEARCH
  const searchResult = index.search(query);

  // IF WE'RE NOT ADDING ANY FILTER BY STATUS, WE CAN AVOID CHECKING IT
  if (!status || !status.length) {
    // FIND THE DOCUMENTORM OBJECTS FROM THE RESULT
    return searchResult.map(({ ref }) => documents[ref]);
  }

  return searchResult.reduce((acc, { ref }) => {
    const documentORM = documents[ref];

    if (documentORM && status.includes((documentORM as DocumentORM).model.status)) {
      acc.push(documentORM);
    }

    return acc;
  }, []);
}

function queryBuilder(searchOptions: DocumentSearchOptions): string {
  const tokens = lunr.tokenizer(searchOptions.text);
  let query = '';

  // CREATE THE QUERY. ("OR" CONDITION WILL BE APPLIED)
  tokens.forEach((token) => {
    let cleanedToken = '';
    const unModifiedToken = token.str.toString().trim();

    // TO AVOID ITERATING OVER EVERY CHAR OF THE TOKENS,
    // WE FIRST CHECK IF THE TOKEN HAS A SPECIAL CHARACTER
    if (unModifiedToken.match(SPECIAL_CHARACTERS_REGEX)) {
      // WE NEED TO ESCAPE THE SPECIAL CHARACTERS SO LUNR DOESN'T CONSIDER THEM AS MODIFIERS
      [...(unModifiedToken)].forEach((char) => {
        cleanedToken += LUNR_RESERVED_CHARACTERS.includes(char) ? `\\${char}` : char;
      });
    } else {
      cleanedToken = unModifiedToken;
    }

    const tokenLowercased = cleanedToken.toLowerCase();
    const isRequired = unModifiedToken.charAt(0) === '!';

    // WE REMOVE THE "!"
    if (isRequired) {
      cleanedToken = cleanedToken.substring(1, cleanedToken.length);
    }

    if (cleanedToken) {
      // IF THE TOKEN IS A PURPOSE VALUE, SEARCH INTO THAT FIELD DIRECTLY
      if (['ER', 'EP', 'INT'].includes(cleanedToken.toUpperCase())) {
        query += `${isRequired ? '+' : ''}purpose:${documentPurpose[cleanedToken.toUpperCase()]}* `;
      } else if (tokenLowercased === 'us') {
        // IF THE TOKEN IS "US" TREAT IT AS A WHOLE WORD (NOT LIKE A SUFFIX/PREFIX)
        query += `${isRequired ? '+' : ''}${tokenLowercased} `;
      } else if (lunr.stopWordFilter(cleanedToken)) {
        // IF IT'S NOT A STOP WORD, ADD IT TO THE QUERY
        query += `${isRequired ? '+' : ''}${cleanedToken}* `;
      }
    }
  });

  return query;
}

export const useAllDocumentsPaginated =
  (searchOptions?: DocumentSearchOptions, opts?: FilterAndSortOptions<DocumentORM>):
    ReturnType<typeof allDocumentsSortedFilteredPaginated | typeof allDocumentsSortedFilteredSearchedPaginated> =>
    useSelector((state: RootState) =>
      searchOptions != null
        ? allDocumentsSortedFilteredSearchedPaginated(state, searchOptions, opts)
        : allDocumentsSortedFilteredPaginated(state, undefined, opts))

export const useMSLDocumentSearch =
  (searchOptions: DocumentSearchOptions, opts?: FilterAndSortOptions<DocumentORM>) => {
    const layeredFilters = useMemo(
      () => {
        return opts
          ? merge(opts, filters.published)
          : filters.published
      },
      [opts],
    )

    return useSelector((state: RootState) => allDocumentsSortedFilteredSearched(state, searchOptions, layeredFilters));
  }

export const useDocumentSearch = (searchOptions: DocumentSearchOptions, opts?: FilterAndSortOptions<DocumentORM>) =>
  useSelector((state: RootState) => allDocumentsSortedFilteredSearched(state, searchOptions, opts));

export const useCanPerformSearch = () =>
  useSelector((state: RootState) => handleCanPerformSearch(state));
