import React, { useMemo, useState } from 'react'
import { compose } from 'redux'

import {
  DNABox,
  DNAButton,
  DNADivider,
  DNAText,
} from '@alucio/lux-ui'

import { useSort, withSort } from 'src/components/DNA/hooks/useSort'
import { useAllDocumentsInstance } from 'src/state/redux/selector/document'
import { merge, filters } from 'src/state/redux/document/query'

import DocumentDropzone from './DocumentDropzone/DocumentDropzone';
import DNAPublisherList from 'src/components/DNA/GridList/DNAPublisherGridList'
import DNADocumentFilters,
{
  useDNADocumentFilters,
  withDNADocumentFilters,
} from 'src/components/DNA/Document/DNADocumentFilters/DNADocumentFilters'
import SelectFileButton from 'src/components/SelectFileButton/SelectFileButton';
import DNAPagination from 'src/components/DNA/Pagination/DNAPagination'
import useFixedHeader from '@alucio/lux-ui/src/hooks/useFixedLayout'
import { exportData } from './Export'
import { useCurrentUser } from 'src/state/redux/selector/user'
import { useTenantList } from 'src/state/redux/selector/tenant'
import { StyleSheet } from 'react-native';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'

const ExportControl = () => {
  const allDocumentsCSV = useAllDocumentsInstance()
  const tenants = useTenantList();
  const currentUser = useCurrentUser();
  return (
    <DNAButton
      style={{ marginRight: 8 }}
      appearance="outline"
      iconLeft="download"
      onPress={() => exportData(allDocumentsCSV, currentUser, tenants)}
    >
      Export content list
    </DNAButton>
  )
}

const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: colors['color-text-basic'],
    flex: 1,
    flexDirection: 'column',
    minHeight: 'calc(100vh - 160px)',
    minWidth: 'auto',
    paddingHorizontal: 33,
    paddingVertical: 24,
  },
});

export const DNALibrary: React.FC = () => {
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])

  const { sortSelectorOpts } = useSort()
  const fixedHeaderWidth = useFixedHeader({ offSet: 163 });
  const { toggleModal, unpublishedToggle, toggleUnpublished, filterSelectorOpts } = useDNADocumentFilters()

  const selectorOpts = useMemo(
    () => merge(
      filters.nonDeleted,
      filterSelectorOpts,
      sortSelectorOpts,
    ),
    [sortSelectorOpts, filterSelectorOpts],
  )

  const allDocuments = useAllDocumentsInstance()
  const filteredDocuments = useAllDocumentsInstance(selectorOpts)
  const currentPage = useCurrentPage()

  function onSelectedFiles(files: File[]): void {
    setFilesToUpload(files);
  }

  return (
    <DNABox style={styles.mainWrapper} spacing="medium">
      <DNADocumentFilters.Modal documents={allDocuments} />
      <DNABox fill spacing="medium" appearance="col">
        {/* Header */}
        <DNABox alignY="end" spacing="between" style={{ maxWidth : fixedHeaderWidth }}>
          <DNABox appearance="col" spacing="medium">
            <DNAText testID="page-title" h5 status="subtle">{currentPage?.LABEL}</DNAText>
            {/* Select File Input [TODO] - Web Only */}
            <SelectFileButton onSelect={onSelectedFiles} text="Upload" multiple={true} />
          </DNABox>
          <DNABox>
            <ExportControl />
            <DNAButton
              appearance="outline"
              iconLeft="filter-variant"
              onPress={toggleModal}
              testID="header-filters-button"
            >
              Filters
            </DNAButton>
          </DNABox>
        </DNABox>
        <DNADivider />
        {/* Content */}
        <DocumentDropzone
          files={filesToUpload}
          toggleMyUnpublishedDocument={() => {
            if (!unpublishedToggle) {
              toggleUnpublished()
            }
          }}
          onCloseModal={() => { setFilesToUpload([]) }}
        >
          <DNABox appearance="col" spacing="medium">
            <DNABox spacing="between" alignY="start" childFill={0} style={{ maxWidth :  fixedHeaderWidth }}>
              <DNADocumentFilters.Chips documents={filteredDocuments} chipsStatus="primary" />
              <DNADocumentFilters.UnpublishToggle />
            </DNABox>
            <DNAPagination query={selectorOpts}>
              <DNABox appearance="col" spacing="medium">
                <DNAPublisherList />
                <DNABox style={{ alignSelf: 'flex-start' }}>
                  <DNAPagination.Nav />
                </DNABox>
              </DNABox>
            </DNAPagination>
          </DNABox>
        </DocumentDropzone>
      </DNABox>
    </DNABox>
  );
}

export default compose(
  withDNADocumentFilters,
  withSort,
)(DNALibrary)
